import { shouldFetch } from 'utils';
import {
  CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST,
  CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS,
  CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

export default () =>
  authCall({
    shouldFetch: ({
      customer: {
        officeProfileProgress: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [
      CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST,
      CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS,
      CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE
    ],
    endpoint: '/user/customer/progress',
    method: 'GET'
  });
