import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCreditCard } from 'actions/shop/payment';
import { translateEditing } from '../HOC';

const RemoveCreditCard = ({ isLoading, onClick }) => (
  <button type="button" className={`delete-creditcard ${isLoading ? 'loading' : ''}`} onClick={onClick} />
);

RemoveCreditCard.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = (
  {
    shop: {
      payment: {
        creditCards: { delete: remove }
      }
    }
  },
  { id }
) => ({
  isLoading: !!(remove[id] && remove[id].isDeleting)
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onClick: () => dispatch(deleteCreditCard(id))
});

export { RemoveCreditCard as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RemoveCreditCard)
);
