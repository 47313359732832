import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pure } from 'recompose';
import DatePicker from 'react-datepicker';
import onClickOutside from 'react-onclickoutside';
import { isHoliday, isWeekday } from 'utils';
import { translateEditing } from '../../../../../HOC';

import './Datepicker.less';

// TODO: Remove last check
const filterDates = date =>
  isWeekday(date) && !isHoliday(date) && moment(new Date(date)).format('YYYY-MM-DD') !== '2018-12-24';
class Datepicker extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleChange(dueDate) {
    if (dueDate) this.props.onChange(dueDate);
  }

  handleClickOutside() {
    this.props.onClickOutside();
  }

  render() {
    const { minDate, dueDate, t } = this.props;

    return (
      <DatePicker
        popperClassName="datepicker-calendar-popout"
        minDate={new Date(minDate)}
        selected={new Date(dueDate)}
        autoFocus
        onChange={this.handleChange}
        filterDate={filterDates}
        placeholderText={t('due-date-placeholder')}
        className="cart-drop shipping-date"
      />
    );
  }
}

Datepicker.propTypes = {
  minDate: PropTypes.object.isRequired,
  dueDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export { Datepicker as PureComponent };
export default pure(translateEditing(onClickOutside(Datepicker)));
