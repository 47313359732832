import { I18N_LANGUAGE_SET, I18N_CURRENCY_SET } from 'constants/actionTypes';

export default (
  state = {
    language: null,
    currency: null
  },
  action
) => {
  switch (action.type) {
    case I18N_LANGUAGE_SET:
      return { ...state, language: action.language };

    case I18N_CURRENCY_SET:
      return { ...state, currency: action.currency };

    default:
      return state;
  }
};
