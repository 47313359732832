import { AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case AUTH_TOKEN_REQUEST:
      return { ...state, isFetching: true };

    case AUTH_TOKEN_SUCCESS:
    case AUTH_TOKEN_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
