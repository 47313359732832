import React from 'react';
import { pure } from 'recompose';
import { Pagination } from 'common';
import { ServiceRequest } from '../..';

import './ServiceRequestList.less';

const ServiceRequestList = ({
  serviceRequests,
  numberOfItems,
  showPerPage,
  handlePageChange,
  updateServiceRequest,
  statusesOptions
}) => (
  <div className="service-request-list-wrapper">
    <div className="service-request-list">
      {serviceRequests &&
        serviceRequests.length > 0 &&
        serviceRequests.map(serviceRequest => (
          <ServiceRequest
            key={serviceRequest.request_id}
            serviceRequest={serviceRequest}
            updateServiceRequest={updateServiceRequest}
            statusesOptions={statusesOptions}
          />
        ))}
    </div>

    {numberOfItems > showPerPage && (
      <div className="orders-list-pagination">
        <Pagination handlePageChange={handlePageChange} numberOfItems={numberOfItems} showItemsPerPage={showPerPage} />
      </div>
    )}
  </div>
);

export { ServiceRequestList as PureComponent };
export default pure(ServiceRequestList);
