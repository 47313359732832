import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { currency } from 'components/HOC';
import { setOrderPoNumber } from 'actions/orders';

export class Order extends Component {
  constructor(props) {
    super(props);

    this.state = { editingPoNumber: '', isEditing: false };
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.props.item;

    if (nextProps.orderPoNumbers[id] && nextProps.orderPoNumbers[id].isSubmitted) {
      this.handleCancelButtonClick();
    }
  }

  handlePoNumberChange = e => {
    e.persist();
    this.setState(() => ({
      editingPoNumber: e.target.value
    }));
  };

  handleSubmitButtonClick = () => this.props.setOrderPoNumber(this.state.editingPoNumber, this.props.item.id);

  handleCancelButtonClick = () => {
    this.setState(() => ({
      editingPoNumber: '',
      isEditing: false
    }));
  };

  handleAddOrEditLinkClick = () => this.setState(() => ({ isEditing: true }));

  hasPoNumber = () => {
    const { item, orderPoNumbers } = this.props;
    const isPoNumberSubmitted = orderPoNumbers[item.id] ? orderPoNumbers[item.id].isSubmitted : false;

    return isPoNumberSubmitted || item.po_number;
  };

  renderEditPoNumber = () => {
    const { t } = this.props;
    const hasPoNumber = this.hasPoNumber();

    return (
      <div className={`flex po-number-edit-box ${hasPoNumber ? 'has-po-number' : ''}`}>
        <input
          className="edit-po-number-input"
          type="text"
          onChange={e => this.handlePoNumberChange(e)}
          placeholder={t('po-number-placeholder')}
          value={this.state.editingPoNumber}
        />
        <button type="submit" className="button-submit save" onClick={this.handleSubmitButtonClick}>
          {t('save')}
        </button>
        <button type="button" className="button-soft cancel" onClick={this.handleCancelButtonClick}>
          {t('cancel')}
        </button>
      </div>
    );
  };

  renderAddOrEditLinks = () => {
    const { t } = this.props;
    const hasPoNumber = this.hasPoNumber();

    if (hasPoNumber) {
      return (
        <div className="edit-po-number" onClick={this.handleAddOrEditLinkClick} role="button" tabIndex="-1">
          {t('edit-po-number')}
        </div>
      );
    }

    return (
      <div className="add-po-number" onClick={this.handleAddOrEditLinkClick} role="button" tabIndex="-1">
        {t('insert-po-number')}
      </div>
    );
  };

  renderPoNumber = () => {
    const { t, item, orderPoNumbers } = this.props;
    const isPoNumberSubmitted = orderPoNumbers[item.id] ? orderPoNumbers[item.id].isSubmitted : false;
    const hasPoNumber = isPoNumberSubmitted || item.po_number;
    const poNumber = isPoNumberSubmitted ? orderPoNumbers[item.id].data.poNumber : item.po_number;
    return (
      <div className="flex order-po-number-container">
        {hasPoNumber ? (
          <div className="order-po-number">
            <span className="order-po-number-info">
              {t('po-number')}: {poNumber}
            </span>
          </div>
        ) : (
          ''
        )}
        {this.state.isEditing ? this.renderEditPoNumber() : this.renderAddOrEditLinks()}
      </div>
    );
  };

  render() {
    const { item, c, t } = this.props;

    return (
      <div className="order-entry">
        <div className="order-short-description flex">
          {item.type === 'cleaning' ? (
            <div className="order-date">
              {t('from')}: {moment(item.start_date).format('L')}
            </div>
          ) : (
            <div className="order-date">
              {t('order-date')}: {moment(item.created_date).format('L')}
            </div>
          )}
          {this.renderPoNumber()}
          <span className={`order-icon ${item.type === 'cleaning' ? 'icon-cleaning' : 'icon-shop'}`} />
        </div>
        <div className="order-detailed-desciption">
          <div className="row-titles flex">
            <div className="title-description">{t('description')}</div>
            <div className="title-quantity">{t('quantity')}</div>
            <div className="title-price">
              {t('price')}
              <span className="included-tax">
                &nbsp;(
                {t('ex-tax')})
              </span>
            </div>
          </div>
          {item.products.map(product => (
            <div className="row-values flex" key={product.id}>
              <div className="value-description">{product.name}</div>
              <div className="value-quantity">{product.quantity}</div>
              <div className="value-price">{c(product.total_price)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Order.propTypes = {
  item: PropTypes.object.isRequired,
  c: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  orderPoNumbers: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  orderPoNumbers: state.orderPoNumbers
});

const mapDispatchToProps = {
  setOrderPoNumber
};

export default pure(
  currency(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Order)
  )
);
