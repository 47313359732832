import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Header from '../Header';
import { Sum } from '../Common';
import { CancelButton, OrderButton, PaymentMethod, PoNumber, ShippingAddress, BillingAddress } from '.';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShippingAddressSaved: true,
      isBillingAddressSaved: true,
      isAddressesTheSame: true
    };
  }

  handleSameAddressCheckboxClick = isTheSame => {
    this.setState({
      isAddressesTheSame: isTheSame
    });
  };

  handleShippingAddressSubmit = () => {
    this.setState({
      isShippingAddressSaved: true
    });
  };

  handleShippingAddressChange = () => {
    this.setState({
      isShippingAddressSaved: false
    });
  };

  handleBillingAddressSubmit = () => {
    this.setState({
      isBillingAddressSaved: true
    });
  };

  handleBillingAddressChange = () => {
    this.setState({
      isBillingAddressSaved: false,
      isAddressesTheSame: false
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="cart-flex cart-animation">
        <Header />

        <div className="cart-inside-container">
          <div className="cart-inside">
            <ShippingAddress
              handleShippingAddressSubmit={this.handleShippingAddressSubmit}
              handleShippingAddressChange={this.handleShippingAddressChange}
              isAddressesTheSame={this.state.isAddressesTheSame}
            />
            <BillingAddress
              handleBillingAddressSubmit={this.handleBillingAddressSubmit}
              handleBillingAddressChange={this.handleBillingAddressChange}
              handleSameAddressCheckboxClick={this.handleSameAddressCheckboxClick}
            />
            <PaymentMethod />
            <PoNumber />
          </div>
        </div>

        <div className="cart-all-combined">
          <div>
            <Sum />
            <div className="cart-all-combined-flex">
              <CancelButton />
              <OrderButton
                isShippingAddressSaved={this.state.isShippingAddressSaved}
                isBillingAddressSaved={this.state.isBillingAddressSaved}
              />
            </div>
            <Link to="/support" className="cart-help">
              {t('need-help')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Payment.propTypes = {
  t: PropTypes.func.isRequired
};

export default pure(withTranslation('Shop/Cart/Default')(Payment));
