import { SHOP_PRODUCTS_REQUEST, SHOP_PRODUCTS_SUCCESS, SHOP_PRODUCTS_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default (endpoint, dataKey, query) => {
  const currentPage = query.page || 1;

  return authCall({
    shouldFetch: ({
      shop: {
        products: {
          list: { [dataKey]: { isFetching, pages: { [currentPage]: { fetchedAt } = {} } = {} } = {} }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { dataKey, currentPage },
    types: [SHOP_PRODUCTS_REQUEST, SHOP_PRODUCTS_SUCCESS, SHOP_PRODUCTS_FAILURE],
    endpoint,
    method: 'GET',
    query: ({
      shop: {
        partners: { partnerIds }
      }
    }) => ({
      ...query,
      page: currentPage, // force page for now
      partner_ids: partnerIds.length > 0 ? partnerIds : undefined
    })
  });
};
