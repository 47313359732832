import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';

import './Input.less';

const Input = props => {
  const { id, className, parentClass, label, required, nodeRef, error, errormsg } = props;
  const passedProps = omit(props, 'className', 'parentClass', 'label', 'nodeRef', 'ref', 'error', 'errormsg');

  if (nodeRef) passedProps.ref = nodeRef;
  return (
    <div className={`input ${parentClass}`}>
      {label && (
        <label className={`input__label ${required ? 'required' : ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      {error && (
        <label className="error" htmlFor={id}>
          {errormsg}
        </label>
      )}
      <input {...passedProps} className={`input__input ${className}`} />
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  parentClass: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  required: PropTypes.bool,
  error: PropTypes.bool,
  errormsg: PropTypes.string
};

Input.defaultProps = {
  className: '',
  parentClass: '',
  type: 'text',
  required: false
};

export default htmlId(Input);
