import {
  SHOP_FAVORITE_DELETE_REQUEST,
  SHOP_FAVORITE_DELETE_SUCCESS,
  SHOP_FAVORITE_DELETE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default productId =>
  authCall({
    shouldFetch: state =>
      !(state.shop.favorites.delete[productId] && state.shop.favorites.delete[productId].isDeleting),
    meta: { productId },
    types: [SHOP_FAVORITE_DELETE_REQUEST, SHOP_FAVORITE_DELETE_SUCCESS, SHOP_FAVORITE_DELETE_FAILURE],
    endpoint: `/user/shop/favorites/${productId}`,
    method: 'DELETE'
  });
