import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';
import { createCase } from 'actions/cases';

import './MissingProduct.less';

export class MissingProduct extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isSubmitted && nextProps.isSubmitted) {
      this.props.closeModal();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const product = this.productInput.value.trim();
    const brand = this.brandInput.value.trim();
    const quantity = this.quantityInput.value.trim();

    this.props.onSubmit(product, brand, quantity);
  }

  render() {
    const { t, productName, isSubmitting } = this.props;

    return (
      <DocumentTitle title={t('headline')}>
        <form className="request-form" disabled={isSubmitting} onSubmit={this.handleSubmit}>
          <div className="input-row">
            <input
              type="text"
              name="product"
              ref={ref => {
                this.productInput = ref;
              }}
              className="text-field name"
              placeholder={t('product')}
              defaultValue={productName}
              required
            />

            <input
              type="text"
              name="brand"
              ref={ref => {
                this.brandInput = ref;
              }}
              className="text-field brand"
              placeholder={t('brand')}
            />

            <input
              type="text"
              name="quantity"
              ref={ref => {
                this.quantityInput = ref;
              }}
              className="text-field amount"
              placeholder={t('quantity')}
            />
          </div>

          <div className="input-row">
            <button type="submit" className={`submit-button ${isSubmitting ? 'loading-light' : ''}`}>
              {t('request')}
            </button>
          </div>

          <div className="information-row">
            <p>
              <span className="important">{t('info-headline')}</span>
              {t('info-text')}
            </p>
          </div>
        </form>
      </DocumentTitle>
    );
  }
}

MissingProduct.propTypes = {
  productName: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func
};

const mapStateToProps = ({
  search: { q: productName },
  cases: {
    create: { isCreating: isSubmitting, isCreated: isSubmitted }
  }
}) => ({ productName, isSubmitting, isSubmitted });

const mapDispatchToProps = (dispatch, { t }) => ({
  onSubmit: (product, brand, quantity) =>
    dispatch(createCase(t('case-subject', { product, brand, quantity }), t('case-body', { product, brand, quantity })))
});

export { MissingProduct as PureComponent };
export default withTranslation('Modal/MissingProduct')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Missing Product', { className: 'missing-product-modal' })(MissingProduct))
);
