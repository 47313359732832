import {
  SERVICE_EVENT_DEFINITION_COSTS_REQUEST,
  SERVICE_EVENT_DEFINITION_COSTS_SUCCESS,
  SERVICE_EVENT_DEFINITION_COSTS_FAILURE
} from 'constants/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_DEFINITION_COSTS_REQUEST: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: true, fetchedAt: null } };
    }

    case SERVICE_EVENT_DEFINITION_COSTS_SUCCESS: {
      const {
        meta: { dataKey }
      } = action;

      return {
        ...state,
        [dataKey]: { ...state[dataKey], isFetching: false, fetchedAt: new Date(), items: action.payload }
      };
    }

    case SERVICE_EVENT_DEFINITION_COSTS_FAILURE: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: false } };
    }

    default:
      return state;
  }
};
