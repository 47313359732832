import { fetchMembers } from '../../actions/members';
import memoize from '../memoize';

const getCustomerId = state => state.current.customer && state.current.customer.id;

export default dispatch =>
  memoize([getCustomerId], customerId => {
    if (!customerId) return;

    dispatch(fetchMembers());
  });
