import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFavoriteProductsForTeaser } from 'selectors/shop';
import { EmptyView } from '../..';

const EmptyTeaser = ({ isVisible }) =>
  isVisible ? (
    <div className="empty-con">
      <EmptyView />
    </div>
  ) : null;

EmptyTeaser.propTypes = {
  isVisible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isVisible:
    !(state.shop.bestsellers.items && state.shop.bestsellers.items.length > 0) &&
    getFavoriteProductsForTeaser(state).length <= 0
});

export default connect(mapStateToProps)(EmptyTeaser);
