import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Input, Checkbox } from 'common/Form';
import { changeShippingAddress } from 'actions/customer';

const createState = ({ street = '', postalCode = '', city = '', elevator = true, floor = '', countryCode = 'DE' }) => ({
  street,
  postalCode,
  city,
  elevator,
  floor,
  countryCode
});

export class ShippingAddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.propsHaveChanged(nextProps)) {
      this.setState(createState(nextProps));
    }
  }

  get hasChanged() {
    return this.propsHaveChanged(this.state);
  }

  propsHaveChanged(nextProps) {
    return (
      this.props.street !== nextProps.street ||
      this.props.postalCode !== nextProps.postalCode ||
      this.props.city !== nextProps.city ||
      this.props.elevator !== nextProps.elevator ||
      this.props.floor !== nextProps.floor ||
      this.props.countryCode !== nextProps.countryCode
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.hasChanged) {
      this.props.onSubmit(this.state);
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] === value) return;
    this.setState({ [name]: value });
  }

  render() {
    const { isSubmitting, t, country } = this.props;
    const { street, city, postalCode, elevator, floor } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Input
          autoFocus
          value={street}
          label={t('street')}
          onChange={this.handleChange}
          name="street"
          parentClass="form-group"
          required
        />

        <div className="form-group flex-row">
          <Input
            value={postalCode}
            label={t('postal-code')}
            name="postalCode"
            onChange={this.handleChange}
            parentClass="company-zip row-group"
            required
          />

          <Input
            value={city}
            label={t('city')}
            name="city"
            onChange={this.handleChange}
            parentClass="company-city row-group"
            required
          />
        </div>
        <div className="input form-group">
          <p className="company-country" name="countryCode">
            {t(country)}
          </p>
        </div>
        <Checkbox
          alignCheckboxLeft
          className="company-elevator"
          label={t('elevator')}
          name="elevator"
          onChange={() => this.setState({ elevator: !elevator })}
          defaultChecked={elevator}
        />
        <Input
          value={floor}
          label={t('floor')}
          name="floor"
          onChange={this.handleChange}
          parentClass="company-floor row-group"
          required
        />
        <button type="submit" disabled={!this.hasChanged} className={isSubmitting ? 'loading-light' : ''}>
          {t('save')}
        </button>
      </form>
    );
  }
}

ShippingAddressForm.propTypes = {
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  elevator: PropTypes.bool,
  floor: PropTypes.string,
  country: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  current: { customer },
  customer: {
    shippingAddress: { isChanging: isSubmitting }
  }
}) => {
  const { street, postal_code: postalCode, city, elevator, floor, country_code: country } =
    (customer && customer.shipping_address) || {};
  return { street, postalCode, city, elevator, floor, country, isSubmitting };
};

const mapDispatchToProps = {
  onSubmit: changeShippingAddress
};

export { ShippingAddressForm as PureComponent };
export default withTranslation('Settings/Customer/ShippingAddressForm')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShippingAddressForm)
);
