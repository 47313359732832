import { OFFER_REJECT, OFFER_REJECT_SUCCESS, OFFER_REJECT_FAIL } from 'constants/actionTypes';
import { apiCall } from 'api';

const rejectOffer = (token, comment) =>
  apiCall({
    types: [OFFER_REJECT, OFFER_REJECT_SUCCESS, OFFER_REJECT_FAIL],
    endpoint: `/offer/reject/${token}`,
    method: 'POST',
    body: { comment }
  });

export default rejectOffer;
