import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { Tooltip } from 'common';
import { getPartners } from 'selectors/shop';
import { ResetButton, TogglePartner } from '.';

import './PartnerFilter.less';

class PartnerFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuppliersMenu: false
    };
  }

  toggleSuppliersMenu = () => {
    this.setState(prevState => ({
      showSuppliersMenu: !prevState.showSuppliersMenu
    }));
  };

  handleClickOutside() {
    this.setState({
      showSuppliersMenu: false
    });
  }

  render() {
    const { numberOfSelectedItems, isLoading, items, t } = this.props;
    const className = classNames('shop-partners bottom arrow-right', {
      loading: isLoading,
      active: numberOfSelectedItems > 0,
      open: this.state.showSuppliersMenu
    });
    const tooltipId = 'tooltip-partner-filter';

    return (
      <Tooltip className={className} id={tooltipId}>
        <button
          onClick={this.toggleSuppliersMenu}
          type="button"
          className="button-soft shop-partners-button"
          data-tip={t('tooltip')}
          data-for={tooltipId}
          data-effect="solid"
          data-place="left"
        >
          {t('partners')}
          {items && (
            <span>
              {' '}
              ({numberOfSelectedItems || items.length}/{items.length})
            </span>
          )}
        </button>

        <div className="shop-partner-list">
          {items && items.map(item => <TogglePartner key={item.id} item={item} />)}

          <ResetButton />
        </div>
      </Tooltip>
    );
  }
}

PartnerFilter.propTypes = {
  numberOfSelectedItems: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    shop: {
      partners: {
        list: { isFetching },
        partnerIds
      }
    }
  } = state;

  return {
    numberOfSelectedItems: partnerIds.length,
    isLoading: isFetching,
    items: getPartners(state)
  };
};

export default withTranslation('Shop/PartnerFilter')(connect(mapStateToProps)(onClickOutside(PartnerFilter)));
