import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { submitAccountData } from 'actions/customer';
import { notifyError } from 'actions/notifications';
import { Input } from 'common';
import { Modal } from 'components/HOC';

import './AddAccountData.less';

export class AddAccountData extends Component {
  constructor(props) {
    super(props);

    const customer = props.customer || {};
    const billingAddress = props.billingAddress || {};

    this.state = {
      address: billingAddress.street,
      plz: billingAddress.postal_code,
      city: billingAddress.city,
      phone: customer.primary_contact ? customer.primary_contact.phone : '',
      employees: customer.number_of_employees,
      size: customer.floor_space
    };
  }

  componentWillReceiveProps(nextProps) {
    const { customer, billingAddress } = nextProps;

    if (!customer) return;

    this.setState(() => ({
      address: billingAddress.street,
      plz: billingAddress.postal_code,
      city: billingAddress.city,
      phone: customer.primary_contact ? customer.primary_contact.phone : '',
      employees: customer.number_of_employees,
      size: customer.floor_space
    }));
  }

  handleChange = ({ target: { name, value } }) => {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { address, plz, city, phone, employees, size } = this.state;

    const accountData = {
      street: address,
      postal_code: plz,
      city,
      contact_phone: phone,
      number_of_employees: employees,
      floor_space: size
    };
    onSubmit(accountData);
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  render() {
    const { isChanging, t } = this.props;
    const { address, plz, city, phone, employees, size } = this.state;

    const sharedProps = {
      className: 'form-control',
      onChange: this.handleChange,
      parentClass: 'form-group',
      type: 'text'
    };

    return (
      <div className="modal-flex">
        <div className="account-data-modal-box">
          <h2 className="headline add-account__headline">{t('headline')}</h2>
          <p className="add-account__subline">{t('subline')}</p>
          <form onSubmit={this.handleSubmit}>
            <fieldset disabled={isChanging}>
              <Input {...sharedProps} label={t('address')} name="address" value={address} />
              <Input {...sharedProps} label={t('plz')} name="plz" value={plz} />
              <Input {...sharedProps} label={t('city')} name="city" value={city} />
              <Input {...sharedProps} label={t('phone')} name="phone" value={phone} />
              <Input {...sharedProps} label={t('employees')} name="employees" value={employees} />
              <Input {...sharedProps} label={t('size')} name="size" value={size} />
              <button type="submit" className={`spo-input submit center ${isChanging ? 'loading-light' : ''}`}>
                {t('submit')}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

AddAccountData.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isChanging: PropTypes.bool,
  isChanged: PropTypes.bool,
  customer: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired
};

const empty = {};
const mapStateToProps = ({ current: { customer } }) => ({
  customer: customer || empty,
  billingAddress: (customer && customer.billing_address) || empty
});
const mapDispatchToProps = {
  onSubmit: submitAccountData,
  showError: notifyError
};

export { AddAccountData as PureComponent };
export default withTranslation('Settings/AddAccountData')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Add Account Data Modal', { className: 'account-data-modal' })(AddAccountData))
);
