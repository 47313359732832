import {
  SHOP_CART_PRODUCT_REMOVE_REQUEST,
  SHOP_CART_PRODUCT_REMOVE_SUCCESS,
  SHOP_CART_PRODUCT_REMOVE_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_PREVIEW } from 'constants/shoppingCart';
import { authCall } from 'api';

export default product =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_PREVIEW,
    meta: { product, quantity: 0 },
    types: [SHOP_CART_PRODUCT_REMOVE_REQUEST, SHOP_CART_PRODUCT_REMOVE_SUCCESS, SHOP_CART_PRODUCT_REMOVE_FAILURE],
    endpoint: `/user/shop/cart/product/${product.id}`,
    method: 'DELETE'
  });
