import {
  SERVICE_EVENTS_REQUEST,
  SERVICE_EVENTS_SUCCESS,
  SERVICE_EVENTS_FAILURE,

  SERVICE_EVENT_FILTERS_SELECT,
  SERVICE_EVENT_FILTERS_DESELECT,
  SERVICE_EVENT_FILTERS_RESET
} from 'constants/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FILTERS_SELECT:
    case SERVICE_EVENT_FILTERS_DESELECT:
    case SERVICE_EVENT_FILTERS_RESET:
      return initialState;

    case SERVICE_EVENTS_REQUEST: {
      const { meta: { dataKey, currentPage } } = action;

      return { ...state,
        [dataKey]: { ...state[dataKey],
          isFetching: true,
          pages: { ...state[dataKey] && state[dataKey].pages,
            [currentPage]: {
              fetchedAt: null
            }
          },
          hasMore: null
        }
      };
    }

    case SERVICE_EVENTS_SUCCESS: {
      const { meta: { dataKey, currentPage }, response } = action;

      return { ...state,
        [dataKey]: { ...state[dataKey],
          isFetching: false,
          pages: { ...state[dataKey].pages,
            [currentPage]: {
              fetchedAt: new Date(),
              items: action.payload.map(item => item.id)
            }
          },
          currentPage,
          hasMore: !!(response && response.status === 206)
        }
      };
    }

    case SERVICE_EVENTS_FAILURE: {
      const { meta: { dataKey } } = action;

      return { ...state,
        [dataKey]: { ...state[dataKey],
          isFetching: false
        }
      };
    }

    default:
      return state;
  }
};
