import { AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE } from 'constants/actionTypes';
import { apiCall } from 'api';

export default (email, password, captcha = '') =>
  apiCall({
    types: [AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE],
    endpoint: '/user/auth',
    method: 'POST',
    body: { email, password, captcha }
  });
