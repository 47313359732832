import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { resetPartners } from 'actions/shop/partners';
import { MissingProductLink } from 'components/Header';

import './EmptyView.less';

const EmptyView = ({ hasFilters, onClickResetFilters, t }) => (
  <div className="empty-view">
    {hasFilters && (
      <div>
        <p className="headline">{t('message')}</p>
        <p className="empty-view-text">{t('filter-active')}</p>
        <button type="button" className="empty-view-reset" onClick={onClickResetFilters}>
          {t('reset-filter')}
        </button>
        <MissingProductLink>{t('missing-product')}</MissingProductLink>
      </div>
    )}

    {!hasFilters && (
      <div className="center">
        <p className="headline">{t('message')}</p>
        <Link className="button empty-view-back" to="/shop/all">
          {t('back')}
        </Link>
      </div>
    )}
  </div>
);

EmptyView.propTypes = {
  hasFilters: PropTypes.bool,
  onClickResetFilters: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { cart },
    partners: { partnerIds }
  }
}) => ({
  hasFilters: !cart.sed && partnerIds.length > 0
});

const mapDispatchToProps = {
  onClickResetFilters: resetPartners
};

export default withTranslation('Shop/Views/EmptyView')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmptyView)
);
