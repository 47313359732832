import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { closeModal } from 'actions/location';
import { openModal, closeModal as trackCloseModal, denyModal, confirmModal } from 'actions/tracking';

import './Modal.less';

const createMapDispatchToProps = label => dispatch => ({
  onOpenModal: () => dispatch(openModal(label)),
  onCloseModal: () => {
    dispatch(closeModal());
    dispatch(trackCloseModal(label));
  },
  onConfirmModal: () => dispatch(confirmModal(label)),
  onDenyModal: () => dispatch(denyModal(label))
});

export default (label, options = {}) => WrappedComponent => {
  const { isClosable = true, className = '' } = options;

  class Modal extends PureComponent {
    constructor(props) {
      super(props);

      this.handleClose = this.handleClose.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
      if (isClosable) window.addEventListener('keydown', this.handleKeyDown);
      this.props.onOpenModal();
    }

    componentWillUnmount() {
      this.unregisterKeyDown();
    }

    unregisterKeyDown() {
      window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleClose() {
      if (isClosable) this.props.onCloseModal();
    }

    handleKeyDown(e) {
      if (e.which === 27) {
        // Esc
        this.unregisterKeyDown();
        this.props.onCloseModal();
      }
    }

    render() {
      const { isLoading, onCloseModal, onConfirmModal, onDenyModal } = this.props;
      const passedProps = omit(this.props, 'onOpenModal', 'onCloseModal', 'onConfirmModal', 'onDenyModal');

      return (
        <div className="modal-wrap">
          <div className="modal-overlay" onClick={this.handleClose} role="button" tabIndex="-1" />

          <div className={`modal ${className} ${isLoading ? 'loading' : ''}`}>
            <WrappedComponent
              {...passedProps}
              closeModal={onCloseModal}
              confirmModal={onConfirmModal}
              denyModal={onDenyModal}
            />

            {isClosable && (
              <div className="modal-closer-con">
                <button type="button" className="modal-closer" onClick={this.handleClose}>
                  &nbsp;
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  Modal.propTypes = {
    isLoading: PropTypes.bool,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onConfirmModal: PropTypes.func.isRequired,
    onDenyModal: PropTypes.func.isRequired
  };

  Modal.defaultProps = {
    isLoading: false
  };

  return connect(
    null,
    createMapDispatchToProps(label)
  )(Modal);
};
