import React, { Component } from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import { BarChart } from 'react-easy-chart';
import { withTranslation } from 'react-i18next';

import './ServiceRequestStats.less';

class ServiceRequestStats extends Component {
  showChartTitle = (data, event) => {
    const { x: title, price: value } = data;
    const rect = event.target.getBoundingClientRect();
    const { offsetLeft, offsetTop } = this.statsSidebar;

    this.chartTooltip.innerHTML = `${title}: ${value}`;
    this.chartTooltip.style.display = 'block';
    this.chartTooltip.style.left = `${rect.left - offsetLeft - 10}px`;
    this.chartTooltip.style.top = `${rect.top - offsetTop - 10}px`;
  };

  hideChartTitle = () => {
    this.chartTooltip.style.display = 'none';
  };

  render() {
    const { t, isOpen } = this.props;
    const chartData = [
      { x: 'Jan', y: 32, color: '#2c94e2', price: '110,10 €' },
      { x: 'Feb', y: 63, color: '#2a9d0eec94e2', price: '350,01 €' },
      { x: 'Mar', y: 97, color: '#2c94e2', price: '520 €' },
      { x: 'Mai', y: 25, color: '#2a9d0eec94e2', price: '100 €' },
      { x: 'Apr', y: 136, color: '#2c94e2', price: '632,45 €' },
      { x: 'Jun', y: 85, color: '#2a9d0eec94e2', price: '510,10 €' },
      { x: 'Jul', y: 73, color: '#2c94e2', price: '450,58 €' }
    ];

    return (
      <div
        className={classNames('service-request-stats', { open: isOpen })}
        ref={ref => {
          this.statsSidebar = ref;
        }}
      >
        <div className="stat-section">
          <h2 className="section-title">{t('total-service-requests')}</h2>

          <div className="section-right">
            <div className="bar-diagram">
              <div
                ref={ref => {
                  this.chartTooltip = ref;
                }}
                className="stat-diagram-tooltip"
                style={{ display: 'none' }}
              />
              <BarChart
                width={275}
                height={150}
                data={chartData}
                mouseOverHandler={this.showChartTitle}
                mouseOutHandler={this.hideChartTitle}
              />

              <div className="months">{chartData && chartData.map(el => <div key={el.x}>{el.x}</div>)} </div>
            </div>
          </div>
        </div>

        <div className="stat-section stat-section-overview">
          <h2 className="section-title">{t('expenses-overview')}</h2>

          <div className="item">
            <b>{t('total-year')}</b>
            <span>32.778,56 €</span>
          </div>
          <div className="item budg">
            <b>{t('budget')}</b>
            <span>
              <i>166%</i>(+ 8.500 €)
            </span>
          </div>
        </div>

        <div className="stat-section stat-section-trend">
          <h2 className="section-title">{t('request-status')}</h2>

          <div className="section-left">
            <div className="trend-number">6</div>
            <div className="trend-description">
              <div className="title">{t('open-requests')}</div>
              <div className="details">
                <span>12.545,78 €</span>
              </div>
            </div>
          </div>

          <div className="section-right">
            <div className="trend-number">3</div>
            <div className="trend-description">
              <div className="title">{t('pending-requests')}</div>
              <div className="details">
                <span>678,56 €</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ServiceRequestStats as PureComponent };
export default pure(withTranslation('ServiceRequests')(ServiceRequestStats));
