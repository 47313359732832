import { SHOP_FAVORITES_REQUEST, SHOP_FAVORITES_SUCCESS, SHOP_FAVORITES_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      shop: {
        favorites: {
          list: { isFetching, fetchedAt }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [SHOP_FAVORITES_REQUEST, SHOP_FAVORITES_SUCCESS, SHOP_FAVORITES_FAILURE],
    endpoint: '/user/shop/favorites',
    method: 'GET'
  });
