import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Elements } from 'react-stripe-elements';

import { PAYMENT_METHOD_BILLING, PAYMENT_METHOD_CREDIT_CARD } from 'constants/paymentMethods';
import { selectCreditCard, selectPaymentMethod } from 'actions/shop/payment';
import { RadioOption } from 'common/Form';
import { htmlId } from 'components/HOC';
import { CreditCard, RemoveCreditCard } from '.';

import './PaymentMethod.less';

export const PaymentMethod = ({
  id,
  creditCards,
  paymentMethods,
  selectedCreditCard,
  selectedMethod,
  onSelectPaymentMethod,
  onSelectCreditCard,
  t
}) => {
  const hasNewCard = !!creditCards.find(cc => cc.isNew);
  const sharedProps = { id, name: 'cc-payment', alignButtonLeft: false };

  return (
    <div className="payment-method">
      <p className="payment-method__headline cart-title">{t('headline')}</p>

      <div className="payment-method__choose">
        {paymentMethods.includes(PAYMENT_METHOD_BILLING) && (
          <RadioOption
            {...sharedProps}
            label={t('invoice')}
            value={PAYMENT_METHOD_BILLING}
            checked={selectedMethod === PAYMENT_METHOD_BILLING}
            onChange={({ target: { value } }) => onSelectPaymentMethod(value)}
          />
        )}

        {creditCards.map(creditCard => (
          <div className="creditcards-available" key={creditCard.id}>
            <RadioOption
              {...sharedProps}
              value={creditCard.id}
              label={`####-####-####-${creditCard.last4}`}
              checked={selectedCreditCard === creditCard.id}
              onChange={({ target: { value } }) => onSelectCreditCard(value)}
            />

            {selectedCreditCard !== creditCard.id && <RemoveCreditCard id={creditCard.id} />}
          </div>
        ))}

        {!hasNewCard && paymentMethods.includes(PAYMENT_METHOD_CREDIT_CARD) && (
          <RadioOption
            {...sharedProps}
            label={t('credit-card')}
            value={PAYMENT_METHOD_CREDIT_CARD}
            checked={!selectedMethod}
            onChange={() => {
              onSelectPaymentMethod();
            }}
          >
            <Elements>
              <CreditCard />
            </Elements>
          </RadioOption>
        )}
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  creditCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      last4: PropTypes.string.isRequired
    })
  ).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCreditCard: PropTypes.string,
  selectedMethod: PropTypes.string,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  onSelectCreditCard: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    payment: {
      selectedMethod,
      methods: paymentMethods,
      creditCards: { list: creditCards, selected: selectedCreditCard }
    }
  }
}) => ({ creditCards, paymentMethods, selectedCreditCard, selectedMethod });

const mapDispatchToProps = {
  onSelectPaymentMethod: selectPaymentMethod,
  onSelectCreditCard: selectCreditCard
};

export default withTranslation('Shop/Cart/Payment')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(htmlId(PaymentMethod))
);
