import React, { Component } from 'react';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { DocumentTitle } from 'common';

import './Analytics.less';

class Analytics extends Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    focusedInput: null
  };

  onFilterDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate,
      endDate
    });
  };

  render() {
    const { t } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <DocumentTitle title={t('page-title')}>
        <div className="analytics-page">
          <div className="page-head">
            <DateRangePicker
              startDatePlaceholderText={t('from')}
              endDatePlaceholderText={t('to')}
              customArrowIcon="-"
              showClearDates
              startDateId="analyticStartDate"
              startDate={startDate}
              endDateId="analyticEndDate"
              endDate={endDate}
              onDatesChange={this.onFilterDatesChange}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              isOutsideRange={() => false}
              displayFormat="DD/MM/YYYY"
              small
            />

            <button type="button" className="button button-blue btn-download">
              {t('download-csv')}
            </button>
          </div>
          <iframe
            frameBorder="0"
            width="100%"
            title="kpi"
            src="https://share.geckoboard.com/dashboards/SS5C3R6CAM35I7BX"
          />
        </div>
      </DocumentTitle>
    );
  }
}

export default pure(withTranslation('Analytics')(Analytics));
