import { SHOP_PRODUCT_REQUEST, SHOP_PRODUCT_SUCCESS, SHOP_PRODUCT_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default productId =>
  authCall({
    shouldFetch: ({
      shop: {
        products: {
          single: { [productId]: { isFetching, fetchedAt } = {} }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { productId },
    types: [SHOP_PRODUCT_REQUEST, SHOP_PRODUCT_SUCCESS, SHOP_PRODUCT_FAILURE],
    endpoint: `/user/shop/product/${productId}`,
    method: 'GET'
  });
