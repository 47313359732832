import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { IMAGE_SHOP_PARTNER } from 'constants/images';

const PartnerImage = ({ item }) => (
  <div
    className="partner-logo"
    style={{
      background: `url(${item.image_url || IMAGE_SHOP_PARTNER}) no-repeat center center #fff`,
      backgroundSize: '75% auto'
    }}
  />
);

PartnerImage.propTypes = {
  item: PropTypes.object.isRequired
};

export default pure(PartnerImage);
