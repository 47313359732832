import React from 'react';
import PropTypes from 'prop-types';

const Event = ({ item }) => (
  <div className="header-module__event">
    {item.start_date.format('HH:mm')} - {item.end_date.format('HH:mm')} &nbsp;{item.name}
  </div>
);

Event.propTypes = {
  item: PropTypes.object.isRequired
};

export { Event as PureComponent };
export default Event;

