import { SHOP_PARTNERS_REQUEST, SHOP_PARTNERS_SUCCESS, SHOP_PARTNERS_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      shop: {
        partners: {
          list: { isFetching, fetchedAt }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [SHOP_PARTNERS_REQUEST, SHOP_PARTNERS_SUCCESS, SHOP_PARTNERS_FAILURE],
    endpoint: '/user/shop/partners',
    method: 'GET'
  });
