import {
  SHOP_CART_CHECKOUT_CANCEL_REQUEST,
  SHOP_CART_CHECKOUT_CANCEL_SUCCESS,
  SHOP_CART_CHECKOUT_CANCEL_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT && !state.shop.cart.isCancelling,
    types: [
      SHOP_CART_CHECKOUT_CANCEL_REQUEST,
      SHOP_CART_CHECKOUT_CANCEL_SUCCESS,
      SHOP_CART_CHECKOUT_CANCEL_FAILURE
    ],
    endpoint: '/user/shop/cart',
    method: 'GET'
  });
