import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getDisplayName } from 'utils/HOC';
import { updateIntensiveCleaningObject, submitIntensiveCleaning } from 'actions/serviceEvents/index';

const initialState = {
  values: {},
  invalidFields: {}
};

const IntensiveCleaningContainer = WrappedComponent => {
  class Container extends React.Component {
    valueTypes = {
      bool: [
        'office',
        'office_collect_dishes',
        'office_deep_cleaning',
        'office_move_furniture',
        'office_clean_equipment',
        'bathroom',
        'bathroom_deep_cleaning',
        'kitchen',
        'kitchen_deep_cleaning',
        'kitchen_move_furniture',
        'entrance',
        'entrance_deep_cleaning',
        'entrance_move_furniture',
        'other',
        'irregular_hours'
      ],
      number: ['number_of_employees', 'office_size']
    };

    invalidFieldClassNameMapping = {
      all_cleaning_areas: '#block--cleaning-areas-headline',
      floor_space: '#block--floor_space',
      number_of_employees: '#block--number_of_employees',
      office_cleaning_area_size: '#block--office_cleaning_area_size',
      office_floor_type: '#block--office_floor_type',
      bathroom_cleaning_area_size: '#block--bathroom_cleaning_area_size',
      kitchen_cleaning_area_size: '#block--kitchen_cleaning_area_size',
      kitchen_floor_type: '#block--kitchen_floor_type',
      entrance_cleaning_area_size: '#block--entrance_cleaning_area_size',
      entrance_floor_type: '#block--entrance_floor_type',
      other_details: '#block--other_details',
      date_time: '#block--date_time',
      date1: '#block--date1'
    };

    state = initialState;

    static displayName = `Container(${getDisplayName(WrappedComponent)})`;

    static propTypes = {};

    // set default values with data from redux when the componentWillMount
    componentWillMount() {
      this.setData(this.props.values);
    }

    shouldComponentUpdate(nextProps, nextState) {
      return (
        Object.keys(nextState.values).some(key => this.props.values[key] !== nextState.values[key]) ||
        JSON.stringify(this.state.invalidFields) !== JSON.stringify(nextState.invalidFields) ||
        this.props.intensiveCleaningSubmit.isSuccessful !== nextProps.intensiveCleaningSubmit.isSuccessful
      );
    }

    componentWillUpdate(nextProps, nextState) {
      this.props.updateIntensiveCleaningObject({
        ...nextState.values
      });
    }

    componentDidUpdate() {
      const { intensiveCleaningSubmit } = this.props;

      if (intensiveCleaningSubmit.isSuccessful) {
        this.props.history.push('/cleaning/intensive-cleaning/step-3');
      }
    }

    // convenience method for direct input events. for use as onChange handler
    // example: <input name="fieldname" onChange={onChange} />
    onChange = e => {
      this.setValue(e.target.name, e.target.value);
    };

    // manually set the value for a certain property
    // example: <DateTimeGroup onChange={ date => setValue('myDate', date.isoDate) } />
    setValue = (prop, value) => {
      // Reset validation as soon as a value is changed
      this.resetValidation();
      this.setState(state => ({
        values: {
          ...state.values,
          [prop]: this.convertValue(prop, value)
        }
      }));
    };

    // populates the container state with data from redux store
    setData = obj => {
      this.setState(() => ({
        values: {
          ...obj
        }
      }));
    };

    handleSubmit = () => {
      const val = this.state.values;
      const intensiveDetails = [];

      if (val.office) {
        intensiveDetails.push({
          cleaning_area: 'office',
          cleaning_area_size: val.office_cleaning_area_size,
          collect_dishes: val.office_collect_dishes,
          floor_type: val.office_floor_type,
          deep_cleaning: val.office_deep_cleaning,
          move_furniture: val.office_move_furniture,
          clean_equipment: val.office_clean_equipment
        });
      }
      if (val.bathroom) {
        intensiveDetails.push({
          cleaning_area: 'bathroom',
          cleaning_area_size: val.bathroom_cleaning_area_size,
          deep_cleaning: val.bathroom_deep_cleaning
        });
      }
      if (val.kitchen) {
        intensiveDetails.push({
          cleaning_area: 'kitchen',
          cleaning_area_size: val.kitchen_cleaning_area_size,
          floor_type: val.kitchen_floor_type,
          deep_cleaning: val.kitchen_deep_cleaning,
          move_furniture: val.kitchen_move_furniture
        });
      }
      if (val.entrance) {
        intensiveDetails.push({
          cleaning_area: 'entrance',
          cleaning_area_size: val.entrance_cleaning_area_size,
          floor_type: val.entrance_floor_type,
          deep_cleaning: val.entrance_deep_cleaning,
          move_furniture: val.entrance_move_furniture
        });
      }
      if (val.other) {
        intensiveDetails.push({
          cleaning_area: 'other',
          details: val.other_details
        });
      }

      const dates = [];
      if (val.date1) {
        dates.push(val.date1);
      }
      if (val.date2) {
        dates.push(val.date2);
      }
      if (val.date3) {
        dates.push(val.date3);
      }

      const payload = {
        floor_space: val.floor_space,
        number_of_employees: val.number_of_employees,
        date_time: val.date_time,
        irregular_hours: val.irregular_hours,
        dates,
        intensive_details: intensiveDetails
      };

      this.props.submitIntensiveCleaning(payload);
    };

    markFieldAsInvalid = fieldName => {
      this.setState(state => ({
        invalidFields: {
          ...state.invalidFields,
          [fieldName]: true
        }
      }));

      const invalidNode = document.querySelector(this.invalidFieldClassNameMapping[fieldName]);

      if (invalidNode) {
        invalidNode.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };

    resetValidation = fieldName => {
      if (!fieldName) {
        return this.setState(() => ({
          invalidFields: {}
        }));
      }
      return this.setState(state => ({
        invalidFields: {
          ...state.invalidFields,
          [fieldName]: false
        }
      }));
    };

    validateStep1 = (state = this.state) => {
      // General Fields
      if (Number.isNaN(parseInt(state.values.floor_space, 10)) || parseInt(state.values.floor_space, 10) < 50) {
        this.markFieldAsInvalid('floor_space');
        return false;
      }

      if (
        Number.isNaN(parseInt(state.values.number_of_employees, 10)) ||
        !(state.values.number_of_employees % 1 === 0) ||
        parseInt(state.values.number_of_employees, 10) < 5
      ) {
        this.markFieldAsInvalid('number_of_employees');
        return false;
      }

      // Cleaning Area Switches
      if (state.values.office !== true && state.values.office !== false) {
        this.markFieldAsInvalid('office');
        return false;
      }
      if (state.values.bathroom !== true && state.values.bathroom !== false) {
        this.markFieldAsInvalid('bathroom');
        return false;
      }
      if (state.values.kitchen !== true && state.values.kitchen !== false) {
        this.markFieldAsInvalid('kitchen');
        return false;
      }
      if (state.values.entrance !== true && state.values.entrance !== false) {
        this.markFieldAsInvalid('entrance');
        return false;
      }
      if (state.values.other !== true && state.values.other !== false) {
        this.markFieldAsInvalid('other');
        return false;
      }

      // Cleaning Areas Internal Fields
      if (state.values.office === true) {
        // check work space size only if "office cleaning" is set to "yes"
        if (
          Number.isNaN(parseInt(state.values.office_cleaning_area_size, 10)) ||
          !(state.values.office_cleaning_area_size % 1 === 0) ||
          parseInt(state.values.office_cleaning_area_size, 10) < 1
        ) {
          this.markFieldAsInvalid('office_cleaning_area_size');
          return false;
        }

        if (!state.values.office_floor_type) {
          this.markFieldAsInvalid('office_floor_type');
          return false;
        }

        if (state.values.office_collect_dishes !== true && state.values.office_collect_dishes !== false) {
          this.markFieldAsInvalid('office_collect_dishes');
          return false;
        }

        if (state.values.office_deep_cleaning !== true && state.values.office_deep_cleaning !== false) {
          this.markFieldAsInvalid('office_deep_cleaning');
          return false;
        }

        if (state.values.office_deep_cleaning === true) {
          if (state.values.office_move_furniture !== true && state.values.office_move_furniture !== false) {
            this.markFieldAsInvalid('office_move_furniture');
            return false;
          }
        }

        if (state.values.office_clean_equipment !== true && state.values.office_clean_equipment !== false) {
          this.markFieldAsInvalid('office_clean_equipment');
          return false;
        }
      }

      if (state.values.bathroom === true) {
        if (
          Number.isNaN(parseInt(state.values.bathroom_cleaning_area_size, 10)) ||
          !(state.values.bathroom_cleaning_area_size % 1 === 0) ||
          parseInt(state.values.bathroom_cleaning_area_size, 10) < 1
        ) {
          this.markFieldAsInvalid('bathroom_cleaning_area_size');
          return false;
        }

        if (state.values.bathroom_deep_cleaning !== true && state.values.bathroom_deep_cleaning !== false) {
          this.markFieldAsInvalid('bathroom_deep_cleaning');
          return false;
        }
      }

      if (state.values.kitchen === true) {
        if (
          Number.isNaN(parseInt(state.values.kitchen_cleaning_area_size, 10)) ||
          !(state.values.kitchen_cleaning_area_size % 1 === 0) ||
          parseInt(state.values.kitchen_cleaning_area_size, 10) < 1
        ) {
          this.markFieldAsInvalid('kitchen_cleaning_area_size');
          return false;
        }

        if (!state.values.kitchen_floor_type) {
          this.markFieldAsInvalid('kitchen_floor_type');
          return false;
        }

        if (state.values.kitchen_deep_cleaning !== true && state.values.kitchen_deep_cleaning !== false) {
          this.markFieldAsInvalid('kitchen_deep_cleaning');
          return false;
        }

        if (state.values.kitchen_deep_cleaning === true) {
          if (state.values.kitchen_move_furniture !== true && state.values.kitchen_move_furniture !== false) {
            this.markFieldAsInvalid('kitchen_move_furniture');
            return false;
          }
        }
      }

      if (state.values.entrance === true) {
        if (
          Number.isNaN(parseInt(state.values.entrance_cleaning_area_size, 10)) ||
          !(state.values.entrance_cleaning_area_size % 1 === 0) ||
          parseInt(state.values.entrance_cleaning_area_size, 10) < 1
        ) {
          this.markFieldAsInvalid('entrance_cleaning_area_size');
          return false;
        }

        if (!state.values.entrance_floor_type) {
          this.markFieldAsInvalid('entrance_floor_type');
          return false;
        }

        if (state.values.entrance_deep_cleaning !== true && state.values.entrance_deep_cleaning !== false) {
          this.markFieldAsInvalid('entrance_deep_cleaning');
          return false;
        }

        if (state.values.entrance_deep_cleaning === true) {
          if (state.values.entrance_move_furniture !== true && state.values.entrance_move_furniture !== false) {
            this.markFieldAsInvalid('entrance_move_furniture');
            return false;
          }
        }
      }

      if (state.values.other === true) {
        if (!state.values.other_details) {
          this.markFieldAsInvalid('other_details');
          return false;
        }
      }

      // at least one cleaning area should be defined
      if (
        state.values.office === false &&
        state.values.bathroom === false &&
        state.values.kitchen === false &&
        state.values.entrance === false &&
        state.values.other === false
      ) {
        if (!state.values.other_details) {
          this.markFieldAsInvalid('all_cleaning_areas');
          return false;
        }
      }

      this.resetValidation();
      return true;
    };

    validateStep2 = (state = this.state) => {
      if (!state.values.date_time) {
        this.markFieldAsInvalid('date_time');
        return false;
      }

      if (state.values.irregular_hours !== true && state.values.irregular_hours !== false) {
        this.markFieldAsInvalid('irregular_hours');
        return false;
      }

      if (!state.values.date1) {
        this.markFieldAsInvalid('date1');
        return false;
      }

      this.resetValidation();
      return true;
    };

    // casts values to their proper type to avoid unnecessary re-renders to due type inconsitency
    convertValue = (prop, value) => {
      if (this.valueTypes.bool.includes(prop)) {
        if (value === 'false' || value === '0') {
          return false;
        }
        return Boolean(value);
      }
      if (this.valueTypes.number.includes(prop)) {
        // leads currently to leading zero that can not be removed
        // return Number(value);
        return value;
      }
      return value;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          values={this.state.values}
          invalidFields={this.state.invalidFields}
          validateStep1={this.validateStep1}
          validateStep2={this.validateStep2}
          setValue={this.setValue}
          setData={this.setData}
          getData={this.getData}
          onChange={this.onChange}
          onSubmit={this.handleSubmit}
        />
      );
    }
  }

  const mapStateToProps = ({ serviceEvents: { intensiveCleaningRequest, intensiveCleaningSubmit } }) => ({
    values: intensiveCleaningRequest,
    intensiveCleaningSubmit
  });

  const mapDispatchToProps = {
    updateIntensiveCleaningObject,
    submitIntensiveCleaning
  };

  return withTranslation('Services/IntensiveCleaning')(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))
  );
};

export default IntensiveCleaningContainer;
