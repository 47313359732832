import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setLanguage } from 'actions/i18n';
import { Select } from 'common/Form';

import './ChangeLanguage.less';

export const ChangeLanguage = ({ onChange, language, t }) => {
  const languages = [{ name: t('german'), value: 'de' }, { name: t('english'), value: 'en' }];
  return (
    <div className="settings-language">
      <Select
        label={t('fieldname')}
        name="country_code"
        onChange={({ target: { value } }) => onChange(value)}
        options={languages}
        value={language}
        parentClass="form-group"
      />
    </div>
  );
};

ChangeLanguage.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ i18n: { language } }) => ({ language });

const mapDispatchToProps = {
  onChange: setLanguage
};

export default withTranslation('Settings/ChangeLanguage')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangeLanguage)
);
