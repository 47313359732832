import { FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE } from 'constants/actionTypes';
import { X_TOTAL_COUNT } from '../../constants/headers';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    numberOfItems: 0,
    showPerPage: 5,
    items: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload,
        numberOfItems: parseInt(action.response && action.response.headers.get(X_TOTAL_COUNT), 10) || 0
      };

    case FETCH_ORDERS_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
