import React from 'react';
import { withTranslation } from 'react-i18next';
import { pure } from 'recompose';
import { Formik, Form, Field } from 'formik';
import { Button, Textarea } from '@spone/ui';
import { object, string } from 'yup';

import './OfferRejectModal.less';

export const OfferRejectModal = ({ t, handleRejectOffer }) => {
  const validationSchema = object({
    comment: string().required(t('Form:required'))
  });

  return (
    <div className="offer-reject-modal">
      <p>{t('OfferReview/OfferRejectModal:modal-text-1')}</p>
      <p>{t('OfferReview/OfferRejectModal:modal-text-2')}</p>

      <Formik initialValues={{ comment: '' }} onSubmit={handleRejectOffer} validationSchema={validationSchema}>
        <Form>
          <Field
            component={Textarea}
            rows={4}
            label={t('OfferReview/OfferRejectModal:comment-label')}
            placeholder={t('OfferReview/OfferRejectModal:comment-placeholder')}
            name="comment"
          />

          <div className="form-buttons">
            <Button type="submit">{t('OfferReview/OfferRejectModal:submit')}</Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default pure(withTranslation(['OfferReview/OfferRejectModal', 'Form'])(OfferRejectModal));
