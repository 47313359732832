import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { MemberList } from '../..';

import './MemberSidebar.less';

const MemberSidebar = ({
  members,
  showForm,
  showMemberForm,
  searchHandler,
  editMember,
  t,
  searchValue,
  searchEmpty
}) => (
  <div className={classNames('members-list', { active: showForm })}>
    <div className="title-wrapper">
      <h2 className="title">{t('page-title')}</h2>

      <button type="button" className="button button-blue create-member-btn" onClick={showMemberForm}>
        {t('create')}
      </button>
    </div>

    <div className="form-elements-group search-member">
      <input
        type="text"
        className="form-input"
        placeholder={t('search-team')}
        onChange={searchHandler}
        value={searchValue}
      />
      <button className="search-submit-btn" type="button" />
    </div>

    <MemberList members={members} editMember={editMember} showMemberForm={showMemberForm} searchEmpty={searchEmpty} />
  </div>
);

export { MemberSidebar as PureComponent };
export default withTranslation('Members')(MemberSidebar);
