import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Header } from '..';
import { Sum, Discount } from '../Common';
import { SubCart, CancelButton, PaymentButton, CouponForm } from '.';

import './Checkout.less';

const Checkout = ({ subCarts, t }) => (
  <div className="cart-flex cart-animation checkout">
    <Header />

    <div className="cart-inside-container">
      <div className="cart-inside">
        {subCarts.map(item => (
          <SubCart key={item.id} item={item} />
        ))}
      </div>
    </div>

    <div className="cart-all-combined">
      <div>
        <CouponForm />
        <Discount />
        <Sum />
        <div className="cart-all-combined-flex">
          <CancelButton />
          <PaymentButton />
        </div>
        <Link to="/support" className="cart-help">
          {t('need-help')}
        </Link>
      </div>
    </div>
  </div>
);

Checkout.propTypes = {
  subCarts: PropTypes.array,
  t: PropTypes.func.isRequired
};

Checkout.defaultProps = {
  subCarts: []
};

const mapStateToProps = ({
  shop: {
    cart: {
      cart: { sub_carts: subCarts }
    }
  }
}) => ({ subCarts });

export default withTranslation('Shop/Cart/Default')(connect(mapStateToProps)(Checkout));
