import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { Select, Input, RadioButton } from 'common/Form';
import { submitWindowCleaning, submitWindowCleaningOversized } from 'actions/serviceEvents/index';
import { windowHObj, windowWObj } from '../../Config';

import './WindowCleaning.less';

const dateObj = [
  { time: 'time1', date: 'date1', focused: 'focused1' },
  { time: 'time2', date: 'date2', focused: 'focused2' },
  { time: 'time3', date: 'date3', focused: 'focused3' }
];

class WindowCleaning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      officeSize: '50',
      mode: 'add',
      panels: [],
      floorLevel: false,
      doubleBox: false,
      windowExceed: false,
      windowFrames: false,
      panesError: false,
      startDate: null,
      reqStartDate: null,
      startTime: null,
      reqStartTime: null,
      visit_dates: [],
      date1: null,
      date2: null,
      date3: null,
      time1: null,
      time2: null,
      time3: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderListOfPanes = this.renderListOfPanes.bind(this);
  }

  componentDidUpdate() {
    const { history, windowCleaning, windowCleaningOversized } = this.props;

    if (windowCleaning.isSuccessful || windowCleaningOversized.isSuccessful) {
      history.push('/cleaning/cleaning-calc/step-3');
    }
  }

  onTimeChange(e, value) {
    if (e) {
      this.setState({
        [value]: e.format('HH:mm')
      });
    } else {
      this.setState({
        [value]: null
      });
    }
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  handleSubmit() {
    const { reqStartDate, reqStartTime } = this.state;
    const { date1, date2, date3, time1, time2, time3 } = this.state;
    const visitDates = [];
    const startThis = moment(`${reqStartDate} ${reqStartTime}`).toISOString();

    if (date1 && time1) {
      const newFormat = moment(`${date1.format('YYYY-MM-DD')} ${time1}`).toISOString();
      visitDates.push(newFormat);
    }
    if (date2 && time2) {
      const newFormat = moment(`${date2.format('YYYY-MM-DD')} ${time2}`).toISOString();
      visitDates.push(newFormat);
    }
    if (date3 && time3) {
      const newFormat = moment(`${date3.format('YYYY-MM-DD')} ${time3}`).toISOString();
      visitDates.push(newFormat);
    }

    if (this.state.officeSize >= 500) {
      this.props.submitWindowCleaningOversized({
        officeSize: this.state.officeSize,
        visitDates
      });
    } else {
      this.props.submitWindowCleaning({
        officeSize: this.state.officeSize,
        panels: this.state.panels,
        startThis,
        windowExceed: this.state.windowExceed,
        windowFrames: this.state.windowFrames
      });
    }
  }

  addPane(id) {
    const { quantity, height, width, windowPanes, floorLevel, doubleBox } = this.state;
    const newArray = this.state.panels.filter(panel => panel.id !== id);

    if (!quantity || !(quantity % 1 === 0) || !height || !width || !windowPanes || !(windowPanes % 1 === 0)) {
      this.setState({
        panesError: true
      });
      return;
    }

    const obj = {
      quantity,
      height,
      width,
      number_of_panes: windowPanes,
      is_floor_to_ceiling_window: floorLevel,
      is_double_box_window: doubleBox,
      id: `${height}-${quantity}-${width}-${windowPanes}-${floorLevel}-${doubleBox}${new Date().getTime()}`
    };

    this.setState(prevState => ({
      panels: [...prevState.panels, obj],
      panesError: false
    }));

    if (id) {
      this.setState({
        panels: [...newArray, obj]
      });

      this.setState({
        mode: 'add',
        panesError: false
      });
    }
  }

  editPanel(id) {
    this.setState(prevState => {
      const actualPane = prevState.panels.filter(obj => obj.id === id)[0];

      return {
        mode: id,
        quantity: actualPane.quantity,
        height: actualPane.height,
        width: actualPane.width,
        windowPanes: actualPane.windowPanes,
        floorLevel: actualPane.floorLevel,
        doubleBox: actualPane.doubleBox
      };
    });
  }

  removePanel(id) {
    const { panels } = this.state;
    const rArray = panels.filter(obj => obj.id !== id);

    this.setState({
      panels: rArray,
      mode: 'add'
    });
  }

  renderPanel(id) {
    const { width, height, panesError } = this.state;
    const { t } = this.props;

    return (
      <fieldset className={`cleaning-services__boxed ${id && 'editable'}`} id="window">
        <div className="window-fields">
          <Input
            parentClass="window-wrapper"
            placeholder={t('window-number')}
            type="number"
            data-value="quantity"
            onChange={this.handleChange}
          />
          <Select
            id="window-width"
            className="window-width"
            parentClass="window-wrapper"
            options={windowWObj}
            data-value="width"
            value={width}
            dataType={t('window-width')}
          />
          <Select
            id="window-height"
            className="window-height"
            parentClass="window-wrapper"
            options={windowHObj}
            data-value="height"
            value={height}
            dataType={t('window-height')}
          />
        </div>
        <label className="cleaning-services__label" htmlFor="floor-level">
          {t('window-floor')}
        </label>
        <div className="window-radio-group" id="floor-level">
          <RadioButton name="floor-level" label={t('yes')} onChange={() => this.setState({ floorLevel: true })} />
          <RadioButton
            name="floor-level"
            label={t('no')}
            onChange={() => this.setState({ floorLevel: false })}
            defaultChecked
          />
        </div>
        <label className="cleaning-services__label" htmlFor="floor-level">
          {t('window-panes')}
        </label>
        <div className="cleaning-services__input-con">
          <Input
            parentClass="window-panes"
            placeholder={t('window-panes-nr')}
            type="number"
            data-value="windowPanes"
            onChange={this.handleChange}
          />
        </div>
        <label className="cleaning-services__label" htmlFor="window-double-case">
          {t('window-double')}
        </label>
        <div className="window-radio-group" id="window-double-case">
          <RadioButton name="window-case" label={t('yes')} onClick={() => this.setState({ doubleBox: true })} />
          <RadioButton
            name="window-case"
            label={t('no')}
            onClick={() => this.setState({ doubleBox: false })}
            defaultChecked
          />
        </div>
        {panesError && <span className="error">{t('error-window')}</span>}
        {!id && (
          <button type="button" className="cleaning-services__window-add" onClick={() => this.addPane()}>
            {t('window-add')}
          </button>
        )}
        {id && (
          <button type="button" className="cleaning-services__window-add editable" onClick={() => this.addPane(id)}>
            {t('window-edit')}
          </button>
        )}
      </fieldset>
    );
  }

  renderListOfPanes() {
    const { t } = this.props;
    const { mode, panels } = this.state;

    return panels.map(obj => (
      <div className="parent" key={obj.id}>
        <div className="panes-item">
          <div className="text-group">
            <ul>
              <li>{`${obj.quantity} ${t('windows')} (${obj.height} x ${obj.width})`}</li>
              <li>{`${obj.number_of_panes} ${t('panes')}`}</li>
              {obj.is_double_box_window && <li>{t('double-windows')}</li>}
              {obj.is_floor_to_ceiling_window ? <li>{t('floor-window')}</li> : ''}
            </ul>
          </div>
          <div className="icon-group">
            {/* <button onClick={() => this.editPanel(obj.id)} className="icon-pencil" /> */}
            <button type="button" onClick={() => this.removePanel(obj.id)} className="icon-trash" />
          </div>
        </div>
        {mode === obj.id && this.renderPanel(obj.id)}
      </div>
    ));
  }

  render() {
    const { t } = this.props;
    const { officeSize, panels, mode } = this.state;
    const coloredWidth = (officeSize - 50) / 4.5;
    return (
      <div
        onChange={e => this.setState({ [e.target.dataset.value]: e.target.value })}
        className="pad cleaning-services cleaning-windows"
      >
        <div className="cleaning-services__banner">
          <div className="cleaning-services__icon" />
          <div className="cleaning-services__description">
            <h2>{t('title')}</h2>
            <p>{t('description')}</p>
          </div>
        </div>
        <div className="cleaning-services__content">
          <label className="cleaning-services__label" htmlFor="slider">
            {t('office-size')}
          </label>
          <p className="facility-size-output">
            {officeSize > 499 && t('more')} {officeSize} m<sup>2</sup>
          </p>

          <div className="relative">
            <Input
              id="slider"
              onChange={e => this.setState({ officeSize: e.target.value })}
              value={officeSize || '50'}
              min="50"
              max="500"
              data-value="officeSize"
              step="50"
              type="range"
            />
            <div className="range-colored" style={{ width: `${coloredWidth}%` }} />
          </div>
          {officeSize < 499 && (
            <div key={4}>
              <label className="cleaning-services__label" htmlFor="window">
                {t('window-label')}
              </label>
              {panels && this.renderListOfPanes()}
              {mode === 'add' && this.renderPanel()}
              <label className="cleaning-services__label" htmlFor="window-exceed">
                {t('window-too-high')}
              </label>
              <div className="window-radio-group" id="window-exceed">
                <RadioButton
                  name="window-exceed-radio"
                  label={t('yes')}
                  onClick={() => this.setState({ windowExceed: true })}
                />
                <RadioButton
                  className="bibibi"
                  name="window-exceed-radio"
                  label={t('no')}
                  onClick={() => this.setState({ windowExceed: false })}
                  defaultChecked
                />
              </div>
              <label className="cleaning-services__label" htmlFor="window-frames-con">
                {t('window-frames')}
              </label>
              <div className="window-radio-group" id="window-frames-con">
                <RadioButton
                  name="window-frames"
                  label={t('yes')}
                  onClick={() => this.setState({ windowFrames: true })}
                />
                <RadioButton
                  name="window-frames"
                  label={t('no')}
                  onClick={() => this.setState({ windowFrames: false })}
                  defaultChecked
                />
              </div>

              <label className="cleaning-services__label" htmlFor="datetime">
                {t('date-cleaning')}
              </label>
              <div>
                <fieldset id="datetime">
                  <div className="cleaning-services__times">
                    <SingleDatePicker
                      placeholder={t('date-placeholder')}
                      id="date"
                      date={this.state.startDate}
                      onDateChange={newDate =>
                        this.setState({
                          startDate: newDate,
                          reqStartDate: moment(newDate).format('YYYY-MM-DD')
                        })
                      }
                      showClearDate
                      numberOfMonths={1}
                      focused={this.state.focused}
                      onFocusChange={({ focused }) => this.setState({ focused })}
                      openDirection="up"
                    />
                    <TimePicker
                      id="time"
                      showSecond={false}
                      placeholder={t('time-placeholder')}
                      className="cleaning-services__timepick"
                      onChange={e =>
                        this.setState({
                          startTime: e,
                          reqStartTime: moment(e).format('H:MM')
                        })
                      }
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          )}
          {officeSize && officeSize > 499 && (
            <div>
              <label className="cleaning-services__label" htmlFor="date1">
                {t('date-visit')}
              </label>
              <div className="cleaning-services__date-group">
                {dateObj.map(date => (
                  <div className="cleaning-services__single-date" key={date.date}>
                    <div className="cleaning-services__times">
                      <SingleDatePicker
                        placeholder={t('date-placeholder')}
                        id={date.date}
                        date={this.state[date.date]}
                        onDateChange={newDate => this.setState({ [date.date]: newDate })}
                        showClearDate
                        numberOfMonths={1}
                        focused={this.state[date.focused]}
                        onFocusChange={({ focused }) =>
                          this.setState({
                            [date.focused]: focused
                          })
                        }
                        hideKeyboardShortcutsPanel
                        openDirection="up"
                      />
                      <TimePicker
                        id={date.time}
                        showSecond={false}
                        placeholder={t('time-placeholder')}
                        className="cleaning-services__timepick"
                        onChange={e => this.onTimeChange(e, date.time)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="cleaning-services__buttongroup">
            <Link className="cleaning-services__button" to="/cleaning/cleaning-menu">
              {t('cancel')}
            </Link>
            <button type="submit" onClick={() => this.handleSubmit()} className="cleaning-services__submit">
              {t('submit')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

WindowCleaning.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { windowCleaning, windowCleaningOversized } }) => ({
  windowCleaning,
  windowCleaningOversized
});

export default withTranslation('Services/WindowCleaning')(
  withRouter(
    connect(
      mapStateToProps,
      { submitWindowCleaningOversized, submitWindowCleaning }
    )(WindowCleaning)
  )
);
