import { hasError } from 'utils';
import { AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE } from 'constants/actionTypes';
import { CAPTCHA_REQUIRED, CAPTCHA_INVALID } from '../../constants/errors';

export default (
  state = {
    isFetching: false,
    isCaptchaRequired: false
  },
  action
) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return { ...state, isFetching: true };

    case AUTH_LOGIN_SUCCESS:
      return { ...state, isFetching: false, isCaptchaRequired: false };

    case AUTH_LOGIN_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isCaptchaRequired: hasError(action.payload, CAPTCHA_REQUIRED, CAPTCHA_INVALID)
      };
    }

    default:
      return state;
  }
};
