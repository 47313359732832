import {
  SHOP_CART_PRODUCT_ADD_REQUEST,
  SHOP_CART_PRODUCT_ADD_SUCCESS,
  SHOP_CART_PRODUCT_ADD_FAILURE,
  SHOP_CART_PRODUCT_REMOVE_REQUEST,
  SHOP_CART_PRODUCT_REMOVE_SUCCESS,
  SHOP_CART_PRODUCT_REMOVE_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_PREVIEW } from 'constants/shoppingCart';
import { authCall } from 'api';
import { RRule } from 'utils';

export default (product, q = 1, isSubscribed = false) => {
  const quantity = parseInt(q, 10) || 1;
  const partnerId = product.partner_id;
  const rrule = isSubscribed ? new RRule({ freq: RRule.WEEKLY }) : undefined;

  return authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_PREVIEW,
    meta: { product, quantity, partnerId },
    types:
            quantity > 0
              ? [SHOP_CART_PRODUCT_ADD_REQUEST, SHOP_CART_PRODUCT_ADD_SUCCESS, SHOP_CART_PRODUCT_ADD_FAILURE]
              : [
                SHOP_CART_PRODUCT_REMOVE_REQUEST,
                SHOP_CART_PRODUCT_REMOVE_SUCCESS,
                SHOP_CART_PRODUCT_REMOVE_FAILURE
              ],
    endpoint: `/user/shop/cart/product/${product.id}/change`,
    method: 'PUT',
    body: {
      quantity,
      rrule,
      partner_id: partnerId
    }
  });
};
