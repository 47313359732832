import { createSelector } from 'reselect';

const getFavorites = state => state.shop.favorites.list.items;
const getProducts = state => state.shop.products.entities;
const getPartnerIds = state => state.shop.partners.partnerIds;

export default createSelector(
  [getFavorites, getProducts, getPartnerIds], (favorites, products, partnerIds) =>
    favorites.map(productId => products[productId])
      .filter(product => !!product)
      .filter(product => (partnerIds.length > 0 ? partnerIds.includes(product.partner_id) : true))
      .slice(0, 25));
