const getHead = () => document.getElementsByTagName('head')[0];

const createScript = src => {
  const head = getHead();
  if (!head) return null;

  const script = document.createElement('script');
  script.src = src;
  script.async = 1;

  head.appendChild(script);
  return script;
};

const createStyle = href => {
  const head = getHead();
  if (!head) return null;

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;

  head.appendChild(link);
  return link;
};

export { createScript, createStyle };
