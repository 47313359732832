import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ShapedOptions.less';

export class ShapedOptions extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, showMoreOptionInputField: false };
  }

  handleClick = (name, selectedOption) => {
    this.setState(() => ({
      selectedOption,
      showMoreOptionInputField: false
    }));

    this.props.handleClick(name, selectedOption);
  };

  handleChange = (e, name) => {
    const { value } = e.target;
    const selectedOption = { value, displayName: value };

    this.setState(() => ({
      selectedOption
    }));

    this.props.handleClick(name, selectedOption);
  };

  handleMoreButtonClick = () => {
    this.setState(() => ({ selectedOption: null, showMoreOptionInputField: true }));
  };

  render() {
    const { showMoreButton, options, className, label, inputOptionPlaceholder, moreButtonTitle, name } = this.props;

    if (!options.length) return null;

    const { selectedOption, showMoreOptionInputField } = this.state;

    return (
      <div className={`shaped-options ${className || ''}`}>
        {label ? <p className="shaped-options-label">{label}</p> : null}
        {options.map(option => (
          <button
            type="button"
            className={`option-button
                                ${selectedOption && selectedOption.value === option.value ? 'active' : ''}`}
            key={`option-${option.value}`}
            onClick={() => this.handleClick(name, option)}
          >
            {option.displayName}
          </button>
        ))}
        {showMoreButton ? (
          <button
            type="button"
            className={`button-more ${showMoreOptionInputField ? 'active' : ''}`}
            onClick={this.handleMoreButtonClick}
          >
            {moreButtonTitle}
          </button>
        ) : null}
        {showMoreOptionInputField ? (
          <input
            type="number"
            min="1"
            placeholder={inputOptionPlaceholder || ''}
            onChange={e => this.handleChange(e, name)}
          />
        ) : null}
      </div>
    );
  }
}

ShapedOptions.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  inputOptionPlaceholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  showMoreButton: PropTypes.bool.isRequired,
  moreButtonTitle: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default ShapedOptions;
