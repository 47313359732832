import MobileDetect from 'mobile-detect';

let isMobile = null;

export default () => {
  if (isMobile === null) {
    const md = new MobileDetect(window.navigator.userAgent);
    isMobile = md.mobile() || md.tablet() || md.phone();
  }

  return isMobile;
};
