import React, { Component } from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';

class IssueSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchForm: false,
      search: ''
    };
  }

  toggleSearchForm = e => {
    e.preventDefault();
    const { showSearchForm, search } = this.state;

    if (showSearchForm) {
      this.props.searchIssues({ search });
    } else {
      this.setState(
        () => ({
          showSearchForm: true
        }),
        () => {
          this.searchInputRef.focus();
        }
      );
    }
  };

  onCloseSearch = () => {
    this.setState({
      search: ''
    });

    this.props.searchIssues({ search: '' });
  };

  onChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  render() {
    const { showSearchForm, search } = this.state;

    return (
      <form className={classNames('search-form', { active: showSearchForm })} onSubmit={this.toggleSearchForm}>
        <input
          className="search-form-input"
          type="text"
          ref={ref => {
            this.searchInputRef = ref;
          }}
          value={search}
          onChange={this.onChange}
        />
        <button type="submit" className="button button-blue btn-search" />
        {showSearchForm && search.length > 0 && (
          <button
            type="button"
            className="button button-blue btn-search btn-search-close"
            onClick={this.onCloseSearch}
          />
        )}
      </form>
    );
  }
}

export { IssueSearch as PureComponent };
export default pure(IssueSearch);
