import { setSearch } from '../../actions/search';
import memoize from '../memoize';

const getShouldClearSearch = state => state.location.pathname !== '/shop/search' && state.search.q.length !== 0;

let timer;

// This observer is delayed because search is set before location /shop/search
// @see actions/search
export default (dispatch, timeout = 800) =>
  memoize(
    [getShouldClearSearch],
    shouldClearSearch =>
      new Promise(resolve => {
        clearTimeout(timer);

        if (shouldClearSearch) {
          timer = setTimeout(() => {
            dispatch(setSearch(''));
            resolve();
          }, timeout);
          return;
        }

        resolve();
      })
  );
