import { CHANGE_MAIN_COLOR } from 'constants/actionTypes';

export default color => (dispatch, getState) => {
  const state = getState();

  if (color !== state.color) {
    dispatch({
      type: CHANGE_MAIN_COLOR,
      color
    });
  }
};
