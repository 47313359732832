import { combineReducers } from 'redux';
import entities from './entities';
import list from './list';
import partnerIds from './partnerIds';

export default combineReducers({
  entities,
  list,
  partnerIds
});
