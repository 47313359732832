import moment from 'moment';
import getHolidays from 'constants/getHolidays';

export const isHoliday = date => {
  const HOLIDAYS = getHolidays();
  const formatedDate = moment(date);
  const currentDate = formatedDate.format('MM-DD');
  if (HOLIDAYS.DE.FIXED.includes(currentDate)) return true;

  const variableHolidays = HOLIDAYS.DE[formatedDate.year()];
  if (!variableHolidays) return false;
  return variableHolidays.includes(currentDate);
};

export const isWeekday = date => {
  if (!date) return false;

  let day;

  if (date instanceof moment) {
    day = date.day();
  } else {
    day = date.getDay();
  }
  return date && day && day !== 0 && day !== 6;
};
