import memoize from '../memoize';
import { getHeap } from '../../tracking';

const getUserId = state => state.current.user && state.current.user.id;
const getCustomerId = state => state.current.customer && state.current.customer.id;

export default raven =>
  memoize([getUserId, getCustomerId], (userId, customerId) => {
    if (!userId) return;

    const heap = getHeap();
    if (heap) heap.identify(userId);

    if (raven && raven.isSetup()) {
      raven.setUserContext({
        userId,
        customerId
      });
    }
  });
