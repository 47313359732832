import {
  SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_REQUEST,
  SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS,
  SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default cardId => (dispatch, getState) => {
  const {
    shop: {
      payment: {
        creditCards: { list }
      }
    }
  } = getState();
  const creditCard = list.find(cc => cc.id === cardId);

  if (creditCard && creditCard.isNew) {
    dispatch({
      type: SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS,
      meta: { cardId }
    });
    return Promise.resolve();
  }

  return dispatch(
    authCall({
      shouldFetch: state =>
        !(
          state.shop.payment.creditCards.delete[cardId] &&
                    state.shop.payment.creditCards.delete[cardId].isDeleting
        ),
      types: [
        SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_REQUEST,
        SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS,
        SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_FAILURE
      ],
      meta: { cardId },
      endpoint: `/user/users/current/payments/credit-card/${cardId}`,
      method: 'DELETE'
    })
  );
};
