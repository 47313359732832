import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { DateRangePicker } from 'react-dates';
import onClickOutside from 'react-onclickoutside';
import { withTranslation } from 'react-i18next';
import { Transition } from 'common';
import { SERVICE_REQUEST_STATUS } from 'constants/serviceRequests';
import { CheckboxSection } from '.';

class FilterSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null
    };
  }

  handleClickOutside() {
    this.props.hide();
  }

  render() {
    const { t, children, isOpen, status, onChangeFilter, startDate, endDate, filterDatesChange } = this.props;

    return (
      <div className="rel">
        {children}
        <Transition timeout={100}>
          {isOpen && (
            <div className="dropdown-select checkbox-dropdown">
              <div className="filter-section">
                <div className="name name--no-style">{t('select-dates')}</div>
                <DateRangePicker
                  small
                  startDatePlaceholderText={t('from')}
                  endDatePlaceholderText={t('to')}
                  customArrowIcon="-"
                  showClearDates
                  isOutsideRange={() => false}
                  startDateId="requestsStartDate"
                  endDateId="requestsEndDate"
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={filterDatesChange}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  displayFormat="DD/MM/YYYY"
                />
              </div>
              <CheckboxSection
                title="status"
                items={SERVICE_REQUEST_STATUS}
                checkedItems={status}
                change={val => onChangeFilter(val, 'status')}
              />
            </div>
          )}
        </Transition>
      </div>
    );
  }
}

FilterSelect.propTypes = {
  t: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChangeFilter: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  filterDatesChange: PropTypes.func.isRequired
};

export { FilterSelect as PureComponent };
export default pure(withTranslation('Issues')(onClickOutside(FilterSelect)));
