import { SERVICE_EVENT_FILTERS_RESET } from 'constants/actionTypes';

export default () => (dispath, getState) => {
  const {
    serviceEvents: {
      filters: { selected }
    }
  } = getState();

  if (!selected.types.length && !selected.states.length && !selected.modes.length) {
    return;
  }

  dispath({
    type: SERVICE_EVENT_FILTERS_RESET
  });
};
