import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';

const getIsEditing = state => !!state.shop.cart.cart.sed;
const getT = (state, props) => props.t;

const createGetMemoizedT = () =>
  createSelector(
    [getIsEditing, getT],
    (isEditing, t) => {
      if (!isEditing) return t;

      return (key, options) =>
        t(key, {
          ...options,
          ns: ['Shop/Cart/Editing', 'Shop/Cart/Default'],
          defaultNS: 'Shop/Cart/Editing',
          fallbackNS: 'Shop/Cart/Default'
        });
    }
  );

const mapStateToProps = () => {
  const getMemoizedT = createGetMemoizedT();

  return (state, props) => ({
    t: getMemoizedT(state, props)
  });
};

export default WrappedComponent =>
  withTranslation('Shop/Cart/Default')(
    connect(
      mapStateToProps,
      {}
    )(WrappedComponent)
  );
