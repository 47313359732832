/* eslint-disable import/prefer-default-export */
import i18next from 'src/i18next';

const getRepeatDays = () => [
  {
    label: i18next.t('Shop/Cart/Default:weekday-mo'),
    value: '0'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-tu'),
    value: '1'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-we'),
    value: '2'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-th'),
    value: '3'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-fr'),
    value: '4'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-sa'),
    value: '5'
  },
  {
    label: i18next.t('Shop/Cart/Default:weekday-su'),
    value: '6'
  }
];

export { getRepeatDays };
