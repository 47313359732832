import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_PREMIUM_TASKLIST } from 'constants/location';

const PremiumTasklistLink = ({ pathname, t }) => (
  <span>
    <Link to={`${pathname}${LOCATION_HASH_PREMIUM_TASKLIST}`} className="cleaning-services__premiumlink">
      {t('premium-tasklist-title')}
    </Link>
    ?
  </span>
);

PremiumTasklistLink.propTypes = {
  pathname: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export { PremiumTasklistLink as PureComponent };
export default withTranslation('Modal/CleaningTasklist')(connect(mapStateToProps)(PremiumTasklistLink));
