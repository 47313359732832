import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changeProduct } from 'actions/shop/cart';

const AddButton = ({ t, onClick }) => (
  <button type="button" className="product-add" onClick={onClick}>
    {t('add-to-cart')}
  </button>
);

AddButton.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, { item }) => ({
  onClick: () => dispatch(changeProduct(item))
});

export { AddButton as PureComponent };
export default withTranslation('Shop/Product')(
  connect(
    null,
    mapDispatchToProps
  )(AddButton)
);
