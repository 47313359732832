// @flow
import React from 'react';
import { Field } from 'formik';
import { Checkbox, CheckboxGroup } from '@spone/ui';
import cx from 'classnames';

import { getRepeatDays } from 'utils/schedules';

import './DaysCheckboxGroup.less';

export const DaysCheckboxGroup = ({
  name,
  valuesArray,
  disabled = false,
  activeDays,
  setFieldTouched,
  setFieldValue
}) => {
  const repeatDays = getRepeatDays();

  const checkIfDisabledByWeekDay = day => {
    if (!disabled && activeDays && activeDays.length > 0) {
      return !activeDays.includes(parseInt(day, 10));
    }

    return disabled;
  };

  return (
    <div className={cx('weeks-list', { disabled })}>
      <CheckboxGroup
        id={name}
        value={valuesArray}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
        className="days-checkbox-group"
        numeric
      >
        {repeatDays.map(day => (
          <Field
            component={Checkbox}
            key={`day_${day.label}`}
            label={day.label.substring(0, 2)}
            name={name}
            id={day.value}
            disabled={checkIfDisabledByWeekDay(day.value)}
          />
        ))}
      </CheckboxGroup>
    </div>
  );
};
export default DaysCheckboxGroup;
