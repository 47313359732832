/* eslint-disable jsx-a11y/label-has-associated-control, camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { pure } from 'recompose';
import Dropdown from 'react-dropdown';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { createIssue } from 'actions/issues';
import { getPriorities, getCategories } from '../../helper';

import './CreateIssue.less';

class CreateIssue extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      customer_id: null,
      category: null,
      priority: null,
      assignee_id: null,
      createAnother: false,
      description: '',
      file: null,
      floor: '',
      room: ''
    };

    this.state = this.defaultState;
    this.priorities = getPriorities(props.t);
    this.categories = getCategories(props.t);
  }

  onChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;

    this.setState({
      [name]: value
    });
  };

  onChangeCheckbox = () => {
    this.setState(prevState => ({
      createAnother: !prevState.createAnother
    }));
  };

  imageUpload = event => {
    const image = event.target.files[0];

    this.setState({
      file: image
    });
  };

  submitForm = e => {
    e.preventDefault();

    if (this.isFormValid()) {
      this.props.createIssue(this.state).then(() => {
        if (this.state.createAnother) {
          this.setState({ ...this.defaultState });
        }
      });
    }
  };

  isFormValid = () => {
    const { category, description, priority } = this.state;

    if (!category || description.length === 0 || !priority) {
      return false;
    }

    return true;
  };

  formatList = (arr, label) => arr.map(el => ({ label: el[label], value: el.id }));

  render() {
    const { customer_id, category, assignee_id, createAnother, priority, file, description, floor, room } = this.state;
    const { t, objects, members } = this.props;

    const objectsList = objects && this.formatList(objects, 'name');
    const assignee = members && this.formatList(members, 'fullname');

    return (
      <DocumentTitle title={t('new-issue-title')}>
        <div className="create-issue content-area">
          <div className="page-header">
            <h1 className="page-title">{t('new-issue-title')}</h1>
          </div>
          <div className="page-content">
            <form className="issue-form" onSubmit={this.submitForm}>
              <div className="form-section">
                <div className="section-title">{t('issue-overview')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('object')}</div>
                    <Dropdown
                      className="Dropdown-flat"
                      options={objectsList}
                      onChange={e => this.onChange(e, 'customer_id')}
                      value={customer_id}
                      placeholder={t('choose-object')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('category')}</div>
                    <Dropdown
                      className="Dropdown-flat"
                      options={this.categories}
                      onChange={e => this.onChange(e, 'category')}
                      value={category}
                      placeholder={t('choose-issue-category')}
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="section-title">{t('description-attachment')}</div>

                <div className="form-row">
                  <div className="form-description">
                    <textarea
                      className="textarea"
                      placeholder={t('task-description')}
                      value={description}
                      onChange={e => this.onChange(e, 'description')}
                    />

                    <div className="form-files">
                      <div className="file-upload">
                        <input
                          id="uploadImage"
                          type="file"
                          accept="image/*"
                          className="button"
                          onChange={this.imageUpload}
                        />
                        <label htmlFor="uploadImage">{file ? file.name : t('attach-picture')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="section-title">{t('location-information')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('floor')}</div>
                    <input
                      type="text"
                      className="form-input"
                      placeholder={t('input-floor')}
                      value={floor}
                      onChange={e => this.onChange(e, 'floor')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('room')}</div>
                    <input
                      type="text"
                      className="form-input"
                      placeholder={t('input-room')}
                      value={room}
                      onChange={e => this.onChange(e, 'room')}
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="section-title">{t('priority-title')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('priority')}</div>
                    <Dropdown
                      className="Dropdown-flat Dropdown-max-3"
                      options={this.priorities}
                      onChange={e => this.onChange(e, 'priority')}
                      value={priority}
                      placeholder={t('choose-priority')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('assignee')}</div>
                    <Dropdown
                      className="Dropdown-flat Dropdown-max-3"
                      options={assignee}
                      onChange={e => this.onChange(e, 'assignee_id')}
                      value={assignee_id}
                      placeholder={t('assign-task')}
                    />
                  </div>
                </div>
              </div>

              <div className="form-buttons">
                <Link to="/issues" className="link">
                  {t('back')}
                </Link>
                <div className="form-buttons-right">
                  <label
                    className={classNames('checkbox-label ui-component', {
                      checked: createAnother
                    })}
                    htmlFor="cteateAnotherIssue"
                  >
                    {t('create-another')}
                    <input
                      type="checkbox"
                      id="cteateAnotherIssue"
                      name="open"
                      value={false}
                      onChange={this.onChangeCheckbox}
                      checked={createAnother}
                    />
                  </label>

                  <button type="submit" className="button button-blue form-submit-btn">
                    {t('create')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

CreateIssue.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  objects: state.current.user.available_customers,
  members: state.members.items
});

const mapDispatchToProps = dispatch => ({ createIssue: data => dispatch(createIssue(data)) });

export { CreateIssue as PureComponent };
export default pure(
  withTranslation('Issues')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CreateIssue)
  )
);
