import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { RadioButton } from 'common/Form';

class TrueOrFalse extends React.Component {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      initialValue: PropTypes.bool,
      name: PropTypes.string,
      labels: PropTypes.array,
      t: PropTypes.func.isRequired
    };

    static defaultProps = {
      initialValue: false
    };

    setTrue = e => {
      this.setState(() => ({
            value: true // eslint-disable-line
      }));
      this.props.onChange(e, true);
    };

    setFalse = e => {
      this.setState(() => ({
            value: false // eslint-disable-line
      }));
      this.props.onChange(e, false);
    };

    render() {
      const { labels = [this.props.t('yes'), this.props.t('no')], name, initialValue } = this.props;

      return (
        <div className="radio-group">
          <RadioButton
            name={name}
            label={labels[0]}
            value="true"
            onClick={this.setTrue}
            defaultChecked={!!initialValue === true}
          />
          <RadioButton
            name={name}
            label={labels[1]}
            value="false"
            onClick={this.setFalse}
            defaultChecked={!!initialValue === false}
          />
        </div>
      );
    }
}

export default withTranslation('Services/Cleaning')(TrueOrFalse);
