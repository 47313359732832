import { SHOP_CART_PAYMENT_SUCCESS, SHOP_CART_ORDER_SUCCESS } from 'constants/actionTypes';

const initialState = {
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    case SHOP_CART_PAYMENT_SUCCESS:
      return { ...state, token: action.payload.billing_token };

    default:
      return state;
  }
};
