import React from 'react';
import PropTypes from 'prop-types';

export default class CleaningBox extends React.PureComponent {

    static propTypes = {
      children: PropTypes.node,
      headline: PropTypes.string
    };

    render() {
      const { children, className, headline } = this.props;
      return (
        <div className={`cleaning-services__boxed ${className || ''}`}>
          { headline && <p className="cleaning-services__boxed__headline">{headline}</p> }
          { children }
        </div>
      );
    }

}
