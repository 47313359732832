import { createSelector } from 'reselect';
import { getItemsFromPages } from '../shared';

const getPages = (state, { dataKey }) => state.serviceEvents.list[dataKey] && state.serviceEvents.list[dataKey].pages;
const getEntities = state => state.serviceEvents.entities;

export default () =>
  createSelector([getItemsFromPages(getPages), getEntities], (items, entities) => {
    if (!items) return null;

    return items.map(itemId => entities[itemId]).filter(item => !!item);
  });
