import { SHOP_CART_PAYMENT_CREDIT_CARD_SELECT } from 'constants/actionTypes';
import { PAYMENT_METHOD_CREDIT_CARD } from 'constants/paymentMethods';
import { selectPaymentMethod } from '.';

export default id => (dispatch, getState) => {
  const {
    shop: {
      payment: {
        creditCards: { selected }
      }
    }
  } = getState();

  if (id === selected) return;

  dispatch(selectPaymentMethod(PAYMENT_METHOD_CREDIT_CARD));
  dispatch({
    type: SHOP_CART_PAYMENT_CREDIT_CARD_SELECT,
    id
  });
};
