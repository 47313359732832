/* eslint-disable import/no-named-as-default */

import CancelButton from './CancelButton';
import OrderButton from './OrderButton';
import Payment from './Payment';
import PaymentMethod from './PaymentMethod';
import CreditCard from './CreditCard';
import PoNumber from './PoNumber';
import ShippingAddress from './ShippingAddress';
import BillingAddress from './BillingAddress';
import RemoveCreditCard from './RemoveCreditCard';

export default Payment;
export {
  CancelButton,
  CreditCard,
  OrderButton,
  PaymentMethod,
  PoNumber,
  ShippingAddress,
  BillingAddress,
  RemoveCreditCard
};
