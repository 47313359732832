import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from '../Common';

import {
  AdditionalCleaning,
  WindowCleaning,
  GegenbauerCleaning,
  CleaningMenu,
  RecurringCleaning,
  RecurringCleaning2,
  CleaningSuccess,
  IntensiveCleaning
} from '.';

import './Cleaning.less';
import './CleaningService.less';

const Cleaning = ({ t }) => (
  <DocumentTitle title={t('service-cleaning-title')}>
    <div className="col-group content-area">
      <Switch>
        <Route path="/cleaning/additional-cleaning" component={AdditionalCleaning} />
        <Route path="/cleaning/gegenbauer-cleaning" component={GegenbauerCleaning} />
        <Route path="/cleaning/cleaning-window" component={WindowCleaning} />
        <Route path="/cleaning/cleaning-window/success" component={CleaningSuccess} />
        <Route path="/cleaning/cleaning-calc/step-1" component={RecurringCleaning} />
        <Route path="/cleaning/cleaning-calc/step-2" component={RecurringCleaning2} />
        <Route path="/cleaning/cleaning-calc/step-3" component={CleaningSuccess} />
        <Redirect exact path="/cleaning/intensive-cleaning" to="/cleaning/intensive-cleaning/step-1" />
        <Route path="/cleaning/intensive-cleaning/step-:step" component={IntensiveCleaning} />
        <Route path="/cleaning" component={CleaningMenu} />
      </Switch>
    </div>
  </DocumentTitle>
);

export default withTranslation('Services/Cleaning')(withRouter(Cleaning));
