import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Modal } from 'components/HOC';
import { sendOffer } from 'actions/offerConfirmation';

import './ConfirmOffer.less';

const createState = () => {
  const signedFirstName = '';
  const signedLastName = '';
  const signedEmailAddress = '';
  const lostReasonDetails = '';
  const errorAccept = false;
  const errorReject = false;
  return {
    signedFirstName,
    signedLastName,
    signedEmailAddress,
    lostReasonDetails,
    errorAccept,
    errorReject
  };
};

export class ConfirmOffer extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);
    this.offerToken = window.location.pathname.split('/offer/')[1]; //eslint-disable-line
  }

  componentWillReceiveProps(nextProps) {
    const { isSubmitted, isSubmitting } = nextProps.submitOffer[this.offerToken]
      ? nextProps.submitOffer[this.offerToken]
      : false;

    if (isSubmitted && !isSubmitting) {
      this.props.history.push(`/offer/success/${this.offerToken}`);
    }
  }

  handleChange = ({ target: { name, value } }) => {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  };

  handleSubmitButtonClick = e => {
    e.preventDefault();
    const { isConfirmed, handleSubmit } = this.props;
    const { signedFirstName, signedLastName, signedEmailAddress, lostReasonDetails } = this.state;

    if (isConfirmed) {
      const submitOfferData = {
        signed_first_name: signedFirstName,
        signed_last_name: signedLastName,
        signed_email_address: signedEmailAddress,
        status: 'CONFIRM'
      };
      if (this.validate()) {
        handleSubmit(this.offerToken, submitOfferData);
      }
    } else {
      const submitOfferData = {
        lost_reason_details: lostReasonDetails,
        status: 'REJECT'
      };
      if (this.validate()) {
        handleSubmit(this.offerToken, submitOfferData);
      }
    }
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  renderConfirmationForm = () => {
    const { t, submitOffer } = this.props;
    const { signedFirstName, signedLastName, signedEmailAddress, errorAccept } = this.state;
    return (
      <div>
        <h2 className="headline">{t('headline-accept')}</h2>
        <p className="offer__modaltext">{t('please-verify')}</p>
        <form onSubmit={this.handleSubmitButtonClick}>
          <fieldset disabled={Object.keys(submitOffer).length && submitOffer[this.offerToken].isSubmitting}>
            <div className="col-group">
              <div className="col-6 left">
                <label htmlFor="firstName">{t('first-name')}</label>
                <input
                  className="offer__input"
                  id="firstName"
                  type="text"
                  onChange={this.handleChange}
                  name="signedFirstName"
                  value={signedFirstName}
                />
              </div>
              <div className="col-6 right">
                <label htmlFor="lastName">{t('last-name')}</label>
                <input
                  className="offer__input"
                  id="lastName"
                  type="text"
                  onChange={this.handleChange}
                  name="signedLastName"
                  value={signedLastName}
                />
              </div>
              <div className="col-6 left">
                <label htmlFor="email">{t('e-mail')}</label>
                <input
                  className="offer__input"
                  id="email"
                  type="email"
                  onChange={this.handleChange}
                  name="signedEmailAddress"
                  value={signedEmailAddress}
                />
              </div>
              {errorAccept && <p className="col-12 offer__errortext">{t('error-accept')}</p>}
              <div className="col-12">
                <div className="col-6 left">
                  <button type="button" className="button button-cancel col-12" onClick={this.handleCloseModal}>
                    {t('cancel')}
                  </button>
                </div>
                <div className="col-6 right">
                  <button
                    type="submit"
                    className={`button button-submit col-12 ${
                      Object.keys(submitOffer).length && submitOffer[this.offerToken].isSubmitting
                        ? 'loading-light'
                        : ''
                    }`}
                  >
                    {t('accept-offer')}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  };

  renderRejectionForm = () => {
    const { t, submitOffer } = this.props;
    const { lostReasonDetails, errorReject } = this.state;
    return (
      <div>
        <h2 className="headline">{t('headline-reject')}</h2>
        <form onSubmit={this.handleSubmitButtonClick}>
          <fieldset disabled={Object.keys(submitOffer).length && submitOffer[this.offerToken].isSubmitting}>
            <div className="col-group">
              <label className="col-12" htmlFor="lostReasonDetails">
                {t('reject-reason')}
              </label>
              <textarea
                className="offer__textarea col-12"
                type="text"
                onChange={this.handleChange}
                id="lostReasonDetails"
                name="lostReasonDetails"
                value={lostReasonDetails}
              />
              {errorReject && <p className="col-12 offer__errortext">{t('error-reject')}</p>}
              <div className="col-12">
                <div className="col-6 left">
                  <button type="button" className="button button-cancel col-12" onClick={this.handleCloseModal}>
                    {t('cancel')}
                  </button>
                </div>
                <div className="col-6 right">
                  <button
                    type="submit"
                    className={`button button-submit col-12 ${
                      Object.keys(submitOffer).length && submitOffer[this.offerToken].isSubmitting
                        ? 'loading-light'
                        : ''
                    }`}
                  >
                    {t('reject-offer')}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  };

  validate() {
    const { isConfirmed } = this.props;
    const { signedFirstName, signedLastName, signedEmailAddress, lostReasonDetails } = this.state;
    let isValid = false;

    if (isConfirmed) {
      if (signedFirstName && signedLastName && signedEmailAddress) {
        this.setState({ errorAccept: false });
        isValid = true;
      } else {
        this.setState({ errorAccept: true });
        isValid = false;
      }
      return isValid;
    }

    if (lostReasonDetails) {
      this.setState({ errorReject: false });
      isValid = true;
    } else {
      this.setState({ errorReject: true });
      isValid = false;
    }
    return isValid;
  }

  render() {
    const { isConfirmed } = this.props;

    return (
      <div className="settings-modal-box">
        {isConfirmed ? this.renderConfirmationForm() : this.renderRejectionForm()}
      </div>
    );
  }
}

ConfirmOffer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitOffer: PropTypes.object
};

const mapStateToProps = ({ offerConfirmation: { submitOffer } }) => ({ submitOffer });

const mapDispatchToProps = dispatch => ({
  handleSubmit: (offerToken, submitOfferData) => dispatch(sendOffer(offerToken, submitOfferData))
});

export { ConfirmOffer as PureComponent };
export default withTranslation('OfferConfirmation')(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Modal('Confirm Offer Modal', { className: 'confirm-offer-modal' })(ConfirmOffer))
  )
);
