/* eslint-disable no-param-reassign */
import { shouldFetch, formatFormData, formatPriorities } from 'utils';
import {
  ISSUES_FETCH,
  ISSUES_FETCH_SUCCESS,
  ISSUES_FETCH_FAILURE,
  ISSUES_CREATE,
  ISSUES_CREATE_SUCCESS,
  ISSUES_CREATE_FAILURE,
  ISSUES_FETCH_ISSUE,
  ISSUES_FETCH_ISSUE_SUCCESS,
  ISSUES_FETCH_ISSUE_FAILURE,
  ISSUES_EDIT,
  ISSUES_EDIT_SUCCESS,
  ISSUES_EDIT_FAILURE,
  ISSUES_SEARCH,
  ISSUES_SEARCH_SUCCESS,
  ISSUES_SEARCH_FAILURE,
  ISSUES_FETCH_STATS,
  ISSUES_FETCH_STATS_SUCCESS,
  ISSUES_FETCH_STATS_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

export const fetchIssues = query => {
  if (query && query.priority && query.priority.length) {
    query.priority = formatPriorities(query.priority);
  }

  return authCall({
    types: [ISSUES_FETCH, ISSUES_FETCH_SUCCESS, ISSUES_FETCH_FAILURE],
    endpoint: `/user/tickets`,
    method: 'GET',
    query
  });
};

export const fetchIssue = id =>
  authCall({
    types: [ISSUES_FETCH_ISSUE, ISSUES_FETCH_ISSUE_SUCCESS, ISSUES_FETCH_ISSUE_FAILURE],
    endpoint: `/user/ticket/${id}`,
    method: 'GET'
  });

export const createIssue = issue => {
  const data = formatFormData(issue);

  return authCall({
    types: [ISSUES_CREATE, ISSUES_CREATE_SUCCESS, ISSUES_CREATE_FAILURE],
    endpoint: `/user/ticket`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const searchIssue = (query, shouldDoForceFetch = false) =>
  authCall({
    shouldFetch: ({ issues: { isFetching, fetchedAt } }) => shouldFetch(isFetching, fetchedAt, shouldDoForceFetch),
    types: [ISSUES_SEARCH, ISSUES_SEARCH_SUCCESS, ISSUES_SEARCH_FAILURE],
    endpoint: `/user/tickets/search`,
    method: 'GET',
    query
  });

export const updateIssue = issue => {
  if (!issue.assignee_id) {
    delete issue.assignee_id;
  }

  const data = formatFormData(issue);

  return authCall({
    types: [ISSUES_EDIT, ISSUES_EDIT_SUCCESS, ISSUES_EDIT_FAILURE],
    endpoint: `/user/ticket/${data && data.get('id')}`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const fetchStats = () =>
  authCall({
    types: [ISSUES_FETCH_STATS, ISSUES_FETCH_STATS_SUCCESS, ISSUES_FETCH_STATS_FAILURE],
    endpoint: `/user/tickets/stats`,
    method: 'GET'
  });
