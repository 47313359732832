import { SHOP_CART_SHOW, SHOP_CART_HIDE } from 'constants/actionTypes';

export default isVisible => (dispatch, getState) => {
  const {
    shop: { cart }
  } = getState();

  if (cart.isVisible !== !!isVisible) {
    dispatch({
      type: isVisible ? SHOP_CART_SHOW : SHOP_CART_HIDE
    });
  }
};
