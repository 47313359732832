import {
  SHOP_CART_PO_NUMBER_CHANGE_REQUEST,
  SHOP_CART_PO_NUMBER_CHANGE_SUCCESS,
  SHOP_CART_PO_NUMBER_CHANGE_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_PAYMENT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default poNumber =>
  authCall({
    shouldFetch: state =>
      state.shop.cart.step === SHOPPING_CART_STEP_PAYMENT && !state.shop.cart.isPoNumberChanging,
    types: [
      SHOP_CART_PO_NUMBER_CHANGE_REQUEST,
      SHOP_CART_PO_NUMBER_CHANGE_SUCCESS,
      SHOP_CART_PO_NUMBER_CHANGE_FAILURE
    ],
    endpoint: '/user/shop/cart/po_number',
    method: 'PUT',
    body: { po_number: poNumber }
  });
