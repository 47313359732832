import moment from 'moment';

let enrichedHolidays;

const calculateEasterDependentHoldidays = date => [
  moment(date).subtract(2, 'days'),
  moment(date),
  moment(date).add(1, 'days'),
  moment(date).add(39, 'days'),
  moment(date).add(50, 'days')
].map(v => v.format('MM-DD'));


const holidays = {
  DE: {
    FIXED: ['01-01',
      '01-02',
      '01-03',
      '05-01',
      '10-03',
      '12-25',
      '12-26',
      '12-27',
      '12-28',
      '12-29',
      '12-30',
      '12-31'],
    2017: ['10-31', ...calculateEasterDependentHoldidays('2017-04-16')],
    2018: [...calculateEasterDependentHoldidays('2018-04-01')]
  }
};

export default () => {
  if (!enrichedHolidays) {
    enrichedHolidays = holidays;
  }
  return enrichedHolidays;
};
