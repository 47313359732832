import { toast } from 'react-toastify';
import { NOTIFICATION_SHOW, NOTIFICATION_CLOSE } from 'constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case NOTIFICATION_SHOW: {
      const filteredMessages = state.filter(item => item.level !== action.item.level);
      toast[action.item.level](action.item && action.item.message);
      return [...filteredMessages, action.item];
    }
    case NOTIFICATION_CLOSE:
      return state.filter(item => item.id !== action.id);
    default:
      return state;
  }
};
