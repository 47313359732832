import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pure } from 'recompose';
import { RRule, getRRuleWeekday } from 'utils';
import { translateEditing } from '../../../../../HOC';
import { Weekly, Monthly } from '.';

class Schedule extends Component {
  handleChange(freq) {
    const { dueDate, onChange } = this.props;

    if (freq === RRule.WEEKLY) {
      return onChange({
        freq: RRule.WEEKLY,
        byweekday: [getRRuleWeekday(moment(dueDate).format('E'))]
      });
    }

    if (freq === RRule.MONTHLY) {
      return onChange({ freq: RRule.MONTHLY });
    }

    return onChange();
  }

  render() {
    const {
      rruleOptions: { freq },
      t
    } = this.props;

    return (
      <div className="cart-scheduler">
        <div className="tab-titles">
          <button
            type="button"
            className={`button-inherit button-one-time ${
              freq !== RRule.WEEKLY && freq !== RRule.MONTHLY ? 'active' : ''
            }`}
            onClick={() => this.handleChange()}
          >
            {t('one-time')}
          </button>

          <button
            type="button"
            className={`button-inherit button-weekly ${freq === RRule.WEEKLY ? 'active' : ''}`}
            onClick={() => this.handleChange(RRule.WEEKLY)}
          >
            {t('weekly')}
          </button>

          <button
            type="button"
            className={`button-inherit button-monthly ${freq === RRule.MONTHLY ? 'active' : ''}`}
            onClick={() => this.handleChange(RRule.MONTHLY)}
          >
            {t('monthly')}
          </button>
        </div>

        <div className="tab-content">
          {freq === RRule.WEEKLY && <Weekly {...this.props} />}
          {freq === RRule.MONTHLY && <Monthly {...this.props} />}
        </div>
      </div>
    );
  }
}

Schedule.propTypes = {
  dueDate: PropTypes.object.isRequired,
  rruleOptions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export { Schedule as PureComponent };
export default pure(translateEditing(Schedule));
