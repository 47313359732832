import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';
import { DocumentTitle } from 'common';
import { Banner, Header, Schedule, Invoices, Services, PastEvents } from '.';

import './Dashboard.less';

const Dashboard = ({ t, user }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('UA-63398880-1');
    }
  }, []);

  const today = moment().startOf('day');
  const tonight = moment().endOf('day');
  const currentMonth = moment().month();

  return (
    <DocumentTitle title={t('headline')}>
      <div className="col-group content-area dashboard">
        <h1 className="dashboard-headline">{t('headline')}</h1>
        <p className="greeting">
          {currentMonth === 11 ? t('greeting-december') : t('greeting')}, <strong>{user.fullname}</strong>
        </p>
        <div className="col-12">
          <Banner />
          <Header startDate={today} endDate={tonight} />
          <div className="col-7 left-column">
            <Schedule />
            <Invoices />
          </div>
          <div className="col-5 right-column">
            <Services />
            <PastEvents />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.current.user || {}
});

export default pure(
  withTranslation('Dashboard')(
    connect(
      mapStateToProps,
      null
    )(Dashboard)
  )
);
