import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../..';

const ReorderLink = ({ item, location, t }) => {
  const tooltipId = `tooltip-se-${item.id}-reorder-link`;

  if (!item.is_reorderable) return null;

  return (
    <Tooltip className="se-reorder-link" id={tooltipId}>
      <Link className="sed-reorder" to={location} data-for={tooltipId} data-tip={t('reorder')} data-effect="solid">
        &nbsp;
      </Link>
    </Tooltip>
  );
};

ReorderLink.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  t: PropTypes.func.isRequired
};

export default pure(withTranslation('Modal/ServiceEvent')(ReorderLink));
