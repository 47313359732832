import {
  SERVICE_EVENT_DEFINITIONS_REQUEST,
  SERVICE_EVENT_DEFINITIONS_SUCCESS,
  SERVICE_EVENT_DEFINITIONS_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

/**
 * @param query.start_date
 * @param query.end_date
 */
export default (dataKey, query) =>
  authCall({
    shouldFetch: ({
      serviceEventDefinitions: {
        list: { [dataKey]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { dataKey },
    types: [
      SERVICE_EVENT_DEFINITIONS_REQUEST,
      SERVICE_EVENT_DEFINITIONS_SUCCESS,
      SERVICE_EVENT_DEFINITIONS_FAILURE
    ],
    endpoint: '/user/service-event-definitions',
    method: 'GET',
    query: ({
      serviceEvents: {
        filters: { selected }
      }
    }) => ({ ...query, ...selected })
  });
