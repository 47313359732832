import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

class WithSEFilters extends Component {

  componentWillReceiveProps(nextProps) {
    if (this.filtersHaveChanged(nextProps)) {
      this.props.onChange();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !this.filtersHaveChanged(nextProps);
  }

  filtersHaveChanged(nextProps) {
    return this.props.filters !== nextProps.filters;
  }

  render() {
    if (!this.props.children) return null;

    const passedProps = omit(this.props, 'filters', 'onChange', 'dispatch');
    return <div {...passedProps}>{this.props.children}</div>;
  }
}

WithSEFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { filters: { selected: filters } } }) => ({ filters });

export { WithSEFilters as PureComponent };
export default connect(mapStateToProps)(WithSEFilters);
