import {
  SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_REQUEST,
  SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_SUCCESS,
  SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { CONTENT_TYPE, CONTENT_TYPE_JSON } from '../../constants/headers';

export default intensiveCleaningSubmit =>
  authCall({
    shouldFetch: () => true,
    types: [
      SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_REQUEST,
      SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_SUCCESS,
      SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_FAILURE
    ],
    endpoint: '/user/form/cleaning/intensive',
    meta: 'submitCleaning',
    method: 'POST',
    headers: {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON
    },
    body: intensiveCleaningSubmit
  });
