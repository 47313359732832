import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { getCategories } from 'selectors/shop';
import { MobileMenuItem } from '.';

import './MobileMenu.less';

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };

    this.toggleClick = this.toggleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    window.addEventListener('touchStart', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('touchStart', this.handleClickOutside);
  }

  handleClickOutside() {
    if (this.state.isOpened) {
      this.setState({
        isOpened: false
      });
    }
  }

  toggleClick() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened
    }));
  }

  render() {
    const { items, isFetching } = this.props;
    const { isOpened } = this.state;

    return (
      <div className="shop-nav-mobile dropdown-container bottom arrow-left">
        <button
          type="button"
          className={`hamburger ${isOpened ? 'is-active' : ''} ${isFetching ? 'loading' : ''}`}
          onClick={this.toggleClick}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
        </button>

        {isOpened && items && (
          <div className="shop-nav-mobile-list dropdown-list">
            <ul>
              {items.map(item => (
                <MobileMenuItem key={item.id} item={item} onClick={this.handleClickOutside} />
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

MobileMenu.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array
};

const mapStateToProps = state => ({
  isFetching: state.shop.categories.list.isFetching,
  items: getCategories(state)
});

export default connect(mapStateToProps)(onClickOutside(MobileMenu));
