import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getFavoriteProductsForTeaser } from "selectors/shop";
import { fetchFavorites } from "actions/shop/favorites";
import { DocumentTitle } from "common";
import { WithCustomer } from "components/DI";
import { FavoriteTeaser, Bestsellers } from "components/Shop";
import Header from "components/Header";

import "./NotFound.less";

export class NotFound extends Component {
  componentWillMount() {
    this.props.fetchData();
  }

  render() {
    const { items, fetchData, t } = this.props;

    return (
      <DocumentTitle title={t("headline")}>
        <WithCustomer
          onChange={fetchData}
          className="col-group content-area pad"
        >
          <Header />
          <div className="not-found">
            <div className="not-found__header">
              <img
                alt={t("headline")}
                className="not-found-img"
                src="/images/icons/vacuum_02.svg"
              />
              <h1 className="not-found-headline">{t("headline")}</h1>
              <p className="not-found-description">{t("description")}</p>
              <Link to="/" className="button-blue center">
                {t("dashboard")}
              </Link>
            </div>
            <p className="headline">{t("services-headline")}</p>
            <div className="not-found__services">
              <div className="not-found__service">
                <div className="not-found__service-image recurring" />
                <div className="not-found__service-title">
                  <h3>{t("service-recurring-cleaning")}</h3>
                  <Link
                    to="/cleaning/cleaning-calc/step-1"
                    className="not-found__service-button"
                  >
                    {t("book")}
                  </Link>
                </div>
              </div>
              <div className="not-found__service">
                <div className="not-found__service-image intensive" />
                <div className="not-found__service-title">
                  <h3>{t("service-intensive-cleaning")}</h3>
                  <Link
                    to="/cleaning/intensive-cleaning/step-1"
                    className="not-found__service-button"
                  >
                    {t("book")}
                  </Link>
                </div>
              </div>
              {/* <div className="not-found__service">
                <div className="not-found__service-image services" />
                <div className="not-found__service-title">
                  <h3>{t('service-other-services')}</h3>
                  <Link to="/services" className="not-found__service-button">
                    {t('look')}
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="shop-module shop-module-slider">
              <FavoriteTeaser />
              {items && items.length === 0 && (
                <div>
                  <Bestsellers />
                </div>
              )}
            </div>
          </div>
        </WithCustomer>
      </DocumentTitle>
    );
  }
}

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  items: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const items = getFavoriteProductsForTeaser(state, props);
  return {
    items
  };
};

const mapDispatchToProps = {
  fetchData: fetchFavorites
};

export default withTranslation("Public/NotFound")(
  connect(mapStateToProps, mapDispatchToProps)(NotFound)
);
