import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { searchProducts } from 'actions/shop/products';
import { createGetProducts } from 'selectors/shop';
import Overview from '../Overview';

const createTitle = ({ t }) => `${t('headline')} - ${t('Shop:headline')}`;

const createBreadcrumbItems = ({ t }) => [{ url: '/shop', label: t('Shop:headline') }, { label: t('headline') }];

class SearchOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: createTitle(props),
      breadcrumbItems: createBreadcrumbItems(props)
    };

    this.fetchDataTimer = null;

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.t !== nextProps.t) {
      this.setState({
        title: createTitle(nextProps),
        breadcrumbItems: createBreadcrumbItems(nextProps)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.q !== prevProps.q) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchDataTimer);
  }

  fetchData(nextPage) {
    const { dispatch, dataKey, q, currentPage, limit } = this.props;

    clearTimeout(this.fetchDataTimer);

    this.fetchDataTimer = setTimeout(
      () =>
        dispatch(
          searchProducts(dataKey, {
            q,
            page: nextPage || currentPage,
            limit
          })
        ),
      500
    );
  }

  fetchNext() {
    this.fetchData(this.props.currentPage + 1);
  }

  render() {
    const { fetchData, fetchNext } = this;
    const { title, breadcrumbItems } = this.state;
    const { isFetching, items, hasMore } = this.props;
    const passedProps = { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext };

    return <Overview {...passedProps} trackingListId="search_list" />;
  }
}

SearchOverview.propTypes = {
  q: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  limit: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  t: PropTypes.func.isRequired
};

SearchOverview.defaultProps = {
  limit: 25
};

const createProps = ({ search: { q } }) => ({
  q,
  dataKey: `srch_${q}`
});

const mapStateToProps = () => {
  const getProducts = createGetProducts();

  return (state, props) => {
    const {
      shop: {
        products: {
          list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore } = {} }
        }
      }
    } = state;

    return {
      isFetching,
      items: getProducts(state, props),
      currentPage,
      hasMore
    };
  };
};

export default withTranslation('Shop/SearchOverview')(connect(createProps)(connect(mapStateToProps)(SearchOverview)));
