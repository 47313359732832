import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { order } from 'actions/shop/cart';
import { notifyError } from 'actions/notifications';
import { translateEditing } from '../HOC';

class OrderButton extends Component {
  handleClick = () => {
    const { isShippingAddressSaved, isBillingAddressSaved, showError, onClick, t } = this.props;
    if (!isShippingAddressSaved || !isBillingAddressSaved) {
      showError(t('save-changes-error'));
      return false;
    }

    return onClick();
  };

  render() {
    const { isLoading, isDisabled, t } = this.props;
    return (
      <button
        type="button"
        className={`button cart-submit ${isLoading ? 'loading-light' : ''} ${isDisabled ? 'disabled' : ''}`}
        onClick={this.handleClick}
      >
        {t('order')}
      </button>
    );
  }
}

OrderButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isShippingAddressSaved: PropTypes.bool.isRequired,
  isBillingAddressSaved: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isOrdering, isAddingCoupon, isRemovingCoupon, isDeleting },
    payment: { selectedMethod }
  }
}) => ({
  isLoading: isOrdering,
  isDisabled: isAddingCoupon || isRemovingCoupon || isDeleting || !selectedMethod
});

const mapDispatchToProps = {
  onClick: order,
  showError: notifyError
};

export { OrderButton as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderButton)
);
