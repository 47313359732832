/* eslint-disable camelcase */
import React, { Component } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  changeButtonsColor,
  changeMainColor,
  changeLogo,
  resetColors,
  saveUserTheme
} from 'actions/userSettings/index';
import { userThemeSave } from 'actions/tracking';
import { ColorSection, UploadSection } from './components';

import './UserSettings.less';

class UserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUserMenu: false,
      isLoaded: false
    };
  }

  componentDidMount() {
    this.defaultState = {
      buttonsColor: this.props.userTheme.buttonsColor || '#2c94e2',
      mainColor: this.props.userTheme.mainColor || '#2c94e2',
      logo: this.props.userTheme.logo,
      logoFile: null,
      isLogoChanged: false
    };

    this.setState({
      isLoaded: true,
      isLogoChanged: false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.isLoaded &&
      (nextProps.userTheme.buttonsColor !== prevState.buttonsColor ||
        nextProps.userTheme.mainColor !== prevState.mainColor)
    ) {
      return {
        buttonsColor: nextProps.userTheme.buttonsColor || '#2c94e2',
        mainColor: nextProps.userTheme.mainColor || '#2c94e2',
        logo: nextProps.userTheme.logo
      };
    }

    return null;
  }

  onChangeButtonsColor(color) {
    this.setState({ buttonsColor: color.hex });
    this.props.onChangeButtonsColor(color);
  }

  onChangeMainColor(color) {
    this.setState({ mainColor: color.hex });
    this.props.onChangeMainColor(color);
  }

  onChangeLogo(logo, logoFile) {
    this.setState({ logo, logoFile, isLogoChanged: true });
    this.props.onChangeLogo(logo);
  }

  toggleUserMenu = () => {
    this.setState(prevState => ({
      showUserMenu: !prevState.showUserMenu
    }));
  };

  resetColors = () => {
    this.setState(prevState => ({
      ...prevState,
      ...this.defaultState,
      isLogoChanged: true
    }));

    this.props.resetColors({
      buttonsColor: '#2c94e2',
      mainColor: '#2c94e2',
      logo: null,
      logoFile: null
    });
  };

  saveColors = () => {
    const { buttonsColor: button_color, mainColor: main_color, logoFile: picture, isLogoChanged } = this.state;

    const theme = {
      button_color,
      main_color
    };

    if (isLogoChanged) {
      theme.picture = picture;
    }

    this.props.saveTheme(theme).then(() => {
      this.props.onClick();
      this.toggleUserMenu();
    });
  };

  render() {
    const { showUserMenu, buttonsColor, logo, mainColor } = this.state;
    const { t } = this.props;

    const menuClass = classNames('user-settings-wrap', {
      open: showUserMenu
    });

    return (
      <div className="user-settings">
        {showUserMenu && (
          <div
            role="presentation"
            className="user-settings-overlay"
            onClick={() => this.toggleUserMenu()}
            tabIndex="-1"
          />
        )}

        <button type="button" className="user-settings-toggler-btn" onClick={() => this.toggleUserMenu()}>
          <span>{t('sidebar-theme')}</span>
        </button>

        <div className={menuClass}>
          <div className="user-settings-menu">
            <div className="menu-header">
              <span className="menu-header-title">{t('sidebar-title')}</span>
              <button type="button" className="menu-close-btn" onClick={() => this.toggleUserMenu()} />
            </div>

            <ColorSection
              title={t('section-buttons-title')}
              color={buttonsColor}
              changeColor={c => this.onChangeButtonsColor(c)}
            />

            <ColorSection
              title={t('section-main-title')}
              color={mainColor}
              changeColor={c => this.onChangeMainColor(c)}
            />

            <UploadSection
              title={t('section-upload-title')}
              uploadInputText={t('section-upload-label')}
              logo={logo}
              changeLogo={(l, logoFile) => this.onChangeLogo(l, logoFile)}
            />

            <div className="menu-buttons">
              <button type="button" className="menu-button-reset button-reject" onClick={() => this.resetColors()}>
                {t('btn-reset')}
              </button>
              <button type="button" className="menu-button-save button-blue" onClick={this.saveColors}>
                {t('btn-save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userTheme: state.userSettings
});

const mapDispatchToProps = dispatch => ({
  onChangeButtonsColor: color => dispatch(changeButtonsColor(color)),
  onChangeMainColor: color => dispatch(changeMainColor(color)),
  onChangeLogo: logo => dispatch(changeLogo(logo)),
  resetColors: colors => dispatch(resetColors(colors)),
  saveTheme: theme => dispatch(saveUserTheme(theme)),
  onClick: () => dispatch(userThemeSave())
});

export { UserSettings as PureComponent };
export default withTranslation('UserSettings')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserSettings)
);
