import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { Transition } from 'common';

export class AmountDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { isBoxVisible: false };
  }

  toggleBox = () => {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  };

  toggleBox = () => {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  };

  renderAmountDetails = (t, c, amount, total, paid) => (
    <div className="amount-details">
      <div className="amount-details-grand-total">
        <span className="title">{t('grand-total')}</span>
        <span className="value">{c(total)}</span>
      </div>
      <div className="amount-details-paid">
        <span className="title">{t('prepayments')}</span>
        <span className="value">{c(paid)}</span>
      </div>
      <div className="separator" />
      <div className="amount-details-outstanding">
        <span className="title">{t('outstanding-amount')}</span>
        <span className="value">{c(amount)}</span>
      </div>
    </div>
  );

  handleClickOutside() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { amount, total, paid } = this.props.invoice;
    const { isPaid, c, t } = this.props;
    const { isBoxVisible } = this.state;
    const amountToShow = !isPaid ? amount : paid;

    return (
      <div
        className={`amount ${!isPaid ? 'col-2' : 'col-4'} ${isBoxVisible ? 'open' : ''}`}
        onClick={this.toggleBox}
        role="button"
        tabIndex="-1"
      >
        <span className="amount-icon" />
        <span className="amount-text">{c(amountToShow)}</span>
        <Transition>{isBoxVisible && this.renderAmountDetails(t, c, amount, total, paid)}</Transition>
      </div>
    );
  }
}

AmountDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  c: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isPaid: PropTypes.bool
};

export default onClickOutside(AmountDetails);
