export const CONTENT_TYPE = 'Content-Type';
export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_FORM_URLENCODED = 'application/x-www-form-urlencoded';
export const CONTENT_TYPE_MULTIPART_FORM_DATA = 'multipart/form-data';
export const SAME_BOUNDARY = 'SOME_BOUNDARY';

export const BOUNDARY = 'boundary';
export const X_AUTH_TOKEN = 'X-Auth-Token';
export const X_MASTER_TOKEN = 'X-Master-Token';
export const X_LANGUAGE = 'X-Language';
export const X_TOTAL_COUNT = 'X-Total-Count';
