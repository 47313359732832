import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '..';

const EditButton = ({ item, t }) => {
  const tooltipId = `tooltip-sed-${item.id}-edit-button`;

  if (!item.is_editable) return null;

  return (
    <Tooltip id={tooltipId}>
      <button
        type="button"
        className="sed-edit"
        data-for={tooltipId}
        data-tip={t('edit')}
        data-effect="solid"
        data-place="left"
      >
                &nbsp;
      </button>
    </Tooltip>
  );
};

EditButton.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export { EditButton as PureComponent };
export default pure(withTranslation('Modal/ServiceEvent')(EditButton));
