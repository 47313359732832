import {
  CUSTOMER_SHIPPING_ADDRESS_EDIT_REQUEST,
  CUSTOMER_SHIPPING_ADDRESS_EDIT_SUCCESS,
  CUSTOMER_SHIPPING_ADDRESS_EDIT_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isChanging: false,
    isChanged: false,
    item: null
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_SHIPPING_ADDRESS_EDIT_REQUEST:
      return { ...state, isChanging: true, isChanged: false };

    case CUSTOMER_SHIPPING_ADDRESS_EDIT_SUCCESS:
      return { ...state, isChanging: false, isChanged: true, item: action.payload };

    case CUSTOMER_SHIPPING_ADDRESS_EDIT_FAILURE:
      return { ...state, isChanging: false };

    default:
      return state;
  }
};
