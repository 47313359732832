import React from 'react';
import PropTypes from 'prop-types';

export default class ShippingAddressSuccess extends React.PureComponent {

  render() {
    const {
      street,
      city,
      postalCode,
      country,
      ticket,
      currentAddress,
      addressSuccess,
      addressSoon,
      newAddress } = this.props;
    return (
      <div>
        <div className="shipping-address-display">
          <p className="shipping-address-text">{addressSuccess}</p>
          <p className="shipping-address-text">{addressSoon}</p>
          <br />
          <p className="shipping-address-new">{newAddress}</p>
          <p>{ticket}</p>
        </div>
        <div className="shipping-address-display">
          <p className="shipping-address-text">{currentAddress}</p>
          <br />
          <p>{street}</p>
          <p>{postalCode} {city}, {country}</p>
        </div>
      </div>
    );
  }
}

ShippingAddressSuccess.propTypes = {
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  ticket: PropTypes.string
};
