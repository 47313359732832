import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import './LargeIconButton.less';

const LargeIconButton = ({ onClick, icon, text }) => (
  <button type="button" className="large-icon-button" onClick={onClick}>
    <span className="icon">
      <img alt="" src={icon} />
    </span>
    <span className="text">{text}</span>
  </button>
);

LargeIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string
};

export default pure(LargeIconButton);
