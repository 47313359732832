/* eslint-disable react/no-unused-state */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

class CleaningDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      reqStartDate: null,
      startTime: null,
      reqStartTime: null
    };
  }

  render() {
    const { t } = this.props;
    const { startDate } = this.state;
    return (
      <div>
        <label className="cleaning-services__label" htmlFor="datetime">
          {t('date-label')}
        </label>
        <div>
          <fieldset id="datetime">
            <div className="cleaning-services__times">
              <SingleDatePicker
                placeholder={t('date-placeholder')}
                id="date"
                date={startDate}
                onDateChange={newDate =>
                  this.setState({
                    startDate: newDate,
                    reqStartDate: moment(newDate).format('DD-MM-YYYY')
                  })
                }
                showClearDate
                numberOfMonths={1}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                openDirection="up"
              />
              <TimePicker
                id="time"
                showSecond={false}
                placeholder={t('time-placeholder')}
                className="cleaning-services__timepick"
                onChange={e =>
                  this.setState({
                    startTime: e,
                    reqStartTime: moment(e).format('LT')
                  })
                }
              />
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}

CleaningDate.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ startDate }) => ({
  startDate
});

const mapDispatchToProps = (dispatch, { startDate }) => ({
  startDate
});

export { CleaningDate as PureComponent };
export default withTranslation('Services/WindowCleaning')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CleaningDate)
);
