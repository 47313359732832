import deleteFavorite from './deleteFavorite';
import createFavorite from './createFavorite';

export default productId => (dispatch, getState) => {
  const { shop: { favorites: { list: { items } } } } = getState();

  dispatch(items.includes(productId)
    ? deleteFavorite(productId)
    : createFavorite(productId));
};
