import {
  CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST,
  CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS,
  CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    item: {}
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST:
      return { ...state, isFetching: true };

    case CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS:
      return { ...state, isFetching: false, fetchedAt: new Date(), item: action.payload };

    case CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
