import {
  AUTH_LOGIN_SUCCESS,
  CUSTOMER_CHANGE_SUCCESS,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null
  },
  action
) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case CUSTOMER_CHANGE_SUCCESS:
      return { ...state, fetchedAt: new Date() };

    case USER_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case USER_SUCCESS:
      return { ...state, isFetching: false, fetchedAt: new Date() };

    case USER_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
