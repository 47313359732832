import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchOffer, submitOffer as sendData, trackPartnerEmailAddress } from 'actions/partnerConfirmation';
import { DocumentTitle } from 'common';

import { trackOfferSeen } from 'actions/tracking';
import { CompanyDetails, DateBox, ExecutionDate, NotificationBox, PriceBox, TaskList, ExecutorBox, PONumber } from '.';

import './PartnerConfirmation.less';

const createState = ({
  match: {
    params: { token }
  },
  offer
}) => {
  const offerToken = token;
  const allRejected = false;
  const partnerOffer = offer;

  if (offer[offerToken] && offer[offerToken].item) {
    if (offer[offerToken].contract_signed_date) {
      return {
        partnerOffer,
        offerToken,
        allRejected: false
      };
    }
    if (offer[offerToken].item.contract_rejected_date && !offer[offerToken].contract_signed_date) {
      return {
        partnerOffer,
        offerToken,
        allRejected: true
      };
    }
  }

  return {
    partnerOffer,
    offerToken,
    allRejected
  };
};

class PartnerConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);
    this.state.scrollTop = 0;
    this.props.fetchData();
    this.offerOpenTime = null;
    this.isSubmitted = false;
  }

  componentDidMount() {
    this.props.trackOfferSeen(this.props.match.params.token);
    window.addEventListener('scroll', this.handleScroll);
    document.body.classList.add('partner-confirmation');
  }

  componentWillReceiveProps(nextProps) {
    const offerToken = this.props.match.params.token;

    if (
      this.props.match.params.token !== nextProps.match.params.token ||
      this.props.offer[offerToken] !== nextProps.offer[offerToken]
    ) {
      this.setState(createState(nextProps));

      if (nextProps.offer[offerToken].item) {
        this.props.trackPartnerEmailAddress(nextProps.offer[offerToken].item.signature_email);
      }
    }
  }

  componentDidUpdate(nextProps, nextState) {
    const { offerToken } = nextState;
    const { isSubmitting } = this.props.submitOffer;

    if (!this.isSubmitted && this.props.submitOffer.isSubmitted && !isSubmitting) {
      this.props.history.push(`/executive/${offerToken}#success-partner`);
      this.isSubmitted = true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove('partner-confirmation');
  }

  handleScroll = () => {
    this.setState(() => ({
      scrollTop: document.documentElement.scrollTop
    }));
  };

  handleClickTask = publicToken => {
    const { offer } = this.props;
    const { offerToken } = this.state;
    let performances = [];

    if (offer[offerToken].item.contract_rejected_date || offer[offerToken].item.contract_signed_date) {
      return;
    }

    performances = offer[offerToken].item.performances.map(item => {
      const performance = item;
      if (item.public_token === publicToken) {
        if (item.contract_status === 'confirmed' || !item.contract_status) {
          performance.contract_status = 'rejected';
        } else {
          performance.contract_status = 'confirmed';
        }
      }

      return performance;
    });

    const newState = {
      ...this.state,
      partnerOffer: {
        [offerToken]: {
          item: {
            ...this.state.partnerOffer[offerToken].item,
            performances
          }
        }
      }
    };
    this.setState(() => newState);
  };

  insertPONumber = ({ target: { value } }) => {
    const { offerToken } = this.state;

    const newState = {
      ...this.state,
      partnerOffer: {
        [offerToken]: {
          item: {
            ...this.state.partnerOffer[offerToken].item,
            po_number: value
          }
        }
      }
    };

    this.setState(() => newState);
  };

  handleSendOfferClick = (lostReason = '') => {
    const { offerToken } = this.state;
    const { performances } = this.state.partnerOffer[offerToken].item;
    const { submitOfferData } = this.props;

    const offerData = {};
    offerData.public_tokens = [];
    offerData.lost_reason_details = lostReason;
    offerData.po_number = this.state.partnerOffer[offerToken].item.po_number;

    performances.forEach(item => {
      offerData.public_tokens.push({
        public_token: item.public_token,
        action: !item.contract_status || item.contract_status === 'confirmed' ? 'CONFIRM' : 'REJECT'
      });
    });

    submitOfferData(offerData);
  };

  render() {
    const { t, offer } = this.props;
    const { partnerOffer, offerToken, allRejected } = this.state;
    const pdfUrl = `${process.env.REACT_APP_SPONE_API_URL}/offer/contract/${offerToken}`;

    if (!offer[offerToken] || !offer[offerToken].item) {
      return (
        <DocumentTitle title={t('title')}>
          <div className="offer-container col-group content-area partner-confirmation">
            <p>{t('no-offer')}</p>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <DocumentTitle title={t('title')}>
        <div className="offer-container col-group content-area partner-confirmation">
          <div className="header-wrapper">
            <header className="navbar">
              <div className="logo-wrapper">
                <img className="partner-logo" alt="logo" src={offer[offerToken].item.partner_logo} />
              </div>
            </header>
          </div>
          <NotificationBox
            offer={offer}
            offerToken={offerToken}
            subType={offer[offerToken].item.subtype}
            allRejected={allRejected}
          />
          <div className="offer-details col-8">
            <div className="offer-summary">
              <div className="offer-box">
                <h1 className="offer-h1">
                  {offer[offerToken].item.subtype === 'Offer' ? (
                    <span>{t('offer')}</span>
                  ) : (
                    <span>{t('performance-protocol')}</span>
                  )}{' '}
                  - {offer[offerToken].item.corporate_name}
                </h1>
                {offer[offerToken] && !offer[offerToken].item.contract_signed_date && (
                  <a href={pdfUrl} className="button button-blue pdf-button" rel="noopener noreferrer" target="_blank">
                    {t('view-pdf')}
                  </a>
                )}
                <p className="offer-subline">
                  {t('ID')} {offer[offerToken].item.quote_id}/ {t('customer-id')}{' '}
                  {offer[offerToken].item.account_number}
                </p>
                <PONumber
                  poNumber={offer[offerToken].item.po_number}
                  insertPONumber={this.insertPONumber}
                  offer={offer}
                  offerToken={offerToken}
                />
                <ExecutionDate offer={offer} offerToken={offerToken} subType={offer[offerToken].item.subtype} />
              </div>
              <CompanyDetails
                offer={offer}
                offerToken={offerToken}
                subType={offer[offerToken].item.subtype}
                allRejected={allRejected}
              />

              {offer[offerToken].item.performances && (
                <TaskList offer={partnerOffer} offerToken={offerToken} handleClickTask={this.handleClickTask} />
              )}
              {offer[offerToken].item.performances && offer[offerToken].item.subtype === 'Performance Note' && (
                <ExecutorBox offer={offer} offerToken={offerToken} />
              )}
              {offer[offerToken].item.subtype === 'Offer' && (
                <div className="offer-box offer-disclaimer">
                  <p>{t('footer-1')}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('footer-2', {
                        partner_name: offer[offerToken].item.partner_name,
                        partner_email: offer[offerToken].item.partner_staff_email,
                        partner_phone: offer[offerToken].item.partner_phone
                      })
                    }}
                  />
                  <p>{t('footer-3')}</p>
                </div>
              )}
              {offer[offerToken].item.subtype === 'Performance Note' && (
                <div className="offer-box offer-disclaimer">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('footer-2', {
                        partner_name: offer[offerToken].item.partner_name,
                        partner_email: offer[offerToken].item.partner_staff_email,
                        partner_phone: offer[offerToken].item.partner_phone
                      })
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="offer-pricecolumn col-4">
            <div className={this.state.scrollTop > 100 ? 'fixed-column' : 'offer-column'}>
              <PriceBox
                offer={partnerOffer}
                offerToken={offerToken}
                handleSendOfferClick={this.handleSendOfferClick}
                offerIsSent={
                  offer[offerToken].item.contract_rejected_date || offer[offerToken].item.contract_signed_date
                }
                disabled={Object.keys(this.props.submitOffer).length && this.props.submitOffer.isSubmitting}
              />
              <DateBox offer={offer} offerToken={offerToken} />
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

PartnerConfirmation.propTypes = {
  fetchData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  offer: PropTypes.object,
  t: PropTypes.func.isRequired,
  trackPartnerEmailAddress: PropTypes.func.isRequired,
  submitOfferData: PropTypes.func.isRequired,
  submitOffer: PropTypes.object
};

const mapStateToProps = ({ partnerConfirmation: { offer, submitOffer } }) => ({ offer, submitOffer });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { token }
    }
  }
) => ({
  fetchData: () => dispatch(fetchOffer(token)),
  token,
  trackPartnerEmailAddress: email => dispatch(trackPartnerEmailAddress(email)),
  trackOfferSeen: offerToken => dispatch(trackOfferSeen(offerToken)),
  submitOfferData: (offerToken, offerDetails) => dispatch(sendData(offerToken, offerDetails))
});

export { PartnerConfirmation as PureComponent };
export default withTranslation('PartnerConfirmation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnerConfirmation)
);
