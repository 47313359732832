import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SyncCalendarLink } from '.';

import './Navbar.less';

const Navbar = ({ currentDate, prevMonth, nextMonth, t }) => (
  <div className="col-group pad">
    <div className="timeline-headline">
      <div className="col-6">
        <span>{t('headline')}</span>

        <SyncCalendarLink />
      </div>

      <div className="col-6">
        <Link
          to={`/timeline/${nextMonth.format('YYYYMMDD')}`}
          className="button timeline-next-month"
          title={nextMonth.format('MMMM YYYY')}
        >
          &nbsp;
        </Link>

        <Link
          to={`/timeline/${prevMonth.format('YYYYMMDD')}`}
          className="button timeline-prev-month"
          title={prevMonth.format('MMMM YYYY')}
        >
          &nbsp;
        </Link>

        <div className="timeline-this-month">{currentDate.format('MMMM YYYY')}</div>
      </div>
    </div>
  </div>
);

Navbar.propTypes = {
  currentDate: PropTypes.object.isRequired,
  prevMonth: PropTypes.object.isRequired,
  nextMonth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default pure(withTranslation('Timeline')(Navbar));
