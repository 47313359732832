// @flow

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Public, { LoginFromUrl, LeadLoginFromUrl } from 'components/Public';
import Protected from 'components/Protected';

type Props = {
  isAuthenticated: boolean
};

const Routes = ({ isAuthenticated }: Props) => (
  <Switch>
    <Route path="/login/:jwtToken" component={LoginFromUrl} />
    <Route path="/leadlogin/:jwtToken/:masterToken" component={LeadLoginFromUrl} />

    {!isAuthenticated ? <Public /> : <Protected />}
  </Switch>
);

export default Routes;
