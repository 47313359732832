import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { currency } from 'components/HOC';
import { Loader } from 'common';
import { fetchDueInvoices } from 'actions/invoices';

import './Invoices.less';

export class Invoices extends Component {
  constructor(props) {
    super(props);

    this.overdueInvoices = [];
    this.unpaidInvoices = [];

    this.extractInvoicesByStatus(props.dueInvoices);
  }

  componentDidMount() {
    this.props.fetchDueInvoices();
  }

  componentWillReceiveProps(nextProps) {
    this.extractInvoicesByStatus(nextProps.dueInvoices);
  }

  getTotalAmount = invoices => {
    let amount = 0;
    invoices.forEach(item => {
      amount += item.amount;
    });

    return amount;
  };

  extractInvoicesByStatus = invoices => {
    const { items } = invoices;
    if (!items) return;

    items.forEach(item => {
      if (item.status.toLowerCase() === 'overdue') {
        this.overdueInvoices.push(item);
      }
      if (item.status.toLowerCase() === 'open') {
        this.unpaidInvoices.push(item);
      }
    });
  };

  render() {
    const { c, t, isFetching } = this.props;
    return (
      <div className="dashboard-modul modul-invoices">
        <div className="modul__header">{t('invoice-management')}</div>
        <div className="modul__bigrow">
          <Loader isLoading={isFetching} />
          <div className="flex">
            <div className="invoices-column overdue flex">
              <div className="title">{t('overdue')}</div>
              <div className="amount">{this.overdueInvoices.length}</div>
              <div className="total-amount">{`${t('total')}: ${c(this.getTotalAmount(this.overdueInvoices))}`}</div>
            </div>
            <div className="invoices-column unpaid flex">
              <div className="title">{t('coming-due')}</div>
              <div className="amount">{this.unpaidInvoices.length}</div>
              <div className="total-amount">{`${t('total')}: ${c(this.getTotalAmount(this.unpaidInvoices))}`}</div>
            </div>
          </div>
          <div className="link-to-invoices-page">
            <Link to="/invoices-orders" className="invoices-details">
              {t('see-invoices')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Invoices.propTypes = {
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired,
  dueInvoices: PropTypes.object
};

const mapStateToProps = state => ({
  isFetching: state.invoices.due.isFetching,
  dueInvoices: state.invoices.due
});

const mapDispatchToProps = {
  fetchDueInvoices
};

export { Invoices as PureComponent };
export default withTranslation('Dashboard/InvoiceManagement')(
  currency(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Invoices)
  )
);
