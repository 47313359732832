import { combineReducers } from 'redux';
import entities from './entities';
import list from './list';
import single from './single';
import products from './products';
import costs from './costs';

export default combineReducers({
  entities,
  list,
  single,
  products,
  costs
});
