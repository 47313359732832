import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Modal } from 'components/HOC';
import { fetchOffer } from 'actions/partnerConfirmation';

import './SuccessPartner.less';

export class SuccessPartner extends Component {
  constructor(props) {
    super(props);

    this.offerToken = window.location.pathname.split('/executive/')[1]; //eslint-disable-line
    this.props.fetchData(this.offerToken);
    this.pdfUrl = `${process.env.REACT_APP_SPONE_API_URL}/offer/contract/${this.offerToken}`;
  }

  handleCloseModal = () => {
    this.props.closeModal();
  };

  render() {
    const { t, offer } = this.props;

    if (!offer[this.offerToken] || !offer[this.offerToken].item) {
      return (
        <div className="offer-container col-group content-area">
          <div className="offer-details loading">
            <p className="offer-success__description">{t('fetch-offer')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="offer-container col-group">
        <div className="offer-details">
          <div
            className={`partner-success ${
              offer[this.offerToken].item.contract_rejected_date && !offer[this.offerToken].item.contract_signed_date
                ? 'rejected'
                : ''
            }`}
          >
            {offer[this.offerToken].item.contract_rejected_date &&
              !offer[this.offerToken].item.contract_signed_date &&
              offer[this.offerToken].item.subtype === 'Offer' && (
              <div>
                <p className="partner-success__description">{t('unsuccessful')}</p>
                <p className="partner-success__description">
                  {t('unsuccessful2', {
                    ServiceManager: offer[this.offerToken].item.partner_name
                  })}
                </p>
              </div>
            )}
            {offer[this.offerToken].item.contract_rejected_date &&
              !offer[this.offerToken].item.contract_signed_date &&
              offer[this.offerToken].item.subtype === 'Performance Note' && (
              <div>
                <p className="gegenbauer-success__description">
                  {t('unsuccessful-performance', {
                    ServiceManager: offer[this.offerToken].item.partner_name
                  })}
                </p>
              </div>
            )}
            {offer[this.offerToken].item.contract_signed_date && offer[this.offerToken].item.subtype === 'Offer' && (
              <div>
                <h1 className="partner-success__headline">{t('success-headline')}</h1>
                <p className="partner-success__description">{t('success-description')}</p>
                <p className="partner-success__description">{t('success-description2')}</p>
                <div>
                  <a href={this.pdfUrl} className="button button-blue" rel="noopener noreferrer" target="_blank">
                    {t('success-button')}
                  </a>
                </div>
              </div>
            )}
            {offer[this.offerToken].item.contract_signed_date &&
              offer[this.offerToken].item.subtype === 'Performance Note' && (
              <div>
                <h1 className="partner-success__headline">{t('success-headline')}</h1>
                <p className="partner-success__description">{t('success-description-performance')}</p>
                <div>
                  <a href={this.pdfUrl} className="button button-blue" rel="noopener noreferrer" target="_blank">
                    {t('success-button-performance')}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SuccessPartner.propTypes = {
  fetchData: PropTypes.func.isRequired,
  offer: PropTypes.object,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ partnerConfirmation: { offer } }) => ({ offer });

const mapDispatchToProps = dispatch => ({
  fetchData: offerToken => dispatch(fetchOffer(offerToken))
});

export { SuccessPartner as PureComponent };
export default withTranslation('PartnerConfirmation')(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Modal('Success Partner Modal', { className: 'success-partner--modal' })(SuccessPartner))
  )
);
