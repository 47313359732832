import {
  SHOP_PRODUCT_SUCCESS,
  SHOP_PRODUCTS_SUCCESS,
  SHOP_BESTSELLERS_SUCCESS,
  SERVICE_EVENT_PRODUCTS_SUCCESS,
  SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_PRODUCTS_SUCCESS:
      return action.payload.reduce((entities, item) => ({ ...entities, [item.id]: item }), state);

    case SHOP_BESTSELLERS_SUCCESS:
      return action.payload.reduce(
        (entities, item) => ({
          ...entities,
          ...item.products.reduce((products, product) => ({ ...products, [product.id]: product }), {})
        }),
        state
      );

    case SHOP_PRODUCT_SUCCESS:
      return { ...state, [action.payload.id]: action.payload };

    case SERVICE_EVENT_PRODUCTS_SUCCESS:
    case SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS:
      return action.payload.reduce((entities, item) => {
        if (!item.customer_product) return entities;

        return { ...entities, [item.customer_product.id]: item.customer_product };
      }, state);

    default:
      return state;
  }
};
