import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const ExecutionDate = ({ t, offer, offerToken, subType }) => (
  <div className="execution-date">
    {subType === 'Offer' && <p>
      {t('offer-and-date',
        {
          created_date: moment(offer[offerToken].item.created_date).format('DD.MM.YYYY')
        },
      )}
    </p>}
    {subType === 'Performance Note' && <p>
      {t('performance-and-date',
        {
          StartDate: moment(offer[offerToken].item.start_date).format('DD.MM.YYYY'),
          EndDate: moment(offer[offerToken].item.end_date).format('DD.MM.YYYY')
        },
      )}
    </p>}
  </div>
);

ExecutionDate.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export { ExecutionDate as PureComponent };
export default withTranslation('PartnerConfirmation')(ExecutionDate);
