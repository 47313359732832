import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { propCreator } from 'components/HOC';
import { WithCustomer } from 'components/DI';
import { Loader } from 'common';
import { fetchServiceEvents } from 'actions/serviceEvents';
import { groupServiceEventsBy } from 'selectors/serviceEvents';
import { Day } from '.';

import './Calendar.less';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { dates, currentPage, hasMore } = this.props;

    if (dates !== prevProps.dates) {
      this.fetchData();
    } else if (hasMore) {
      this.fetchData(currentPage + 1);
    }
  }

  fetchData(nextPage) {
    const { dispatch, dates, dataKey, currentPage, limit } = this.props;
    const startDate = dates[0].date;
    const endDate = dates[dates.length - 1].date;

    dispatch(
      fetchServiceEvents(dataKey, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        page: nextPage || currentPage,
        limit
      })
    );
  }

  render() {
    const { dates, isFetching, items } = this.props;

    return (
      <WithCustomer onChange={() => this.fetchData(1)}>
        <Loader isLoading={isFetching} />

        {dates.slice(0, 7).map(date => (
          <div key={`th-${date.key}`} className="timeline-weekday">
            <span className="timeline-day-date">{date.date.format('ddd')}</span>
          </div>
        ))}

        <div className="timeline-days">
          {dates.map(date => (
            <Day key={date.key} item={date} numberOfDays={dates.length} serviceEvents={items && items[date.key]} />
          ))}
        </div>
      </WithCustomer>
    );
  }
}

Calendar.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  limit: PropTypes.number,
  dates: PropTypes.array,
  isFetching: PropTypes.bool,
  items: PropTypes.object
};

Calendar.defaultProps = {
  limit: 50
};

const createProps = ({ dates }) => ({
  dataKey: `tmln_${dates[0].date.format('YYYYMMDD')}`
});

const mapStateToProps = () => {
  const getServiceEvents = groupServiceEventsBy('YYYYMMDD');

  return (state, props) => {
    const { dataKey } = props;
    const {
      serviceEvents: {
        list: { [dataKey]: { isFetching, currentPage = 1, hasMore } = {} }
      }
    } = state;

    return {
      isFetching,
      items: getServiceEvents(state, props),
      currentPage,
      hasMore
    };
  };
};

export default propCreator(createProps)(connect(mapStateToProps)(Calendar));
