import { CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS, CUSTOMER_UPDATE_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isChanging: false,
    isChanged: false
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_UPDATE_REQUEST:
      return { ...state, isChanging: true, isChanged: false };

    case CUSTOMER_UPDATE_SUCCESS:
      return { ...state, isChanging: false, isChanged: true };

    case CUSTOMER_UPDATE_FAILURE:
      return { ...state, isChanging: false };

    default:
      return state;
  }
};
