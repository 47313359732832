import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOCATION_QUERY_SED } from 'constants/location';
import { ReorderLink, FeedbackLink } from '.';

class Event extends Component {
    clickEvent = (event, link) => {
      if (event.target.nodeName !== 'A') {
        this.props.history.replace(link);
      }
    };

    render() {
      const { item, pathname } = this.props;
      const location = `${pathname}?${LOCATION_QUERY_SED}=${item.definition_id}#${item.id}`;

      return (
        <div className="modul__row" key={item.id}>
          <div
            className="se-link pointer"
            role="presentation"
            onClick={event => this.clickEvent(event, location)}
          >
            <div className="past-service-event relative flex">
              <div className="event-info flex">
                <span className={`se-circle ${item.type}`} />
                <div className="event-title ellipsis">
                  <span className="headline">{item.name}</span>
                </div>
                <div className="event-date">{item.start_date.format('ddd, DD.MM.YYYY')}</div>
              </div>
              <div className="event-buttons flex">
                <FeedbackLink item={item} />
                <ReorderLink item={item} location={location} />
              </div>
            </div>
          </div>
        </div>
      );
    }
}

Event.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export { Event as PureComponent };
export default withRouter(connect(mapStateToProps)(Event));
