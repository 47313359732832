import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setJwtToken } from 'actions/auth';
import { LOCATION_PATHNAME_DEFAULT } from 'constants/location';

const LoginFromUrl = ({ jwtToken, onMatch }) => {
  onMatch(jwtToken);

  return <Redirect to={LOCATION_PATHNAME_DEFAULT} />;
};

LoginFromUrl.propTypes = {
  jwtToken: PropTypes.string.isRequired,
  onMatch: PropTypes.func.isRequired
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { jwtToken }
    }
  }
) => ({ jwtToken });

const mapDispatchToProps = {
  onMatch: setJwtToken
};

export { LoginFromUrl as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFromUrl);
