import { BRAND_SET } from 'constants/actionTypes';

export default (state = null, action) => {
  switch (action.type) {
    case BRAND_SET:
      return action.brand || null;

    default:
      return state;
  }
};
