import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DocumentTitle, Transition } from 'common';
import { notifyError } from 'actions/notifications';
import { register } from 'actions/register';
import { RegisterStepOne, RegisterStepTwo } from '.';

import './Register.less';

const initialState = {
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  captcha: '',
  company: '',
  salutation: 'Frau',
  password: '',
  password_repeat: '',
  agbSet: false,
  country_code: 'DE',
  errors: {
    email: false,
    password: false,
    passwordrepeat: false,
    firstname: false,
    lastname: false,
    company: false,
    phone: false,
    agb: false
  },
  allValid: true
};

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeCountry = ({ target: { value } }) => {
    this.setState(() => ({ country_code: value }));
  };

  handleChange({ target: { type, name, value } }) {
    let realValue = value;

    if (type === 'radio') {
      realValue = value === 'true' ? 'Frau' : 'Herr';
    }

    if (type === 'checkbox') {
      this.setState(prevState => ({
        agbSet: !prevState.agbSet
      }));
    }

    if (this.state[name] !== realValue) {
      this.setState({
        [name]: realValue
      });
    }

    if (!this.state.allValid) {
      this.validate();
    }
  }

  handleCaptcha(value) {
    this.handleChange({
      target: {
        name: 'captcha',
        value
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, source } = this.props;
    const { allValid } = this.state;

    this.validate();

    if (allValid) {
      onSubmit({ ...this.state, source, base_url: window.location.origin });
    }
  }

  validate() {
    const {
      email,
      password,
      firstname,
      lastname,
      phone,
      company,
      password_repeat: passwordrepeat,
      agbSet
    } = this.state;
    const inputs = {
      email,
      password,
      firstname,
      lastname,
      phone,
      company,
      passwordrepeat
    };
    const errors = {
      email: false,
      password: false,
      passwordrepeat: false,
      firstname: false,
      lastname: false,
      company: false,
      phone: false,
      agb: false
    };
    let errorsFound = 0;
    const phoneRegex = /^\d+$/;

    Object.keys(inputs).forEach(input => {
      if (!inputs[input] || !inputs[input].trim()) {
        errorsFound += 1;
        errors[input] = true;
      }
    });

    if (password !== passwordrepeat) {
      errorsFound += 1;
      errors.passwordrepeat = true;
    }

    if (password.length <= 7) {
      errorsFound += 1;
      errors.password = true;
    }

    if (!phoneRegex.test(phone)) {
      errorsFound += 1;
      errors.phone = true;
    }

    if (agbSet !== true) {
      errorsFound += 1;
      errors.agb = true;
    }

    if (errorsFound) {
      this.setState({
        allValid: false,
        errors
      });
      return;
    }

    this.setState({
      allValid: true,
      errors: {}
    });
  }

  render() {
    const { step, t } = this.props;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="public__background">
          <header className="fake-website-header">
            <div className="content">
              <a href="https://one-ifm.com/" title="Service Partner ONE" rel="nofollow" className="logo">
                <img src="/images/logo/logo_SPONE.svg" alt="Service Partner ONE" />
              </a>
              <div className="info">
                <span>{t('headline')}</span>
              </div>
              <div className="login_register">
                <Link className="header-link" to="/login">
                  <span>{t('login')}</span>
                </Link>
              </div>
            </div>
          </header>
          <div className="public__box__register">
            <div className="register-view">
              <Transition className="fade" leaveTimeout={300}>
                {step === 1 && (
                  <RegisterStepOne
                    onChange={this.handleChange}
                    onChangeCaptcha={this.handleCaptcha}
                    onSubmit={this.handleSubmit}
                    changeCountry={this.changeCountry}
                    errorPassword={this.state.errors.password}
                    errorPasswordRepeat={this.state.errors.passwordrepeat}
                    errorEmail={this.state.errors.email}
                    errorFirstname={this.state.errors.firstname}
                    errorLastname={this.state.errors.lastname}
                    errorCompany={this.state.errors.company}
                    errorPhone={this.state.errors.phone}
                    errorAgb={this.state.errors.agb}
                  />
                )}
              </Transition>
              <Transition className="register-step-two fade">
                {step === 2 && (
                  <RegisterStepTwo token={this.props.token} onSubmit={this.handleSubmit} email={this.state.email} />
                )}
              </Transition>
            </div>
          </div>
          <div className="public_footer">
            <Link className="public_footer__link" to="https://www.servicepartner.one/de/impressum">
              {' '}
              {t('imprint')}
            </Link>
            <Link className="public_footer__link" to="https://one-ifm.com/en/datenschutzerklaerung/">
              {' '}
              {t('privacy-police')}
            </Link>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Register.propTypes = {
  step: PropTypes.number,
  source: PropTypes.string,
  t: PropTypes.func.isRequired
};

Register.defaultProps = {
  step: 1
};

const mapStateToProps = (
  { register: { step, token } },
  {
    match: {
      params: { source }
    }
  }
) => ({ step, token, source });

const mapDispatchToProps = {
  onSubmit: register,
  onError: notifyError
};

export default withTranslation('Public/Register')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Register)
);
