import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_CALENDAR_SYNC } from 'constants/location';

const SyncCalendarLink = ({ pathname, t }) => (
  <Link to={`${pathname}${LOCATION_HASH_CALENDAR_SYNC}`} className="button calendar-modal-button">
    {t('sync-calendar')}
  </Link>
);

SyncCalendarLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default withTranslation('Timeline')(connect(mapStateToProps)(SyncCalendarLink));
