/* eslint-disable react/no-did-update-set-state, camelcase */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Input, Select } from '@spone/ui';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import i18next from 'src/i18next';

import { SALUTATIONS } from 'constants/salutations';
import { LOCATION_HASH_CONFIRM_DEACTIVATE_MEMBER, LOCATION_HASH_CONFIRM_ACTIVATE_MEMBER } from 'constants/location';
import { PHONE_REG_EXP } from 'constants/validations';

import './MemberForm.less';

const DEFAULT_STATE = {
  customer_id: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  picture: '',
  salutation: '',
  inactive: false,
  id: null,
  image_url: null
};

const validationSchema = object({
  firstname: string().required(i18next.t('Form:required')),
  lastname: string().required(i18next.t('Form:required')),
  phone: string()
    .required(i18next.t('Form:required'))
    .matches(PHONE_REG_EXP, i18next.t('Form:phone')),
  email: string()
    .required(i18next.t('Form:required'))
    .email(i18next.t('Form:email'))
});

class MemberForm extends Component {
  constructor(props) {
    super(props);
    this.state = props.member || DEFAULT_STATE;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.member !== this.props.member && this.props.showForm) {
      this.setState({
        ...this.props.member
      });
    }
  }

  onChangeImage = event => {
    event.persist();

    if (event.target && event.target.files[0]) {
      this.setState({
        image_url: URL.createObjectURL(event.target.files[0]),
        picture: event.target.files[0]
      });
    }
  };

  removeImage = e => {
    if (this.state.image_url) {
      e.preventDefault();

      this.setState({
        image_url: '',
        picture: ''
      });
    }
  };

  onFormSubmit = (values, resetForm) => {
    const newMember = {
      email: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone,
      picture: this.state.picture,
      salutation: values.salutation,
      customer_id: this.props.customerId,
      id: this.state.id
    };

    this.props.submitForm(newMember);
    this.setState(DEFAULT_STATE);
    resetForm();
  };

  formatFullName = (firstName, lastName) =>
    firstName && lastName ? `${firstName.substring(0, 1)} ${lastName.substring(0, 1)}` : '';

  cancelEdit = () => {
    this.setState(DEFAULT_STATE);
    this.props.cancelEdit();
  };

  deleteMember = () => {
    this.props.deleteMember(this.state.id);
    this.setState(DEFAULT_STATE);
  };

  getInitialValues = () => {
    return {
      firstname: this.state.firstname || DEFAULT_STATE.firstname,
      lastname: this.state.lastname || DEFAULT_STATE.lastname,
      phone: this.state.phone || DEFAULT_STATE.phone,
      email: this.state.email || DEFAULT_STATE.email,
      salutation: this.state.salutation || DEFAULT_STATE.salutation
    };
  };

  render() {
    const { image_url, firstname, lastname, inactive } = this.state;
    const { t, isEdit, showForm } = this.props;
    const btnText = isEdit ? t('Members:update') : t('Members:save');
    const pageTitle = isEdit ? t('Members:edit') : t('Members:create');

    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => this.onFormSubmit(values, resetForm)}
      >
        {() => (
          <Form className="member-form">
            <div className="page-header">
              <h1 className="page-title">{pageTitle}</h1>
            </div>

            <div
              className={classNames('member-photo', {
                hasPhoto: image_url && image_url.length > 0,
                noPhoto: !image_url || image_url.length === 0,
                inactive
              })}
              style={{ backgroundImage: `url(${image_url})` }}
            >
              {!image_url && (
                <div className="member-photo-wrap">
                  <input name="userImage" id="userImage" type="file" accept="image/*" onChange={this.onChangeImage} />

                  <span className="dummy">{this.formatFullName(firstname, lastname)}</span>
                </div>
              )}
              <label
                // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
                role="presentation"
                htmlFor="userImage"
                className={classNames('member-photo-icon', { remove: image_url && image_url.length > 0 })}
                // eslint-disable-next-line
                onClick={this.removeImage}
              />
            </div>

            <div className="form-row">
              <Field component={Select} name="salutation" label={t('salutation')} options={SALUTATIONS} />
            </div>

            <div className="form-row">
              <Field type="text" component={Input} name="firstname" label={t('firstname')} />

              <Field type="text" component={Input} name="lastname" label={t('lastname')} />
            </div>

            <div className="form-row full">
              <Field type="tel" component={Input} name="phone" label={t('phone')} tooltip={t('Form:phone-tip')} />
            </div>

            <div className="form-row full">
              <Field type="email" component={Input} name="email" label={t('email')} disabled={isEdit} />
            </div>

            <div className={classNames('form-buttons', { edit: isEdit })}>
              {showForm && (
                <button className="link form-cancel-btn" type="button" onClick={this.cancelEdit}>
                  {t('cancel')}
                </button>
              )}

              <div>
                {isEdit && (
                  <Link
                    to={{
                      hash: inactive ? LOCATION_HASH_CONFIRM_ACTIVATE_MEMBER : LOCATION_HASH_CONFIRM_DEACTIVATE_MEMBER,
                      state: { id: this.state.id }
                    }}
                    className="link"
                  >
                    {inactive ? t('activate-member') : t('deactivate-member')}
                  </Link>
                )}

                <button type="submit" className="button button-blue form-submit-btn">
                  {btnText}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ current: { customer } }) => ({
  customerId: customer && customer.id
});

export default withTranslation(['Members', 'Form'])(connect(mapStateToProps)(MemberForm));
