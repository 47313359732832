import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import {
  SCHEDULE_DURATION_3_MONTHS,
  SCHEDULE_DURATION_6_MONTHS,
  SCHEDULE_DURATION_12_MONTHS
} from 'constants/schedule';
import { Select } from 'common';
import { translateEditing } from '../../../../HOC';

const Duration = ({ duration, onChange, t }) => (
  <Select
    value={duration}
    onChange={({ target: { value } }) => onChange(value)}
    options={[
      { value: SCHEDULE_DURATION_3_MONTHS, name: t('duration-3-months') },
      { value: SCHEDULE_DURATION_6_MONTHS, name: t('duration-6-months') },
      { value: SCHEDULE_DURATION_12_MONTHS, name: t('duration-12-months') }
    ]}
    className="shipping-duration"
    parentClass="shipping-duration-wrapper"
  />
);

Duration.propTypes = {
  duration: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

Duration.defaultProps = {
  duration: SCHEDULE_DURATION_3_MONTHS
};

export { Duration as PureComponent };
export default pure(translateEditing(Duration));
