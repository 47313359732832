import { CONTENT_TYPE } from '../constants/headers';

const emptyCodes = [204, 205];

export default response => {
  const contentType = response.headers.get(CONTENT_TYPE);

  if (contentType && contentType.includes('json') && !emptyCodes.includes(response.status)) {
    return response.clone().json();
  }

  return Promise.resolve();
};
