import { combineReducers } from 'redux';
import entities from './entities';
import list from './list';
import note from './note';
import deposit from './deposit';
import single from './single';
import products from './products';
import cleaningConfig from './cleaningConfig';
import cleaningCalc from './cleaningCalc';
import cleaningRequest from './cleaningRequest';
import intensiveCleaningConfig from './intensiveCleaningConfig';
import intensiveCleaningRequest from './intensiveCleaningRequest';
import intensiveCleaningSubmit from './intensiveCleaningSubmit';
import submitCleaning from './submitCleaning';
import windowCleaning from './windowCleaning';
import windowCleaningOversized from './windowCleaningOversized';
import additionalCleaning from './additionalCleaning';
import gegenbauerCleaning from './gegenbauerCleaning';
import rate from './rate';
import status from './status';
import filters from './filters';
import feedback from './feedback';

export default combineReducers({
  filters,
  entities,
  list,
  note,
  deposit,
  single,
  products,
  cleaningConfig,
  cleaningCalc,
  submitCleaning,
  windowCleaning,
  windowCleaningOversized,
  additionalCleaning,
  gegenbauerCleaning,
  rate,
  status,
  cleaningRequest,
  intensiveCleaningConfig,
  intensiveCleaningRequest,
  intensiveCleaningSubmit,
  feedback
});
