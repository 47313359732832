/* eslint-disable jsx-a11y/label-has-associated-control, camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal } from '@spone/ui';

import { fetchIssue, updateIssue } from 'actions/issues';
import { DocumentTitle } from 'common';
import { getActiveMembers } from 'selectors/members';
import { getPriorities, getStatuses, formatList, mapPriorities } from '../../helper';

import './EditIssue.less';

// TODO: remove when BE websocket will be done for WI;
// export const API_URL = process.env.REACT_APP_SPONE_API_URL;
export const API_URL = 'https://partner-dev.servicepartner.one/';

class EditIssue extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      status: null,
      priority: null,
      assignee_id: null,
      fullDescription: false,
      showImage: false
    };

    this.state = this.defaultState;
    this.statuses = getStatuses(props.t);
    this.priorities = getPriorities(props.t);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getIssue(id).then(() => {
      if (this.props.issue) {
        this.setState({
          status: this.props.issue.status,
          priority: this.props.issue.priority.toString(),
          assignee_id: this.props.issue.assignee_id,
          id: this.props.issue.id,
          showImage: false,
          showMoreButton: false
        });

        this.formatDescription();
      }
    });
  }

  formatDescription = () => {
    const { issue } = this.props;

    if (issue && issue.description) {
      if (issue.description.length > 80) {
        this.setState({
          showMoreButton: true
        });
      }
    }
  };

  onChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;

    this.setState({
      [name]: value
    });
  };

  submitForm = e => {
    e.preventDefault();

    this.props.updateIssue(this.state).then(() => {
      this.setState(this.defaultState);
      this.props.history.push('/issues');
    });
  };

  toggleDescription = () => {
    this.setState(prevState => ({
      fullDescription: !prevState.fullDescription
    }));
  };

  showFullImage = () => {
    this.setState({
      showImage: true
    });
  };

  hideFullImage = () => {
    this.setState({
      showImage: false
    });
  };

  // renderMessenger() {
  //     const { t, authToken, userId } = this.props;
  //     const { params } = this.props.match;
  //     const issueId = params.id;

  //     return (
  //         <Messenger
  //             apiUrl={`${API_URL}/`}
  //             authToken={authToken}
  //             topics={[`/topic/${userId}/issues/${issueId}`]}
  //             chatId={issueId}
  //             comments={[]}
  //             writeComment={() => {}}
  //             trans={{ write_answer: t('write-answer') }}
  //         />
  //     );
  // }

  render() {
    const { t, issue, members } = this.props;
    const { status, priority, assignee_id, fullDescription, showImage, showMoreButton } = this.state;
    if (!issue) {
      return null;
    }
    const assignee = members && formatList(members, 'fullname');
    const image = issue.image_url ? issue.image_url : '/images/issues/dummy2.svg';
    const creatorImage =
      issue && issue.contact_picture ? issue.contact_picture : '/images/icons/spo-viewallusers-icon.svg';

    return (
      <DocumentTitle title={t('issue-overview')}>
        <div className="create-issue edit-issue content-area">
          <div className="page-header">
            <h1 className="page-title">
              <div className="page-title-left">
                <Link to="/issues">{t('headline')}</Link>
                <span className="divider" /> {issue.title} <span className="issue-number">- #{issue.id}</span>
              </div>

              <button type="button" className="button button-blue btn-convert">
                {t('convert-into-booking')}
              </button>
            </h1>
          </div>

          <div className="page-subheader">
            <div
              className="image"
              role="presentation"
              onClick={this.showFullImage}
              style={{ backgroundImage: `url('${image}')` }}
            />

            <Modal isOpen={showImage} onClose={this.hideFullImage}>
              <img src={image} alt="mainpic" />
            </Modal>

            <div className="details">
              <div className="address">
                <span className="icon address-icon" />
                {issue.customer_name && <span>{issue.customer_name}</span>}
                {issue.floor && <span>{`, ${t('floor')} ${issue.floor}`}</span>}
                {issue.room && <span>{`, ${t('room')} ${issue.room}`}</span>}
              </div>

              <div className="creator">
                <div className="user">
                  <span className="user-image" style={{ backgroundImage: `url('${creatorImage}')` }} />
                  {`${issue.contact_first_name} ${issue.contact_last_name}`}
                </div>
                <span className="type">{t('qaApp')}</span>
                <span className="time">{moment(issue.created_date).fromNow()}</span>
              </div>

              {issue.description && (
                <div className={classNames('description', { full: fullDescription })}>
                  {issue.description}{' '}
                  {showMoreButton && (
                    <span role="presentation" onClick={this.toggleDescription}>
                      {fullDescription ? t('show-less') : t('show-more')}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="page-content">
            {/* <div className="messanger">{this.renderMessenger()}</div> */}

            <form className="issue-form properties-form" onSubmit={this.submitForm}>
              <div className="title">{t('issue-properties')}</div>

              <div className="form-row">
                <div className="form-elements-group">
                  <div className="label">{t('status')}</div>
                  <Dropdown
                    className="Dropdown-flat status-dropdown"
                    options={this.statuses}
                    onChange={e => this.onChange(e, 'status')}
                    value={status}
                    placeholder={t('pick-status')}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-elements-group">
                  <div className="label">{t('priority')}</div>
                  <Dropdown
                    className="Dropdown-flat"
                    options={this.priorities}
                    onChange={e => this.onChange(e, 'priority')}
                    value={mapPriorities(t, priority)}
                    placeholder={t('choose-priority')}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-elements-group">
                  <div className="label">{t('assignee')}</div>
                  <Dropdown
                    className="Dropdown-flat"
                    options={assignee}
                    onChange={e => this.onChange(e, 'assignee_id')}
                    value={assignee_id}
                    placeholder={t('assign-task')}
                  />
                </div>
              </div>

              <button type="submit" className="button button-blue button-secondary form-submit-btn">
                {t('update')}
              </button>
            </form>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

EditIssue.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authToken: state.auth.data.jwtToken,
  userId: state.current.user.id,
  issue: state.issues.issue,
  members: getActiveMembers(state.members.items) || []
});

const mapDispatchToProps = dispatch => ({
  getIssue: id => dispatch(fetchIssue(id)),
  updateIssue: issue => dispatch(updateIssue(issue))
});

export { EditIssue as PureComponent };
export default pure(
  withTranslation('Issues')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(EditIssue))
  )
);
