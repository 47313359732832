import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { payment } from 'actions/shop/cart';
import { translateEditing } from '../HOC';

const PaymentButton = ({ isLoading, isDisabled, onClick, t }) => (
  <button
    type="button"
    className={`button cart-submit ${isLoading ? 'loading-light' : ''} ${isDisabled ? 'disabled' : ''}`}
    onClick={onClick}
  >
    {t('next')}
  </button>
);

PaymentButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLoading: state.shop.cart.isRequestingPayment,
  isDisabled: state.shop.cart.isCancelling || state.shop.cart.isDeleting
});

const mapDispatchToProps = {
  onClick: payment
};

export { PaymentButton as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentButton)
);
