import { combineReducers } from 'redux';
import create from './create';
import remove from './delete';
import list from './list';

export default combineReducers({
  create,
  delete: remove,
  list
});
