import React, { PureComponent } from 'react';
import uniqueId from 'lodash/uniqueId';

const PREFIX = 'html-id-';

export default WrappedComponent => {
  class HTMLId extends PureComponent {

    constructor(props) {
      super(props);

      this.state = {
        id: props.id || this.getUniqueId()
      };
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.id !== nextProps.id) {
        this.setState({
          id: nextProps.id || this.getUniqueId()
        });
      }
    }

    getUniqueId() {
      this.id = this.id || uniqueId(PREFIX);
      return this.id;
    }

    render() {
      const mergedProps = { ...this.props, ...this.state };

      return <WrappedComponent {...mergedProps} />;
    }
  }

  return HTMLId;
};
