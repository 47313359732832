import {
  SHOP_CART_ORDER_REQUEST,
  SHOP_CART_ORDER_SUCCESS,
  SHOP_CART_ORDER_FAILURE
} from 'constants/actionTypes';
import { PAYMENT_METHOD_BILLING, PAYMENT_METHOD_CREDIT_CARD } from 'constants/paymentMethods';
import { SHOPPING_CART_STEP_PAYMENT } from 'constants/shoppingCart';
import { authCall } from 'api';

const getBodyForBilling = ({
  shop: {
    payment: {
      billing: { token }
    }
  }
}) => ({ billing_token: token });

const getBodyForCreditCard = ({
  shop: {
    payment: {
      creditCards: { list, selected }
    }
  }
}) => {
  const creditCard = list.find(cc => cc.id === selected);

  return {
    credit_card_id: creditCard && creditCard.id,
    credit_card_token: creditCard && creditCard.isNew && creditCard.token,
    save_credit_card: !!(creditCard && creditCard.save)
  };
};

export default () =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_PAYMENT && !state.shop.cart.isOrdering,
    types: [SHOP_CART_ORDER_REQUEST, SHOP_CART_ORDER_SUCCESS, SHOP_CART_ORDER_FAILURE],
    endpoint: ({
      shop: {
        payment: { selectedMethod }
      }
    }) => `/user/shop/cart/order/${selectedMethod}`,
    method: 'POST',
    body: state => {
      const {
        shop: {
          payment: { selectedMethod }
        }
      } = state;

      switch (selectedMethod) {
        case PAYMENT_METHOD_BILLING:
          return getBodyForBilling(state);

        case PAYMENT_METHOD_CREDIT_CARD:
          return getBodyForCreditCard(state);

        default:
          throw new Error('No payment method selected');
      }
    }
  });
