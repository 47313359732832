/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createScript } from 'utils';

class SnapChat extends Component {
  componentDidMount() {
    const { user, customer } = this.props;
    this.createChat(user, customer);
  }

  componentWillReceiveProps({ user, customer }) {
    if (this.props.user !== user || this.props.customer !== customer) {
      this.createChat(user, customer);
    }
  }

  componentWillUnmount() {
    this.removeChat();
  }

  init = (gslbBaseURL, firstname, lastname, email, accountid) => {
    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = JSON.parse(localStorage.getItem('language'));

    window.embedded_svc.settings.extraPrechatFormDetails = [
      { label: 'First Name', value: firstname, transcriptFields: [], displayToAgent: true },
      { label: 'Last Name', value: lastname, transcriptFields: [], displayToAgent: true },
      { label: 'Email', value: email, transcriptFields: [], displayToAgent: true },
      { label: 'accountid', value: accountid, transcriptFields: [], displayToAgent: false }
    ];

    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityName: 'Contact',
        showOnCreate: true,
        linkToEntityName: 'Case',
        linkToEntityField: 'ContactId',
        saveToTranscript: 'Contact',
        entityFieldMaps: [
          { isExactMatch: true, fieldName: 'FirstName', doCreate: false, doFind: true, label: 'First Name' },
          { isExactMatch: true, fieldName: 'LastName', doCreate: false, doFind: true, label: 'Last Name' },
          { isExactMatch: true, fieldName: 'Email', doCreate: false, doFind: true, label: 'Email' },
          { isExactMatch: true, fieldName: 'Type__c', doCreate: false, doFind: true, label: 'type' }
        ]
      },
      {
        entityName: 'Account',
        showOnCreate: true,
        entityFieldMaps: [{ isExactMatch: true, fieldName: 'Id', doCreate: false, doFind: true, label: 'accountid' }]
      },
      {
        entityName: 'Case',
        showOnCreate: true,
        saveToTranscript: 'Case',
        entityFieldMaps: [
          { isExactMatch: false, fieldName: 'Subject', doCreate: true, doFind: false, label: 'issue' },
          { isExactMatch: false, fieldName: 'Status', doCreate: true, doFind: false, label: 'Status' },
          { isExactMatch: false, fieldName: 'Origin', doCreate: true, doFind: false, label: 'Origin' },
          { isExactMatch: false, fieldName: 'Type', doCreate: true, doFind: false, label: 'casetype' }
        ]
      }
    ];

    // Settings for Live Agent
    window.embedded_svc.settings.avatarImgURL =
      'https://web.servicepartner.one/images/icons/spo-dashboard-livechat-icon-margareta.jpg';
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.init(
      process.env.REACT_APP_SALESFORCE_CHAT_URL,
      process.env.REACT_APP_SALESFORCE_LIVE_CHAT_URL,
      gslbBaseURL,
      process.env.REACT_APP_SALESFORCE_ORG_ID,
      process.env.REACT_APP_SALESFORCE_CHAT_NAME,
      {
        baseLiveAgentContentURL: process.env.REACT_APP_SALESFORCE_LIVE_AGENT_CONTENT_URL,
        deploymentId: process.env.REACT_APP_SALESFORCE_LIVE_AGENT_ID,
        buttonId: '5732400000000zj',
        baseLiveAgentURL: process.env.REACT_APP_SALESFORCE_LIVE_AGENT_CHAT_URL,
        eswLiveAgentDevName: process.env.REACT_APP_SALESFORCE_ESW_LIVE_AGENT_DEV_NAME
      }
    );
  };

  createChat = (user, customer) => {
    if (!user || !customer) return;

    const personalInfo = user.fullname.split(' ');
    if (!window.embedded_svc) {
      if (!this.script) {
        this.script = createScript(process.env.REACT_APP_SALESFORCE_LIVE_AGENT_SCRIPT_URL);
      }
      this.script.onload = () => {
        this.init(null, personalInfo[0], personalInfo[1], user.email, customer.id);
      };
    } else {
      this.init(process.env.REACT_APP_SALESFORCE_CHAT_URL, personalInfo[0], personalInfo[1], user.email, customer.id);
    }
  };

  removeChat = () => {
    if (!this.script) return;

    const chats = document.querySelectorAll('.embeddedServiceHelpButton');
    if (chats && chats.length > 0) {
      chats.forEach(el => el.parentNode.removeChild(el));
    }

    this.script.remove();
    this.script = null;

    delete window.embedded_svc;
  };

  render() {
    return <div className="livechat" />;
  }
}

SnapChat.propTypes = {
  user: PropTypes.object,
  customer: PropTypes.object
};

const mapStateToProps = ({ current: { user, customer } }) => ({ user, customer });

export { SnapChat as PureComponent };
export default connect(mapStateToProps)(SnapChat);
