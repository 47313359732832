import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchBestsellers } from 'actions/shop/bestsellers';
import { getBestsellers } from 'selectors/shop';
import { WithCustomer, WithPartnerIds } from 'components/DI';
import { BestsellerTeaser } from '.';

class Bestsellers extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    const { isLoading, items, fetchData } = this.props;

    return (
      <div className={`${isLoading ? 'loading' : ''}`}>
        {items && items.map(item => <BestsellerTeaser key={item.id} item={item} />)}

        <WithCustomer onChange={fetchData} />
        <WithPartnerIds onChange={fetchData} />
      </div>
    );
  }
}

Bestsellers.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  fetchData: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    shop: {
      bestsellers: { isFetching }
    }
  } = state;
  const items = getBestsellers(state);

  return {
    isFetching: !items && isFetching,
    items
  };
};

const mapDispatchToProps = {
  fetchData: fetchBestsellers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bestsellers);
