import {
  SHOP_FAVORITE_DELETE_REQUEST,
  SHOP_FAVORITE_DELETE_SUCCESS,
  SHOP_FAVORITE_DELETE_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_FAVORITE_DELETE_REQUEST: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isDeleting: true, isDeleted: false } };
    }

    case SHOP_FAVORITE_DELETE_SUCCESS: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isDeleting: false, isDeleted: true } };
    }

    case SHOP_FAVORITE_DELETE_FAILURE: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isDeleting: false } };
    }

    default:
      return state;
  }
};
