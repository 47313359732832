import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FeedbackLink = ({ item, t }) => {
  const hasFeedback = !!item.has_feedback;
  return (
    <Link
      className={`button ${hasFeedback ? 'button-subtle' : 'button-regular'}`}
      to={`/feedback/${item.id}/${item.start_date}`}
    >
      {hasFeedback ? t('view-feedback') : t('submit-feedback')}
    </Link>
  );
};

FeedbackLink.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation('Dashboard/PastEvents')(FeedbackLink);
