import storage from 'store';
import { LOCAL_STORAGE_JWT_TOKEN, LOCAL_STORAGE_MASTER_TOKEN } from '../../constants/localStorage';
import memoize from '../memoize';

const getAuthData = state => state.auth.data;

export default memoize([getAuthData], ({ jwtToken, masterToken }) => {
  storage.set(LOCAL_STORAGE_JWT_TOKEN, jwtToken);
  storage.set(LOCAL_STORAGE_MASTER_TOKEN, masterToken);
});
