import storage from 'store';
import { storeAuth, storeLanguage, storeSettings } from './persisters';

export default store => {
  if (!storage.enabled) return;

  store.subscribe(() => {
    const state = store.getState();

    storeLanguage(state);
    storeAuth(state);
    storeSettings(state);
  });
};
