import React from 'react';
import { pure } from 'recompose';
import { FavoriteTeaser, Bestsellers, EmptyTeaser } from '.';

import './PersonalOverview.less';

const PersonalOverview = () => (
  <div className="shop-module shop-module-slider">
    <FavoriteTeaser />
    <Bestsellers />
    <EmptyTeaser />
  </div>
);

export default pure(PersonalOverview);
