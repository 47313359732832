import {
  USER_PASSWORD_SET_REQUEST,
  USER_PASSWORD_SET_SUCCESS,
  USER_PASSWORD_SET_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default password =>
  authCall({
    shouldFetch: state => !state.users.password.set.isSetting,
    types: [USER_PASSWORD_SET_REQUEST, USER_PASSWORD_SET_SUCCESS, USER_PASSWORD_SET_FAILURE],
    endpoint: '/user/users/current/password',
    method: 'POST',
    body: { password }
  });
