import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';
import { recoverPassword } from 'actions/password';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ email: this.emailInput.value });

    this.props.onSubmit(this.emailInput.value, this.recaptcha && this.recaptcha.getValue());
  }

  render() {
    const { isRecovering, isCaptchaRequired, t, isRecovered } = this.props;
    const { email } = this.state;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="modal-password">
          <div className="modal-password-inside">
            <p className="headline">{t('headline')}</p>
            {isRecovered ? (
              <div className="modal-password-alert" dangerouslySetInnerHTML={{ __html: t('alert', { email }) }} />
            ) : (
              <div>
                <p className="modal-password-text">{t('enter-email')}</p>
                <form onSubmit={this.handleSubmit}>
                  <fieldset disabled={isRecovering}>
                    <div className="form-group">
                      <input
                        type="email"
                        ref={ref => {
                          this.emailInput = ref;
                        }}
                        className="form-control"
                        placeholder={t('email')}
                        required
                      />
                    </div>

                    {isCaptchaRequired && (
                      <div className="recaptcha">
                        <ReCAPTCHA
                          ref={ref => {
                            this.recaptcha = ref;
                          }}
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                          onChange={() => {}}
                        />
                      </div>
                    )}

                    <button type="submit" className={`button relative ${isRecovering ? 'loading' : ''}`}>
                      {t('send')}
                    </button>
                  </fieldset>
                </form>
              </div>
            )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isRecovering: PropTypes.bool,
  isRecovered: PropTypes.bool,
  isCaptchaRequired: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  password: {
    recover: { isRecovering, isRecovered, isCaptchaRequired }
  }
}) => ({ isRecovering, isRecovered, isCaptchaRequired });

const mapDispatchToProps = {
  onSubmit: recoverPassword
};

export { ResetPassword as PureComponent };

export default withTranslation('Public/ResetPassword')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Reset Password', { className: 'password-modal' })(ResetPassword))
);
