import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Scheduler } from ".";

const Details = ({ item: { id, schedule } }) => (
  <div className="cart-details">
    <Scheduler
      id={id}
      minDate={schedule.min_date}
      dueDate={schedule.due_date}
      duration={schedule.duration}
      rrule={schedule.rrule}
    />
  </div>
);

Details.propTypes = {
  item: PropTypes.object.isRequired
};

export { Details as PureComponent };
export default pure(Details);
