import storage from 'store';
import { AUTH_RESTORE } from 'constants/actionTypes';
import { LOCAL_STORAGE_JWT_TOKEN, LOCAL_STORAGE_MASTER_TOKEN } from 'constants/localStorage';

export default () => dispatch => {
  if (!storage.enabled) return;

  const jwtToken = storage.get(LOCAL_STORAGE_JWT_TOKEN);
  const masterToken = storage.get(LOCAL_STORAGE_MASTER_TOKEN);

  if (jwtToken && masterToken) {
    dispatch({
      type: AUTH_RESTORE,
      jwtToken,
      masterToken
    });
  }
};
