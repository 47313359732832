import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import omit from 'lodash/omit';

export const Tooltip = props => {
  const { id, children, place } = props;
  const tooltip = <ReactTooltip id={id} place={place} />;

  if (!children) return tooltip;

  const passedProps = omit(props, 'id');

  return (
    <div {...passedProps}>
      {children}
      {tooltip}
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.string
};

export default pure(Tooltip);
