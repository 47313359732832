import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import responsive from 'components/HOC/responsive';
import Product, { EmptyProduct } from '../../Product';

const SliderTeaser = ({ location, locationText, items }) => {
  const children = items.map(item => <Product key={item.id} item={item} />);

  children.push(
    <Link key="slider-item-link" to={location}>
      <EmptyProduct name={locationText} />
    </Link>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    variableWidth: true
  };

  return (
    <div className="slide-wrapper">
      <Slider {...settings}>
        {children.map(
          (item, index) =>
            // eslint-disable-next-line react/no-array-index-key
            item || <EmptyProduct key={`slider-item-${index}`} />
        )}
      </Slider>
    </div>
  );
};

SliderTeaser.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  locationText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default responsive()(SliderTeaser);
