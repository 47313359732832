import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { pure } from 'recompose';
import { Formik, Form, Field } from 'formik';
import { Button, Textarea, Datepicker, Input } from '@spone/ui';
import { format } from 'date-fns';
import { get } from 'lodash';
import { RRule } from 'rrule';

import { DaysCheckboxGroup } from 'components/OfferReview';

import './OfferAcceptModal.less';

export const OfferAcceptModal = ({ t, handleAcceptOffer, offer }) => {
  const [initialValues, setInitialValue] = useState({
    startDate: offer.start_date ? new Date(offer.start_date) : null,
    startTime: get(offer, 'startTime') || '',
    endTime: get(offer, 'endTime') || '',
    comment: '',
    days: []
  });

  const handleSubmitStartDate = (date, setFieldValue) => {
    setFieldValue('startDate', format(new Date(date), 'yyyy-MM-dd'));
  };

  useEffect(() => {
    if (offer.rrule && offer.type === 'recurrent') {
      const rule = RRule.fromString(offer.rrule);
      // GEt rrule days if it's weekly (2)
      if (rule.options.freq === 2) {
        const days = get(rule, 'options.byweekday') || [];

        setInitialValue({ ...initialValues, days });
      }
    }
  }, [initialValues, offer.rrule, offer.type]);

  return (
    <div className="offer-accept-modal">
      <p>{t('OfferReview/OfferAcceptModal:modal-text')}</p>

      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleAcceptOffer}>
        {({ values, setFieldValue, setFieldTouched }) => (
          <Form>
            <div className="form-field">
              <Field
                component={Datepicker}
                name="startDate"
                dateFormat="dd.MM.yyyy"
                showIcon
                disabled
                selectedDate={values.startDate}
                onChange={date => handleSubmitStartDate(date, setFieldValue)}
                label={t('OfferReview/OfferAcceptModal:start-date-label')}
                placeholder={t('OfferReview/OfferAcceptModal:start-date-placeholder')}
              />
            </div>
            <div className="form-field">
              <div className="form-field-label">{t('OfferReview/OfferAcceptModal:select-days')}</div>

              <DaysCheckboxGroup
                name="days"
                valuesArray={values.days}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
            </div>

            <div className="form-field">
              <div className="form-field-label">{t('OfferReview/OfferAcceptModal:suggest-time')}</div>

              <div className="form-field-two">
                <Field
                  component={Input}
                  label={t('OfferReview/OfferAcceptModal:start-time-label')}
                  name="startTime"
                  type="time"
                  className="hasValue field-start-time"
                />

                <Field
                  component={Input}
                  label={t('OfferReview/OfferAcceptModal:end-time-label')}
                  name="endTime"
                  type="time"
                  className="hasValue field-end-time"
                />
              </div>
            </div>

            <div className="form-field">
              <Field
                component={Textarea}
                rows={4}
                label={t('OfferReview/OfferAcceptModal:comment-label')}
                placeholder={t('OfferReview/OfferAcceptModal:comment-placeholder')}
                name="comment"
              />
            </div>

            <div className="form-buttons">
              <Button type="submit">{t('OfferReview/OfferAcceptModal:submit')}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default pure(withTranslation(['OfferReview/OfferAcceptModal', 'Form'])(OfferAcceptModal));
