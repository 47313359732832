import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18N_DEFAULT_LANGUAGE } from './constants/i18n';

i18next.use(initReactI18next).init({
  lng: I18N_DEFAULT_LANGUAGE,
  fallbackLng: I18N_DEFAULT_LANGUAGE,
  fallbackNS: 'Common',
  resources: {
    de: {
      Common: {
        'no-tax': 'exkl. USt',
        'vertical-drinks': 'Getränke',
        'vertical-fruits': 'Früchte',
        'vertical-rental': 'Miete oder Leasing',
        'vertical-coffee': 'Kaffee',
        'vertical-cleaning': 'Reinigung',
        'vertical-supplies': 'Verbrauchsmaterialien',
        'vertical-handymen': 'Handwerker',
        'vertical-equipment': 'Equipment',
        'vertical-food': 'Lebensmittel',
        'vertical-catering': 'Catering',
        'vertical-misc': 'Sonstiges',
        'vertical-relocation': 'Umzug',
        'vertical-total': 'Gesamtkosten',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Uhrzeit'
      },
      Form: {
        required: 'Erforderlich',
        phone: 'Telefonnummer ist ungültig',
        'phone-tip': 'Beispiel: +4917312345678',
        email: 'E-Mail ist ungültig'
      },
      'Ads/RecommendCampaignDE': {
        label: 'Special',
        headline: 'Service Partner ONE empfehlen: 50 Euro erhalten',
        subline: 'Weitersagen lohnt sich!',
        text:
          'Kennen Sie Office ManagerInnen oder GeschäftsführerInnen in Berlin, Hamburg, München, Frankfurt am Main und Köln? Wir belohnen Sie mit 50 Euro!',
        button: 'Jetzt empfehlen'
      },
      'Ads/RecommendCampaignGB': {
        label: 'Special',
        headline: 'Service Partner ONE empfehlen: 50 Pfund erhalten',
        subline: 'Weitersagen lohnt sich!',
        text: 'Kennen Sie Office ManagerInnen oder GeschäftsführerInnen in London? Wir belohnen Sie mit 50 Pfund!',
        button: 'Jetzt empfehlen'
      },
      'Common/ServiceEvent': {
        'by-staff': 'Von {{fullname}}',
        'track-order': 'Versandverfolgung'
      },
      'Common/ServiceEvent/NoteBox': {
        subject: 'Betreff',
        body: 'Ihre Nachricht für uns',
        deposit: 'Haben Sie Pfand?',
        send: 'Senden'
      },
      'Common/ServiceEvent/DepositBox': {
        body: 'Anzahl an Getränkekästen',
        description:
          'Möchten Sie, dass Ihre Pfandflaschen bei dieser Lieferung mitgenommen werden? Bitte teilen Sie uns mit, wie viele Kästen abgeholt werden sollen.',
        headline: 'Haben Sie Pfand?',
        info: 'Wegen der Bearbeitungszeit kann diese Angabe nur bis zwei Tage vor dieser Lieferung geändert werden.',
        'close-link': 'Danke, aber bitte nicht jetzt',
        send: 'Abschicken'
      },
      'Common/ServiceEvent/NoteButton': {
        tooltip: 'Notiz hinzufügen'
      },
      'Common/ServiceEvent/DepositButton': {
        tooltip: 'Pfand hinzufügen'
      },
      'Common/ServiceEvent/RatingBox': {
        message: 'Ihr Feedback (optional)',
        send: 'Senden'
      },
      'Common/ServiceEvent/RatingButton': {
        tooltip: 'Bewertung abgeben'
      },
      'Common/ServiceEvent/ResumeButton': {
        'confirm-resume': 'Bitte wenden Sie sich an unseren Kundenservice, um das Event wieder zu aktivieren.',
        'too-late':
          'Entschuldigung! Dieser Service kann aufgrund der kurzen Vorlaufzeit nicht mehr aktiviert werden. Bitte kontaktieren Sie Customer Service.'
      },
      'Common/ServiceEventDefinition': {
        headline: 'Produkte & Services',
        'mode-on_demand': 'Auf Abruf',
        'mode-one_time': 'Einmalig',
        'mode-recurrent': 'Wiederholend',
        'next-event': 'Nächster Termin: ',
        'by-partner': 'Von {{name}}',
        'pending-request': 'In Vorbereitung',
        edited: 'Gerade bearbeitet'
      },
      'Common/ServiceEventDefinition/CancelButton': {
        cancel: 'Stornieren',
        'confirm-cancel':
          'Bitte Sie kontaktieren Sie Customer Care bei Änderungswünschen oder wenn Sie die Bestellung stornieren möchten.'
      },
      Dashboard: {
        headline: 'Dashboard',
        greeting: 'Willkommen zurück',
        'greeting-december': 'Hohoho, frohe Advents- und Jahresendzeit'
      },
      'Dashboard/Banner': {
        headline: 'SP ONE Adventskalender',
        tagline: 'Melden Sie sich jetzt bei unserem digitalen Adventskalender an um exklusive Angebote zu erhalten.',
        title: 'Warm durch den Winter',
        text:
          'Nicht nur Händewärmer, sondern auch Genussmittel. Erhalten Sie 20% Rabatt auf Ihre Kaffee-Bestellung (ausgenommen Kapseln) ab einem Netto-Warenwert von 100€',
        text2: '',
        button: 'Zum Newsletter anmelden',
        'button-mobile': 'Zum Newsletter anmelden',
        button2: 'Jetzt bestellen!',
        deal2: 'Deal des Monats',
        discount2: '20% Rabatt',
        extra: 'auf Kaffee!',
        deal: 'Tägliche Angebote:',
        discount: 'bis zu',
        amount: '20%',
        off: 'Rabatt',
        'your-email': 'Ihre E-Mail-Adresse'
        // headline: 'Haben Sie die passende Maschine im Büro?',
        // tagline:
        //     'Falls nicht, schlagen Sie zu bei unserer Monatsaktion mit unschlagbarem Rabatt nur bis 30/11/2018. Wir helfen gerne, den richtigen Kaffeevollautomaten für Ihre Büroräumlichkeiten zu finden.',
        // button: 'Unverbindlich anfragen',
        // 'button-mobile': 'Unverbindlich anfragen',
        // deal: 'Deal des Monats:',
      },
      'Dashboard/Navbar': {
        headline: 'Produkte & Services für',
        'per-month': ' / Monat',
        'to-current-month': 'Zum aktuellen Monat'
      },
      'Dashboard/Header': {
        'todays-events': 'Heutige Ereignisse',
        'next-events': 'Nächste Ereignisse',
        'see-calendar': 'Alle Termine im Kalender',
        'need-help': 'Brauchen Sie Hilfe?',
        'chat-now': 'Chatten Sie jetzt mit uns',
        'chat-not': 'Kundenberater offline',
        'call-instead': 'Rufen Sie uns stattdessen unter der (030) 22 01237 0 an.',
        'call-us': 'oder rufen Sie uns an unter der  (030) 22 01237 0',
        'profile-progress': 'Fortschritt des Büroprofils',
        'office-data': 'Stimmen Ihre Daten noch? Überprüfen Sie ihre Angaben jederzeit:',
        'office-data-lead': 'Stimmen Ihre Daten?',
        'edit-profile': 'Büroprofil bearbeiten',
        'add-profile': 'Büroprofil ergänzen',
        'no-items': 'Heute keine Ereignisse',
        more: 'Mehr anzeigen',
        required: 'Erforderliche Angaben',
        optional: 'Optional',
        'info-missing': 'Informationen fehlen',
        'one-info-missing': 'Information fehlt',
        'info-completed': 'Alle Angaben sind vollständig.',
        'number-of-employees': 'Anzahl der Mitarbeiter',
        'floor-space': 'Bürofläche',
        floor: 'Stockwerk',
        elevator: 'Aufzug vorhanden',
        'first-steps': 'Erste Schritte für Neukunden',
        'onboarding-text': 'Möchten Sie eine Einführung in Ihre Service Plattform?',
        onboarding: 'Alle Funktionen erklärt',
        'services-button': 'Dienstleistungen',
        'services-text': 'Noch keine Services gebucht? Entdecken Sie jetzt unser '
      },
      'Dashboard/Schedule': {
        title: 'Wöchentliche Planung',
        more: 'Show More',
        edit: 'Ändern',
        bottles: 'Leergut',
        comment: 'Kommentieren',
        'no-events': 'Keine Ereignisse für den ausgewählten Tag.',
        'no-events-head': 'Es gibt keine Ereignisse für den ausgewählten Tag.',
        'no-events-sub': 'Services und Produktlieferungen werden hier angezeigt.',
        'go-to-shop': 'Zum Shop',
        'track-order': 'Versandverfolgung'
      },
      'Dashboard/ServiceEventDefinitionOverview': {
        headline: 'Produkte & Services',
        tooltip: 'Ihre derzeit gebuchten bzw. bestellten Leistungen'
      },
      'Dashboard/ServiceEventDefinitionOverview/EmptyList/Shop': {
        headline: 'Produkte kaufen',
        subline: 'Bestellen Sie alles rund ums Büro von Getränken bis IT-Bedarf.',
        button: 'Jetzt bestellen'
      },
      'Dashboard/ServiceEventDefinitionOverview/EmptyList/Service': {
        headline: 'Services anfragen',
        subline:
          'Fragen Sie Ihr unverbindliches Angebot für Reinigung, Catering, Kaffeemaschinen, Wasserspender oder Handwerker an.',
        button: 'Jetzt anfragen'
      },
      'Dashboard/ServiceEventFilter': {
        tooltip: 'Einzelne Servicetypen ein- oder ausblenden',
        'reset-filters': 'Filter zurücksetzen',
        filters: 'Filter',
        'filter-active': 'Aktiv',
        'filter-paused': 'Pausiert',
        'filter-one_time': 'Einmalig',
        'filter-recurrent': 'Wiederholend',
        'filter-drinks': 'Getränke',
        'filter-fruits': 'Früchte',
        'filter-rental': 'Miete/Leasing',
        'filter-coffee': 'Kaffee',
        'filter-cleaning': 'Reinigung',
        'filter-supplies': 'Verbrauchsmaterialien',
        'filter-handymen': 'Handwerker',
        'filter-equipment': 'Equipment',
        'filter-food': 'Lebensmittel',
        'filter-catering': 'Catering',
        'filter-misc': 'Sonstiges',
        'filter-relocation': 'Umzug',
        'filter-product-order': 'Produktbestellung'
      },
      'Dashboard/ServiceEventOverview': {
        'headline-upcoming': 'Kommende Events',
        'headline-past': 'Vergangene Events',
        'no-items':
          'Bestellen Sie Produkte im Shop oder nehmen Sie einen unserer Services in Anspruch, dann erscheinen Ihre Termine hier.',
        more: 'Mehr'
      },
      'Dashboard/Feedback': {
        headline: 'Ihr Feedback hilft uns, unsere Dienstleistung zu verbessern.',
        'was-service-provided':
          'Ist unser Partner an Ihrem Standort/in Ihrem Büro gewesen um die Lieferung zu übergeben oder die Dienstleistung auszuführen?',
        'notify-service-was-not-provided':
          'Wir entschuldigen uns, dass der Service nicht wie erwartet erbracht wurde. Wir werden schnellstmöglich mit einer Rückmeldung auf Sie zukommen.',
        'did-provider-do-all-tasks':
          'Wurden die Produkte oder die Dienstleistung wie erwartet geliefert bzw erbracht (d.h. alle Aufgaben wurde erfüllt, alle Produkte waren in der Lieferung enthalten, es waren keine Schäden an der Lieferung, die Reinigungskräfte haben sich vorbildlich verhalten,etc.)?',
        'not-fulfilled-tasks':
          'Bitte führen Sie aus, weshalb die Produkte oder die Dienstleistung nicht wie erwartet geliefert bzw. ausgeführt wurde.',
        'submit-feedback': 'Feedback senden',
        'notify-feedback-sent-successfully': 'Vielen Dank für Ihr Feedback!',
        'notify-unexpected-error': 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
        'return-todashboard': 'Zurück zum Dashboard',
        yes: 'Ja',
        no: 'Nein',
        'mandatory-field': 'Pflichtfeld',
        'max-255': 'Max. 255 Zeichen'
      },
      'Dashboard/PastEvents': {
        title: 'Vergangene Ereignisse',
        more: 'Mehr anzeigen',
        'view-feedback': 'Zeige Feedback',
        'submit-feedback': 'Feedback senden',
        reorder: 'erneut bestellen',
        'no-items': 'Keine vergangenen Ereignisse in diesem Monat.',
        'no-events-head': 'Es gibt keine vergangenen Ereignisse für de ausgewählten Monat.',
        'no-events-sub': 'Vergangene Services und Produktlieferungen werden hier angezeigt.',
        'go-to-cleaning': 'Zu unseren Reinigungsangeboten'
      },
      'Dashboard/ServiceLinks': {
        title: 'Weitere Services und Produkte buchen',
        cleaning: 'Reinigung',
        'other-services': 'Andere Services',
        shop: 'Shop'
      },
      'Dashboard/InvoiceManagement': {
        'invoice-management': 'Rechnungsmanagement',
        overdue: 'Überfällig',
        'coming-due': 'Fällig',
        total: 'Gesamt',
        'see-invoices': 'Rechnungen ansehen'
      },
      Header: {
        'search-for': 'Produkt suchen...',
        'product-is-missing': 'Produkt fehlt?'
      },
      'Modal/FeatureHighlights': {
        title: 'Willkommen',
        next: 'Weiter',
        done: 'Schließen'
      },
      'Modal/MissingProduct': {
        headline: 'Produkt anfragen',
        product: 'Namen des gesuchten Produktes',
        brand: 'Marke',
        quantity: 'Anzahl',
        request: 'Produkt anfragen',
        'info-headline': 'Sie haben nicht das gewünschte Produkt gefunden?',
        'info-text':
          'Einfach Produktnamen eintragen, absenden und unsere Mitarbeiter prüfen Ihre Anfrage und ergänzen ggf. das Produkt.',
        'case-subject': 'Fehlendes Produkt im Shop: {{product}}',
        'case-body':
          'Der Kunde hat ein Produkt angefordert: {{product}} {{brand}} {{quantity}}. Bitte prüfen und ggf. dem Pricebook im Webshop hinzufügen.'
      },
      'Modal/ServiceEventDefinition': {
        'tab-events': 'Events',
        'tab-tasks': 'Aufgaben',
        'tab-products': 'Produkte',
        'no-events': 'Keine Events',
        week: 'KW {{week}}',
        more: 'Mehr'
      },
      'Modal/ServiceEvent': {
        'back-to-service': 'Abo-Änderung',
        'no-tasks': 'Keine Aufgaben',
        'no-products': 'Keine Produkte',
        discount: 'Rabatt',
        'delivery-costs': 'Versandkosten',
        'sum-no-tax': 'Summe (exkl. USt)',
        'sum-tax': 'Summe (inkl. USt)',
        'current-price': 'Aktueller Preis:',
        'out-of-stock': 'Vergriffen',
        reorder: 'Erneut bestellen',
        edit: 'Bearbeiten'
      },
      'Modal/CalendarSync': {
        title: 'Kalender synchronisieren',
        heading: 'So synchronisieren Sie Ihren Event-Kalender automatisch mit Ihrem lokalen Kalender',
        'introduction-paragraph':
          'Wir verwenden das Standardformat ical. Die Synchronisierungsmethoden ' +
          'unterscheiden sich leicht von Kalenderprogramm zu Kalendarprogramm. Wählen Sie also zuerst das von ' +
          'Ihnen verwendete Programm aus.',
        icalendar: 'Apple iCalendar',
        outlook: 'Microsoft Outlook',
        gcalendar: 'Google Kalender',
        'step-one-heading': 'Wo möchten Sie unseren Kalender hinzufügen?',
        'step-two-heading': 'Kopieren Sie sich die Kalender-URL in die Zwischenablage',
        'step-three-heading': 'So fügen Sie den Kalender hinzu',
        copy: 'Kopieren',
        'tutorial-icalendar':
          '<p>Klicken Sie im Menü von iCalendar auf <strong>Ablage</strong> und dann auf <strong>Neues Kalendarabonnoment</strong>. ' +
          'Fügen Sie die kopierte Kalender-URL aus Schritt 2 nun hier ein und klicken Sie auf <strong>Abonnieren</strong>.</p>' +
          '<p>Es öffnet sich ein Fenster in dem Sie diverse Einstellungen für den Kalender festlegen können. Sie können einen eigenen Namen vergeben ' +
          'sowie die Häufigkeit der Aktualisierung anpassen. Unter dem Punkt <strong>Automatisch aktualisieren</strong> legen Sie fest wie häufig der Kalender synchronisiert ' +
          'wird. Wir empfehlen die Einstellung <strong>täglich</strong>. Sollten die Events nicht sofort angezeigt werden, klicken Sie im Bereich <strong>Weitere Kalender</strong> zweimal ' +
          'auf den Kalendernamen - danach sollten die Events in der Kalenderansicht erscheinen.</p>',
        'tutorial-gcalendar':
          '<p>Klicken Sie im Google Kalender unten links im Bereich <strong>Weitere Kalender</strong> auf den kleinen Pfeil rechts neben der Überschrift. ' +
          'Dann klicken Sie auf <strong>Über URL hinzufügen</strong>. Im sich öffnenden Fenster fügen Sie dann die ' +
          'Kalender-URL aus Schritt 2 ein und klicken auf <strong>Kalender hinzufügen</strong>.</p>',
        'tutorial-outlook':
          '<p>In der Kalenderansicht von Outlook zuerst <strong>rechtsklicken</strong> unten links auf <strong>Meine Kalender</strong>. Klicken Sie dann auf <strong>Kalender hinzufügen</strong> und auf <strong>aus dem Internet</strong>. ' +
          'Im sich öffnenden Fenster fügen Sie abschließend die Kalender-URL in das Textfeld und klicken auf OK.</p>'
      },
      'Modal/ProductDetail': {
        price: 'Preis',
        shipment: 'Versand',
        'add-to-cart': 'zum Einkaufswagen hinzufügen',
        'minimum-order-value': 'Mindestbestellwert',
        deposit: 'Pfand',
        'discount-price': 'Abo Preis',
        'discount-save': 'Sie sparen',
        'discount-question': 'Als Abo?*',
        'discount-rules':
          '*Abonnement-Modalitäten (Dauer, Intervall etc.) sind pro Partner-Einkaufswagen im Checkout einstellbar.'
      },
      'Modal/CleaningTasklist': {
        title: 'Cleaning tasklist',
        'premium-tasklist-title': 'Premiumreinigung',
        'cleaning-tasklist-linktitle': 'Beinhaltete Leistungen',
        'premium-header': 'Premium-Services',
        'cleaning-intensive-cleaning-header': 'Standard-Intensivreinigungsleistungen',
        'cleaning-general-cleaning-header': 'Unterhaltsreinigung',
        'cleaning-header': 'Leistungen Unterhaltsreinigung',
        'cleaning-particle': 'Reinigungsgegenstand',
        'cleaning-performance': 'Leistung',
        'cleaning-offices': 'Büroräume',
        'cleaning-wet-wipe-bag': 'Entleeren, ggf. feucht reinigen, mit Müllbeutel versehen',
        'cleaning-fog-wet': 'Nebelfeucht wischen',
        'cleaning-free-desk': 'Freigeräumte Tische',
        'cleaning-hardware': 'Telefone, Tastaturen, Schreibtischlampen',
        'cleaning-upholstery': 'Polstermöbel',
        'cleaning-vacuum': 'Saugen/reinigen',
        'cleaning-traffic-areas': 'Verkehrsflächen',
        'cleaning-floors': 'Böden',
        'cleaning-kitchen': 'Küche',
        'cleaning-wet-fog-vacuum': 'Saugen oder fegen und nebelfeucht wischen',
        'cleaning-shelfs-180': 'Schränke, Regale, Fensterbänke bis 1,8m',
        'cleaning-desks-180': 'Freigeräumte Tische, Schränke, Regale, Fensterbänke bis 1,8m',
        'cleaning-shelf': 'Spüle mit Ablagefläche, Schrankfronten',
        'cleaning-dishwasher': 'Geschirrspülmaschine',
        'cleaning-move': 'Einräumen/ausräumen/starten',
        'cleaning-plumbing': 'Sanitärbereich',
        'cleaning-baskets': 'Abfallbehälter, Papierkörbe',
        'cleaning-hard-floors': 'Hartböden (Fliesen)',
        'cleaning-disinfectant-wipe': 'Desinfizierend wischen',
        'cleaning-wc-objects': 'Alle WC-Objekte',
        'cleaning-disinfectant-polish': 'Desinfizierend reinigen',
        'cleaning-wall': 'Trenn- und Schamwände, Wandfliesen im Spritzbereich',
        'cleaning-mirror': 'Spiegel, Ablagen, Armaturen',
        'cleaning-wet-wipe': 'Feucht wischen und polieren',
        'cleaning-clean-polish': 'Reinigen/polieren',
        'cleaning-fill-paper': 'Toiletten- und Handtuchpapier & Seife auffüllen',
        'cleaning-fill-customer': 'Auffüllen, Füllung stellt Auftraggeber',
        'cleaning-optional-benefit': 'Optionale Leistungen',
        'cleaning-components': 'Fußleisten, Heizkörper, Türen, -Rahmen, Schalter, Steckdosen',
        'cleaning-cleanse': 'Reinigen',
        'cleaning-spider-web': 'Spinnengewebe',
        'cleaning-sweep': 'Abfegen',
        'cleaning-only-premium': '* Nur in der Premiumreinigung enthalten',
        'cleaning-dishes': 'Geschirr',
        'cleaning-collect': 'Einsammeln',
        'cleaning-vacuum-clean-mop': 'Saugen oder fegen und ggf. nebelfeucht wischen',
        'cleaning-waste-baskets': 'Abfallbehälter, Papierkörbe',
        'cleaning-empty-replace-bags': 'Entleeren, ggf. feucht reinigen, mit Müllbeutel versehen',
        'cleaning-cleared-off-desks': 'Freigeräumte Tische',
        'cleaning-telephones-keyboards-lamps': 'Telefone, Tastaturen, Schreibtischlampen',
        'cleaning-baseboards': 'Fußleisten',
        'cleaning-radiators': 'Heizkörper',
        'cleaning-doors-doorframes': 'Türen, -rahmen',
        'cleaning-light-switches-power-outlets': 'Schalter, Steckdosen',
        'cleaning-furniture': 'Möbel',
        'cleaning-remove': 'Abfegen',
        'cleaning-wipe-down-dust': 'Nebelfeucht wischen/entstauben',
        'cleaning-deep-machine-cleaning': 'Grundreinigung mit Maschine (bspw. Einscheibenmaschine)',
        'cleaning-urinal-walls': 'Trenn- und Schamwände',
        'cleaning-toilets-wall-tiles': 'Wandfliesen im Spritzbereich',
        'cleaning-mirrors-shelves-fittings': 'Spiegel, Ablagen, Armaturen',
        'cleaning-fingerprints': 'Fingerspuren an Türen, Glastüren, -einsätzen',
        'cleaning-office-equipment': 'Bürogeräte (Telefone, Tastaturen, Bildschirme, usw.)',
        'cleaning-bottles-kitchenware-cutlery': 'Flaschen und Geschirr',
        'cleaning-collect-wash': 'Einsammeln und waschen',
        'cleaning-windowswills': 'Fensterbänke',
        'cleaning-chairs-non-upholstered': 'Stühle (exkl. Polster)',
        'cleaning-oven': 'Ofen/Herdplatten',
        'cleaning-refrigerator': 'Kühlschrank',
        'cleaning-microwave': 'Mikrowelle',
        'cleaning-sink-area': 'Spüle mit Ablagefläche, Schrankfronten',
        'cleaning-clean-interior-exterior': 'Reinigen/polieren außen und innen',
        'cleaning-empty-reload-start': 'Einräumen/ausräumen/starten',
        'cleaning-disinfect': 'Desinfizieren',
        'cleaning-all-areas': 'Alle Bereiche'
      },
      'Modal/CustomerInformation': {
        title: 'Sind die Eingabedaten immernoch aktuell?',
        'company-name': 'Informationen zur Firma',
        company: 'Firmenname',
        'floor-space': 'Fläche (qm)',
        'number-of-employees': 'Anzahl der Mitarbeiter',
        'title-billing-address': 'Rechnungsadresse',
        'title-shipping-address': 'Versandadresse',
        yes: 'Ja',
        no: 'Nein'
      },
      'Modal/ServiceEventRating': {
        title: 'Wie zufrieden waren Sie?',
        subtitle: 'Helfen Sie uns Ihren Service zu verbessern',
        'rate-type-cleaning': 'Bewerten Sie die letzte Dienstleistung',
        'rate-type-default': 'Bewerten Sie die letzte Lieferung',
        'rating-min': 'Überhaupt nicht zufrieden',
        'rating-max': 'Sehr zufrieden',
        'rating-message': 'Ihr Feedback (optional)',
        rate: 'Bewertung absenden',
        cancel: 'Danke, aber bitte nicht jetzt'
      },
      Members: {
        'page-title': 'Teammitglieder',
        create: 'Neues Team-Mitglied anlegen',
        edit: 'Team-Mitglied bearbeiten',
        'search-team': 'Nach Team-Mitglied suchen',
        'deactivate-member': 'Mitglied deaktivieren',
        'activate-member': 'Mitglied aktivieren',
        cancel: 'Abbrechen',
        save: 'Speichern',
        update: 'Aktualisieren',
        salutation: 'Anrede',
        mr: 'Herr',
        mrs: 'Frau',
        gender: 'Geschlecht',
        firstname: 'Vorname',
        lastname: 'Nachname',
        phone: 'Telefonnummer',
        email: 'E-mail',
        password: 'Passwort',
        'no-members': 'Sie haben noch keine Team-Mitglieder.',
        'no-members-found': 'Es wurde kein Team-Mitglied mit diesem Namen gefunden.',
        'create-now': 'Jetzt neues Mitglied anlegen',
        'create-success': 'Team-Mitglied wurde erfolgreich angelegt.',
        'create-error': 'Team Mitglied konnte nicht angelegt werden. Bitte versuchen Sie es später noch einmal.',
        'update-success': 'Mitgliedsdaten wurden erfolgreich aktualisiert.',
        'update-error': 'Mitgliedsdaten konnten nicht aktualisiert werden. Bitte versuchen Sie es später noch einmal.',
        'deactivate-success': 'Team-Mitglied wurde erfolgreich deaktiviert.',
        'activate-success': 'Team-Mitglied wurde erfolgreich aktiviert.'
      },
      Navbar: {
        cleaning: 'Reinigung',
        dashboard: 'Dashboard',
        'my-account': 'Mein Konto',
        'switch-account': 'Account wechseln:',
        'user-management': 'Benutzerverwaltung',
        shop: 'Shop',
        services: 'Services',
        'service-request': 'Serviceanfragen',
        settings: 'Einstellungen',
        support: 'Hilfe',
        analytics: 'Analytics',
        timeline: 'Kalender',
        issues: 'Probleme',
        invoices: 'Rechnungen & Bestellungen',
        'tooltip-dashboard': 'Ihr persönliches Dashboard',
        'tooltip-shop': 'Produkte im Webshop suchen & bestellen',
        'tooltip-services':
          'Persönliches Angebot für Reinigung, Handwerker, Umzug, Catering oder Wasserspender/Kaffeevollautomaten anfragen',
        'tooltip-timeline': 'Persönliche Kalenderansicht',
        'tooltip-support': 'Kontaktieren Sie uns & finden Sie Antworten auf die häufigsten Fragen',
        'tooltip-settings': 'Passwort ändern, Rechnungen herunterladen',
        'tooltip-logout': 'Ausloggen'
      },
      Notifications: {
        close: 'Schließen',
        ok: 'OK',
        cancel: 'Abbrechen',
        'confirm-override-cart': 'Möchten Sie die Produkte in Ihrem Warenkorb überschreiben?',
        'confirm-leave-editing': 'Möchten Sie den Bearbeitungsmodus wirklich verlassen? Ihre Änderungen gehen verloren.'
      },
      'Public/NotFound': {
        headline: 'Leider wurde die gesuchte Seite nicht gefunden.',
        description: 'Entdecken Sie unsere Services oder gehen Sie zurück zum Dashboard:',
        dashboard: 'Dashboard',
        'services-headline': 'Diese Services könnten Sie interessieren',
        book: 'Jetzt buchen',
        look: 'Ansehen',
        'service-recurring-cleaning': 'Regelmäßige Unterhaltsreinigung',
        'service-intensive-cleaning': 'Einmalige Intensivreinigung',
        'service-other-services': 'Weitere Services'
      },
      'Public/Login': {
        headline: 'Melden Sie sich in Ihrem Service Partner ONE-Konto an.',
        'enter-email': 'Bitte E-Mail eingeben',
        'enter-password': 'Bitte Passwort eingeben',
        email: 'E-Mail-Adresse',
        password: 'Passwort',
        login: 'Einloggen',
        'no-client': 'Noch nicht Kunde?',
        'register-long': 'Registrieren Sie sich',
        register: 'Registrieren',
        'partner-register': 'Anmelden',
        'partner-register-headline': 'Bereits Service Partner ONE-Dienstleister?',
        'forgot-password': 'Passwort vergessen?',
        imprint: 'Impressum',
        'privacy-police': 'Datenschutz',
        header: 'Wir bieten Ihnen Komplettlösungen. Von der Büroklammer bis zur Reinigung.'
      },
      'Public/Register': {
        city: 'Stadt',
        company: 'Firmenname',
        complete: 'Vervollständigen',
        email: 'Firmen E-Mail-Adresse',
        'email-placeholder': 'Ihre E-Mail Adresse',
        'employee-option-1': '< 50 Mitarbeiter',
        'employee-option-2': '50 - 100 Mitarbeiter',
        'employee-option-3': '100 - 250 Mitarbeiter',
        'employee-option-4': '250 - 500 Mitarbeiter',
        'employee-option-5': '> 500 Mitarbeiter',
        firstname: 'Vorname',
        'go-back': 'Login',
        headline: 'Erstellen Sie Ihr Service Partner ONE Konto.',
        lastname: 'Nachname',
        'live-demo-heading': 'Live-Demo',
        'live-demo-text':
          'Lassen Sie uns Ihnen zeigen, wie Sie ihr Office Management mit Service Partner ONE vereinfachen können. Vereinbaren Sie mit unserem Customer Success - Mitarbeiter eine kostenlose Durchführung durch unseren Service.',
        'live-demo-toggle-text': 'Ja, ich möchte eine Live-Demo erhalten.',
        ceo: 'Geschäftsführer',
        'modal-headline': 'Anmelden',
        'modal-headline-step-2': 'Danke, für Ihre Anmeldung',
        'modal-headline-text':
          'Bitte füllen Sie die optionalen Felder aus und helfen Sie uns, ein auf Ihr Unternehmen maßgeschneidertes Serviceangebot zu erstellen.',
        'number-of-employees': 'Mitarbeiter',
        'number-of-offices': 'Anzahl der Büros',
        phone: 'Telefon',
        'phone-placeholder': 'Ihre Telefonnummer',
        'postal-code': 'Postleitzahl',
        login: 'Anmelden',
        register: 'Registrieren',
        'office-space': 'Bürofläche in qm',
        country: 'Land',
        'country-de': 'Deutschland',
        'country-en': 'Großbritannien',
        street: 'Straße',
        subheadline: 'Bereits Service Partner ONE-Kunde?',
        vatin: 'Umsatzsteuer-ID',
        mr: 'Herr',
        ms: 'Frau',
        password: 'Passwort',
        'password-placeholder': 'Passwort eingeben',
        'password-placeholder-2': 'Passwort wiederholen',
        'confirm-password': 'Passwort bestätigen',
        agb: 'Mit Ihrer Anmeldung erklären Sie sich mit unseren ',
        agb2: 'AGBs, ',
        agb3: 'unserer ',
        agb4: 'Datenschutzerklärung, ',
        agb5: 'sowie den Bestimmungen zu Cookies & Internet-Werbung einverstanden.',
        'accept-agb': 'AGBs und Datenschutzerklärung akzeptieren.',
        'check-inbox': 'Fast geschafft! Bitte schauen Sie in Ihr E-Mail-Postfach.',
        'confirm-email': 'Bestätigen Sie jetzt Ihre E-Mail-Adresse, um Zugang zu all unseren Services zu erhalten.',
        'click-link':
          '<span>Wir haben Ihnen eine E-Mail an <strong>{{email}}</strong> geschickt.<br />Bitte klicken Sie auf den Bestätigungs-Link in der E-Mail.</span>',
        'no-email': 'Aktivierungs-E-Mail nicht erhalten?',
        'send-email-again':
          'Der E-Mail-Versand kann in Ausnahmefällen einige Minuten dauern. Sollten Sie auch nach 5 Minuten keine E-Mail erhalten haben, klicken Sie bitte hier: ',
        'send-email-again-2': 'E-Mail erneut anfordern',
        'enter-password': 'Bitte geben Sie ein Passwort ein, das mindestens 8 Zeichen lang ist.',
        'enter-password-repeat': 'Die eingegebenen Passwörter stimmen nicht überein.',
        'enter-email': 'Bitte geben Sie eine gültige Email-Adresse ein, wie zum Beispiel "max.mustermann@mail.com".',
        'enter-name': 'Bitte geben Sie einen Vor- und Nachnamen ein, wie zum Beispiel "Max" und "Mustermann".',
        'enter-firstname': 'Bitte geben Sie einen Vornamen ein.',
        'enter-lastname': 'Bitte geben Sie einen Nachnamen ein.',
        'enter-company': 'Bitte geben Sie einen Firmennamen ein, wie zum Beispiel "Musterfamilie GmbH".',
        'enter-phone': 'Bitte geben Sie eine gültige Telefonnumer ein, wie zum Beispiel "03022012370".',
        'passwords-not-matching': 'Die eingegebenen Passwörter stimmen nicht überein.',
        'email-invalid': 'Die eingegebene Email-Adresse ist ungültig.',
        imprint: 'Impressum',
        'privacy-police': 'Datenschutz',
        DE: 'Deutschland',
        AU: 'Österreich'
      },
      'Public/RecoverPassword': {
        headline: 'Passwort vergessen',
        'go-back': 'Zurück zum Login',
        email: 'E-Mail-Adresse',
        send: 'Abschicken'
      },
      'Public/ResetPassword': {
        headline: 'Passwort vergessen',
        'enter-email':
          'Bitte geben Sie eine E-Mail-Adresse an. Wir schicken Ihnen Anweisungen zum Zurücksetzen des Passworts.',
        'go-back': 'Zurück zum Login',
        email: 'E-Mail-Adresse',
        send: 'Abschicken',
        alert:
          '<span>Wir haben Ihnen eine E-Mail an <strong>{{email}}</strong> geschickt. Sie enthält Anweisungen zum Zurücksetzen des Passworts.</span>'
      },
      'Public/RestorePassword': {
        headline: 'Passwort neu wählen',
        'new-password': 'Neues Passwort',
        'retype-password': 'Passwort wiederholen',
        'passwords-not-matching': 'Passwörter stimmen nicht überein',
        'password-too-short': 'Mindestens 6 Zeichen',
        change: 'Ändern'
      },
      'Public/Verify': {
        headline: 'Verifikation',
        'verify-text-success':
          'Falls Sie nach 10 Sekunden nicht wietergeleitet werden, klicken Sie auf den folgenden Link:',
        'verify-text-fetching': 'Verifizierung läuft...',
        'verify-text-failing': 'Verifizierung fehlgeschlagen!',
        'verify-text-already-verified':
          'Falls Sie bereits zuvor Ihre E-Mail verifiziert haben, loggen Sie sich einfach ein:',
        dashboard: 'Weiter zum Dashboard'
      },
      OfferConfirmation: {
        'no-offer': 'Es steht kein aktives Angebot zur Verfügung.',
        confirm: 'Bestätigen',
        reject: 'Ablehnen',
        accept: 'Ich akzeptiere',
        'terms-conditions': 'die AGB',
        'terms-warning': 'Bitte AGB akzeptieren.',
        'offer-notification-reject': 'Dieses Angebot wurde bereits abgelehnt.',
        'offer-notification-accept': 'Dieses Angebot wurde bereits bestätigt.',
        'headline-reject': 'Angebot ablehnen',
        'headline-accept': 'Bestätigung',
        'please-verify':
          'Bitte tragen Sie Ihre E-Mail-Adresse und Ihren Namen in die dafür vorgesehenen Felder ein, um Ihre digitale Unterschrift dem Vertrag hinzuzufügen.',
        'digital-signature': 'Digitale Unterschrift',
        'first-name': 'Vorname',
        'last-name': 'Nachname',
        'e-mail': 'E-Mail-Adresse',
        'reject-reason': 'Bitten geben Sie an, weshalb Sie das Angebot ablehnen.',
        'reject-offer': 'Angebot ablehnen',
        'accept-offer': 'Bestätigen',
        cancel: 'Abbrechen',
        'success-headline': 'Vielen Dank!',
        'success-description': 'Wir freuen uns, dass Sie sich für unsere Dienstleistung entschieden haben!',
        'success-description2':
          'Sie erhalten alle wichtigen Informationen für den Start Ihrer Reinigung separat per E-Mail.',
        unsuccessful: 'Wir bedauern, dass die angebotene Leistung nicht Ihren Bedürfnissen entspricht.',
        'success-button': 'Ihr Dashboard',
        'error-accept': 'Bitte geben Sie Namen und E-Mail an.',
        'error-reject': 'Bitte geben Sie einen Grund an.',
        'fetch-offer': 'Einen Moment, Ihre Anfrage wird bearbeitet.'
      },
      PartnerConfirmation: {
        'no-offer': 'Es steht kein aktives Angebot zur Verfügung.',
        confirm: 'Bestätigen',
        reject: 'Ablehnen',
        accept: 'Ich akzeptiere',
        offer: 'Angebot',
        'performance-protocol': 'Leistungsnachweis',
        'terms-conditions': 'die AGB',
        'terms-warning': 'Bitte AGB akzeptieren.',
        'offer-notification-reject': 'Dieses Angebot wurde am {{date}} abgelehnt.',
        'offer-notification-accept': 'Dieses Angebot wurde am {{date}} angenommen.',
        'performance-notification-reject': 'Dieser Leistungsnachweis wurde am {{date}} abgelehnt.',
        'performance-notification-accept': 'Dieser Leistungsnachweis wurde am {{date}} bestätigt.',
        'offer-date-reject': '<span>Dieses Angebot wurde am <strong class="red">{{date}}</strong> abgelehnt.</span>',
        'offer-date-accept': '<span>Dieses Angebot wurde am <strong class="red">{{date}}</strong> angenommen.</span>',
        'performance-date-reject':
          '<span>Dieser Leistungsnachweis wurde am <strong class="red">{{date}}</strong> abgelehnt.</span>',
        'performance-date-accept':
          '<span>Dieser Leistungsnachweis wurde am <strong class="red">{{date}}</strong> bestätigt.</span>',
        'performance-date-validity':
          '<span>An dieses Angebot halten wir uns bis zum <strong class="red">{{date}}</strong> gebunden.</span>',
        'headline-reject': 'Angebot ablehnen',
        'headline-accept': 'Bestätigung',
        'reject-reason': 'Bitten geben Sie an, weshalb Sie das Angebot ablehnen.',
        'reject-details': 'Angebotsdetails',
        'reject-offer': 'Angebot ablehnen',
        'accept-offer': 'Bestätigen',
        cancel: 'Abbrechen',
        'success-headline': 'Vielen Dank!',
        'success-description':
          'Der zuständige Service Manager wird sich umgehend für die Terminvereinbarung zur Ausführung der gewünschten Leistung bei Ihnen melden.',
        'success-description2':
          'Sie erhalten das unterschriebene Angebot separat per E-Mail und können dieses jederzeit über nachfolgenden Button herunterladen:',
        'success-description-performance':
          'Sie erhalten den Leistungsnachweis separat per E-Mail und können diesen jederzeit über nachfolgenden Button herunterladen:',
        unsuccessful: 'Wir bedauern, dass die angebotene Leistung nicht Ihren Bedürfnissen entspricht.',
        unsuccessful2:
          'Herr / Frau {{ServiceManager}} wird sich zeitnah bei Ihnen melden und steht Ihnen für Rückfragen zum Dienstleistungsangebot gerne zur Verfügung.',
        'unsuccessful-performance':
          'Sie haben soeben einen Leistungsnachweis über unsere erbrachte Dienstleistung abgelehnt. Herr / Frau {{ServiceManager}} wird sich umgehend bei Ihnen melden und steht für Rückfragen gerne zur Verfügung.',
        'success-button': 'Ihr Angebot',
        'success-button-performance': 'Ihr Leistungsnachweis',
        'error-reject': 'Bitte geben Sie einen Grund an.',
        'fetch-offer': 'Einen Moment, Ihre Anfrage wird bearbeitet.',
        'intro-text-offer':
          'vielen Dank für Ihr Interesse an unserer Dienstleistung. Nachfolgend finden Sie unser Angebot zu der gewünschten Leistung. Bitte bestätigen Sie die Durchführung der vereinbarten Leistungen über den Button “Bestätigen”.',
        'intro-text-performance':
          'vielen Dank, dass Sie unsere Dienstleistung in Anspruch genommen haben. Nachfolgend finden Sie die Übersicht zu unseren erbrachten Leistungen. Bitte bestätigen Sie die Durchführung der vereinbarten Leistungen über den Button “Bestätigen”.',
        'office-cleaning': 'Ihre professionelle Büroreinigung',
        'per-week': 'x pro Woche',
        'customer-id': 'Kunden-Nr.: ',
        ID: 'ID',
        addresses: 'Adressen',
        'shipping-address': 'Leistungsort',
        'billing-address': 'Rechnungsadresse',
        tasks: 'Auszuführende Leistung(en)',
        'choose-task': 'Bitte wählen Sie die Leistungen aus, die Sie verbindlich bestätigen möchten.',
        task: 'Position',
        'hours-cost': 'Stunden à',
        'squaremeters-cost': 'm² à',
        'pieces-cost': 'Stück à',
        'total-price': 'Gesamtpreis',
        'footer-1':
          'Das genannte Leistungsentgelt beinhaltet die Stellung der für die Reinigung notwendigen Ge- und Verbrauchsmaterialien, nicht enthalten sind die tariflichen Zuschläge für evtl. Nacht-, Feiertags- und Wochenendarbeit sowie für Überstunden. Skonto wird nicht gewährt.',
        'footer-2':
          '<span>Für Fragen steht Ihnen {{partner_name}} unter <a class="link" href="mailto:{{partner_email}}">{{partner_email}}</a> oder telefonisch unter {{partner_phone}} gern zur Verfügung.</span>',
        'footer-3':
          'Gerne sehen wir Ihrer Beauftragung der angebotenen Leistung entgegen, deren fachgerechte und sorgfältige Ausführung wir Ihnen zusichern.',
        'customer-info': 'Kundeninformationen',
        'customer-id-name': 'Kd. - Nr. / Kundebez.:',
        'cost-center': 'Kostenstelle:',
        'cost-payer': 'Kostenträger:',
        'task-list': 'Ausgeführte Leistung(en)',
        'cleaning-staff': 'Durchführender Mitarbeiter:',
        'price-box': 'Leistungsbetrag',
        summary: 'Zusammenfassung',
        tax: 'MwSt. (19%)',
        'total-with-tax': 'Gesamtbetrag (brutto)',
        confirming: 'Bestätigen',
        'some-rejected': 'Leistung(en) wurde(n) nicht ausgewählt',
        rejecting: 'Leistungen ablehnen',
        'all-rejected': 'Keine Leistung wurden ausgewählt',
        'offer-and-date': 'Angebot vom {{created_date}}',
        'performance-and-date': 'Ausgeführt im Zeitraum {{StartDate}} - {{EndDate}}',
        'service-worker': 'Ansprechpartner',
        'service-tel': 'Telefon:',
        'service-email': 'E-Mail: ',
        rejected: 'abgelehnt',
        area: 'Leistungsbereich:',
        'executor-headline': 'Durchführender Mitarbeiter',
        until: ' bis ',
        'view-pdf': 'PDF anzeigen',
        'po-number': 'Bestellnummer',
        'po-number-optional': 'Bestellnummer (optional)'
      },
      Services: {
        headline: 'Unsere Services',
        'info-line':
          'Wenn Sie weitere Fragen zu einem unserer Services haben sollten, kontaktieren Sie uns telefonisch unter der <a href="tel:00493022012370">(030) 22 01237 0</a> (Mo — Fr, 09:00 — 18:00 Uhr) oder wenden Sie sich an Ihren persönlichen Ansprechpartner.',
        book: 'Jetzt buchen',
        'service-cleaning-title': 'Reinigung',
        'service-cleaning-description':
          'In nur wenigen Schritten zu Ihrem Reinigungs-Angebot mit Zufriedenheitsgarantie.',
        'service-cleaning-url': 'https://servicepartnerone.typeform.com/to/K1jasB',
        'service-handymen-title': 'Handwerker',
        'service-handymen-description': 'Sie suchen einen Handwerker für Ihr Büro?',
        'service-handymen-url': 'https://servicepartnerone.typeform.com/to/wdzkJ9',
        'service-relocation-title': 'Umzug',
        'service-relocation-description': 'Suchen Sie eine Firma für Ihren Umzug?',
        'service-relocation-url': 'https://servicepartnerone.typeform.com/to/aSqA2c',
        'service-catering-title': 'Catering',
        'service-catering-description': 'Suchen Sie einen Cateringanbieter für Ihr Büro? ',
        'service-catering-url': 'https://servicepartnerone.typeform.com/to/fhjQ8C',
        'service-water-title': 'Wasserspender',
        'service-water-description': 'Sie suchen einen Wasserspender für Ihr Büro?',
        'service-water-url': 'https://servicepartnerone.typeform.com/to/g4AJ5N',
        'service-coffee-title': 'Kaffee',
        'service-coffee-description': 'Sie suchen eine Kaffeemaschine für Ihr Büro?',
        'service-coffee-url': 'https://servicepartnerone.typeform.com/to/TzdciA'
      },
      'Services/Cleaning': {
        'service-cleaning-title': 'Reinigung',
        'service-cleaning-description': 'Schnell zu Ihrem Reinigungs-Angebot mit Zufriedenheitsgarantie',
        'step-1': '1. Anforderungen',
        'step-2': '2. Besichtigungstermin',
        'step-3': '3. Bestätigung',
        'headline-step-1': 'Individuelle Reinigung nach Ihren Anforderungen',
        'headline-step-2': 'Vereinbaren Sie einen Besichtigungstermin',
        'headline-step-3': 'Bestätigung',
        floor: 'Wie groß ist die zu reinigende Fläche (in m²)?',
        hours: 'Sollen Reinigungen am Sonntag oder nachts (zwischen 22 Uhr und 5 Uhr) stattfinden?',
        toilet: 'Wie groß sind Ihre Toiletten insgesamt (in m²)?',
        addonFloor: 'm²',
        addonEmployees: 'Mitarbeiter',
        estimatedPrice: 'Voraussichtlicher Preis',
        excludeTaxes: 'pro Monat / exkl. MwSt',
        includesCleaningSupplies: 'Inklusive Reinigungsmittel',
        noTravelFees: 'Keine Reisekosten',
        priceEvaluationVisit: 'Besichtigungtermin vereinbaren',
        week: 'Wie oft soll pro Woche gereinigt werden?',
        'tooltip-week':
          'basierend auf Ihrer Größenangabe gibt der Rechner automatisch eine empfohlene Mindesthäufigkeit für die Anzahl wöchentlicher Reinigungen aus',
        'tooltip-premium':
          'Die Premiumreinigung enthält: 1. Abräumen von Tischen 2. Reinigung von Telefonen, Tastaturen, Schreibtischlampen 3. Ein-/Ausräumen von Geschirrspülmaschinen 4. Reinigung von Polstermöbeln',
        woodenFloor: 'Welcher Bodenbelag ist in Ihrem Büro vorhanden?',
        nrEmployees: 'Wie viele Mitarbeiter haben Sie?',
        kitchen: 'Wie groß sind Ihre Küchen insgesamt (in m²)?',
        premium: 'Wünschen Sie eine ',
        'floor-type-carpet-bright': 'Teppich hell',
        'floor-type-carpet-dark': 'Teppich dunkel',
        'floor-type-laminate': 'Laminat',
        'floor-type-parquet': 'Parkett',
        'floor-type-linoleum': 'Linoleum',
        'floor-type-screed': 'Estrich',
        'floor-type-stone': 'Stein',
        'error-size-exceeded': 'Ungültige Eingabe- Zahl zu groß.',
        'error-not-integer': 'Bitte ausschließlich ganze Zahlen eingeben, ohne Kommata.',
        yes: 'Ja',
        no: 'Nein',
        next: 'Weiter',
        back: 'Zurück',
        result: 'Ihr monatlicher Reinigungspreis sollte sich in diesem Rahmen bewegen:',
        'cleaning-tasklist-title': 'Überblick über die angebotenen Services',
        startDate: 'Gewünschtes Startdatum?',
        'option-date1': 'Option 1:',
        'option-date2': 'Option 2:',
        'option-date3': 'Option 3:',
        'output-placeholder': 'Bitte zuerst Daten angeben',
        'output-wrong': 'keine Preisberechnung möglich',
        'result-headline': 'Objektreinigung nach Ihren Angaben',
        'result-info':
          '*dies ist ein vorläufiger Preisrahmen: basierend auf speziellen Gegebenheiten des zu reinigenden Objektes und möglichen zusätzlichen Services (z.B. Fenster- und Intensivreinigung) kann er Änderungen unterliegen.',
        modal: 'Überblick über die angebotenen Services',
        button: 'Besichtigungstermin vereinbaren',
        jumbotron: 'Besichtigungstermin vereinbaren',
        'time-description':
          'Nennen Sie uns bitte drei verschiedene Tage, an denen wir einen Besichtigungstermin bei Ihnen durchführen können. Sobald Sie uns Ihre Terminwünsche geschickt haben, bestätigen wir Ihre Besichtigung innerhalb von zwei Werktagen.',
        'time-headline': 'Bitte wählen Sie Ihre drei bevorzugten Termine aus.',
        'success-headline': 'Ihre Anfrage war erfolgreich',
        'success-description':
          'Vielen Dank, Ihre Anfrage wurde erfolgreich an uns übermittelt. Wir bearbeiten Ihre Anfrage umgehend und melden uns in Kürze direkt bei Ihnen.',
        'success-brand': 'Ihr Service Partner ONE Team',
        'back-button': 'Zu den Services',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Uhrzeit'
      },
      'Services/CleaningMenu': {
        headline: 'Unterhalts­reinigung',
        'recurring-cleaning-headline': 'Regelmäßige Unterhaltsreinigung',
        'recurring-cleaning-point-1': 'Standard- und Premium-Reinigungs­leistungen',
        'recurring-cleaning-point-2': 'sofortige Preiserstellung',
        'recurring-cleaning-point-3': 'Einjähriger Reinigungsvertrag',
        'window-headline': 'Einmalige Fensterreinigung',
        'window-point-1': 'beidseitige Reinigung und optionale Rahmenreinigung',
        'window-point-2': 'sofortige Preiserstellung',
        'one-time-headline': 'Einmalige Grundreinigung',
        'one-time-point-1': 'personalisierbare Reinigungs­leistungen',
        'one-time-point-2': 'Preis pro Stunde',
        'one-time-point-additional': 'Ideal nach Veranstaltungen oder als Zusatzleistung',
        'intensive-headline': 'Einmalige Intensivreinigung',
        'intensive-point-1': 'Standard-Intensiv­reinigungs­leistungen',
        'intensive-point-additional':
          'Ideal für Bodenreinigung mit Einscheibenmaschine, Reinigung der Bürogeräte und weitere Formen von Spezialreinigungen',
        'book-button': 'Jetzt buchen',
        'cleaning-tasklist-linktitle': 'Beinhaltete Leistungen',
        'coming-soon': 'In Planung'
      },
      'Services/AdditionalCleaning': {
        title: 'Einmalige Unterhaltsreinigung',
        description: 'Buchen Sie hier Ihre einmalige Reinigung oder Zusatzleistung mit nur zwei Klicks.',
        'date-label': 'Wann kann die Zusatzleistung ausgeführt werden?',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Uhrzeit',
        cleaningHours: 'Wie viele Reinigungsstunden brauchen Sie?',
        cleaningOption: 'Welche Hauptaufgaben sollen ausgeführt werden?',
        cleanFloor: 'Boden reinigen',
        cleanFurniture: 'Möbel reinigen',
        cleanKitchen: 'Küche reinigen',
        misc: 'Sonstiges',
        'please-describe': 'Bitte beschreiben Sie die Aufgabe näher:',
        'is-partial':
          'Sollen die Aufgaben auf der gesamten Fläche oder nur in einem bestimmten Bereich ausgeführt werden?',
        'which-part': 'Bitte geben Sie an, welche Teile des Objekts gereinigt werden soll:',
        partial: 'Teilbereich',
        total: 'gesamte Fläche',
        'error-date': 'Bitte wählen Sie einen Termin aus!',
        'error-choose': 'Bitte wählen Sie einen Reinigungsservice aus!',
        cancel: 'Abbrechen',
        submit: 'Abschicken'
      },
      'Services/GegenbauerCleaning': {
        title: 'Einmalige Zusatzleistung',
        description:
          'Tragen Sie hier die Daten für die automatische Erstellung eines Angebots oder Leistungsnachweises ein.',
        'choose-type': 'Bitte wählen Sie aus, was erstellt werden soll:',
        offer: 'Angebot',
        performance: 'Leistungsnachweis',
        'date-label': 'Wann wird (Angebot) oder wurde (Leistungsnachweis) die Zusatzleistung ausgeführt?',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Start-Uhrzeit',
        cleaningHours: 'Wie viele Reinigungsstunden brauchen Sie?',
        cleaningOption: 'Welche Leistungen werden ausgeführt?',
        'choose-task': 'Bitte auswählen',
        misc: 'Sonstige Leistungen',
        'please-describe': 'Anmerkung zur Aufgabe/Leistung:',
        size:
          'Wie groß ist die zu reinigende Fläche (m²) bzw. wie hoch ist die Stückzahl (#) der zu reinigenden Möbel?',
        where:
          'Bitte geben Sie an, wo im Gebäude die Leistung erbracht werden soll (z.B.: 1. Etage, Küche, Konferenzraum, Eingangsbereich, etc.):',
        contact: 'Bitte geben Sie den Leistungsempfänger mit Name, Anschrift und E-Mail-Adresse an:',
        'error-date': 'Bitte wählen Sie einen Termin aus!',
        'error-choose': 'Bitte wählen Sie einen Reinigungsservice aus!',
        'error-size': 'Bitte geben Sie einen Wert an.',
        'error-contact': 'Bitte geben Sie Kontaktdaten an.',
        'error-where': 'Bitte geben Sie einen Ort an.',
        cancel: 'Abbrechen',
        submit: 'Abschicken',
        'is-partial':
          'Sollen die Aufgaben auf der gesamten Fläche oder nur in einem bestimmten Bereich ausgeführt werden?',
        'which-part': 'Bitte geben Sie an, welche Teile des Objekts gereinigt werden soll:',
        partial: 'Teilbereich',
        total: 'gesamte Fläche',
        cleanFloor: 'Boden reinigen',
        collectBottles: 'Flaschen einsammeln',
        cleanToilets: 'Toiletten reinigen',
        cleanDishes: 'Geschirr reinigen',
        cleanFurniture: 'Möbel reinigen',
        cleanKitchen: 'Küche reinigen',
        'svs-cleaning': 'SVS Reinigungskraft (Mo-Sa)',
        'svs-glas': 'SVS Glasreinigung (Mo-Sa)',
        'svs-construction': 'SVS Baureinigung (Mo-Sa)',
        'deep-carpet': 'Grundreinigung Teppichboden',
        'deep-hardwood-no-coating': 'Grundreinigung Hartbelagböden ohne beschichten',
        'deep-hardwood-one-coating': 'Grundreinigung Hartbelagböden mit 1x beschichten',
        'deep-hardwood-two-coating': 'Grundreinigung Hartbelagböden mit 2x beschichten',
        'deep-between': 'Grund-/Zwischenreinigung Cleanern',
        'deep-hard-chairs': 'Grundreinigung Hartbelagstühle',
        'deep-padded-chairs': 'Grundreinigung Polsterstühle',
        'deep-furnishings-many': 'Grundreinigung Mobiliar (intensiv möbliert)',
        'deep-furnishings-few': 'Grundreinigung Mobiliar (gering möbliert)'
      },
      'Services/WindowCleaning': {
        title: 'Einmalige Fensterreinigung',
        description: 'Schnell zu Ihrem Reinigungs-Angebot mit Zufriedenheitsgarantie.',
        'office-size': 'Wie groß ist Ihr zu reinigendes Objekt?',
        more: 'Größer als',
        'window-label': 'Bitte beschreiben Sie Ihre verschiedenen Fenstertypen',
        'window-number': 'Anzahl',
        'window-width': 'Breite (in cm)',
        'window-height': 'Höhe (in cm)',
        'window-floor': 'Sind die Fenster bodentief?',
        'window-panes': 'Wie viele Sektionen haben die Fenster?',
        'window-panes-nr': 'Anzahl',
        'window-double': 'Handelt es sich um Doppelkastenfenster?',
        'window-add': 'Dieses Fenster hinzufügen',
        'window-edit': 'Änderungen speichern',
        'window-too-high': 'Haben Sie Fenster, deren niedrigster Punkt in einer Höhe von zwei Metern oder mehr liegt?',
        'window-frames': 'Sollen die Rahmen gereinigt werden?',
        windows: 'Fenster',
        panes: 'Sektionen',
        'double-windows': 'Doppelfenster',
        'floor-window': 'bodentief',
        yes: 'Ja',
        no: 'Nein',
        'date-cleaning': 'Bitte geben Sie Ihren Wunschtermin für die Reinigung an.',
        'date-visit': 'Bitte geben Sie drei mögliche Terminwünsche für eine Besichtigung an.',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Uhrzeit',
        'error-window':
          'Fenster (Anzahl und Größe) und Sektionen sind Pflichtfelder. Bitte geben Sie ihre Daten ein! Nur ganze Zahlen sind erlaubt.',
        cancel: 'Abbrechen',
        submit: 'Abschicken'
      },
      'Services/IntensiveCleaning': {
        'step-1': 'Reinigungsdetails',
        'step-2': 'Terminfestlegung',
        'step-3': 'Bestätigung',
        'service-cleaning-title': 'Einmalige Intensivreinigung',
        'service-cleaning-description': 'Schnell zu Ihrem Reinigungs-Angebot mit Zufriedenheitsgarantie',
        'facility-info-headline': 'Objektinformationen',
        'object-size': 'Wie groß ist die Fläche Ihres Objekts?',
        'no-of-employees': 'Wie viele Mitarbeiter haben Sie?',
        'define-cleaning-area-headline': 'Zu reinigende Flächen',
        'work-space-headline': 'Bürofläche',
        'work-space-switch': 'Soll eine Intensivreinigung der Bürofläche ausgeführt werden?',
        'work-space-size': 'Wie groß ist die Fläche ihres Büros?',
        sqm: 'qm',
        more: '>',
        'all-areas-error': 'Bitte bestimmen Sie mindestens eine zu reinigende Fläche.',
        dishes:
          'Müssen im Rahmen der Intensivreinigung Geschirr und Flaschen an den Arbeitsplätzen eingesammelt werden?',
        'floor-type': 'Welcher Bodenbelag ist in Ihrer Bürofläche vorhanden?',
        'work-space-deep-clean': 'Soll im Rahmen der Intensivreinigung eine Grundreinigung des Bodens erfolgen?',
        'furniture-move': 'Müssen Tische oder andere Möbel verrückt werden?',
        'office-equipment':
          'Soll das Reinigungsteam die Bürogeräte (Telefone, Tastaturen, Bildschirme, usw.) nebelfeucht wischen/entstauben?',
        bathrooms: 'WC-Bereich',
        'bathroom-switch': 'Soll eine Intensivreinigung der Toiletten ausgeführt werden?',
        'bathroom-size': 'Wie groß ist die Fläche Ihrer Toiletten?',
        'bathroom-deep-clean': 'Soll im Rahmen der Intensivreinigung eine Grundreinigung des Bodens erfolgen?',
        'kitchen-switch': 'Soll eine Intensivreinigung der Küche ausgeführt werden?',
        kitchen: 'Küche',
        'kitchen-size': 'Wie groß ist die Fläche der Küche? (m²)',
        'kitchen-floor-type': 'Welcher Bodenbelag ist in Ihrer Küche vorhanden?',
        'kitchen-deep-clean': 'Soll im Rahmen der Intensivreinigung eine Grundreinigung des Bodens erfolgen?',
        'traffic-area-headline': 'Verkehrsflächen',
        'traffic-area-switch': 'Soll eine Intensivreinigung der Verkehrsflächen ausgeführt werden?',
        'traffic-area-size': 'Wie groß ist die Fläche der Verkehrsflächen?',
        'traffic-area-floor-type': 'Welcher Bodenbelag ist in Ihrer Verkehrsflächen vorhanden?',
        'traffic-area-deep-clean': 'Soll im Rahmen der Intensivreinigung eine Grundreinigung des Bodens erfolgen?',
        other: 'Weitere',
        'other-switch':
          'Gibt es weitere Räume, die ebenfalls Teil der Intensivreinigung sein sollen (bspw. Keller, Balkon etc.)?',
        'other-details': 'Bitte beschreiben Sie die weiteren Räume',
        cancel: 'Zurück',
        submit: 'Anfrage senden',
        next: 'Weiter',
        employees: 'Mitarbeiter',
        'choose-floor-type': 'Wählen Sie einen Bodenbelag',
        'floor-type-tiling': 'Fliesen',
        'floor-type-carpet': 'Teppich',
        'floor-type-laminate': 'Laminat',
        'floor-type-parquet': 'Parkett',
        'floor-type-linoleum': 'Linoleum',
        'floor-type-pvc': 'PVC',
        'cleaning-date-headline': 'Legen Sie die Reinigungsdaten fest',
        'date-of-service': 'Was ist Ihr gewünschtes Leistungsdatum?',
        'time-of-service': 'Sollen Reinigungen am Sonntag oder nachts zwischen 22 Uhr und 5 Uhr stattfinden?',
        'visit-date-headline': 'Besichtigung zur Angebotserstellung',
        'visit-date-description':
          'Bevor wir die Reinigungskräfte zu Ihrem Objekt senden können, muss der Reinigungspartner Ihr Objekt besichtigen, um Ihre spezifischen Gegebenheiten berücksichtigen zu können.',
        'choose-visit-dates': 'Bitte wählen Sie drei bevorzugte Daten aus.',
        'date-placeholder': 'Datum',
        'option-date1': 'Option 1:',
        'option-date2': 'Option 2:',
        'option-date3': 'Option 3:',
        'success-headline': 'Ihre Anfrage war erfolgreich',
        'success-description':
          'Vielen Dank, Ihre Anfrage wurde erfolgreich an uns übermittelt. Wir bearbeiten Ihre Anfrage umgehend und melden uns in Kürze direkt bei Ihnen.',
        'success-brand': 'Ihr Service Partner ONE Team',
        error: 'Das ist eine Pflichtangabe.',
        'error-employees': 'Das ist eine Pflichtangabe. Bitte nur ganze Zahlen ab 5 Mitarbeitern eintragen.',
        'error-numbers': 'Das ist eine Pflichtangabe. Bitte nur ganze Zahlen.',
        'visit-date-error': 'Bitte geben Sie mindestens ein Datum an'
      },
      Settings: {
        headline: 'Einstellungen',
        'account-settings': 'Account Einstellungen',
        'tab-office-profile': 'Bürodaten',
        'tab-spendings': 'Ausgaben & Budget',
        'tab-billing-address': 'Rechnungsanschrift',
        'tab-shipping-address': 'Lieferanschrift',
        'tab-password': 'Passwort',
        'tab-users': 'Benutzer',
        'tab-languages': 'Sprache',
        'tab-login-security': 'Passwort & Sicherheit',
        'tab-email-notifications': 'E-Mail-Benachrichtigungen'
      },
      'Settings/LoginAndSecurity': {
        'current-user-headline': 'Aktiver Benutzer',
        'password-headline': 'Passwort',
        'all-users-headline': 'Alle Benutzer',
        users: 'Benutzer',
        edit: 'Bearbeiten',
        'view-all-users': 'Alle Nutzer anzeigen'
      },
      'Settings/ChangeLanguage': {
        fieldname: 'Sprache wählen',
        headline: 'Spracheinstellungen',
        german: 'Deutsch',
        english: 'Englisch'
      },
      'Settings/ChangePassword': {
        change: 'speichern',
        cancel: 'abbrechen',
        'current-password': 'Aktuelles Passwort',
        'new-password': 'Neues Passwort',
        'retype-password': 'Neues Passwort wiederholen',
        'passwords-not-matching': 'Passwörter stimmen nicht überein',
        headline: 'Passwort ändern',
        'hint-new-password': 'Passwort-Anforderungen: mindestens 8 Zeichen, mindestens 1 Zahl, mindestens 1 Buchstabe',
        'hint-repeat-password': 'Passwörter stimmen nicht überein'
      },
      'Settings/SetPassword': {
        headline: 'Neues Passwort vergeben',
        help: 'Bitte vergeben Sie zu Ihrer eigenen Sicherheit ein neues Passwort (mind. 8 Zeichen).',
        password: 'Neues Passwort',
        'retype-password': 'Neues Passwort wiederholen',
        'passwords-not-matching': 'Passwörter stimmen nicht überein',
        save: 'Passwort speichern'
      },
      'Settings/Invoices': {
        headline: 'Rechnungen & Bestellungen',
        date: 'Rechnungsdatum',
        'due-date': 'Fälligkeitsdatum',
        number: 'Rechnungsnummer',
        'no-items': 'Keine Rechnungen vorhanden.',
        due: 'Ausstehend',
        amount: 'Betrag',
        'amount-paid': 'Betrag bezahlt',
        'grand-total': 'Gesamtsumme',
        prepayments: 'Vorauszahlungen',
        'outstanding-amount': 'Restbetrag',
        status: 'Status',
        paid: 'Bezahlt',
        'tab-invoices': 'Rechnungen',
        'tab-orders': 'Meine Bestellungen'
      },
      'Settings/Orders': {
        from: 'Startdatum',
        to: 'Enddatum',
        'order-date': 'Bestelldatum',
        'po-number': 'Bestellnummer',
        description: 'Beschreibung',
        quantity: 'Anzahl',
        price: 'Preis',
        'included-tax': 'inkl. MwSt.',
        'ex-tax': 'zzgl. MwSt.',
        'po-number-placeholder': 'Bestellnummer eingeben',
        save: 'Speichern',
        cancel: 'Abbrechen',
        'no-result-msg': 'Sieht so aus, als hätten Sie noch keine Bestellungen aufgegeben. ',
        'no-result-hint': 'Sie können all Ihre Bürobedürfnisse erfüllen, indem Sie in unserem Shop bestellen.',
        'go-to-shop': 'Jetzt einkaufen',
        'insert-po-number': 'Bestellnummer eingeben',
        'edit-po-number': 'bearbeiten'
      },
      'Settings/Customer': {
        headline: 'Firma',
        info: 'Ihre Informationen',
        'customer-headline': 'Bürodaten',
        'billing-address-headline': 'Rechnungsadresse',
        'shipping-address-headline': 'Versandadresse',
        'logged-in-as': 'Eingeloggt als:'
      },
      'Settings/Customer/CustomerDataForm': {
        employees: 'Mitarbeiter',
        sqm: 'm²',
        'number-of-employees': 'Wie viele Mitarbeiter haben Sie?*',
        floorspace: 'Wie groß ist Ihre Bürofläche?*',
        'which-floors': 'Auf welchem Stockwerk befindet sich Ihr Büro?*',
        'has-elevator-escalator': 'Ist ein Aufzug zu Ihrem Büro vorhanden?*',
        'additional-fields-headline': 'Angabe weiterer Eckdaten zu Ihrem Büro',
        'opening-hours': 'Öffnungszeiten',
        'contact-person': 'Ansprechpartner',
        'phone-number': 'Telefonnnummer',
        'lock-type': 'Bürozutritt',
        key: 'Schlüssel',
        'number-pad': 'Passwort',
        'access-card': 'Zugangskarte',
        gatekeeper: 'Pförtner',
        'building-type': 'Gebäudetyp',
        altbau: 'Altbau',
        modern: 'Neubau',
        'number-of-kitchens': 'Anzahl der Küchen',
        'number-of-bathrooms': 'Anzahl der Sanitärräume',
        'number-of-windows': 'Anzahl der Fenster',
        'floor-type': 'Bodenbelag (im Büro)',
        tiling: 'Fliesen',
        carpet: 'Teppichboden',
        laminat: 'Laminat',
        parquet: 'Parkett',
        linoleum: 'Linoleum',
        pvc: 'PVC',
        'has-air-conditioner': 'Haben Sie eine Klimaanlage in Ihrem Büro?',
        'heater-type': 'Was für eine Heizung ist bei Ihnen installiert?',
        gas: 'Gasheizung',
        electronic: 'Elektroheizung',
        hydronic: 'Flächenheizgerät',
        'heat-pump': 'Wärmepumpe',
        'type-the-number': 'Bitten geben Sie die Anzahl der Fenster ein',
        other: 'andere',
        more: 'Mehr',
        yes: 'Ja',
        no: 'Nein',
        firstname: 'Vorname',
        lastname: 'Familienname',
        save: 'Änderungen speichern',
        'error-not-integer': 'Bitte ausschließlich ganze Zahlen eingeben, ohne Kommata.',
        'error-size-exceeded': 'Ungültige Eingabe- Zahl zu groß.',
        to: 'bis'
      },
      'Settings/Customer/BillingAddressForm': {
        street: 'Straße',
        'postal-code': 'PLZ',
        city: 'Stadt',
        state: 'Bundesland',
        country: 'Staat',
        person: 'Ansprechspartner',
        cellphone: 'Handynummer',
        email: 'E-Mail',
        save: 'Änderungen speichern',
        DE: 'Deutschland',
        NL: 'Niederlande',
        GB: 'Großbritannien',
        AU: 'Österreich',
        'request-change': 'Adressänderung beantragen'
      },
      'Settings/Customer/EmailNotifications': {
        headline: 'Hier können Sie die Benachrichtigungen, die Sie von uns bekommen, nach Ihren Wünschen einstellen:',
        updates: 'Updates & Ankündigungen',
        offers: 'Sonderangebote (Newsletter)',
        insights: 'Brancheneinblicke (Blog)',
        'updates-subline':
          'Bleiben Sie über Firmenankündigungen und Service Partner ONE Produkt- und Service-Updates informiert.',
        'offers-subline': 'Erhalten Sie Informationen über Sonderangebote unserer Produkte und Dienstleistungen.',
        'insights-subline':
          'Receive engaging content such as eBooks, whitepapers, videos, etc on the industry and how it’s relevant to you and your business.',
        disclaimer: 'Bitte beachten Sie, dass Änderungen bis zu einige Stunden Bearbeitungszeit benötigen können.',
        save: 'Änderungen speichern',
        'opt-out': 'Alle E-Mails abbestellen'
      },
      'Settings/Customer/ShippingAddressForm': {
        street: 'Straße',
        'postal-code': 'PLZ',
        city: 'Stadt',
        state: 'Bundesland',
        country: 'Staat',
        floor: 'Stockwerk',
        elevator: 'Aufzug vorhanden',
        save: 'Änderungen speichern',
        'request-change': 'Änderung der Adresse anfragen',
        'address-success': 'Vielen Dank für die Anfrage zur Anpassung Ihrer Lieferanschrift.',
        'address-soon': 'Wir werden diese schnellmöglich bestätigen.',
        'new-address': 'Neue Adresse:',
        'current-address': 'Aktuelle Adresse:',
        DE: 'Deutschland',
        NL: 'Niederlande',
        GB: 'Großbritannien',
        AU: 'Österreich'
      },
      'Settings/Users': {
        headline: 'Alle Benutzer',
        info: 'Ihre angelegten Benutzer'
      },
      'Settings/AddAccountData': {
        headline: 'Fast geschafft!',
        subline: 'Bitte geben Sie Ihre Firmendetails an.',
        address: 'Lieferadresse',
        plz: 'PLZ',
        city: 'Stadt',
        phone: 'Telefonnummer',
        employees: 'Anzahl Mitarbeiter',
        size: 'Bürofläche (m²)',
        submit: 'Bestätigen'
      },
      Shop: {
        headline: 'Shop'
      },
      'Shop/CategoryMenu': {
        more: 'Mehr'
      },
      'Shop/Cart/Default': {
        sum: 'Summe',
        vat: '(inkl. USt)',
        'no-vat': '(exkl. USt)',
        cancel: 'Zurück',
        next: 'Weiter',
        'shopping-cart': 'Warenkorb',
        'sub-total': 'Zwischensumme',
        deposit: 'Pfand',
        'delivery-costs': 'Versandkosten (Brutto)',
        'tax-amount': 'Umsatzsteuer',
        'minimum-order-value-reached': 'Mindestbestellwert von {{value}} erreicht',
        'minimum-order-value-not-reached': 'Mindestbestellwert von {{value}} noch nicht erreicht',
        'shipping-type': 'Versandart',
        'shipping-type-standard': 'Standard',
        'shipping-address': 'Lieferung an:',
        'shipping-address-1': 'Lieferadresse',
        'billing-address': 'Rechnungsadresse',
        'addresses-identical': 'Entspricht meiner Lieferadresse',
        comment: 'Anmerkungen',
        'comment-placeholder': 'Anmerkungen',
        'select-due-date': 'Liefertermin auswählen',
        'due-date': 'Liefertermin',
        'due-date-placeholder': 'z.B. 05.06.2016',
        'one-time': 'Einmalig',
        weekly: 'Wöchentlich',
        monthly: 'Monatlich',
        'weekday-mo': 'Mo',
        'weekday-tu': 'Di',
        'weekday-we': 'Mi',
        'weekday-th': 'Do',
        'weekday-fr': 'Fr',
        'weekday-sa': 'Sa',
        'weekday-su': 'So',
        'select-weekday': 'Wählen Sie die gewünschten Liefertage.',
        'every-2-weeks': 'Alle zwei Wochen',
        'every-3-weeks': 'Alle drei Wochen',
        'every-4-weeks': 'Alle vier Wochen',
        'every-day-month': 'Lieferung an jedem <strong>{{dayOfMonth}}. des Monats</strong>',
        'every-n-weekday-month': 'Lieferung an jedem <strong>{{weekdayPosition}}. {{dayName}} des Monats</strong>',
        'every-n-weekday-month-last': 'Lieferung an jedem <strong>letzten {{dayName}} des Monats</strong>',
        'close-cart': 'Weiter einkaufen',
        'need-help': 'Benötigen Sie Hilfe bei Ihrer Bestellung?',
        'empty-cart': 'Ihr Warenkorb ist leer.',
        'shop-now': 'Jetzt einkaufen',
        discount: 'Rabatt',
        'sum-subcart-discount': 'Sonstige Rabatte',
        'show-sum-details': 'Warenkorbdetails',
        checkout: 'Zur Kasse',
        order: 'Jetzt kaufen',
        'save-changes-error': 'Bitte speichern Sie Ihre Daten',
        finish: 'Warenkorb schließen',
        coupon: 'Gutscheincode',
        'coupon-placeholder': 'z.B. justdoit2016',
        redeem: 'Einlösen',
        'duration-3-months': 'Laufzeit: 3 Monate',
        'duration-6-months': 'Laufzeit: 6 Monate',
        'duration-12-months': 'Laufzeit: 12 Monate',
        'success-message': 'Ihre Bestellung wurde erfolgreich aufgegeben!',
        'notification-title': 'Hinweis:',
        'notification-text':
          'Mit dieser Bestellung überschreiten Sie Ihr gesetztes Budget. Sie können dennoch weiterhin Einkaufen',
        'notification-confirmation': 'Ja, ich möchte über meinem gesetzten Budget bestellen.',
        'notification-cat':
          'Diese Bestellung überschreitet Ihr gesetztes Budget für die Kategorie(n) {{value}}. Sie können dennoch weiterhin Einkaufen.',
        net: 'Netto',
        gross: 'Brutto',
        'no-vat-no-deposit': 'exkl. USt und Pfand',
        edit: 'Bearbeiten',
        'cancel-edit': 'Abbrechen',
        save: 'Speichern',
        companyName: 'Firmenname',
        street: 'Strasse und Hausnummer',
        postCode: 'Postleitzahl',
        city: 'Stadt',
        country: 'Land',
        floor: 'Etage',
        elevator: 'Fahrstuhl',
        notes: 'Anmerkungen',
        yes: 'Ja',
        no: 'Nein',
        'error-required': 'Pflichtfeld',
        AU: 'Österreich',
        DE: 'Deutschland'
      },
      'Shop/Cart/Editing': {
        'close-cart': 'Weiter bearbeiten',
        checkout: 'Änderungen bestätigen',
        order: 'Änderungen abschicken',
        'empty-cart': 'Fügen Sie Produkte hinzu.',
        'need-help': 'Benötigen Sie Hilfe beim Bearbeiten?',
        'success-message': 'Der Service wurde erfolgreich bearbeitet!'
      },
      'Shop/Cart/Payment': {
        'cc-number': 'Kartennummer',
        'cc-expiration': 'Gültig bis',
        'cc-expiration-placeholder': 'MM / JJ',
        'cc-save': 'Merken',
        'cc-cvc': 'Sicherheitscode',
        'cc-add': 'Hinzufügen',
        'cc-loading': 'Füge hinzu',
        'credit-card': 'Kreditkarte hinzufügen',
        headline: 'Zahlungsmethode',
        invoice: 'Rechnung'
      },
      'Shop/Cart/PoNumber': {
        'want-po-number': 'Möchten sie eine Bestellnummer hinterlegen?',
        'po-number': 'Bestellnummer',
        'change-chosen-po-number': 'Bestellnummer: "{{poNumber}}". Ändern?',
        add: 'Hinzufügen',
        change: 'Ändern'
      },
      'Shop/ProductOverview': {
        headline: 'Alle Produkte'
      },
      'Shop/FavoriteOverview': {
        headline: 'Ihre Favoriten',
        'show-all': 'Alle Favoriten anzeigen',
        'favorite-product': 'Favorit',
        'shop-now': 'Jetzt einkaufen.'
      },
      'Shop/SearchOverview': {
        headline: 'Suche'
      },
      'Shop/BestsellerOverview': {
        'show-all': 'Alle Bestseller anzeigen'
      },
      'Shop/PartnerFilter': {
        partners: 'Lieferanten',
        tooltip: 'Produkte einzelner Lieferanten ein- oder ausblenden',
        reset: 'Zurücksetzen'
      },
      'Shop/Product': {
        'add-to-cart': 'In den Warenkorb',
        'add-to-favourites': 'Produkt zu persönlichen Favoriten hinzufügen',
        'remove-from-favourites': 'Produkt von den Favoriten entfernen',
        subscription: 'im Abo'
      },
      'Shop/Notifications/Editing': {
        message: 'Sie sind gerade dabei die Bestellung "{{name}}" zu ändern.',
        help: 'Bitte beachten Sie dass nur Produkte von "{{name}}" verfügbar sind.',
        cancel: 'Abbrechen'
      },
      'Shop/Views/EmptyView': {
        message: 'Keine Produkte vorhanden.',
        'filter-active':
          'Sie haben die Lieferanten gefiltert. Setzen sie die Lieferanten zurück, um mehr Produkte zu sehen.',
        'reset-filter': 'Zurücksetzen',
        back: 'Zurück zu "alle Produkte"',
        'missing-product': 'Ihre Liebligsprodukt fehlt? Jetzt anforden!'
      },
      Support: {
        headline: 'Support'
      },
      'Support/Faq': {
        headline: 'FAQ',
        search: 'FAQ durchsuchen'
      },
      'Support/CreateCase': {
        headline: 'Kontakt',
        subline: 'Benötigen Sie Hilfe? Kontaktieren Sie uns.',
        subject: 'Betreff',
        body: 'Ihre Nachricht für uns',
        submit: 'Abschicken'
      },
      Terms: {
        headline: 'Allgemeine Geschäftsbedingungen',
        salutation: 'Sehr geehrter Service Partner ONE Kunde,',
        text:
          'wir arbeiten für Sie fortlaufend daran, Service Partner ONE sicherer und attraktiver zu machen. Das bringt auch von Zeit zu Zeit Änderungen an den AGB mit sich: Zum 15. Mai 2018 werden wir unsere Allgemeine Geschäftsbedingungen aktualisieren.',
        greetings: 'Herzliche Grüße,',
        spo: 'Ihr Service Partner ONE-Team',
        agree: 'Ich stimme zu'
      },
      Timeline: {
        headline: 'Kalender',
        'sync-calendar': 'Kalender synchronisieren'
      },
      Error: {
        'http-400': 'Bitte überprüfen Sie Ihre Angaben.',
        'http-500': 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.',
        'request-error': 'Ein Fehler ist aufgetreten: "{{message}}".',
        'error-unknown': 'Ein unbekannter Fehler ist aufgetreten.'
      },
      'Payments/CreditCard': {
        'card-number': 'Kartennummer',
        cvc: 'CVC',
        'exp-month': 'MM',
        'exp-year': 'JJJJ',
        charge: 'Bezahlen'
      },
      Issues: {
        headline: 'Kommunikationsbereich',
        'new-service-request-btn': '+ Neues Feedback senden',
        status: 'Status',
        open: 'Offen',
        none: 'Keiner',
        'waiting-for-sp': 'Warten auf SP',
        in_progress: 'In Bearbeitung',
        closed: 'Geschlossen',
        resolved: 'Abgeschlossen',
        reopened: 'Geöffnet',
        low: 'Niedrig',
        medium: 'Mittel',
        high: 'Hoch',
        urgent: 'Dringend',
        priority: 'Priorität',
        qaApp: 'QA App',
        assignee: 'Verantwortlicher',
        'open-issues': 'Offenes Feedback',
        'todays-trand': 'Der heutige Trend',
        'resolved-issues': 'Gelöste Probleme',
        'created-issues': 'Probleme erstellt',
        'response-time': 'Durchschnittliche Antwortzeit',
        'no-issues': 'Noch kein Feedback erstellt',
        'this-time-yesterday': 'gestern um diese Zeit',
        filterBtn: 'Filter',
        statsBtn: 'Analytics',
        from: 'von',
        to: 'zu',
        'select-dates': 'Datum auswählen',
        'new-issue-title': 'Neues Feedback',
        'issue-overview': 'Allgemein',
        object: 'Objekt',
        building: 'Gebäude',
        'choose-object': 'Objekt auswählen',
        category: 'Kategorie',
        'choose-issue-category': 'Kategorie auswählen',
        'description-attachment': 'Beschreibung',
        'task-description': 'Beschreiben Sie das Problem',
        'attach-picture': 'Bild anhängen',
        'attach-file': 'Datei anhängen',
        'location-information': 'Standortinformationen',
        'pick-status': 'Status auswählen',
        'choose-priority': 'Priorität auswählen',
        'assign-task': 'Zuweisen',
        'create-another': 'Weiteres erstellen',
        create: 'Erstellen',
        'call-now': 'Jetzt anrufen',
        'issue-properties': 'Feedbackdetails',
        update: 'Aktualisieren',
        floor: 'Etage',
        'input-floor': 'Etage eingeben',
        room: 'Raum',
        'input-room': 'Raumbezeichnung eingeben',
        'input-building': 'Gebäude eingeben',
        back: 'Zurück',
        'convert-into-booking': 'In Buchung umwandeln'
      },
      UserSettings: {
        'sidebar-title': 'Seite personalisieren',
        'sidebar-theme': 'Anpassen',
        'section-buttons-title': 'Button- und Linkfarbe',
        'section-main-title': 'Hauptfarbe',
        'section-upload-title': 'Logo',
        'section-upload-label': 'Logo hochladen',
        'btn-reset': 'Zurücksetzen',
        'btn-save': 'Speichern'
      },
      ServiceRequests: {
        approved: 'Genehmigt',
        archived: 'Archiviert',
        waiting_for_approval: 'Warten auf die Bestätigung',
        'open-service-requests': 'Offenes Feedback',
        'resolved-service-request': 'Bereits ausgeführte Serviceanfragen',
        'created-service-request': 'Serviceanfrage erstellt',
        headline: 'Alle Serviceanfragen',
        'new-service-request-btn': '+ Neue Serviceanfrage',
        qaApp: 'QA App',
        'qa-app': 'QA app',
        api: 'API',
        'todays-trand': 'Der heutige Trend',
        'no-service-requests': 'Noch keine Anfrage erstellt',
        filterBtn: 'Filter',
        statsBtn: 'Analytics',
        from: 'von',
        to: 'zu',
        'select-dates': 'Datum auswählen',
        'new-service-request-title': 'Neue Anfrage',
        'service-request-overview': 'Anfragedetails',
        'service-request-object': 'Objektinformation',
        object: 'Objekt',
        building: 'Gebäude',
        'choose-object': 'Wähle ein Objekt',
        'attach-picture': 'Bild anhängen',
        'location-information': 'Standortinformationen',
        update: 'Aktualisieren',
        floor: 'Etage',
        'input-floor': 'Geben Sie eine Etage ein',
        room: 'Zimmer',
        'input-room': 'Eingabe eines Raumes',
        'input-building': 'Gebäude betreten',
        back: 'Zurück',
        'total-service-requests': 'Service-Anfragen insgesamt',
        'expenses-overview': 'Ausgabenübersicht',
        'total-year': 'Insgesamt in diesem Jahr',
        budget: 'Budget',
        'request-status': 'Anforderungsstatus',
        'open-requests': 'Offene Anfragen',
        'pending-requests': 'Offene Anfragen',
        summary: 'Zusammenfassung',
        'service-date': 'Servicedatum',
        date: 'Datum',
        time: 'Zeit',
        'input-date': 'Wählen Sie ein Datum aus',
        'input-time': 'Wähle eine Zeit',
        'success-create': 'Serviceanforderung wurde erfolgreich erstellt!',
        'success-edit': 'Serviceanfrage wurde erfolgreich bearbeitet!',
        contact: 'Kontakt',
        'choose-contact': 'Kontakt auswählen',
        category: 'Kategorie',
        'choose-category': 'Wählen Sie die Kategorie aus',
        'task-description': 'Aufgabenbeschreibung',
        confirm: 'Bestätigen',
        damages: 'Schäden',
        cleaning_supplies: 'Reinigungsmittel',
        cleaning_service: 'Reinigungsservice',
        out_of_service: 'Außer Betrieb',
        size: 'Bürogröße in m²',
        'show-less': 'Zeige weniger',
        'show-more': 'Zeig mehr',
        status: 'Status',
        'service-request': 'Service Request',
        archive: 'Archiv',
        clone: 'Klon',
        'request-information': 'Anfrage Informationen',
        'service-provider': 'Dienstanbieter',
        'shipping-address': 'Lieferanschrift',
        'billing-address': 'Rechnungsadresse',
        'your-messages': 'Deine Nachrichten',
        'service-recipient': 'Dienstempfänger',
        'request-details': 'Anfragedetails',
        additional: 'Zusätzlich',
        intensive: 'Intensiv',
        window: 'Fenster'
      },
      Analytics: {
        'page-title': 'Analytics',
        from: 'von',
        to: 'zu',
        'download-csv': 'Als CSV herunterladen'
      },
      OfferReview: {
        offer: 'Angebot',
        'angebot-number': 'Angebot Nr.',
        'download-pdf': 'PDF herunterladen',
        'general-informations': 'Allgemeine Informationen',
        'service-period': 'Leistungszeitraum',
        frequency: 'Häufigkeit',
        'valid-until': 'Gültig bis',
        address: 'Adresse',
        'service-address': 'Leistungsort',
        'billing-provider': 'Rechnungsadresse',
        'service-provider': 'Dienstleister',
        'additional-info': 'Zusätzliche Informationen',
        description: 'Beschreibung',
        products: 'Produkte',
        item: 'Artikel',
        'total-price': 'Gesamtpreis',
        'tax-rate': 'Steuersatz',
        'task-list': 'Aufgabenliste',
        'your-offer-price': 'Ihr Angebotspreis',
        'net-price': 'Nettopreis',
        'total-vat': 'MwSt.',
        'gross-price': 'Bruttopreis',
        confirm: 'Bestätigen',
        reject: 'Ablehnen',
        'help-text':
          '*Nach Vertragsabschluss erhalten von uns je nach Wunsch monatlich oder auf Abruf eine Rechnung. Wir bitten Sie, den zu zahlenden Betrag auf das in der Rechnung genannte Konto zu überweisen.',
        'bank-acc': 'Bankverbindung',
        owner: 'Inhaber',
        iban: 'IBAN',
        'tax-number': 'Steuer-Nr.',
        ust: 'USt-IdNr.',
        rejected: 'Abgelehntes Angebot',
        accepted: 'Angenommenes Angebot',
        closed: 'Archiviertes Angebot',
        'offer-not-found':
          'Es tut uns leid, ein Fehler ist aufgetreten. Das Angebot konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
        'reject-offer': 'Angebot ablehnen',
        'accept-offer': 'Angebot akzeptieren',
        'manager-in-charge': 'Verantwortlicher Manager',
        contacts: 'Kontakte',
        phone: 'Telefon',
        email: 'E-mail',
        'one-time': 'Einmalig',
        weekly: 'wöchentlich',
        monthly: 'monatlich',
        daily: 'täglich',
        yearly: 'jährlich'
      },
      'OfferReview/OfferRejectModal': {
        'modal-text-1': 'Wir bedauern, dass die angebotene Leistung nicht Ihren Bedürfnissen entspricht.',
        'modal-text-2': 'Bitte teilen Sie uns mit, warum Sie das Angebot abgelehnt haben.',
        'comment-label': 'Ablehnungsgrund',
        'comment-placeholder': 'Helfen Sie uns, den Grund für die Ablehnung Ihres Angebot zu verstehen',
        submit: 'Abschicken'
      },
      'OfferReview/OfferAcceptModal': {
        'modal-text': 'Bitte teilen Sie uns Ihre Wünsche bezüglich der Terminplanung mit.',
        'start-date-label': 'Startdatum',
        'start-date-placeholder': 'Ab wann möchten Sie mit der Dienstleistung starten?',
        'select-days': 'An welchen Tagen soll die Dienstleistung stattfinden?',
        'suggest-time': 'Möchten Sie eine Uhrzeit vorschlagen?',
        'start-time-label': 'Startzeit',
        'end-time-label': 'Endzeit',
        'comment-label': 'Kommentar',
        'comment-placeholder': 'Möchten Sie einen Kommentar hinterlassen?',
        submit: 'Angebot bestätigen',
        'success-msg':
          'Vielen Dank, dass Sie das Angebot bestätigt haben. Sie erhalten den endgültigen Vertrag, nachdem wir die Terminplanung für Ihre Dienstleistung abgeschlossen haben. In der Zwischenzeit stehen wir Ihnen jederzeit für Fragen zur Verfügung und freuen uns auf eine erfolgreiche Zusammenarbeit.',
        'reject-msg':
          'Es tut uns Leid, dass das Angebot nicht Ihren Bedürfnissen entspricht. Wir werden uns zeitnah bei Ihnen melden.'
      }
    },

    en: {
      Common: {
        'no-tax': 'Ex. VAT',
        'vertical-drinks': 'Drinks',
        'vertical-fruits': 'Fruits',
        'vertical-rental': 'Rental',
        'vertical-coffee': 'Coffee',
        'vertical-cleaning': 'Cleaning',
        'vertical-supplies': 'Supplies',
        'vertical-handymen': 'Handyman',
        'vertical-equipment': 'Equipment',
        'vertical-food': 'Food',
        'vertical-catering': 'Catering',
        'vertical-misc': 'Misc',
        'vertical-relocation': 'Relocation',
        'vertical-total': 'Total Cost'
      },
      Form: {
        required: 'Required',
        phone: 'Phone number is invalid',
        'phone-tip': 'Example: +4917312345678',
        email: 'Email is invalid'
      },
      'Ads/RecommendCampaignDE': {
        label: 'Special',
        headline: 'Recommend Service Partner ONE – earn €50!',
        subline: 'Spread the word!',
        text:
          'Do you know Office Managers or Business Leaders in Berlin, Hamburg, Munich, Frankfurt am Main and Cologne who you’d like to recommend us to? We’ll reward you with €50!',
        button: 'Recommend'
      },
      'Ads/RecommendCampaignGB': {
        label: 'Special',
        headline: 'Recommend Service Partner ONE – earn £50!',
        subline: 'Spread the word!',
        text:
          'Do you know Office Managers or Business Leaders in London who you’d like to recommend us to? We’ll reward you with £50!',
        button: 'Recommend'
      },
      'Common/ServiceEvent': {
        'by-staff': 'By {{fullname}}',
        'track-order': 'Track Order'
      },
      'Common/ServiceEvent/NoteBox': {
        subject: 'Subject',
        body: 'Your message',
        deposit: 'Do you have deposit?',
        send: 'Send'
      },
      'Common/ServiceEvent/DepositBox': {
        body: 'Amount of crates',
        description:
          'Do you want your deposit bottles picked up? Please tell us the amount of crates you want picked up.',
        headline: 'Do you have returnable bottles?',
        info: 'Keep in mind that we need two days advanced notice. This cannot be changed on a shorter notice.',
        'close-link': 'Thanks, but not right now',
        send: 'Send'
      },
      'Common/ServiceEvent/NoteButton': {
        tooltip: 'Add a note'
      },
      'Common/ServiceEvent/DepositButton': {
        tooltip: 'Add a deposit'
      },
      'Common/ServiceEvent/RatingBox': {
        message: 'Your feedback (optional)',
        send: 'Send'
      },
      'Common/ServiceEvent/RatingButton': {
        tooltip: 'Add review'
      },
      'Common/ServiceEvent/ResumeButton': {
        'confirm-resume': 'Please contact customer service to reactivate this event.',
        'too-late':
          'We are sorry! This service can not be reactivated due to the short lead time. Please contact Customer Service.'
      },
      'Common/ServiceEventDefinition': {
        'back-to-sed': 'Back to Service',
        headline: 'Products & Services',
        'mode-on_demand': 'On demand',
        'mode-one_time': 'One time',
        'mode-recurrent': 'Recurrent',
        'next-event': 'Next Event: ',
        'by-partner': 'By {{name}}',
        'pending-request': 'Pending request',
        edited: 'Just edited'
      },
      'Common/ServiceEventDefinition/CancelButton': {
        cancel: 'Cancel',
        'confirm-cancel': 'Please contact Customer Care for changes or if you want to cancel the order.'
      },
      Dashboard: {
        headline: 'Dashboard',
        greeting: 'Welcome back',
        'greeting-december': 'Hohoho, happy end of the year time and holidays'
      },
      'Dashboard/Banner': {
        // headline: 'Do you have the right machine in your office?',
        // tagline:
        //     'If not, we are offering this month an unbeatable discount only valid until 30/11/2018. We will be happy to help you find the rightfully automatic coffee machine for your office.',
        // button: 'Request a non-binding quote',
        // 'button-mobile': 'Request a quote',
        // deal: 'Deal of the month:',
        // amount: '20%',
        // off: 'off'
        headline: 'SP ONE Advent calendar',
        tagline: 'Subscribe now to our digital Advent calendar to receive exclusive offers.',
        title: 'Stay warm on the inside',
        text: 'Not only hand warmers, but brings also joy.',
        text2: 'Get 20% off your coffee order (excluding capsules) for a net value of 100 € or more.',
        button: 'Subscribe to our Newsletter',
        button2: 'Buy now!',
        'button-mobile': 'Subscribe to our Newsletter',
        deal2: 'Deal of the month',
        discount2: '20% discount',
        extra: 'on coffee',
        deal: 'Daily offers:',
        discount: 'up to',
        amount: '20%',
        off: 'off',
        'your-email': 'Your e-mail address'
      },
      'Dashboard/Navbar': {
        headline: 'Products & Services for',
        'per-month': ' / Month',
        'to-current-month': 'To current month'
      },
      'Dashboard/Header': {
        'todays-events': "Today's events",
        'next-events': 'Next events',
        'see-calendar': 'See calendar for more',
        'need-help': 'Need help?',
        'chat-now': 'Chat with us now',
        'chat-not': 'Agent offline',
        'call-instead': 'The chat is not available. Call us instead: (030) 22 01237 0',
        'call-us': 'or call us at (030) 22 01237 0',
        'no-items': 'No events today',
        more: 'Show more',
        required: 'Required',
        optional: 'Optional',
        'info-missing': 'information missing',
        'info-completed': 'All optional information are completed.',
        'number-of-employees': 'Number of employees',
        'floor-space': 'Facility size',
        floor: 'Floor Level',
        elevator: 'Has elevator or not',

        'profile-progress': 'Office profile progress',
        'office-data': 'Still up to date? Take a look at your company and office data anytime:',
        'office-data-lead': 'Is your data correct?',
        'edit-profile': 'Edit office profile',
        'add-profile': 'Complete your office profile.',
        'first-steps': 'First steps for new customers',
        onboarding: 'Get to know our services',
        'onboarding-text': 'Would you like to be introduced to your dashboard?',
        'services-button': 'services',
        'services-text': 'Haven’t booked any services yet? Explore our '
      },
      'Dashboard/ServiceEventDefinitionOverview': {
        headline: 'Products & Services',
        tooltip: 'Your currently booked or ordered services'
      },
      'Dashboard/ServiceEventDefinitionOverview/EmptyList/Shop': {
        headline: 'Shop products',
        subline: 'Order everything around the office from beverages to IT needs.',
        button: 'Order now'
      },
      'Dashboard/ServiceEventDefinitionOverview/EmptyList/Service': {
        headline: 'Request service offer',
        subline: 'Ask your non-binding offer for cleaning, catering, coffee machines, handymen or water dispensers.',
        button: 'Request now'
      },
      'Dashboard/ServiceEventFilter': {
        tooltip: 'Only show or hide services with certain types',
        'reset-filters': 'Reset filters',
        filters: 'Filter',
        'filter-active': 'Active',
        'filter-paused': 'Paused',
        'filter-one_time': 'One-Time',
        'filter-recurrent': 'Recurrent',
        'filter-drinks': 'Drinks',
        'filter-fruits': 'Fruits',
        'filter-rental': 'Rental',
        'filter-coffee': 'Coffee',
        'filter-cleaning': 'Cleaning',
        'filter-supplies': 'Supplies',
        'filter-handymen': 'Handymen',
        'filter-equipment': 'Equipment',
        'filter-food': 'Food',
        'filter-catering': 'Catering',
        'filter-misc': 'Misc',
        'filter-relocation': 'Relocation',
        'filter-product-order': 'Product order'
      },
      'Dashboard/ServiceEventOverview': {
        'headline-upcoming': 'Upcoming Events',
        'headline-past': 'Past Events',
        'no-items': 'If you order products in the shop or book one of our services, then your dates will appear here.',
        more: 'More'
      },
      'Dashboard/Feedback': {
        headline: 'Your feedback helps us improve our services.',
        'was-service-provided':
          'Did our affiliate show up at your facility in order to make the product or service delivery?',
        'notify-service-was-not-provided':
          'We apologize that the service was not provided as expected. We will begin an inquiry immediately and get back to you as soon as possible.',
        'did-provider-do-all-tasks':
          'Was the product or service delivered as expected (i.e. all tasks were completed, all products included in delivery, no products were broken, cleaners behaved as expected, etc.)?',
        'not-fulfilled-tasks': 'Please detail why the product or service delivery was not as expected.',
        'submit-feedback': 'Submit your feedback',
        'notify-feedback-sent-successfully': 'Thank you for your feedback!',
        'notify-unexpected-error': 'An unexpected error occured.  Please try again.',
        'return-todashboard': 'Return to dashboard',
        yes: 'Yes',
        no: 'No',
        'mandatory-field': 'Mandatory field',
        'max-255': 'Max. 255 characters'
      },
      'Dashboard/PastEvents': {
        title: 'Past Events',
        more: 'Show More',
        'view-feedback': 'View Feedback',
        'submit-feedback': 'Submit Feedback',
        reorder: 'Reorder',
        'no-items': 'No past events during this month.',
        'no-events-head': 'No past events during the chosen month.',
        'no-events-sub': 'Services and products with delivery dates will be displayed here.',
        'go-to-cleaning': 'See cleaning services'
      },
      'Dashboard/Schedule': {
        title: 'Weekly schedule',
        more: 'Show More',
        edit: 'Edit',
        bottles: 'Bottle collection',
        comment: 'Comment',
        'no-events': 'No events for the selected day.',
        'no-events-head': 'You have no events for the selected day.',
        'no-events-sub': 'Past services and deliveries show up here.',
        'go-to-shop': 'Go to the shop',
        'track-order': 'Track Order'
      },
      'Dashboard/ServiceLinks': {
        title: 'Book additional services or products',
        cleaning: 'Cleaning',
        'other-services': 'Other Services',
        shop: 'Shop'
      },
      'Dashboard/InvoiceManagement': {
        'invoice-management': 'Invoice Management',
        overdue: 'Overdue',
        'coming-due': 'Coming due',
        total: 'Total',
        'see-invoices': 'See invoices'
      },
      Header: {
        'search-for': 'Search for products...',
        'product-is-missing': 'Product missing?'
      },
      'Modal/FeatureHighlights': {
        title: 'Welcome',
        next: 'Next',
        done: 'Done'
      },
      'Modal/MissingProduct': {
        headline: 'Request product',
        product: 'Enter product name',
        brand: 'brand',
        quantity: 'quantity',
        request: 'Request product',
        'info-headline': "Couldn't find the product you're looking for?",
        'info-text':
          'Simply enter the product name and submit a request! Our service team will check it, add the product if possible and get in touch soon.',
        'case-subject': 'Missing product in shop: {{product}}',
        'case-body':
          'The customer requested a product: {{product}} {{brand}} {{quantity}}. Please validate the request and add it to the pricebook if necessary.'
      },
      'Modal/ServiceEventDefinition': {
        'tab-events': 'Events',
        'tab-tasks': 'Tasks',
        'tab-products': 'Products',
        'no-events': 'No Events',
        week: 'Week {{week}}',
        more: 'More'
      },
      'Modal/ServiceEvent': {
        'back-to-service': 'Change abo',
        'no-tasks': 'No Tasks',
        'no-products': 'No Products',
        discount: 'Discount',
        'delivery-costs': 'Delivery costs',
        'sum-no-tax': 'Total (ex VAT)',
        'sum-tax': 'Total (inc VAT)',
        'current-price': 'Current price:',
        'out-of-stock': 'Out of stock',
        reorder: 'Reorder',
        edit: 'Edit'
      },
      'Modal/CalendarSync': {
        title: 'Synchronize Calendar',
        heading: 'How to synchronize your Service Partner ONE event calendar with your local calendar',
        'introduction-paragraph':
          'We use the standardized ical format. The synchronization process differs slightly from tool to tool. Please select your calendar' +
          ' application first.',
        icalendar: 'Apple iCalendar',
        outlook: 'Microsoft Outlook',
        gcalendar: 'Google Calendar',
        'step-one-heading': 'Which tool do you use?',
        'step-two-heading': 'Copy the calendar URL into your clipboard',
        'step-three-heading': 'Add the calendar',
        copy: 'Copy',
        'tutorial-icalendar':
          "<p>In iCalendar's menu, click on <strong>File</strong> and then on <strong>New calendar subscription</strong>. Paste the copied calendar URL " +
          'into the dialogue and click on <strong>subscribe.</strong>' +
          '<p>In the next dialogue you can configure some settings. Among them the name of the calendar and the update interval. Choose the update interval of your preference. We suggest <strong>daily.</strong> ' +
          'Now you can click on OK and the calendar will be synchronized. Due to a bug in iCalendar the events might not immediately appear. Click twice on the calendar in the ' +
          '<strong>Other calendars</strong> section - now the calendar should sync.</p>',
        'tutorial-gcalendar':
          '<p>Within the Google Calendar window there is a section called <strong>Other calendars</strong> on the bottom left - click on the small arrow next to that ' +
          "section's heading. Now click on <strong>add from URL</strong> and paste the calendar URL from step 2 into the new dialogue. Now click on <strong>Add calendar</strong> and you are done.</p>",
        'tutorial-outlook':
          "<p>In Outlook's calendar view, <strong>right click</strong> on <strong>My calendars</strong>. Now select <strong>Add calendar</strong> and <strong>from the internet</strong>. " +
          'In the newly opened window, paste the calendar URL from step 2 and then click on OK.</p>'
      },
      'Modal/ProductDetail': {
        price: 'Price',
        shipment: 'Shipment',
        'add-to-cart': 'Add to Cart',
        'minimum-order-value': 'Minimum order cost',
        deposit: 'Deposit',
        'discount-price': 'Subscription Price',
        'discount-question': 'Subscription?*',
        'discount-rules':
          '*Subscription-rules (duration, interval etc.) can be edited in the cart for each partner-checkout.',
        'discount-save': 'You save'
      },
      'Modal/CleaningTasklist': {
        'cleaning-intensive-cleaning-header': 'Intensive Cleaning Tasklist',
        'cleaning-general-cleaning-header': 'Cleaning Tasklist',
        'cleaning-header': 'Cleaning Tasklist',
        'premium-tasklist-title': 'premium cleaning',
        'cleaning-tasklist-linktitle': 'What’s included',
        'premium-header': 'Premium Services',
        'cleaning-particle': 'Cleaning article',
        'cleaning-performance': 'Service',
        'cleaning-offices': 'Office',
        'cleaning-wet-wipe-bag': 'Empty, replace garbage bags and sanitize (if necessary)',
        'cleaning-fog-wet': 'Wipe down with cleaning spray or towelettes',
        'cleaning-free-desk': 'Clean-up desks',
        'cleaning-hardware': 'Phone, keyboard, desk lamp',
        'cleaning-upholstery': 'Upholstery',
        'cleaning-vacuum': 'Vacuum/cleaning',
        'cleaning-traffic-areas': 'Traffic areas',
        'cleaning-floors': 'Flooring',
        'cleaning-kitchen': 'Kitchen',
        'cleaning-wet-fog-vacuum': 'Vacuum or sweep and fog wet wiping',
        'cleaning-shelfs-180': 'Closets, shelving, and window sills shorter than 1.8m in height',
        'cleaning-desks-180': 'Cleaned up desks, cupboards, shelves, window board up to 1.8m',
        'cleaning-shelf': 'Sink with tray, cabinet fronts',
        'cleaning-dishwasher': 'Dishwasher',
        'cleaning-move': 'Loading/unloading/start',
        'cleaning-plumbing': 'WC and Restroom Area',
        'cleaning-baskets': 'Waste baskets and paper containers',
        'cleaning-hard-floors': 'Hard floors (tiles)',
        'cleaning-disinfectant-wipe': 'Mop with disinfectant',
        'cleaning-wc-objects': 'All surfaces in WC area',
        'cleaning-disinfectant-polish': 'Clean with disinfectant',
        'cleaning-wall': 'WC walls and separators',
        'cleaning-mirror': 'Mirrors, trays, fittings',
        'cleaning-wet-wipe': 'Wet wipe and polish',
        'cleaning-clean-polish': 'Wipe away and polish surfaces',
        'cleaning-fill-paper': 'Toilet paper, hand towels and soap refill',
        'cleaning-fill-customer': 'Refill, supply from customer',
        'cleaning-optional-benefit': 'Optional services',
        'cleaning-components': 'Toeboard, heaters, doors, door frames, switches, wall sockets',
        'cleaning-cleanse': 'Cleaning',
        'cleaning-spider-web': 'Spiderwebs',
        'cleaning-sweep': 'Sweeping',
        'cleaning-only-premium': '* Only included in premium cleaning',
        'cleaning-dishes': 'Dishes, kitchenware, and cutlery',
        'cleaning-collect': 'Collect from working area',
        'cleaning-vacuum-clean-mop': 'Vacuum, sweep and mop (if necessary)',
        'cleaning-waste-baskets': 'Waste baskets and paper containers',
        'cleaning-empty-replace-bags': 'Empty, replace garbage bags and sanitize (if necessary)',
        'cleaning-cleared-off-desks': 'Cleared-off desks',
        'cleaning-telephones-keyboards-lamps': 'Telephones, keyboards and desk lamps',
        'cleaning-baseboards': 'Baseboards',
        'cleaning-radiators': 'Radiators',
        'cleaning-doors-doorframes': 'Doors and doorframes',
        'cleaning-light-switches-power-outlets': 'Light switches and power outlets',
        'cleaning-furniture': 'Furniture',
        'cleaning-remove': 'Remove',
        'cleaning-wipe-down-dust': 'Wipe down and dust off',
        'cleaning-deep-machine-cleaning': 'Deep cleaning with machine',
        'cleaning-urinal-walls': 'WC and urinal walls and separators',
        'cleaning-toilets-wall-tiles': 'Wall tiles around toilets and urinals',
        'cleaning-mirrors-shelves-fittings': 'Mirrors, shelves and fittings',
        'cleaning-fingerprints': 'Fingerprints on doors, glass doors, and other surfaces',
        'cleaning-office-equipment': 'Office equipment (telephones, keyboards, monitors, etc.)',
        'cleaning-bottles-kitchenware-cutlery': 'Bottles, kitchenware, and cutlery',
        'cleaning-collect-wash': 'Collect and wash',
        'cleaning-windowswills': 'Windowswills',
        'cleaning-chairs-non-upholstered': 'Chairs (non-upholstered)',
        'cleaning-oven': 'Oven and stovetop',
        'cleaning-refrigerator': 'Refrigerator',
        'cleaning-microwave': 'Microwave',
        'cleaning-sink-area': 'Sink area and cabinet surfaces',
        'cleaning-clean-interior-exterior': 'Clean interior and exterior',
        'cleaning-empty-reload-start': 'Empty, reload with dirty dishes, and start cycle',
        'cleaning-disinfect': 'Disinfect',
        'cleaning-all-areas': 'All Areas'
      },
      'Modal/CustomerInformation': {
        title: 'Are the values still up to date?',
        company: 'Company name',
        'floor-space': 'Floor space (sm)',
        'number-of-employees': 'Number of Employees',
        'title-billing-address': 'Billing Address',
        'title-shipping-address': 'Shipping Address',
        yes: 'Yes',
        no: 'No'
      },
      'Modal/ServiceEventRating': {
        title: 'How satisfied are you?',
        subtitle: 'Help us improve your service',
        'rate-type-cleaning': 'Rate the last service',
        'rate-type-default': 'Rate the last delivery',
        'rating-min': 'Totally unsatisfied',
        'rating-max': 'Very satisfied',
        'rating-message': 'Your feedback (optional)',
        rate: 'Send rating',
        cancel: 'Thanks, but not right now'
      },
      Members: {
        'page-title': 'Team Members',
        create: 'Add new team member',
        edit: 'Edit team member',
        'search-team': 'Search for team member',
        'deactivate-member': 'Deactivate member',
        'activate-member': 'Activate member',
        cancel: 'Cancel',
        save: 'Save',
        update: 'Update',
        gender: 'Gender',
        mr: 'Mr.',
        mrs: 'Mrs.',
        salutation: 'Salutation',
        firstname: 'First name',
        lastname: 'Last name',
        phone: 'Phone number',
        email: 'E-mail',
        password: 'Password',
        'no-members': "You don't have any team members yet.",
        'no-members-found': 'No member with this name has been found.',
        'create-now': 'Create a new team member now',
        'create-success': 'Team member has been successfully created.',
        'create-error': 'Team member could not be created. Please try again later.',
        'update-success': 'Member data has been updated successfully.',
        'update-error': 'Member data could not be updated. Please try again later.',
        'deactivate-success': 'Team member was successfully deactivated.',
        'activate-success': 'Team member was successfully activated.'
      },
      Navbar: {
        cleaning: 'Cleaning',
        dashboard: 'Dashboard',
        'my-account': 'My Account',
        'switch-account': 'Switch account:',
        'service-request': 'Service requests',
        'user-management': 'User Management',
        services: 'Services',
        shop: 'Shop',
        settings: 'Settings',
        support: 'Support',
        analytics: 'Analytics',
        timeline: 'Calendar',
        invoices: 'Invoices & Orders',
        issues: 'Issues',
        'tooltip-dashboard': 'Your personal dashboard',
        'tooltip-shop': 'Search & order products in the Webshop',
        'tooltip-services':
          'Request personal offer for cleaning, handymen, relocation, catering or water dispensers / coffee machines',
        'tooltip-timeline': 'Personal Calendar view',
        'tooltip-support': 'Contact us or find answers to frequently asked questions',
        'tooltip-settings': 'Change Password, download invoices',
        'tooltip-logout': 'Logout'
      },
      Notifications: {
        close: 'Close',
        ok: 'Ok',
        cancel: 'Cancel',
        'confirm-override-cart': 'Do you want to overwrite the products in your shopping cart?',
        'confirm-leave-editing': 'Do you really want to leave the editing mode? Your changes will be lost.'
      },
      'Public/NotFound': {
        headline: 'Apologies. We couldn´t find the page',
        description:
          'It looks like the page was displaced or removed. Use the top navigation bar or the next button to go directly to the dashboard.',
        dashboard: 'Go to dashboard',
        'services-headline': 'Services that might interest you',
        book: 'Book now',
        look: 'See services',
        'service-recurring-cleaning': 'Recurring Cleaning',
        'service-intensive-cleaning': 'One-time intensive cleaning',
        'service-other-services': 'More services'
      },
      'Public/Login': {
        headline: 'Log-in your Service Partner ONE account.',
        'enter-email': 'Please enter e-mail',
        'enter-password': 'Please enter password',
        email: 'E-Mail address',
        password: 'Password',
        login: 'Login',
        'no-client': 'Not a client?',
        register: 'Register',
        'register-long': 'Register',
        'forgot-password': 'Forgot password?',
        'partner-register': 'Login',
        'partner-register-headline': 'Already a Service Partner ONE service provider?',
        imprint: 'Imprint',
        'privacy-police': 'Privacy Police',
        header: 'We offer complete solutions. From the paperclip to office cleaning.'
      },
      'Public/Register': {
        city: 'City',
        company: 'Company name',
        complete: 'Complete',
        email: 'Company e-mail address',
        'email-placeholder': 'Your email-address',
        'employee-option-1': '< 50 employees',
        'employee-option-2': '50 - 100 employees',
        'employee-option-3': '100 - 250 employees',
        'employee-option-4': '250 - 500 employees',
        'employee-option-5': '> 500 employees',
        firstname: 'First name',
        'go-back': 'Login',
        headline: 'Create your Service Partner ONE account.',
        lastname: 'Last name',
        'live-demo-heading': 'Live Demo',
        'live-demo-text':
          'Join us for a free demo of the Service Partner ONE web interface and let us show you how so simplify our office management!',
        'live-demo-toggle-text': 'Yes, I want to get a live demonstration',
        ceo: 'Manager',
        'modal-headline': 'Signup',
        'modal-headline-step-2': 'Thank You for Your signup',
        'modal-headline-text':
          'Please help us to get the best offer for your company by filling out the optional fields.',
        'number-of-employees': 'Employees',
        'number-of-offices': 'Offices',
        phone: 'Phone',
        'phone-placeholder': 'Your telephone number',
        'postal-code': 'Postal code',
        login: 'Login',
        register: 'Signup',
        'office-space': 'Office space in sq ft',
        country: 'Country',
        'country-de': 'Germany',
        'country-en': 'United Kingdom',
        street: 'Street',
        subheadline: 'Already have an account?',
        vatin: 'VAT ID',
        mr: 'Mr.',
        ms: 'Ms.',
        password: 'Password',
        'password-placeholder': 'Insert password',
        'password-placeholder-2': 'Repeat password',
        'confirm-password': 'Confirm password',
        agb: 'By signing in, you agree to Service Partner ONEs ',
        agb2: 'Terms and conditions, ',
        agb3: '',
        agb4: 'Privacy policy ',
        agb5: '& Cookies and Internet advertising.',
        'accept-agb': 'Accept terms, conditions and privacy policy.',
        'check-inbox': 'Almost done! Please check your inbox',
        'confirm-email': 'Confirm your email address and access now all our services.',
        'click-link':
          '<span>We have sent you a verification email to <strong>{{email}}</strong>.<br />Please click on the confirmation link provided in the email.</span>',
        'no-email': 'You did not receive any email yet?',
        'send-email-again':
          'Emails can take some time to reach your mailbox or could have landed in your spam folder. If you have not received any email after 5 minutes, click here to ',
        'send-email-again-2': 'send an activation email again',
        'enter-password': 'Please provide a password with minimum 8 characters.',
        'enter-password-repeat': 'The entered passwords do not match.',
        'enter-email': 'Please enter a valid email address like "johndoe@mail.com".',
        'enter-name': 'Please enter a first and last name like "John" and "Doe".',
        'enter-firstname': 'Please enter a first name.',
        'enter-lastname': 'Please enter a last name.',
        'enter-company': 'Please enter a company name like "Acme GmbH".',
        'enter-phone': 'Please enter a valid phone number like "03022012370".',
        'passwords-not-matching': 'Passwords do not match.',
        'email-invalid': 'Email is not valid.',
        imprint: 'Imprint',
        'privacy-police': 'Privacy Police',
        DE: 'Germany',
        AU: 'Austria'
      },
      'Public/RecoverPassword': {
        headline: 'Forgot password',
        'go-back': 'Return',
        email: 'E-mail address',
        send: 'Send'
      },
      'Public/ResetPassword': {
        headline: 'Forgot password',
        'enter-email':
          'Please enter the e-mail you use for Service Partner ONE, so we can help you create a new password.',
        'go-back': 'Return',
        email: 'E-mail address',
        send: 'Send',
        alert:
          '<span>We have sent an e-mail to <strong>{{email}}</strong> with the instructions to reset your password.</span>'
      },
      'Public/RestorePassword': {
        headline: 'Pick new password',
        'new-password': 'New password',
        'retype-password': 'Repeat password',
        'passwords-not-matching': 'Passwords do not match',
        'password-too-short': 'At least 6 characters',
        change: 'Change'
      },
      'Public/Verify': {
        headline: 'Verification',
        'verify-text':
          'You are being forwarded to the SP ONE plattform. If this takes more than 10 seconds, use this button:',
        dashboard: 'Dashboard'
      },
      PartnerConfirmation: {
        'no-offer': 'Es steht kein aktives Angebot zur Verfügung.',
        confirm: 'Bestätigen',
        reject: 'Ablehnen',
        accept: 'Ich akzeptiere',
        offer: 'Angebot',
        'performance-protocol': 'Leistungsnachweis',
        'terms-conditions': 'die AGB',
        'terms-warning': 'Bitte AGB akzeptieren.',
        'offer-notification-reject': 'Dieses Angebot wurde am {{date}} abgelehnt.',
        'offer-notification-accept': 'Dieses Angebot wurde am {{date}} angenommen.',
        'performance-notification-reject': 'Dieser Leistungsnachweis wurde am {{date}} abgelehnt.',
        'performance-notification-accept': 'Dieser Leistungsnachweis wurde am {{date}} bestätigt.',
        'offer-date-reject': '<span>Dieses Angebot wurde am <strong class="red">{{date}}</strong> abgelehnt.</span>',
        'offer-date-accept': '<span>Dieses Angebot wurde am <strong class="red">{{date}}</strong> angenommen.</span>',
        'performance-date-reject':
          '<span>Dieser Leistungsnachweis wurde am <strong class="red">{{date}}</strong> abgelehnt.</span>',
        'performance-date-accept':
          '<span>Dieser Leistungsnachweis wurde am <strong class="red">{{date}}</strong> bestätigt.</span>',
        'performance-date-validity':
          '<span>An dieses Angebot halten wir uns bis zum <strong class="red">{{date}}</strong> gebunden.</span>',
        'headline-reject': 'Angebot ablehnen',
        'headline-accept': 'Bestätigung',
        'reject-reason': 'Bitten geben Sie an, weshalb Sie das Angebot ablehnen.',
        'reject-details': 'Angebotsdetails',
        'reject-offer': 'Angebot ablehnen',
        'accept-offer': 'Bestätigen',
        cancel: 'Abbrechen',
        'success-headline': 'Vielen Dank!',
        'success-description':
          'Der zuständige Service Manager wird sich umgehend für die Terminvereinbarung zur Ausführung der gewünschten Leistung bei Ihnen melden.',
        'success-description2':
          'Sie erhalten das unterschriebene Angebot separat per E-Mail und können dieses jederzeit über nachfolgenden Button herunterladen:',
        'success-description-performance':
          'Sie erhalten den Leistungsnachweis separat per E-Mail und können diesen jederzeit über nachfolgenden Button herunterladen:',
        unsuccessful: 'Wir bedauern, dass die angebotene Leistung nicht Ihren Bedürfnissen entspricht.',
        unsuccessful2:
          'Herr / Frau {{ServiceManager}} wird sich zeitnah bei Ihnen melden und steht Ihnen für Rückfragen zum Dienstleistungsangebot gerne zur Verfügung.',
        'unsuccessful-performance':
          'Sie haben soeben einen Leistungsnachweis über unsere erbrachte Dienstleistung abgelehnt. Herr / Frau {{ServiceManager}} wird sich umgehend bei Ihnen melden und steht für Rückfragen gerne zur Verfügung.',
        'success-button': 'Ihr Angebot',
        'success-button-performance': 'Ihr Leistungsnachweis',
        'error-reject': 'Bitte geben Sie einen Grund an.',
        'fetch-offer': 'Einen Moment, Ihre Anfrage wird bearbeitet.',
        'intro-text-offer':
          'vielen Dank für Ihr Interesse an unserer Dienstleistung. Nachfolgend finden Sie unser Angebot zu der gewünschten Leistung. Bitte bestätigen Sie die Durchführung der vereinbarten Leistungen über den Button “Bestätigen”.',
        'intro-text-performance':
          'vielen Dank, dass Sie unsere Dienstleistung in Anspruch genommen haben. Nachfolgend finden Sie die Übersicht zu unseren erbrachten Leistungen. Bitte bestätigen Sie die Durchführung der vereinbarten Leistungen über den Button “Bestätigen”.',
        'office-cleaning': 'Ihre professionelle Büroreinigung',
        'per-week': 'x pro Woche',
        'customer-id': 'Kunden-Nr.: ',
        ID: 'ID',
        addresses: 'Adressen',
        'shipping-address': 'Leistungsort',
        'billing-address': 'Rechnungsadresse',
        tasks: 'Auszuführende Leistung(en)',
        'choose-task': 'Bitte wählen Sie die Leistungen aus, die Sie verbindlich bestätigen möchten.',
        task: 'Position',
        'hours-cost': 'Stunden à',
        'squaremeters-cost': 'm² à',
        'pieces-cost': 'Stück à',
        'total-price': 'Gesamtpreis',
        'footer-1':
          'Das genannte Leistungsentgelt beinhaltet die Stellung der für die Reinigung notwendigen Ge- und Verbrauchsmaterialien, nicht enthalten sind die tariflichen Zuschläge für evtl. Nacht-, Feiertags- und Wochenendarbeit sowie für Überstunden. Skonto wird nicht gewährt.',
        'footer-2':
          '<span>Für Fragen steht Ihnen {{partner_name}} unter <a class="link" href="mailto:{{partner_email}}">{{partner_email}}</a> oder telefonisch unter {{partner_phone}} gern zur Verfügung.</span>',
        'footer-3':
          'Gerne sehen wir Ihrer Beauftragung der angebotenen Leistung entgegen, deren fachgerechte und sorgfältige Ausführung wir Ihnen zusichern.',
        'customer-info': 'Kundeninformationen',
        'customer-id-name': 'Kd. - Nr. / Kundebez.:',
        'cost-center': 'Kostenstelle:',
        'cost-payer': 'Kostenträger:',
        'task-list': 'Ausgeführte Leistung(en)',
        'cleaning-staff': 'Durchführender Mitarbeiter:',
        'price-box': 'Leistungsbetrag',
        summary: 'Zusammenfassung',
        tax: 'MwSt. (19%)',
        'total-with-tax': 'Gesamtbetrag (brutto)',
        confirming: 'Bestätigen',
        'some-rejected': 'Leistung(en) wurde(n) nicht ausgewählt',
        rejecting: 'Leistungen ablehnen',
        'all-rejected': 'Keine Leistung wurden ausgewählt',
        'offer-and-date': 'Angebot vom {{created_date}}',
        'performance-and-date': 'Ausgeführt im Zeitraum {{StartDate}} - {{EndDate}}',
        'service-worker': 'Ansprechpartner',
        'service-tel': 'Telefon:',
        'service-email': 'E-Mail: ',
        rejected: 'abgelehnt',
        area: 'Leistungsbereich:',
        'executor-headline': 'Durchführender Mitarbeiter',
        until: ' bis ',
        'view-pdf': 'PDF anzeigen',
        'po-number': 'Bestellnummer',
        'po-number-optional': 'Bestellnummer (optional)'
      },
      Services: {
        headline: 'Our Services',
        'info-line':
          'If you have any further questions regarding one of our services, please call <a href="tel:00442080172133">+44 20 8017 2133</a> (Mon — Fri, 09:00 — 18:00) or get in touch with your sales representative.',
        book: 'Book now',
        'service-cleaning-title': 'Cleaning',
        'service-cleaning-description': 'Are you looking for a professional cleaning company for your office?',
        'service-cleaning-url': 'https://servicepartnerone.typeform.com/to/YmWGVT',
        'service-handymen-title': 'Handymen',
        'service-handymen-description': 'Are you looking for a handyman for your office?',
        'service-handymen-url': 'https://servicepartnerone.typeform.com/to/eiwEiY',
        'service-relocation-title': 'Relocation',
        'service-relocation-description': 'Are you looking for a moving company?',
        'service-relocation-url': 'https://servicepartnerone.typeform.com/to/aCmobV',
        'service-catering-title': 'Catering',
        'service-catering-description': 'Are you looking for a catering provider for your office?',
        'service-catering-url': 'https://servicepartnerone.typeform.com/to/v40ioA',
        'service-water-title': 'Water dispenser',
        'service-water-description': 'Are you looking for a water dispenser for your office?',
        'service-water-url': 'https://servicepartnerone.typeform.com/to/Wy8Tge',
        'service-coffee-title': 'Coffee',
        'service-coffee-description': 'Are you looking for a coffee machine for your office?',
        'service-coffee-url': 'https://servicepartnerone.typeform.com/to/Fd7fmU'
      },
      'Services/Cleaning': {
        'service-cleaning-title': 'Recurring Cleaning',
        'service-cleaning-description': 'Get your cleaning offer fast, satisfaction guaranteed',
        'step-1': '1. Cleaning details',
        'step-2': '2. Scheduling',
        'step-3': '3. Done',
        'headline-step-1': 'Custom-made cleaning according to your requirements',
        'headline-step-2': 'Schedule a visit at your office',
        'headline-step-3': 'Confirmation',
        description:
          'One of our cleaning partners will visit your office to finalize the offer. For now we need a some information about your office. This will help you to estimate your monthly expenses and we can find the perfect cleaning partner for you more easily.',
        floor: 'How large is the area that needs to be cleaned (in m²)?',
        hours: 'Should cleaning be done on Sundays or at night (between 10pm and 5am)?',
        toilet: 'How large are your bathrooms (in m²)?',
        addonFloor: 'm²',
        addonEmployees: 'employees',
        estimatedPrice: 'Estimated Price',
        excludeTaxes: 'per month / excl. taxes',
        includesCleaningSupplies: 'Includes cleaning supplies',
        noTravelFees: 'No travel fees',
        priceEvaluationVisit: 'Price Evaluation Visit',
        'tooltip-week':
          'The calculator suggest a minimal cleaning frequency per week based on the office size you specify',
        'tooltip-premium':
          'Premium cleaning includes: 1. Clearing of desks 2. Cleaning of phones, keyboards, desk lamps 3. Filling/emptying dish washer 4. Cleaning of upholstered furniture',
        week: 'How often should your office be cleaned per week?',
        startDate: 'Your preferred starting date',
        woodenFloor: 'What type of floor do you have in the office?',
        nrEmployees: 'How many employees do you have?',
        kitchen: 'How large is your kitchen area in total (in m²)?',
        premium: 'Would you like to book ',
        'floor-type-carpet-bright': 'carpet bright',
        'floor-type-carpet-dark': 'carpet dark',
        'floor-type-laminate': 'laminate',
        'floor-type-parquet': 'parquet',
        'floor-type-linoleum': 'linoleum',
        'floor-type-screed': 'screed',
        'floor-type-stone': 'stone',
        'error-size-exceeded': 'Invalid entry - Inserted number is too large.',
        'error-not-integer': 'Only whole number characters allowed, without decimals or commas.',
        yes: 'Yes',
        no: 'No',
        next: 'Next',
        back: 'Back',
        result: 'Your monthly cleaning price should be around:',
        'cleaning-tasklist-title': 'Overview of our services',
        'option-date1': 'Option 1:',
        'option-date2': 'Option 2:',
        'option-date3': 'Option 3:',
        'output-placeholder': 'Please provide more data',
        'output-wrong': 'No price estimate possible',
        'result-headline': 'Your personal cleaning offer',
        'result-info':
          '*this is a preliminary estimate: there may be changes based on the specific conditions of your office and booking of additional services (window cleaning or intensive cleaning for example).',
        button: 'Schedule a visit',
        jumbotron: 'Schedule a visit',
        'time-description':
          'Please name three dates when we could visit your office. We will confirm the final visit date within two business days.',
        'time-headline': 'Please choose three preferred visit dates.',
        'success-headline': 'We have received your request',
        'success-description': 'Thank you for sending your request. We will get back to you shortly.',
        'success-brand': 'Your Service Partner ONE Team',
        'back-button': 'To the Services',
        'date-placeholder': 'Date',
        'time-placeholder': 'Time'
      },
      'Services/CleaningMenu': {
        headline: 'Cleaning Services',
        'recurring-cleaning-headline': 'Recurring cleaning service',
        'recurring-cleaning-point-1': 'Standard and premium cleaning tasks',
        'recurring-cleaning-point-2': 'Instant pricing',
        'recurring-cleaning-point-3': '1 year cleaning contract',
        'window-headline': 'Window cleaning',
        'window-point-1': 'Interior and exterior cleaning, including the option to clean window frames',
        'window-point-2': 'Instant pricing',
        'one-time-headline': 'One-time cleaning',
        'one-time-point-1': 'Customized cleaning tasks',
        'one-time-point-2': 'Pricing per hour',
        'one-time-point-additional': 'Ideal after company events, additional general cleaning hours',
        'intensive-headline': 'Intensive cleaning',
        'intensive-point-1': 'Standard deep cleaning tasks',
        'intensive-point-additional':
          'Ideal for intensive floor cleaning, office equipment cleaning, and general special cleaning tasks',
        'book-button': 'Book now',
        'cleaning-tasklist-linktitle': 'What’s included',
        'coming-soon': 'Coming soon'
      },
      'Services/AdditionalCleaning': {
        title: 'One-time cleaning',
        description: 'Get your cleaning offer fast, satisfaction guaranteed',
        'date-label': 'When would you like the cleaning to take place?',
        'date-placeholder': 'Date',
        'time-placeholder': 'Time',
        cleaningHours: 'How many hours of cleaning time are needed?',
        cleaningOption: 'What are the main tasks to perform during the cleaning session?',
        cleanFloor: 'Floor cleaning',
        collectBottles: 'Bottle collection',
        cleanToilets: 'Toilet cleaning',
        cleanDishes: 'Dish cleaning',
        cleanFurniture: 'Furniture cleaning',
        cleanKitchen: 'Kitchen cleaning',
        misc: 'Others',
        'please-describe': 'Please describe the tasks:',
        'is-partial': 'In which area should the cleaning take place?',
        'which-part': 'Please specify the zone:',
        partial: 'Specific area',
        total: 'Entire office',
        'error-date': 'Please choose a time and date!',
        'error-choose': 'Please choose a cleaning service!',
        cancel: 'Cancel',
        submit: 'Submit'
      },
      'Services/GegenbauerCleaning': {
        title: 'Einmalige Zusatzleistung',
        description:
          'Tragen Sie hier die Daten für die automatische Erstellung eines Angebots oder Leistungsnachweises ein.',
        'choose-type': 'Bitte wählen Sie aus, was erstellt werden soll:',
        offer: 'Angebot',
        performance: 'Leistungsnachweis',
        'date-label': 'Wann wird (Angebot) oder wurde (Leistungsnachweis) die Zusatzleistung ausgeführt?',
        'date-placeholder': 'Datum',
        'time-placeholder': 'Start-Uhrzeit',
        cleaningHours: 'Wie viele Reinigungsstunden brauchen Sie?',
        cleaningOption: 'Welche Leistungen werden ausgeführt?',
        'choose-task': 'Bitte auswählen',
        misc: 'Sonstige Leistungen',
        'please-describe': 'Anmerkung zur Aufgabe/Leistung:',
        size:
          'Wie groß ist die zu reinigende Fläche (m²) bzw. wie hoch ist die Stückzahl (#) der zu reinigenden Möbel?',
        where:
          'Bitte geben Sie an, wo im Gebäude die Leistung erbracht werden soll (z.B.: 1. Etage, Küche, Konferenzraum, Eingangsbereich, etc.):',
        contact: 'Bitte geben Sie den Leistungsempfänger mit Name, Anschrift und E-Mail-Adresse an:',
        'error-date': 'Bitte wählen Sie einen Termin aus!',
        'error-choose': 'Bitte wählen Sie einen Reinigungsservice aus!',
        'error-size': 'Bitte geben Sie einen Wert an.',
        'error-contact': 'Bitte geben Sie Kontaktdaten an.',
        'error-where': 'Bitte geben Sie einen Ort an.',
        cancel: 'Abbrechen',
        submit: 'Abschicken',
        'is-partial':
          'Sollen die Aufgaben auf der gesamten Fläche oder nur in einem bestimmten Bereich ausgeführt werden?',
        'which-part': 'Bitte geben Sie an, welche Teile des Objekts gereinigt werden soll:',
        partial: 'Teilbereich',
        total: 'gesamte Fläche',
        cleanFloor: 'Boden reinigen',
        collectBottles: 'Flaschen einsammeln',
        cleanToilets: 'Toiletten reinigen',
        cleanDishes: 'Geschirr reinigen',
        cleanFurniture: 'Möbel reinigen',
        cleanKitchen: 'Küche reinigen',
        'svs-cleaning': 'SVS Reinigungskraft (Mo-Sa)',
        'svs-glas': 'SVS Glasreinigung (Mo-Sa)',
        'svs-construction': 'SVS Baureinigung (Mo-Sa)',
        'deep-carpet': 'Grundreinigung Teppichboden',
        'deep-hardwood-no-coating': 'Grundreinigung Hartbelagböden ohne beschichten',
        'deep-hardwood-one-coating': 'Grundreinigung Hartbelagböden mit 1x beschichten',
        'deep-hardwood-two-coating': 'Grundreinigung Hartbelagböden mit 2x beschichten',
        'deep-between': 'Grund-/Zwischenreinigung Cleanern',
        'deep-hard-chairs': 'Grundreinigung Hartbelagstühle',
        'deep-padded-chairs': 'Grundreinigung Polsterstühle',
        'deep-furnishings-many': 'Grundreinigung Mobiliar (intensiv möbliert)',
        'deep-furnishings-few': 'Grundreinigung Mobiliar (gering möbliert)'
      },
      'Services/WindowCleaning': {
        title: 'Window cleaning',
        description: 'Get your window cleaning offer fast, satisfaction guaranteed',
        'office-size': 'What is the size of your facility?',
        more: 'more than',
        'window-label': 'Please describe your different types of windows',
        'window-number': 'Number of windows',
        'window-width': 'Width (cm)',
        'window-height': 'Height (cm)',
        'window-floor': 'Are your windows on ground level?',
        'window-panes': 'How many panes do the windows have?',
        'window-panes-nr': 'Amount',
        'window-double': 'Are the windows made of double casement?',
        'window-add': 'Add this window',
        'window-edit': 'Submit changed window',
        'window-too-high':
          'Do you have high windows that are located more than 2 meters above floor level (measuring from the bottom of the window)?',
        'window-frames': 'Should the frames be cleaned?',
        windows: 'window(s)',
        panes: 'panes',
        'double-windows': 'double casement',
        'floor-window': 'ground level',
        yes: 'Yes',
        no: 'No',
        'date-cleaning': 'Please choose a a start date for your cleaning session.',
        'date-visit': 'Please specify three possible dates for a visit.',
        'date-placeholder': 'Date',
        'time-placeholder': 'Time',
        'error-window':
          'Please check your data: windows (quantity and size) and panes are mandatory. Whole numbers only, no decimals.',
        cancel: 'Cancel',
        submit: 'Submit'
      },
      'Services/IntensiveCleaning': {
        'step-1': 'Cleaning details',
        'step-2': 'Scheduling',
        'step-3': 'Confirmation',
        'service-cleaning-title': 'Intensive Cleaning',
        'service-cleaning-description': 'Get your cleaning offer fast, satisfaction guaranteed',
        'facility-info-headline': 'Facility Information',
        'object-size': 'How large is your facility?',
        'no-of-employees': 'How many employees work in this space?',
        'define-cleaning-area-headline': 'Define Cleaning Areas',
        'work-space-headline': 'Work space',
        'work-space-switch': 'Should the intensive cleaning be carried out in the work space?',
        'work-space-size': 'What is the size of your work space?',
        sqm: 'sqm',
        more: '>',
        'all-areas-error': 'Please define at least one cleaning area.',
        dishes: 'Should dishes and bottles in the workspace be collected during the cleaning session?',
        'floor-type': 'Please describe the type of floor in your work space',
        'work-space-deep-clean': 'Should the floor of your work space be deeply cleaned?',
        'furniture-move': 'Should the furniture or tables be moved?',
        'office-equipment':
          'Should the office equipment be cleaned as well? (Phones, keyboards, computer screen, etc.)',
        bathrooms: 'Bathrooms',
        'bathroom-switch': 'Should the intensive cleaning be carried out in the bathrooms?',
        'bathroom-size': 'What is the size of your bathroom space?',
        'bathroom-deep-clean': 'Should the floor of your bathroom space be deeply cleaned?',
        'kitchen-switch': 'Should the intensive cleaning be carried out in the kitchen space?',
        kitchen: 'Kitchen',
        'kitchen-size': 'What is the size of your kitchen space?',
        'kitchen-floor-type': 'Please describe the type of floor in your kitchen space',
        'kitchen-deep-clean': 'Should the floor of your kitchen space be deeply cleaned?',
        'traffic-area-headline': 'Heavy Traffic Areas',
        'traffic-area-switch':
          'Should intensive cleaning be carried out in the heavy traffic areas (like the entrance space)?',
        'traffic-area-size': 'What is the size of your heavy traffic area?',
        'traffic-area-floor-type': 'Please describe the type of floor in your entrance space',
        'traffic-area-deep-clean': 'Should the floor of your entrance space be deeply cleaned?',
        other: 'Other',
        'other-switch':
          'Is there any other space you would like to include in the intensive cleaning session? (balcony, basement etc...)',
        'other-details': 'Please describe the other areas',
        cancel: 'Back',
        submit: 'Send request',
        next: 'Next',
        employees: 'employees',
        'choose-floor-type': 'Select a type of floor',
        'floor-type-tiling': 'tiling',
        'floor-type-carpet': 'carpet',
        'floor-type-laminate': 'laminate',
        'floor-type-parquet': 'parquet',
        'floor-type-linoleum': 'linoleum',
        'floor-type-pvc': 'PVC',
        'cleaning-date-headline': 'Schedule Cleaning Dates',
        'date-of-service': 'On what dates do you want the intensive cleaning to take place?',
        'time-of-service': 'Should cleaning be done on Sundays or at night between 10pm and 5am?',
        'visit-date-headline': 'Price Evaluation Visit',
        'visit-date-description':
          'Before sending the cleaners to your facility, we need to schedule an evaluation visit during which our partner can assess the specific requirements of your facility.',
        'choose-visit-dates': 'Please choose three preferred visit dates:',
        'date-placeholder': 'Date',
        'option-date1': 'Option 1:',
        'option-date2': 'Option 2:',
        'option-date3': 'Option 3:',
        error: 'This data is mandatory.',
        'error-employees': 'This data is mandatory. Whole numbers only, no decimals and at least 5 employees.',
        'error-numbers': 'This data is mandatory. Whole numbers only, no decimals.',
        'success-headline': 'We have received your request',
        'success-description': 'Thank you for sending your request. We will get back to you shortly.',
        'success-brand': 'Your Service Partner ONE Team',
        'visit-date-error': 'Please provide at least one option.'
      },
      Settings: {
        headline: 'Settings',
        'account-settings': 'Account settings',
        'tab-office-profile': 'Office Profile',
        'tab-billing-address': 'Billing Address',
        'tab-shipping-address': 'Shipping Address',
        'tab-password': 'Password',
        'tab-users': 'Users',
        'tab-languages': 'Languages',
        'tab-login-security': 'Login & Security',
        'tab-email-notifications': 'E-Mail-Notifications'
      },
      'Settings/LoginAndSecurity': {
        'current-user-headline': 'Logged-in user',
        'password-headline': 'Password',
        'all-users-headline': 'All Users',
        users: 'users',
        edit: 'Edit',
        'view-all-users': 'View all users'
      },
      'Settings/ChangeLanguage': {
        headline: 'Language settings',
        fieldname: 'Select language',
        german: 'German',
        english: 'English'
      },
      'Settings/ChangePassword': {
        change: 'Save',
        cancel: 'Cancel',
        'current-password': 'Current password',
        'new-password': 'New password',
        'retype-password': 'Repeat new password',
        'passwords-not-matching': 'Passwords do not match',
        headline: 'Edit password',
        'hint-new-password': 'Must be at least 8 characters, with at least one number and letter',
        'hint-repeat-password': "Passwords don't match"
      },
      'Settings/SetPassword': {
        headline: 'Change your password',
        help: 'Please enter a new password for your own security (at least 8 characters).',
        password: 'New password',
        'retype-password': 'Repeat new password',
        'passwords-not-matching': 'Passwords do not match',
        save: 'Save password'
      },
      'Settings/Invoices': {
        headline: 'Invoices & Orders',
        date: 'Invoice Date',
        'due-date': 'Due Date',
        number: 'Invoice No.',
        amount: 'Amount',
        'amount-paid': 'Amount Paid',
        'no-items': 'No invoices available.',
        due: 'Due',
        'grand-total': 'Grand Total',
        prepayments: 'Prepayments',
        'outstanding-amount': 'Outstanding Amount',
        status: 'Status',
        paid: 'Paid',
        'tab-invoices': 'Invoices',
        'tab-orders': 'Order history'
      },
      'Settings/Orders': {
        from: 'Start date',
        to: 'End date',
        'order-date': 'Order Date',
        'po-number': 'P.O. Number',
        description: 'Product & Service',
        quantity: 'Quantity',
        price: 'Price',
        'included-tax': 'incl. tax',
        'ex-tax': 'excl. tax',
        'po-number-placeholder': 'Type a P.O. Number',
        save: 'Save',
        cancel: 'Cancel',
        'no-result-msg': 'Looks like you haven’t placed any orders yet.',
        'no-result-hint': 'You can satisfy all your office needs by placing an order in our shop!',
        'go-to-shop': 'Shop now',
        'insert-po-number': 'Insert P.O. number',
        'edit-po-number': 'Edit'
      },
      'Settings/Customer': {
        headline: 'Company',
        info: 'Your company information',
        'customer-headline': 'Office data',
        'billing-address-headline': 'Billing address',
        'shipping-address-headline': 'Shipping address',
        'logged-in-as': 'Logged in as:'
      },
      'Settings/Customer/CustomerDataForm': {
        employees: 'employees',
        sqm: 'sqm',
        'number-of-employees': 'Number of employees? (required)',
        floorspace: 'Facility size? (required)',
        'which-floors': 'On which floor(s) is your office? (required)',
        'has-elevator-escalator': 'Do you have an elevator or escalator in your building? (required)',
        'additional-fields-headline': 'Additional office data, in order to auto complete booking forms (optional)',
        'opening-hours': 'Opening hours',
        'contact-person': 'Contact person',
        'phone-number': 'Phone number',
        'lock-type': 'Type of lock for the entrance door',
        key: 'Key',
        'number-pad': 'Number pad',
        'access-card': 'Access card',
        gatekeeper: 'Gatekeeper',
        'building-type': 'Type of the building',
        altbau: 'Altbau',
        modern: 'Modern',
        'number-of-kitchens': 'Number of kitchens',
        'number-of-bathrooms': 'Number of bathrooms',
        'number-of-windows': 'Number of windows',
        'floor-type': 'Type of floor in the work space',
        tiling: 'Tiling',
        carpet: 'Carpet',
        laminat: 'Laminate',
        parquet: 'Parquet',
        linoleum: 'Linoleum',
        pvc: 'PVC',
        'has-air-conditioner': 'Do you have air conditioner in your office?',
        'heater-type': 'What type of heater do you have?',
        gas: 'Gas',
        electronic: 'Electronic',
        hydronic: 'Hydronic',
        'heat-pump': 'Heat pump',
        'type-the-number': 'Please type the number',
        other: 'Other',
        more: 'More',
        yes: 'Yes',
        no: 'No',
        firstname: 'First Name',
        lastname: 'Last Name',
        save: 'Update profile',
        'error-not-integer': 'Only whole number characters allowed, without decimals or commas.',
        'error-size-exceeded': 'Invalid entry - Inserted number is too large.',
        to: 'to'
      },
      'Settings/Customer/BillingAddressForm': {
        street: 'Street',
        'postal-code': 'Postal code',
        city: 'City',
        state: 'Federal state',
        country: 'Country',
        person: 'Contact person',
        cellphone: 'Cell phone',
        email: 'E-mail',
        save: 'Save',
        DE: 'Germany',
        NL: 'Netherands',
        GB: 'United Kingdom',
        AU: 'Austria'
      },
      'Settings/Customer/EmailNotifications': {
        headline: 'You can customize the notifications you will receive from us and adapt it to your interests:',
        updates: 'Updates & Announcements',
        offers: 'Special Offers (Newsletter)',
        insights: 'Industry insights (Blog)',
        'updates-subline':
          'Stay in the know with Service Partner ONE product and feature updates, as well as occasional company announcements.',
        'offers-subline': 'Get informed about special deals regarding our products and services.',
        'insights-subline':
          'Receive engaging content such as eBooks, whitepapers, videos, etc on the industry and how it’s relevant to you and your business.',
        disclaimer: 'Please, note that changes in your preferences can take up to a few hours to take place.',
        save: 'Update preferences',
        'opt-out': 'Opt out of all eMail-communication.'
      },
      'Settings/Customer/ShippingAddressForm': {
        street: 'Street',
        'postal-code': 'Postal code',
        city: 'City',
        state: 'State',
        country: 'Country',
        floor: 'Floor Level',
        elevator: 'Has elevator or not',
        save: 'Save',
        'request-change': 'Request a change of address',
        'address-success': 'You have requested a change of address.',
        'address-soon': 'We will confirm the change as soon as possible',
        'new-address': 'New address:',
        'current-address': 'Current shipping address:',
        DE: 'Germany',
        NL: 'Netherands',
        GB: 'United Kingdom',
        AU: 'Austria'
      },
      'Settings/Users': {
        headline: 'All Users',
        info: 'Your users'
      },
      'Settings/AddAccountData': {
        headline: 'Almost there',
        subline: 'Please enter your facility details.',
        address: 'Office address',
        plz: 'Postal code',
        city: 'City',
        phone: 'Best contact number',
        employees: 'Number of employees',
        size: 'Office size in m²',
        submit: 'Finish'
      },
      Shop: {
        headline: 'Shop'
      },
      'Shop/CategoryMenu': {
        more: 'More'
      },
      'Shop/Cart/Default': {
        sum: 'Sum',
        vat: '(inc. VAT)',
        'no-vat': '(Ex. VAT)',
        cancel: 'Back',
        next: 'Next',
        'shopping-cart': 'Shopping Cart',
        'sub-total': 'Subtotal',
        deposit: 'Deposit',
        'delivery-costs': 'Delivery costs (Gross)',
        'tax-amount': 'VAT',
        'minimum-order-value-reached': 'Minimum order value of {{value}} reached',
        'minimum-order-value-not-reached': 'Minimum order value of {{value}} not reached',
        'shipping-type': 'Delivery',
        'shipping-type-standard': 'Standard',
        'shipping-address': 'Delivery address',
        'shipping-address-1': 'Shipping address',
        'billing-address': 'Billing address',
        'addresses-identical': 'Same as delivery address',
        comment: 'Notes',
        'comment-placeholder': 'Notes',
        'select-due-date': 'Add delivery date',
        'due-date': 'Delivery Date',
        'due-date-placeholder': 'e.g. 06/05/2016',
        'one-time': 'One time',
        weekly: 'Weekly',
        monthly: 'Monthly',
        'weekday-mo': 'Mo',
        'weekday-tu': 'Tu',
        'weekday-we': 'We',
        'weekday-th': 'Th',
        'weekday-fr': 'Fr',
        'weekday-sa': 'Sa',
        'weekday-su': 'Su',
        'select-weekday': 'Select your preferred delivery days.',
        'every-2-weeks': 'Every two weeks',
        'every-3-weeks': 'Every three weeks',
        'every-4-weeks': 'Every four weeks',
        'every-day-month': 'Delivered on every <strong>{{dayOfMonth}}th of the Month</strong>',
        'every-n-weekday-month': 'Delivered on every <strong>{{weekdayPosition}}. {{dayName}} of the Month</strong>',
        'every-n-weekday-month-last': 'Delivered on every <strong>last {{dayName}} of the Month</strong>',
        'close-cart': 'Continue shopping',
        'need-help': 'Need help with your order?',
        'empty-cart': 'Your Shopping Cart is empty.',
        'shop-now': 'Shop now!',
        discount: 'Discount',
        'sum-subcart-discount': 'Additional discount',
        'show-sum-details': 'Checkout details',
        checkout: 'Checkout',
        order: 'Place your Order',
        'save-changes-error': 'Please save your changes',
        finish: 'Close shopping cart',
        coupon: 'Voucher code',
        'coupon-placeholder': 'i.e. justdoit2016',
        redeem: 'Redeem',
        'duration-3-months': 'Duration: 3 Months',
        'duration-6-months': 'Duration: 6 Months',
        'duration-12-months': 'Duration: 12 Months',
        'success-message': 'Well done! We received your order.',
        'notification-title': 'Notification:',
        'notification-text':
          'This order exceeds your budget for the current month. You are still allowed to continue ordering',
        'notification-confirmation': 'Yes, I would like to order over my budget.',
        'notification-cat':
          'This order is exceeding your monthly budget for the following categories {{value}}. You are still allowed to continue ordering.',
        net: 'Net',
        gross: 'Gross',
        'no-vat-no-deposit': 'ex. VAT and Deposit',
        edit: 'Edit',
        'cancel-edit': 'Cancel',
        save: 'Save',
        companyName: 'Company name',
        street: 'Street',
        postCode: 'Post code',
        country: 'Country',
        city: 'City',
        floor: 'Floor',
        elevator: 'Elevator',
        notes: 'Notes',
        yes: 'Yes',
        no: 'No',
        'error-required': 'Required',
        AU: 'Austria',
        DE: 'Germany'
      },
      'Shop/Cart/Editing': {
        'close-cart': 'Continue editing',
        checkout: 'Confirm',
        order: 'Apply',
        'empty-cart': 'Add products.',
        'need-help': 'In need of help editing?',
        'success-message': 'Service was successfully edited!'
      },
      'Shop/Cart/Payment': {
        'cc-number': 'Card number',
        'cc-expiration': 'Expiration date',
        'cc-expiration-placeholder': 'MM / YY',
        'cc-save': 'Remember',
        'cc-cvc': 'Security code',
        'cc-add': 'Add',
        'cc-loading': 'Adding',
        'credit-card': 'Add credit card',
        headline: 'Payment method',
        invoice: 'Invoice'
      },
      'Shop/Cart/PoNumber': {
        'want-po-number': 'Would you like to add a purchase order number?',
        'po-number': 'P.O. number',
        'change-chosen-po-number': 'P.O. number: {{poNumber}}. Change?',
        add: 'Add',
        change: 'Change'
      },
      'Shop/ProductOverview': {
        headline: 'All Products'
      },
      'Shop/FavoriteOverview': {
        headline: 'Favourites',
        'show-all': 'Show all favourites',
        'favorite-product': 'Favourite',
        'shop-now': 'Find some now!'
      },
      'Shop/BestsellerOverview': {
        'show-all': 'Show all bestseller'
      },
      'Shop/SearchOverview': {
        headline: 'Search'
      },
      'Shop/PartnerFilter': {
        partners: 'Suppliers',
        tooltip: 'Only show or hide products by individual suppliers',
        reset: 'Reset'
      },
      'Shop/Product': {
        'add-to-cart': 'Add to cart',
        'add-to-favourites': 'Add product to personal favourites',
        'remove-from-favourites': 'Remove product from personal favourites',
        subscription: 'in Subscr.'
      },
      'Shop/Notifications/Editing': {
        message: 'You are currently editing order {{name}}.',
        help: 'Please note that only products from {{name}} are available.',
        cancel: 'Cancel'
      },
      'Shop/Views/EmptyView': {
        message: 'No Products found.',
        'filter-active': 'Supplier filter is currently activated. Reset suppliers to see more products.',
        'reset-filter': 'Reset',
        back: 'Back to products overview page',
        'missing-product': 'Your favourite product is missing? Ask for it now!'
      },
      Support: {
        headline: 'Support'
      },
      'Support/Faq': {
        headline: 'FAQ',
        search: 'Search FAQ'
      },
      'Support/CreateCase': {
        headline: 'Contact',
        subline: 'Need help? Get in touch with us.',
        subject: 'Subject',
        body: 'Your Message',
        submit: 'Submit'
      },
      Terms: {
        headline: 'Terms and Conditions',
        salutation: 'Dear Service Partner ONE customers,',
        text:
          'We are constantly working to make Service Partner ONE more attractive and secure. Time-to-time, we must change our Terms and Conditions to help accomplish that goal. From May 15th, 2018, we will update our Terms and Conditions. ',
        greetings: 'Sincerely,',
        spo: 'Your Service Partner ONE Team',
        agree: 'I accept'
      },
      Timeline: {
        headline: 'Calendar',
        'sync-calendar': 'Synchronize Calendar'
      },
      Error: {
        'http-400': 'Please check your data.',
        'http-500': 'Something went wrong, please try again.',
        'request-error': 'An error occured: "{{message}}".',
        'error-unknown': 'An unknown error occured.'
      },
      'Payments/CreditCard': {
        'card-number': 'Card number',
        cvc: 'CVC',
        'exp-month': 'MM',
        'exp-year': 'YYYY',
        charge: 'Charge'
      },
      Issues: {
        headline: 'All issues',
        'new-issue-btn': '+ New issue',
        status: 'Status',
        low: 'Low',
        medium: 'Medium',
        high: 'High',
        urgent: 'Urgent',
        open: 'Open',
        none: 'None',
        'waiting-for-sp': 'Waiting for SP',
        in_progress: 'In progress',
        closed: 'Closed',
        resolved: 'Resolved',
        reopened: 'Reopened',
        priority: 'Priority',
        qaApp: 'QA app',
        assignee: 'Assignee',
        'open-issues': 'Open issues',
        'todays-trand': "Today's trend",
        'resolved-issues': 'Resolved issues',
        'created-issues': 'Created issues',
        'response-time': 'Average response time',
        'no-issues': 'No issue created yet',
        'this-time-yesterday': 'this time yesterday',
        filterBtn: 'Filter',
        statsBtn: 'Analytics',
        from: 'from',
        to: 'to',
        'select-dates': 'Select dates',
        'new-issue-title': 'New issue',
        'issue-overview': 'General',
        object: 'Object',
        'priority-title': 'Priority and assignee',
        'choose-object': 'Choose an object',
        category: 'Category',
        'choose-issue-category': 'Choose a category',
        'description-attachment': 'Description and attachments',
        'task-description': 'Feedback description',
        'attach-picture': 'Attach picture',
        'location-information': 'Location information',
        'pick-status': 'Pick a status',
        'choose-priority': 'Choose priority',
        'assign-task': 'Choose assignee',
        'create-another': 'Create another',
        create: 'Create',
        'call-now': 'Call now',
        'issue-properties': "Issue's properties",
        update: 'Update',
        floor: 'Floor',
        'input-floor': 'Enter floor',
        contact: 'Contact',
        'contact-input': 'Choose contact',
        room: 'Room',
        'input-room': 'Input a room',
        back: 'Back',
        'convert-into-booking': 'Convert into booking',
        'write-answer': 'Write a message',
        damages: 'Damages',
        cleaning_supplies: 'Cleaning Supplies',
        cleaning_service: 'Cleaning Service',
        out_of_service: 'Out Of Service',
        'success-create': 'Issue was successfully created!',
        'success-edit': 'Issue was successfully edited!',
        'show-less': 'show less',
        'show-more': 'show more'
      },
      UserSettings: {
        'sidebar-title': 'Customize site',
        'sidebar-theme': 'Customize',
        'section-buttons-title': 'Button and link colors',
        'section-main-title': 'Main Color',
        'section-upload-title': 'Logo',
        'section-upload-label': 'Upload logo',
        'btn-reset': 'Reset',
        'btn-save': 'Save'
      },
      ServiceRequests: {
        approved: 'Approved',
        archived: 'Archived',
        waiting_for_approval: 'Waiting for approval',
        'open-service-requests': 'Open service requests',
        'resolved-service-request': 'Executed service requests',
        'created-service-request': 'Service request created',
        headline: 'All service requests',
        'new-service-request-btn': '+ New service request',
        qaApp: 'QA app',
        'qa-app': 'QA app',
        api: 'API',
        'todays-trand': "Today's trend",
        'no-service-requests': 'No service requests created yet',
        filterBtn: 'Filter',
        statsBtn: 'Analytics',
        from: 'from',
        to: 'to',
        status: 'Status',
        'select-dates': 'Select dates',
        'new-service-request-title': 'New service request',
        'service-request-overview': 'Request details',
        'service-request-object': 'Object information',
        object: 'Object',
        building: 'Building',
        'choose-object': 'Choose an object',
        'attach-picture': 'Attach picture',
        'attach-file': 'Attach file',
        'location-information': 'Location information',
        update: 'Update',
        floor: 'Floor',
        'input-floor': 'Enter floor',
        room: 'Room',
        'input-room': 'Enter room',
        'input-building': 'Enter building',
        back: 'Back',
        'total-service-requests': 'Total service requests',
        'expenses-overview': 'Expenses overview',
        'total-year': 'Total this year',
        budget: 'Budget',
        'request-status': 'Request status',
        'open-requests': 'Open requests',
        'pending-requests': 'Pending requests',
        summary: 'Summary',
        'service-date': 'Service date',
        date: 'Date',
        time: 'Time',
        'input-date': 'Choose a date',
        'input-time': 'Choose a time',
        'success-create': 'Service request was successfully created!',
        'success-edit': 'Service request was successfully edited!',
        contact: 'Contact',
        'choose-contact': 'Choose contact',
        category: 'Category',
        'choose-category': 'Choose the category',
        'task-description': 'Task description',
        confirm: 'Confirm',
        damages: 'Damages',
        cleaning_supplies: 'Cleaning Supplies',
        cleaning_service: 'Cleaning Service',
        out_of_service: 'Out Of Service',
        size: 'Office size in m²',
        'service-request': 'Service Request',
        archive: 'Archive',
        clone: 'Clone',
        'request-information': 'Request information',
        'service-provider': 'Service provider',
        'shipping-address': 'Shipping address',
        'billing-address': 'Billing address',
        'your-messages': 'Your messages',
        'service-recipient': 'Service recipient',
        'request-details': 'Request Details',
        additional: 'Additional',
        intensive: 'Intensive',
        window: 'Window'
      },
      Analytics: {
        'page-title': 'Analytics',
        from: 'from',
        to: 'to',
        'download-csv': 'Download as CSV'
      },
      OfferReview: {
        offer: 'Offer',
        'angebot-number': 'Offer Nr.',
        'download-pdf': 'Download PDF',
        'general-informations': 'General informations',
        'service-period': 'Service period',
        frequency: 'Frequency',
        'valid-until': 'Valid until',
        address: 'Address',
        'service-address': 'Service address',
        'billing-provider': 'Billing address',
        'service-provider': 'Service provider',
        'additional-info': 'Additional information',
        description: 'Description',
        products: 'Products',
        item: 'Item',
        'total-price': 'Total price',
        'tax-rate': 'Tax rate',
        'task-list': 'Task list',
        'your-offer-price': 'Your offer price',
        'net-price': 'Net price',
        'total-vat': 'Total VAT amount',
        'gross-price': 'Gross price',
        confirm: 'Confirm',
        reject: 'Reject',
        'help-text':
          '*After conclusion of the contract, we will send you an invoice monthly or on demand. We ask you to transfer the invoice amount to the account stated in the invoice.',
        'bank-acc': 'Bank account',
        owner: 'Owner',
        iban: 'IBAN',
        'tax-number': 'Tax-Nr.',
        ust: 'USt-IdNr.',
        rejected: 'Rejected offer',
        accepted: 'Accepted offer',
        closed: 'Closed offer',
        'offer-not-found': "We're sorry, there's been an error. The offer could not be loaded. Please try again later.",
        'reject-offer': 'Reject offer',
        'accept-offer': 'Accept offer',
        'manager-in-charge': 'Manager in charge',
        contacts: 'Contacts',
        phone: 'Phone',
        email: 'E-mail',
        'one-time': 'One time',
        weekly: 'per week',
        monthly: 'per month',
        daily: 'per day',
        yearly: 'per year'
      },
      'OfferReview/OfferRejectModal': {
        'modal-text-1': 'We regret that the service offered does not meet your needs.',
        'modal-text-2': 'Would you be so kind to let us know why you rejected the offer?',
        'comment-label': 'Reason for refusal',
        'comment-placeholder': 'Help us to understand the reason for the rejection of your offer',
        submit: 'Submit'
      },
      'OfferReview/OfferAcceptModal': {
        'modal-text': 'Please let us know your wishes regarding scheduling.',
        'start-date-label': 'Start date',
        'start-date-placeholder': 'When would you like to start using the service?',
        'select-days': 'On which days should the service take place?',
        'suggest-time': 'Would you like to suggest a time?',
        'start-time-label': 'Start time',
        'end-time-label': 'End time',
        'comment-label': 'Comment',
        'comment-placeholder': 'Would you like to leave a comment?',
        submit: 'Accept offer',
        'success-msg':
          'Thank you for confirming the offer. You will receive the final contract after we have completed the scheduling of your service. In the meantime, we are always available to answer your questions and look forward to a successful cooperation.',
        'reject-msg':
          'We are sorry that the offer does not meet your needs. Your service manager will get back to you as soon as possible.'
      }
    }
  }
});

export default i18next;
