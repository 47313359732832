import { fetchUser } from '../../actions/users';
import memoize from '../memoize';

const getAuthenticated = state => state.auth.data.isAuthenticated;
const getUserId = state => state.current.user && state.current.user.id;

export default dispatch =>
  memoize(
    [getAuthenticated, getUserId],
    (isAuthenticated, userId) => isAuthenticated && !userId && dispatch(fetchUser())
  );
