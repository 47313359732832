import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle, ServiceEventDefinition } from 'common';
import { fetchServiceEventDefinition } from 'actions/serviceEventDefinitions';
import { Modal } from 'components/HOC';
import Tabs from './Tabs';

import './ServiceEventDefinitionModal.less';

export class ServiceEventDefinitionModal extends Component {
  componentDidMount() {
    if (!this.props.item) {
      this.props.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item && !this.props.item) {
      this.props.fetchData();
    }
  }

  render() {
    const { item } = this.props;

    return (
      <DocumentTitle title={(item && item.name) || '...'}>
        <div className="modal-flex">
          {item && <ServiceEventDefinition item={item} />}
          {item && <Tabs item={item} />}
        </div>
      </DocumentTitle>
    );
  }
}

ServiceEventDefinitionModal.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  isLoading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  item: PropTypes.object,
  fetchData: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const {
    serviceEventDefinitions: {
      entities,
      single: { [props.id]: { isFetching } = {} }
    }
  } = state;
  const item = entities[props.id];

  return {
    isLoading: !item && isFetching,
    item
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchData: () => dispatch(fetchServiceEventDefinition(id))
});

export default withTranslation('Modal/ServiceEventDefinition')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Service Event Definition', { className: 'modal-sed' })(ServiceEventDefinitionModal))
);
