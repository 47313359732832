import { CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT } from '../../constants/contentful';
import { showSetPassword, showFeatureHighlights, showCustomerInformation } from '../../actions/location';
import memoize from '../memoize';

const getAuthenticated = state => state.auth.data.isAuthenticated;
const getForceSetPassword = state => !!(state.current.user && state.current.user.should_set_password);
const getFeatureHighlights = state =>
  !!(
    state.contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT] &&
        state.contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items &&
        state.contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items.length > 0
  );
const getUpdateInformation = state => !!(state.current.customer && state.current.customer.should_update_information);

export default dispatch =>
  memoize(
    [getAuthenticated, getForceSetPassword, getFeatureHighlights, getUpdateInformation],
    (isAuthenticated, forceSetPassword, featureHighlights, updateInformation) => {
      if (!isAuthenticated) return;
      if (forceSetPassword) dispatch(showSetPassword());
      else if (featureHighlights) dispatch(showFeatureHighlights());
      else if (updateInformation) dispatch(showCustomerInformation());
    }
  );
