import {
  SERVICE_EVENT_CLEANING_REQUEST,
  SERVICE_EVENT_CLEANING_SUCCESS,
  SERVICE_EVENT_CLEANING_FAILURE
} from 'constants/actionTypes';

const initialState = {
  isFetching: false,
  fetchedAt: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_CLEANING_REQUEST:
      return { ...state,
        isFetching: true
      };

    case SERVICE_EVENT_CLEANING_SUCCESS:
      return { ...state,
        ...action.payload,
        isFetching: false,
        fetchedAt: Date.now()
      };

    case SERVICE_EVENT_CLEANING_FAILURE:
      return { ...state,
        isFetching: false
      };
    default:
      return state;
  }
};
