import React, { PureComponent } from 'react';

export default (createProps = {}) => WrappedComponent => {
  const isCallable = typeof createProps === 'function';

  class DataKey extends PureComponent {

    constructor(props) {
      super(props);

      this.state = isCallable ? createProps(props) : createProps;
    }

    componentWillReceiveProps(nextProps) {
      if (isCallable) {
        this.setState(createProps(nextProps));
      }
    }

    render() {
      const mergedProps = { ...this.props, ...this.state };
      return <WrappedComponent {...mergedProps} />;
    }
  }

  return DataKey;
};
