import {
  MEMBERS_FETCH,
  MEMBERS_FETCH_SUCCESS,
  MEMBERS_FETCH_FAILURE,
  MEMBERS_CREATE,
  MEMBERS_CREATE_SUCCESS,
  MEMBERS_CREATE_FAILURE,
  MEMBERS_DELETE,
  MEMBERS_DELETE_SUCCESS,
  MEMBERS_DELETE_FAILURE,
  MEMBERS_EDIT,
  MEMBERS_EDIT_SUCCESS,
  MEMBERS_EDIT_FAILURE,
  MEMBERS_DEACTIVATE,
  MEMBERS_DEACTIVATE_SUCCESS,
  MEMBERS_DEACTIVATE_FAILURE,
  MEMBERS_ACTIVATE,
  MEMBERS_ACTIVATE_SUCCESS,
  MEMBERS_ACTIVATE_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

const formatFormData = data => {
  const formData = new FormData();

  /* eslint-disable-next-line */
    for (const name in data) {
    formData.append(name, data[name]);
  }

  return formData;
};
export const fetchMembers = () =>
  authCall({
    shouldFetch: state => !state.members.isFetching,
    types: [MEMBERS_FETCH, MEMBERS_FETCH_SUCCESS, MEMBERS_FETCH_FAILURE],
    endpoint: `/user/users`,
    method: 'GET'
  });

export const createMember = member => {
  const data = formatFormData(member);

  return authCall({
    types: [MEMBERS_CREATE, MEMBERS_CREATE_SUCCESS, MEMBERS_CREATE_FAILURE],
    endpoint: `/user/users/new`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const updateMember = member => {
  const data = formatFormData(member);

  return authCall({
    types: [MEMBERS_EDIT, MEMBERS_EDIT_SUCCESS, MEMBERS_EDIT_FAILURE],
    endpoint: `/user/users/${data && data.get('id')}`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const deleteMember = id =>
  authCall({
    types: [MEMBERS_DELETE, MEMBERS_DELETE_SUCCESS, MEMBERS_DELETE_FAILURE],
    endpoint: `/user/users/${id}`,
    method: 'DELETE'
  });

export const deactivateMember = id => {
  const data = formatFormData({
    id,
    inactive: true
  });

  return authCall({
    types: [MEMBERS_DEACTIVATE, MEMBERS_DEACTIVATE_SUCCESS, MEMBERS_DEACTIVATE_FAILURE],
    endpoint: `/user/users/${id}`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const activateMember = id => {
  const data = formatFormData({
    id,
    inactive: false
  });

  return authCall({
    types: [MEMBERS_ACTIVATE, MEMBERS_ACTIVATE_SUCCESS, MEMBERS_ACTIVATE_FAILURE],
    endpoint: `/user/users/${id}`,
    method: 'POST',
    body: data,
    multipart: true
  });
};
