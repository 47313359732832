import {
  PARTNER_SUBMIT_OFFER_REQUEST,
  PARTNER_SUBMIT_OFFER_SUCCESS,
  PARTNER_SUBMIT_OFFER_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isSubmitting: false,
    isSubmitted: false,
    response: null
  },
  action
) => {
  switch (action.type) {
    case PARTNER_SUBMIT_OFFER_REQUEST: {
      return { ...state, isSubmitting: true, isSubmitted: false, response: null };
    }

    case PARTNER_SUBMIT_OFFER_SUCCESS: {
      return { ...state, isSubmitting: false, isSubmitted: true, response: action.payload };
    }

    case PARTNER_SUBMIT_OFFER_FAILURE: {
      return { ...state, isSubmitting: false, isSubmitted: false, response: null };
    }

    default:
      return state;
  }
};
