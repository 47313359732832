import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Step1, Step2, Step3, Stepbar, Banner } from '.';

import './IntensiveCleaning.less';

class IntensiveCleaning extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        step: PropTypes.string
      }).isRequired
    }).isRequired,
    t: PropTypes.func.isRequired
  };

  getStep = (step = 1) => {
    if (step === 1) {
      return <Step1 />;
    }

    if (step === 2) {
      return <Step2 />;
    }

    if (step === 3) {
      return <Step3 />;
    }

    return <Step1 />;
  };

  render() {
    const {
      t,
      match: {
        params: { step }
      }
    } = this.props;
    return (
      <div className="pad cleaning-services intensive-cleaning">
        <Stepbar step={Number(step)} />
        <Banner title={t('service-cleaning-title')} description={t('service-cleaning-description')} />
        <div className="cleaning-services__content">
          <div>{this.getStep(Number(step))}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('Services/IntensiveCleaning')(withRouter(IntensiveCleaning));
