/* eslint-disable import/no-named-as-default */

import RestorePassword from 'components/RestorePassword';
import RecoverPassword from 'components/RecoverPassword';
import Verify from 'components/Verify';
import LoginFromUrl from './LoginFromUrl';
import LeadLoginFromUrl from './LeadLoginFromUrl';
import Public from './Public';

export default Public;
export { LoginFromUrl, LeadLoginFromUrl, RecoverPassword, RestorePassword, Verify };
