/* eslint-disable import/no-named-as-default */

import CalendarSync from './components/CalendarSync';
import FeatureHighlights from './components/FeatureHighlights';
import MissingProduct from './components/MissingProduct';
import ServiceEvent from './components/ServiceEvent';
import ServiceEventDefinitionModal from './components/ServiceEventDefinitionModal';
import ProductDetail from './components/ProductDetail';
import IntensiveCleaningTasklist, { IntensiveCleaningTasklistLink } from './components/IntensiveCleaningTasklist';
import CleaningTasklist, { CleaningTasklistLink } from './components/CleaningTasklist';
import PremiumTasklist, { PremiumTasklistLink } from './components/PremiumTasklist';
import CustomerInformation from './components/CustomerInformation';
import AddAccountData from './components/AddAccountData';
import ServiceEventRating from './components/ServiceEventRating';
import Dispatcher from './Dispatcher';
import Service from './components/Service';
import ResetPassword, { ResetPasswordLink } from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import SetPassword from './components/SetPassword';
import ConfirmOffer from './components/ConfirmOffer';
import DeclinePartner from './components/DeclinePartner';
import SuccessPartner from './components/SuccessPartner';
import ConfirmDeactivateMember from './components/ConfirmDeactivateMember';

export default Dispatcher;
export {
  CalendarSync,
  FeatureHighlights,
  MissingProduct,
  ServiceEvent,
  ServiceEventDefinitionModal,
  ProductDetail,
  IntensiveCleaningTasklist,
  IntensiveCleaningTasklistLink,
  CleaningTasklist,
  CleaningTasklistLink,
  PremiumTasklist,
  PremiumTasklistLink,
  CustomerInformation,
  AddAccountData,
  ServiceEventRating,
  Service,
  ResetPassword,
  ResetPasswordLink,
  ChangePassword,
  SetPassword,
  ConfirmOffer,
  DeclinePartner,
  SuccessPartner,
  ConfirmDeactivateMember
};
