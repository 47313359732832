import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showCart } from 'actions/location';
import { Header } from 'components/Shop/components/Cart';

import './CartHeader.less';

const CartHeader = ({ onClick }) => (
  <div onClick={onClick} role="button" tabIndex="-1">
    <Header />
  </div>
);

CartHeader.propTypes = {
  onClick: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onClick: () => showCart() // Because of (e) passed from onClick
};

export default connect(
  null,
  mapDispatchToProps
)(CartHeader);
