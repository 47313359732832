import {
  SHOP_CART_PAYMENT_METHOD_SELECT,
  SHOP_CART_ORDER_SUCCESS,
  SHOP_CART_ORDER_FAILURE
} from 'constants/actionTypes';
import { PAYMENT_METHOD_BILLING } from 'constants/paymentMethods';
import { ORDER_PAYMENT_BILLING_NOT_ALLOWED } from 'constants/errors';
import { hasError } from 'utils';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_PAYMENT_METHOD_SELECT:
      return action.method;

    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    case SHOP_CART_ORDER_FAILURE:
      if (state === PAYMENT_METHOD_BILLING && hasError(action.payload, ORDER_PAYMENT_BILLING_NOT_ALLOWED)) {
        return initialState;
      }

      return state;

    default:
      return state;
  }
};
