import {
  PASSWORD_RESTORE_REQUEST,
  PASSWORD_RESTORE_SUCCESS,
  PASSWORD_RESTORE_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isRestoring: false,
    isRestored: false
  },
  action
) => {
  switch (action.type) {
    case PASSWORD_RESTORE_REQUEST:
      return { ...state, isRestoring: true, isRestored: false };

    case PASSWORD_RESTORE_SUCCESS:
      return { ...state, isRestoring: false, isRestored: true };

    case PASSWORD_RESTORE_FAILURE:
      return { ...state, isRestoring: false };

    default:
      return state;
  }
};
