import { CHANGE_LOGO } from 'constants/actionTypes';

export default logo => (dispatch, getState) => {
  const state = getState();

  if (logo !== state.logo) {
    dispatch({
      type: CHANGE_LOGO,
      logo
    });
  }
};
