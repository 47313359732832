import { CUSTOMER_CHANGE_REQUEST, CUSTOMER_CHANGE_SUCCESS, CUSTOMER_CHANGE_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';

export default customerId => (dispatch, getState) => {
  const {
    current: { customer }
  } = getState();
    // TODO: should be in shouldFetch
  if (customerId === (customer && customer.id)) return Promise.resolve();

  return dispatch(
    authCall({
      shouldFetch: state => !state.customer.change.isChanging,
      types: [CUSTOMER_CHANGE_REQUEST, CUSTOMER_CHANGE_SUCCESS, CUSTOMER_CHANGE_FAILURE],
      endpoint: `/user/customer/${customerId}/change`,
      method: 'POST'
    })
  );
};
