import { combineReducers } from 'redux';
import emailNotifications from './emailNotifications';
import single from './single';
import password from './password';

export default combineReducers({
  emailNotifications,
  single,
  password
});
