import {
  SERVICE_EVENT_RATE_REQUEST,
  SERVICE_EVENT_RATE_SUCCESS,
  SERVICE_EVENT_RATE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (serviceEventId, value, message) =>
  authCall({
    shouldFetch: state =>
      !(state.serviceEvents.rate[serviceEventId] && state.serviceEvents.rate[serviceEventId].isRating),
    meta: { serviceEventId },
    types: [SERVICE_EVENT_RATE_REQUEST, SERVICE_EVENT_RATE_SUCCESS, SERVICE_EVENT_RATE_FAILURE],
    endpoint: `/user/service-events/${serviceEventId}/rating`,
    method: 'PUT',
    body: { value, message }
  });
