import {
  PASSWORD_RESTORE_REQUEST,
  PASSWORD_RESTORE_SUCCESS,
  PASSWORD_RESTORE_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';

export default (token, password) =>
  apiCall({
    shouldFetch: state => !state.password.restore.isRestoring,
    types: [PASSWORD_RESTORE_REQUEST, PASSWORD_RESTORE_SUCCESS, PASSWORD_RESTORE_FAILURE],
    endpoint: `/user/auth/password/${token}`,
    method: 'PUT',
    body: { password }
  });
