import {
  SHOP_CART_DELETE_REQUEST,
  SHOP_CART_DELETE_SUCCESS,
  SHOP_CART_DELETE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: state => !state.shop.cart.isDeleting,
    types: [SHOP_CART_DELETE_REQUEST, SHOP_CART_DELETE_SUCCESS, SHOP_CART_DELETE_FAILURE],
    endpoint: '/user/shop/cart',
    method: 'DELETE'
  });
