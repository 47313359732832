import {
  SERVICE_EVENT_CLEANING_CALC_REQUEST,
  SERVICE_EVENT_CLEANING_CALC_SUCCESS,
  SERVICE_EVENT_CLEANING_CALC_FAILURE
} from 'constants/actionTypes';

const initialState = {
  isCreating: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_CLEANING_CALC_REQUEST:
      return { ...state,
        isCreating: true
      };

    case SERVICE_EVENT_CLEANING_CALC_SUCCESS:
      return { ...state,
        ...action.payload,
        isCreating: false
      };

    case SERVICE_EVENT_CLEANING_CALC_FAILURE:
      return { ...state,
        isCreating: false
      };
    default:
      return state;
  }
};
