import { SEARCH_SET } from 'constants/actionTypes';

export default (
  state = {
    isTyping: false,
    q: ''
  },
  action
) => {
  switch (action.type) {
    case SEARCH_SET:
      return { ...state, q: action.q, isTyping: !!action.isTyping };

    default:
      return state;
  }
};
