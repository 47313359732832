import React from 'react';
import { withTranslation } from 'react-i18next';

const ProgressDetails = ({ t, item }) => {
  const requiredDetails = {
    elevator: item.elevator,
    floor: item.floor,
    floorSpace: item.floor_space,
    numberOfEmployees: item.number_of_employees
  };
  const optionalDetails = [
    item.building_type,
    item.business_hours,
    item.contact_name,
    item.contact_phone,
    item.entrance_lock,
    item.floor_type,
    item.has_ac,
    item.heater_type,
    item.number_of_bathrooms,
    item.number_of_kitchens,
    item.number_of_windows
  ];
  let { percentageProgress } = item;
  let missingCount = 0;

  const optionalDetailsMissing = () => {
    missingCount = optionalDetails.filter(detail => !detail).length;

    if (missingCount > 0 && missingCount !== 1) {
      return `${missingCount} ${t('info-missing')}`;
    }
    if (missingCount === 1) {
      return `${missingCount} ${t('one-info-missing')}`;
    }
    return t('info-completed');
  };

  if (!percentageProgress) {
    percentageProgress = 0;
  }

  return (
    <div className="header-module__progress-tooltip-container" style={{ marginLeft: `${percentageProgress}%` }}>
      <div className="header-module__progress-tooltip">
        <div className="header-module__progress-tooltip-headline">{t('required')}</div>
        {Object.keys(requiredDetails).map(detail => {
          const hasCompletedClass = requiredDetails[detail] ? 'completed' : '';
          return (
            <div key={detail}>
              <div className="header-module__progress-tooltip-required">
                <span className={`header-module__progress-tooltip-arrow ${hasCompletedClass}`}>
                  {requiredDetails[detail] ? '' : '-'}
                </span>
                <span className="header-module__progress-tooltip-required-detail">
                  {t(detail.replace(/([A-Z])/g, '-$1').toLowerCase())}
                </span>
              </div>
            </div>
          );
        })}
        <div className="header-module__progress-tooltip-headline optional">{t('optional')}</div>
        <div className={missingCount === 0 ? 'optionalCompleted' : ''}>{optionalDetailsMissing()}</div>
      </div>
    </div>
  );
};

export default withTranslation('Dashboard/Header')(ProgressDetails);
