import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Loader, Pagination } from 'common';
import { fetchOrders } from 'actions/orders';
import { Order, NoResult } from '../..';

import './Orders.less';

export class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      startDate: undefined,
      endDate: undefined,
      focusedInput: null,
      limit: this.props.showPerPage
    };
  }

  componentWillMount() {
    this.props.fetchOrders(this.state.startDate, this.state.endDate, this.state.limit, this.state.currentPage, true);
  }

  handlePageChange = page => {
    const { startDate, endDate } = this.state;

    this.setState(() => ({
      currentPage: page
    }));

    const formatedStartDate = startDate ? moment(this.state.startDate, 'YYYY-MM-DD').toISOString() : undefined;
    const formatedEndDate = endDate ? moment(this.state.endDate, 'YYYY-MM-DD').toISOString() : undefined;

    return this.props.fetchOrders(formatedStartDate, formatedEndDate, this.state.limit, page, true);
  };

  handleDateChange = (startDate, endDate) => {
    this.setState(() => ({ startDate, endDate }));

    const formatedStartDate = startDate ? moment(startDate, 'YYYY-MM-DD').toISOString() : undefined;
    const formatedEndDate = endDate ? moment(endDate, 'YYYY-MM-DD').toISOString() : undefined;

    return this.props.fetchOrders(formatedStartDate, formatedEndDate, this.state.limit, this.state.currentPage, true);
  };

  renderOrders = () => {
    const { items, t } = this.props;

    return items.map(item => <Order t={t} key={item.id} item={item} />);
  };

  renderNoResult = t => <NoResult t={t} />;

  renderOrdersPage = () => {
    const { items, t } = this.props;

    if (items.length) {
      return this.renderOrders();
    }

    return this.renderNoResult(t);
  };

  render() {
    const { isFetching, numberOfItems, showPerPage, t } = this.props;

    return (
      <div className="orders-list">
        <DateRangePicker
          small
          startDatePlaceholderText={t('from')}
          endDatePlaceholderText={t('to')}
          customArrowIcon="-"
          startDateId="ordersStartDate"
          startDate={this.state.startDate}
          enableOutsideDays={false}
          isOutsideRange={() => false}
          showClearDates
          endDateId="ordersEndDate"
          endDate={this.state.endDate}
          onDatesChange={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          displayFormat="DD/MM/YYYY"
        />
        <Loader isLoading={isFetching} />
        {this.renderOrdersPage()}
        {numberOfItems > showPerPage ? (
          <div className="orders-list-pagination">
            <Pagination
              handlePageChange={this.handlePageChange}
              numberOfItems={numberOfItems}
              showItemsPerPage={showPerPage}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Orders.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func.isRequired,
  numberOfItems: PropTypes.number.isRequired,
  showPerPage: PropTypes.number.isRequired,
  fetchOrders: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isFetching: state.orders.isFetching,
  items: state.orders.items,
  numberOfItems: state.orders.numberOfItems,
  showPerPage: state.orders.showPerPage
});

const mapDispatchToProps = {
  fetchOrders
};

export default withTranslation('Settings/Orders')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Orders)
);
