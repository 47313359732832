import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkout } from 'actions/shop/cart';
import { translateEditing } from '../HOC';

const CheckoutButton = ({ isLoading, isDisabled, onClick, t }) => (
  <button
    type="button"
    className={`button cart-submit ${isLoading ? 'loading-light' : ''} ${isDisabled ? 'disabled' : ''}`}
    onClick={onClick}
  >
    {t('checkout')}
  </button>
);

CheckoutButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: {
      isCheckingOut,
      isDeleting,
      cart: { checkout: canCheckout }
    }
  }
}) => ({
  isLoading: isCheckingOut,
  isDisabled: !canCheckout || isDeleting
});

const mapDispatchToProps = {
  onClick: checkout
};

export { CheckoutButton as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutButton)
);
