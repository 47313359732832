import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { currency } from 'components/HOC';
import { ProductImage } from 'components/Shop/components/Product/Common';

import './Product.less';

const Product = ({ item, showNewPrice, showOutOfStock, t, c }) => {
  const className = classNames('modal-product', {
    'product-out': item.is_out_of_stock,
    'product-new-price': !!item.new_unit_price
  });

  return (
    <div className={className}>
      <ProductImage item={item} />

      <div className="modal-product-data">
        <div className="modal-product-name ellipsis">{item.name}</div>
        <div className="modal-product-desc ellipsis">{item.description}</div>
      </div>

      <div className="modal-product-priceblock">
        {showNewPrice && item.new_unit_price && (
          <div className="modal-product-current-price">
            <span>{t('current-price')}</span> {c(item.new_unit_price)}
          </div>
        )}

        {showOutOfStock && item.is_out_of_stock && <div className="modal-product-out">{t('out-of-stock')}</div>}

        <div>
          <div className="modal-product-price">{c(item.total_price)}</div>
          <div className="modal-product-unitprice">
            {item.quantity}
            &nbsp;&times; {c(item.unit_price)}
          </div>

          {item.discount_amount && item.discount_amount > 0 ? ` - ${c(item.discount_amount)} ${t('discount')}` : ''}
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  item: PropTypes.object.isRequired,
  showNewPrice: PropTypes.bool,
  showOutOfStock: PropTypes.bool,
  c: PropTypes.func.isRequired
};

export default currency(withTranslation('Modal/ServiceEvent')(Product));
