import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import PieChart from 'react-minimal-pie-chart';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchStats } from 'actions/issues';

import './IssueStats.less';

class Stats extends Component {
  constructor(props) {
    super(props);
    this.setState = {};
  }

  componentDidMount() {
    this.props.fetchStats();
  }

  showChartTitle = (event, data, dataIndex) => {
    const { title, value } = data[dataIndex];
    const rect = event.target.getBoundingClientRect();
    const { offsetLeft, offsetTop } = this.statsSidebar;

    this.chartTooltip.innerHTML = `${title}: ${value}`;
    this.chartTooltip.style.display = 'block';
    this.chartTooltip.style.left = `${rect.left - offsetLeft - 10}px`;
    this.chartTooltip.style.top = `${rect.top - offsetTop - 10}px`;
  };

  hideChartTitle = () => {
    this.chartTooltip.style.display = 'none';
  };

  filterIssues = (e, data, key) => {
    this.props.filterIssues({ priority: [data[key].val] });
  };

  formatChartData = (stats, t) => {
    const { prio1, prio2, prio3, prio4, prio5, prio6, prio7, prio8, prio9, prio10 } = stats;

    return [
      {
        title: t('low'),
        value: prio1 + prio2 + prio3,
        color: '#74b8ea',
        val: 'low'
      },
      {
        title: t('medium'),
        value: prio4 + prio5 + prio6,
        color: '#2c94e2',
        val: 'medium'
      },
      {
        title: t('high'),
        value: prio7 + prio8 + prio9,
        color: '#2273af',
        val: 'high'
      },
      {
        title: t('urgent'),
        value: prio10,
        color: '#314d62',
        val: 'urgent'
      }
    ];
  };

  render() {
    const { t, stats, isOpen } = this.props;

    if (!stats || Object.keys(stats).length === 0) return null;

    const chartData = this.formatChartData(stats, t);

    return (
      <div
        className={classNames('issues-stats', { open: isOpen })}
        ref={ref => {
          this.statsSidebar = ref;
        }}
      >
        <div className="stat-section">
          <h2 className="section-title">{t('open-issues')}</h2>

          <div className="section-left priorities">
            <div className="prio p-low">{t('low')}</div>
            <div className="prio p-medium">{t('medium')}</div>
            <div className="prio p-high">{t('high')}</div>
            <div className="prio p-urgent">{t('urgent')}</div>
          </div>

          <div className="section-right">
            <div className="stat-diagram">
              <div
                ref={ref => {
                  this.chartTooltip = ref;
                }}
                className="stat-diagram-tooltip"
                style={{ display: 'none' }}
              />
              <PieChart
                animate
                data={chartData}
                onClick={this.filterIssues}
                onMouseOver={this.showChartTitle}
                onMouseOut={this.hideChartTitle}
              />
            </div>
          </div>
        </div>

        <div className="stat-section stat-section-trend">
          <h2 className="section-title">{t('todays-trand')}</h2>

          <div className="section-left">
            <div className="trend-number">{stats.closed_today}</div>
            <div className="trend-description">
              <div className="title">{t('resolved-issues')}</div>
              <div className="details high">
                <span>{stats.closed_yesterday}</span> {t('this-time-yesterday')}
              </div>
            </div>
          </div>

          <div className="section-right">
            <div className="trend-number">{stats.created_today}</div>
            <div className="trend-description">
              <div className="title">{t('created-issues')}</div>
              <div className="details low">
                <span>{stats.created_yesterday}</span> {t('this-time-yesterday')}
              </div>
            </div>
          </div>
        </div>
        <div className="stat-resp-time">
          {t('response-time')} <span>{Math.floor(stats.average_time / 60)} min</span>
        </div>
      </div>
    );
  }
}

Stats.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  stats: state.issues.stats
});

const mapDispatchToProps = dispatch => ({
  fetchStats: () => dispatch(fetchStats())
});

export { Stats as PureComponent };
export default pure(
  withTranslation('Issues')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Stats)
  )
);
