import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IMAGE_SHOP_PARTNER } from 'constants/images';
import { Transition } from 'common';
import { Box } from '.';

export class CompanySwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxVisible: false
    };

    this.closeBox = this.closeBox.bind(this);
    this.toggleBox = this.toggleBox.bind(this);
  }

  toggleBox() {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  }

  closeBox() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { isLoading, isVisible, item } = this.props;
    const { isBoxVisible } = this.state;
    const imageUrl = (item && item.image_url) || IMAGE_SHOP_PARTNER;

    if (!isVisible) return null;

    return (
      <div className="account-wrapper ignore-react-onclickoutside">
        <button type="button" className={`account-toggler ${isBoxVisible ? 'active' : ''}`} onClick={this.toggleBox}>
          <div className="account-logo-wrapper">
            <img className="account-logo" src={imageUrl} alt="" />
            <div className={`account-logo-suffix ${isLoading ? 'loading' : ''}`} />
          </div>
        </button>

        <Transition name="box-switcher">{isBoxVisible && <Box closeBox={this.closeBox} />}</Transition>
      </div>
    );
  }
}

CompanySwitcher.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  item: PropTypes.object
};

const mapStateToProps = ({
  current: { user, customer },
  users: {
    single: { isFetching }
  },
  customer: {
    change: { isChanging }
  }
}) => ({
  isLoading: isFetching || isChanging,
  item: customer,
  isVisible: !!(user && user.available_customers && user.available_customers.length > 1)
});

export default connect(mapStateToProps)(CompanySwitcher);
