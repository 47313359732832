import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';

import './RadioButton.less';

const RadioButton = props => {
  const { className, id, label } = props;
  const passedProps = omit(props, 'className', 'id', 'label');
  return (
    <label htmlFor={id} className={`form-radio ${className || ''}`}>
      <div className="form-radio__button">
        <input {...passedProps} id={id} type="radio" className="form-radio__input" />
        <div className="form-radio__con" />
      </div>
      <p className="form-radio__text">{label}</p>
    </label>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default htmlId(RadioButton);
