import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DATA_KEY_SHOP_PRODUCTS_FAVORITES } from 'constants/dataKeys';
import { fetchFavoriteProducts } from 'actions/shop/products';
import { getFavoriteProducts } from 'selectors/shop';
import Overview from '../Overview/Overview';

const createTitle = ({ t }) => `${t('headline')} - ${t('Shop:headline')}`;

const createBreadcrumbItems = ({ numberOfItems, t }) => [
  { url: '/shop', label: t('Shop:headline') },
  { label: t('headline'), numberOfItems }
];

class FavoriteOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: createTitle(props),
      breadcrumbItems: createBreadcrumbItems(props)
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.t !== nextProps.t || this.props.numberOfItems !== nextProps.numberOfItems) {
      this.setState({
        title: createTitle(nextProps),
        breadcrumbItems: createBreadcrumbItems(nextProps)
      });
    }
  }

  fetchData(nextPage) {
    const { dispatch, currentPage, limit } = this.props;

    dispatch(
      fetchFavoriteProducts({
        page: nextPage || currentPage,
        limit
      })
    );
  }

  fetchNext() {
    this.fetchData(this.props.currentPage + 1);
  }

  render() {
    const { fetchData, fetchNext } = this;
    const { title, breadcrumbItems } = this.state;
    const { isFetching, items, hasMore } = this.props;
    const passedProps = { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext };

    return <Overview {...passedProps} trackingListId="favorite_list" />;
  }
}

FavoriteOverview.propTypes = {
  limit: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  numberOfItems: PropTypes.number,
  t: PropTypes.func.isRequired
};

FavoriteOverview.defaultProps = {
  limit: 25
};

const mapStateToProps = (state, props) => {
  const {
    shop: {
      products: {
        list: { [DATA_KEY_SHOP_PRODUCTS_FAVORITES]: { isFetching, currentPage = 1, hasMore, numberOfItems } = {} }
      }
    }
  } = state;

  return {
    isFetching,
    items: getFavoriteProducts(state, props),
    currentPage,
    hasMore,
    numberOfItems
  };
};

export default withTranslation('Shop/FavoriteOverview')(connect(mapStateToProps)(FavoriteOverview));
