import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { LOCATION_HASH_SED_TASKS, LOCATION_HASH_SED_EVENTS, LOCATION_HASH_SED_PRODUCTS } from 'constants/location';
import { Transition } from 'common';
import { TaskGroups } from '../../ServiceEvent/Shared';
import ServiceEvent from '../../ServiceEvent';
import { Events, Products } from '.';

const TAB_EVENTS = 'TAB_EVENTS';
const TAB_PRODUCTS = 'TAB_PRODUCTS';
const TAB_TASKS = 'TAB_TASKS';

const getActiveTab = ({ item, hash }) => {
  if (item.isTypeCleaning) {
    if (hash === LOCATION_HASH_SED_TASKS) {
      return TAB_TASKS;
    }

    return TAB_EVENTS;
  }

  if (hash === LOCATION_HASH_SED_EVENTS) {
    return TAB_EVENTS;
  }

  return TAB_PRODUCTS;
};

export class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: getActiveTab(props)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.hash !== nextProps.hash) {
      this.setState({
        activeTab: getActiveTab(nextProps)
      });
    }
  }

  renderCurrentTab() {
    const { item } = this.props;
    const { activeTab } = this.state;

    switch (activeTab) {
      case TAB_TASKS:
        return <TaskGroups items={item.task_groups || []} />;

      case TAB_PRODUCTS:
        return <Products item={item} />;

      default:
        return <Events id={item.id} />;
    }
  }

  renderServiceEventTab() {
    const { hash } = this.props;

    if (hash && hash.length === 19) {
      return <ServiceEvent id={hash.substr(1)} taskGroups={this.props.item.task_groups} />;
    }

    return null;
  }

  render() {
    const { item, pathname, search, t } = this.props;
    const { activeTab } = this.state;

    const isEventsTab = activeTab === TAB_EVENTS;
    const isTasksTab = activeTab === TAB_TASKS;
    const isProductsTab = activeTab === TAB_PRODUCTS;

    return (
      <div className="modal-tabs-flex">
        <div className="modal-tabs">
          <Link
            className={`modal-singletab modal-eventstab ${isEventsTab ? 'active' : ''}`}
            to={`${pathname}${search}${LOCATION_HASH_SED_EVENTS}`}
          >
            {t('tab-events')}
          </Link>

          {item.isTypeCleaning && (
            <Link
              className={`modal-singletab modal-tasktab ${isTasksTab ? 'active' : ''}`}
              to={`${pathname}${search}${LOCATION_HASH_SED_TASKS}`}
            >
              {t('tab-tasks')}
            </Link>
          )}

          {!item.isTypeCleaning && (
            <Link
              className={`modal-singletab modal-productstab ${isProductsTab ? 'active' : ''}`}
              to={`${pathname}${search}${LOCATION_HASH_SED_PRODUCTS}`}
            >
              {t('tab-products')}
            </Link>
          )}
        </div>

        <div className="modal-content">{this.renderCurrentTab()}</div>

        <Transition name="se-animation" appearTimeout={700} enterTimeout={700} leaveTimeout={500}>
          {this.renderServiceEventTab()}
        </Transition>
      </div>
    );
  }
}

Tabs.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  hash: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ location: { pathname, search, hash } }) => ({ pathname, search, hash });

export default withTranslation('Modal/ServiceEventDefinition')(connect(mapStateToProps)(Tabs));
