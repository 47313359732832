import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { Transition } from 'common';
import { fetchOfficeProfileProgress as fetchOfficeProfileProgressAction } from 'actions/customer';
import ProgressDetails from '../ProgressDetails';

export const Progress = ({ t, item, fetchOfficeProfileProgress }) => {
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  useEffect(() => {
    fetchOfficeProfileProgress();
  }, [fetchOfficeProfileProgress]);

  const toggleBox = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  Progress.handleClickOutside = () => setIsBoxVisible(false);

  let { percentageProgress } = item;

  if (!percentageProgress) {
    percentageProgress = 0;
  }

  return (
    <div className="header-module__column header-module__progress">
      <p className="header-module__headline">{t('profile-progress')}</p>
      <div>{t('office-data')}</div>
      <div className="header-module__percent">{percentageProgress}%</div>
      <div className="header-module__bar" onClick={toggleBox} role="presentation">
        <div
          style={{ width: `${percentageProgress}%` }}
          className={`header-module__bar-inner ${percentageProgress === 100 ? 'profile-completed' : ''}`}
        />
      </div>
      <Transition>{isBoxVisible && <ProgressDetails item={item} />}</Transition>
      <Link to="/settings" className="header-module__link flex-bottom">
        {t('edit-profile')}
      </Link>
    </div>
  );
};
Progress.prototype = {};

Progress.propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.object,
  fetchOfficeProfileProgress: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  item: state.customer.officeProfileProgress.item
});

const mapDispatchToProps = {
  fetchOfficeProfileProgress: () => fetchOfficeProfileProgressAction()
};

const clickOutsideConfig = {
  handleClickOutside: () => Progress.handleClickOutside
};

export default withTranslation('Dashboard/Header')(
  connect(mapStateToProps, mapDispatchToProps)(onClickOutside(Progress, clickOutsideConfig))
);
