import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchUser } from 'actions/users';
import { LOCATION_HASH_PASSWORD_CHANGE } from 'constants/location';

import './LoginAndSecurity.less';

export class LoginAndSecurity extends Component {
  componentWillMount() {
    this.props.fetchUser();
  }

  handleEditPasswordClick = () => {
    const path = this.props.location.pathname;

    return this.props.history.push(`${path}${LOCATION_HASH_PASSWORD_CHANGE}`);
  };

  render() {
    const { t, user } = this.props;

    if (!user) {
      return null;
    }
    return (
      <div className="security">
        <div className="security__container">
          <div className="security__headline">
            <p>{t('current-user-headline')}:</p>
          </div>
          <div className="security__content">
            <p className="security__fullname">{user.fullname}</p>
            <p className="security__email">{user.email}</p>
          </div>
        </div>
        <div className="security__container">
          <div className="security__headline">
            <p>{t('password-headline')}:</p>
          </div>
          <div className="content">
            <p className="password-placeholder">
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <span className="password-placeholder-dot" />
              <button type="button" onClick={this.handleEditPasswordClick} className="security__edit-password">
                <span className="security__edit-password-bg" />
                <span>{t('edit')}</span>
              </button>
            </p>
          </div>
        </div>
        <div className="security__container flex flex-justify">
          <div className="security__headline">
            <p>{t('all-users-headline')}:</p>
          </div>
          <div className="security__content ">
            <p className="security__users-count">
              <Link to="/members" className="security__show-users">
                <span className="security__show-users-bg" />
                <span>{t('view-all-users')}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ current: { user }, location }) => ({
  user,
  location
});

const mapDispatchToProps = {
  fetchUser
};

LoginAndSecurity.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  fetchUser: PropTypes.func.isRequired
};

export default withTranslation('Settings/LoginAndSecurity')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginAndSecurity)
);
