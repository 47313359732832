import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  LOCATION_HASH_SET_ACCOUNT_DATA,
  LOCATION_HASH_FEATURE_HIGHLIGHTS
} from "constants/location";
import { CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT } from "constants/contentful";

const ProgressLead = ({ t, hasFeatures }) => (
  <div className="header-module__column header-module__progress">
    <p className="header-module__headline">{t("first-steps")}</p>
    {hasFeatures && (
      <div>
        <div>{t("onboarding-text")}</div>
        <Link
          to={`${LOCATION_HASH_FEATURE_HIGHLIGHTS}`}
          className="header-module__link--lead"
        >
          {t("onboarding")}
        </Link>
      </div>
    )}
    {/* {!hasFeatures && (
      <div>
        <span>{t('services-text')}</span>
        <Link to="/services" className="header-module__link--lead">
          {t('services-button')}
        </Link>
      </div>
    )} */}
    <div className="flex-bottom">
      <div>{t("office-data-lead")}</div>
      <Link
        to={`${LOCATION_HASH_SET_ACCOUNT_DATA}`}
        className="header-module__link--lead nopad"
      >
        {t("add-profile")}
      </Link>
    </div>
  </div>
);

ProgressLead.propTypes = {
  hasFeatures: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = contentful => ({
  hasFeatures: !!(
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT] &&
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items &&
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items.length > 0
  )
});

export default withTranslation("Dashboard/Header")(
  connect(mapStateToProps)(ProgressLead)
);
