import { pushLocation, replaceLocation } from "../location";
import { setSearch } from ".";

const TIMER_TIMEOUT = process.env.NODE_ENV === 'test' ? 1 : 4000;

let timer;

const search = (q = '', isTyping = false) => (dispatch, getState) => {
  clearTimeout(timer);
  dispatch(setSearch(q, isTyping));

  const { location } = getState();

  if (!q) {
    if (location.pathname !== '/shop/all') {
      dispatch(
        pushLocation({
          pathname: '/shop/all'
        })
      );
    }

    return Promise.resolve();
  }

  if (isTyping) {
    if (location.pathname !== '/shop/search') {
      dispatch(
        replaceLocation({
          pathname: '/shop/search'
        })
      );
    }

    // After typing set search again for tracking
    return new Promise(resolve => {
      timer = setTimeout(() => {
        dispatch(search(q));
        resolve();
      }, TIMER_TIMEOUT);
    });
  }

  if (location.pathname !== '/shop/search' || location.query.q !== q) {
    dispatch(
      pushLocation({
        pathname: '/shop/search',
        search: `?q=${q}`
      })
    );
  }

  return Promise.resolve();
};

export default search;
