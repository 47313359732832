import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import { SingleDatePicker } from 'react-dates';

const dateObj = [
  { time: 'time1', date: 'date1', focused: 'focused1' },
  { time: 'time2', date: 'date2', focused: 'focused2' },
  { time: 'time3', date: 'date3', focused: 'focused3' }
];

class VisitDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visit_dates: []
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {/* eslint-disable-next-line */}
        <label className="cleaning-services__label" htmlFor="date1">
          Please choose a starting date for your cleaning session:
        </label>
        <div className="cleaning-services__date-group">
          {dateObj.map(date => (
            <div className="cleaning-services__single-date" key={date.date}>
              <div className="cleaning-services__times">
                <SingleDatePicker
                  placeholder={t('date-placeholder')}
                  id={date.date}
                  date={this.state[date.date]}
                  onDateChange={newDate => this.setState({ [date.date]: newDate })}
                  showClearDate
                  numberOfMonths={1}
                  focused={this.state[date.focused]}
                  onFocusChange={({ focused }) =>
                    this.setState({
                      [date.focused]: focused
                    })
                  }
                  hideKeyboardShortcutsPanel
                  openDirection="up"
                />
                <TimePicker
                  id={date.time}
                  showSecond={false}
                  placeholder={t('time-placeholder')}
                  className="cleaning-services__timepick"
                  onChange={e => this.onTimeChange(e, date.time)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

VisitDate.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: windowCleaning }) => ({
  windowCleaning
});

export { VisitDate as PureComponent };
export default withTranslation('Services/WindowCleaning')(connect(mapStateToProps)(VisitDate));
