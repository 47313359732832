import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { showCustomCleaningForms } from 'utils/brandFlags';
import {
  resetAdditional,
  resetWeekly,
  resetWindow,
  resetWindowOversized,
  resetGegenbauer,
  resetRecurrentCleaningObject
} from 'actions/serviceEvents';

class CleaningSuccess extends Component {
  componentDidMount() {
    const {
      brand,
      history,
      submitCleaning,
      additionalCleaning,
      windowCleaning,
      windowCleaningOversized,
      gegenbauerCleaning
    } = this.props;

    if (
      showCustomCleaningForms(brand) &&
      !submitCleaning.isSuccessful &&
      !additionalCleaning.isSuccessful &&
      !windowCleaningOversized.isSuccessful &&
      !windowCleaning.isSuccessful &&
      !gegenbauerCleaning.isSuccessful
    ) {
      history.push('/cleaning/gegenbauer-cleaning');
    }
  }

  componentWillUnmount() {
    this.props.resetAdditional();
    this.props.resetWeekly();
    this.props.resetRecurrentCleaningObject();
    this.props.resetWindow();
    this.props.resetWindowOversized();
    this.props.resetGegenbauer();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="pad cleaning-services">
        <div className="cleaning-services__box">
          <div className="cleaning-services__success">
            <h1 className="cleaning-services__success-headline">{t('success-headline')}</h1>
            <p className="cleaning-services__success-description">{t('success-description')}</p>
            <p className="cleaning-services__success-brand">{t('success-brand')}</p>
          </div>
        </div>
      </div>
    );
  }
}

CleaningSuccess.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  brand,
  serviceEvents: { submitCleaning, additionalCleaning, windowCleaning, windowCleaningOversized, gegenbauerCleaning }
}) => ({
  brand,
  submitCleaning,
  additionalCleaning,
  windowCleaning,
  windowCleaningOversized,
  gegenbauerCleaning
});

export default withTranslation('Services/Cleaning')(
  withRouter(
    connect(
      mapStateToProps,
      {
        resetAdditional,
        resetWeekly,
        resetWindow,
        resetWindowOversized,
        resetGegenbauer,
        resetRecurrentCleaningObject
      }
    )(CleaningSuccess)
  )
);
