import { createSelector } from 'reselect';

const getItems = state => {
  const dataKey = `srch_${state.search.q}`;
  return state.shop.products.list[dataKey]
        && state.shop.products.list[dataKey].pages
        && state.shop.products.list[dataKey].pages[1]
        && state.shop.products.list[dataKey].pages[1].items;
};
const getProducts = state => state.shop.products.entities;

export default createSelector(
  [getItems, getProducts], (items, products) => {
    if (!items) return [];

    return items.slice(0, 3)
      .map(productsId => products[productsId])
      .filter(product => !!product)
      .map(item => ({ key: item.id, label: item.name }));
  },
);
