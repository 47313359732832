import { FEATURES_SEEN_REQUEST, FEATURES_SEEN_SUCCESS, FEATURES_SEEN_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';

export default featureIds =>
  authCall({
    shouldFetch: state => !state.features.seen.isCreating,
    types: [FEATURES_SEEN_REQUEST, FEATURES_SEEN_SUCCESS, FEATURES_SEEN_FAILURE],
    endpoint: '/user/features/seen',
    method: 'POST',
    body: {
      seen_feature_ids: featureIds
    }
  });
