import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { currency } from 'components/HOC';

const ExecutorBox = ({ t, offer, offerToken }) => (
  <div className="offer-box executor-box">
    <p className="offer-headline partner__tasklist__head">{t('executor-headline')}</p>
    {offer[offerToken].item.performances.map(item => (
      <div className="partner__executor" key={`${offerToken}-${item.public_token}`}>
        <p>
          {item.cleaning_staff_information} -{` ${moment(item.start_date).format('DD.MM.YYYY')}`}
          {moment(item.end_date).format('DD.MM.YYYY') !== moment(item.start_date).format('DD.MM.YYYY')
            ? t('until') + moment(item.end_date).format('DD.MM.YYYY')
            : ''}
        </p>
      </div>
    ))}
  </div>
);

ExecutorBox.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired
};

export { ExecutorBox as PureComponent };
export default currency(withTranslation('PartnerConfirmation')(ExecutorBox));
