import { VERIFY_REQUEST, VERIFY_SUCCESS, VERIFY_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    isSuccess: false,
    isFailing: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case VERIFY_REQUEST:
      return { ...state, isFetching: true, isSuccess: false, isFailing: false };

    case VERIFY_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true, isFailing: false, data: action.payload };

    case VERIFY_FAILURE: {
      return { ...state, isFetching: false, isSuccess: false, isFailing: true };
    }

    default:
      return state;
  }
};
