import { SERVICE_REQUEST_STATUS, SERVICE_REQUEST_TYPES } from 'constants/serviceRequests';

export const getStatuses = t =>
  SERVICE_REQUEST_STATUS.map(el => ({
    label: t(el),
    value: el
  }));

export const getCategories = t =>
  SERVICE_REQUEST_TYPES.map(el => ({
    label: t(el),
    value: el
  }));

export const formatList = (arr, label) => arr.map(el => ({ label: el[label], value: el.id }));
