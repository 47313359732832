import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Transition } from 'common';
import { NoteBox } from 'common/ServiceEvent';

class CommentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxVisible: false
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isNoted && nextProps.isNoted) {
      this.closeBox();
    }
  }

  toggleBox(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  }

  closeBox() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { item, t } = this.props;
    const { isBoxVisible } = this.state;

    return (
      <div className="event-comment">
        <button type="button" className="event-comment__button" onClick={e => this.toggleBox(e)}>
          {t('comment')}
        </button>

        <Transition>{isBoxVisible && <NoteBox item={item} closeBox={this.closeBox} />}</Transition>
      </div>
    );
  }
}

CommentButton.propTypes = {
  item: PropTypes.object.isRequired,
  isNoted: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { note } }, { item: { id } }) => ({
  isNoting: !!(note[id] && note[id].isNoting),
  isNoted: !!(note[id] && note[id].isNoted)
});

export default withTranslation('Dashboard/Schedule')(connect(mapStateToProps)(CommentButton));
