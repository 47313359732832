import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addCoupon } from 'actions/shop/cart';

const initialState = {
  coupon: ''
};

class CouponForm extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isSubmitted && nextProps.isSubmitted) {
      this.setState(initialState);
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state.coupon.trim());
  }

  render() {
    if (!this.props.isVisible) return null;

    const { isDisabled, isSubmitting, t } = this.props;
    const { coupon } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <fieldset disabled={isDisabled}>
          <label htmlFor="coupon-form-input" className="voucher-label">
            {t('coupon')}:
          </label>

          <div className="voucher-wrapper clearfix">
            <input
              type="text"
              name="coupon"
              id="coupon-form-input"
              value={coupon}
              className="voucher-input"
              onChange={this.handleChange}
              placeholder={t('coupon-placeholder')}
              required
            />

            <button type="submit" className={`voucher-button ${isSubmitting ? 'loading-light' : ''}`}>
              {t('redeem')}
            </button>
          </div>
        </fieldset>
      </form>
    );
  }
}

CouponForm.propTypes = {
  isVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

CouponForm.defaultProps = {
  isVisible: true,
  isDisabled: false,
  isSubmitting: false,
  isSubmitted: false
};

const mapStateToProps = ({
  shop: {
    cart: { isRequestingPayment, isAddingCoupon, isCouponAdded, isRemovingCoupon, cart }
  }
}) => ({
  isVisible: !cart.sed && !cart.discount,
  isDisabled: isRequestingPayment || isAddingCoupon || isRemovingCoupon,
  isSubmitting: isAddingCoupon,
  isSubmitted: isCouponAdded
});

const mapDispatchToProps = {
  onSubmit: addCoupon
};

export { CouponForm as PureComponent };
export default withTranslation('Shop/Cart/Default')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CouponForm)
);
