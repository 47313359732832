import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import {
  fetchServiceRequests as fetchServiceRequestsAction,
  searchServiceRequests,
  updateServiceRequest as updateServiceRequestAction
} from 'actions/serviceRequests';
import { ServiceRequestList, ServiceRequestFilter, ServiceRequestStats, ServiceRequestSearch } from '.';
import { getStatuses } from './helper';

import './ServiceRequests.less';

class ServiceRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: {
        data: [
          { title: 'Low', value: 20, color: '#74b8ea' },
          { title: 'High', value: 20, color: '#2c94e2' },
          { title: 'Medium', value: 30, color: '#2273af' },
          { title: 'Urgent', value: 30, color: '#314d62' }
        ],
        resolved: 6,
        created: 3,
        resolvedYesterday: 6,
        cereatedYesterday: 2,
        responseTime: 37
      },
      query: {
        status: [],
        end_date: [],
        start_date: []
      },
      showStats: false,
      currentPage: 1,
      limit: 20
    };
  }

  componentDidMount() {
    this.props.fetchServiceRequests();
  }

  toggleStats = () => {
    this.setState(prevState => ({
      showStats: !prevState.showStats
    }));
  };

  handlePageChange = page => {
    this.setState(
      () => ({
        currentPage: page
      }),
      () => this.onFetchServiceRequests()
    );
  };

  onFetchServiceRequests = query => {
    this.setState(
      prevState => ({
        ...prevState,
        query: {
          ...prevState.query,
          ...query
        }
      }),
      () => {
        if (this.state.query && this.state.query.search) {
          this.props.searchServiceRequests(
            { ...this.state.query, limit: this.state.limit, page: this.state.currentPage },
            true
          );
        } else {
          this.props.fetchServiceRequests({
            ...this.state.query,
            limit: this.state.limit,
            page: this.state.currentPage
          });
        }
      }
    );
  };

  renderContent = () => {
    const { t, requests, showPerPage, numberOfItems, updateServiceRequest } = this.props;
    const statusesOptions = getStatuses(t);

    return (
      <div className="page-content">
        <ServiceRequestList
          serviceRequests={requests}
          numberOfItems={numberOfItems}
          showPerPage={showPerPage}
          handlePageChange={this.handlePageChange}
          updateServiceRequest={updateServiceRequest}
          statusesOptions={statusesOptions}
        />
      </div>
    );
  };

  renderEmptyMessage = t => <div className="service-request-list-empty">{t('no-service-requests')}</div>;

  render() {
    const { t, requests } = this.props;
    const { showStats } = this.state;
    const requestsExist = requests && requests.length > 0;

    let content = this.renderEmptyMessage(t);

    if (requestsExist) {
      content = this.renderContent();
    }

    return (
      <DocumentTitle title={t('headline')}>
        <div className="service-request-page content-area">
          <div className="page-header">
            <h1 className="page-title">{t('headline')}</h1>

            <div className="header-right">
              <ServiceRequestSearch searchServiceRequests={this.onFetchServiceRequests} />

              <Link to="/service-requests/create" className="button button-blue">
                {t('new-service-request-btn')}
              </Link>
            </div>
          </div>

          <ServiceRequestFilter toggleStats={this.toggleStats} fetchServiceRequests={this.onFetchServiceRequests} />

          {content}

          <ServiceRequestStats isOpen={showStats} filterServiceRequests={this.onFetchServiceRequests} />
        </div>
      </DocumentTitle>
    );
  }
}

ServiceRequests.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  showPerPage: state.serviceRequests.showPerPage,
  numberOfItems: state.serviceRequests.numberOfItems,
  requests: state.serviceRequests.items
});

const mapDispatchToProps = dispatch => ({
  fetchServiceRequests: query => dispatch(fetchServiceRequestsAction(query)),
  searchServiceRequests: (query, force) => dispatch(searchServiceRequests(query, force)),
  updateServiceRequest: data => dispatch(updateServiceRequestAction(data))
});

export default pure(
  withTranslation('ServiceRequests')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ServiceRequests)
  )
);
