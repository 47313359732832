import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Input } from 'common';
import { Modal } from 'components/HOC';
import { changePassword } from 'actions/users';
import { notifyError } from 'actions/notifications';

import './ChangePassword.less';

const initialState = {
  currentPassword: '',
  newPassword: '',
  verifyPassword: '',
  showNewPasswordHint: false,
  showRepeatPasswordHint: false
};

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isChanged && nextProps.isChanged) {
      this.setState(initialState);
    }

    if (nextProps.isChanged) {
      this.handleCloseModal();
    }
  }

  handleChange = ({ target: { name, value } }) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)(.*)$/;
    if (name === 'newPassword') {
      if (!regex.test(value) || value.length < 8) {
        this.setState(() => ({
          showNewPasswordHint: true
        }));
      } else {
        this.setState(() => ({
          showNewPasswordHint: false
        }));
      }
    }

    if (name === 'verifyPassword') {
      if (value !== this.state.newPassword) {
        this.setState(() => ({
          showRepeatPasswordHint: true
        }));
      } else {
        this.setState(() => ({
          showRepeatPasswordHint: false
        }));
      }
    }

    if (this.state[name] !== value) {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit, showError, t } = this.props;
    const { currentPassword, newPassword, verifyPassword } = this.state;

    if (newPassword !== verifyPassword) return showError(t('passwords-not-matching'));
    return onSubmit(currentPassword, verifyPassword);
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  render() {
    const { isChanging, t } = this.props;
    const { currentPassword, newPassword, verifyPassword } = this.state;
    const language = JSON.parse(localStorage.getItem('language'));

    const sharedProps = {
      className: 'form-control',
      minLength: '8',
      onChange: this.handleChange,
      parentClass: 'form-group',
      required: true,
      type: 'password'
    };

    return (
      <div className="settings-modal-box">
        <h2 className="settings-headline">{t('headline')}</h2>
        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={isChanging}>
            <Input {...sharedProps} label={t('current-password')} name="currentPassword" value={currentPassword} />

            <Input {...sharedProps} label={t('new-password')} name="newPassword" value={newPassword} />
            {this.state.showNewPasswordHint ? <p className="hint">{t('hint-new-password')}</p> : ''}

            <Input {...sharedProps} label={t('retype-password')} name="verifyPassword" value={verifyPassword} />
            {this.state.showRepeatPasswordHint ? <p className="hint">{t('hint-repeat-password')}</p> : ''}

            <button type="submit" className={`save language-${language} ${isChanging ? 'loading-light' : ''}`}>
              {t('change')}
            </button>
            <button type="button" className={`cancel language-${language}`} onClick={this.handleCloseModal}>
              {t('cancel')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isChanging: PropTypes.bool,
  isChanged: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired
};

const mapStateToProps = ({
  users: {
    password: {
      change: { isChanging, isChanged }
    }
  }
}) => ({ isChanging, isChanged });

const mapDispatchToProps = {
  onSubmit: changePassword,
  showError: notifyError
};

export { ChangePassword as PureComponent };
export default withTranslation('Settings/ChangePassword')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Change Password', { className: 'password-change-modal' })(ChangePassword))
);
