import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

export const SwitchButton = ({ item, isSelected, onClick }) => (
  <button type="button" className={`single-account ${isSelected ? 'active' : ''}`} onClick={onClick}>
    <div className="single-account-name">{item.name}</div>
    <div className={`single-account-icon ${isSelected ? 'active' : ''}`} />
  </button>
);

SwitchButton.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default pure(SwitchButton);
