import {
  SHOP_CART_SCHEDULE_SET_REQUEST,
  SHOP_CART_SCHEDULE_SET_SUCCESS,
  SHOP_CART_SCHEDULE_SET_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { DATE_FORMAT } from 'constants/dateTime';
import { RRule } from 'utils';
import moment from 'moment';
import { authCall } from 'api';

export default (id, dueDate, rruleOptions, duration) => {
  // need to copy options, cause RRule destruct options
  const rrule = rruleOptions ? new RRule({ ...rruleOptions }).toString().replace('RRULE:', '') : undefined;

  return authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT,
    types: [SHOP_CART_SCHEDULE_SET_REQUEST, SHOP_CART_SCHEDULE_SET_SUCCESS, SHOP_CART_SCHEDULE_SET_FAILURE],
    endpoint: `/user/shop/cart/sub_cart/${id}/schedule`,
    method: 'PUT',
    body: {
      due_date: moment(dueDate).format(DATE_FORMAT),
      rrule,
      duration
    }
  });
};
