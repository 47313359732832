import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { pure } from 'recompose';
import classNames from 'classnames';

const CheckboxSection = ({ t, title, items, checkedItems, change }) => (
  <div className="filter-section">
    <div className="name">{t(title)}</div>

    {items.map(item => (
      <label
        className={classNames('checkbox-label ui-component', {
          checked: checkedItems.includes(item)
        })}
        htmlFor={`checkboxFilter_${item}`}
        key={item}
      >
        {t(item)}
        <input type="checkbox" id={`checkboxFilter_${item}`} name={item} onChange={change} />
      </label>
    ))}
  </div>
);

CheckboxSection.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  checkedItems: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired
};

export { CheckboxSection as PureComponent };
export default pure(withTranslation('Issues')(CheckboxSection));
