import {
  CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST,
  CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS,
  CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    items: {}
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload ? action.payload : state.items
      };

    case CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
