import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from 'actions/auth';

export const LogoutButton = ({ isLoading, onClick, t }) => (
  <span
    onClick={onClick}
    className={`nav-link link logout ${isLoading ? 'disabled loading-light' : ''}`}
    role="presentation"
  >
    {t('tooltip-logout')}
  </span>
);

LogoutButton.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  auth: {
    logout: { isFetching: isLoading }
  }
}) => ({ isLoading });

const mapDispatchToProps = {
  onClick: logout
};

export { LogoutButton as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutButton);
