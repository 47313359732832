import { createClient } from 'contentful';
import { shouldFetch } from 'utils';
import { CONTENTFUL_REQUEST, CONTENTFUL_SUCCESS, CONTENTFUL_FAILURE } from 'constants/actionTypes';

let client;
const getClient = () => {
  if (!client) {
    client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
    });
  }

  return client;
};

const setClient = c => {
  client = c;
};

export { setClient };
export default query => (dispatch, getState) => {
  const { content_type: dataKey } = query;
  const {
    contentful: { [dataKey]: { isFetching, fetchedAt } = {} }
  } = getState();

  if (!shouldFetch(isFetching, fetchedAt)) return Promise.resolve();

  const meta = { dataKey };

  dispatch({
    type: CONTENTFUL_REQUEST,
    meta
  });

  return getClient()
    .getEntries(query)
    .then(payload =>
      dispatch({
        type: CONTENTFUL_SUCCESS,
        meta,
        payload
      })
    )
    .catch(requestError =>
      dispatch({
        type: CONTENTFUL_FAILURE,
        meta,
        error: true,
        requestError
      })
    );
};
