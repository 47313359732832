import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';

import './Checkbox.less';

const Checkbox = props => {
  const { id, className, label, alignCheckboxLeft, children } = props;
  const passedProps = omit(props, 'className', 'label', 'alignCheckboxLeft', 'children');

  const checkboxLabel = <p className="checkbox__text">{label}</p>;

  return (
    <label htmlFor={id} className={`checkbox ${className}`}>
      {!alignCheckboxLeft && children}
      {label && !alignCheckboxLeft && checkboxLabel}

      <div className="checkbox__button">
        <input {...passedProps} className="checkbox__checkbox" type="checkbox" />
        <div className="checkbox__con" />
      </div>

      {alignCheckboxLeft && children}
      {label && alignCheckboxLeft && checkboxLabel}
    </label>
  );
};

Checkbox.propTypes = {
  alignCheckboxLeft: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string
};

Checkbox.defaultProps = {
  className: '',
  alignCheckboxLeft: true
};

export default htmlId(Checkbox);
