import { SHOP_CART_PAYMENT_METHOD_SELECT } from 'constants/actionTypes';

export default (method = null) => (dispatch, getState) => {
  const {
    shop: {
      payment: { selectedMethod }
    }
  } = getState();

  if (method === selectedMethod) return;

  dispatch({
    type: SHOP_CART_PAYMENT_METHOD_SELECT,
    method
  });
};
