import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { acceptTerms } from 'actions/customer';

import './Terms.less';

class Terms extends Component {
  constructor(props) {
    super(props);

    if (props.current.customer.has_accepted_tos === true) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.current.customer.has_accepted_tos === true) {
      this.props.history.push('/dashboard');
    }
  }

  handleAcceptButtonClick = () => {
    const { submitAcceptTerms } = this.props;
    submitAcceptTerms();
  };

  render() {
    const { t } = this.props;
    return (
      <DocumentTitle title={t('headline')}>
        <div className="col-group content-area pad terms">
          <div className="terms__logo" />
          <div className="col-12 flex-wrap">
            <p className="terms__text">{t('salutation')}</p>
            <p className="terms__text">{t('text')}</p>
            <p className="terms__text">
              {t('greetings')}
              <br />
              {t('spo')}
            </p>
            <div className="terms__termsbox">
              <p className="headline">Allgemeine Geschäftsbedingungen für Kundenverträge im Gebäudereiniger-Handwerk</p>
              <p className="subline">der Service Partner ONE GmbH, Winsstraße 62, D-10405 Berlin</p>
              <p>1. Anbieter und Geltungsbereich</p>
              <p>
                1.1. Anbieter der Reinigungsleistungen ist die Service Partner ONE GmbH, Winsstraße 62, 10405 Berlin
                (nachfolgend „SP ONE“, „wir“, „uns“ etc.). SP ONE tritt unter den Marken „Service Partner ONE“ und „SP
                ONE“ auf.
              </p>
              <p>
                1.2. Diese Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) gelten für alle Verträge der Service
                Partner ONE GmbH über Dienstleistungen, einschließlich ihrer Anbahnung und ihres Zustandekommens. Sie
                gelten ausschließlich; allgemeine Geschäftsbedingungen von Kunden finden keine Anwendung.
              </p>
              <p>2. Leistungen der SP ONE</p>
              <p>
                2.1. Gegenstand des Vertrages ist die Erbringung von Reinigungsleistungen durch die SP ONE. Die von SP
                ONE zu erbringenden Reinigungsleistungen umfassen
              </p>

              <p>a) die An- und Abfahrt zum Leistungsort,</p>
              <p>
                b) das Erbringen der Reinigungsleistung gemäß des einzelvertraglich bestimmten Leistungsverzeichnisses,
              </p>
              <p>
                c) die Stellung des benötigten Personals, des benötigten Reinigungsequipments – Ausrüstung,
                Reinigungsmittel und Reinigungsmaschinen – und sonstiger zur Ausführung der vorgenannten Leistungen
                erforderlichen Materialien
              </p>
              <p>
                d) Aufstockung des Verbrauchsmaterials (gestellt vom Kunden bzw. gesondert zu buchen über SP ONE) in den
                Sanitäranlagen,
              </p>
              <p>e) alle Planungs- und Koordinierungsleistungen für das Vorstehende.</p>
              <p>
                2.2. Weitere und weitergehende Leistungen kann der Kunde, wenn und soweit von SP ONE angeboten, gegen
                zusätzliches Entgelt hinzubuchen.
              </p>
              <p>
                2.3. SP ONE ist verpflichtet, die nach dem Vertrag zu erbringenden Arbeiten leistungs-, fach- und
                fristgerecht auszuführen. Erkennt SP ONE, dass er dieser Verpflichtung vorübergehend nicht nachkommen
                kann, so hat er dies dem Kunden unverzüglich mitzuteilen.
              </p>
              <p>
                2.4. Im Falle einer nicht vertragsgemäßen Erfüllung ist SP ONE berechtigt, dem Kunden eine Frist von
                einer Woche zur Nachbesserung zu setzen. Kommt SP ONE der Aufforderung der Nachbesserung nicht
                fristgerecht nach, ist der Kunde berechtigt, die vereinbarten Entgelte angemessen herabzusetzen.
              </p>
              <p>3. Pflichten der SP ONE</p>
              <p>
                3.1. Die SP ONE hat die Arbeitszeit der Reinigungskräfte in Übereinstimmung mit dem Kunden so
                festzulegen, dass der Dienstbetrieb nicht behindert wird.
              </p>
              <p>
                3.2. Der SP ONE stellt die erforderlichen Arbeitskräfte. Er verpflichtet sich nur fachkundiges und
                zuverlässiges Personal einzusetzen. Der SP ONE ist ferner berechtigt, sich zur Erfüllung seiner
                Verpflichtungen anderer Unternehmen (Subunternehmer, sogenannte „Partner“) zu bedienen; der Kunde
                erteilt vorsorglich hiermit seine Zustimmung.
              </p>
              <p>
                3.3. Erhält die SP ONE Schlüssel oder Zahlencodes durch den Kunden, sind diese nach Ablauf des Vertrages
                ordnungsgemäß und fristgerecht zurück zu geben. Eine Vervielfältigung seitens des der SP ONE ist nicht
                gestattet. Eine gleichlautende Vereinbarung gilt gleichermaßen für den ausführenden Partner.
              </p>
              <p>
                3.4. Ausländische Arbeitskräfte müssen im Besitz einer gültigen Aufenthalts- und Arbeitserlaubnis sein.
                Arbeitskräfte mit meldepflichtigen übertragbaren Krankheiten dürfen nicht eingesetzt werden.
              </p>
              <p>
                3.5. Die SP ONE verpflichtet die Arbeitskräfte keinen Einblick in die Akten und Schriftstücke zu nehmen,
                weder Schreibtische, Schränke noch andere Einrichtungsgegenstände zu öffnen, die in den Räumen
                befindliche Telefone und Büromaschinen nicht zu benutzen, Verschwiegenheit über bekanntgewordene
                dienstliche Vorgänge zu wahren, Gegenstände, die in den zu reinigenden Räumen gefunden werden,
                unverzüglich dem zuständigen Beauftragten zu übergeben.
              </p>
              <p>
                3.6. Die SP ONE hat auf Verlangen des Kunden im Rahmen wiederkehrender Reinigungsleistungen jede
                Arbeitskraft oder den Partner unverzüglich auszutauschen, soweit Gründe (mehrmalige Schlechtleistung
                oder erhebliches Fehlverhalten) für einen Austausch vorliegen.
              </p>
              <p>
                3.7. Personen, die die SP ONE nicht mit der Ausführung der Reinigungsarbeiten betraut hat, dürfen nicht
                in das Gebäude mitgenommen werden.
              </p>
              <p>
                3.8. Im Anschluss an eine erbrachte Reinigungsleistung ist die SP ONE verpflichtet einen
                Ausführungsnachweise (im Folgenden „Leistungsnachweise“) zu erstellen, der als Beleg über die Leistung
                gilt und vom Kunden als solcher anerkannt wird. Die Leistungsnachweise werden in elektronischer Form
                erstellt und dem Kunden elektronisch per E-Mail oder über die SP ONE Kunden Online-Plattform zugestellt.
              </p>
              <p>4. Zustandekommen von Verträgen</p>
              <p>
                4.1. Die vertraglichen Leistungen werden im Vorfeld des Vertragsschlusses iterativ konkretisiert.
                Grundsätzlich wird zwischen einmaligen Reinigungsleistungen (u.a. einmalige Grundreinigungen,
                Fensterreinigungen, Intensivreinigungen) und wiederkehrenden Reinigungsleistungen
                (Unterhaltsreinigungen) unterschieden.
              </p>
              <p>
                4.2. Der Kunde unterbreitet SP ONE über die SP ONE Kunden Online-Plattform, per E-Mail oder telefonisch
                zunächst bestimmte Informationen über die geplante Reinigung, u.a. die Leistungsadresse, die
                Rechnungsadresse, den Umfang der Reinigungsleistung, die zu erbringenden Zusatzleistungen, die
                Leistungsdaten, den Leistungszeitraum (Vertragslaufzeit), die Häufigkeit der Leistung, den Zugang zu den
                jeweiligen Räumlichkeiten, das benötigte Material und die Beschaffenheit des jeweiligen Gebäudes und der
                Räumlichkeiten.
              </p>
              <p>
                4.3. Alle gemachten Angaben müssen zutreffen; sie bilden am Ende die Grundlage für das preisliche
                Angebot und die spätere Durchführung der Reinigung. Falsche, ungenaue oder unterlassene Angaben gehen zu
                Lasten des Kunden. Sie können zu Mehrkosten oder auch dazu führen, dass die Reinigung nicht durchgeführt
                wird.
              </p>
              <p>
                4.4. SP ONE erstellt auf der Grundlage der Angaben des Kunden einen konkreten Kostenvorschlag und
                übermittelt diesen dem Kunden in der dafür vorgesehenen Weise (elektronisch per E-Mail oder über die
                Kunden Online-Plattform der SP ONE). Sagt der Kostenvorschlag dem Kunden zu, kann der Kunde die
                Reinigung in der dafür vorgesehenen Weise (elektronisch per E-Mail oder über die Kunden Online-Plattform
                der SP ONE) direkt buchen. SP ONE wird dem Kunden den Auftrag binnen drei Werktagen bestätigen. SP ONE
                ist indes berechtigt, Aufträge auch abzulehnen; Gründe hierfür sind nicht erforderlich.
              </p>
              <p>4.5. Für Vertragsschlüsse steht nur die deutsche Sprache zur Verfügung.</p>
              <p>
                4.6. Der Vertrag zwischen SP ONE und dem Kunden wird grundsätzlich nicht in einem gesonderten
                Vertragstext zur Person des Kunden niedergelegt, auf den der Kunde dann später als solchen zugreifen
                könnte. Der Inhalt des Vertrages ergibt sich aber aus dem Gegenstand des Vertrages (Angebot des Kunden
                über die betreffenden Leistungen, Preis ggf. nachträglich hinzubestellte Zusatzleistungen etc.) und
                diesen AGB.
              </p>
              <p>5. Erweiterungen des Leistungsumfangs</p>
              <p>
                5.1. Möchte der Kunde den Leistungsumfang nach Vertragsschluss erweitern oder sonstige Veränderungen
                Leistungsverzeichnis (Wechsel von einer Leistung gegen eine andere Leistung) vornehmen, kann er sich mit
                SP ONE in Verbindung setzen. Das SP ONE-Team wird die Details mit dem Kunden festlegen und den Kunden
                durch den Nachbuchungsprozess leiten.
              </p>
              <p>
                5.2. SP ONE weist darauf hin, dass die Mitarbeiter, die die Reinigung vor Ort durchführen, grundsätzlich
                keine Vollmacht besitzen und uns daher nicht wirksam rechtsgeschäftlich vertreten können. Sofern der
                Kunde jedoch während der Reinigung vor Ort noch bestimmte Zusatzleistungen hinzubuchen will und diese
                Zusatzleistungen auch erbracht werden können, oder wenn absehbar andere Mehraufwände entstehen, so sind
                die betreffenden Zusatz- bzw. Mehrleistungen zeitnah auf der Kunde-Plattform von SP ONE durch den Kunden
                nachzutragen. Alternativ erstellt das Reinigungspersonal im Anschluss einen Leistungsnachweis gemäß den
                Vorgaben von SP ONE, der als Beleg über den erbrachten Mehraufwand dient.
              </p>
              <p>6. Besonderheiten aus der Gebäudereinigungsbranche</p>
              <p>
                6.1. Die Mitarbeiter vor Ort sind nicht berechtigt, Zahlungen vom Kunden entgegenzunehmen. Gleichwohl
                vorgenommene Zahlungen des Kunden unmittelbar an Mitarbeiter werden nicht mit befreiender Wirkung zu
                Lasten von SP ONE geleistet. Solche Zahlungen lässt SP ONE also nicht gegen sich gelten.
              </p>
              <p>
                6.2. SP ONE hat das Recht, vom Vertrag mit dem Kunden zurückzutreten, falls SP ONE – ohne dies jeweils
                vertreten zu müssen – keine Kapazitäten für die Erfüllung der vertraglichen Leistungen hat bzw. die
                vereinbarten Termine nicht einhalten kann, ohne dass SP ONE dies vorhersehen und/oder verhindern konnte.
              </p>
              <p>
                6.3. Ein Rücktrittsrecht steht SP ONE auch zu, wenn bei Vertragsschluss nicht erkennbare Umstände
                vorliegen, die einen Rücktritt unter Berücksichtigung eines anerkennenswerten Interesses von SP ONE
                rechtfertigen, z. B. in Fällen höherer Gewalt, Streik und Naturkatastrophen.
              </p>
              <p>
                6.4. SP ONE wird im Rücktrittsfall vom Kunden bereits geleistete Gegenleistungen unverzüglich erstatten.
              </p>
              <p>7. Kündigungsrecht des Kunden</p>
              <p>
                7.1. Der Kunde kann einen Vertrag über eine einmalige Reinigung bis zu 3 Werktagen vor Beginn der
                Leistung kündigen. Liegen die Gründe für die Kündigung nicht im Risikobereich von SP ONE, hat SP ONE
                dann jedoch das Recht, ein Drittel der vereinbarten Vergütung zu verlangen.
              </p>
              <p>
                7.2. Anderes gilt, wenn Kunde mindestens 14 Tage vor der geplanten Reinigung den Reinigungsvertrag bei
                SP ONE kündigt. In diesem Fall berechnet SP ONE dem Kunden eine einmalige Bearbeitungsgebühr in Höhe von
                30,- EUR.
              </p>
              <p>
                7.3. Der Kunde kann einen Vertrag über eine wiederkehrende Reinigung in den ersten drei Monaten der
                Vertragslaufzeit mit einer Frist von zwei Wochen zum Monatsende kündigen. Eine abweichende Regelung
                bedarf der Schriftform.
              </p>
              <p>7.4. Das dem Kunden zustehende Kündigungsrecht bedarf der Textform.</p>
              <p>7.5. Andere dem Kunden zustehende Rechte bleiben unberührt.</p>
              <p>8. Pflichten des Kunden</p>
              <p>
                8.1. Der Kunde stellt für die Durchführung der Reinigungsarbeiten unentgeltlich warmes und kaltes Wasser
                sowie Strom zur Verfügung. Der Auftragnehmer hat für einen sparsamen Verbrauch zu sorgen.
              </p>
              <p>
                8.2. Die für den Dienst notwendigen Schlüssel bzw. Codes (bei elektronischen Zugängen) sind vom Kunden
                rechtzeitig und kostenlos zur Verfügung zu stellen.
              </p>
              <p>
                8.3. Der Kunde erhält am Ende eines jeden Monats (Leistungszeitraum), spätestens in den ersten 5
                Werktagen des Folgemonats, eine Übersicht aller Leistungsnachweise des Leistungszeitraums in
                elektronischer Form per E-Mail; hierfür hat der Kunde der SP ONE eine E-Mail-Adresse zu nennen. Ein
                Leistungsnachweis gilt als Beleg über die Leistung und wird vom Kunden als solcher anerkannt. Die
                Leistungsnachweise werden in elektronischer Form erstellt und dem Kunden elektronisch per E-Mail oder
                über die SP ONE Kunden Online-Plattform zugestellt. Die Leistungsnachweise gelten als akzeptiert und
                konkludent bestätigt, wenn innerhalb der nächsten zwei Werktage nach Zugang der Leistungsnachweise kein
                Widerspruch in Schriftform (elektronisch per E-Mail ist ausreichend) erfolgt ist.
              </p>
              <p>
                8.4. Der Kunde ist verpflichtet, die erforderlichen Informationen Leistungsort, Rechnungsadresse,
                Ansprechpartner, Anzahl und Benennung der Räumlichkeit und deren (insbesondere Böden, Fenster, Türen)
                wahrheitsgemäß und vollständig zu erteilen sowie sonstige, für die Durchführung der Reinigung relevanten
                Umstände mitzuteilen.
              </p>
              <p>
                8.5. Der Kunde ist insbesondere zur Erfassung und Übermittlung einer vollständigen und wahrheitsgemäßen
                Angabe der Quadratmeteranzahl und der Mitarbeiteranzahl in den zu reinigenden Räumlichkeiten des
                Unternehmens des Kunden verpflichtet. Vor der verbindlichen Bestellung ist der Kunde verpflichtet, die
                Angaben auf Vollständigkeit zu prüfen und notwendige Anpassungen gegebenenfalls elektronisch
                (elektronisch per E-Mail oder über die Kunden Online-Plattform der SP ONE) oder telefonisch SP ONE zu
                melden. Der Kostenvorschlag kann sich durch eine solche Nachmeldung entsprechend ändern.
              </p>
              <p>
                8.6. Der Kunde ist verpflichtet, sämtliche für die ordnungsgemäße Durchführung der Reinigung
                erforderlichen Vorbereitungsmaßnahmen, die zu einer ordnungsgemäßen Durchführung der Reinigung erfüllt
                sein müssen (v.a. Abräumen von Tischen, Aufräumen von Konferenzräumen, der Küche, etc.; jeweils soweit
                nicht von SP ONE als Zusatzleistung gebucht), rechtzeitig vor Reinigungsbeginn selbstständig
                vorzunehmen. Der Kunde stellt somit sicher, dass es am Tag der Reinigung zu keiner von ihm zu
                vertretenden Beeinträchtigung oder Verzögerung kommt.
              </p>
              <p>
                8.7. Der Kunde ist verpflichtet, etwaige Änderungen seiner Angaben, die den Leistungsort als auch die
                Räumlichkeiten und andere leistungsrelevante Aspekte betreffen, SP ONE unverzüglich nach
                Kenntniserlangung mitzuteilen. Derartige Änderungen können zusätzliche Leistungen seitens SP ONE
                erfordern, die der Kunde entsprechend zu vergüten hat.
              </p>
              <p>
                8.8. Der Kunde ist verpflichtet, unter der zuletzt angegebenen Rufnummer (Mobil/ Festnetz) für SP ONE
                erreichbar zu sein, insbesondere in den Stunden vor dem avisierten Beginn der Reinigung.
              </p>
              <p>9. Vergütung und Zahlungsbedingungen</p>
              <p>9.1. Die dem Kunden genannten Preise verstehen sich exklusive der Umsatzsteuer.</p>
              <p>
                9.2. Die vereinbarten Entgelte sind in dem jeweiligen Reinigungsvertrag ausgewiesen. Diese werden bei
                wiederkehrenden Reinigungsleistungen berechnet, unabhängig ob der Kunde einzelne Reinigungstage pausiert
                hat oder ein Reinigungstag auf einen Feiertag gefallen ist. Wünscht der Auftraggeber im Rahmen einer
                wiederkehrenden Reinigungsleistung eine Nachholung der Reinigung muss diese außerhalb der regulären
                Unterhaltsreinigung vereinbart werden.
              </p>
              <p>9.3. Die vereinbarten Entgelte gelten als Festpreise. Sie können erhöht oder ermäßigt werden, wenn:</p>
              <p>
                a) der Abschluss neuer Lohn- oder Rahmentarifverträge, die für den Auftragnehmer gelten, dies
                erforderlich machen sollte,
              </p>
              <p>b) durch Rechtsvorschriften Änderungen der Sozialleistungen bestimmt werden,</p>
              <p>
                c) organisatorische Änderungen des Dienstbetriebes auf Dauer Änderungen der Nutzungsintensität mit sich
                bringen
              </p>
              <p>9.4. Änderungen der Entgelte bedürfen der schriftlichen Vereinbarung.</p>
              <p>
                9.5. Der Kunde ist berechtigt, die zu reinigenden Flächen nach den betrieblichen Erfordernissen
                jederzeit vorübergehend einzuschränken
              </p>
              <p>
                9.6. Bei Änderung von Tariflöhnen sind wir berichtigt den Preis entsprechend anzupassen. Sollten sich
                die Gegebenheiten beim Auftraggeber maßgeblich geändert haben, (beispielsweise Vergrößerung/Verringerung
                der zu reinigenden Fläche, Erhöhung der Anzahl der Mitarbeiter) behaltet sich die SP ONE vor, den Preis
                entsprechend anzupassen.
              </p>
              <p>
                9.7. Soweit mit dem Kunden nichts anderes vereinbart ist, sind Zahlungsforderungen von SP ONE am Ende
                eines jeden Monats, in dem die Leistungserbringung stattgefunden hat, fällig, spätestens mit
                Rechnungsstellung. Der Kunde hat ein Zahlungsziel von 14. Tagen. SP ONE behält sich das Recht vor, bei
                erheblichen Fremdkosten, die SP ONE aus dem einzelnen Auftrag entstehen, vom Kunden eine entsprechende
                Abschlagszahlung zu fordern.
              </p>
              <p>
                9.8. Es werden gegebenenfalls verschiedene Zahlungsmöglichkeiten angeboten (z.B. Zahlung auf Rechnung,
                Kreditkarte oder Lastschrift), ohne dass SP ONE hierzu jedoch verpflichtet ist. Für die
                Zahlungsabwicklung über Zahlungssystemanbieter (z.B. Kreditkarte) gelten ausschließlich die Nutzungs-
                und Geschäftsbedingungen des betreffenden Zahlungssystemanbieters. Wurde zur Zahlung das SEPA
                Lastschriftverfahren vereinbart, so ist SP ONE dazu verpflichtet, dem Kunden Betrag und Belastungsdatum
                im Vorfeld mitzuteilen. Die Vorinformation (Pre-Notification) erfolgt spätestens einen Werktag vor
                Kontobelastung. Mit der Wahl des SEPA-Lastschriftverfahrens erklärt sich der Kunde damit einverstanden,
                dass die Vorabinformationsfrist (Pre-Notification) von 14 Kalendertagen auf einen Werktag vor Fälligkeit
                verkürzt wird.
              </p>
              <p>
                9.9. Für jede Art von Gutschriften oder die Abrechnung von Mehraufwänden hat SP ONE die Wahl, diese über
                die ursprünglich vom Kunden ausgewählte Zahlungsmethode oder per Rechnung abzurechnen.
              </p>
              <p>
                9.10. Der Kunde erhält eine Rechnung am Ende des Monats über alle erbrachten Leistungen der SP ONE in
                elektronischer Form per E-Mail an die von ihm genannte E-Mail-Adresse.
              </p>
              <p>10. Vertragsdauer von Unterhaltsreinigungen</p>
              <p>
                10.1. Ein Vertrag über eine wiederkehrende Reinigungsleistung beginnt mit dem Leistungsbeginn
                (Start-Datum) der Reinigung und endet 12 (zwölf) Monate nach Leistungsbeginn.
              </p>
              <p>
                10.2. Der Vertrag über eine Unterhaltsreinigung verlängert sich jeweils um ein Jahr, wenn er nicht drei
                Monate vor Vertragsablauf gekündigt wird.
              </p>
              <p>11. Zugang zur Kunden Online-Plattform SP ONE, Überlassung der Software</p>
              <p>
                11.1. SP ONE stellt dem Kunden über seine Webseite eine Software als webbasierte
                Software-as-a-Service-Lösung in der jeweils aktuellen Version zur Verfügung und räumt dem Kunden das
                Recht ein, die Software im Rahmen der Einzelverträge bestimmungsgemäß und gemäß den vertraglichen
                Vereinbarungen zu nutzen. Die Nutzung der Software setzt eine bestehende Internetverbindung und die
                Nutzung einer kompatiblen Browser-Software voraus. Die Nutzung der Software ist für den Kunden
                kostenlos.
              </p>
              <p>
                11.2. Die Nutzung der Software ist nur mit Hilfe spezieller Log In-Daten, jeweils bestehend aus einem
                Benutzernamen und einem Kennwort (im Folgenden „Zugangsdaten“) möglich. Mit Abschluss eines Vertrages
                übermittelt SP ONE an den Kunden den Zugangsdatensatz, der jeweils nur von dem betreffenden Kunden
                persönlich oder durch einen Mitarbeiter genutzt werden darf. Der Kunde ist verpflichtet, die
                Zugangsdaten geheim zu halten und vor dem Zugriff durch Dritte geschützt aufzubewahren sowie seine
                Mitarbeiter entsprechend zu verpflichten. Sind dem Kunden Zugangsdaten abhandengekommen oder stellt er
                fest oder hegt er den Verdacht, dass überlassene Zugangsdaten von einem Dritten genutzt werden, hat er
                dies SP ONE unverzüglich mitzuteilen.
              </p>
              <p>
                11.3. Die Software ermöglicht u. a. bestellte Leistungen nachzuverfolgen, verbindliche Angebot für den
                Abschluss eines Einzelvertrages abzugeben, die Erfassung und Freigabe von Leistungsnachweisen und die
                Aufbereitung von Abrechnungsdaten zum Zwecke der Rechnungstellung.
              </p>
              <p>12. Verfügbarkeit der Software und Sicherung der Daten</p>
              <p>
                12.1. SP ONE bemüht sich, die Verfügbarkeit der Software möglichst durchgehend und fehlerfrei zu
                gewährleisten. Der Kunde erkennt jedoch an, dass bereits aus technischen Gründen und aufgrund der
                Abhängigkeit von äußeren Einflüssen, z. B. im Rahmen der Fernmeldenetze, eine ununterbrochene
                Verfügbarkeit nicht sichergestellt werden kann.
              </p>
              <p>
                12.2. SP ONE führt an den eigenen Servern zur Sicherstellung des Betriebes und ggf. zum Zwecke der
                Erweiterung der eigenen Angebote gelegentlich Wartungsarbeiten durch, die zu einer vorübergehenden
                Beeinträchtigung der Nutzbarkeit der Software führen können. SP ONE wird die Wartungsarbeiten, sofern
                möglich, in besonders nutzungsarmen Zeiten durchführen.
              </p>
              <p>
                12.3. Der Kunde ist für die wiederkehrende Sicherung seiner Daten selbst verantwortlich. Resultieren
                Schäden des Kunden aus dem Verlust von Daten, so haftet SP ONE hierfür, auch wenn ein
                haftungsbegründendes Ereignis vorliegt, nur, soweit die Schäden auch durch eine wiederkehrende Sicherung
                aller relevanten Daten durch den Kunden nicht vermieden worden wären.
              </p>
              <p>13. Abwerbungsverbot</p>
              <p>
                13.1. Der Kunde verpflichtet sich, während der Laufzeit und bis einschließlich des 12. Monats nach
                Ablauf bzw. Ende dieses Vertrages weder unmittelbar selbst oder durch Dritte Arbeitskräfte des
                Auftragnehmers bzw. von den durch den Auftragnehmer beauftragten Partnern oder die Partner als Ganzes
                abzuwerben.
              </p>
              <p>
                13.2. Es wird vereinbart, dass sowohl eine Weiterbeschäftigung, als auch eine Neuanstellung der
                Arbeitskräfte bzw. Angestellten der SP ONE und/oder der Arbeitskräfte bzw. Angestellten eines von SP ONE
                zum Zwecke der Leistungsausführung gegründeten Unternehmens und/oder der Angestellten bzw. Arbeitskräfte
                eines zum Zwecke der Leistungserbringung seitens der SP ONE beauftragten Unternehmens, für den Zeitraum
                von sechs Monaten nach Vertragsende ausgeschlossen wird.
              </p>
              <p>
                13.3. Bei Nichteinhaltung von Ziffer 13.1 und 13.2 durch den Kunden wird eine Abfindungszahlung/
                Vertragsstrafe an die SP ONE in Höhe von den zwölf durchschnittlichen Monatsgehältern (brutto) je
                beschäftigter Arbeitskraft, mindestens aber von 1.000,- EUR bezogen auf den jeweiligen Mitarbeiter, zum
                Monatsende der Anspruchsanmeldung fällig. Die Geltendmachung eines darüberhinausgehenden Schadens bleibt
                vorbehalten.
              </p>
              <p>14. Haftung von SP ONE, Haftungsausschluss- und Begrenzungsgründe</p>
              <p>14.1. SP ONE haftet grundsätzlich nach den gesetzlichen Vorschriften.</p>
              <p>
                14.2. SP ONE ist von der Haftung befreit, soweit Beschädigungen oder die Überschreitungen der
                Leistungsfrist auf Umständen beruht, die SP ONE auch bei größter Sorgfalt nicht vermeiden und deren
                Folgen SP ONE nicht abwenden konnte.
              </p>
              <p>
                14.3. Die Haftung von SP ONE wegen Überschreitung der Leistungsfrist ist auf den dreifachen Betrag der
                Vergütung für die monatliche Reinigungsleistung begrenzt.
              </p>
              <p>
                14.4. Haftet SP ONE wegen der Verletzung einer mit der Ausführung der Reinigungsleistung
                zusammenhängenden vertraglichen Pflicht für Schäden, die nicht durch Verlust oder Beschädigung des Gutes
                oder durch Überschreitung der Leistungsfrist entstehen, und handelt es sich um andere Schäden als Sach-
                oder Personenschäden, so ist auch in diesem Falle die Haftung begrenzt, und zwar auf das Dreifache der
                Vergütung für die monatliche Reinigungsleistung.
              </p>
              <p>
                14.5. Die Haftung von SP ONE wegen Beschädigung der zu reinigenden Räumlichkeiten und beweglichen
                Gegenstände ist auf einen Betrag von 50,- EUR je Quadratmeter, der zur Erfüllung des Vertrages benötigt
                wird, beschränkt.
              </p>
              <p>
                14.6. Der Kunde wird hiermit über die Möglichkeit unterrichtet, eine weitergehende Haftung zu
                vereinbaren oder zu versichern.
              </p>
              <p>
                14.7. Ansprüche des Kunden gegen SP ONE wegen Verlust oder Beschädigung der Räumlichkeiten oder von
                Inventar entfallen, wenn der Verlust oder die Beschädigung der Sachen äußerlich erkennbar war und SP ONE
                nicht spätestens am Tag nach der Leistung angezeigt worden ist, wenn der Verlust oder die Beschädigung
                äußerlich nicht erkennbar war und SP ONE nicht innerhalb von vierzehn Tagen nach Ablieferung angezeigt
                worden ist.
              </p>
              <p>
                14.8. Die Anzeige des Kunden muss den Verlust oder die Beschädigung hinreichend deutlich kennzeichnen.
                Eine Schadensanzeige ist durch den Kunden in Textform zu erstatten. Der Kunde kann für die
                Schadensanzeige das auf der Webseite hierfür von SP ONE zur Verfügung gestellte Formular verwenden.
              </p>
              <p>15. Datenschutz</p>
              <p>
                15.1. Alle Informationen zur Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten bei der
                Webseite finden sich in der Datenschutzerklärung.
              </p>
              <p>
                15.2. SP ONE übermittelt ggf. personenbezogene Daten der Kunden an Dritte, soweit dies für die Zwecke
                der Durchführung des jeweils geschlossenen Vertrages erforderlich ist.
              </p>
              <p>16. Schlussbestimmungen</p>
              <p>
                16.1. Auf Verträge zwischen SP ONE und den Kunden findet das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts und des deutschen bzw. europäischen Kollisionsrechts Anwendung.
              </p>
              <p>
                16.2. Der Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und SP ONE
                ist Berlin.
              </p>
              <p>
                16.3. Sollte eine Bestimmung dieses Vertrages ganz oder teilweise unwirksam sein oder werden, so bleibt
                die Wirksamkeit des Vertrages im Übrigen hiervon unberührt. Die Parteien verpflichten sich, anstelle der
                unwirksamen Bestimmung eine wirksame Regelung zu vereinbaren, die nach Form, Inhalt und Maß dem am
                nächsten kommt, was nach dem Sinn und wirtschaftlichen Erfolg der unwirksamen Bestimmung von den
                Vertragsschließenden beabsichtigt war. Das Gleiche gilt im Falle einer Lücke.
              </p>

              <p className="headline">Allgemeine Geschäftsbedingungen für Warenlieferungen und Dienstleistungen</p>
              <p className="subline">der Service Partner ONE GmbH, Winsstraße 62, D-10405 Berlin</p>
              <p>1. Anbieter und Geltungsbereich</p>
              <p>
                1.1. Anbieter der Warenlieferungen und Dienstleistungen ist die Service Partner ONE GmbH, Winsstraße 62,
                10405 Berlin (nachfolgend „SP ONE“, „wir“, „uns“ etc.). SP ONE tritt unter den Marken „Service Partner
                ONE“ und „SP ONE“ auf.
              </p>
              <p>
                1.2. Diese Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) gelten für alle Aufträge bzw. Verträge
                der Service Partner ONE GmbH über Warenlieferungen bzw. Dienstleistungen, einschließlich ihrer Anbahnung
                und ihres Zustandekommens. Sie gelten ausschließlich; allgemeine Geschäftsbedingungen von Kunden finden
                keine Anwendung.
              </p>
              <p>2. Leistungen der SP ONE</p>
              <p>
                2.1. Gegenstand des Auftrages bzw. Vertrages ist die Erbringung von Warenlieferungen bzw.
                Dienstleistungen durch die SP ONE. Die von SP ONE zu erbringenden Leistungen umfassen
              </p>
              <p>a) die An- und Abfahrt zum Leistungsort,</p>
              <p>b) das Erbringen der vereinbarten Warenlieferung bzw. Dienstleistung,</p>
              <p>
                c) die Stellung des benötigten Personals, des benötigten Equipments und sonstiger zur Ausführung der
                vorgenannten Leistungen erforderlichen Materialien
              </p>
              <p>d) alle Planungs- und Koordinierungsleistungen für das Vorstehende.</p>
              <p>
                2.2. SP ONE ist verpflichtet, die vereinbarten Warenlieferungen und Dienstleistungen leistungs-, fach-
                und fristgerecht auszuführen. Erkennt SP ONE, dass er dieser Verpflichtung vorübergehend nicht
                nachkommen kann, so hat er dies dem Kunden unverzüglich mitzuteilen.
              </p>
              <p>
                2.3. Im Falle einer nicht vertragsgemäßen Erfüllung ist SP ONE berechtigt, dem Kunden eine Frist von
                einer Woche zur Nachbesserung zu setzen. Kommt SP ONE der Aufforderung der Nachbesserung nicht
                fristgerecht nach, ist der Kunde berechtigt, die vereinbarten Entgelte angemessen herabzusetzen.
              </p>
              <p>3. Pflichten der SP ONE</p>
              <p>
                3.1. Die SP ONE stellt die erforderlichen Arbeitskräfte zur Ausführung der vereinbarten Warenlieferungen
                und Dienstleistungen. Sie verpflichtet sich nur fachkundiges und zuverlässiges Personal einzusetzen. SP
                ONE ist hierbei berechtigt, sich zur Erfüllung seiner Verpflichtungen anderer Unternehmen
                (Subunternehmer, sogenannte „Partner“) zu bedienen; der Kunde erteilt vorsorglich hiermit seine
                Zustimmung.
              </p>
              <p>
                3.2. Erhält die SP ONE Schlüssel oder Zugangscodes zu den Räumlichkeiten des Kunden, sind diese nach
                Erfüllung des Auftrages ordnungsgemäß und fristgerecht zurück zu geben. Eine Vervielfältigung seitens
                der SP ONE ist nicht gestattet. Eine gleichlautende Vereinbarung gilt gleichermaßen für den ausführenden
                Partner.
              </p>
              <p>
                3.3. Personen, die die SP ONE nicht mit der Ausführung der Warenlieferung oder Dienstleistung betraut
                hat, dürfen nicht in das Gebäude mitgenommen werden.
              </p>
              <p>4. Zugang zur Kunden Online-Plattform SP ONE</p>
              <p>
                4.1. SP ONE stellt dem Kunden über seine Webseite eine Software als webbasierte
                Software-as-a-Service-Lösung in der jeweils aktuellen Version zur Verfügung und räumt dem Kunden das
                Recht ein, die Software bestimmungsgemäß und gemäß den vertraglichen Vereinbarungen zu nutzen. Die
                Nutzung der Software setzt eine bestehende Internetverbindung und die Nutzung einer kompatiblen
                Browser-Software voraus. Die Nutzung der Software ist für den Kunden kostenlos.
              </p>
              <p>
                4.2. Die Software ermöglicht u. a. verbindliche Bestellungen für Waren zu tätigen, Angebot für den
                Abschluss eines Dienstleistungseinzelvertrages anzufordern, bestellte Warenlieferungen und
                Dienstleistungen nachzuverfolgen, und die Aufbereitung von Abrechnungsdaten zum Zwecke der
                Rechnungstellung einzusehen.
              </p>
              <p>
                4.3. Die Nutzung der Software ist nur mit Hilfe spezieller Log In-Daten, jeweils bestehend aus einem
                Benutzernamen und einem Kennwort (im Folgenden „Zugangsdaten“) möglich. Zur Generierung der Log In-Daten
                unterbreitet der Kunde SP ONE zunächst per E-Mail, telefonisch oder über die SP ONE Webseite bestimmte
                Informationen über sein Unternehmen, u.a. die Leistungsadresse, die Rechnungsadresse, Ansprechperson,
                Kontaktdaten (E-Mailadresse und Telefon) und die Beschaffenheit des jeweiligen Gebäudes (Fahrstuhl,
                Stockwerk, etc.). Auf Basis der unterbreiteten Informationen erstellt SP ONE einen Kunden-Account und
                sendet dem Kunden über eine automatisch generierte E-Mail die speziellen Log In-Daten zu.
              </p>
              <p>
                4.4. Alle gemachten Angaben müssen zutreffen; sie bilden am Ende die Grundlage für die spätere
                Durchführung der Warenlieferungen. Falsche, ungenaue oder unterlassene Angaben gehen zu Lasten des
                Kunden. Sie können zu Mehrkosten oder auch dazu führen, dass die Warenlieferung nicht durchgeführt wird.
              </p>
              <p>
                4.5. Der Zugangsdatensatz darf jeweils nur von dem betreffenden Kunden persönlich oder durch einen
                Mitarbeiter genutzt werden. Der Kunde ist verpflichtet, die Zugangsdaten geheim zu halten und vor dem
                Zugriff durch Dritte geschützt aufzubewahren sowie seine Mitarbeiter entsprechend zu verpflichten. Sind
                dem Kunden Zugangsdaten abhandengekommen oder stellt er fest oder hegt er den Verdacht, dass überlassene
                Zugangsdaten von einem Dritten genutzt werden, hat er dies SP ONE unverzüglich mitzuteilen.
              </p>
              <p>5. Zustandekommen von Aufträgen, Sonderbedingungen für elektronische Bestellungen</p>
              <p>
                5.1. Bestellungen zu Waren können ausschließlich über die SP ONE Kunden Online-Plattform in dem dafür
                vorgesehenen Shop getätigt werden.
              </p>
              <p>
                5.2. Nach Eingang der Bestellung über die Kunden Online-Plattform erhält der Kunde eine automatisch
                generierte Benachrichtigungs-Email, in der der Erhalt der Bestellung bestätigt wird (nachfolgend
                „Eingangsbestätigung“). Die Eingangsbestätigung stellt noch keine verbindliche Annahme der Bestellung
                dar. Die Annahme der Bestellung erfolgt gesondert per E-Mail. Hinsichtlich des Umfangs und der Art der
                Leistung ist alleine der Inhalt der Annahme der Bestellung für SP ONE verbindlich.
              </p>
              <p>6. Sonderbedingungen für wiederkehrende Bestellungen</p>
              <p>
                6.1. Der Kunde hat die Möglichkeit über die SP ONE Kunden Online-Plattform wiederkehrende Lieferungen
                einer bestimmten Menge und Anzahl von gewünschten Produkten und Dienstleistungen einzurichten. Diese
                Lieferungen sind automatisch wiederkehrende Lieferungen täglicher, wöchentlicher oder monatlicher
                Lieferungen.
              </p>
              <p>
                6.2. Die Einrichtung der wiederkehrenden Lieferungen erfolgt im Shop der SP ONE Kunden Online-Plattform
                durch die folgenden Schritte.
              </p>
              <p>
                6.3. Die wiederkehrenden Bestellungen sind mit einer Frist von vierzehn Tagen zum Monatsende kündbar.
                Die Kündigung ist schriftlich oder per E-Mail an den SP ONE Kundenservice unter
                kunden@servicepartner.one zu richten.
              </p>
              <p>
                6.4. Geht am Ende der gewählten Laufzeit der wiederkehrenden Bestellung keine Kündigung gemäß § 6.3. vom
                Kunden schriftlich bei SP ONE ein, so verlängert sich das Vertragsverhältnis automatisch um die
                ursprünglich gewählte Laufzeit.
              </p>
              <p>7. Lieferung, Gefahrübergang, Eigentum</p>
              <p>
                7.1. Die Einhaltung des von SP ONE angegebenen Liefertermins setzt die Abklärung aller technischen
                Fragen und die rechtzeitige Erfüllung der Mitwirkungspflichten des Kunden gemäß § 9.1. voraus. In Fällen
                höherer Gewalt (Streik, Naturereignisse oder ähnliches) ist die Lieferzeit für die Dauer des
                Hindernisses unterbrochen.
              </p>
              <p>
                7.2. Die auf der Kunden Online-Plattform angegebene Liefertage und Produktverfügbarkeiten beziehen sich
                auf Angaben der Kooperationspartner von SP ONE. Sie sind unverbindliche Aussagen über voraussichtliche
                Liefertage und Verfügbarkeiten und beziehen sich auf die Werktage von Montag bis Freitag.
                Teillieferungen sind zulässig.
              </p>
              <p>
                7.3. Gerät SP ONE mit seiner Leistung in Verzug, so ist der Kunde berechtigt, SP ONE eine angemessene
                Frist zur Leistung oder Nacherfüllung zu setzen. Setzt der Kunde SP ONE die Frist erfolglos, so ist er
                berechtigt, vom Vertrag zurückzutreten, wenn der Verzug auf Vorsatz oder grober Fahrlässigkeit oder auf
                einer wesentlichen Pflichtverletzung beruht. Schadensersatzansprüche statt der Leistung in Höhe des
                vorhersehbaren Schadens stehen dem Kunden nur zu, wenn der Verzug auf Vorsatz oder grober Fahrlässigkeit
                oder auf einer wesentlichen Pflichtverletzung beruht.
              </p>
              <p>
                7.4. Kommt der Kunde in Annahmeverzug oder verletzt der Kunde sonstige Mitwirkungspflichten, so ist SP
                ONE berechtigt, Ersatz des SP ONE entstandenen Schadens, einschließlich etwaiger Mehraufwendungen, zu
                verlangen. In diesem Fall geht auch die Gefahr eines zufälligen Untergangs oder einer zufälligen
                Verschlechterung der Kaufsache in dem Zeitpunkt auf den Kunden über, in dem dieser in Annahmeverzug
                gerät.
              </p>
              <p>
                7.5. Die Gefahr des zufälligen Untergangs oder der zufälligen Verschlechterung des Vertragsgegenstands
                geht mit der Übergabe an den Spediteur, Frachtführer oder die sonst zur Ausführung der Versendung
                bestimmten Person, spätestens jedoch mit Verlassen des Werkes oder Lagers, auf den Kunden über. Dies
                gilt unabhängig davon, ob die Versendung vom Erfüllungsort erfolgt oder wer die Frachtkosten trägt.
              </p>
              <p>
                7.6. SP ONE behält sich das Eigentum an der Kaufsache bis zum Ausgleich aller offenen Forderungen aus
                der Geschäftsverbindung mit dem Kunden vor. Die entsprechenden Sicherungsrechte sind auf Dritte
                übertragbar.
              </p>
              <p>8. Lieferbedingungen</p>
              <p>
                8.1. Die für die Versendung der bestellten Ware möglicherweise anfallenden Kosten variieren je nach
                Lieferant. Die anfallenden Kosten werden je Lieferant getrennt im Warenkorb dargestellt.
              </p>
              <p>
                8.2. Die bestellten Waren werden entweder mit einem Paketdienst oder durch die eigene Logistik der
                Lieferanten zugestellt.
              </p>
              <p>
                8.3. SP ONE weist vorsorglich darauf hin, wenn bei der Anlieferung der bestellten Ware zwischen der
                tatsächlichen Abladestelle und dem tatsächlichen Aufstellungsort der Ware ein Hindernis, wie z.B.
                Stufen, zu überwinden ist, möglicherweise Zusatzkosten entstehen können.
              </p>
              <p>
                8.4. Der Kunde hat die Möglichkeit vor Abschluss des Bestellvorgangs auf der SP ONE Kunden
                Online-Plattform mit dem SP ONE Kundenservice via Telefon oder Live-Chat in Kontakt zu treten, um die
                Anlieferung und die tatsächlich anfallenden Kosten abzustimmen.
              </p>
              <p>9. Mängelrügen, Mängelhaftung, Reparaturen</p>
              <p>
                9.1. Mängelansprüche des Kunden setzen voraus, dass er seiner nach § 377 HGB geschuldete Untersuchungs-
                und Rügeobliegenheit ordnungsgemäß nachkommt.
              </p>
              <p>
                9.2. Der Kunde hat Mängelansprüche nur bei erheblichen Mängeln an neu hergestellten Sachen oder
                Leistungen. Eine Haftung für gebrauchte Sachen ist ausgeschlossen.
              </p>
              <p>9.3. Mängelansprüche bestehen nicht</p>
              <p>a) bei nur unerheblicher Abweichung von der vereinbarten Beschaffenheit</p>
              <p>b) bei nur unerheblicher Beeinträchtigung der Brauchbarkeit und</p>
              <p>
                c) bei natürlicher Abnutzung oder Verschleiß wie bei Schäden, die nach dem Gefahrenübergang infolge
                fehlerhafter oder nachlässiger Behandlung, übermäßiger Beanspruchung, ungeeigneter Betriebsmittel oder
                aufgrund besonderer äußerer Einflüssen entstehen.
              </p>
              <p>
                9.4. Bei Mängeln der Ware ist SP ONE gegenüber dem Kunden nach eigener Wahl zur Nacherfüllung durch die
                Beseitigung des Mangels oder Lieferung mangelfreier Ware berechtigt.
              </p>
              <p>
                9.5. Sofern SP ONE zur Nacherfüllung nicht in der Lage ist, kann der Kunde nach seiner Wahl vom Vertrag
                zurücktreten oder den Lieferpreis mindern. Dasselbe gilt, wenn die Nachlieferung fehlschlägt, dem Kunden
                unzumutbar ist oder sich aus Gründen, die SP ONE zu vertreten hat, nicht innerhalb einer angemessenen
                Frist möglich ist.
              </p>
              <p>9.6. Die Verjährungsfrist für Mängelansprüche beträgt 12 Monate, gerechnet ab Gefahrübergang.</p>
              <p>
                9.7. Ansprüche des Kunden wegen der zum Zwecke der Nacherfüllung erforderlichen Aufwendungen,
                insbesondere Transport-, Wege-, Arbeits- und Materialkosten, sind ausgeschlossen, soweit die
                Aufwendungen sich erhöhen, weil die von SPO gelieferte Ware nachträglich an einen anderen Ort als die
                Niederlassung des Kunden verbracht worden ist, es sei denn, die Verbringung entspricht ihrem
                bestimmungsgemäßen Verbrauch.
              </p>
              <p>10. Besonderheiten</p>
              <p>
                10.1. Die Mitarbeiter vor Ort sind nicht berechtigt, Zahlungen direkt an den Kooperationspartner von SP
                ONE zu leisten. Gleichwohl vorgenommene Zahlungen des Kunden unmittelbar an Mitarbeiter der
                Kooperationspartner von SP ONE werden nicht mit befreiender Wirkung zu Lasten von SP ONE geleistet.
                Solche Zahlungen lässt SP ONE also nicht gegen sich gelten.
              </p>
              <p>
                10.2. SP ONE hat das Recht, von Verträgen mit dem Kunden zurückzutreten, falls SP ONE – ohne dies
                jeweils vertreten zu müssen – keine Kapazitäten für die Erfüllung der vertraglichen Leistungen hat bzw.
                die vereinbarten Termine nicht einhalten kann, ohne dass SP ONE dies vorhersehen und/oder verhindern
                konnte.
              </p>
              <p>
                10.3. Ein Rücktrittsrecht steht SP ONE auch zu, wenn bei Vertragsschluss nicht erkennbare Umstände
                vorliegen, die einen Rücktritt unter Berücksichtigung eines anerkennenswerten Interesses von SP ONE
                rechtfertigen, z. B. in Fällen höherer Gewalt, Streik und Naturkatastrophen.
              </p>
              <p>
                10.4. SP ONE wird im Rücktrittsfall vom Kunden bereits geleistete Gegenleistungen unverzüglich
                erstatten.
              </p>
              <p>
                10.5. Die Aufrechnung des Kunden gegen Forderungen von SP ONE ist unzulässig, es sei denn, die
                Gegenforderung ist unbestritten oder rechtskräftig festgestellt.
              </p>
              <p>
                10.6. Eine Zurückbehaltung von Zahlungen aufgrund von Ansprüchen des Kunden gegen SP ONE, auch soweit
                sie auf einem anderen mit SP ONE abgeschlossenen Vertragsverhältnis beruhen, ist ausgeschlossen.
              </p>
              <p>11. Einhaltung Jugendschutz</p>
              <p>
                11.1. Der Verkauf von Artikeln, die gesetzlichen Verkaufsbeschränkungen unterliegen an nicht volljährige
                Personen, ist nicht möglich. Zur Sicherung der Einhaltung der entsprechenden gesetzlichen Vorschriften
                ist SP ONE bzw. der ausliefernde Mitarbeiter (Erfüllungsgehilfe) in Zweifelsfällen berechtigt, die
                Übergabe der Waren von der Vorlage eines Personalausweises abhängig zu machen.
              </p>
              <p>
                11.2. Sofern der Nachweis der Volljährigkeit des Empfängers nicht oder nicht zweifelsfrei erbracht
                werden kann, wird SP ONE die Abgabe der unter das Jugendschutzgesetz fallenden Waren verweigern.
              </p>
              <p>12. Pflichten des Kunden</p>
              <p>
                12.1. Der Kunde ist verpflichtet, die erforderlichen Informationen wie Leistungsort, Rechnungsadresse,
                Ansprechpartner und Kontaktdaten wahrheitsgemäß und vollständig zu erteilen sowie sonstige, für die
                Durchführung der Warenlieferung bzw. Dienstleistung relevanten Umstände mitzuteilen.
              </p>
              <p>
                12.2. Der Kunde ist verpflichtet, etwaige Änderungen seiner Angaben, die den Leistungsort als auch die
                Räumlichkeiten und andere leistungsrelevante Aspekte betreffen, SP ONE unverzüglich nach
                Kenntniserlangung mitzuteilen. Derartige Änderungen können zusätzliche Leistungen seitens SP ONE
                erfordern, die der Kunde entsprechend zu vergüten hat.
              </p>
              <p>
                12.3. Der Kunde ist verpflichtet, unter der zuletzt angegebenen Rufnummer (Mobil/ Festnetz) für SP ONE
                erreichbar zu sein, insbesondere an dem Tag vom avisierten Lieferdatum / Leistungsdatum.
              </p>
              <p>13. Vergütung und Zahlungsbedingungen</p>
              <p>
                13.1. Die dem Kunden genannten Preise bzw. die im Webshop dargestellten Preise verstehen sich exklusive
                der Umsatzsteuer. Die Mehrwertsteuer wird am Ende des Bestellprozesses auf den Warenkorb aufgeschlagen.
              </p>
              <p>
                13.2. Preiskorrekturen aufgrund von eventuellen Irrtümern und Preisänderungen behält sich SP ONE
                ausdrücklich vor.
              </p>
              <p>
                13.3. Soweit mit dem Kunden nicht anders vereinbart, sind Zahlungsforderungen von SP ONE am Ende eines
                jeden Monats, in dem die Leistungserbringung stattgefunden hat, fällig, spätestens mit
                Rechnungsstellung. Der Kunde hat ein Zahlungsziel von 14 Tagen. SP ONE behält sich das Recht vor, bei
                erheblichen Fremdkosten, die SP ONE aus dem einzelnen Auftrag entstehen, vom Kunden eine entsprechende
                Abschlagszahlung zu fordern.
              </p>
              <p>
                13.4. Es werden gegebenenfalls verschiedene Zahlungsmöglichkeiten angeboten (z.B. Zahlung auf Rechnung,
                Kreditkarte oder Lastschrift), ohne dass SP ONE hierzu jedoch verpflichtet ist. Für die
                Zahlungsabwicklung über Zahlungssystemanbieter (z.B. Kreditkarte) gelten ausschließlich die Nutzungs-
                und Geschäftsbedingungen des betreffenden Zahlungssystemanbieters. Wurde zur Zahlung das SEPA
                Lastschriftverfahren vereinbart, so ist SP ONE dazu verpflichtet, dem Kunden Betrag und Belastungsdatum
                im Vorfeld mitzuteilen. Die Vorinformation (Pre-Notification) erfolgt spätestens einen Werktag vor
                Kontobelastung. Mit der Wahl des SEPA-Lastschriftverfahrens erklärt sich der Kunde damit einverstanden,
                dass die Vorabinformationsfrist (Pre-Notification) von 14 Kalendertagen auf einen Werktag vor Fälligkeit
                verkürzt wird.
              </p>
              <p>
                13.5. Für jede Art von Gutschriften oder die Abrechnung von Mehraufwänden hat SP ONE die Wahl, diese
                über die ursprünglich vom Kunden ausgewählte Zahlungsmethode oder per Rechnung abzurechnen.
              </p>
              <p>
                13.6. Der Kunde erhält eine Rechnung am Ende des Monats über alle erbrachten Leistungen der SP ONE in
                elektronischer Form per E-Mail an die von ihm genannte E-Mail-Adresse.
              </p>
              <p>
                13.7. SP ONE behält sich das Recht vor mit einem Zahlungsdienstleister zusammenzuarbeiten. In diesem
                Fall setzt der Kauf auf Rechnung unter anderem eine erfolgreiche Bonitätsprüfung durch den
                Zahlungsdienstleister voraus. Wird dem Kunden der Kauf auf Rechnung gestattet, erfolgt die Abwicklung
                der Zahlung in Zusammenarbeit mit dem Zahlungsdienstleister und dem mit dem Zahlungsdienstleister
                zusammenarbeitenden Factor, an den SP ONE seine Zahlungsforderungen abtritt. Der Kunde kann in diesem
                Fall nur an den Factor die Zahlung mit schuldbefreiender Wirkung leisten. SP ONE bleibt bei dem Kauf auf
                Rechnung über einen Zahlungsdienstleister weiterhin für allgemeine Kundenanfragen zuständig.
              </p>
              <p>14. Verfügbarkeit der Software und Sicherung der Daten</p>
              <p>
                14.1. SP ONE bemüht sich, die Verfügbarkeit der Software möglichst durchgehend und fehlerfrei zu
                gewährleisten. Der Kunde erkennt jedoch an, dass bereits aus technischen Gründen und aufgrund der
                Abhängigkeit von äußeren Einflüssen, z.B. im Rahmen der Fernmeldenetze, eine ununterbrochene
                Verfügbarkeit nicht sichergestellt werden kann.
              </p>
              <p>
                14.2. SP ONE führt an den eigenen Servern zur Sicherstellung des Betriebes und ggf. zum Zwecke der
                Erweiterung der eigenen Angebote gelegentlich Wartungsarbeiten durch, die zu einer vorübergehenden
                Beeinträchtigung der Nutzbarkeit der Software führen können. SP ONE wird die Wartungsarbeiten, sofern
                möglich, in besonders nutzungsarmen Zeiten durchführen.
              </p>
              <p>
                14.3. Der Kunde ist für die wiederkehrende Sicherung seiner Daten selbst verantwortlich. Resultieren
                Schäden des Kunden aus dem Verlust von Daten, so haftet SP ONE hierfür, auch wenn ein
                haftungsbegründendes Ereignis vorliegt, nur, soweit die Schäden auch durch eine wiederkehrende Sicherung
                aller relevanten Daten durch den Kunden nicht vermieden worden wären.
              </p>
              <p>15. Haftung von SP ONE, Haftungsausschluss- und Begrenzungsgründe</p>
              <p>15.1. SP ONE haftet grundsätzlich nach den gesetzlichen Vorschriften.</p>
              <p>
                15.2. SP ONE ist von der Haftung befreit, soweit Beschädigungen oder die Überschreitungen der
                Leistungsfrist auf Umständen beruht, die SP ONE auch bei größter Sorgfalt nicht vermeiden und deren
                Folgen SP ONE nicht abwenden konnte.
              </p>
              <p>
                15.3. Die Haftung von SP ONE wegen Überschreitung der Leistungsfrist ist auf den dreifachen Betrag der
                Vergütung für die vereinbarte Warenlieferung oder Dienstleistung begrenzt.
              </p>
              <p>
                15.4. Haftet SP ONE wegen der Verletzung einer mit der Ausführung der Warenlieferung bzw. Dienstleistung
                zusammenhängenden vertraglichen Pflicht für Schäden, die nicht durch Verlust oder Beschädigung des Gutes
                oder durch Überschreitung der Leistungsfrist entstehen, und handelt es sich um andere Schäden als Sach-
                oder Personenschäden, so ist auch in diesem Falle die Haftung begrenzt, und zwar auf das Dreifache der
                Vergütung für die erbrachte Warenlieferung bzw. Dienstleistung.
              </p>
              <p>
                15.5. Der Kunde wird hiermit über die Möglichkeit unterrichtet, eine weitergehende Haftung zu
                vereinbaren oder zu versichern.
              </p>
              <p>
                15.6. Ansprüche des Kunden gegen SP ONE wegen Verlust oder Beschädigung der Räumlichkeiten oder von
                Inventar entfallen, wenn der Verlust oder die Beschädigung der Sachen äußerlich erkennbar war und SP ONE
                nicht spätestens am Tag nach der Leistung angezeigt worden ist, wenn der Verlust oder die Beschädigung
                äußerlich nicht erkennbar war und SP ONE nicht innerhalb von vierzehn Tagen nach Ablieferung angezeigt
                worden ist.
              </p>
              <p>
                15.7. Die Anzeige des Kunden muss den Verlust oder die Beschädigung hinreichend deutlich kennzeichnen.
                Eine Schadensanzeige ist durch den Kunden in Textform zu erstatten. Der Kunde kann für die
                Schadensanzeige das auf der Webseite hierfür von SP ONE zur Verfügung gestellte Formular verwenden.
              </p>
              <p>16. Ergänzende Bestimmungen im Bereich Obst</p>
              <p>
                16.1. SP ONE sichert zu, dass die bestellten Früchte ihrer Eigenart entsprechend sorgfältig und den
                gesetzlichen Vorschriften entsprechend zu transportieren. Etwaige Mängel oder Reklamationen, die
                vorkommen können, müssen unverzüglich nach Auslieferung der Früchte geltend gemacht werden, da es sich
                um verderbliche Ware handelt. Dazu muss unmittelbar nach Anlieferung eine E-Mail mit einem Foto als
                Nachweis an SP ONE gesendet werden oder über die SP ONE Feedback Funktion.
              </p>
              <p>16.2. Die angegebenen Gewichtsangaben bei den Produkten gelten als ca.-Angabe.</p>
              <p>
                16.3. Da es sich bei der bestellten Ware um eine verderbliche Ware im Sinne des § 312d Abs. 4 Ziffer 1
                BGB handelt, steht dem Kunden kein Recht auf Rückgabe zu.
              </p>
              <p>17. Ergänzende Bestimmungen im Bereich Getränke</p>
              <p>
                17.1. Die auf der SP ONE Kunden Online-Plattform im Shop ausgewiesene Getränkepreise sind Nettopreise
                zzgl. Pfand und gesetzlicher Umsatzsteuer.
              </p>
              <p>
                17.2. SP ONE verpflichtet sich lediglich bepfandetes Mehrweg-Leergut maximal im Umfang des gelieferten
                Pfandguts zurückzunehmen. Einzelleergut sowie nicht-bepfandetes Leergut wird nicht zurückgenommen.
              </p>
              <p>
                17.3. Pfandgutschriften werden auf der monatlichen Rechnung separat ausgewiesen und entsprechend
                verrechnet.
              </p>
              <p>
                17.4. Eine Rückgabe (Rücknahme oder Umtausch), auf die kein gesetzlicher Rechtsanspruch besteht, ist
                ausgeschlossen.
              </p>
              <p>18. Ergänzende Bestimmungen im Bereich Bürobedarf und Verbrauchsmaterialien</p>
              <p>
                18.1. Eine Rückgabe (Rücknahme oder Umtausch), auf die kein gesetzlicher Rechtsanspruch besteht, ist
                ausgeschlossen. Soweit bei den Artikeln Rückgaberechte ausgewiesen sind, gehen die den AGB von SP ONE
                vor.
              </p>
              <p>
                18.2. Zur Ausführung des Rückgaberechts hat der Kunde die Möglichkeit, über den SP ONE Kundenservice
                einen Retoure-Schein anzufordern, der zum kostenlosen Rückversand der Ware zum entsprechenden
                Lieferanten berechtigt.
              </p>
              <p>
                18.3. Die Gefahr des Untergangs und der Verschlechterung der vom Kunden zurückgesandten Ware trägt der
                Kunde bis zur Übergabe an den von SP ONE benannten Lieferanten.
              </p>
              <p>19. Ergänzende Bestimmungen im Bereich Kaffee und Kaffeemaschinen</p>
              <p>
                19.1. SP ONE behält sich vor, die Liefermenge bei Großbestellungen von Kaffee möglicherweise zu
                beschränken bzw. in mehreren Teillieferungen auszuliefern.
              </p>
              <p>
                19.2. Unterzeichnet der Kunde einen über SP ONE vermittelten Leasingvertrag zu einer Kaffeemaschine, so
                gelten die Allgemeinen Geschäftsbedingungen des Leasinggebers.
              </p>
              <p>
                19.3. Kauft der Kunde einen Kaffeevollautomaten über SP ONE, so gelten die Garantiebestimmungen des
                jeweiligen Kooperationspartners, sowie die Haftungsbestimmungen gemäß §15.
              </p>
              <p>20. Datenschutz</p>
              <p>
                20.1. Alle Informationen zur Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten bei der
                Webseite finden sich in der Datenschutzerklärung.
              </p>
              <p>
                20.2. SP ONE übermittelt ggf. personenbezogene Daten der Kunden an Dritte, soweit dies für die Zwecke
                der Durchführung des jeweils geschlossenen Vertrages erforderlich ist.
              </p>
              <p>21. Änderungen der Allgemeinen Geschäftsbedingungen</p>
              <p>
                21.1. SP ONE behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen mit Wirkung für die
                Zukunft zu ändern.
              </p>
              <p>
                21.2. Zumutbare Änderungen dieser Allgemeinen Geschäftsbedingungen werden dem Kunden schriftlich oder in
                Textform mitgeteilt. Die Änderungen gelten als genehmigt, wenn der Kunde nicht schriftlich oder in
                Textform widerspricht. Der Widerspruch muss innerhalb von vier Wochen nach Zugang der Mitteilung
                eingegangen sein. Widerspricht der Kunde einer Änderung, kann SP ONE von einer Fortführung des
                Vertragsverhältnisses absehen.
              </p>
              <p>22. Schlussbestimmungen</p>
              <p>
                22.1. Auf Verträge zwischen SP ONE und den Kunden findet das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts und des deutschen bzw. europäischen Kollisionsrechts Anwendung.
              </p>
              <p>
                22.2. Der Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und SP ONE
                ist Berlin.
              </p>
              <p>
                22.3. Sollte eine Bestimmung dieses Vertrages ganz oder teilweise unwirksam sein oder werden, so bleibt
                die Wirksamkeit des Vertrages im Übrigen hiervon unberührt. Die Parteien verpflichten sich, anstelle der
                unwirksamen Bestimmung eine wirksame Regelung zu vereinbaren, die nach Form, Inhalt und Maß dem am
                nächsten kommt, was nach dem Sinn und wirtschaftlichen Erfolg der unwirksamen Bestimmung von den
                Vertragsschließenden beabsichtigt war. Das Gleiche gilt im Falle einer Lücke.
              </p>
            </div>
            <div className="text-right">
              <button type="button" className="button-submit terms__button" onClick={this.handleAcceptButtonClick}>
                {t('agree')}
              </button>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Terms.propTypes = {
  t: PropTypes.func.isRequired,
  submitAcceptTerms: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  current: PropTypes.object.isRequired
};

const mapStateToProps = ({ customer, current }) => ({ customer, current });

const mapDispatchToProps = dispatch => ({
  submitAcceptTerms: () => dispatch(acceptTerms())
});

export { Terms as PureComponent };
export default pure(
  withTranslation('Terms')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Terms)
  )
);
