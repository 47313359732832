import React from 'react';
import PropTypes from 'prop-types';

import './InputGroup.less';

export class InputGroup extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    return <div className="input-group">{this.props.children}</div>;
  }
}

export default InputGroup;
