import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { rateServiceEvent } from 'actions/serviceEvents';

import './RatingBox.less';

const createState = ({ item: { rating_value: ratingValue = 0, rating_message: ratingMessage = '' } = {} }) => ({
  ratingValue,
  ratingMessage,
  hoverValue: 0
});

class RatingBox extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.item !== nextProps.item) {
      this.setState(createState(nextProps));
    }
  }

  handleClickOutside() {
    this.props.closeBox();
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, item } = this.props;
    const { ratingValue, ratingMessage } = this.state;

    onSubmit(item.id, ratingValue, ratingMessage);
  }

  render() {
    const { isRating, t } = this.props;
    const { ratingValue, ratingMessage, hoverValue } = this.state;
    const starValue = hoverValue || ratingValue;
    const ratingStars = [1, 2, 3, 4, 5];
    const isDisabled = !ratingValue && !ratingMessage;

    return (
      <form onSubmit={this.handleSubmit} className="rating-box">
        <fieldset disabled={isRating}>
          <div className="rating-stars">
            {ratingStars.map(value => (
              <button
                name="ratingValue"
                value={value}
                key={`star-${value}`}
                type="button"
                className={`rating-star ${starValue >= value ? 'active' : ''}`}
                onClick={this.handleChange}
                onMouseEnter={() => this.handleChange({ target: { name: 'hoverValue', value } })}
                onMouseLeave={() => this.handleChange({ target: { name: 'hoverValue', value: 0 } })}
              >
                &nbsp;
              </button>
            ))}
          </div>

          <textarea
            name="ratingMessage"
            value={ratingMessage || ''}
            onChange={this.handleChange}
            className="rating-message"
            rows="3"
            placeholder={t('message')}
          />

          <button
            className={`rating-submit ${isDisabled ? 'disabled' : ''} ${isRating ? 'loading' : ''}`}
            type="submit"
          >
            {t('send')}
          </button>
        </fieldset>
      </form>
    );
  }
}

RatingBox.propTypes = {
  isRating: PropTypes.bool,
  item: PropTypes.object.isRequired,
  closeBox: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { rate } }, { item: { id } }) => ({
  isRating: !!(rate[id] && rate[id].isRating)
});

const mapDispatchToProps = {
  onSubmit: rateServiceEvent
};

export default withTranslation('Common/ServiceEvent/RatingBox')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(onClickOutside(RatingBox))
);
