import { createSelector } from 'reselect';

const getSelectedFilterTypes = state => state.serviceEvents.filters.selected.types;

const getAllCosts = ({
  serviceEventDefinitions: { costs }
}, props) => costs[props.dataKey] && costs[props.dataKey].items;

const calculateSum = items => items.map(item => item.cost).reduce((a, b) => a + b, 0);

export default createSelector(
  [getSelectedFilterTypes, getAllCosts], (filters, costs) => {
    if (!costs) return 0;
    const actualCosts = !filters.length ? costs : costs.filter(item => filters.includes(item.type));
    return calculateSum(actualCosts);
  },
);
