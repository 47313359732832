import { toast } from 'react-toastify';
import {
  ISSUES_FETCH,
  ISSUES_FETCH_SUCCESS,
  ISSUES_FETCH_FAILURE,
  ISSUES_CREATE,
  ISSUES_CREATE_SUCCESS,
  ISSUES_CREATE_FAILURE,
  ISSUES_EDIT,
  ISSUES_EDIT_SUCCESS,
  ISSUES_EDIT_FAILURE,
  ISSUES_SEARCH,
  ISSUES_SEARCH_SUCCESS,
  ISSUES_SEARCH_FAILURE,
  ISSUES_FETCH_ISSUE,
  ISSUES_FETCH_ISSUE_SUCCESS,
  ISSUES_FETCH_ISSUE_FAILURE,
  ISSUES_FETCH_STATS,
  ISSUES_FETCH_STATS_SUCCESS,
  ISSUES_FETCH_STATS_FAILURE
} from 'constants/actionTypes';
import { X_TOTAL_COUNT } from 'constants/headers';

import i18next from '../../i18next';
import { updateObjectInArray } from '../helper';

const t = i18next.getFixedT(null, 'Issues');

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    numberOfItems: 0,
    showPerPage: 20,
    items: [],
    stats: {}
  },
  action
) => {
  switch (action.type) {
    case ISSUES_SEARCH:
    case ISSUES_FETCH_ISSUE:
    case ISSUES_FETCH:
    case ISSUES_EDIT:
    case ISSUES_CREATE:
    case ISSUES_FETCH_STATS:
      return { ...state, isFetching: true, fetchedAt: null };

    case ISSUES_SEARCH_SUCCESS:
    case ISSUES_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload,
        numberOfItems: parseInt(action.response && action.response.headers.get(X_TOTAL_COUNT), 10) || 0
      };

    case ISSUES_FETCH_STATS_FAILURE:
    case ISSUES_SEARCH_FAILURE:
    case ISSUES_FETCH_ISSUE_FAILURE:
    case ISSUES_FETCH_FAILURE:
    case ISSUES_EDIT_FAILURE:
    case ISSUES_CREATE_FAILURE:
      return { ...state, isFetching: false };

    case ISSUES_FETCH_ISSUE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        issue: action.payload
      };

    case ISSUES_CREATE_SUCCESS:
      toast.success(t('success-create'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case ISSUES_EDIT_SUCCESS:
      toast.success(t('success-edit'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case ISSUES_FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload
      };

    default:
      return state;
  }
};
