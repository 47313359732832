import React from 'react';
import { pure } from 'recompose';

const TabContent = props => (
  <div className="tab-content">
    {props.children}
  </div>
);

export default pure(TabContent);
