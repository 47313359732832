import { AUTH_LOGOUT_REQUEST, AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case AUTH_LOGOUT_REQUEST:
      return { ...state, isFetching: true };

    case AUTH_LOGOUT_SUCCESS:
    case AUTH_LOGOUT_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
