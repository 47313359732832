/* eslint-disable no-return-assign */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UploadSection extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.logo && !this.props.logo) {
      this.uploadLogo.value = '';
    }
  }

  handleLoadLocalFile(event) {
    event.preventDefault();
    const { files } = event.target;
    const localImageUrl = window.URL.createObjectURL(files[0]);
    const logoFile = files[0];

    this.props.changeLogo(localImageUrl, logoFile);
  }

  render() {
    const { title, logo } = this.props;
    const customLogoStyle = logo ? { backgroundImage: `url(${logo})` } : null;

    return (
      <div className="menu-pannel">
        <div className="menu-pannel-title">{title}</div>
        <div className="menu-pannel-upload">
          <div className="logo-preview" style={customLogoStyle} />

          <div className="upload-input-group">
            <input
              ref={el => (this.uploadLogo = el)}
              id="uploadLogo"
              type="file"
              onChange={event => this.handleLoadLocalFile(event)}
              accept="image/jpeg, image/jpg, image/png, image/x-png"
            />
            <label htmlFor="uploadLogo">{this.props.uploadInputText}</label>
          </div>
        </div>
      </div>
    );
  }
}

UploadSection.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.any
};

export { UploadSection as PureComponent };
export default UploadSection;
