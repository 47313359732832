import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchRequestedShippingAddress } from 'actions/customer';
import { ShippingAddressSuccess, ShippingAddressForm } from '.';

import './ShippingAddress.less';

const createState = state => ({
  street: state.street || '',
  postalCode: state.postalCode || '',
  city: state.city || '',
  shippingAddressTicket: state.shippingAddressTicket || { items: {} },
  wantAddressChange: false
});

export class ShippingAddress extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);
    this.addressChangeInit = this.addressChangeInit.bind(this);
  }

  componentWillMount() {
    this.props.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.propsHaveChanged(nextProps)) {
      this.setState(createState(nextProps));
    }
  }

  get hasChanged() {
    return this.propsHaveChanged(this.state);
  }

  propsHaveChanged(nextProps) {
    return (
      this.props.street !== nextProps.street ||
      this.props.postalCode !== nextProps.postalCode ||
      this.props.city !== nextProps.city ||
      this.props.shippingAddressTicket !== nextProps.shippingAddressTicket
    );
  }

  addressChangeInit() {
    this.setState({ wantAddressChange: true });
  }

  render() {
    const { country, shippingRequested, t } = this.props;
    const { street, city, postalCode, shippingAddressTicket, wantAddressChange } = this.state;
    const addressRequested = !!shippingAddressTicket.items.body || shippingRequested;

    return (
      <div className="company-address">
        {addressRequested ? (
          <ShippingAddressSuccess
            street={street}
            city={city}
            postalCode={postalCode}
            country={t(country)}
            currentAddress={t('current-address')}
            addressSuccess={t('address-success')}
            addressSoon={t('address-soon')}
            newAddress={t('new-address')}
            ticket={shippingAddressTicket.items.body}
          />
        ) : (
          <div>
            {!wantAddressChange ? (
              <div className="shipping-address-display">
                <p>{street}</p>
                <p>
                  {postalCode} {city}, {t(country)}
                </p>
                <button type="button" className="shipping-address-request" onClick={this.addressChangeInit}>
                  {t('request-change')}
                </button>
              </div>
            ) : (
              <ShippingAddressForm />
            )}
          </div>
        )}
      </div>
    );
  }
}

ShippingAddress.propTypes = {
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  shippingAddressTicket: PropTypes.object,
  shippingRequested: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  current: { customer },
  customer: {
    shippingAddress: { isChanged: shippingRequested }
  },
  customer: { shippingAddressTicket }
}) => {
  const { street, postal_code: postalCode, city, country_code: country } =
    (customer && customer.shipping_address) || {};
  return { shippingAddressTicket, street, postalCode, city, country, shippingRequested };
};

const mapDispatchToProps = {
  fetchData: fetchRequestedShippingAddress
};

export default withTranslation('Settings/Customer/ShippingAddressForm')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShippingAddress)
);
