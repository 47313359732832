import { ga, createAnalytics, createHeap } from '.';

export default function configureTracking(history) {
  createAnalytics(process.env.REACT_APP_SEGMENT_WRITE_KEY);
  createHeap(process.env.REACT_APP_HEAP_ID);

  if (ga.create(process.env.REACT_APP_GOOGLE_ANALYTICS)) {
    history.listen(location => {
      ga.set('page', location.pathname);
      ga.send('pageview');
    });
  }
}
