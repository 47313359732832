import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { WithCustomer } from 'components/DI';
import { Loader, YearPicker } from 'common';
import { fetchDueInvoices, fetchPaidInvoices } from 'actions/invoices';
import { groupInvoicesByYear } from 'selectors/invoices';
import { Invoice } from '.';

import './Invoices.less';

export class Invoices extends Component {
  constructor(props) {
    super(props);

    const date = new Date();
    this.state = { year: date.getFullYear() };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchDueInvoices();
    this.props.fetchPaidInvoices();
  };

  handleYearChange = selectedYear => {
    this.setState(() => ({ year: selectedYear }));
  };

  renderDueInvoicesByYear = (items, year) => {
    const { t } = this.props;
    return (
      <div className="invoice-entries">
        <div className="invoice-headline">{t('due')}</div>
        {!items[year] ? (
          <p className="no-items">{t('no-items')}</p>
        ) : (
          <div>
            <div className="invoice-header">
              <span className="date col-2">{t('date')}</span>
              <span className="due-date col-2">{t('due-date')}</span>
              <span className="invoice-number col-2">{t('number')}</span>
              <span className="status col-2">{t('status')}</span>
              <span className="amount col-2">
                <span className="amount-text">{t('amount')}</span>
              </span>
            </div>
            <div className="separator" />
            {items[year].map(invoice => (
              <Invoice key={invoice.number} invoice={invoice} t={t} />
            ))}
          </div>
        )}
      </div>
    );
  };

  renderPaidInvoicesByYear = (items, year) => {
    const { t } = this.props;
    return (
      <div className="invoice-entries">
        <div className="invoice-headline">{t('paid')}</div>
        {!items[year] ? (
          <p className="no-items">{t('no-items')}</p>
        ) : (
          <div>
            <div className="invoice-header">
              <span className="date col-4">{t('date')}</span>
              <span className="invoice-number col-4">{t('number')}</span>
              <span className="amount col-2">
                <span className="amount-text">{t('amount-paid')}</span>
              </span>
            </div>
            <div className="separator" />
            {items[year].map(invoice => (
              <Invoice key={invoice.number} invoice={invoice} isPaid t={t} />
            ))}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isFetching, paidInvoices, dueInvoices } = this.props;

    return (
      <div className="invoices-list">
        <WithCustomer onChange={this.fetchData} className="invoices col-12">
          <YearPicker handleYearChange={this.handleYearChange} />
          <Loader isLoading={isFetching} />
          <div className="invoice-details">
            {dueInvoices && this.renderDueInvoicesByYear(dueInvoices, this.state.year)}
          </div>
          <div className="invoice-details">
            {paidInvoices && this.renderPaidInvoicesByYear(paidInvoices, this.state.year)}
          </div>
        </WithCustomer>
      </div>
    );
  }
}

Invoices.propTypes = {
  t: PropTypes.func.isRequired,
  paidInvoices: PropTypes.object,
  dueInvoices: PropTypes.object
};

const mapStateToProps = state => ({
  isFetching: state.invoices.paid.isFetching || state.invoices.due.isFetching,
  paidInvoices: groupInvoicesByYear(state.invoices.paid),
  dueInvoices: groupInvoicesByYear(state.invoices.due)
});

const mapDispatchToProps = {
  fetchDueInvoices,
  fetchPaidInvoices
};

export default withTranslation('Settings/Invoices')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Invoices)
);
