import {
  SHOP_CART_COUPON_ADD_REQUEST,
  SHOP_CART_COUPON_ADD_SUCCESS,
  SHOP_CART_COUPON_ADD_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default coupon =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT && !state.shop.cart.isAddingCoupon,
    types: [SHOP_CART_COUPON_ADD_REQUEST, SHOP_CART_COUPON_ADD_SUCCESS, SHOP_CART_COUPON_ADD_FAILURE],
    endpoint: '/user/shop/cart/discount',
    method: 'POST',
    body: { coupon }
  });
