import { FETCH_OFFER_REQUEST, FETCH_OFFER_SUCCESS, FETCH_OFFER_FAILURE } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_OFFER_REQUEST: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isFetching: true, fetchedAt: null } };
    }

    case FETCH_OFFER_SUCCESS: {
      const {
        meta: { token }
      } = action;

      return {
        ...state,
        [token]: { ...state[token], isFetching: false, fetchedAt: new Date(), item: action.payload }
      };
    }

    case FETCH_OFFER_FAILURE: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isFetching: false } };
    }

    default:
      return state;
  }
};
