import { combineReducers } from 'redux';
import entities from './entities';
import list from './list';
import single from './single';

export default combineReducers({
  entities,
  list,
  single
});
