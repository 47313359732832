import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LOCATION_QUERY_SED } from 'constants/location';
import { RatingButton, NoteButton, ResumeButton, ReorderLink, RatingLink, DepositButton } from '.';

import './ServiceEvent.less';

const ServiceEvent = ({ item, pathname, withButtons, t, inverse, rateBox }) => {
  const className = classNames(`se ${item.status}`, {
    'se-header': inverse
  });
  const location = `${pathname}?${LOCATION_QUERY_SED}=${item.definition_id}#${item.id}`;
  const isRateable = item.isRateable();
  const isNoteable = item.isNoteable();
  const isDrinks = item.type === 'drinks';
  const hasThreeButtons = withButtons && (isRateable || isNoteable);
  const trackableUrl = item && item.tracking_url;

  return (
    <div className={className}>
      <div className={`se-date ${inverse ? item.type : ''}`}>
        <div className="se-day">{item.start_date.format('D')}</div>
        <div className="se-month">{item.start_date.format('MMM')}</div>
        <span className={`se-circle ${!inverse ? item.type : ''}`} />
      </div>

      <div className="se-body-wrap flex">
        <div className={`se-body ellipsis ${hasThreeButtons ? 'reorder-pad' : ''}`}>
          <Link className="se-link" to={location}>
            &nbsp;
          </Link>

          <div className="flex se-headline-wrap">
            <span className="headline">{item.name}</span>

            {item.staff && <p className="subline">{t('by-staff', item.staff)}</p>}
          </div>
        </div>

        {trackableUrl && (
          <button
            type="button"
            className="event-track-button"
            onClick={e => {
              e.preventDefault();
              window.open(trackableUrl, '_blank');
            }}
          >
            {t('track-order')}
          </button>
        )}

        <div className="se-buttongroup">
          <div className="se-button-container">
            <ResumeButton item={item} />
            {isRateable && rateBox && <RatingButton item={item} />}
            {isRateable && !rateBox && <RatingLink item={item} />}
            {isNoteable && <NoteButton item={item} />}
            {withButtons && <ReorderLink item={item} location={location} />}
            {isDrinks && <DepositButton item={item} />}
          </div>
        </div>
      </div>
    </div>
  );
};

ServiceEvent.propTypes = {
  item: PropTypes.object.isRequired,
  withButtons: PropTypes.bool,
  t: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  rateBox: PropTypes.bool
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default withTranslation('Common/ServiceEvent')(connect(mapStateToProps)(ServiceEvent));
