import {
  SHOP_CART_PAYMENT_METHOD_SELECT,
  SHOP_CART_PAYMENT_CREDIT_CARD_SELECT,
  SHOP_CART_ORDER_SUCCESS
} from 'constants/actionTypes';
import { PAYMENT_METHOD_CREDIT_CARD } from '../../../../constants/paymentMethods';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_PAYMENT_CREDIT_CARD_SELECT:
      return action.id;

    case SHOP_CART_PAYMENT_METHOD_SELECT:
      if (action.method !== PAYMENT_METHOD_CREDIT_CARD) {
        return initialState;
      }

      return state;

    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
