import {
  SERVICE_EVENT_DEFINITION_REQUEST,
  SERVICE_EVENT_DEFINITION_SUCCESS,
  SERVICE_EVENT_DEFINITION_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_DEFINITION_REQUEST: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return {
        ...state,
        [serviceEventDefinitionId]: { ...state[serviceEventDefinitionId], isFetching: true, fetchedAt: null }
      };
    }

    case SERVICE_EVENT_DEFINITION_SUCCESS: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return {
        ...state,
        [serviceEventDefinitionId]: {
          ...state[serviceEventDefinitionId],
          isFetching: false,
          fetchedAt: new Date(),
          item: action.payload.id
        }
      };
    }

    case SERVICE_EVENT_DEFINITION_FAILURE: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return { ...state, [serviceEventDefinitionId]: { ...state[serviceEventDefinitionId], isFetching: false } };
    }

    default:
      return state;
  }
};
