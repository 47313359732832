import { stepOne, stepTwo } from ".";

export default body => (dispatch, getState) => {
  const { register: { step } } = getState();

  switch (step) {
    case 2:
      return dispatch(stepTwo(body));

    default:
      return dispatch(stepOne(body));
  }
};
