import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import classNames from 'classnames';
import { ShapedOptions, RadioButton, InputGroup, InputGroupAddon } from 'common/Form';
import { updateCustomer } from 'actions/customer';
import { notifyError } from 'actions/notifications';
import { officeDataRequiredForm, officeDataAdditionalForm } from './Config';

import './Customer.less';

export class Customer extends Component {
  constructor(props) {
    super(props);

    const customer = props.customer || {};
    this.state = {
      buildingType: customer.building_type,
      contactPersonFirstname: customer.primary_contact ? customer.primary_contact.firstname : '',
      contactPersonLastname: customer.primary_contact ? customer.primary_contact.lastname : '',
      floorSpace: customer.floor_space,
      floorToBeCleaned: customer.floor,
      floorType: customer.floor_type,
      hasAirConditioner: customer.has_ac,
      hasElevator: customer.elevator,
      heaterType: customer.heater_type,
      lockType: customer.entrance_lock,
      numberOfEmployees: customer.number_of_employees,
      numberOfWindows: customer.number_of_windows,
      phoneNumber: customer.primary_contact ? customer.primary_contact.phone : '',
      openingHoursFrom: customer.business_hour_from && moment(customer.business_hour_from, 'HH:mm').format('HH:mm'),
      openingHoursTo: customer.business_hour_until && moment(customer.business_hour_until, 'HH:mm').format('HH:mm'),
      numberOfBathrooms: customer.number_of_bathrooms,
      numberOfKitchens: customer.number_of_kitchens
    };
  }

  componentWillReceiveProps(nextProps) {
    const { customer } = nextProps;

    if (!customer) return;

    this.setState(() => ({
      buildingType: customer.building_type,
      contactPersonFirstname: customer.primary_contact ? customer.primary_contact.firstname : '',
      contactPersonLastname: customer.primary_contact ? customer.primary_contact.lastname : '',
      floorSpace: customer.floor_space,
      floorToBeCleaned: customer.floor,
      floorType: customer.floor_type,
      hasAirConditioner: customer.has_ac,
      hasElevator: customer.elevator,
      heaterType: customer.heater_type,
      lockType: customer.entrance_lock,
      numberOfEmployees: customer.number_of_employees,
      numberOfWindows: customer.number_of_windows,
      phoneNumber: customer.primary_contact ? customer.primary_contact.phone : '',
      openingHoursFrom: customer.business_hour_from && moment(customer.business_hour_from, 'HH:mm').format('HH:mm'),
      openingHoursTo: customer.business_hour_until && moment(customer.business_hour_until, 'HH:mm').format('HH:mm'),
      numberOfBathrooms: customer.number_of_bathrooms,
      numberOfKitchens: customer.number_of_kitchens
    }));
  }

  onTimeChange = (e, state) => {
    if (!e) return;

    this.setState(() => ({ [state]: e.format('HH:mm') }));
  };

  validateNumberValues = value => {
    if (/^\d*$/.test(value) === false) {
      // show notification
      this.props.notifyError(this.props.t('error-not-integer'));
      return false;
    }

    if (parseInt(value, 10) >= 100000) {
      // show notification
      this.props.notifyError(this.props.t('error-size-exceeded'));
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (
      !this.validateNumberValues(this.state.floorSpace) ||
      !this.validateNumberValues(this.state.floorToBeCleaned) ||
      !this.validateNumberValues(this.state.numberOfEmployees) ||
      (this.state.numberOfWindows && !this.validateNumberValues(this.state.numberOfWindows)) ||
      (this.state.numberOfBathrooms && !this.validateNumberValues(this.state.numberOfBathrooms)) ||
      (this.state.numberOfKitchens && !this.validateNumberValues(this.state.numberOfKitchens))
    )
      return false;

    const customerData = {
      building_type: this.state.buildingType,
      contact_firstname: this.state.contactPersonFirstname,
      contact_lastname: this.state.contactPersonLastname,
      contact_phone: this.state.phoneNumber,
      floor_space: this.state.floorSpace,
      floor: this.state.floorToBeCleaned,
      floor_type: this.state.floorType,
      has_ac: this.state.hasAirConditioner,
      elevator: this.state.hasElevator,
      heater_type: this.state.heaterType,
      entrance_lock: this.state.lockType,
      number_of_employees: this.state.numberOfEmployees,
      number_of_windows: this.state.numberOfWindows,
      business_hour_from: this.state.openingHoursFrom,
      business_hour_until: this.state.openingHoursTo,
      number_of_bathrooms: this.state.numberOfBathrooms,
      number_of_kitchens: this.state.numberOfKitchens
    };

    return this.props.updateCustomer(customerData);
  };

  handleFieldValueChange = (e, field) => {
    const { value } = e.target;
    const { name } = field;

    let newValue = value;
    if (name === 'hasElevator' || name === 'hasAirConditioner') {
      newValue = value === 'true';
    }

    if (
      [
        'floorSpace',
        'floorToBeCleaned',
        'numberOfEmployees',
        'numberOfWindows',
        'numberOfBathrooms',
        'numberOfKitchens'
      ].indexOf(name) !== -1
    ) {
      if (!this.validateNumberValues(value)) return false;
    }

    return this.setState(() => ({ [name]: newValue }));
  };

  handleShapedOptionClick = (state, option) => {
    const { value } = option;
    this.setState(() => ({ [state]: value }));
  };

  renderInputField = (t, field, isRequired = true) => (
    <div className={`company-details-formrow ${field.className}`} key={field.id}>
      <label htmlFor={field.id} className="company-details__label">
        {t(field.label)}
      </label>
      <InputGroup>
        <input
          type={field.type}
          min={field.min}
          id={field.id}
          className={field.className}
          required={isRequired}
          placeholder={t(field.placeholder) || ''}
          onChange={e => this.handleFieldValueChange(e, field)}
          value={this.state[field.name] || ''}
        />
        {field.addon ? <InputGroupAddon>{t(field.addon)}</InputGroupAddon> : null}
      </InputGroup>
    </div>
  );

  renderRadioField = (t, field) => (
    <div className={`company-details-formrow ${field.className}`} key={field.id}>
      <p className="input__label">{t(field.label)}</p>
      {field.options.map(option => (
        <RadioButton
          key={option.id}
          name={field.name}
          label={t(option.label)}
          checked={this.state[field.name] === option.value}
          value={option.value}
          onChange={e => this.handleFieldValueChange(e, field)}
        />
      ))}
    </div>
  );

  renderTimeField = (t, field, separator) => {
    const optionsLength = field.options.length;
    return (
      <div className={`company-details-formrow ${field.className}`} key={field.id}>
        <p className="input__label">{t(field.label)}</p>
        {field.options.map((option, i) => {
          let defaultTime = null;
          if (this.state[option.name]) {
            const d = moment().format('YYYY-MM-DD');
            defaultTime = moment(`${d} ${this.state[option.name]}`);
          }

          if (optionsLength === i + 1) {
            return (
              <span key={option.id}>
                <TimePicker
                  id={option.id}
                  key={option.id}
                  showSecond={option.showSecond}
                  placeholder={t(option.placeholder)}
                  className={option.className}
                  onChange={e => this.onTimeChange(e, option.name)}
                  value={defaultTime}
                />
              </span>
            );
          }

          return (
            <span key={option.id}>
              <TimePicker
                id={option.id}
                key={option.id}
                showSecond={option.showSecond}
                placeholder={t(option.placeholder)}
                className={option.className}
                onChange={e => this.onTimeChange(e, option.name)}
                value={defaultTime}
              />
              {separator}
            </span>
          );
        })}
      </div>
    );
  };

  renderTextGroupField = (t, field) => (
    <div className={`company-details-formrow ${field.className}`} key={field.id}>
      <p>{t(field.label)}</p>
      {field.options.map(option => this.renderInputField(t, option, false))}
    </div>
  );

  renderShapedOptions = (t, field) => (
    <ShapedOptions
      showMoreButton={field.showMoreButton}
      moreButtonTitle={t('more')}
      options={field.options}
      handleClick={this.handleShapedOptionClick}
      className={`company-details-formrow ${field.className}`}
      label={t(field.label)}
      inputOptionPlaceholder={t(field.inputOptionPlaceholder)}
      key={field.name}
      name={field.name}
    />
  );

  render() {
    const { isLoading, t } = this.props;
    const mainClass = classNames('settings-profile', {
      loading: isLoading
    });

    return (
      <div className={mainClass}>
        <div className="company-details">
          <form>
            <fieldset>
              {officeDataRequiredForm.map(field => {
                if (field.type === 'number') {
                  return this.renderInputField(t, field);
                }
                if (field.type === 'radio') {
                  return this.renderRadioField(t, field);
                }

                return null;
              })}
            </fieldset>
            <div className="separator" />
            <fieldset>
              <p className="optional-fields-headline">{t('additional-fields-headline')}</p>
              {officeDataAdditionalForm.map(field => {
                if (field.type === 'timeGroup') {
                  return this.renderTimeField(t, field, <span className="time-separator">{t('to')}</span>);
                }
                if (field.type === 'textGroup') {
                  return this.renderTextGroupField(t, field);
                }
                if (field.type === 'text') {
                  return this.renderInputField(t, field, false);
                }
                if (field.type === 'radio') {
                  return this.renderRadioField(t, field);
                }
                if (field.type === 'shapedOptions') {
                  return this.renderShapedOptions(t, field);
                }
                if (field.type === 'number') {
                  return this.renderInputField(t, field, false);
                }
                if (field.type === 'radio') {
                  return this.renderRadioField(t, field);
                }

                return null;
              })}
              <button className="company-details-submit" type="button" onClick={this.handleSubmit}>
                {t('save')}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

Customer.propTypes = {
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  notifyError: PropTypes.func.isRequired,
  customer: PropTypes.object,
  updateCustomer: PropTypes.func.isRequired
};

const mapStateToProps = ({
  users: {
    single: { isFetching }
  },
  current: { customer }
}) => ({
  isLoading: isFetching,
  customer
});

export { Customer as PureComponent };
export default withTranslation('Settings/Customer/CustomerDataForm')(
  connect(mapStateToProps, { notifyError, updateCustomer })(Customer)
);
