import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PartnerFilter, SinglePartner } from ".";

const Dispatcher = ({ isEditing }) => (isEditing ? <SinglePartner /> : <PartnerFilter />);

Dispatcher.propTypes = {
  isEditing: PropTypes.bool
};

const mapStateToProps = ({ shop: { cart: { cart } } }) => ({
  isEditing: !!cart.sed
});

export { Dispatcher as PureComponent };
export default connect(mapStateToProps)(Dispatcher);
