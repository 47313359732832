import { NOTIFICATION_SHOW } from 'constants/actionTypes';
import { NOTIFICATION_LEVEL_SUCCESS } from '../../constants/notifications';

const defaults = {
  timeout: 3000
};

export default (message, options = {}) => ({
  type: NOTIFICATION_SHOW,
  item: {
    ...defaults,
    ...options,
    id: Math.random(),
    message,
    level: NOTIFICATION_LEVEL_SUCCESS,
    closable: true
  }
});
