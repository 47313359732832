import moment from 'moment';
import {
  SERVICE_EVENT_STATUS_ACTIVE,
  SERVICE_EVENT_STATUS_PAUSED,
  SERVICE_EVENT_STATUS_INACTIVE,
  SERVICE_EVENT_TYPE_CLEANING,
  SERVICE_EVENT_MODE_RECURRENT
} from '../constants/models';

export default props => {
  const startDate = moment(props.start_date, moment.ISO_8601);
  const endDate = moment(props.end_date, moment.ISO_8601);

  return {
    ...props,
    start_date: startDate,
    end_date: endDate,
    isActive: props.status === SERVICE_EVENT_STATUS_ACTIVE,
    isPaused: props.status === SERVICE_EVENT_STATUS_PAUSED,
    isInactive: props.status === SERVICE_EVENT_STATUS_INACTIVE,
    isTypeCleaning: props.type === SERVICE_EVENT_TYPE_CLEANING,
    isModeRecurrent: props.mode === SERVICE_EVENT_MODE_RECURRENT,
    isRateable: () => endDate < new Date().getTime(),
    isNoteable: () => startDate >= new Date().getTime(),
    isStatusChangeable: () => startDate >= moment().add(4, 'days'),
    isUpcoming: () => startDate > new Date().getTime(),
    toJson: () => props
  };
};
