import React, { Component } from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';

import './ToggleBox.less';

export class ToggleBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: props.isOpened
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps({ isOpened }) {
    if (this.props.isOpened !== isOpened) {
      this.setState({ isOpened });
    }
  }

  toggle() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened
    }));
  }

  render() {
    const { title, component, children } = this.props;
    const { isOpened } = this.state;

    return (
      <div className="toggle-box">
        <button
          type="button"
          className={`button-inherit toggle-box-header ${isOpened ? 'open' : ''}`}
          onClick={this.toggle}
        >
          {title}
        </button>

        {isOpened && <div className="toggle-box-content">{component ? React.createElement(component) : children}</div>}
      </div>
    );
  }
}

ToggleBox.propTypes = {
  title: PropTypes.string,
  isOpened: PropTypes.bool,
  component: PropTypes.func
};

export default pure(ToggleBox);
