import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { fetchIssues as fetchIssuesAction, searchIssue, updateIssue as updateIssueAction } from 'actions/issues';
import { getActiveMembers } from 'selectors/members';
import { IssuesList, Filter, IssueStats, IssueSearch } from '.';
import { getStatuses, getPriorities } from './helper';

import './Issues.less';

class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        priority: [],
        status: [],
        end_date: [],
        start_date: []
      },
      showStats: false,
      currentPage: 1,
      limit: 20
    };
  }

  componentDidMount() {
    this.onFetchIssue();
  }

  toggleStats = () => {
    this.setState(prevState => ({
      showStats: !prevState.showStats
    }));
  };

  handlePageChange = page => {
    this.setState(
      () => ({
        currentPage: page
      }),
      () => this.onFetchIssue()
    );
  };

  onFetchIssue = query => {
    this.setState(
      prevState => ({
        ...prevState,
        query: {
          ...prevState.query,
          ...query
        }
      }),
      () => {
        if (this.state.query && this.state.query.search) {
          this.props.searchIssues({ ...this.state.query, limit: this.state.limit, page: this.state.currentPage }, true);
        } else {
          this.props.fetchIssues({
            ...this.state.query,
            limit: this.state.limit,
            page: this.state.currentPage
          });
        }
      }
    );
  };

  renderContent = () => {
    const { showStats } = this.state;
    const { t, issues, showPerPage, numberOfItems, members, updateIssue } = this.props;
    const statusesOptions = getStatuses(t);
    const prioritiesOptions = getPriorities(t);

    return (
      <div className="page-content">
        <IssuesList
          issues={issues}
          numberOfItems={numberOfItems}
          showPerPage={showPerPage}
          handlePageChange={this.handlePageChange}
          members={members}
          updateIssue={updateIssue}
          statusesOptions={statusesOptions}
          prioritiesOptions={prioritiesOptions}
        />
        <IssueStats isOpen={showStats} filterIssues={this.onFetchIssue} />
      </div>
    );
  };

  renderEmptyMessage = t => <div className="issues-list-empty">{t('no-issues')}</div>;

  render() {
    const { issues, t } = this.props;
    let content = this.renderEmptyMessage(t);

    if (issues && issues.length > 0) {
      content = this.renderContent();
    }

    return (
      <DocumentTitle title={t('headline')}>
        <div className="issues-page content-area">
          <div className="page-header">
            <h1 className="page-title">{t('headline')}</h1>

            <div className="header-right">
              <IssueSearch searchIssues={this.onFetchIssue} />

              <Link to="/issues/create" className="button button-blue">
                {t('new-issue-btn')}
              </Link>
            </div>
          </div>

          <Filter
            fetchIssues={this.onFetchIssue}
            toggleStats={this.toggleStats}
            selectedPriorities={this.state.query.priority}
          />

          {content}
        </div>
      </DocumentTitle>
    );
  }
}

Issues.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  showPerPage: state.issues.showPerPage,
  numberOfItems: state.issues.numberOfItems,
  issues: state.issues.items,
  members: getActiveMembers(state.members.items) || []
});

const mapDispatchToProps = dispatch => ({
  fetchIssues: query => dispatch(fetchIssuesAction(query)),
  searchIssues: (query, force) => dispatch(searchIssue(query, force)),
  updateIssue: data => dispatch(updateIssueAction(data))
});

export default pure(
  withTranslation('Issues')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Issues)
  )
);
