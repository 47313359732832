import {
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE
} from 'constants/actionTypes';
import { selectCreditCard } from '.';

export default (data, save) => (dispatch, getState) => {
  const {
    shop: {
      payment: {
        creditCards: {
          create: { isCreating }
        }
      }
    }
  } = getState();

  if (isCreating) return Promise.resolve();

  return new Promise(resolve => {
    dispatch({ type: SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST });

    if (data.error) {
      dispatch({
        type: SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE,
        error: true,
        payload: data.error.message
      });

      resolve();
      return;
    }

    dispatch({
      type: SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS,
      meta: { save },
      payload: data.token
    });
    dispatch(selectCreditCard(data.token.card.id));

    resolve(data.token);
  });
};
