import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import Teaser from './Teaser';

const BestsellerTeaser = ({ item, t }) => (
  <Teaser
    className="bestsellers-row"
    headline={item.name}
    location={`/shop/bestseller/${item.id}/${item.slug}`}
    locationText={t('show-all')}
    items={item.products}
    trackingListId={`besteller_${item.id}_teaser`}
  />
);

BestsellerTeaser.propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default pure(withTranslation('Shop/BestsellerOverview')(BestsellerTeaser));
