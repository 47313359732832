import { LOCATION_HASH_PASSWORD_SET } from '../../constants/location';
import replaceLocation from './replaceLocation';

export default () => (dispatch, getState) => {
  const {
    location: { pathname, hash }
  } = getState();

  if (hash !== LOCATION_HASH_PASSWORD_SET) {
    dispatch(replaceLocation({ pathname, hash: LOCATION_HASH_PASSWORD_SET }));
  }
};
