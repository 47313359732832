/* eslint-disable jsx-a11y/label-has-associated-control, camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
// import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from '@spone/ui';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { fetchServiceRequest, cloneServiceRequest } from 'actions/serviceRequests';
import { DocumentTitle } from 'common';

import './ServiceRequestDetails.less';

class ServiceRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getServiceRequest(id);
  }

  showFullImage = () => {
    this.setState({
      showImage: true
    });
  };

  hideFullImage = () => {
    this.setState({
      showImage: false
    });
  };

  onClone = () => {
    const { serviceRequest } = this.props;

    // TODO: Fill correct props (now only location [object])
    const clonedServiceRequest = {
      location: serviceRequest.location,
      category: null,
      assignee_id: null,
      createAnother: false,
      description: '',
      file: '',
      image: '',
      startDate: null,
      time: null,
      location_floor: '',
      location_room: '',
      location_building: '',
      size: ''
    };
    this.props.cloneServiceRequest(clonedServiceRequest);
    this.props.history.push('/service-requests/create');
  };

  onArchive = () => {};

  render() {
    const { t, serviceRequest, customers } = this.props;
    const { showImage } = this.state;
    const location =
      customers &&
      serviceRequest &&
      serviceRequest.location &&
      customers.find(item => item.id === serviceRequest.location);
    const image = serviceRequest.image_url ? serviceRequest.image_url : '/images/issues/dummy2.svg';

    return (
      <DocumentTitle title={t('service-request')}>
        <div className="detail-service-request content-area">
          <div className="page-header">
            <h1 className="page-title">
              <div className="page-title-left">
                <Link to="/service-requests">{t('headline')}</Link>
                <span className="divider" />
                {serviceRequest.subtype} {serviceRequest.type}{' '}
                {serviceRequest.db_id && <span className="issue-number">- #{serviceRequest.db_id}</span>}
              </div>

              <div className="page-title-right">
                {serviceRequest.status !== 'archived' && (
                  <button
                    type="button"
                    className="button button-blue button-secondary btn-archive"
                    onClick={this.onArchive}
                  >
                    {t('archive')}
                  </button>
                )}
                <button type="button" className="button button-blue btn-clone" onClick={this.onClone}>
                  {t('clone')}
                </button>
              </div>
            </h1>
          </div>

          <div className="page-subheader">
            <div
              className="image"
              role="presentation"
              onClick={this.showFullImage}
              style={{ backgroundImage: `url('${image}')` }}
            />

            <Modal isOpen={showImage} onClose={this.hideFullImage}>
              <img src={image} alt="mainpic" />
            </Modal>

            <div className="details">
              {serviceRequest.description && <div className="description">{serviceRequest.description}</div>}

              <div className="status">
                <span className="icon status-icon" />
                <span>{t(serviceRequest.status)}</span>
              </div>

              <div className="budget">
                <span className="icon budget-icon" />
                <span>{serviceRequest.customer_price_net || 0} €</span>
              </div>
            </div>
          </div>

          <div className="page-content">
            <div className="request-info">
              <div className="info-section">
                <div className="info-section-title">{t('request-information')}</div>
                <b>
                  {serviceRequest.subtype} {serviceRequest.type}
                </b>
                <div>{serviceRequest.location_building}</div>
                {serviceRequest.floor && (
                  <div>
                    {serviceRequest.floor} {t('floor')}
                  </div>
                )}
                {serviceRequest.room && (
                  <div>
                    {t('room')} {serviceRequest.room}
                  </div>
                )}
                {serviceRequest.service_start_date && (
                  <div>{moment(serviceRequest.service_start_date).format('DD.MM.YYYY HH:mm')}</div>
                )}
              </div>
              <div className="info-section">
                <div className="info-section-title">{t('service-provider')}</div>
                <b>{serviceRequest.partner_name}</b>
                <div>{serviceRequest.partner_contact_name}</div>
                <div>{serviceRequest.partner_street}</div>
                <div>
                  {serviceRequest.partner_postal_code} {serviceRequest.partner_city}
                </div>
              </div>
              {location && location.shipping_address && (
                <div className="info-section">
                  <div className="info-section-title">{t('shipping-address')}</div>
                  <b>{location.name}</b>
                  <div>{location.shipping_address.street}</div>
                  <div>
                    {location.shipping_address.postal_code} {location.shipping_address.city}
                  </div>
                  <div>{location.country_code}</div>
                </div>
              )}
              {location && location.billing_address && (
                <div className="info-section">
                  <div className="info-section-title">{t('billing-address')}</div>
                  <b>{location.name}</b>
                  <div>{location.billing_address.street}</div>
                  <div>
                    {location.billing_address.postal_code} {location.billing_address.city}
                  </div>
                  <div>{location.country_code}</div>
                </div>
              )}
            </div>

            <div className="request-messages">
              <div className="request-messages-title">{t('your-messages')}</div>
              MESSENGER HERE
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ServiceRequestDetails.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authToken: state.auth.data.jwtToken,
  userId: state.current.user.id,
  serviceRequest: state.serviceRequests.serviceRequest,
  customers: state.current.user.available_customers
});

const mapDispatchToProps = dispatch => ({
  getServiceRequest: id => dispatch(fetchServiceRequest(id)),
  cloneServiceRequest: serviceRequest => dispatch(cloneServiceRequest(serviceRequest))
});

export { ServiceRequestDetails as PureComponent };
export default pure(
  withTranslation('ServiceRequests')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(ServiceRequestDetails))
  )
);
