import { FEATURES_SEEN_REQUEST, FEATURES_SEEN_SUCCESS, FEATURES_SEEN_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isCreating: false,
    isCreated: false
  },
  action
) => {
  switch (action.type) {
    case FEATURES_SEEN_REQUEST:
      return { ...state, isCreating: true, isCreated: false };

    case FEATURES_SEEN_SUCCESS:
      return { ...state, isCreating: false, isCreated: true };

    case FEATURES_SEEN_FAILURE:
      return { ...state, isCreating: false };

    default:
      return state;
  }
};
