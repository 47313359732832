import i18next from '../../../i18next';
import { showCart } from '../../location';
import { createCart } from '.';
import { confirm } from '../../notifications';

const t = i18next.getFixedT(null, 'Notifications');

const createBody = items =>
  items
    .filter(item => !!item.customer_product && !item.is_delivery_cost && !item.is_discount)
    .reduce((body, item) => ({ ...body, [item.customer_product]: item.product.quantity }), {});

const reorder = (hasCart, items) => dispatch =>
  (hasCart ? dispatch(confirm(t('confirm-override-cart'))) : Promise.resolve())
    .then(() => items && dispatch(createCart(createBody(items))))
    .then(() => dispatch(showCart()))
    .catch(() => {});

const reorderServiceEvent = serviceEventId => (dispatch, getState) => {
  const {
    serviceEvents: {
      products: { [serviceEventId]: { items } = {} }
    },
    shop: {
      cart: { cart }
    }
  } = getState();

  return dispatch(reorder(cart.length > 0, items));
};

const reorderSED = serviceEventDefinitionId => (dispatch, getState) => {
  const {
    serviceEventDefinitions: {
      products: { [serviceEventDefinitionId]: { items } = {} }
    },
    shop: {
      cart: { cart }
    }
  } = getState();

  return dispatch(reorder(cart.length > 0, items));
};

export { reorderServiceEvent, reorderSED };
