import { SHOP_PARTNERS_REQUEST, SHOP_PARTNERS_SUCCESS, SHOP_PARTNERS_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    items: null
  },
  action
) => {
  switch (action.type) {
    case SHOP_PARTNERS_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case SHOP_PARTNERS_SUCCESS:
      return { ...state, isFetching: false, fetchedAt: new Date(), items: action.payload.map(item => item.id) };

    case SHOP_PARTNERS_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
