import { toast } from 'react-toastify';
import {
  SERVICE_REQUESTS_FETCH,
  SERVICE_REQUESTS_FETCH_SUCCESS,
  SERVICE_REQUESTS_FETCH_FAILURE,
  SERVICE_REQUESTS_CREATE,
  SERVICE_REQUESTS_CREATE_SUCCESS,
  SERVICE_REQUESTS_CREATE_FAILURE,
  SERVICE_REQUESTS_EDIT,
  SERVICE_REQUESTS_EDIT_SUCCESS,
  SERVICE_REQUESTS_EDIT_FAILURE,
  SERVICE_REQUESTS_SEARCH,
  SERVICE_REQUESTS_SEARCH_SUCCESS,
  SERVICE_REQUESTS_SEARCH_FAILURE,
  SERVICE_REQUESTS_FETCH_REQUEST,
  SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS,
  SERVICE_REQUESTS_FETCH_REQUEST_FAILURE,
  SERVICE_REQUESTS_CLONE
} from 'constants/actionTypes';
import { X_TOTAL_COUNT } from 'constants/headers';
import i18next from '../../i18next';
import { updateObjectInArray } from '../helper';

const t = i18next.getFixedT(null, 'ServiceRequests');

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    numberOfItems: 0,
    showPerPage: 20,
    items: [],
    stats: {},
    serviceRequest: {}
  },
  action
) => {
  switch (action.type) {
    case SERVICE_REQUESTS_SEARCH:
    case SERVICE_REQUESTS_FETCH_REQUEST:
    case SERVICE_REQUESTS_FETCH:
    case SERVICE_REQUESTS_EDIT:
    case SERVICE_REQUESTS_CREATE:
      return { ...state, isFetching: true, fetchedAt: null };

    case SERVICE_REQUESTS_SEARCH_SUCCESS:
    case SERVICE_REQUESTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload,
        numberOfItems: parseInt(action.response && action.response.headers.get(X_TOTAL_COUNT), 10) || 0,
        serviceRequest: {}
      };

    case SERVICE_REQUESTS_SEARCH_FAILURE:
    case SERVICE_REQUESTS_FETCH_REQUEST_FAILURE:
    case SERVICE_REQUESTS_FETCH_FAILURE:
    case SERVICE_REQUESTS_EDIT_FAILURE:
    case SERVICE_REQUESTS_CREATE_FAILURE:
      return { ...state, isFetching: false };

    case SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        serviceRequest: action.payload
      };

    case SERVICE_REQUESTS_CREATE_SUCCESS:
      toast.success(t('success-create'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case SERVICE_REQUESTS_EDIT_SUCCESS:
      toast.success(t('success-edit'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case SERVICE_REQUESTS_CLONE:
      return {
        ...state,
        isFetching: false,
        serviceRequest: action.payload
      };

    default:
      return state;
  }
};
