import {
  SERVICE_EVENT_STATUS_CHANGE_REQUEST,
  SERVICE_EVENT_STATUS_CHANGE_SUCCESS,
  SERVICE_EVENT_STATUS_CHANGE_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_STATUS_CHANGE_REQUEST: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isChanging: true,
          isChanged: false
        }
      };
    }

    case SERVICE_EVENT_STATUS_CHANGE_SUCCESS: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isChanging: false,
          isChanged: true
        }
      };
    }

    case SERVICE_EVENT_STATUS_CHANGE_FAILURE: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isChanging: false
        }
      };
    }

    default:
      return state;
  }
};
