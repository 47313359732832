import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const Placeholder = ({ t }) => (
  <div className="modul__row modul-placeholder">
    <p className="modul-placeholder__headline center">{t('no-events-head')}</p>
    <p className="modul-placeholder__subline center">{t('no-events-sub')}</p>
    <div className="modul-placeholder__icon cleaning" />
    <Link to="/cleaning" className="button-blue modul-placeholder__button">
      {t('go-to-cleaning')}
    </Link>
    <div className="placeholder-box">
      <div className="placeholder-box__dot" />
      <div className="placeholder-box__left">
        <div className="placeholder-box__topline" />
        <div className="placeholder-box__bottomline" />
      </div>
      <div className="placeholder-box__right">
        <div className="placeholder-box__bottomline" />
      </div>
    </div>
    <div className="placeholder-box">
      <div className="placeholder-box__dot" />
      <div className="placeholder-box__left">
        <div className="placeholder-box__topline" />
        <div className="placeholder-box__bottomline" />
      </div>
      <div className="placeholder-box__right">
        <div className="placeholder-box__bottomline" />
      </div>
    </div>
  </div>
);


Placeholder.propTypes = {
  t: PropTypes.func.isRequired
};

export { Placeholder as PureComponent };
export default withTranslation('Dashboard/PastEvents')(Placeholder);
