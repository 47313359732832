import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PartnerImage } from '../Product/Common';

import './SinglePartner.less';

const SinglePartner = ({ item }) =>
  item ? (
    <div className="shop-partner-edit">
      <div className="shop-partner-edit-name">{item.name}</div>

      <PartnerImage item={item} />
    </div>
  ) : null;

SinglePartner.propTypes = {
  item: PropTypes.object
};

const mapStateToProps = ({
  shop: {
    cart: { cart }
  }
}) => ({
  item: cart.sed && cart.sed.partner
});

export { SinglePartner as PureComponent };
export default connect(mapStateToProps)(SinglePartner);
