import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { SuccessPage } from 'components/OfferConfirmation';
import OfferReview from 'components/OfferReview/OfferReview';
import PartnerConfirmation from 'components/PartnerConfirmation';
import Navbar from 'components/Navbar';
import Login from 'components/Login';
import Register from 'components/Register';
import ModalDispatcher from '../Modal';
import { RestorePassword, Verify } from '.';

import './Public.less';

export const Public = () => (
  <div className="public__wrapper">
    <Navbar />
    <Switch>
      <Route path="/register" component={Register} exact />
      <Route path="/register/:source" component={Register} />
      <Route path="/password/:token" component={RestorePassword} />
      <Route path="/offer/success/:token" component={SuccessPage} />
      {/* <Route path="/offer/:token" component={OfferConfirmation} /> */}
      <Route path="/offers/:token" component={OfferReview} />
      <Route path="/executive/:token" component={PartnerConfirmation} />
      <Route path="/verify/:token" component={Verify} />
      <Route component={Login} />
    </Switch>
    <ModalDispatcher />
  </div>
);

export default withRouter(Public);
