import PartnerConfirmation from './PartnerConfirmation';
import CompanyDetails from './components/CompanyDetails';
import DateBox from './components/DateBox';
import ExecutionDate from './components/ExecutionDate';
import NotificationBox from './components/NotificationBox';
import PriceBox from './components/PriceBox';
import TaskList from './components/TaskList';
import ExecutorBox from './components/ExecutorBox';
import PONumber from './components/PONumber';

export default PartnerConfirmation;
export { CompanyDetails, DateBox, ExecutionDate, NotificationBox, PriceBox, TaskList, ExecutorBox, PONumber };
