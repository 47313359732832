import { combineReducers } from 'redux';
import create from './create';
import remove from './delete';
import list from './list';
import selected from './selected';

export default combineReducers({
  create,
  delete: remove,
  list,
  selected
});
