import {
  CUSTOMER_BILLING_ADDRESS_CHANGE_REQUEST,
  CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS,
  CUSTOMER_BILLING_ADDRESS_CHANGE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default submitBillingAddress =>
  authCall({
    shouldFetch: s => !s.customer.billingAddress.isChanging,
    types: [
      CUSTOMER_BILLING_ADDRESS_CHANGE_REQUEST,
      CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS,
      CUSTOMER_BILLING_ADDRESS_CHANGE_FAILURE
    ],
    endpoint: '/user/customer/billing-address-contact',
    method: 'PUT',
    body: submitBillingAddress
  });
