import React from 'react';
import PropTypes from 'prop-types';

export default class Banner extends React.PureComponent {

    static propTypes = {
      title: PropTypes.string,
      description: PropTypes.string
    };

    render() {
      const { title, description } = this.props;
      return (
        <div className="cleaning-services__banner">
          <div className="cleaning-services__icon" />
          <div className="cleaning-services__description">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>
      );
    }

}
