import storage from 'store';
import { LOCAL_STORAGE_LANGUAGE } from '../../constants/localStorage';
import memoize from '../memoize';

const getLanguage = state => state.i18n.language;

export default memoize(
  [getLanguage],
  language => language && storage.set(LOCAL_STORAGE_LANGUAGE, language),
);
