import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { alert } from 'actions/notifications';

class ResumeButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { dispatch, item, t } = this.props;

    dispatch(alert(t(item.isStatusChangeable() ? 'confirm-resume' : 'too-late')));
  }

  render() {
    const { item, isChanging } = this.props;

    if (item.isPaused && item.isUpcoming()) {
      return (
        <div className={`se-button status ${isChanging ? 'loading' : 'paused'}`}>
          <button type="button" className="button-status" onClick={this.handleClick}>
            &nbsp;
          </button>
        </div>
      );
    }

    return null;
  }
}

ResumeButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  isChanging: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { status } }, { item: { id } }) => ({
  isChanging: !!(status[id] && status[id].isChanging)
});

export default withTranslation('Common/ServiceEvent/ResumeButton')(connect(mapStateToProps)(ResumeButton));
