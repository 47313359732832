import {
  SHOP_PARTNERS_RESTORE,
  SHOP_PARTNERS_SELECT,
  SHOP_PARTNERS_SELECT_ONE,
  SHOP_PARTNERS_DESELECT,
  SHOP_PARTNERS_RESET
} from 'constants/actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_PARTNERS_RESTORE:
      return action.partnerIds;

    case SHOP_PARTNERS_SELECT:
      return [...state, action.partnerId];

    case SHOP_PARTNERS_SELECT_ONE:
      return [action.partnerId];

    case SHOP_PARTNERS_DESELECT:
      return state.filter(partnerId => partnerId !== action.partnerId);

    case SHOP_PARTNERS_RESET:
      return initialState;

    default:
      return state;
  }
};
