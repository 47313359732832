import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetFilters } from 'actions/serviceEvents/filters';
import { getIsFilterSelected } from 'selectors/serviceEvents/filters';

export const ResetButton = ({ isDisabled, onClick, t }) => (
  <button
    type="button"
    className={cx('button-soft reset', {
      disabled: isDisabled
    })}
    onClick={onClick}
  >
    {t('reset-filters')}
  </button>
);

ResetButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isDisabled: !getIsFilterSelected(state)
});

const mapDispatchToProps = {
  onClick: resetFilters
};

export default withTranslation('Dashboard/ServiceEventFilter')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetButton)
);
