import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transition } from 'common';
import {
  LOCATION_QUERY_SED,
  LOCATION_QUERY_SERVICE_TYPE,
  LOCATION_HASH_CALENDAR_SYNC,
  LOCATION_HASH_PRODUCT_MISSING,
  LOCATION_HASH_PASSWORD_SET,
  LOCATION_HASH_PASSWORD_RESET,
  LOCATION_HASH_PASSWORD_CHANGE,
  LOCATION_HASH_FEATURE_HIGHLIGHTS,
  LOCATION_HASH_CUSTOMER_INFORMATION,
  LOCATION_HASH_SET_ACCOUNT_DATA,
  LOCATION_HASH_CLEANING_TASKLIST,
  LOCATION_HASH_INTENSIVE_CLEANING_TASKLIST,
  LOCATION_HASH_PREMIUM_TASKLIST,
  LOCATION_HASH_CONFIRM_OFFER,
  LOCATION_HASH_REJECT_PARTNER,
  LOCATION_HASH_SUCCESS_PARTNER,
  LOCATION_HASH_REJECT_OFFER,
  LOCATION_QUERY_PRODUCT,
  LOCATION_QUERY_SE_RATING,
  LOCATION_HASH_CONFIRM_DEACTIVATE_MEMBER,
  LOCATION_HASH_CONFIRM_ACTIVATE_MEMBER
} from 'constants/location';
import {
  SERVICE_TYPE_CATERING,
  SERVICE_TYPE_CLEANING,
  SERVICE_TYPE_HANDYMEN,
  SERVICE_TYPE_RELOCATION,
  SERVICE_TYPE_WATER,
  SERVICE_TYPE_COFFEE
} from 'constants/serviceTypes';
import { CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT } from 'constants/contentful';
import {
  ServiceEventDefinitionModal,
  CalendarSync,
  MissingProduct,
  ResetPassword,
  ChangePassword,
  SetPassword,
  Service,
  FeatureHighlights,
  ProductDetail,
  CustomerInformation,
  AddAccountData,
  ServiceEventRating,
  IntensiveCleaningTasklist,
  CleaningTasklist,
  PremiumTasklist,
  ConfirmOffer,
  DeclinePartner,
  SuccessPartner,
  ConfirmDeactivateMember
} from '.';

export class Dispatcher extends Component {
  renderModal() {
    const { query, hash, hasFeatures, state } = this.props;

    if (query[LOCATION_QUERY_SED]) {
      return <ServiceEventDefinitionModal id={query[LOCATION_QUERY_SED]} />;
    }

    if (query[LOCATION_QUERY_PRODUCT]) {
      return <ProductDetail id={query[LOCATION_QUERY_PRODUCT]} />;
    }

    if (query[LOCATION_QUERY_SE_RATING]) {
      return <ServiceEventRating id={query[LOCATION_QUERY_SE_RATING]} />;
    }

    if (query[LOCATION_QUERY_SERVICE_TYPE]) {
      switch (query[LOCATION_QUERY_SERVICE_TYPE]) {
        case SERVICE_TYPE_CATERING:
        case SERVICE_TYPE_CLEANING:
        case SERVICE_TYPE_HANDYMEN:
        case SERVICE_TYPE_RELOCATION:
        case SERVICE_TYPE_WATER:
        case SERVICE_TYPE_COFFEE:
          return <Service type={query[LOCATION_QUERY_SERVICE_TYPE]} />;

        default:
          return null;
      }
    }

    switch (hash) {
      case LOCATION_HASH_FEATURE_HIGHLIGHTS:
        return hasFeatures ? <FeatureHighlights /> : null;

      case LOCATION_HASH_CUSTOMER_INFORMATION:
        return <CustomerInformation />;
      case LOCATION_HASH_SET_ACCOUNT_DATA:
        return <AddAccountData />;
      case LOCATION_HASH_CALENDAR_SYNC:
        return <CalendarSync />;

      case LOCATION_HASH_PRODUCT_MISSING:
        return <MissingProduct />;

      case LOCATION_HASH_PASSWORD_SET:
        return <SetPassword />;

      case LOCATION_HASH_PASSWORD_RESET:
        return <ResetPassword />;
      case LOCATION_HASH_PASSWORD_CHANGE:
        return <ChangePassword />;
      case LOCATION_HASH_CLEANING_TASKLIST:
        return <CleaningTasklist />;
      case LOCATION_HASH_INTENSIVE_CLEANING_TASKLIST:
        return <IntensiveCleaningTasklist />;
      case LOCATION_HASH_PREMIUM_TASKLIST:
        return <PremiumTasklist />;
      case LOCATION_HASH_CONFIRM_OFFER:
        return <ConfirmOffer isConfirmed />;
      case LOCATION_HASH_CONFIRM_DEACTIVATE_MEMBER:
        return <ConfirmDeactivateMember id={state && state.id} deactivate />;
      case LOCATION_HASH_CONFIRM_ACTIVATE_MEMBER:
        return <ConfirmDeactivateMember id={state && state.id} />;
      case LOCATION_HASH_REJECT_OFFER:
        return <ConfirmOffer />;
      case LOCATION_HASH_REJECT_PARTNER:
        return <DeclinePartner />;

      case LOCATION_HASH_SUCCESS_PARTNER:
        return <SuccessPartner />;
      default:
        return null;
    }
  }

  render() {
    return <Transition name="modal-animation">{this.renderModal()}</Transition>;
  }
}

Dispatcher.propTypes = {
  query: PropTypes.object.isRequired,
  hash: PropTypes.string,
  hasFeatures: PropTypes.bool,
  state: PropTypes.any
};

const mapStateToProps = ({ location: { query, hash, state }, contentful }) => ({
  query,
  hash,
  state,
  hasFeatures: !!(
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT] &&
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items &&
    contentful[CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT].items.length > 0
  )
});

export default connect(mapStateToProps)(Dispatcher);
