import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_INTENSIVE_CLEANING_TASKLIST } from 'constants/location';

const IntensiveCleaningTasklistLink = ({ pathname, t, className }) => (
  <Link
    to={`${pathname}${LOCATION_HASH_INTENSIVE_CLEANING_TASKLIST}`}
    className={`cleaning-service__link ${className || ''}`}
  >
    {t('cleaning-tasklist-linktitle')}
  </Link>
);

IntensiveCleaningTasklistLink.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string,
  t: PropTypes.func.isRequired
};

IntensiveCleaningTasklistLink.defaultProps = {
  className: ''
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export { IntensiveCleaningTasklistLink as PureComponent };
export default withTranslation('Modal/CleaningTasklist')(connect(mapStateToProps)(IntensiveCleaningTasklistLink));
