/* eslint-disable camelcase */

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink, withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { hideShopButton } from "utils/brandFlags";
// import { hideShopButton, hideServicesButton } from "utils/brandFlags";
import { changeCustomer } from "actions/customer";
import { Search } from "components/Header";
import { Account, LogoutButton } from ".";

import "./Navbar.less";

export class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = { isVisible: false };
  }

  componentDidMount() {
    this.historyListener = this.props.history.listen(() => {
      this.setState({
        isVisible: false
      });
      document.body.classList.remove("overflow-hidden");
    });
  }

  componentWillUnmount() {
    this.historyListener();
  }

  isOfferPage = () => /\/offers\/.+/.test(window.location.pathname);

  toggleNavbar = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));

    document.body.classList.toggle("overflow-hidden");
  };

  renderNavItems() {
    const { t, brand } = this.props;

    return (
      <div className="main-navi">
        <div className="main-navi-wrapper">
          <div className="nav-dashboard-link nav-item">
            <NavLink
              to="/dashboard"
              activeClassName="active"
              className="nav-link"
              title={t("dashboard")}
            >
              <span className="nav-item-text">{t("dashboard")}</span>
              <span className="nav-item-icon" />
              <div className="nav-item-blue-bar" />
            </NavLink>
          </div>
          {hideShopButton(brand) ? null : (
            <div className="nav-shop nav-item">
              <NavLink
                to="/shop"
                activeClassName="active"
                className="nav-link"
                title={t("shop")}
              >
                <span className="nav-item-text">{t("shop")}</span>
                <span className="nav-item-icon" />
                <div className="nav-item-blue-bar" />
              </NavLink>
            </div>
          )}

          <div className="nav-cleaning nav-item">
            <NavLink
              to="/cleaning"
              activeClassName="active"
              className="nav-link"
              title={t("cleaning")}
            >
              <span className="nav-item-text">{t("cleaning")}</span>
              <span className="nav-item-icon" />
              <div className="nav-item-blue-bar" />
            </NavLink>
          </div>

          {/* TEMPORARY COMMENTED */}
          {/* <div className="nav-booking nav-item">
                        <NavLink
                            to="/service-requests"
                            activeClassName="active"
                            className="nav-link"
                            title={t('service-request')}
                        >
                            <span className="nav-item-text">{t('service-request')}</span>
                            <span className="nav-item-icon" />
                            <div className="nav-item-blue-bar" />
                        </NavLink>
                    </div> */}

          {/* {hideServicesButton(brand) ? null : (
            <div className="nav-services nav-item">
              <NavLink to="/services" activeClassName="active" className="nav-link" title={t('services')}>
                <span className="nav-item-text">{t('services')}</span>
                <span className="nav-item-icon" />
                <div className="nav-item-blue-bar" />
              </NavLink>
            </div>
          )} */}

          <div className="nav-timeline nav-item">
            <NavLink
              to="/timeline"
              activeClassName="active"
              className="nav-link"
              title={t("timeline")}
            >
              <span className="nav-item-text">{t("timeline")}</span>
              <span className="nav-item-icon" />
              <div className="nav-item-blue-bar" />
            </NavLink>
          </div>
          <div className="nav-invoice nav-item">
            <NavLink
              to="/invoices-orders"
              activeClassName="active"
              className="nav-link"
              title={t("invoices")}
            >
              <span className="nav-item-text">{t("invoices")}</span>
              <span className="nav-item-icon" />
              <div className="nav-item-blue-bar" />
            </NavLink>
          </div>

          {/* TEMPORARY COMMENTED */}
          {/* <div className="nav-services nav-item">
                        <NavLink to="/issues" activeClassName="active" className="nav-link">
                            <span className="nav-item-text">{t('issues')}</span>
                            <span className="nav-item-icon" />
                        </NavLink>
                    </div> */}
          {brand && brand === "wework" && (
            <div className="nav-analytics nav-item">
              <NavLink
                to="/analytics"
                activeClassName="active"
                className="nav-link"
                title={t("analytics")}
              >
                <span className="nav-item-text">{t("analytics")}</span>
                <span className="nav-item-icon" />
                <div className="nav-item-blue-bar" />
              </NavLink>
            </div>
          )}
        </div>
        <div className="nav-support nav-item">
          <NavLink
            to="/support"
            activeClassName="active"
            className="nav-link"
            title={t("support")}
          >
            <span className="nav-item-text">{t("support")}</span>
            <span className="nav-item-icon" />
            <div className="nav-item-blue-bar" />
          </NavLink>
        </div>
      </div>
    );
  }

  render() {
    const {
      t,
      customerName,
      doChangeCustomer,
      items,
      customerId,
      isConverted,
      isDisabled,
      auth,
      logo,
      image_url
    } = this.props;
    const { isVisible } = this.state;

    const offerPageNav = (
      <div className="navbar-nav flex">
        <div className="nav">
          <span className="nav-logo" />
        </div>
      </div>
    );

    if (!auth.data.isAuthenticated && !this.isOfferPage()) {
      return null;
    }

    const customLogoStyle = logo ? { backgroundImage: `url(${logo})` } : null;

    return (
      <div className="header-wrapper">
        <header className="navbar">
          <Link
            to="/"
            className={classNames("logo", { defaultLogo: !logo })}
            title="Service Partner ONE"
            style={customLogoStyle}
          >
            <span className="nav-logo" />
          </Link>

          <div className="navbar-right nav-account-settings">
            <div className="navbar-search search">
              <Search />
            </div>
            <div className="nav-account-settings">
              {isConverted ? (
                <Account
                  t={t}
                  customerName={customerName}
                  items={items}
                  doChangeCustomer={doChangeCustomer}
                  customerId={customerId}
                  image={image_url}
                />
              ) : (
                <div className="nav-link-lead">
                  <LogoutButton t={t} />
                </div>
              )}
            </div>
            <div
              onClick={this.toggleNavbar}
              className={`mobile-menu${isVisible ? " open" : ""}`}
              role="button"
              tabIndex="0"
            >
              <div className="button mobile-menu-button">
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </header>
        <div className={`header-sidebar ${isVisible ? "mobile-open" : ""}`}>
          <nav className={`navbar ${isDisabled ? "disabled" : ""}`}>
            {this.isOfferPage() ? offerPageNav : this.renderNavItems()}
          </nav>
          <div className="nav-account-settings mobile">
            {isConverted ? (
              <Account
                t={t}
                customerName={customerName}
                items={items}
                doChangeCustomer={doChangeCustomer}
                customerId={customerId}
                image={image_url}
              />
            ) : (
              <div className="nav-link-lead">
                <LogoutButton t={t} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  isDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  current: { user, customer },
  auth,
  brand,
  shop: {
    cart: { cart }
  },
  userSettings: { logo }
}) => ({
  isDisabled: !!cart.sed,
  auth,
  brand,
  customerName: customer && customer.name,
  customerId: customer && customer.id,
  items: user && user.available_customers,
  isConverted: customer ? customer.is_converted : 0,
  logo,
  image_url: customer && customer.image_url
});

const mapDispatchToProps = {
  doChangeCustomer: changeCustomer
};

export default withTranslation("Navbar")(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
);
