import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ReorderLink = ({ item, location, t }) => {
  if (!item.is_reorderable) return null;

  return (
    <Link
      className="button-subtle button"
      to={location}
    >{t('reorder')}</Link>
  );
};

ReorderLink.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  t: PropTypes.func.isRequired
};

export { ReorderLink as PureComponent };
export default withTranslation('Dashboard/PastEvents')(ReorderLink);
