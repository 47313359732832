import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createGetProducts,
  createGetDeliveryCost,
  createGetDiscount,
  createHasProducts
} from 'selectors/serviceEventDefinitions';
import { fetchServiceEventDefinitionProducts } from 'actions/serviceEventDefinitions';
import { reorderSED, startEditingSED } from 'actions/shop/cart';
import { List, Sum, ReorderButton, EditButton } from '../../ServiceEvent/Shared/Products';

export class Products extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.id !== prevProps.item.id) {
      this.props.fetchData();
    }
  }

  render() {
    const { item, isFetching, products, hasProducts, deliveryCost, discount, doReorder, doStartEditing } = this.props;

    return (
      <div className="modal-content-products">
        <List
          isFetching={isFetching}
          items={products}
          showNewPrice={!item.isModeRecurrent}
          showOutOfStock={item.is_reorderable}
        />

        <div className="modal-all-combined">
          <Sum item={item} deliveryCost={deliveryCost} discount={discount} />

          <div className="modal-buttons-flex">
            {item.is_reorderable && <ReorderButton isDisabled={isFetching || !hasProducts} onClick={doReorder} />}

            {item.is_editable && (
              <EditButton item={item} isDisabled={isFetching || !hasProducts} onClick={doStartEditing} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Products.propTypes = {
  item: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  products: PropTypes.array,
  hasProducts: PropTypes.bool,
  deliveryCost: PropTypes.object,
  discount: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  doReorder: PropTypes.func.isRequired,
  doStartEditing: PropTypes.func.isRequired
};

const mapStateToProps = () => {
  const getProducts = createGetProducts();
  const getDeliveryCost = createGetDeliveryCost();
  const getDiscount = createGetDiscount();
  const hasProducts = createHasProducts();

  return (state, props) => {
    const {
      serviceEventDefinitions: {
        products: { [props.item.id]: { isFetching } = {} }
      }
    } = state;

    return {
      isFetching,
      products: getProducts(state, props),
      hasProducts: hasProducts(state, props),
      deliveryCost: getDeliveryCost(state, props),
      discount: getDiscount(state, props)
    };
  };
};

const mapDispatchToProps = (dispatch, { item: { id } }) => ({
  fetchData: () => dispatch(fetchServiceEventDefinitionProducts(id)),
  doReorder: () => dispatch(reorderSED(id)),
  doStartEditing: () => dispatch(startEditingSED(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
