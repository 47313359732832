import { createSelector } from 'reselect';

const getItems = (state, { dataKey }) =>
  state.serviceEventDefinitions.list[dataKey] &&
    state.serviceEventDefinitions.list[dataKey].items;
const getEntities = state => state.serviceEventDefinitions.entities;

export default () => createSelector(
  [getItems, getEntities], (items, entities) => {
    if (!items) return null;

    return items.map(itemId => entities[itemId])
      .filter(item => !!item);
  },
);
