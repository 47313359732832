import {
  SHOP_BESTSELLERS_REQUEST,
  SHOP_BESTSELLERS_SUCCESS,
  SHOP_BESTSELLERS_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      shop: {
        bestsellers: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [SHOP_BESTSELLERS_REQUEST, SHOP_BESTSELLERS_SUCCESS, SHOP_BESTSELLERS_FAILURE],
    endpoint: '/user/shop/bestsellers',
    method: 'GET',
    query: ({
      shop: {
        partners: { partnerIds }
      }
    }) => ({
      partner_ids: partnerIds.length > 0 ? partnerIds : undefined
    })
  });
