import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { changePoNumber } from 'actions/shop/cart';

import './PoNumber.less';

class PoNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      poNumber: props.poNumber,
      isFormVisible: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.showForm = this.showForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillReceiveProps({ poNumber }) {
    if (this.props.poNumber !== poNumber) {
      this.setState({
        poNumber,
        isFormVisible: false
      });
    }
  }

  showForm() {
    if (!this.state.isFormVisible) {
      this.setState({
        isFormVisible: true
      });
    }
  }

  handleChange({ target: { value: poNumber } }) {
    if (this.state.poNumber !== poNumber) {
      this.setState({ poNumber });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit } = this.props;
    const { poNumber } = this.state;

    onSubmit(poNumber);
  }

  handleClickOutside() {
    if (this.state.isFormVisible) {
      this.setState({
        poNumber: this.props.poNumber,
        isFormVisible: false
      });
    }
  }

  render() {
    const { poNumber, isSubmitting, t } = this.props;
    const { isFormVisible, poNumber: value } = this.state;

    return (
      <div className="po-number">
        {!isFormVisible && (
          <button type="button" className="po-number__button po-number__button--want" onClick={this.showForm}>
            {poNumber ? t('change-chosen-po-number', { poNumber }) : t('want-po-number')}
          </button>
        )}

        {isFormVisible && (
          <form onSubmit={this.handleSubmit}>
            <label className="cart-title" htmlFor="po-number-input">
              {t('po-number')}
            </label>

            <fieldset disabled={isSubmitting}>
              <div className="po-number__con">
                <input
                  id="po-number-input"
                  name="poNumberInput"
                  className="po-number__input"
                  value={value || ''}
                  onChange={this.handleChange}
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                />

                <button className={`po-number__submit ${isSubmitting ? 'loading-light' : ''}`} type="submit">
                  {poNumber ? t('change') : t('add')}
                </button>
              </div>
            </fieldset>
          </form>
        )}
      </div>
    );
  }
}

PoNumber.propTypes = {
  t: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  poNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { cart, isPoNumberChanging }
  }
}) => ({
  poNumber: cart.po_number,
  isSubmitting: isPoNumberChanging
});

const mapDispatchToProps = {
  onSubmit: changePoNumber
};

export { PoNumber as PureComponent };
export default withTranslation('Shop/Cart/PoNumber')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(onClickOutside(PoNumber))
);
