import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { propCreator } from 'components/HOC';
import { createGetEntries } from 'selectors/contentful';
import { fetchBanner } from 'actions/contentful';
import { CONTENTFUL_CONTENT_TYPE_BANNER } from 'constants/contentful';

import './Banner.less';

class Banner extends Component {
  componentDidMount() {
    this.props.fetchBannerData();
  }

  getContenfulField = fieldName => {
    const { items } = this.props;
    let content = null;

    if (items && items[0] && items[0].fields && fieldName) {
      if (fieldName === 'banner') {
        content = items[0].fields[fieldName].fields.file.de.url;
      } else if (fieldName === 'cornerText') {
        content = documentToHtmlString(items[0].fields[fieldName]);
      } else {
        content = items[0].fields[fieldName];
      }
    }

    return content;
  };

  onClickButton = () => {
    ReactGA.event({
      category: 'CI Banner',
      action: 'click'
    });

    const btnRedirect = this.getContenfulField('buttonRedirect');
    if (btnRedirect) {
      this.props.history.push(btnRedirect);
    }
  };

  render() {
    const isButtonExist = this.getContenfulField('buttonRedirect') && this.getContenfulField('buttonText');
    const bannerHorisontalPosition = this.getContenfulField('bannerHorisontalPosition') || 'center';
    const bannerVerticalPosition = this.getContenfulField('bannerVerticalPosition') || 'center';
    const backgroundPosition = `${bannerHorisontalPosition} ${bannerVerticalPosition}`;
    const descriptionColor = this.getContenfulField('descriptionColor');

    return (
      <div
        className="dashboard-banner"
        style={{ backgroundImage: `url(${this.getContenfulField('banner')})`, backgroundPosition }}
      >
        <div className={cx('dashboard-banner-texts', descriptionColor)}>
          <div className="dashboard-banner-headline">{this.getContenfulField('title')}</div>
          <div className="dashboard-banner-tagline">{this.getContenfulField('description')}</div>
          {isButtonExist && (
            <button type="button" className="dashboard-banner-button button-blue" onClick={this.onClickButton}>
              <span className="mobile-none">{this.getContenfulField('buttonText')}</span>
            </button>
          )}
        </div>
        <div className="dashboard-banner-eyecatcher">
          <div className="text" dangerouslySetInnerHTML={{ __html: this.getContenfulField('cornerText') }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  const getEntries = createGetEntries();

  return (state, props) => {
    const {
      contentful: { [props.dataKey]: { isFetching } = {} }
    } = state;

    return {
      isLoading: isFetching,
      items: getEntries(state, props)
    };
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBannerData: () => dispatch(fetchBanner())
});

const createProps = {
  dataKey: CONTENTFUL_CONTENT_TYPE_BANNER
};

export default withRouter(
  withTranslation('Dashboard/Banner')(
    propCreator(createProps)(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Banner)
    )
  )
);
