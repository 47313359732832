import {
  SHOP_FAVORITE_CREATE_REQUEST,
  SHOP_FAVORITE_CREATE_SUCCESS,
  SHOP_FAVORITE_CREATE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default productId =>
  authCall({
    shouldFetch: state =>
      !(state.shop.favorites.create[productId] && state.shop.favorites.create[productId].isCreating),
    meta: { productId },
    types: [SHOP_FAVORITE_CREATE_REQUEST, SHOP_FAVORITE_CREATE_SUCCESS, SHOP_FAVORITE_CREATE_FAILURE],
    endpoint: `/user/shop/favorites/${productId}`,
    method: 'POST'
  });
