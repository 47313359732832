import {
  SERVICE_EVENT_PRODUCTS_REQUEST,
  SERVICE_EVENT_PRODUCTS_SUCCESS,
  SERVICE_EVENT_PRODUCTS_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default serviceEventId =>
  authCall({
    shouldFetch: ({
      serviceEvents: {
        products: { [serviceEventId]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { serviceEventId },
    types: [SERVICE_EVENT_PRODUCTS_REQUEST, SERVICE_EVENT_PRODUCTS_SUCCESS, SERVICE_EVENT_PRODUCTS_FAILURE],
    endpoint: `/user/service-events/${serviceEventId}/products`,
    method: 'GET'
  });
