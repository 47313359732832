import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import { changeCustomer } from 'actions/customer';
import { SwitchButton } from '.';

export class Box extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.props.closeBox();
  }

  render() {
    const { customerId, items, closeBox, doChangeCustomer } = this.props;
    const className = classNames('dropdown-container clicker right arrow-bottom accounts-list', {
      one: items && items.length === 1,
      'multi-column': items && items.length > 16
    });

    return (
      <div className={className}>
        <div className="dropdown-list">
          {items &&
            items.map(item => (
              <SwitchButton
                key={item.id}
                item={item}
                isSelected={item.id === customerId}
                onClick={() => {
                  doChangeCustomer(item.id);
                  closeBox();
                }}
              />
            ))}
        </div>
      </div>
    );
  }
}

Box.propTypes = {
  customerId: PropTypes.string,
  items: PropTypes.array,
  closeBox: PropTypes.func.isRequired,
  doChangeCustomer: PropTypes.func.isRequired
};

const mapStateToProps = ({ current: { user, customer } }) => ({
  customerId: customer && customer.id,
  items: user && user.available_customers
});

const mapDispatchToProps = {
  doChangeCustomer: changeCustomer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(Box));
