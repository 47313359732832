import {
  EMAIL_NOTIFICATIONS_FETCH_REQUEST,
  EMAIL_NOTIFICATIONS_FETCH_SUCCESS,
  EMAIL_NOTIFICATIONS_FETCH_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default () =>
  authCall({
    types: [
      EMAIL_NOTIFICATIONS_FETCH_REQUEST,
      EMAIL_NOTIFICATIONS_FETCH_SUCCESS,
      EMAIL_NOTIFICATIONS_FETCH_FAILURE
    ],
    endpoint: '/user/users/current/preferences',
    method: 'GET'
  });
