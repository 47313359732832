import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Breadcrumb.less';

const labelize = item => (item.numberOfItems ? `${item.label} (${item.numberOfItems})` : item.label);

const Breadcrumb = ({ items }) =>
  items.length ? (
    <div className="breadcrumb headline">
      {items.map(item =>
        item.url ? (
          <Link key={item.key || item.url.replace('/', '-')} className="breadcrumb-child" to={item.url}>
            {labelize(item)}
          </Link>
        ) : (
          <span key={item.key || item.label.replace(' ', '-')}>{labelize(item)}</span>
        )
      )}
    </div>
  ) : null;

Breadcrumb.propTypes = {
  items: PropTypes.array.isRequired
};

export default pure(Breadcrumb);
