import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const CompanyDetails = ({ t, offer, offerToken, subType }) => {
  if (subType === 'Offer') {
    return (
      <div className="offer-box">
        <p className="offer-headline">{t('addresses')}</p>
        <div className="offer-address-box col-group">
          <div className="col-12 partner__info">
            <div className="col-6 col-m-12">
              <div className="partner__infobox left-box">
                <p className="offer-address-headline">{t('shipping-address')}</p>
                <p className="offer-addressline">{offer[offerToken].item.performance_corporate_name}</p>
                <p className="offer-addressline">
                  {offer[offerToken].item.contact_first_name}&nbsp;
                  {offer[offerToken].item.contact_last_name}
                </p>
                <p className="offer-addressline">{offer[offerToken].item.signature_email}</p>
                <p className="offer-addressline">{offer[offerToken].item.performance_address}</p>
              </div>
            </div>
            <div className="col-6 col-m-12">
              <div className="partner__infobox">
                <p className="offer-address-headline">{t('billing-address')}</p>
                <p className="offer-addressline">{offer[offerToken].item.billing_corporate_name}</p>
                <p className="offer-addressline">
                  {offer[offerToken].item.contact_first_name}&nbsp;
                  {offer[offerToken].item.contact_last_name}
                </p>
                <p className="offer-addressline">{offer[offerToken].item.signature_email}</p>
                <p className="offer-addressline">{offer[offerToken].item.billing_address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (subType === 'Performance Note') {
    return (
      <div className="offer-box"><p className="offer-headline">{t('customer-info')}</p>
        <div className="col-group">
          <div className="col-12 partner__info">
            <div className="col-6 col-m-12">
              <div className="partner__infobox left-box">
                <p>{t('shipping-address')}:<br />
                  <strong>{offer[offerToken].item.performance_address}</strong>
                </p>
              </div>
            </div>
            <div className="col-6 col-m-12">
              <div className="partner__infobox">
                <p>{t('cost-center')}
                  <strong> {offer[offerToken].item.performance_cost_center}</strong>
                </p>
                <p>{t('cost-payer')}
                  <strong> {offer[offerToken].item.performance_cost_payer}</strong>
                </p>
                <p>DLA: <strong>933</strong></p>
                <p>VA: <strong>2</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (<div />);
};

CompanyDetails.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired

};

export { CompanyDetails as PureComponent };
export default withTranslation('PartnerConfirmation')(CompanyDetails);
