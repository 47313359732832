import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setProduct } from 'actions/shop/cart';
import { DecreaseButton, IncreaseButton, QuantityInput } from '.';

class Modifiers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: props.quantity
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleIncrease = this.handleIncrease.bind(this);
    this.handleDecrease = this.handleDecrease.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quantity !== nextProps.quantity) {
      this.setState({
        quantity: nextProps.quantity
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleChange(quantity) {
    if (quantity !== this.state.quantity) {
      this.setState({ quantity });

      if (!Number.isNaN(quantity)) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.props.onChange(quantity), this.props.timeout);
      }
    }
  }

  handleIncrease() {
    this.handleChange((this.state.quantity || 0) + 1);
  }

  handleDecrease() {
    this.handleChange((this.state.quantity || 0) - 1);
  }

  render() {
    const { isDisabled } = this.props;

    return (
      <div className={`cart-product-changer ${isDisabled ? 'disabled' : ''}`}>
        <QuantityInput {...this.state} onChange={this.handleChange} />
        <DecreaseButton onClick={this.handleDecrease} />
        <IncreaseButton onClick={this.handleIncrease} />
      </div>
    );
  }
}

Modifiers.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  quantity: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  timeout: PropTypes.number
};

Modifiers.defaultProps = {
  timeout: 200
};

const mapStateToProps = ({
  shop: {
    cart: { isCheckingOut, isDeleting }
  }
}) => ({
  isDisabled: isCheckingOut || isDeleting
});

const mapDispatchToProps = (dispatch, { item }) => ({
  onChange: quantity => dispatch(setProduct(item, quantity))
});

export { Modifiers as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modifiers);
