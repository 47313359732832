import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { removeCoupon } from 'actions/shop/cart';

const RemoveCouponButton = ({ isVisible, isDisabled, isLoading, onClick }) =>
  isVisible ? (
    <button
      type="button"
      className={`voucher-remove ${isDisabled ? 'disabled' : ''} ${isLoading ? 'loading-light' : ''}`}
      onClick={onClick}
    />
  ) : null;

RemoveCouponButton.propTypes = {
  isVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { step, isOrdering, isRemovingCoupon, isAddingCoupon }
  }
}) => ({
  isVisible: step === SHOPPING_CART_STEP_CHECKOUT,
  isDisabled: isOrdering || isRemovingCoupon || isAddingCoupon,
  isLoading: isRemovingCoupon
});

const mapDispatchToProps = {
  onClick: removeCoupon
};

export { RemoveCouponButton as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveCouponButton);
