import {
  SERVICE_EVENT_FILTERS_REQUEST,
  SERVICE_EVENT_FILTERS_SUCCESS,
  SERVICE_EVENT_FILTERS_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FILTERS_REQUEST: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: true, fetchedAt: null } };
    }

    case SERVICE_EVENT_FILTERS_SUCCESS: {
      const {
        meta: { dataKey }
      } = action;

      return {
        ...state,
        [dataKey]: {
          ...state[dataKey],
          isFetching: false,
          fetchedAt: new Date(),
          types: action.payload.types || [],
          modes: action.payload.modes || [],
          states: action.payload.states || []
        }
      };
    }

    case SERVICE_EVENT_FILTERS_FAILURE: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: false } };
    }

    default:
      return state;
  }
};
