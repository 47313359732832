import {
  AUTH_LOGIN_SUCCESS,
  CUSTOMER_CHANGE_SUCCESS,
  USER_SUCCESS,
  CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  TERMS_UPDATE_SUCCESS
} from 'constants/actionTypes';

export default (state = null, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case CUSTOMER_CHANGE_SUCCESS:
      return action.payload.user.customer || action.payload.user.company; // Backward compatibility

    case USER_SUCCESS:
    case CUSTOMER_UPDATE_SUCCESS:
    case CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS:
      return action.payload.customer || action.payload.company; // Backward compatibility
    case TERMS_UPDATE_SUCCESS:
      return { ...state, has_accepted_tos: true };
    default:
      return state;
  }
};
