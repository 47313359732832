export const LOCATION_PATHNAME_DEFAULT = '/dashboard';
export const LOCATION_PATHNAME_TERMS_AND_CONDITIONS = '/terms';

export const LOCATION_QUERY_SED = 'sed';
export const LOCATION_QUERY_SERVICE_TYPE = 'service_type';
export const LOCATION_QUERY_PRODUCT = 'product';
export const LOCATION_QUERY_SE_RATING = 'rating';

export const LOCATION_HASH_FEATURE_HIGHLIGHTS = '#feature-highlights';
export const LOCATION_HASH_SERVICE_HIGHLIGHTS = '#service-highlights';
export const LOCATION_HASH_PRODUCT_MISSING = '#missing-product';
export const LOCATION_HASH_CALENDAR_SYNC = '#sync-calendar';
export const LOCATION_HASH_PASSWORD_SET = '#set-password';
export const LOCATION_HASH_PASSWORD_RESET = '#reset-password';
export const LOCATION_HASH_PASSWORD_CHANGE = '#change-password';
export const LOCATION_HASH_CUSTOMER_INFORMATION = '#customer-info-update';
export const LOCATION_HASH_SET_ACCOUNT_DATA = '#lead-data';
export const LOCATION_HASH_SED_TASKS = '#tasks';
export const LOCATION_HASH_SED_EVENTS = '#events';
export const LOCATION_HASH_SED_PRODUCTS = '#products';
export const LOCATION_HASH_CLEANING_TASKLIST = '#cleaning-tasklist';
export const LOCATION_HASH_PREMIUM_TASKLIST = '#premium-tasklist';
export const LOCATION_HASH_INTENSIVE_CLEANING_TASKLIST = '#intensive-cleaning-tasklist';
export const LOCATION_HASH_CONFIRM_OFFER = '#confirm-offer';
export const LOCATION_HASH_REJECT_OFFER = '#reject-offer';
export const LOCATION_HASH_REJECT_PARTNER = '#reject-offer-partner';
export const LOCATION_HASH_SUCCESS_PARTNER = '#success-partner';
export const LOCATION_HASH_CONFIRM_DEACTIVATE_MEMBER = '#confirm-deactivate-member';
export const LOCATION_HASH_CONFIRM_ACTIVATE_MEMBER = '#confirm-activate-member';
