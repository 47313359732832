import React, { Component } from 'react';

export default WrappedComponent => {
  class Bump extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isBumping: false
      };
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.numberOfItems > this.props.numberOfItems) {
        this.bump();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.bumpTimer);
    }

    setIsBumping(isBumping) {
      if (this.state.isBumping !== isBumping) {
        this.setState({ isBumping });
      }
    }

    bump() {
      this.setIsBumping(true);
      clearTimeout(this.bumpTimer);
      this.bumpTimer = setTimeout(() => this.setIsBumping(false), 400);
    }

    render() {
      const mergedProps = { ...this.props, ...this.state };
      return <WrappedComponent {...mergedProps} />;
    }
  }

  return Bump;
};
