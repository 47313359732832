import ServiceRequests from './ServiceRequests';

import ServiceRequest from './components/ServiceRequest';
import ServiceRequestCreate from './components/ServiceRequestCreate';
import ServiceRequestDetails from './components/ServiceRequestDetails';
import ServiceRequestList from './components/ServiceRequestList';
import ServiceRequestSearch from './components/ServiceRequestSearch';
import ServiceRequestStats from './components/ServiceRequestStats';
import ServiceRequestFilter from './components/ServiceRequestFilter';

export default ServiceRequests;
export {
  ServiceRequest,
  ServiceRequestCreate,
  ServiceRequestDetails,
  ServiceRequestList,
  ServiceRequestSearch,
  ServiceRequestStats,
  ServiceRequestFilter
};
