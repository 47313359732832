import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class RecurringCleaningLayout extends PureComponent {

  render() {
    const { t, activeStep, form, estimatedPrice } = this.props;
    const step1ActiveClass = activeStep === 'step1' ? 'active' : '';
    const step2ActiveClass = activeStep === 'step2' ? 'active' : '';

    return (
      <div className="pad cleaning-services recurring-cleaning">
        <div className="cleaning-services__stepbar">
          <div className={`cleaning-services__step step-1 ${step1ActiveClass}`}>
            {t('step-1')}
          </div>
          <div className="cleaning-services__ministep" />
          <div className={`cleaning-services__step step-2 ${step2ActiveClass}`}>
            {t('step-2')}
          </div>
          <div className="cleaning-services__ministep" />
          <div className="cleaning-services__step step-3">
            {t('step-3')}
          </div>
        </div>
        <div className="cleaning-services__container">
          <div className="cleaning-services__mainbox">
            <div className="cleaning-services__banner">
              <div className="cleaning-services__icon" />
              <div className="cleaning-services__description">
                <h2>{t('service-cleaning-title')}</h2>
                <p>{t('service-cleaning-description')}</p>
              </div>
            </div>
            <div className="cleaning-services__box">
              {form}
            </div>
          </div>
          <div className="cleaning-services__teaser">
            <div className="cleaning-services__price">
              <p>{t('estimatedPrice')}</p>
              <p>{estimatedPrice}</p>
              <span>{t('excludeTaxes')}</span>
            </div>
            <div className="cleaning-services__inclusive">
              <p>{t('includesCleaningSupplies')}</p>
              <p>{t('noTravelFees')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecurringCleaningLayout.propTypes = {
  t: PropTypes.func.isRequired,
  estimatedPrice: PropTypes.string.isRequired,
  activeStep: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired
};

export default withTranslation('Services/Cleaning')((RecurringCleaningLayout));
