import { hasError } from 'utils';
import {
  PASSWORD_RECOVER_REQUEST,
  PASSWORD_RECOVER_SUCCESS,
  PASSWORD_RECOVER_FAILURE
} from 'constants/actionTypes';
import { CAPTCHA_REQUIRED, CAPTCHA_INVALID } from '../../constants/errors';

export default (
  state = {
    isRecovering: false,
    isRecovered: false,
    isCaptchaRequired: false
  },
  action
) => {
  switch (action.type) {
    case PASSWORD_RECOVER_REQUEST:
      return { ...state, isRecovering: true, isRecovered: false };

    case PASSWORD_RECOVER_SUCCESS:
      return { ...state, isRecovering: false, isRecovered: true, isCaptchaRequired: false };

    case PASSWORD_RECOVER_FAILURE: {
      return {
        ...state,
        isRecovering: false,
        isCaptchaRequired: hasError(action.payload, CAPTCHA_REQUIRED, CAPTCHA_INVALID)
      };
    }

    default:
      return state;
  }
};
