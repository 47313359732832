import {
  SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST,
  SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS,
  SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return {
        ...state,
        [serviceEventDefinitionId]: { ...state[serviceEventDefinitionId], isFetching: true, fetchedAt: null }
      };
    }

    case SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return {
        ...state,
        [serviceEventDefinitionId]: {
          ...state[serviceEventDefinitionId],
          isFetching: false,
          fetchedAt: new Date(),
          items: action.payload.map(item => ({
            ...item,
            customer_product: item.customer_product ? item.customer_product.id : null
          }))
        }
      };
    }

    case SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE: {
      const {
        meta: { serviceEventDefinitionId }
      } = action;

      return { ...state, [serviceEventDefinitionId]: { ...state[serviceEventDefinitionId], isFetching: false } };
    }

    default:
      return state;
  }
};
