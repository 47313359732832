import { CASE_CREATE_REQUEST, CASE_CREATE_SUCCESS, CASE_CREATE_FAILURE } from 'constants/actionTypes';
import { authCall } from '../api';

export default (subject, body) =>
  authCall({
    shouldFetch: state => !state.cases.create.isCreating,
    types: [CASE_CREATE_REQUEST, CASE_CREATE_SUCCESS, CASE_CREATE_FAILURE],
    endpoint: '/user/cases',
    method: 'POST',
    body: { subject, body }
  });
