import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { propCreator } from 'components/HOC';
import { fetchServiceEvents as fetchServiceEventsAction } from 'actions/serviceEvents';
import { createGetServiceEvents } from 'selectors/serviceEvents';
import { Event } from '../..';

const Today = ({
  hasMore,
  isFetching,
  items,
  t,
  fetchServiceEvents,
  startDate,
  endDate,
  dataKey,
  currentPage,
  limit
}) => {
  const fetchData = useCallback(
    nextPage => {
      fetchServiceEvents(dataKey, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        page: nextPage || currentPage,
        order_by: 'asc',
        only_within_date_span: true,
        limit
      });
    },
    [currentPage, dataKey, endDate, fetchServiceEvents, limit, startDate]
  );

  const fetchNext = () => {
    fetchData(currentPage + 1);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, startDate]);

  return (
    <div className="header-module__column header-module__today">
      <p className="header-module__headline">{t('todays-events')}</p>
      {items && items.length > 0 && items.map(item => <Event key={item.id} item={item} />)}
      {items && !items.length && <div className="modul__row empty">{t('no-items')}</div>}
      {hasMore && (
        <button
          type="button"
          className={`header-module__more ${isFetching ? 'disabled loading' : ''}`}
          onClick={fetchNext}
        >
          {t('more')}
        </button>
      )}

      <Link to="/timeline" className="header-module__link flex-bottom">
        {t('see-calendar')}
      </Link>
    </div>
  );
};

Today.propTypes = {
  dataKey: PropTypes.string.isRequired,
  fetchServiceEvents: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  limit: PropTypes.number,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  t: PropTypes.func.isRequired
};

Today.defaultProps = {
  limit: 3
};

const createProps = ({ startDate, endDate }) => ({
  dataKey: `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`
});

const mapStateToProps = () => {
  const getServiceEvents = createGetServiceEvents();

  return (state, props) => {
    const {
      serviceEvents: {
        list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore } = {} }
      }
    } = state;

    return {
      isFetching,
      items: getServiceEvents(state, props),
      currentPage,
      hasMore
    };
  };
};

const mapDispatchToProps = dispatch => ({
  fetchServiceEvents: (dataKey, query) => dispatch(fetchServiceEventsAction(dataKey, query))
});

export default withTranslation('Dashboard/Header')(
  propCreator(createProps)(connect(mapStateToProps, mapDispatchToProps)(Today))
);
