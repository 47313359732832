import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

const IncreaseButton = ({ onClick }) => (
  <button type="button" className="cart-product-plus" onClick={onClick}>
    +
  </button>
);

IncreaseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default pure(IncreaseButton);
