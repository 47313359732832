import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { stringify } from 'query-string';
import { DocumentTitle, TabPane, TabTitle, TabContent, LargeIconButton } from 'common';
import { Modal } from 'components/HOC';

import './CalendarSync.less';

const createCalendarUrl = calendarToken => {
  if (!calendarToken) return '';

  return `${process.env.REACT_APP_SPONE_API_URL}/user/calendar?${stringify({
    calendar_id: calendarToken,
    client_root: window.location.origin
  })}`;
};

export class CalendarSync extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      calendarUrl: createCalendarUrl(props.calendarToken)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.calendarToken !== nextProps.calendarToken) {
      this.setState({
        calendarUrl: createCalendarUrl(nextProps.calendarToken)
      });
    }
  }

  selectTab(selectedTab) {
    if (selectedTab !== this.state.selectedTab) {
      this.setState({
        selectedTab
      });
    }
  }

  renderSubheading() {
    const { t } = this.props;
    const { calendarUrl } = this.state;

    return (
      <div className="tutorial-intro">
        <div className="list-heading">
          <span className="number-bubble">2</span>
          <h2>{t('step-two-heading')}</h2>
        </div>

        <span className="link-input">
          <input
            type="text"
            value={calendarUrl}
            contentEditable="false"
            readOnly="readOnly"
            onFocus={({ target: { select } }) => select()}
          />

          <button type="button" onClick={() => copy(calendarUrl)}>
            {t('copy')}
          </button>
        </span>

        <div className="list-heading">
          <span className="number-bubble">3</span>
          <h2>{t('step-three-heading')}</h2>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { selectedTab, calendarUrl } = this.state;
    const subheading = this.renderSubheading();

    return (
      <DocumentTitle title={t('title')}>
        {calendarUrl && (
          <div className="calendarmodal-inside">
            <h1>{t('heading')}</h1>
            <p>{t('introduction-paragraph')}</p>

            <div className="list-heading">
              <span className="number-bubble">1</span>
              <h2>{t('step-one-heading')}</h2>
            </div>

            <TabPane activeTabIndex={selectedTab}>
              <TabTitle>
                <LargeIconButton
                  icon="/images/calendarsync/icalendar-icon.png"
                  text={t('icalendar')}
                  onClick={() => this.selectTab(0)}
                />
              </TabTitle>

              <TabTitle>
                <LargeIconButton
                  icon="/images/calendarsync/outlook-icon.png"
                  text={t('outlook')}
                  onClick={() => this.selectTab(1)}
                />
              </TabTitle>

              <TabTitle>
                <LargeIconButton
                  icon="/images/calendarsync/gcalendar-icon.png"
                  text={t('gcalendar')}
                  onClick={() => this.selectTab(2)}
                />
              </TabTitle>

              <TabContent>
                {subheading}
                <div dangerouslySetInnerHTML={{ __html: t('tutorial-icalendar') }} />
              </TabContent>

              <TabContent>
                {subheading}
                <div dangerouslySetInnerHTML={{ __html: t('tutorial-outlook') }} />
              </TabContent>

              <TabContent>
                {subheading}
                <div dangerouslySetInnerHTML={{ __html: t('tutorial-gcalendar') }} />
              </TabContent>
            </TabPane>
          </div>
        )}
      </DocumentTitle>
    );
  }
}

CalendarSync.propTypes = {
  t: PropTypes.func,
  isLoading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  calendarToken: PropTypes.string
};

const mapStateToProps = ({
  current: { customer },
  users: {
    single: { isFetching: isLoading }
  }
}) => ({
  isLoading,
  calendarToken: customer && customer.calendar_token
});

export default withTranslation('Modal/CalendarSync')(
  connect(mapStateToProps)(Modal('Calendar Sync', { className: 'calendar-sync' })(CalendarSync))
);
