import React from 'react';
import PropTypes from 'prop-types';
import { currency } from 'components/HOC';
import { PartnerImage } from '../../../Product/Common';
import { translateEditing } from '../../HOC';
import { Sum } from '../../Common/SubCart';
import { Product } from '.';

const SubCart = ({ item, t, c }) => {
  const { partner, items, sum_net: sumNet } = item;
  const { minimum_order_value: minimumOrderValue } = partner;

  return (
    <div className="cart-item">
      <PartnerImage item={partner} />

      <span className="cart-partner">
        <strong className="cart-partner-name">{partner.name}</strong>
        {t('shopping-cart')}
      </span>

      <div className="col-group">
        <div className="cart-original">
          <div className="cart-productlist">
            {items.map(product => (
              <Product key={product.product.id} item={product} />
            ))}
          </div>

          <Sum item={item} />

          {minimumOrderValue > 0 && (
            <div>
              {sumNet >= minimumOrderValue && (
                <div className="cart-minimal-value reached">
                  {t('minimum-order-value-reached', { value: c(minimumOrderValue) })}
                </div>
              )}

              {sumNet < minimumOrderValue && (
                <div className="cart-minimal-value">
                  {t('minimum-order-value-not-reached', { value: c(minimumOrderValue) })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SubCart.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

export default currency(translateEditing(SubCart));
