import { SHOP_PARTNERS_SUCCESS } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_PARTNERS_SUCCESS:
      return action.payload.reduce((entities, item) => ({ ...entities, [item.id]: item }), {});

    default:
      return state;
  }
};
