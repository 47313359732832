import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, Select } from 'common/Form';
import { submitGegenbauerCleaning } from 'actions/serviceEvents';
import TwoOptions from '../Common/TwoOptions';

import './GegenbauerCleaning.less';

class GegenbauerCleaning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offerType: 'Offer',
      date: null,
      reqDate: null,
      time: null,
      reqTime: null,
      hours: '',
      tasks: [],
      isMisc: false,
      taskMisc: '',
      size: '',
      where: '',
      contact: '',
      errorTime: false,
      errorFields: false,
      errorContact: false,
      errorWhere: false,
      errorSize: false
    };
  }

  componentDidUpdate() {
    const { history, gegenbauerCleaning } = this.props;

    if (gegenbauerCleaning.isSuccessful) {
      history.push('/cleaning/cleaning-calc/step-3');
    }
  }

  createArray(val) {
    const { tasks } = this.state;
    let newArray = [];

    if (tasks.includes(val)) {
      newArray = tasks.filter(task => task !== val);
    } else {
      newArray = [...tasks, val];
    }

    if (val === 'misc') {
      this.setState(prevState => ({
        isMisc: !prevState.isMisc
      }));
    }

    this.setState({ tasks: [...newArray] });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { offerType, reqDate, reqTime, hours, tasks, taskMisc, isMisc, size, where, contact } = this.state;

    const request = {
      type: offerType,
      tasks: [...tasks],
      size,
      place: where,
      contact
    };
    if (reqDate && reqTime) {
      const dateTime = moment(`${reqDate} ${reqTime}`).toISOString();
      request.date = dateTime;
    }
    if (isMisc) {
      request.tasks.push(taskMisc);
    }
    if (hours) {
      request.hours = hours;
    }

    if (this.validate()) {
      this.props.submitGegenbauerCleaning(request);
    }
  }

  validate() {
    const { offerType, tasks, taskMisc, date, time, size, where, contact } = this.state;
    let isValid = true;

    if (tasks.length < 1 && !taskMisc) {
      this.setState({ errorFields: true });
      isValid = false;
    }
    if (offerType === 'Performance Note' && (!date || !time)) {
      this.setState({ errorTime: true });
      isValid = false;
    }
    if (!size) {
      this.setState({ errorSize: true });
      isValid = false;
    }
    if (!where) {
      this.setState({ errorWhere: true });
      isValid = false;
    }
    if (!contact) {
      this.setState({ errorContact: true });
      isValid = false;
    }

    return isValid;
  }

  render() {
    const { t } = this.props;
    const { isMisc, errorTime, errorFields, offerType, errorContact, errorWhere, errorSize } = this.state;

    const gegenbauerTaskList = [
      { name: t('svs-cleaning'), value: t('svs-cleaning') },
      { name: t('svs-glas'), value: t('svs-glas') },
      { name: t('svs-construction'), value: t('svs-construction') },
      { name: t('deep-carpet'), value: t('deep-carpet') },
      { name: t('deep-hardwood-no-coating'), value: t('deep-hardwood-no-coating') },
      { name: t('deep-hardwood-one-coating'), value: t('deep-hardwood-one-coating') },
      { name: t('deep-hardwood-two-coating'), value: t('deep-hardwood-two-coating') },
      { name: t('deep-between'), value: t('deep-between') },
      { name: t('deep-hard-chairs'), value: t('deep-hard-chairs') },
      { name: t('deep-padded-chairs'), value: t('deep-padded-chairs') },
      { name: t('deep-furnishings-many'), value: t('deep-furnishings-many') },
      { name: t('deep-furnishings-few'), value: t('deep-furnishings-few') }
    ];

    return (
      <div className="pad cleaning-services gegenbauer">
        <div className="cleaning-services__banner">
          <div className="cleaning-services__icon" />
          <div className="cleaning-services__description">
            <h2>{t('title')}</h2>
            <p>{t('description')}</p>
          </div>
        </div>
        <div className="cleaning-services__content">
          <form className="cleaning-services__form">
            <label className="cleaning-services__label" htmlFor="type">
              {t('choose-type')}
            </label>
            <TwoOptions
              name="type"
              initialValue={offerType}
              label1={t('offer')}
              label2={t('performance')}
              value1="Offer"
              value2="Performance Note"
              onChange={e => this.setState({ offerType: e.target.value })}
            />

            <label className="cleaning-services__label" htmlFor="datetime">
              {t('date-label')}
            </label>
            <div>
              {errorTime && <span className="error">{t('error-date')}</span>}
              <fieldset id="datetime">
                <div className="cleaning-services__times">
                  <SingleDatePicker
                    placeholder={t('date-placeholder')}
                    id="date"
                    date={this.state.date}
                    onDateChange={newDate =>
                      this.setState({
                        date: newDate,
                        reqDate: moment(newDate).format('YYYY-MM-DD')
                      })
                    }
                    showClearDate
                    numberOfMonths={1}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    isOutsideRange={() => false}
                    openDirection="up"
                  />
                  <TimePicker
                    id="time"
                    showSecond={false}
                    placeholder={t('time-placeholder')}
                    className="cleaning-services__timepick"
                    onChange={e => this.setState({ time: e, reqTime: moment(e).format('H:MM') })}
                  />
                </div>
              </fieldset>
            </div>
            <label className="cleaning-services__label" htmlFor="hours">
              {t('cleaningHours')}
            </label>
            <div className="cleaning-services__input-con">
              <input
                id="hours"
                value={this.state.hours}
                onChange={e => this.setState({ hours: e.target.value })}
                type="text"
              />
            </div>
            <label className="cleaning-services__label" htmlFor="cleaningOption">
              {t('cleaningOption')}
            </label>
            {errorFields && <span className="error">{t('error-choose')}</span>}
            <Select
              id="cleaningOption"
              onChange={e => this.setState({ tasks: [e.target.value] })}
              options={gegenbauerTaskList}
              dataType={t('choose-task')}
            />
            <fieldset id="add-checkbox">
              <div className="cleaning-services__checkboxes">
                <Checkbox
                  key="misc"
                  alignCheckboxLeft
                  className="cleaning-services__checkbox"
                  label={t('misc')}
                  id="partner-misc"
                  value={this.state.isMisc}
                  onChange={() =>
                    this.setState(prevState => ({
                      isMisc: !prevState.isMisc
                    }))
                  }
                />
              </div>
            </fieldset>
            {isMisc && (
              <div>
                <label className="cleaning-services__label" htmlFor="task-misc">
                  {t('please-describe')}
                </label>
                <div className="cleaning-services__input-con">
                  <input
                    id="task-misc"
                    value={this.state.taskMisc}
                    onChange={e => this.setState({ taskMisc: e.target.value })}
                    type="text"
                  />
                </div>
              </div>
            )}

            <label className="cleaning-services__label" htmlFor="size">
              {t('size')}
            </label>
            {errorSize && <span className="error">{t('error-size')}</span>}
            <div className="cleaning-services__input-con">
              <input
                id="size"
                value={this.state.size}
                onChange={e => this.setState({ size: e.target.value })}
                type="text"
              />
            </div>
            <label className="cleaning-services__label" htmlFor="where">
              {t('where')}
            </label>
            {errorWhere && <span className="error">{t('error-where')}</span>}
            <div className="cleaning-services__input-con">
              <input
                id="where"
                value={this.state.where}
                onChange={e => this.setState({ where: e.target.value })}
                type="text"
              />
            </div>
            <label className="cleaning-services__label" htmlFor="contact">
              {t('contact')}
            </label>
            {errorContact && <span className="error">{t('error-contact')}</span>}
            <div className="cleaning-services__input-con">
              <input
                id="contact"
                value={this.state.contact}
                onChange={e => this.setState({ contact: e.target.value })}
                type="text"
              />
            </div>
            <div className="cleaning-services__buttongroup">
              <button type="submit" className="cleaning-services__submit" onClick={e => this.handleSubmit(e)}>
                {t('submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

GegenbauerCleaning.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { gegenbauerCleaning } }) => ({
  gegenbauerCleaning
});

export default withTranslation('Services/GegenbauerCleaning')(
  withRouter(
    connect(
      mapStateToProps,
      { submitGegenbauerCleaning }
    )(withTranslation('Services/GegenbauerCleaning')(GegenbauerCleaning))
  )
);
