import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';

const CleaningTasklist = ({ t }) => (
  <DocumentTitle title={t('title')}>
    <div className="modal-flex">
      <div className="cleaning-tasklist">
        <div className="cleaning-tasklist__header">
          <p className="cleaning-tasklist__header-title">{t('cleaning-intensive-cleaning-header')}</p>
        </div>

        <div className="cleaning-tasklist__body offices">
          <p className="cleaning-tasklist__body-title">{t('cleaning-offices')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-baskets')}</p>
            <p className="row-body">{t('cleaning-wet-wipe-bag')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}</p>
            <p className="row-body">{t('cleaning-vacuum-clean-mop')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-cleared-off-desks')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-shelfs-180')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-windowswills')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-fingerprints')}</p>
            <p className="row-body">{t('cleaning-clean-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-chairs-non-upholstered')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}*</p>
            <p className="row-body">{t('cleaning-deep-machine-cleaning')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-office-equipment')}*</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-bottles-kitchenware-cutlery')}*</p>
            <p className="row-body">{t('cleaning-collect-wash')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-baseboards')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-radiators')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-doors-doorframes')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-light-switches-power-outlets')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-furniture')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-spider-web')}</p>
            <p className="row-body">{t('cleaning-remove')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body arreas">
          <p className="cleaning-tasklist__body-title">{t('cleaning-traffic-areas')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}</p>
            <p className="row-body">{t('cleaning-vacuum-clean-mop')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-fingerprints')}</p>
            <p className="row-body">{t('cleaning-clean-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}*</p>
            <p className="row-body">{t('cleaning-deep-machine-cleaning')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-baseboards')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-radiators')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-doors-doorframes')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-light-switches-power-outlets')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-furniture')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-spider-web')}</p>
            <p className="row-body">{t('cleaning-remove')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body kitchen">
          <p className="cleaning-tasklist__body-title">{t('cleaning-kitchen')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-baskets')}</p>
            <p className="row-body">{t('cleaning-wet-wipe-bag')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}</p>
            <p className="row-body">{t('cleaning-wet-fog-vacuum')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-cleared-off-desks')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-fingerprints')}</p>
            <p className="row-body">{t('cleaning-clean-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-shelfs-180')}</p>
            <p className="row-body">{t('cleaning-disinfectant-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-oven')}</p>
            <p className="row-body">{t('cleaning-clean-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-refrigerator')}</p>
            <p className="row-body">{t('cleaning-clean-interior-exterior')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-microwave')}</p>
            <p className="row-body">{t('cleaning-clean-interior-exterior')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-dishwasher')}</p>
            <p className="row-body">{t('cleaning-empty-reload-start')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-sink-area')}</p>
            <p className="row-body">{t('cleaning-disinfect')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}*</p>
            <p className="row-body">{t('cleaning-deep-machine-cleaning')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-baseboards')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-radiators')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-doors-doorframes')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-light-switches-power-outlets')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-furniture')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-spider-web')}</p>
            <p className="row-body">{t('cleaning-remove')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body plumbing">
          <p className="cleaning-tasklist__body-title">{t('cleaning-plumbing')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-baskets')}</p>
            <p className="row-body">{t('cleaning-wet-wipe-bag')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-hard-floors')}</p>
            <p className="row-body">{t('cleaning-disinfectant-wipe')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-wc-objects')}</p>
            <p className="row-body">{t('cleaning-disinfectant-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-urinal-walls')}</p>
            <p className="row-body">{t('cleaning-disinfectant-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-toilets-wall-tiles')}</p>
            <p className="row-body">{t('cleaning-disinfectant-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-mirrors-shelves-fittings')}</p>
            <p className="row-body">{t('cleaning-wet-wipe')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-fingerprints')}</p>
            <p className="row-body">{t('cleaning-clean-polish')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-floors')}*</p>
            <p className="row-body">{t('cleaning-deep-machine-cleaning')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-fill-paper')}</p>
            <p className="row-body">{t('cleaning-fill-customer')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-baseboards')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-radiators')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-doors-doorframes')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-light-switches-power-outlets')}</p>
            <p className="row-body">{t('cleaning-wipe-down-dust')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body allareas">
          <p className="cleaning-tasklist__body-title">{t('cleaning-all-areas')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-upholstery')}</p>
            <p className="row-body">{t('cleaning-vacuum')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-components')}</p>
            <p className="row-body">{t('cleaning-cleanse')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-spider-web')}</p>
            <p className="row-body">{t('cleaning-remove')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-dishes')}*</p>
            <p className="row-body">{t('cleaning-collect')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body asterisk">
          <div className="row">
            <p className="row-body">{t('cleaning-only-premium')}</p>
          </div>
        </div>
      </div>
    </div>
  </DocumentTitle>
);

CleaningTasklist.propTypes = {
  t: PropTypes.func.isRequired
};

export { CleaningTasklist as PureComponent };
export default withTranslation('Modal/CleaningTasklist')(
  Modal('Intensive Cleaning', { className: 'cleaning-tasklist-modal' })(CleaningTasklist)
);
