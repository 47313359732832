import {
  SERVICE_EVENT_DEPOSIT_REQUEST,
  SERVICE_EVENT_DEPOSIT_SUCCESS,
  SERVICE_EVENT_DEPOSIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (serviceEventId, deposit) =>
  authCall({
    shouldFetch: state =>
      !(state.serviceEvents.deposit[serviceEventId] && state.serviceEvents.deposit[serviceEventId].isNoting),
    meta: { serviceEventId },
    types: [SERVICE_EVENT_DEPOSIT_REQUEST, SERVICE_EVENT_DEPOSIT_SUCCESS, SERVICE_EVENT_DEPOSIT_FAILURE],
    endpoint: `/user/service-events/${serviceEventId}/note`,
    method: 'PUT',
    body: {
      deposit
    }
  });
