import {
  SERVICE_EVENT_FEEDBACK_REQUEST,
  SERVICE_EVENT_FEEDBACK_SUCCESS,
  SERVICE_EVENT_FEEDBACK_FAILURE,
  SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST,
  SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS,
  SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';
import { CONTENT_TYPE, CONTENT_TYPE_JSON } from 'constants/headers';

export const fetchFeedback = (id, forceFech) =>
  authCall({
    shouldFetch: () => ({
      serviceEvents: {
        feedback: {
          feedback: {
            [id]: { isFetching, fetchedAt }
          }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt, forceFech),
    types: [SERVICE_EVENT_FEEDBACK_REQUEST, SERVICE_EVENT_FEEDBACK_SUCCESS, SERVICE_EVENT_FEEDBACK_FAILURE],
    meta: { id },
    endpoint: `/user/service-events/${id}/feedback`,
    method: 'GET'
  });

export const sendFeedback = payload =>
  authCall({
    shouldFetch: () => ({
      serviceEvents: {
        feedback: {
          submitFeedback: {
            [payload.id]: { isSubmitted, isSubmitting }
          }
        }
      }
    }) => shouldFetch(isSubmitted, isSubmitting, true),
    types: [
      SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST,
      SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS,
      SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE
    ],
    endpoint: `/user/service-events/${payload.id}/feedback/cleaning`,
    meta: { id: payload.id },
    method: 'PUT',
    headers: {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON
    },
    body: {
      is_completed: payload.isCompleted,
      partially_done_comment: payload.feedbackComment,
      service_provided: payload.isServiceProvided
    }
  });
