import {
  LOCATION_SET,
  SEARCH_SET,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  USER_SUCCESS,
  SHOP_CART_SHOW,
  SHOP_CART_CHECKOUT_SUCCESS,
  SHOP_CART_PRODUCT_ADD_SUCCESS,
  SHOP_CART_PRODUCT_REMOVE_SUCCESS,
  SHOP_CART_PAYMENT_METHOD_SELECT,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS,
  SHOP_CART_ORDER_REQUEST,
  SHOP_CART_ORDER_SUCCESS,
  TRACKING_PRODUCT_CLICK,
  TRACKING_PRODUCT_VIEW,
  TRACKING_PRODUCT_LIST_VIEW,
  TRACKING_PROMOTION_VIEW,
  TRACKING_PROMOTION_CLICK,
  TRACKING_PROMOTION_CLOSE,
  TRACKING_MODAL_DENIED,
  TRACKING_MODAL_CONFIRM,
  TRACKING_MODAL_OPEN,
  TRACKING_MODAL_CLOSE,
  TRACKING_USER_THEME_SAVE
} from 'constants/actionTypes';
import { PAYMENT_METHOD_CREDIT_CARD } from '../constants/paymentMethods';
import { getAnalytics } from '../tracking';

const getCurrency = state => state.i18n.currency;
const getCart = state => state.shop.cart.cart;
const getPartner = (partnerId, state) => state.shop.partners.entities[partnerId];
const getProduct = (product, quantity, state) => {
  const partner = getPartner(product.partner_id, state);

  return {
    product_id: product.id,
    sku: product.id,
    category_id: product.category_id,
    category: 'ecommerce',
    partner_id: product.partner_id,
    partner: partner && partner.name,
    name: product.name,
    price: product.unit_price,
    quantity,
    position: 1,
    label: `${product.name} - ${product.id}`
  };
};
const getDiscount = discount => (discount && discount.amount) || undefined;
const getPromotion = promotion => ({
  ...promotion,
  category: 'ecommerce',
  label: `${promotion.promotion_id} - ${promotion.creative}`
});

export default store => next => action => {
  next(action);

  const analytics = getAnalytics();
  if (!analytics) return;

  const state = store.getState();

  switch (action.type) {
    case LOCATION_SET:
      analytics.page();
      break;

    case SEARCH_SET:
      if (action.q && !action.isTyping) {
        analytics.track(
          'Product Searched',
          {
            query: action.q,
            category: 'ecommerce',
            label: action.q
          },
          { context: { ip: '0.0.0.0' } }
        );
      }
      break;

    case AUTH_LOGIN_SUCCESS:
    case USER_SUCCESS: {
      const user = action.type === USER_SUCCESS ? action.payload : action.payload.user;
      const customer = user.customer || user.company; // Backward compatibility

      analytics.identify(user.id, {
        userType: 'user',
        email: user.email
      });
      analytics.group(customer.id, {
        companyName: customer.name,
        salesforceId: customer.id
      });
      break;
    }

    case AUTH_LOGOUT_SUCCESS:
      analytics.reset();
      break;

    case SHOP_CART_SHOW: {
      const cart = getCart(state);

      if (cart.sub_carts) {
        cart.sub_carts.forEach(subCart => {
          const products = subCart.items.map(item => ({ product_id: item.product.id }));

          if (products.length > 0) {
            analytics.track(
              'Cart Viewed',
              {
                cart_id: `${cart.id}/${subCart.id}`,
                category: 'ecommerce',
                label: `${cart.id}/${subCart.id}`,
                products
              },
              { context: { ip: '0.0.0.0' } }
            );
          }
        });
      }
      break;
    }

    case SHOP_CART_CHECKOUT_SUCCESS: {
      const cart = getCart(state);
      const currency = getCurrency(state);

      if (cart.sub_carts) {
        cart.sub_carts.forEach(subCart => {
          analytics.track(
            'Checkout Started',
            {
              order_id: `${cart.id}/${subCart.id}`,
              value: subCart.sum_gross,
              shipping: subCart.delivery_fee,
              tax: subCart.sum_tax,
              discount: getDiscount(subCart.discount),
              currency,
              category: 'ecommerce',
              label: `${cart.id}/${subCart.id}`,
              products: subCart.items.map(item => getProduct(item.product, item.quantity, state))
            },
            { context: { ip: '0.0.0.0' } }
          );

          analytics.track(
            'Checkout Step Viewed',
            {
              checkout_id: `${cart.id}/${subCart.id}`,
              step: 1,
              category: 'ecommerce',
              label: 1
            },
            { context: { ip: '0.0.0.0' } }
          );
        });
      }
      break;
    }

    case SHOP_CART_ORDER_REQUEST: {
      const cart = getCart(state);

      if (cart.sub_carts) {
        cart.sub_carts.forEach(subCart => {
          analytics.track(
            'Checkout Step Completed',
            {
              checkout_id: `${cart.id}/${subCart.id}`,
              step: 1,
              shipping_method: 'Standard',
              preferedDate: subCart.dueDate && subCart.dueDate,
              deliveryType: subCart.rrule,
              category: 'ecommerce',
              label: 1
            },
            { context: { ip: '0.0.0.0' } }
          );
        });
      }
      break;
    }

    case SHOP_CART_ORDER_SUCCESS: {
      const cart = getCart(state);
      const currency = getCurrency(state);

      if (cart.sub_carts) {
        cart.sub_carts.forEach(subCart => {
          analytics.track(
            'Order Completed',
            {
              order_id: `${cart.id}/${subCart.id}`,
              value: subCart.sum_gross,
              revenue: subCart.sum_net,
              shipping: subCart.delivery_fee,
              tax: subCart.sum_tax,
              discount: getDiscount(subCart.discount),
              currency,
              category: 'ecommerce',
              label: `${cart.id}/${subCart.id}`,
              products: subCart.items.map(item => getProduct(item.product, item.quantity, state))
            },
            { context: { ip: '0.0.0.0' } }
          );
        });
      }
      break;
    }

    case SHOP_CART_PAYMENT_METHOD_SELECT:
      if (action.method) {
        analytics.track(
          `${action.method} Clicked`,
          {
            category: 'ecommerce',
            label: 'checkout'
          },
          { context: { ip: '0.0.0.0' } }
        );
      }
      break;

    case SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS:
      analytics.track(
        `${PAYMENT_METHOD_CREDIT_CARD} Added`,
        {
          category: 'ecommerce',
          label: 'checkout'
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    case SHOP_CART_PRODUCT_ADD_SUCCESS: {
      const {
        meta: { product, quantity },
        payload: { id }
      } = action;

      analytics.track(
        'Product Added',
        {
          ...getProduct(product, quantity, state),
          cart_id: `${id}/${product.partner_id}`
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;
    }

    case SHOP_CART_PRODUCT_REMOVE_SUCCESS: {
      const {
        meta: { product, quantity },
        payload: { id }
      } = action;

      analytics.track(
        'Product Removed',
        {
          ...getProduct(product, quantity, state),
          cart_id: `${id}/${product.partner_id}`
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;
    }

    case TRACKING_PRODUCT_CLICK:
      analytics.track('Product Clicked', getProduct(action.product, 1, state), {
        context: { ip: '0.0.0.0' }
      });
      break;

    case TRACKING_PRODUCT_VIEW:
      analytics.track('Product Viewed', getProduct(action.product, 1, state), {
        context: { ip: '0.0.0.0' }
      });
      break;

    case TRACKING_PRODUCT_LIST_VIEW: {
      const { listId, products } = action;

      analytics.track(
        'Product List Viewed',
        {
          list_id: listId,
          products,
          category: 'ecommerce',
          label: listId
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;
    }

    case TRACKING_MODAL_OPEN:
      analytics.track(
        'Modal Opened',
        {
          label: action.label
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    case TRACKING_MODAL_CLOSE:
      analytics.track(
        'Modal Closed',
        {
          label: action.label
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    case TRACKING_MODAL_CONFIRM:
      analytics.track(
        'Modal Confirmed',
        {
          label: action.label
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    case TRACKING_MODAL_DENIED:
      analytics.track(
        'Modal Denied',
        {
          label: action.label
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    case TRACKING_PROMOTION_VIEW:
      analytics.track('Promotion Viewed', getPromotion(action.promotion), { context: { ip: '0.0.0.0' } });
      break;

    case TRACKING_PROMOTION_CLICK:
      analytics.track('Promotion Clicked', getPromotion(action.promotion), { context: { ip: '0.0.0.0' } });
      break;

    case TRACKING_PROMOTION_CLOSE:
      analytics.track('Promotion Closed', getPromotion(action.promotion), { context: { ip: '0.0.0.0' } });
      break;

    case TRACKING_USER_THEME_SAVE:
      analytics.track(
        'Theme saved',
        {
          label: 'userThemeChanged',
          category: 'All'
        },
        { context: { ip: '0.0.0.0' } }
      );
      break;

    default:
      break;
  }
};
