import { SERVICE_EVENT_REQUEST, SERVICE_EVENT_SUCCESS, SERVICE_EVENT_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default serviceEventId =>
  authCall({
    shouldFetch: ({
      serviceEvents: {
        single: { [serviceEventId]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { serviceEventId },
    types: [SERVICE_EVENT_REQUEST, SERVICE_EVENT_SUCCESS, SERVICE_EVENT_FAILURE],
    endpoint: `/user/service-events/${serviceEventId}`,
    method: 'GET'
  });
