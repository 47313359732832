import { CASE_CREATE_REQUEST, CASE_CREATE_SUCCESS, CASE_CREATE_FAILURE } from 'constants/actionTypes';

export default (
  state = {
    isCreating: false,
    isCreated: false
  },
  action
) => {
  switch (action.type) {
    case CASE_CREATE_REQUEST:
      return { ...state, isCreating: true, isCreated: false };

    case CASE_CREATE_SUCCESS:
      return { ...state, isCreating: false, isCreated: true };

    case CASE_CREATE_FAILURE:
      return { ...state, isCreating: false };

    default:
      return state;
  }
};
