import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { propCreator } from 'components/HOC';
import { fetchProducts } from 'actions/shop/products';
import { replaceLocation } from 'actions/location';
import { createGetProducts, getCategory } from 'selectors/shop';
import Overview from '../Overview/Overview';

const createTitle = ({ category, t }) =>
  [
    category && category.name,
    category && category.parent && category.parent.name,
    category && category.parent && category.parent.parent && category.parent.parent.name,
    t('Shop:headline')
  ]
    .filter(s => !!s)
    .join(' - ');

const createBreadcrumbItems = ({ category, numberOfItems, t }) => {
  const category1 = category && category.parent;
  const category2 = category && category.parent && category.parent.parent;

  return [
    { url: '/shop', label: t('Shop:headline') },
    category && { url: '/shop/all', label: t('headline') },
    category2 && {
      key: category2.id,
      url: `/shop/category/${category2.id}/${category2.slug}`,
      label: category2.name
    },
    category1 && {
      key: category1.id,
      url: `/shop/category/${category1.id}/${category1.slug}`,
      label: category1.name
    },
    category && {
      key: category.id,
      label: category.name,
      numberOfItems
    }
  ].filter(s => !!s);
};

class CategoryOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: createTitle(props),
      breadcrumbItems: createBreadcrumbItems(props)
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.t !== nextProps.t ||
      this.props.category !== nextProps.category ||
      this.props.numberOfItems !== nextProps.numberOfItems
    ) {
      this.setState({
        title: createTitle(nextProps),
        breadcrumbItems: createBreadcrumbItems(nextProps)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.category === false && this.props.category !== prevProps.category) {
      this.props.dispatch(replaceLocation('/shop'));
    }

    if (this.props.categoryId !== prevProps.categoryId) {
      this.fetchData();
    }
  }

  fetchData(nextPage) {
    const { dispatch, dataKey, categoryId, currentPage, limit } = this.props;

    dispatch(
      fetchProducts(dataKey, {
        category_ids: categoryId,
        page: nextPage || currentPage,
        limit
      })
    );
  }

  fetchNext() {
    this.fetchData(this.props.currentPage + 1);
  }

  render() {
    const { fetchData, fetchNext } = this;
    const { title, breadcrumbItems } = this.state;
    const { categoryId, isFetching, items, hasMore } = this.props;
    const passedProps = { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext };

    return <Overview {...passedProps} trackingListId={`category_${categoryId}_list`} />;
  }
}

CategoryOverview.propTypes = {
  categoryId: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  dataKey: PropTypes.string.isRequired,
  limit: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  numberOfItems: PropTypes.number,
  t: PropTypes.func.isRequired
};

CategoryOverview.defaultProps = {
  limit: 25
};

const createProps = ({
  match: {
    params: { categoryId }
  }
}) => ({
  categoryId,
  dataKey: `ctg_${categoryId}`
});

const mapStateToProps = () => {
  const getProducts = createGetProducts();

  return (state, props) => {
    const {
      shop: {
        products: {
          list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore, numberOfItems } = {} }
        }
      }
    } = state;

    return {
      category: getCategory(state, props),
      isFetching,
      items: getProducts(state, props),
      currentPage,
      hasMore,
      numberOfItems
    };
  };
};

export default withTranslation('Shop/ProductOverview')(
  propCreator(createProps)(connect(mapStateToProps)(CategoryOverview))
);
