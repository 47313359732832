import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const EditButton = ({ isLoading, isDisabled, onClick, t }) => (
  <button
    type="button"
    className={`sed-reorder ${isLoading ? 'loading-light' : ''} ${isDisabled ? 'disabled' : ''}`}
    onClick={onClick}
  >
    {t('edit')}
  </button>
);

EditButton.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isEditing: isLoading }
  }
}) => ({ isLoading });

export { EditButton as PureComponent };
export default withTranslation('Modal/ServiceEvent')(connect(mapStateToProps)(EditButton));
