import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Category } from '.';

const CategoryDropdown = ({ initialItems, isActive, level, onClick }) => (
  <ul className={`shop-nav-subcategory dropdown-list ${isActive ? 'active dropdown-container' : ''}`}>
    {initialItems.children.map(subItem => (
      <Category key={subItem.id} item={subItem} level={level} onClick={onClick} />
    ))}
  </ul>
);

CategoryDropdown.propTypes = {
  initialItems: PropTypes.object,
  isActive: PropTypes.bool,
  level: PropTypes.number,
  onClick: PropTypes.func.isRequired
};

CategoryDropdown.defaultProps = {
  isActive: false,
  level: 0
};

export default pure(CategoryDropdown);
