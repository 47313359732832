import {
  SERVICE_EVENTS_SUCCESS,
  SERVICE_EVENT_SUCCESS,
  SERVICE_EVENT_RATE_SUCCESS,
  SERVICE_EVENT_NOTE_SUCCESS,
  SERVICE_EVENT_DEPOSIT_SUCCESS,
  SERVICE_EVENT_STATUS_CHANGE_SUCCESS
} from 'constants/actionTypes';
import { serviceEvent } from '../../models';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENTS_SUCCESS:
      return action.payload.reduce((entities, item) => ({ ...entities, [item.id]: serviceEvent(item) }), state);

    case SERVICE_EVENT_SUCCESS:
      return { ...state, [action.payload.id]: serviceEvent(action.payload) };

    case SERVICE_EVENT_RATE_SUCCESS:
    case SERVICE_EVENT_NOTE_SUCCESS:
    case SERVICE_EVENT_DEPOSIT_SUCCESS:
    case SERVICE_EVENT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        [action.payload.service_event.id]: {
          ...state[action.payload.service_event.id],
          ...serviceEvent(action.payload.service_event)
        }
      };

    default:
      return state;
  }
};
