import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setList, setGrid } from 'actions/shop/view';

import './ToggleViewButton.less';

export const ToggleViewButton = ({ isDisabled, isList, isGrid, onClickGrid, onClickList }) => (
  <div className={`product-view-toggle ${isDisabled ? 'disabled' : ''}`}>
    <button type="button" className={`grid-toggle-button ${isGrid ? 'active' : ''}`} onClick={onClickGrid} />

    <button type="button" className={`list-toggle-button ${isList ? 'active' : ''}`} onClick={onClickList} />
  </div>
);

ToggleViewButton.propTypes = {
  isDisabled: PropTypes.bool,
  isList: PropTypes.bool,
  isGrid: PropTypes.bool,
  onClickGrid: PropTypes.func.isRequired,
  onClickList: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    view: { isList, isGrid }
  }
}) => ({ isList, isGrid });

const mapDispatchToProps = {
  onClickGrid: setGrid,
  onClickList: setList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleViewButton);
