import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Modal } from 'components/HOC';
import { submitOffer as sendData } from 'actions/partnerConfirmation';

export class DeclinePartner extends Component {
  constructor(props) {
    super(props);

    this.state = { lostReasonDetails: '' };
    this.offerToken = window.location.pathname.split('/executive/')[1]; //eslint-disable-line
  }

  componentWillReceiveProps(nextProps) {
    const { isSubmitted, isSubmitting } = nextProps.submitOffer ? nextProps.submitOffer : false;

    if (isSubmitted && !isSubmitting) {
      this.props.history.push(`/executive/${this.offerToken}#success-executive`);
    }
  }

  handleChange = ({ target: { name, value } }) => {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  };

  handleSendOfferClick = e => {
    e.preventDefault();
    const { lostReason } = this.state;
    const { handleSubmit, offer } = this.props;
    const { performances } = offer[this.offerToken].item;

    const offerData = {};
    offerData.public_tokens = [];
    offerData.lost_reason_details = lostReason;

    performances.forEach(item => {
      offerData.public_tokens.push({
        public_token: item.public_token,
        action: 'REJECT'
      });
    });

    handleSubmit(offerData);
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  validate() {
    const { lostReasonDetails } = this.state;
    let isValid = false;

    if (lostReasonDetails) {
      this.setState({ errorReject: false });
      isValid = true;
    } else {
      this.setState({ errorReject: true });
      isValid = false;
    }
    return isValid;
  }

  render() {
    const { t, submitOffer } = this.props;
    const { lostReasonDetails } = this.state;

    return (
      <div>
        <h2 className="headline">{t('headline-reject')}</h2>
        <form onSubmit={this.handleSendOfferClick}>
          <fieldset disabled={Object.keys(submitOffer).length && submitOffer.isSubmitting}>
            <div className="col-group">
              <label className="col-12" htmlFor="lostReasonDetails">
                {t('reject-reason')}
              </label>
              <textarea
                className="offer__textarea col-12"
                type="text"
                onChange={this.handleChange}
                id="lostReasonDetails"
                name="lostReasonDetails"
                value={lostReasonDetails}
              />
              <div className="col-12">
                <div className="col-6 left">
                  <button type="button" className="button button-cancel col-12" onClick={this.handleCloseModal}>
                    {t('cancel')}
                  </button>
                </div>
                <div className="col-6 right">
                  <button
                    type="submit"
                    className={`button button-submit col-12 ${
                      Object.keys(submitOffer).length && submitOffer.isSubmitting ? 'loading-light' : ''
                    }`}
                  >
                    {t('reject-offer')}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

DeclinePartner.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  offer: PropTypes.object,
  submitOffer: PropTypes.object
};

const mapStateToProps = ({ partnerConfirmation: { offer, submitOffer } }) => ({ offer, submitOffer });

const mapDispatchToProps = dispatch => ({
  handleSubmit: offerToken => dispatch(sendData(offerToken))
});

export { DeclinePartner as PureComponent };
export default withTranslation('PartnerConfirmation')(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Modal('Confirm Offer Modal', { className: 'confirm-offer-modal' })(DeclinePartner))
  )
);
