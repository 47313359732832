import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';
import Product from "../../Product";

const ListTeaser = ({ location, locationText, items }) => (
  <div className="list-products-container">
    {items.map(item => (
      <Product key={item.id} item={item} />
    ))}

    <div className="show-more">
      <Link to={location}>{locationText}</Link>
    </div>
  </div>
);

ListTeaser.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  locationText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default pure(ListTeaser);
