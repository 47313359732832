import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Product as SubCartProduct } from '../../../Common/SubCart';
import { Modifiers, RemoveButton } from '.';

const Product = ({ item }) => {
  const { product, quantity } = item;

  return (
    <SubCartProduct item={item}>
      <RemoveButton item={product} />
      <Modifiers item={product} quantity={quantity} />
    </SubCartProduct>
  );
};

Product.propTypes = {
  item: PropTypes.object.isRequired
};

export { Product as PureComponent };
export default pure(Product);
