import {
  CUSTOMER_SHIPPING_ADDRESS_CHANGE_REQUEST,
  CUSTOMER_SHIPPING_ADDRESS_CHANGE_SUCCESS,
  CUSTOMER_SHIPPING_ADDRESS_CHANGE_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isChanging: false,
    isChanged: false
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_SHIPPING_ADDRESS_CHANGE_REQUEST:
      return { ...state, isChanging: true, isChanged: false };

    case CUSTOMER_SHIPPING_ADDRESS_CHANGE_SUCCESS:
      return { ...state, isChanging: false, isChanged: true };

    case CUSTOMER_SHIPPING_ADDRESS_CHANGE_FAILURE:
      return { ...state, isChanging: false };

    default:
      return state;
  }
};
