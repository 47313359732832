const officeDataRequiredForm = [
  {
    className: 'company-employees',
    id: 'company-employees',
    label: 'number-of-employees',
    name: 'numberOfEmployees',
    parentClass: 'form-group',
    type: 'number',
    min: '1',
    addon: 'employees'
  },
  {
    className: 'company-floor-space',
    id: 'company-floor-space',
    label: 'floorspace',
    name: 'floorSpace',
    parentClass: 'form-group',
    type: 'number',
    min: '1',
    addon: 'sqm'
  },
  {
    className: 'company-floor-to-be-cleaned',
    id: 'company-floor-to-be-cleaned',
    label: 'which-floors',
    name: 'floorToBeCleaned',
    parentClass: 'form-group',
    type: 'number',
    min: '1',
    placeholder: 'Please type the floor'
  },
  {
    type: 'radio',
    label: 'has-elevator-escalator',
    name: 'hasElevator',
    className: 'company-elevator-radio-group',
    id: 'company-elevator-radio-group',
    options: [
      {
        id: 'company-elevator-option-yes',
        label: 'yes',
        defaultChecked: true,
        value: true
      },
      {
        id: 'company-elevator-option-no',
        label: 'no',
        defaultChecked: false,
        value: false
      }
    ]
  }
];

const officeDataAdditionalForm = [
  {
    type: 'timeGroup',
    className: 'opening-hours',
    id: 'opening-hours',
    label: 'opening-hours',
    options: [
      {
        id: 'opening-hours-from',
        className: 'opening-hours-from',
        name: 'openingHoursFrom',
        showSecond: false,
        placeholder: ''
      },
      {
        id: 'opening-hours-to',
        className: 'opening-hours-to',
        name: 'openingHoursTo',
        showSecond: false,
        placeholder: ''
      }
    ]
  },
  {
    type: 'textGroup',
    className: 'contact-person-name',
    id: 'contact-person-name',
    label: 'contact-person',
    options: [
      {
        className: 'contact-person-firstname',
        id: 'contact-person-firstname',
        name: 'contactPersonFirstname',
        parentClass: 'form-group',
        type: 'text',
        placeholder: 'firstname'
      },
      {
        className: 'contact-person-lastname',
        id: 'contact-person-lastname',
        name: 'contactPersonLastname',
        parentClass: 'form-group',
        type: 'text',
        placeholder: 'lastname'
      }
    ]
  },
  {
    type: 'text',
    label: 'phone-number',
    name: 'phoneNumber',
    className: 'phone-number',
    id: 'phone-number',
    placeholder: 'phone-number'
  },
  {
    type: 'radio',
    label: 'lock-type',
    name: 'lockType',
    className: 'lock-type-radio-group',
    id: 'lock-type-radio-group',
    options: [
      {
        id: 'lock-type-key',
        label: 'key',
        defaultChecked: false,
        value: 'key'
      },
      {
        id: 'lock-type-number-pad',
        label: 'number-pad',
        defaultChecked: false,
        value: 'numberPad'
      },
      {
        id: 'lock-type-access-card',
        label: 'access-card',
        defaultChecked: false,
        value: 'accessCard'
      },
      {
        id: 'lock-type-gatekeeper',
        label: 'gatekeeper',
        defaultChecked: false,
        value: 'gateKeeper'
      }
    ]
  },
  {
    type: 'radio',
    label: 'building-type',
    name: 'buildingType',
    className: 'building-type-radio-group',
    id: 'building-type-radio-group',
    options: [
      {
        id: 'building-type-altbau',
        label: 'altbau',
        defaultChecked: false,
        value: 'altbau'
      },
      {
        id: 'building-type-modern',
        label: 'modern',
        defaultChecked: false,
        value: 'modern'
      }
    ]
  },
  {
    type: 'shapedOptions',
    showMoreButton: true,
    name: 'numberOfKitchens',
    options: [
      { value: 1, displayName: 1 },
      { value: 2, displayName: 2 },
      { value: 3, displayName: 3 },
      { value: 4, displayName: 4 },
      { value: 5, displayName: 5 }
    ],
    className: '',
    label: 'number-of-kitchens',
    inputOptionPlaceholder: 'type-the-number'
  },
  {
    type: 'shapedOptions',
    showMoreButton: true,
    name: 'numberOfBathrooms',
    options: [
      { value: 1, displayName: 1 },
      { value: 2, displayName: 2 },
      { value: 3, displayName: 3 },
      { value: 4, displayName: 4 },
      { value: 5, displayName: 5 }
    ],
    className: '',
    label: 'number-of-bathrooms',
    inputOptionPlaceholder: 'type-the-number'
  },
  {
    type: 'text',
    label: 'number-of-windows',
    name: 'numberOfWindows',
    className: 'number-of-windows',
    id: 'number-of-windows',
    placeholder: 'type-the-number'
  },
  {
    type: 'radio',
    label: 'floor-type',
    name: 'floorType',
    className: 'floor-type-radio-group',
    id: 'floor-type-radio-group',
    options: [
      {
        id: 'floor-type-tiling',
        label: 'tiling',
        defaultChecked: false,
        value: 'tiling'
      },
      {
        id: 'floor-type-carpet',
        label: 'carpet',
        defaultChecked: false,
        value: 'carpet'
      },
      {
        id: 'floor-type-laminat',
        label: 'laminat',
        defaultChecked: false,
        value: 'laminat'
      },
      {
        id: 'floor-type-parquet',
        label: 'parquet',
        defaultChecked: false,
        value: 'parquet'
      },
      {
        id: 'floor-type-linoleum',
        label: 'linoleum',
        defaultChecked: false,
        value: 'linoleum'
      },
      {
        id: 'floor-type-pvc',
        label: 'pvc',
        defaultChecked: false,
        value: 'pvc'
      },
      {
        id: 'floor-type-other',
        label: 'other',
        defaultChecked: false,
        value: 'other'
      }
    ]
  },
  {
    type: 'radio',
    label: 'has-air-conditioner',
    name: 'hasAirConditioner',
    className: 'air-conditioner-radio-group',
    id: 'air-conditioner-radio-group',
    options: [
      {
        id: 'is-air-conditioner',
        label: 'yes',
        defaultChecked: false,
        value: true
      },
      {
        id: 'no-air-conditioner',
        label: 'no',
        defaultChecked: false,
        value: false
      }
    ]
  },
  {
    type: 'radio',
    label: 'heater-type',
    name: 'heaterType',
    className: 'heater-type-radio-group',
    id: 'heater-type-radio-group',
    options: [
      {
        id: 'heater-type-gas',
        label: 'gas',
        defaultChecked: false,
        value: 'gas'
      },
      {
        id: 'heater-type-electronic',
        label: 'electronic',
        defaultChecked: false,
        value: 'electronic'
      },
      {
        id: 'heater-type-hydronic',
        label: 'hydronic',
        defaultChecked: false,
        value: 'hydronic'
      },
      {
        id: 'heater-type-heat-pump',
        label: 'heat-pump',
        defaultChecked: false,
        value: 'heatPump'
      },
      {
        id: 'heater-type-other',
        label: 'other',
        defaultChecked: false,
        value: 'other'
      }
    ]
  }
];

export { officeDataRequiredForm, officeDataAdditionalForm };
