import { combineReducers } from 'redux';
import billing from './billing';
import methods from './methods';
import selectedMethod from './selectedMethod';
import creditCards from './creditCards';

export default combineReducers({
  billing,
  methods,
  selectedMethod,
  creditCards
});
