import { toast } from 'react-toastify';
import {
  GET_OFFER_REQUEST,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAILURE,
  OFFER_REJECT_SUCCESS,
  OFFER_REJECT_FAIL,
  OFFER_CONFIRM_SUCCESS,
  OFFER_CONFIRM_FAIL
} from 'constants/actionTypes';
import i18next from 'src/i18next';

const t = i18next.getFixedT(null, 'OfferReview/OfferAcceptModal');

export default (
  state = {
    offer: null,
    loading: false,
    offerConfirmSuccess: false,
    offerRejectSuccess: false
  },
  action
) => {
  switch (action.type) {
    case GET_OFFER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_OFFER_SUCCESS: {
      return {
        ...state,
        offer: action.payload,
        loading: false
      };
    }

    case GET_OFFER_FAILURE: {
      return { ...state, loading: false };
    }

    case OFFER_REJECT_SUCCESS: {
      toast.success(t('reject-msg'), { autoClose: 10000 });
      return { ...state, offerRejectSuccess: true };
    }

    case OFFER_REJECT_FAIL: {
      return { ...state, offerRejectSuccess: false };
    }

    case OFFER_CONFIRM_SUCCESS: {
      toast.success(t('success-msg'), { autoClose: 10000 });
      return { ...state, offerConfirmSuccess: true };
    }

    case OFFER_CONFIRM_FAIL: {
      return { ...state, offerConfirmSuccess: false };
    }

    default:
      return state;
  }
};
