import {
  SHOP_FAVORITE_CREATE_REQUEST,
  SHOP_FAVORITE_CREATE_SUCCESS,
  SHOP_FAVORITE_CREATE_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_FAVORITE_CREATE_REQUEST: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isCreating: true, isCreated: false } };
    }

    case SHOP_FAVORITE_CREATE_SUCCESS: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isCreating: false, isCreated: true } };
    }

    case SHOP_FAVORITE_CREATE_FAILURE: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isCreating: false } };
    }

    default:
      return state;
  }
};
