import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import { LOCATION_QUERY_PRODUCT } from 'constants/location';
import { clickProduct } from 'actions/tracking';
import { currency } from 'components/HOC';
import { ProductImage, PartnerImage } from './Common';
import { AddButton, FavoriteButton } from '.';

import './Product.less';

export class Product extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { onClick, isClickPrevented } = this.props;
    if (isClickPrevented()) return e.preventDefault();
    return onClick(e);
  }

  render() {
    const { item, partner, t, c, pathname } = this.props;

    return (
      <div className="product">
        <Link
          className="linkable-wrapper"
          to={`${pathname}?${LOCATION_QUERY_PRODUCT}=${item.id}`}
          onClick={this.handleClick}
        >
          <ProductImage item={item} />

          {partner && (
            <div className="product-partner-container">
              <PartnerImage className="product-partner-logo" item={partner} />

              <div className="product-partner-name ellipsis">{partner.name}</div>
            </div>
          )}

          <div className="product-data">
            <TextTruncate containerClassName="product-name" line={2} truncateText="…" text={item.name} />
            <div className="product-prices">
              <p className="product-price-wrapper">
                <span className="product-price">{c(item.unit_price)}</span>
                <span className="product-tax">{t('no-tax')}</span>
              </p>
            </div>
          </div>
        </Link>

        <FavoriteButton id={item.id} />
        <AddButton item={item} />
      </div>
    );
  }
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
  partner: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isClickPrevented: PropTypes.func,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

Product.defaultProps = {
  isClickPrevented: () => false
};

const mapStateToProps = (
  {
    location: { pathname },
    shop: {
      partners: { entities }
    }
  },
  { item: { partner_id: partnerId } }
) => ({
  pathname,
  partner: entities[partnerId]
});

const mapDispatchToProps = (dispatch, { item }) => ({
  onClick: () => dispatch(clickProduct(item))
});

export default currency(
  withTranslation('Shop/Product')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Product)
  )
);
