import Customer from './components/Customer';
import ChangeLanguage from './components/ChangeLanguage';
import BillingAddressForm from './components/BillingAddressForm';
import EmailNotifications from './components/EmailNotifications';
import ShippingAddress from './components/ShippingAddress';
import LoginAndSecurity from './components/LoginAndSecurity';
import Settings from './Settings';

export default Settings;
export { Customer, ChangeLanguage, EmailNotifications, BillingAddressForm, ShippingAddress, LoginAndSecurity };
