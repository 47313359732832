import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { PartnerImage } from '../../../Product/Common';
import { translateEditing } from '../../HOC';
import { Product, Sum } from '../../Common/SubCart';
import { Details } from '.';

class SubCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: props.isOpened
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened
    }));
  }

  render() {
    const { item, t } = this.props;
    const { isOpened } = this.state;
    const { partner, items } = item;

    return (
      <div className="cart-item">
        <button type="button" className={`button-inherit cart-partner ${isOpened ? 'open' : ''}`} onClick={this.toggle}>
          <strong className="cart-partner-name">{partner.name}</strong>
          {t('shopping-cart')}
        </button>

        <PartnerImage item={partner} />

        <div className="col-group">
          <div className="cart-original">
            <div className={`cart-productlist ${isOpened ? '' : 'hidden'}`}>
              {items.map(product => (
                <Product key={product.product.id} item={product} />
              ))}
            </div>

            <Details item={item} />
            <Sum item={item} />
          </div>
        </div>
      </div>
    );
  }
}

SubCart.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default pure(translateEditing(SubCart));
