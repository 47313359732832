import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const NotificationBox = ({ t, offer, offerToken, subType }) => {
  const offerSignedDate = offer[offerToken].item.contract_signed_date;
  const offerRejectedDate = offer[offerToken].item.contract_rejected_date;
  const offerSalutation = offer[offerToken].item.salutation;
  const pdfUrl = `${process.env.REACT_APP_SPONE_API_URL}/offer/contract/${offerToken}`;

  if (subType === 'Offer') {
    if (offerSignedDate) {
      return (
        <div className="offer__notification">
          {t('offer-notification-accept', { date: moment(offerSignedDate).format('DD.MM.YYYY') })}
          <a
            href={pdfUrl}
            className="button button-blue notification-pdf-button"
            rel="noopener noreferrer"
            target="_blank"
          >{t('success-button')}</a>
        </div>
      );
    }
    if (!offerSignedDate && offerRejectedDate) {
      return (
        <div className="offer__notification rejected">
          {t('offer-notification-reject', { date: moment(offerRejectedDate).format('DD.MM.YYYY') })}
        </div>
      );
    }
    return (
      <div className="offer-box-margin no-border">
        <p><strong>{offerSalutation}</strong>, <br />{t('intro-text-offer')}</p>
      </div>
    );
  }
  if (subType === 'Performance Note') {
    if (offerSignedDate) {
      return (
        <div className="offer__notification">
          {t('performance-notification-accept', { date: moment(offerSignedDate).format('DD.MM.YYYY') })}
          <a
            href={pdfUrl}
            className="button button-blue notification-pdf-button"
            rel="noopener noreferrer"
            target="_blank"
          >{t('success-button-performance')}</a>
        </div>
      );
    }
    if (offerRejectedDate) {
      return (
        <div className="offer__notification rejected">
          {t('performance-notification-reject', { date: moment(offerRejectedDate).format('DD.MM.YYYY') })}
        </div>
      );
    }
    return (
      <div className="offer-box-margin no-border">
        <p><strong>{offerSalutation}</strong>, <br />{t('intro-text-performance')}</p>
      </div>
    );
  }
  return (
    <div className="offer-box-margin no-border">
      <p><strong>{offerSalutation}</strong>, <br />{t('intro-text-offer')}</p>
    </div>
  );
};

NotificationBox.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired
};

export { NotificationBox as PureComponent };
export default withTranslation('PartnerConfirmation')(NotificationBox);
