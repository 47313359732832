import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RadioButton } from 'common/Form';

class TwoOptions extends React.Component {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      initialValue: PropTypes.string,
      name: PropTypes.string,
      labels: PropTypes.array,
      label1: PropTypes.string,
      label2: PropTypes.string,
      value1: PropTypes.string,
      value2: PropTypes.string
    };

    static defaultProps = {
      initialValue: false
    };

    setLeft = e => {
      const { value1 } = this.props;
      this.setState(() => ({
            value: value1 // eslint-disable-line
      }));
      this.props.onChange(e, true);
    };

    setRight = e => {
      const { value2 } = this.props;
      this.setState(() => ({
            value: value2 // eslint-disable-line
      }));
      this.props.onChange(e, false);
    };

    render() {
      const { labels = [this.props.label1, this.props.label2], name, initialValue, value1, value2 } = this.props;

      return (
        <div className="radio-group">
          <RadioButton
            name={name}
            label={labels[0]}
            value={value1}
            onClick={this.setLeft}
            defaultChecked={!!initialValue === true}
          />
          <RadioButton
            name={name}
            label={labels[1]}
            value={value2}
            onClick={this.setRight}
            defaultChecked={!!initialValue === false}
          />
        </div>
      );
    }
}

export default withTranslation('Services/Cleaning')(TwoOptions);
