import storage from 'store';
import { getBrowserLanguages } from 'utils';
import { I18N_ALLOWED_LANGUAGES } from '../../constants/i18n';
import { LOCAL_STORAGE_LANGUAGE } from '../../constants/localStorage';
import setLanguage from './setLanguage';

const fromStorage = () => storage.enabled && storage.get(LOCAL_STORAGE_LANGUAGE);

const fromBrowser = () =>
  getBrowserLanguages()
    .map(language => (language.includes('-') ? language.split('-')[0] : language))
    .filter(language => I18N_ALLOWED_LANGUAGES.includes(language))[0];

export default () => setLanguage(fromStorage() || fromBrowser());
