import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { alert } from 'actions/notifications';
import { Tooltip } from '..';

class CancelButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick();
  }

  render() {
    const { item, t } = this.props;
    const tooltipId = `tooltip-sed-${item.id}-cancel-button`;

    if (!item.isDraft) return null;

    return (
      <Tooltip id={tooltipId}>
        <button
          type="button"
          className="sed-cancel"
          data-for={tooltipId}
          data-tip={t('cancel')}
          data-effect="solid"
          onClick={this.handleClick}
        >
          &nbsp;
        </button>
      </Tooltip>
    );
  }
}

CancelButton.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, { t }) => ({
  onClick: () => dispatch(alert(t('confirm-cancel')))
});

export { CancelButton as PureComponent };
export default withTranslation('Common/ServiceEventDefinition/CancelButton')(
  connect(
    null,
    mapDispatchToProps
  )(CancelButton)
);
