const names = {
  floor: 'floor',
  hours: 'hours',
  toilet: 'toilet',
  week: 'week',
  woodenFloor: 'woodenFloor',
  nrEmployees: 'nrEmployees',
  kitchen: 'kitchen',
  premium: 'premium'
};

const FormObjectArray = [
  {
    type: 'input',
    id: `cleaning-service-${names.floor}`,
    className: `cleaning-service__${names.floor}`,
    label: names.floor,
    addon: 'addonFloor'
  },
  {
    type: 'input',
    id: `cleaning-service-${names.nrEmployees}`,
    className: `cleaning-service__${names.nrEmployees}`,
    label: names.nrEmployees,
    addon: 'addonEmployees'
  },
  {
    type: 'select',
    id: `cleaning-service-${names.week}`,
    className: `cleaning-service__${names.week}`,
    parentClass: `cleaning-service__${names.week}-group`,
    label: names.week,
    tooltip: `tooltip-${names.week}`
  },
  {
    type: 'select',
    id: `cleaning-service-${names.toilet}`,
    className: `cleaning-service__${names.toilet}`,
    parentClass: `cleaning-service__${names.toilet}-group`,
    label: names.toilet
  },
  {
    type: 'select',
    id: `cleaning-service-${names.woodenFloor}`,
    className: `cleaning-service__${names.woodenFloor}`,
    parentClass: `cleaning-service__${names.woodenFloor}-group`,
    label: names.woodenFloor
  },
  {
    type: 'select',
    id: `cleaning-service-${names.kitchen}`,
    className: `cleaning-service__${names.kitchen}`,
    parentClass: `cleaning-service__${names.kitchen}-group`,
    label: names.kitchen
  },
  {
    type: 'radio',
    id: `cleaning-service-${names.premium}`,
    className: `cleaning-service__${names.premium}`,
    parentClass: `cleaning-service__${names.premium}-group`,
    label: names.premium,
    tooltip: `tooltip-${names.premium}`,
    stateToToggle: 'premium',
    name: 'window-case'
  },
  {
    type: 'radio',
    id: `cleaning-service-${names.hours}`,
    className: `cleaning-service__${names.hours}`,
    parentClass: `cleaning-service__${names.hours}-group`,
    label: names.hours,
    stateToToggle: 'hours',
    name: 'window-case-hours'
  }
];

export const hoursObj = [
  { name: 1, value: 1 },
  { name: 1.5, value: 1.5 },
  { name: 2, value: 2 },
  { name: 2.5, value: 2.5 },
  { name: 3, value: 3 },
  { name: 3.5, value: 3.5 },
  { name: 4, value: 4 },
  { name: 4.5, value: 4.5 }
];

export const windowHObj = [
  { name: '0-50cm', value: 50 },
  { name: '50-100cm', value: 100 },
  { name: '100-150cm', value: 150 },
  { name: '150-200cm', value: 200 },
  { name: '250-300cm', value: 300 },
  { name: '>300cm', value: 500 }
];

export const windowWObj = [
  { name: '0-50cm', value: 50 },
  { name: '50-100cm', value: 100 },
  { name: '100-150cm', value: 150 },
  { name: '150-200cm', value: 200 },
  { name: '250-300cm', value: 300 },
  { name: '>300cm', value: 500 }
];

export const mapedObject = {
  toilet: 'toilet_size',
  week: 'number_of_cleanings',
  hours: 'weekend_choice',
  woodenFloor: 'hardfloor_choice',
  kitchen: 'kitchen_size',
  nrEmployees: 'number_of_employees',
  premium: 'premium_choice',
  floor: 'office_size',
  startDate: 'start_date'
};

export const checkboxObj = [
  'cleanFloor',
  'collectBottles',
  'cleanToilets',
  'cleanDishes',
  'cleanFurniture',
  'cleanKitchen',
  'misc'
];

export default FormObjectArray;
