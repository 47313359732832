import {
  SERVICE_EVENT_NOTE_REQUEST,
  SERVICE_EVENT_NOTE_SUCCESS,
  SERVICE_EVENT_NOTE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (serviceEventId, body) =>
  authCall({
    shouldFetch: state =>
      !(state.serviceEvents.note[serviceEventId] && state.serviceEvents.note[serviceEventId].isNoting),
    meta: { serviceEventId },
    types: [SERVICE_EVENT_NOTE_REQUEST, SERVICE_EVENT_NOTE_SUCCESS, SERVICE_EVENT_NOTE_FAILURE],
    endpoint: `/user/service-events/${serviceEventId}/note`,
    method: 'PUT',
    body: {
      body
    }
  });
