/* eslint-disable no-param-reassign, import/prefer-default-export */
import { shouldFetch, formatFormData } from 'utils';
import {
  SERVICE_REQUESTS_FETCH,
  SERVICE_REQUESTS_FETCH_SUCCESS,
  SERVICE_REQUESTS_FETCH_FAILURE,
  SERVICE_REQUESTS_FETCH_REQUEST,
  SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS,
  SERVICE_REQUESTS_FETCH_REQUEST_FAILURE,
  SERVICE_REQUESTS_SEARCH,
  SERVICE_REQUESTS_SEARCH_SUCCESS,
  SERVICE_REQUESTS_SEARCH_FAILURE,
  SERVICE_REQUESTS_EDIT,
  SERVICE_REQUESTS_EDIT_SUCCESS,
  SERVICE_REQUESTS_EDIT_FAILURE,
  SERVICE_REQUESTS_CREATE,
  SERVICE_REQUESTS_CREATE_SUCCESS,
  SERVICE_REQUESTS_CREATE_FAILURE,
  SERVICE_REQUESTS_CLONE
} from 'constants/actionTypes';
import { authCall } from '../api';

export const fetchServiceRequests = query =>
  authCall({
    types: [SERVICE_REQUESTS_FETCH, SERVICE_REQUESTS_FETCH_SUCCESS, SERVICE_REQUESTS_FETCH_FAILURE],
    endpoint: `/user/booking`,
    method: 'GET',
    query
  });

export const fetchServiceRequest = id =>
  authCall({
    types: [
      SERVICE_REQUESTS_FETCH_REQUEST,
      SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS,
      SERVICE_REQUESTS_FETCH_REQUEST_FAILURE
    ],
    endpoint: `/user/booking/${id}`,
    method: 'GET'
  });

export const createServiceRequest = serviceRequest => {
  const data = formatFormData(serviceRequest);

  return authCall({
    types: [SERVICE_REQUESTS_CREATE, SERVICE_REQUESTS_CREATE_SUCCESS, SERVICE_REQUESTS_CREATE_FAILURE],
    endpoint: `/user/booking`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export const searchServiceRequests = (query, shouldDoForceFetch = false) =>
  authCall({
    shouldFetch: ({ issues: { isFetching, fetchedAt } }) => shouldFetch(isFetching, fetchedAt, shouldDoForceFetch),
    types: [SERVICE_REQUESTS_SEARCH, SERVICE_REQUESTS_SEARCH_SUCCESS, SERVICE_REQUESTS_SEARCH_FAILURE],
    endpoint: `/user/booking`,
    method: 'GET',
    query
  });

export const updateServiceRequest = serviceRequest => {
  // if (!issue.assignee_id) {
  //     delete issue.assignee_id;
  // }

  const data = formatFormData(serviceRequest);

  return authCall({
    types: [SERVICE_REQUESTS_EDIT, SERVICE_REQUESTS_EDIT_SUCCESS, SERVICE_REQUESTS_EDIT_FAILURE],
    endpoint: `/user/booking/${data && data.get('id')}`,
    method: 'POST',
    body: data,
    multipart: true
  });
};

export function cloneServiceRequest(serviceRequest) {
  return {
    type: SERVICE_REQUESTS_CLONE,
    payload: serviceRequest
  };
}

// export const fetchStats = () =>
//     authCall({
//         types: [ISSUES_FETCH_STATS, ISSUES_FETCH_STATS_SUCCESS, ISSUES_FETCH_STATS_FAILURE],
//         endpoint: `/user/tickets/stats`,
//         method: 'GET'
//     });
