import React from 'react';
import { CalendarDay } from '../..';

const Calendar = ({ startDate, endDate, ...props }) => {
  const days = [];
  let day = startDate;

  while (day <= endDate) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
  }

  return (
    <div className="modul__calendar">
      {days.map((d, index) => (
        <CalendarDay key={d.getDay()} day={d} index={index} {...props} />
      ))}
    </div>
  );
};

export default Calendar;
