import { createSelector } from 'reselect';

const getProducts = state => state.shop.products.entities;

export default getItems => createSelector(
  [getItems, getProducts], (items, products) => {
    if (!items) return false;

    return items.filter(item => !!products[item.customer_product]).length > 0;
  },
);
