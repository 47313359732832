import { createSelector } from 'reselect';

const getItems = state => state.items;

export default createSelector(
  [getItems], items => {
    if (!items) return null;

    return items.reduce((byYear, item) => ({ ...byYear,
      [item.year]: [...byYear[item.year] || [], item]
    }), {});
  },
);
