import { authCall } from 'api';
import { CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS, CUSTOMER_UPDATE_FAILURE } from 'constants/actionTypes';

export default customerData =>
  authCall({
    shouldFetch: s => !s.customer.update.isChanging,
    endpoint: '/user/customer',
    types: [CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS, CUSTOMER_UPDATE_FAILURE],
    method: 'PUT',
    body: customerData
  });
