import { ISSUE_STATUS, ISSUE_PRIORITY, ISSUE_CATEGORIES } from 'constants/issues';

export const getStatuses = t =>
  ISSUE_STATUS.map(el => ({
    label: t(el),
    value: el
  }));

export const getCategories = t =>
  ISSUE_CATEGORIES.map(el => ({
    label: t(el),
    value: t(el)
  }));

export const getPriorities = t =>
  ISSUE_PRIORITY.map(el => {
    let prio;

    switch (el) {
      case 'low':
        prio = '1';
        break;
      case 'medium':
        prio = '4';
        break;
      case 'high':
        prio = '7';
        break;
      case 'urgent':
        prio = '10';
        break;
      default:
        return null;
    }

    return {
      label: t(el),
      value: prio.toString()
    };
  });

export const mapPriorities = (t, pri) => {
  let prio;

  if (+pri >= 1 && +pri <= 3) {
    prio = 'low';
  } else if (+pri > 3 && +pri <= 6) {
    prio = 'medium';
  } else if (+pri > 6 && +pri <= 9) {
    prio = 'high';
  } else if (+pri === 10) {
    prio = 'urgent';
  }

  return {
    label: t(prio),
    value: pri
  };
};

export const formatList = (arr, label) => arr.map(el => ({ label: el[label], value: el.id }));
