import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  LOCATION_PATHNAME_DEFAULT,
  LOCATION_PATHNAME_TERMS_AND_CONDITIONS
} from "constants/location";
import Dashboard, { Feedback } from "components/Dashboard";
import Settings from "components/Settings";
import { Terms } from "components/Terms";
import Support from "components/Support";
import Shop from "components/Shop";
import Issues, { CreateIssue, EditIssue } from "components/Issues";
import ServiceRequests, {
  ServiceRequestCreate,
  ServiceRequestDetails
} from "components/ServiceRequests";
import Cleaning from "components/Cleaning/Cleaning";
import Timeline from "components/Timeline";
import Navigation from "components/Invoices";
import { OfferConfirmation, SuccessPage } from "components/OfferConfirmation";
import PartnerConfirmation from "components/PartnerConfirmation";
// import Services from 'components/Services';
import { SnapChat } from "components/Vendors";
import Navbar from "components/Navbar";
import ModalDispatcher from "components/Modal";
import Analytics from "components/Analytics";
import Members from "components/Members";
import UserSettings from "components/UserSettings";
import NotFound from "components/NotFound";

export const Protected = ({ hasAcceptedTerms, brand }) => {
  if (hasAcceptedTerms === null) return null;

  if (
    hasAcceptedTerms === false &&
    window.location.pathname !== LOCATION_PATHNAME_TERMS_AND_CONDITIONS
  ) {
    return (
      <>
        <Navbar />
        <Redirect to={LOCATION_PATHNAME_TERMS_AND_CONDITIONS} />
        <ModalDispatcher />
        <SnapChat />
      </>
    );
  }

  return (
    <>
      <UserSettings />
      <Navbar />
      <Switch>
        <Redirect from="/" to={LOCATION_PATHNAME_DEFAULT} exact />
        <Route path="/dashboard" component={Dashboard} exact />
        <Route path="/feedback/:id/:eventDate" component={Feedback} />
        <Route path="/shop" component={Shop} />
        <Route path="/cleaning" component={Cleaning} />
        <Route path="/timeline" component={Timeline} exact />
        <Route path="/timeline/:start" component={Timeline} />
        <Route path="/invoices-orders" component={Navigation} />
        <Route path="/terms" component={Terms} />
        {/* <Route path="/services" component={Services} /> */}
        <Route path="/support" component={Support} />
        <Route path="/settings" component={Settings} />
        <Route path="/offer/success/:token" component={SuccessPage} />
        <Route path="/offer/:token" component={OfferConfirmation} />
        <Route path="/executive/:token" component={PartnerConfirmation} />
        <Route path="/verify/:token" component={Dashboard} />
        <Route path="/register" component={Dashboard} exact />
        <Route path="/register/:source" component={Dashboard} />
        <Route path="/issues" component={Issues} exact />
        <Route path="/issues/create" component={CreateIssue} />
        <Route path="/issues/:id" component={EditIssue} />
        <Route path="/service-requests" component={ServiceRequests} exact />
        <Route
          path="/service-requests/create"
          component={ServiceRequestCreate}
        />
        <Route path="/service-requests/:id" component={ServiceRequestDetails} />

        <Route path="/members" component={Members} exact />
        {brand && brand === "wework" && (
          <Route path="/analytics" component={Analytics} exact />
        )}
        <Route component={NotFound} />
      </Switch>
      <ModalDispatcher />
      <SnapChat />
    </>
  );
};

Protected.propTypes = {
  hasAcceptedTerms: PropTypes.bool
};

const mapStateToProps = ({ brand, current: { customer } }) => ({
  hasAcceptedTerms: customer ? customer.has_accepted_tos : null,
  brand
});

export default withRouter(connect(mapStateToProps)(Protected));
