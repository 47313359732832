import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { IMAGE_SHOP_CART } from 'constants/images';

const ProductImage = ({ item }) => {
  const hasImage = !!(item.thumbnail_200 || item.image_url);

  return (
    <div
      className={`product-img ${hasImage ? '' : 'opacity50'}`}
      style={{
        background: `url(${item.thumbnail_200 || item.image_url || IMAGE_SHOP_CART}) no-repeat center center`,
        backgroundSize: `${hasImage ? 'contain' : '75% auto'}`
      }}
    >
      &nbsp;
    </div>
  );
};

ProductImage.propTypes = {
  item: PropTypes.object.isRequired
};

export default pure(ProductImage);
