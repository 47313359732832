/* eslint-disable jsx-a11y/label-has-associated-control, camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pure } from 'recompose';
import Dropdown from 'react-dropdown';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import { SingleDatePicker } from 'react-dates';
import { DocumentTitle } from 'common';
import { createServiceRequest } from 'actions/serviceRequests';
import { getCategories, formatList } from '../../helper';

import './ServiceRequestCreate.less';

class ServiceRequestCreate extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      location: '',
      booking_type: null,
      contact_id: null,
      createAnother: false,
      description: '',
      file: '',
      image: '',
      cleaning_date: null,
      time: null,
      location_floor: '',
      location_room: '',
      location_building: '',
      size: ''
    };

    this.state = this.defaultState;
    this.categories = getCategories(props.t);
  }

  componentDidMount() {
    // Clone functionality
    if (this.props.serviceRequest) {
      const loc = this.props.objects.find(el => el.id === this.props.serviceRequest.location);

      if (loc) {
        this.setState({
          location: {
            label: loc.name,
            value: loc.id
          }
        });
      }
    }
  }

  onChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;

    this.setState({
      [name]: value
    });
  };

  // onChangeCheckbox = () => {
  //     this.setState(prevState => ({
  //         createAnother: !prevState.createAnother
  //     }));
  // };

  imageUpload = event => {
    const image = event.target.files[0];

    this.setState({
      image
    });
  };

  fileUpload = event => {
    const file = event.target.files[0];

    this.setState({
      file
    });
  };

  submitForm = e => {
    e.preventDefault();

    if (this.isFormValid()) {
      this.props.newServiceRequest(this.state).then(() => {
        if (this.state.createAnother) {
          this.setState({ ...this.defaultState });
        } else {
          this.props.history.push('/service-requests');
        }
      });
    }
  };

  isFormValid = () => {
    const { booking_type, description } = this.state;

    if (!booking_type || description.length === 0) {
      return false;
    }

    return true;
  };

  onDateChange = date => {
    this.setState({
      cleaning_date: date
    });
  };

  onTimeChange = time => {
    this.setState({ time });
  };

  render() {
    const {
      location,
      booking_type,
      contact_id,
      location_building,
      file,
      description,
      location_floor,
      location_room,
      cleaning_date,
      time,
      size
    } = this.state;
    const { t, objects, members } = this.props;

    const objectsList = objects && formatList(objects, 'name');
    const assignee = members && formatList(members, 'fullname');
    const selectedObject = location && objects.find(el => el.id === location);
    const selectedContact = contact_id && members.find(el => el.id === contact_id);

    return (
      <DocumentTitle title={t('new-service-request-title')}>
        <div className="create-service-request content-area">
          <div className="page-header">
            <h1 className="page-title">{t('new-service-request-title')}</h1>
          </div>
          <form className="page-content" onSubmit={this.submitForm}>
            <div className="service-request-form" onSubmit={this.submitForm}>
              <div className="form-section">
                <div className="section-title">{t('service-request-object')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('choose-object')}</div>
                    <Dropdown
                      className="Dropdown-flat"
                      options={objectsList}
                      onChange={e => this.onChange(e, 'location')}
                      value={location}
                      placeholder={t('choose-object')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('choose-contact')}</div>
                    <Dropdown
                      className="Dropdown-flat Dropdown-max-3"
                      options={assignee}
                      onChange={e => this.onChange(e, 'contact_id')}
                      value={contact_id}
                      placeholder={t('choose-contact')}
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="section-title">{t('service-request-overview')}</div>

                <div className="form-row form-row-category">
                  <div className="form-elements-group category-dropdown">
                    <div className="label">{t('category')}</div>
                    <Dropdown
                      className="Dropdown-flat Dropdown-max-3"
                      options={this.categories}
                      onChange={e => this.onChange(e, 'booking_type')}
                      value={booking_type}
                      placeholder={t('choose-category')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('size')}</div>
                    <input
                      type="number"
                      className="form-input"
                      placeholder={t('size')}
                      value={size}
                      onChange={e => this.onChange(e, 'size')}
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="form-row">
                  <div className="form-description">
                    <textarea
                      className="textarea"
                      placeholder={t('task-description')}
                      value={description}
                      onChange={e => this.onChange(e, 'description')}
                    />

                    <div className="form-files">
                      <div className="file-upload">
                        <input
                          id="uploadFile"
                          type="file"
                          accept=".pdf, .doc"
                          className="button"
                          onChange={this.fileUpload}
                        />
                        <label htmlFor="uploadFile">{file ? file.name : t('attach-file')}</label>
                      </div>

                      {/* <div className="file-upload photo-upload">
                                                <input
                                                    id="uploadImage"
                                                    type="file"
                                                    accept="image/*"
                                                    className="button"
                                                    onChange={this.imageUpload}
                                                />
                                                <label htmlFor="uploadImage">
                                                    {image ? image.name : t('attach-picture')}
                                                </label>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="section-title">{t('location-information')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('building')}</div>
                    <input
                      type="text"
                      className="form-input"
                      placeholder={t('input-building')}
                      value={location_building}
                      onChange={e => this.onChange(e, 'location_building')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('floor')}</div>
                    <input
                      type="text"
                      className="form-input"
                      placeholder={t('input-floor')}
                      value={location_floor}
                      onChange={e => this.onChange(e, 'location_floor')}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('room')}</div>
                    <input
                      type="text"
                      className="form-input"
                      placeholder={t('input-room')}
                      value={location_room}
                      onChange={e => this.onChange(e, 'location_room')}
                    />
                  </div>
                </div>
              </div>
              <div className="form-section">
                <div className="section-title">{t('service-date')}</div>

                <div className="form-row">
                  <div className="form-elements-group">
                    <div className="label">{t('date')}</div>
                    <SingleDatePicker
                      placeholder={t('input-date')}
                      date={cleaning_date}
                      onDateChange={this.onDateChange}
                      showClearDate
                      isOutsideRange={() => false}
                      numberOfMonths={1}
                      focused={this.state.focused}
                      onFocusChange={({ focused }) => this.setState({ focused })}
                    />
                  </div>

                  <div className="form-elements-group">
                    <div className="label">{t('time')}</div>
                    <TimePicker
                      id="time"
                      showSecond={false}
                      placeholder={t('input-time')}
                      className="form-input"
                      onChange={this.onTimeChange}
                      value={time}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="service-request-form properties-form">
              <div>
                <div className="title">{t('summary')}</div>
                <div className="summary-table">
                  {selectedObject && (
                    <div className="service-recipient">
                      <span className="title">{t('service-recipient')}</span>

                      {selectedObject.name && <span className="object-name">{selectedObject.name}</span>}
                      {selectedContact && selectedContact.fullname && (
                        <span className="contract-name">{selectedContact.fullname}</span>
                      )}
                      {selectedContact && selectedContact.email && (
                        <span className="email">{selectedContact.email}</span>
                      )}
                      {selectedObject.shipping_address && selectedObject.shipping_address.street && (
                        <span className="address">{selectedObject.shipping_address.street} </span>
                      )}
                      {selectedObject.shipping_address.postal_code && selectedObject.shipping_address.city && (
                        <span className="address">
                          {selectedObject.shipping_address.postal_code} {selectedObject.shipping_address.city}
                        </span>
                      )}
                      {selectedObject.country && <span className="address">{selectedObject.country} </span>}
                    </div>
                  )}
                </div>

                {(booking_type || location_building || location_floor || location_room) && (
                  <div className="summary-table">
                    <div className="request-details">
                      <span className="title">{t('request-details')}</span>
                      {booking_type && <span className="object-name">{booking_type}</span>}
                      {location_building && (
                        <span className="object-name">
                          {t('building')} {location_building}
                        </span>
                      )}
                      {location_floor && (
                        <span className="object-name">
                          {location_floor} {t('floor')}
                        </span>
                      )}
                      {location_room && (
                        <span className="object-name">
                          {t('room')} {location_room}
                        </span>
                      )}
                      {cleaning_date && (
                        <span className="object-name">{moment(cleaning_date).format('DD.MM.YYYY')}</span>
                      )}
                      {time && <span className="object-name">{moment(time).format('HH:mm')}</span>}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {/* <div className="total">
                                    <span className="total-label">Total:</span>
                                    <span className="total-price">1.436,55€</span>
                                </div> */}
                <div className="summary-buttons form-buttons">
                  {/* <label
                                                className={classNames('checkbox-label ui-component', {
                                                    checked: createAnother
                                                })}
                                                htmlFor="createAnotherRequest"
                                            >
                                                {t('create-another')}
                                                <input
                                                    type="checkbox"
                                                    id="createAnotherRequest"
                                                    name="open"
                                                    value={false}
                                                    onChange={this.onChangeCheckbox}
                                                    checked={createAnother}
                                                />
                                            </label> */}

                  <button type="submit" className="button button-blue form-submit-btn">
                    {t('confirm')}
                  </button>

                  <Link to="/service-requests" className="button button-blue button-secondary form-back-btn">
                    {t('back')}
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

ServiceRequestCreate.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  objects: state.current.user.available_customers,
  members: state.members.items,
  serviceRequest: state.serviceRequests.serviceRequest
});

const mapDispatchToProps = dispatch => ({ newServiceRequest: data => dispatch(createServiceRequest(data)) });

export { ServiceRequestCreate as PureComponent };
export default pure(
  withTranslation('ServiceRequests')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ServiceRequestCreate)
  )
);
