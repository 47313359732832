import { createSelector } from 'reselect';

const getBestsellers = state => state.shop.bestsellers.items;
const getProducts = state => state.shop.products.entities;

export default createSelector(
  [getBestsellers, getProducts], (bestsellers, products) => {
    if (!bestsellers) return null;

    return bestsellers.map(bestseller => ({ ...bestseller,
      products: bestseller.products.map(productId => products[productId])
        .filter(product => !!product)
    }));
  },
);
