import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { TabNavigation, DocumentTitle } from 'common';
import { Invoices, Orders } from '../..';

export class Navigation extends Component {
  getTabNavigationItems = t => [
    { id: 'invoices', name: t('tab-invoices'), parentRoute: 'invoices-orders' },
    { id: 'orders', name: t('tab-orders'), parentRoute: 'invoices-orders' }
  ];

  getChildRoutes = () => (
    <Switch>
      <Redirect from="/invoices-orders" exact to="/invoices-orders/invoices" />
      <Route path="/invoices-orders/invoices" component={Invoices} />
      <Route path="/invoices-orders/orders" component={Orders} />
    </Switch>
  );

  render() {
    const { t } = this.props;
    return (
      <DocumentTitle title={t('headline')}>
        <div className="content-area">
          <div className="col-group pad">
            <div className="invoices-headline">{t('headline')}</div>
            <TabNavigation items={this.getTabNavigationItems(t)} />
            <div className="invoices-orders-container">{this.getChildRoutes()}</div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Navigation.propTypes = {
  t: PropTypes.func.isRequired
};

export { Navigation as PureComponent };
export default withRouter(withTranslation('Settings/Invoices')(Navigation));
