/* eslint-disable import/no-named-as-default */

import Dashboard from './Dashboard';
import Banner from './components/Banner';
import Header from './components/Header';
import Schedule from './components/Schedule';
import Invoices from './components/Invoices';
import Services from './components/Services';
import Feedback from './components/Feedback';
import PastEvents from './components/PastEvents';
import Navbar from './components/Navbar';
import ServiceEventDefinitionOverview from './components/ServiceEventDefinitionOverview';
import ServiceEventOverview from './components/ServiceEventOverview';
import ServiceEventFilter from './components/ServiceEventFilter';

export default Dashboard;

export {
  Banner,
  Header,
  Schedule,
  Invoices,
  Services,
  PastEvents,
  Navbar,
  ServiceEventDefinitionOverview,
  ServiceEventOverview,
  Feedback,
  ServiceEventFilter
};
