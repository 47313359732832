import { pushLocation } from ".";

export default location => (dispatch, getState) => {
  let nextLocation = location;

  if (typeof nextLocation === 'string') {
    nextLocation = { pathname: nextLocation };
  }

  if (!nextLocation) {
    nextLocation = getState().location;
  }

  if (nextLocation.pathname.substring(0, 5) !== '/shop') {
    nextLocation = { pathname: '/shop' };
  }

  dispatch(pushLocation({ ...nextLocation,
    state: { isCartVisible: true }
  }));
};
