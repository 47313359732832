import {
  SERVICE_EVENT_FILTERS_SELECT,
  SERVICE_EVENT_FILTERS_DESELECT,
  SERVICE_EVENT_FILTERS_RESET
} from 'constants/actionTypes';

const initialState = {
  types: [],
  modes: [],
  states: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FILTERS_SELECT:
      return { ...state, [action.filterType]: [...state[action.filterType], action.value] };

    case SERVICE_EVENT_FILTERS_DESELECT:
      return { ...state, [action.filterType]: state[action.filterType].filter(value => value !== action.value) };

    case SERVICE_EVENT_FILTERS_RESET:
      return initialState;

    default:
      return state;
  }
};
