import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Autocomplete } from 'common';
import { search } from 'actions/search';
import { getAutocomplete } from 'selectors/search';

import './Search.less';

class Search extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { searchTerm, onSearch } = this.props;
    // Trigger the search instantly
    if (searchTerm) onSearch(searchTerm);
  }

  handleChange(value) {
    this.props.onSearch(value, true);
  }

  handleSelect(item) {
    this.props.onSearch(item.label);
  }

  render() {
    const { searchTerm, items, t } = this.props;

    return (
      <form className="search-form" onSubmit={this.handleSubmit}>
        <Autocomplete
          inputClassName="search-input"
          value={searchTerm}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          placeholder={t('search-for')}
          items={items}
        />
        <div className="search-icon" />
      </form>
    );
  }
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  items: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  searchTerm: state.search.q,
  items: getAutocomplete(state)
});

const mapDispatchToProps = {
  onSearch: search
};

export default withTranslation('Header')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
