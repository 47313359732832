import { SERVICE_EVENT_CLEANING_OBJECT, SERVICE_EVENT_CLEANING_OBJECT_RESET } from 'constants/actionTypes';

const initialState = {
  calcObj: {
    office_size: 100,
    hardfloor_choice: 'carpet_bright',
    toilet_size: 5,
    weekend_choice: false,
    number_of_employees: 1,
    kitchen_size: 5,
    number_of_cleanings: 1,
    premium_choice: false,
    start_date: null
  },
  checked: false,
  step1: true,
  step2: false,
  step3: false,
  dates: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_CLEANING_OBJECT:
      return { ...state, ...action.payload };
    case SERVICE_EVENT_CLEANING_OBJECT_RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
