import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_CLEANING_TASKLIST } from 'constants/location';

export const CleaningTasklistLink = ({ pathname, className, title }) => (
  <Link to={`${pathname}${LOCATION_HASH_CLEANING_TASKLIST}`} className={`cleaning-service__link ${className || ''}`}>
    {title}
  </Link>
);

CleaningTasklistLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  pathname: PropTypes.string
};

CleaningTasklistLink.defaultProps = {
  className: '',
  title: ''
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default connect(mapStateToProps)(CleaningTasklistLink);
