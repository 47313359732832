import {
  REGISTER_STEP_TWO_REQUEST,
  REGISTER_STEP_TWO_SUCCESS,
  REGISTER_STEP_TWO_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';

export default body => (dispatch, getState) => {
  const {
    register: { token }
  } = getState();

  return dispatch(
    apiCall({
      shouldFetch: state => !state.register.isRegistering,
      types: [REGISTER_STEP_TWO_REQUEST, REGISTER_STEP_TWO_SUCCESS, REGISTER_STEP_TWO_FAILURE],
      endpoint: `/user/register/${token}`,
      method: 'POST',
      body
    })
  );
};
