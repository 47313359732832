import { createSelector } from 'reselect';

export default getPages =>
  createSelector(
    [getPages],
    pages => {
      if (!pages) return null;

      return Object.keys(pages).reduce((items, page) => {
        if (!pages[page].items) return items;

        return [...(items || []), ...pages[page].items];
      }, null);
    }
  );
