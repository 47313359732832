import {
  SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_REQUEST,
  SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_SUCCESS,
  SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default cleaningSubmit =>
  authCall({
        shouldFetch: () => true, // eslint-disable-line
    types: [
      SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_REQUEST,
      SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_SUCCESS,
      SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_FAILURE
    ],
    endpoint: '/user/form/cleaning/partner',
    meta: 'submitCleaning',
    method: 'POST',
    body: cleaningSubmit
  });
