import { NOTIFICATION_SHOW } from 'constants/actionTypes';

export default (message, options = {}) => dispatch =>
  new Promise((resolve, reject) =>
    dispatch({
      type: NOTIFICATION_SHOW,
      item: {
        ...options,
        id: Math.random(),
        message,
        closable: false,
        modal: true,
        onConfirm: resolve,
        onCancel: reject
      }
    })
  );
