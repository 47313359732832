import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currency } from 'components/HOC';
import { bump } from '../HOC';

const EditingHeader = ({ isBumping, isLoading, loadingClassName, numberOfItems, sum, item, c }) => (
  <div className={`cart-module ${isBumping ? 'bump' : ''}`}>
    <div className={`cart-preview cart-edit ${item.type}`}>
      <span>
        {item.name} <span className="cart-length bumping">{numberOfItems > 0 ? `(${numberOfItems})` : ''}</span>
      </span>

      <span className={`cart-sum ${isLoading ? loadingClassName : ''}`}>{c(sum)}</span>
    </div>
  </div>
);

EditingHeader.propTypes = {
  isBumping: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingClassName: PropTypes.string,
  item: PropTypes.object.isRequired,
  numberOfItems: PropTypes.number,
  sum: PropTypes.number,
  c: PropTypes.func.isRequired
};

EditingHeader.defaultProps = {
  numberOfItems: 0,
  sum: 0,
  loadingClassName: 'loading'
};

const mapStateToProps = ({
  shop: {
    cart: { isFetching: isLoading, isVisible, cart }
  }
}) => ({
  isLoading,
  loadingClassName: isVisible ? 'loading' : 'loading-light',
  item: cart.sed,
  numberOfItems: cart.length,
  sum: cart.sum_net
});

export { EditingHeader as PureComponent };
export default currency(connect(mapStateToProps)(bump(EditingHeader)));
