/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import { mapPriorities, formatList } from '../../helper';

import './Issue.less';

class Issue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'open',
      priority: '1'
    };
  }

  componentDidMount() {
    this.setState({
      status: this.props.issue.status,
      priority: this.props.issue.priority.toString(),
      assignee_id: this.props.issue.assignee_id
    });
  }

  onChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;

    this.setState(
      () => ({
        [name]: value
      }),
      () => {
        this.props.updateIssue({
          id: this.props.issue.id,
          ...this.state
        });
      }
    );
  };

  openIssueDetails = () => {
    const { history, issue } = this.props;
    history.push(`/issues/${issue.id}`);
  };

  render() {
    const { t, issue, members, prioritiesOptions, statusesOptions } = this.props;
    const { status, priority, assignee_id } = this.state;
    const assignee = members && formatList(members, 'fullname');
    const image = issue.image_url ? issue.image_url : '/images/issues/dummy2.svg';
    const creatorImage = issue.contact_picture ? issue.contact_picture : '/images/icons/spo-viewallusers-icon.svg';
    const assigneeImage = issue.assignee_image_url
      ? issue.assignee_image_url
      : '/images/icons/spo-viewallusers-icon.svg';

    return (
      <div className="issue">
        <div
          className="image"
          role="presentation"
          onClick={this.openIssueDetails}
          style={{ backgroundImage: `url('${image}')` }}
        />

        <div
          className={classNames('details', { hasDescription: issue.description })}
          role="presentation"
          onClick={this.openIssueDetails}
        >
          <div className="title">
            {issue.title}
            <span className="number">#{issue.id}</span>
          </div>

          <div className="address">
            <span className="loc-icon" /> {issue.customer_name && <span>{issue.customer_name}</span>}
            {issue.floor && <span>{`, ${t('floor')} ${issue.floor}`}</span>}
            {issue.room && <span>{`, ${t('room')} ${issue.room}`}</span>}
          </div>

          {issue.description && <div className="description">{issue.description}</div>}

          <div className="creator">
            <div className="user">
              <span className="user-image" style={{ backgroundImage: `url('${creatorImage}')` }} />
              {`${issue.contact_first_name} ${issue.contact_last_name}`}
            </div>
            <span className="type">{t('qaApp')}</span>
            <span className="time">{moment(issue.created_date).fromNow()}</span>
          </div>
        </div>

        <div className="actions">
          <Dropdown
            options={statusesOptions}
            onChange={e => this.onChange(e, 'status')}
            value={status}
            className={classNames('Dropdown-round status-dropdown', status.value)}
          />

          <div className="assignee">
            <div className="user">
              <span className="user-pretext">{t('assignee')}:</span>
              <span className="user-image" style={{ backgroundImage: `url(${assigneeImage})` }} />
              <Dropdown
                options={assignee}
                onChange={e => this.onChange(e, 'assignee_id')}
                value={assignee_id}
                className="Dropdown-inline Dropdown-max-3"
                placeholder={assignee_id ? issue.assignee_name : t('none')}
              />
            </div>
          </div>
          <div className="priority">
            <span className="text">
              {t('priority')}
              {': '}
            </span>

            <Dropdown
              options={prioritiesOptions}
              onChange={e => this.onChange(e, 'priority')}
              value={mapPriorities(t, priority)}
              className="Dropdown-inline"
            />
          </div>
        </div>
      </div>
    );
  }
}

Issue.propTypes = {
  t: PropTypes.func.isRequired,
  issue: PropTypes.object.isRequired
};

export { Issue as PureComponent };
export default pure(withTranslation('Issues')(withRouter(Issue)));
