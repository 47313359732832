import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabItem from './TabItem';
import Dropdown from './Dropdown';

import './TabNavigation.less';

class TabNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = { showDropdown: false };
  }

  componentDidMount() {
    this.setMaxWidth();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setMaxWidth = () => {
    const elems = document.querySelector('#tab-navigation-container').children;

    this.maxWidth = 0;
    Object.keys(elems).forEach(index => {
      this.maxWidth += elems[index].offsetWidth;
    });
  };

  handleResize = () => {
    if (window.innerWidth <= this.maxWidth) {
      this.setState(() => ({
        showDropdown: true
      }));
    } else {
      this.setState(() => ({
        showDropdown: false
      }));
    }
  };

  renderDropdown = items => <Dropdown items={this.renderItems(items)} />;

  renderItems = items => {
    if (!items || !items.length) return null;

    return items.map(item => <TabItem key={item.id} item={item} />);
  };

  render() {
    const { items } = this.props;
    const { showDropdown } = this.state;

    return (
      <div className="tab-navigation">
        <ul className="flex" id="tab-navigation-container">
          {showDropdown ? this.renderDropdown(items) : this.renderItems(items)}
        </ul>
        <div className="separator" />
      </div>
    );
  }
}

TabNavigation.propTypes = {
  items: PropTypes.array.isRequired
};

export default TabNavigation;
