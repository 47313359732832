import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from '../Header';
import { Sum } from '../Common';
import { SubCart, CheckoutButton } from '.';

import './Preview.less';

const Preview = ({ subCarts, t }) => (
  <div className="cart-flex cart-animation">
    <Header />

    <div className="cart-inside-container">
      <div className="cart-inside">
        {subCarts.map(item => (
          <SubCart key={item.id} item={item} />
        ))}

        {!subCarts.length && (
          <div className="cart-empty">
            <p className="cart-empty-text">{t('empty-cart')}</p>
            <Link className="cart-back-button" to="/shop/all">
              {t('shop-now')}
            </Link>
          </div>
        )}
      </div>
    </div>

    <div className="cart-all-combined">
      <div>
        <Sum />
        <CheckoutButton />
        <Link to="/support" className="cart-help">
          {t('need-help')}
        </Link>
      </div>
    </div>
  </div>
);

Preview.propTypes = {
  subCarts: PropTypes.array,
  t: PropTypes.func.isRequired
};

Preview.defaultProps = {
  subCarts: []
};

const mapStateToProps = ({
  shop: {
    cart: {
      cart: { sub_carts: subCarts }
    }
  }
}) => ({ subCarts });

export { Preview as PureComponent };
export default withTranslation('Shop/Cart/Default')(connect(mapStateToProps)(Preview));
