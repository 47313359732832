import React from 'react';
import { withTranslation } from 'react-i18next';
import { Member } from '../..';

const MemberList = ({ t, members, editMember, showMemberForm, searchEmpty }) => (
  <div className="list">
    {members &&
      members.length > 0 &&
      members
        .sort((a, b) => a.fullname.localeCompare(b.fullname))
        .filter(item => !item.inactive)
        .map(member => (
          <Member
            key={member.email}
            fullName={member.fullname}
            userName={member.email}
            firstname={member.firstname}
            lastname={member.lastname}
            photo={member.image_url}
            editMember={() => editMember(member)}
          />
        ))}
    {(!members || members.length <= 0) && (
      <div>
        <div className="no-members">{searchEmpty ? t('no-members') : t('no-members-found')}</div>
        <button className="link default create-member-link" type="button" onClick={showMemberForm}>
          {t('create-now')}
        </button>
      </div>
    )}
  </div>
);

export { MemberList as PureComponent };
export default withTranslation('Members')(MemberList);
