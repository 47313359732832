import { AUTH_LOGIN_FAILURE, AUTH_LOGOUT_FAILURE } from 'constants/actionTypes';
import { logout } from '../actions/auth';

export default store => next => action => {
  if (
    action.error &&
        action.response &&
        action.response.status === 401 &&
        ![AUTH_LOGIN_FAILURE, AUTH_LOGOUT_FAILURE].includes(action.type)
  ) {
    store.dispatch(logout());
    return;
  }

  next(action);
};
