import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { propCreator } from 'components/HOC';
import { replaceLocation } from 'actions/location';
import { fetchBestsellers } from 'actions/shop/bestsellers';
import { fetchBestsellerProducts } from 'actions/shop/products';
import { createGetProducts, getBestseller } from 'selectors/shop';
import Overview from '../Overview';

const createTitle = ({ bestseller, t }) =>
  [bestseller && bestseller.name, t('Shop:headline')].filter(s => !!s).join(' - ');

const createBreadcrumbItems = ({ bestseller, numberOfItems, t }) =>
  [
    { url: '/shop', label: t('Shop:headline') },
    bestseller && {
      key: bestseller.id,
      label: bestseller.name,
      numberOfItems
    }
  ].filter(s => !!s);

class BestsellerOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: createTitle(props),
      breadcrumbItems: createBreadcrumbItems(props)
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchBestsellers());
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.t !== nextProps.t ||
      this.props.bestseller !== nextProps.bestseller ||
      this.props.numberOfItems !== nextProps.numberOfItems
    ) {
      this.setState({
        title: createTitle(nextProps),
        breadcrumbItems: createBreadcrumbItems(nextProps)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bestseller === false && this.props.bestseller !== prevProps.bestseller) {
      this.props.dispatch(replaceLocation('/shop'));
    }

    if (this.props.bestsellerId !== prevProps.bestsellerId) {
      this.fetchData();
    }
  }

  fetchData(nextPage) {
    const { dispatch, bestsellerId, currentPage, limit } = this.props;

    dispatch(
      fetchBestsellerProducts(bestsellerId, {
        page: nextPage || currentPage,
        limit
      })
    );
  }

  fetchNext() {
    this.fetchData(this.props.currentPage + 1);
  }

  render() {
    const { fetchData, fetchNext } = this;
    const { title, breadcrumbItems } = this.state;
    const { bestsellerId, isFetching, items, hasMore } = this.props;
    const passedProps = { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext };

    return <Overview {...passedProps} trackingListId={`besteller_${bestsellerId}_list`} />;
  }
}

BestsellerOverview.propTypes = {
  bestsellerId: PropTypes.string,
  bestseller: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  dataKey: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  limit: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  numberOfItems: PropTypes.number,
  t: PropTypes.func.isRequired
};

BestsellerOverview.defaultProps = {
  limit: 25
};

const createProps = ({
  match: {
    params: { bestsellerId }
  }
}) => ({
  bestsellerId,
  dataKey: bestsellerId
});

const mapStateToProps = () => {
  const getProducts = createGetProducts();

  return (state, props) => {
    const {
      shop: {
        products: {
          list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore, numberOfItems } = {} }
        }
      }
    } = state;

    return {
      isFetching,
      bestseller: getBestseller(state, props),
      items: getProducts(state, props),
      currentPage,
      hasMore,
      numberOfItems
    };
  };
};

export default withTranslation('Shop/BestsellerOverview')(
  propCreator(createProps)(connect(mapStateToProps)(BestsellerOverview))
);
