import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DocumentTitle, Loader, ServiceEvent } from 'common';
import { fetchServiceEvent } from 'actions/serviceEvents';
import { Products } from '.';
import { TaskGroups } from './Shared';

export class ServiceEventTab extends Component {
  componentDidMount() {
    if (!this.props.item) {
      this.props.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item && !this.props.item) {
      this.props.fetchData();
    }
  }

  render() {
    const { isLoading, item, pathname, search, t, taskGroups } = this.props;

    return (
      <DocumentTitle title={(item && item.name) || '...'}>
        <div className="modal-se-con">
          <Link className="sed-back-con" to={`${pathname}${search}`}>
            <div className="sed-back">{t('back-to-service')}</div>
          </Link>

          <div className="modal-se">
            <Loader isLoading={isLoading} />

            {item && <ServiceEvent item={item} inverse rateBox />}
            {item && item.isTypeCleaning && taskGroups && taskGroups.length > 0 && (
              <TaskGroups items={item.task_groups} />
            )}
            {item && !item.isTypeCleaning && <Products item={item} />}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

ServiceEventTab.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  taskGroups: PropTypes.array,
  t: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    location: { pathname, search },
    serviceEvents: {
      entities,
      single: { [props.id]: { isFetching } = {} }
    }
  } = state;
  const item = entities[props.id];

  return {
    isLoading: !item && isFetching,
    item,
    pathname,
    search
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchData: () => dispatch(fetchServiceEvent(id))
});

export default withTranslation('Modal/ServiceEvent')(connect(mapStateToProps, mapDispatchToProps)(ServiceEventTab));
