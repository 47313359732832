import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { CleaningTasklistLink, IntensiveCleaningTasklistLink } from 'components/Modal';

import './CleaningMenu.less';

const CleaningMenu = ({ t }) => (
  <div className="col-group">
    <div className="cleaning-menu-container">
      <h1>{t('headline')}</h1>
      <div className="cleaning-menu">
        <div className="cleaning-menu__box recurring">
          <div className="cleaning-menu__description">
            <span className="cleaning-menu__icon" />
            <Link to="/cleaning/cleaning-calc/step-1" className="cleaning-menu__book">
              <button type="button" className="cleaning-menu__button">
                {t('book-button')}
              </button>
            </Link>
            <h2>{t('recurring-cleaning-headline')}</h2>
            <ul className="cleaning-menu__list">
              <li>{t('recurring-cleaning-point-1')}</li>
              <li>{t('recurring-cleaning-point-2')}</li>
              <li>{t('recurring-cleaning-point-3')}</li>
            </ul>
            <CleaningTasklistLink className="cleaning-menu__link" title={t('cleaning-tasklist-linktitle')} />
          </div>
        </div>
        <div className="cleaning-menu__box one-time">
          <div className="cleaning-menu__description">
            <span className="cleaning-menu__icon" />
            <Link to="/cleaning/additional-cleaning" className="cleaning-menu__book">
              <button type="button" className="cleaning-menu__button">
                {t('book-button')}
              </button>
            </Link>
            <h2>{t('one-time-headline')}</h2>
            <ul className="cleaning-menu__list">
              <li>{t('one-time-point-1')}</li>
              <li>{t('one-time-point-2')}</li>
              <li>{t('one-time-point-additional')}</li>
            </ul>
            <CleaningTasklistLink className="cleaning-menu__link" title={t('cleaning-tasklist-linktitle')} />
          </div>
        </div>
        <div className="cleaning-menu__box window">
          <div className="cleaning-menu__description">
            <span className="cleaning-menu__icon" />
            <Link to="/cleaning/cleaning-window" className="cleaning-menu__book">
              <button type="button" className="cleaning-menu__button">
                {t('book-button')}
              </button>
            </Link>
            <h2>{t('window-headline')}</h2>
            <ul className="cleaning-menu__list">
              <li>{t('window-point-1')}</li>
              <li>{t('window-point-2')}</li>
            </ul>
          </div>
        </div>
        <div className="cleaning-menu__box intensive">
          <div className="cleaning-menu__description">
            <span className="cleaning-menu__icon" />
            <Link to="/cleaning/intensive-cleaning/step-1" className="cleaning-menu__book">
              <button type="button" className="cleaning-menu__button">
                {t('book-button')}
              </button>
            </Link>
            <h2>{t('intensive-headline')}</h2>
            <ul className="cleaning-menu__list">
              <li>{t('intensive-point-1')}</li>
              <li>{t('intensive-point-additional')}</li>
            </ul>
            <IntensiveCleaningTasklistLink className="cleaning-menu__link" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

CleaningMenu.propTypes = {
  t: PropTypes.func.isRequired
};

export { CleaningMenu as PureComponent };
export default withTranslation('Services/CleaningMenu')(CleaningMenu);
