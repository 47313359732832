import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { Transition } from 'common';

class DropdownSelect extends Component {
  handleClickOutside() {
    this.props.hide();
  }

  render() {
    const { t, isOpen, selectItem, items, children } = this.props;

    return (
      <div className="rel">
        {children}
        <Transition timeout={100}>
          {isOpen && (
            <div className="dropdown-select">
              {items &&
                items.map(item => (
                  <div key={item} role="presentation" onClick={() => selectItem(item)} className="dropdown-item">
                    {t(item.toLowerCase())}
                  </div>
                ))}
            </div>
          )}
        </Transition>
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
};

export { DropdownSelect as PureComponent };
export default pure(withTranslation('Issues')(onClickOutside(DropdownSelect)));
