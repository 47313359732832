export const AUTH_RESTORE = 'AUTH_RESTORE';
export const AUTH_JWT_TOKEN_SET = 'AUTH_JWT_TOKEN_SET';
export const AUTH_ALL_TOKENS_SET = 'AUTH_ALL_TOKENS_SET';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

export const AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST';
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS';
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const TRACK_OFFER_EMAIL_ADDRESS = 'TRACK_OFFER_EMAIL_ADDRESS';
export const TRACK_PARTNER_EMAIL_ADDRESS = 'TRACK_PARTNER_EMAIL_ADDRESS';

export const TRACK_OFFER_SEEN_REQUEST = 'TRACK_OFFER_SEEN_REQUEST';
export const TRACK_OFFER_SEEN_SUCCESS = 'TRACK_OFFER_SEEN_SUCCESS';
export const TRACK_OFFER_SEEN_FAILURE = 'TRACK_OFFER_SEEN_SUCCESS';

export const CASE_CREATE_REQUEST = 'CASE_CREATE_REQUEST';
export const CASE_CREATE_SUCCESS = 'CASE_CREATE_SUCCESS';
export const CASE_CREATE_FAILURE = 'CASE_CREATE_FAILURE';

export const CONTENTFUL_REQUEST = 'CONTENTFUL_REQUEST';
export const CONTENTFUL_SUCCESS = 'CONTENTFUL_SUCCESS';
export const CONTENTFUL_FAILURE = 'CONTENTFUL_FAILURE';

export const I18N_LANGUAGE_SET = 'I18N_LANGUAGE_SET';
export const I18N_CURRENCY_SET = 'I18N_CURRENCY_SET';

export const INVOICES_PAID_REQUEST = 'INVOICES_PAID_REQUEST';
export const INVOICES_PAID_SUCCESS = 'INVOICES_PAID_SUCCESS';
export const INVOICES_PAID_FAILURE = 'INVOICES_PAID_FAILURE';
export const INVOICES_DUE_REQUEST = 'INVOICES_DUE_REQUEST';
export const INVOICES_DUE_SUCCESS = 'INVOICES_DUE_SUCCESS';
export const INVOICES_DUE_FAILURE = 'INVOICES_DUE_FAILURE';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const SET_ORDER_PO_NUMBER_REQUEST = 'SET_ORDER_PO_NUMBER_REQUEST';
export const SET_ORDER_PO_NUMBER_SUCCESS = 'SET_ORDER_PO_NUMBER_SUCCESS';
export const SET_ORDER_PO_NUMBER_FAILURE = 'SET_ORDER_PO_NUMBER_FAILURE';

export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';

export const GET_OFFER_REQUEST = 'GET_OFFER_REQUEST';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'GET_OFFER_FAILURE';

export const OFFER_REJECT = 'OFFER_REJECT';
export const OFFER_REJECT_SUCCESS = 'OFFER_REJECT_SUCCESS';
export const OFFER_REJECT_FAIL = 'OFFER_REJECT_FAIL';

export const OFFER_CONFIRM = 'OFFER_CONFIRM';
export const OFFER_CONFIRM_SUCCESS = 'OFFER_CONFIRM_SUCCESS';
export const OFFER_CONFIRM_FAIL = 'OFFER_CONFIRM_FAIL';

export const OFFER_SUBMIT_REQUEST = 'OFFER_SUBMIT_REQUEST';
export const OFFER_SUBMIT_SUCCESS = 'OFFER_SUBMIT_SUCCESS';
export const OFFER_SUBMIT_FAILURE = 'OFFER_SUBMIT_FAILURE';

export const OFFER_DOWNLOAD_PDF = 'OFFER_DOWNLOAD_PDF';
export const OFFER_DOWNLOAD_PDF_SUCCESS = 'OFFER_DOWNLOAD_PDF_SUCCESS';
export const OFFER_DOWNLOAD_PDF_FAIL = 'OFFER_DOWNLOAD_PDF_FAIL';

export const FETCH_PARTNER_REQUEST = 'FETCH_PARTNER_REQUEST';
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS';
export const FETCH_PARTNER_FAILURE = 'FETCH_PARTNER_FAILURE';

export const FETCH_PARTNER_PDF_REQUEST = 'FETCH_PARTNER_PDF_REQUEST';
export const FETCH_PARTNER_PDF_SUCCESS = 'FETCH_PARTNER_PDF_SUCCESS';
export const FETCH_PARTNER_PDF_FAILURE = 'FETCH_PARTNER_PDF_FAILURE';

export const PARTNER_SUBMIT_OFFER_REQUEST = 'PARTNER_SUBMIT_OFFER_REQUEST';
export const PARTNER_SUBMIT_OFFER_SUCCESS = 'PARTNER_SUBMIT_OFFER_SUCCESS';
export const PARTNER_SUBMIT_OFFER_FAILURE = 'PARTNER_SUBMIT_OFFER_FAILURE';

export const LOCATION_SET = 'LOCATION_SET';
export const LOCATION_PUSH = 'LOCATION_PUSH';
export const LOCATION_REPLACE = 'LOCATION_REPLACE';
export const LOCATION_GO_BACK = 'LOCATION_GO_BACK';

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';

export const SEARCH_SET = 'SEARCH_SET';

export const PASSWORD_RECOVER_REQUEST = 'PASSWORD_RECOVER_REQUEST';
export const PASSWORD_RECOVER_SUCCESS = 'PASSWORD_RECOVER_SUCCESS';
export const PASSWORD_RECOVER_FAILURE = 'PASSWORD_RECOVER_FAILURE';

export const PASSWORD_RESTORE_REQUEST = 'PASSWORD_RESTORE_REQUEST';
export const PASSWORD_RESTORE_SUCCESS = 'PASSWORD_RESTORE_SUCCESS';
export const PASSWORD_RESTORE_FAILURE = 'PASSWORD_RESTORE_FAILURE';

export const SHOP_CART_PO_NUMBER_CHANGE_REQUEST = 'SHOP_CART_PO_NUMBER_CHANGE_REQUEST';
export const SHOP_CART_PO_NUMBER_CHANGE_SUCCESS = 'SHOP_CART_PO_NUMBER_CHANGE_SUCCESS';
export const SHOP_CART_PO_NUMBER_CHANGE_FAILURE = 'SHOP_CART_PO_NUMBER_CHANGE_FAILURE';

export const SERVICE_EVENT_DEFINITION_COSTS_REQUEST = 'SERVICE_EVENT_DEFINITION_COSTS_REQUEST';
export const SERVICE_EVENT_DEFINITION_COSTS_SUCCESS = 'SERVICE_EVENT_DEFINITION_COSTS_SUCCESS';
export const SERVICE_EVENT_DEFINITION_COSTS_FAILURE = 'SERVICE_EVENT_DEFINITION_COSTS_FAILURE';

export const SERVICE_EVENT_DEFINITIONS_REQUEST = 'SERVICE_EVENT_DEFINITIONS_REQUEST';
export const SERVICE_EVENT_DEFINITIONS_SUCCESS = 'SERVICE_EVENT_DEFINITIONS_SUCCESS';
export const SERVICE_EVENT_DEFINITIONS_FAILURE = 'SERVICE_EVENT_DEFINITIONS_FAILURE';

export const SERVICE_EVENT_DEFINITION_REQUEST = 'SERVICE_EVENT_DEFINITION_REQUEST';
export const SERVICE_EVENT_DEFINITION_SUCCESS = 'SERVICE_EVENT_DEFINITION_SUCCESS';
export const SERVICE_EVENT_DEFINITION_FAILURE = 'SERVICE_EVENT_DEFINITION_FAILURE';

export const SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST = 'SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST';
export const SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS = 'SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS';
export const SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE = 'SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE';

export const SERVICE_EVENT_FILTERS_REQUEST = 'SERVICE_EVENT_FILTERS_REQUEST';
export const SERVICE_EVENT_FILTERS_SUCCESS = 'SERVICE_EVENT_FILTERS_SUCCESS';
export const SERVICE_EVENT_FILTERS_FAILURE = 'SERVICE_EVENT_FILTERS_FAILURE';

export const SERVICE_EVENT_FILTERS_SELECT = 'SERVICE_EVENT_FILTERS_SELECT';
export const SERVICE_EVENT_FILTERS_DESELECT = 'SERVICE_EVENT_FILTERS_DESELECT';
export const SERVICE_EVENT_FILTERS_RESET = 'SERVICE_EVENT_FILTERS_RESET';

export const SERVICE_EVENTS_REQUEST = 'SERVICE_EVENTS_REQUEST';
export const SERVICE_EVENTS_SUCCESS = 'SERVICE_EVENTS_SUCCESS';
export const SERVICE_EVENTS_FAILURE = 'SERVICE_EVENTS_FAILURE';

export const SERVICE_EVENT_REQUEST = 'SERVICE_EVENT_REQUEST';
export const SERVICE_EVENT_SUCCESS = 'SERVICE_EVENT_SUCCESS';
export const SERVICE_EVENT_FAILURE = 'SERVICE_EVENT_FAILURE';

export const SERVICE_EVENT_CLEANING_REQUEST = 'SERVICE_EVENT_CLEANING_REQUEST';
export const SERVICE_EVENT_CLEANING_SUCCESS = 'SERVICE_EVENT_CLEANING_SUCCESS';
export const SERVICE_EVENT_CLEANING_FAILURE = 'SERVICE_EVENT_CLEANING_FAILURE';

export const SERVICE_EVENT_CLEANING_OBJECT = 'SERVICE_EVENT_CLEANING_OBJECT';
export const SERVICE_EVENT_CLEANING_OBJECT_RESET = 'SERVICE_EVENT_CLEANING_OBJECT_RESET';

export const SERVICE_EVENT_CLEANING_CALC_REQUEST = 'SERVICE_EVENT_CLEANING_CALC_REQUEST';
export const SERVICE_EVENT_CLEANING_CALC_SUCCESS = 'SERVICE_EVENT_CLEANING_CALC_SUCCESS';
export const SERVICE_EVENT_CLEANING_CALC_FAILURE = 'SERVICE_EVENT_CLEANING_CALC_FAILURE';

export const SERVICE_EVENT_CLEANING_SUBMIT_REQUEST = 'SERVICE_EVENT_CLEANING_SUBMIT_REQUEST';
export const SERVICE_EVENT_CLEANING_SUBMIT_SUCCESS = 'SERVICE_EVENT_CLEANING_SUBMIT_SUCCESS';
export const SERVICE_EVENT_CLEANING_SUBMIT_FAILURE = 'SERVICE_EVENT_CLEANING_SUBMIT_FAILURE';
export const SERVICE_EVENT_CLEANING_SUBMIT_RESET = 'SERVICE_EVENT_CLEANING_SUBMIT_RESET';

export const SERVICE_EVENT_INTENSIVE_CLEANING_REQUEST = 'SERVICE_EVENT_INTENSIVE_CLEANING_REQUEST';
export const SERVICE_EVENT_INTENSIVE_CLEANING_SUCCESS = 'SERVICE_EVENT_INTENSIVE_CLEANING_SUCCESS';
export const SERVICE_EVENT_INTENSIVE_CLEANING_FAILURE = 'SERVICE_EVENT_INTENSIVE_CLEANING_FAILURE';
export const SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT = 'SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT';
export const SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET = 'SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET';

export const SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_REQUEST = 'SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_REQUEST';
export const SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_SUCCESS = 'SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_SUCCESS';
export const SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_FAILURE = 'SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_FAILURE';
export const SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_RESET = 'SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_RESET';

export const SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_REQUEST = 'SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_REQUEST';
export const SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_SUCCESS = 'SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_SUCCESS';
export const SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_FAILURE = 'SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_FAILURE';
export const SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_RESET = 'SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_RESET';

export const SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_REQUEST = 'SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_REQUEST';
export const SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_SUCCESS = 'SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_SUCCESS';
export const SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_FAILURE = 'SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_FAILURE';
export const SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_RESET = 'SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_RESET';

export const SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_REQUEST = 'SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_REQUEST';
export const SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_SUCCESS = 'SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_SUCCESS';
export const SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_FAILURE = 'SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_FAILURE';
export const SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_RESET = 'SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_RESET';

export const SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_REQUEST =
  'SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_REQUEST';
export const SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_SUCCESS =
  'SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_SUCCESS';
export const SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_FAILURE =
  'SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_FAILURE';
export const SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET =
  'SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET';

export const SERVICE_EVENT_RATE_REQUEST = 'SERVICE_EVENT_RATE_REQUEST';
export const SERVICE_EVENT_RATE_SUCCESS = 'SERVICE_EVENT_RATE_SUCCESS';
export const SERVICE_EVENT_RATE_FAILURE = 'SERVICE_EVENT_RATE_FAILURE';

export const SERVICE_EVENT_NOTE_REQUEST = 'SERVICE_EVENT_NOTE_REQUEST';
export const SERVICE_EVENT_NOTE_SUCCESS = 'SERVICE_EVENT_NOTE_SUCCESS';
export const SERVICE_EVENT_NOTE_FAILURE = 'SERVICE_EVENT_NOTE_FAILURE';

export const SERVICE_EVENT_DEPOSIT_REQUEST = 'SERVICE_EVENT_DEPOSIT_REQUEST';
export const SERVICE_EVENT_DEPOSIT_SUCCESS = 'SERVICE_EVENT_DEPOSIT_SUCCESS';
export const SERVICE_EVENT_DEPOSIT_FAILURE = 'SERVICE_EVENT_DEPOSIT_FAILURE';

export const SERVICE_EVENT_STATUS_CHANGE_REQUEST = 'SERVICE_EVENT_STATUS_CHANGE_REQUEST';
export const SERVICE_EVENT_STATUS_CHANGE_SUCCESS = 'SERVICE_EVENT_STATUS_CHANGE_SUCCESS';
export const SERVICE_EVENT_STATUS_CHANGE_FAILURE = 'SERVICE_EVENT_STATUS_CHANGE_FAILURE';

export const SERVICE_EVENT_PRODUCTS_REQUEST = 'SERVICE_EVENT_PRODUCTS_REQUEST';
export const SERVICE_EVENT_PRODUCTS_SUCCESS = 'SERVICE_EVENT_PRODUCTS_SUCCESS';
export const SERVICE_EVENT_PRODUCTS_FAILURE = 'SERVICE_EVENT_PRODUCTS_FAILURE';

export const SERVICE_EVENT_FEEDBACK_REQUEST = 'SERVICE_EVENT_FEEDBACK_REQUEST';
export const SERVICE_EVENT_FEEDBACK_SUCCESS = 'SERVICE_EVENT_FEEDBACK_SUCCESS';
export const SERVICE_EVENT_FEEDBACK_FAILURE = 'SERVICE_EVENT_FEEDBACK_FAILURE';
export const SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST = 'SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST';
export const SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS = 'SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS';
export const SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE = 'SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE';

export const SHOP_CART_BUDGET_LIMIT_IGNORE = 'SHOP_CART_BUDGET_LIMIT_IGNORE';

export const SHOP_CART_REQUEST = 'SHOP_CART_REQUEST';
export const SHOP_CART_SUCCESS = 'SHOP_CART_SUCCESS';
export const SHOP_CART_FAILURE = 'SHOP_CART_FAILURE';

export const SHOP_CART_CREATE_REQUEST = 'SHOP_CART_CREATE_REQUEST';
export const SHOP_CART_CREATE_SUCCESS = 'SHOP_CART_CREATE_SUCCESS';
export const SHOP_CART_CREATE_FAILURE = 'SHOP_CART_CREATE_FAILURE';

export const SHOP_CART_DELETE_REQUEST = 'SHOP_CART_DELETE_REQUEST';
export const SHOP_CART_DELETE_SUCCESS = 'SHOP_CART_DELETE_SUCCESS';
export const SHOP_CART_DELETE_FAILURE = 'SHOP_CART_DELETE_FAILURE';

export const SHOP_CART_SHOW = 'SHOP_CART_SHOW';
export const SHOP_CART_HIDE = 'SHOP_CART_HIDE';
export const SHOP_CART_RESET = 'SHOP_CART_RESET';

export const SHOP_CART_PAYMENT_METHOD_SELECT = 'SHOP_CART_PAYMENT_METHOD_SELECT';
export const SHOP_CART_PAYMENT_CREDIT_CARD_SELECT = 'SHOP_CART_PAYMENT_CREDIT_CARD_SELECT';

export const SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_REQUEST = 'SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_REQUEST';
export const SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS = 'SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS';
export const SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_FAILURE = 'SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_FAILURE';

export const SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST = 'SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST';
export const SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS = 'SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS';
export const SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE = 'SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE';

export const SHOP_CART_PRODUCT_ADD_REQUEST = 'SHOP_CART_PRODUCT_ADD_REQUEST';
export const SHOP_CART_PRODUCT_ADD_SUCCESS = 'SHOP_CART_PRODUCT_ADD_SUCCESS';
export const SHOP_CART_PRODUCT_ADD_FAILURE = 'SHOP_CART_PRODUCT_ADD_FAILURE';

export const SHOP_CART_PRODUCT_REMOVE_REQUEST = 'SHOP_CART_PRODUCT_REMOVE_REQUEST';
export const SHOP_CART_PRODUCT_REMOVE_SUCCESS = 'SHOP_CART_PRODUCT_REMOVE_SUCCESS';
export const SHOP_CART_PRODUCT_REMOVE_FAILURE = 'SHOP_CART_PRODUCT_REMOVE_FAILURE';

export const SHOP_CART_COMMENT_SET_REQUEST = 'SHOP_CART_COMMENT_SET_REQUEST';
export const SHOP_CART_COMMENT_SET_SUCCESS = 'SHOP_CART_COMMENT_SET_SUCCESS';
export const SHOP_CART_COMMENT_SET_FAILURE = 'SHOP_CART_COMMENT_SET_FAILURE';

export const SHOP_CART_SCHEDULE_SET_REQUEST = 'SHOP_CART_SCHEDULE_SET_REQUEST';
export const SHOP_CART_SCHEDULE_SET_SUCCESS = 'SHOP_CART_SCHEDULE_SET_SUCCESS';
export const SHOP_CART_SCHEDULE_SET_FAILURE = 'SHOP_CART_SCHEDULE_SET_FAILURE';

export const SHOP_CART_COUPON_ADD_REQUEST = 'SHOP_COUPON_ADD_REQUEST';
export const SHOP_CART_COUPON_ADD_SUCCESS = 'SHOP_COUPON_ADD_SUCCESS';
export const SHOP_CART_COUPON_ADD_FAILURE = 'SHOP_COUPON_ADD_FAILURE';

export const SHOP_CART_COUPON_REMOVE_REQUEST = 'SHOP_COUPON_REMOVE_REQUEST';
export const SHOP_CART_COUPON_REMOVE_SUCCESS = 'SHOP_COUPON_REMOVE_SUCCESS';
export const SHOP_CART_COUPON_REMOVE_FAILURE = 'SHOP_COUPON_REMOVE_FAILURE';

export const SHOP_CART_SERVICE_REORDER_REQUEST = 'SHOP_CART_SERVICE_REORDER_REQUEST';
export const SHOP_CART_SERVICE_REORDER_SUCCESS = 'SHOP_CART_SERVICE_REORDER_SUCCESS';
export const SHOP_CART_SERVICE_REORDER_FAILURE = 'SHOP_CART_SERVICE_REORDER_FAILURE';

export const SHOP_CART_SERVICE_EDIT_REQUEST = 'SHOP_CART_SERVICE_EDIT_REQUEST';
export const SHOP_CART_SERVICE_EDIT_SUCCESS = 'SHOP_CART_SERVICE_EDIT_SUCCESS';
export const SHOP_CART_SERVICE_EDIT_FAILURE = 'SHOP_CART_SERVICE_EDIT_FAILURE';

export const SHOP_CART_CHECKOUT_REQUEST = 'SHOP_CART_CHECKOUT_REQUEST';
export const SHOP_CART_CHECKOUT_SUCCESS = 'SHOP_CART_CHECKOUT_SUCCESS';
export const SHOP_CART_CHECKOUT_FAILURE = 'SHOP_CART_CHECKOUT_FAILURE';

export const SHOP_CART_CHECKOUT_CANCEL_REQUEST = 'SHOP_CART_CHECKOUT_CANCEL_REQUEST';
export const SHOP_CART_CHECKOUT_CANCEL_SUCCESS = 'SHOP_CART_CHECKOUT_CANCEL_SUCCESS';
export const SHOP_CART_CHECKOUT_CANCEL_FAILURE = 'SHOP_CART_CHECKOUT_CANCEL_FAILURE';

export const SHOP_CART_PAYMENT_REQUEST = 'SHOP_CART_PAYMENT_REQUEST';
export const SHOP_CART_PAYMENT_SUCCESS = 'SHOP_CART_PAYMENT_SUCCESS';
export const SHOP_CART_PAYMENT_FAILURE = 'SHOP_CART_PAYMENT_FAILURE';

export const SHOP_CART_PAYMENT_CANCEL_REQUEST = 'SHOP_CART_PAYMENT_CANCEL_REQUEST';
export const SHOP_CART_PAYMENT_CANCEL_SUCCESS = 'SHOP_CART_PAYMENT_CANCEL_SUCCESS';
export const SHOP_CART_PAYMENT_CANCEL_FAILURE = 'SHOP_CART_PAYMENT_CANCEL_FAILURE';

export const SHOP_CART_ORDER_REQUEST = 'SHOP_CART_ORDER_REQUEST';
export const SHOP_CART_ORDER_SUCCESS = 'SHOP_CART_ORDER_SUCCESS';
export const SHOP_CART_ORDER_FAILURE = 'SHOP_CART_ORDER_FAILURE';

export const SHOP_CATEGORIES_REQUEST = 'SHOP_CATEGORIES_REQUEST';
export const SHOP_CATEGORIES_SUCCESS = 'SHOP_CATEGORIES_SUCCESS';
export const SHOP_CATEGORIES_FAILURE = 'SHOP_CATEGORIES_FAILURE';

export const SHOP_FAVORITES_REQUEST = 'SHOP_FAVORITES_REQUEST';
export const SHOP_FAVORITES_SUCCESS = 'SHOP_FAVORITES_SUCCESS';
export const SHOP_FAVORITES_FAILURE = 'SHOP_FAVORITES_FAILURE';

export const SHOP_FAVORITE_CREATE_REQUEST = 'SHOP_FAVORITE_CREATE_REQUEST';
export const SHOP_FAVORITE_CREATE_SUCCESS = 'SHOP_FAVORITE_CREATE_SUCCESS';
export const SHOP_FAVORITE_CREATE_FAILURE = 'SHOP_FAVORITE_CREATE_FAILURE';

export const SHOP_FAVORITE_DELETE_REQUEST = 'SHOP_FAVORITE_DELETE_REQUEST';
export const SHOP_FAVORITE_DELETE_SUCCESS = 'SHOP_FAVORITE_DELETE_SUCCESS';
export const SHOP_FAVORITE_DELETE_FAILURE = 'SHOP_FAVORITE_DELETE_FAILURE';

export const SHOP_PARTNERS_REQUEST = 'SHOP_PARTNERS_REQUEST';
export const SHOP_PARTNERS_SUCCESS = 'SHOP_PARTNERS_SUCCESS';
export const SHOP_PARTNERS_FAILURE = 'SHOP_PARTNERS_FAILURE';

export const SHOP_PARTNERS_RESTORE = 'SHOP_PARTNERS_RESTORE';
export const SHOP_PARTNERS_SELECT = 'SHOP_PARTNERS_SELECT';
export const SHOP_PARTNERS_SELECT_ONE = 'SHOP_PARTNERS_SELECT_ONE';
export const SHOP_PARTNERS_DESELECT = 'SHOP_PARTNERS_DESELECT';
export const SHOP_PARTNERS_RESET = 'SHOP_PARTNERS_RESET';

export const SHOP_PRODUCTS_REQUEST = 'SHOP_PRODUCTS_REQUEST';
export const SHOP_PRODUCTS_SUCCESS = 'SHOP_PRODUCTS_SUCCESS';
export const SHOP_PRODUCTS_FAILURE = 'SHOP_PRODUCTS_FAILURE';

export const SHOP_PRODUCT_REQUEST = 'SHOP_PRODUCT_REQUEST';
export const SHOP_PRODUCT_SUCCESS = 'SHOP_PRODUCT_SUCCESS';
export const SHOP_PRODUCT_FAILURE = 'SHOP_PRODUCT_FAILURE';

export const SHOP_BESTSELLERS_REQUEST = 'SHOP_BESTSELLERS_REQUEST';
export const SHOP_BESTSELLERS_SUCCESS = 'SHOP_BESTSELLERS_SUCCESS';
export const SHOP_BESTSELLERS_FAILURE = 'SHOP_BESTSELLERS_FAILURE';

export const SHOP_VIEW_LIST_SET = 'SHOP_VIEW_LIST_SET';
export const SHOP_VIEW_GRID_SET = 'SHOP_VIEW_GRID_SET';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAILURE = 'USER_PASSWORD_CHANGE_FAILURE';

export const USER_PASSWORD_SET_REQUEST = 'USER_PASSWORD_SET_REQUEST';
export const USER_PASSWORD_SET_SUCCESS = 'USER_PASSWORD_SET_SUCCESS';
export const USER_PASSWORD_SET_FAILURE = 'USER_PASSWORD_SET_FAILURE';

export const REGISTER_STEP_ONE_REQUEST = 'REGISTER_STEP_ONE_REQUEST';
export const REGISTER_STEP_ONE_SUCCESS = 'REGISTER_STEP_ONE_SUCCESS';
export const REGISTER_STEP_ONE_FAILURE = 'REGISTER_STEP_ONE_FAILURE';

export const REGISTER_STEP_TWO_REQUEST = 'REGISTER_STEP_TWO_REQUEST';
export const REGISTER_STEP_TWO_SUCCESS = 'REGISTER_STEP_TWO_SUCCESS';
export const REGISTER_STEP_TWO_FAILURE = 'REGISTER_STEP_TWO_FAILURE';

export const CUSTOMER_CHANGE_REQUEST = 'CUSTOMER_CHANGE_REQUEST';
export const CUSTOMER_CHANGE_SUCCESS = 'CUSTOMER_CHANGE_SUCCESS';
export const CUSTOMER_CHANGE_FAILURE = 'CUSTOMER_CHANGE_FAILURE';

export const CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST = 'CUSTOMER_OFFICE_PROFILE_PROGRESS_REQUEST';
export const CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS = 'CUSTOMER_OFFICE_PROFILE_PROGRESS_SUCCESS';
export const CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE = 'CUSTOMER_OFFICE_PROFILE_PROGRESS_FAILURE';

export const CUSTOMER_BILLING_ADDRESS_CHANGE_REQUEST = 'CUSTOMER_BILLING_ADDRESS_CHANGE_REQUEST';
export const CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS = 'CUSTOMER_BILLING_ADDRESS_CHANGE_SUCCESS';
export const CUSTOMER_BILLING_ADDRESS_CHANGE_FAILURE = 'CUSTOMER_BILLING_ADDRESS_CHANGE_FAILURE';

export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE';

export const CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST = 'CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST';
export const CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS = 'CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS';
export const CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE = 'CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE';

export const CUSTOMER_SHIPPING_ADDRESS_CHANGE_REQUEST = 'CUSTOMER_SHIPPING_ADDRESS_CHANGE_REQUEST';
export const CUSTOMER_SHIPPING_ADDRESS_CHANGE_SUCCESS = 'CUSTOMER_SHIPPING_ADDRESS_CHANGE_SUCCESS';
export const CUSTOMER_SHIPPING_ADDRESS_CHANGE_FAILURE = 'CUSTOMER_SHIPPING_ADDRESS_CHANGE_FAILURE';

export const CUSTOMER_SHIPPING_ADDRESS_EDIT_REQUEST = 'CUSTOMER_SHIPPING_ADDRESS_EDIT_REQUEST';
export const CUSTOMER_SHIPPING_ADDRESS_EDIT_SUCCESS = 'CUSTOMER_SHIPPING_ADDRESS_EDIT_SUCCESS';
export const CUSTOMER_SHIPPING_ADDRESS_EDIT_FAILURE = 'CUSTOMER_SHIPPING_ADDRESS_EDIT_FAILURE';

export const CUSTOMER_BILLING_ADDRESS_EDIT_REQUEST = 'CUSTOMER_BILLING_ADDRESS_EDIT_REQUEST';
export const CUSTOMER_BILLING_ADDRESS_EDIT_SUCCESS = 'CUSTOMER_BILLING_ADDRESS_EDIT_SUCCESS';
export const CUSTOMER_BILLING_ADDRESS_EDIT_FAILURE = 'CUSTOMER_BILLING_ADDRESS_EDIT_FAILURE';

export const EMAIL_NOTIFICATIONS_EDIT_REQUEST = 'EMAIL_NOTIFICATIONS_EDIT_REQUEST';
export const EMAIL_NOTIFICATIONS_EDIT_SUCCESS = 'EMAIL_NOTIFICATIONS_EDIT_SUCCESS';
export const EMAIL_NOTIFICATIONS_EDIT_FAILURE = 'EMAIL_NOTIFICATIONS_EDIT_FAILURE';

export const EMAIL_NOTIFICATIONS_FETCH_REQUEST = 'EMAIL_NOTIFICATIONS_FETCH_REQUEST';
export const EMAIL_NOTIFICATIONS_FETCH_SUCCESS = 'EMAIL_NOTIFICATIONS_FETCH_SUCCESS';
export const EMAIL_NOTIFICATIONS_FETCH_FAILURE = 'EMAIL_NOTIFICATIONS_FETCH_FAILURE';

export const TERMS_UPDATE_REQUEST = 'TERMS_UPDATE_REQUEST';
export const TERMS_UPDATE_SUCCESS = 'TERMS_UPDATE_SUCCESS';
export const TERMS_UPDATE_FAILURE = 'TERMS_UPDATE_FAILURE';

export const BRAND_SET = 'BRAND_SET';

export const FEATURES_SEEN_REQUEST = 'FEATURES_SEEN_REQUEST';
export const FEATURES_SEEN_SUCCESS = 'FEATURES_SEEN_SUCCESS';
export const FEATURES_SEEN_FAILURE = 'FEATURES_SEEN_FAILURE';

export const TRACKING_PRODUCT_CLICK = 'TRACKING_PRODUCT_CLICK';
export const TRACKING_PRODUCT_VIEW = 'TRACKING_PRODUCT_VIEW';
export const TRACKING_PRODUCT_LIST_VIEW = 'TRACKING_PRODUCT_LIST_VIEW';

export const TRACKING_PROMOTION_VIEW = 'TRACKING_PROMOTION_VIEW';
export const TRACKING_PROMOTION_CLICK = 'TRACKING_PROMOTION_CLICK';
export const TRACKING_PROMOTION_CLOSE = 'TRACKING_PROMOTION_CLOSE';

export const TRACKING_MODAL_CONFIRM = 'TRACKING_MODAL_CONFIRM';
export const TRACKING_MODAL_DENIED = 'TRACKING_MODAL_DENIED';
export const TRACKING_MODAL_OPEN = 'TRACKING_MODAL_OPEN';
export const TRACKING_MODAL_CLOSE = 'TRACKING_MODAL_CLOSE';

export const USER_PAYMENTS_CC_LIST_REQUEST = 'USER_PAYMENTS_CC_LIST_REQUEST';
export const USER_PAYMENTS_CC_LIST_SUCCESS = 'USER_PAYMENTS_CC_LIST_SUCCESS';
export const USER_PAYMENTS_CC_LIST_FAILURE = 'USER_PAYMENTS_CC_LIST_FAILURE';

export const USER_PAYMENTS_CC_CREATE_REQUEST = 'USER_PAYMENTS_CC_CREATE_REQUEST';
export const USER_PAYMENTS_CC_CREATE_SUCCESS = 'USER_PAYMENTS_CC_CREATE_SUCCESS';
export const USER_PAYMENTS_CC_CREATE_FAILURE = 'USER_PAYMENTS_CC_CREATE_FAILURE';

export const USER_PAYMENTS_CC_DELETE_REQUEST = 'USER_PAYMENTS_CC_DELETE_REQUEST';
export const USER_PAYMENTS_CC_DELETE_SUCCESS = 'USER_PAYMENTS_CC_DELETE_SUCCESS';
export const USER_PAYMENTS_CC_DELETE_FAILURE = 'USER_PAYMENTS_CC_DELETE_FAILURE';

export const MEMBERS_FETCH = 'MEMBERS_FETCH';
export const MEMBERS_FETCH_SUCCESS = 'MEMBERS_FETCH_SUCCESS';
export const MEMBERS_FETCH_FAILURE = 'MEMBERS_FETCH_FAILURE';

export const MEMBERS_CREATE = 'MEMBERS_CREATE';
export const MEMBERS_CREATE_SUCCESS = 'MEMBERS_CREATE_SUCCESS';
export const MEMBERS_CREATE_FAILURE = 'MEMBERS_CREATE_FAILURE';

export const MEMBERS_EDIT = 'MEMBERS_EDIT';
export const MEMBERS_EDIT_SUCCESS = 'MEMBERS_EDIT_SUCCESS';
export const MEMBERS_EDIT_FAILURE = 'MEMBERS_EDIT_FAILURE';

export const MEMBERS_DELETE = 'MEMBERS_DELETE';
export const MEMBERS_DELETE_SUCCESS = 'MEMBERS_DELETE_SUCCESS';
export const MEMBERS_DELETE_FAILURE = 'MEMBERS_DELETE_FAILURE';

export const MEMBERS_DEACTIVATE = 'MEMBERS_DEACTIVATE';
export const MEMBERS_DEACTIVATE_SUCCESS = 'MEMBERS_DEACTIVATE_SUCCESS';
export const MEMBERS_DEACTIVATE_FAILURE = 'MEMBERS_DEACTIVATE_FAILURE';

export const MEMBERS_ACTIVATE = 'MEMBERS_ACTIVATE';
export const MEMBERS_ACTIVATE_SUCCESS = 'MEMBERS_ACTIVATE_SUCCESS';
export const MEMBERS_ACTIVATE_FAILURE = 'MEMBERS_ACTIVATE_FAILURE';

export const CHANGE_BUTTONS_COLOR = 'CHANGE_BUTTONS_COLOR';
export const CHANGE_MAIN_COLOR = 'CHANGE_MAIN_COLOR';
export const CHANGE_LOGO = 'CHANGE_LOGO';
export const RESET_COLORS = 'RESET_COLORS';

export const USER_SETTINGS_SAVE_THEME_REQUEST = 'USER_SETTINGS_SAVE_THEME_REQUEST';
export const USER_SETTINGS_SAVE_THEME_SUCCESS = 'USER_SETTINGS_SAVE_THEME_SUCCESS';
export const USER_SETTINGS_SAVE_THEME_FAILURE = 'USER_SETTINGS_SAVE_THEME_FAILURE';

export const TRACKING_USER_THEME_SAVE = 'TRACKING_USER_THEME_SAVE';

export const ISSUES_FETCH = 'ISSUES_FETCH';
export const ISSUES_FETCH_SUCCESS = 'ISSUES_FETCH_SUCCESS';
export const ISSUES_FETCH_FAILURE = 'ISSUES_FETCH_FAILURE';

export const ISSUES_FETCH_ISSUE = 'ISSUES_FETCH_ISSUE';
export const ISSUES_FETCH_ISSUE_SUCCESS = 'ISSUES_FETCH_ISSUE_SUCCESS';
export const ISSUES_FETCH_ISSUE_FAILURE = 'ISSUES_FETCH_ISSUE_FAILURE';

export const ISSUES_CREATE = 'ISSUES_CREATE';
export const ISSUES_CREATE_SUCCESS = 'ISSUES_CREATE_SUCCESS';
export const ISSUES_CREATE_FAILURE = 'ISSUES_CREATE_FAILURE';
export const ISSUES_EDIT = 'ISSUES_EDIT';
export const ISSUES_EDIT_SUCCESS = 'ISSUES_EDIT_SUCCESS';
export const ISSUES_EDIT_FAILURE = 'ISSUES_EDIT_FAILURE';
export const ISSUES_SEARCH = 'ISSUES_SEARCH';
export const ISSUES_SEARCH_SUCCESS = 'ISSUES_SEARCH_SUCCESS';
export const ISSUES_SEARCH_FAILURE = 'ISSUES_SEARCH_FAILURE';
export const ISSUES_FETCH_STATS = 'ISSUES_FETCH_STATS';
export const ISSUES_FETCH_STATS_SUCCESS = 'ISSUES_FETCH_STATS_SUCCESS';
export const ISSUES_FETCH_STATS_FAILURE = 'ISSUES_FETCH_STATS_FAILURE';

export const SERVICE_REQUESTS_FETCH = 'SERVICE_REQUESTS_FETCH';
export const SERVICE_REQUESTS_FETCH_SUCCESS = 'SERVICE_REQUESTS_FETCH_SUCCESS';
export const SERVICE_REQUESTS_FETCH_FAILURE = 'SERVICE_REQUESTS_FETCH_FAILURE';
export const SERVICE_REQUESTS_FETCH_REQUEST = 'SERVICE_REQUESTS_FETCH_REQUEST';
export const SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS = 'SERVICE_REQUESTS_FETCH_REQUEST_SUCCESS';
export const SERVICE_REQUESTS_FETCH_REQUEST_FAILURE = 'SERVICE_REQUESTS_FETCH_REQUEST_FAILURE';
export const SERVICE_REQUESTS_SEARCH = 'SERVICE_REQUESTS_SEARCH';
export const SERVICE_REQUESTS_SEARCH_SUCCESS = 'SERVICE_REQUESTS_SEARCH_SUCCESS';
export const SERVICE_REQUESTS_SEARCH_FAILURE = 'SERVICE_REQUESTS_SEARCH_FAILURE';
export const SERVICE_REQUESTS_EDIT = 'SERVICE_REQUESTS_EDIT';
export const SERVICE_REQUESTS_EDIT_SUCCESS = 'SERVICE_REQUESTS_EDIT_SUCCESS';
export const SERVICE_REQUESTS_EDIT_FAILURE = 'SERVICE_REQUESTS_EDIT_FAILURE';
export const SERVICE_REQUESTS_CREATE = 'SERVICE_REQUESTS_CREATE';
export const SERVICE_REQUESTS_CREATE_SUCCESS = 'SERVICE_REQUESTS_CREATE_SUCCESS';
export const SERVICE_REQUESTS_CREATE_FAILURE = 'SERVICE_REQUESTS_CREATE_FAILURE';
export const SERVICE_REQUESTS_CLONE = 'SERVICE_REQUESTS_CLONE';
