import { shouldFetch } from 'utils';
import {
  SERVICE_EVENT_DEFINITION_COSTS_REQUEST,
  SERVICE_EVENT_DEFINITION_COSTS_SUCCESS,
  SERVICE_EVENT_DEFINITION_COSTS_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

export default (dataKey, query) =>
  authCall({
    shouldFetch: ({
      serviceEventDefinitions: {
        costs: { [dataKey]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { dataKey },
    types: [
      SERVICE_EVENT_DEFINITION_COSTS_REQUEST,
      SERVICE_EVENT_DEFINITION_COSTS_SUCCESS,
      SERVICE_EVENT_DEFINITION_COSTS_FAILURE
    ],
    endpoint: '/user/service-event-definitions/costs',
    method: 'GET',
    query: ({
      serviceEvents: {
        filters: {
          selected: { types }
        }
      }
    }) => ({ ...query, types })
  });
