import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchOffer, trackOfferEmailAddress } from 'actions/offerConfirmation';
import { DocumentTitle } from 'common';
import { LOCATION_HASH_CONFIRM_OFFER, LOCATION_HASH_REJECT_OFFER } from 'constants/location';
import { trackOfferSeen } from 'actions/tracking';

import './OfferConfirmation.less';

const createState = ({
  match: {
    params: { token }
  },
  offer
}) => {
  const offerToken = token;
  const offerAccepted = false;
  const offerRejected = false;

  if (offer[offerToken] && offer[offerToken].item) {
    if (offer[offerToken].item.status === 'Closed Won') {
      return {
        offerToken,
        offerAccepted: true,
        offerRejected: false
      };
    }
    if (offer[offerToken].item.status === 'Closed Lost') {
      return {
        offerToken,
        offerAccepted: false,
        offerRejected: true
      };
    }
  }

  return {
    offerToken,
    offerAccepted,
    offerRejected
  };
};

class OfferConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);
    this.props.fetchData();
  }

  componentWillMount() {
    this.props.trackOfferSeen(this.props.match.params.token);
  }

  componentWillReceiveProps(nextProps) {
    const offerToken = this.props.match.params.token;

    if (this.props.match.params.token !== nextProps.match.params.token) {
      this.setState(createState(nextProps));
      if (nextProps.offer[offerToken].item) {
        this.props.trackOfferEmailAddress(nextProps.offer[offerToken].item.signature_email);
      }
    }
    if (this.props.offer[offerToken] !== nextProps.offer[offerToken]) {
      this.setState(createState(nextProps));
      if (nextProps.offer[offerToken].item) {
        this.props.trackOfferEmailAddress(nextProps.offer[offerToken].item.signature_email);
      }
    }
  }

  render() {
    const { t, offer } = this.props;
    const { offerToken, offerAccepted, offerRejected } = this.state;

    if (!offer[offerToken] || !offer[offerToken].item) {
      return (
        <DocumentTitle title={t('title')}>
          <div className="offer-container col-group content-area">
            <p>{t('no-offer')}</p>
          </div>
        </DocumentTitle>
      );
    }

    return (
      <DocumentTitle title={t('title')}>
        <div className="offer-container col-group content-area">
          {offerRejected ? <div className="offer__notification">{t('offer-notification-reject')}</div> : ''}
          {offerAccepted ? <div className="offer__notification">{t('offer-notification-accept')}</div> : ''}
          <div className="offer-details col-8">
            <div dangerouslySetInnerHTML={{ __html: offer[offerToken].item.template_html }} />
          </div>
          <div className="offer-pricecolumn col-4">
            <div className="offer-box-margin">
              <div dangerouslySetInnerHTML={{ __html: offer[offerToken].item.template_pricebox }} />
              {offerAccepted || offerRejected ? (
                ''
              ) : (
                <div className="col-group offer-buttons">
                  <Link to={LOCATION_HASH_CONFIRM_OFFER} className="button button-submit col-12">
                    {t('confirm')}
                  </Link>
                  <Link className="button button-blue col-12" to={LOCATION_HASH_REJECT_OFFER}>
                    {t('reject')}
                  </Link>
                </div>
              )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: offer[offerToken].item.template_sidebox }} />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

OfferConfirmation.propTypes = {
  fetchData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  offer: PropTypes.object,
  t: PropTypes.func.isRequired,
  trackOfferEmailAddress: PropTypes.func.isRequired
};

const mapStateToProps = ({ offerConfirmation: { offer } }) => ({ offer });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { token }
    }
  }
) => ({
  fetchData: () => dispatch(fetchOffer(token)),
  token,
  trackOfferEmailAddress: email => dispatch(trackOfferEmailAddress(email)),
  trackOfferSeen: offerToken => dispatch(trackOfferSeen(offerToken))
});

export { OfferConfirmation as PureComponent };
export default withTranslation('OfferConfirmation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfferConfirmation)
);
