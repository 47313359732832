import { SERVICE_EVENT_DEFINITIONS_SUCCESS, SERVICE_EVENT_DEFINITION_SUCCESS } from 'constants/actionTypes';
import { serviceEventDefinition } from '../../models';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_DEFINITIONS_SUCCESS:
      return action.payload.reduce(
        (entities, item) => ({ ...entities, [item.id]: serviceEventDefinition(item) }),
        state
      );

    case SERVICE_EVENT_DEFINITION_SUCCESS:
      return { ...state, [action.payload.id]: serviceEventDefinition(action.payload) };

    default:
      return state;
  }
};
