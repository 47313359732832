import { createSelector } from 'reselect';

const getBestsellerId = (state, props) => props.bestsellerId;
const getBestsellers = state => state.shop.bestsellers.items;

export default createSelector(
  [getBestsellerId, getBestsellers], (bestsellerId, bestsellers) => {
    if (!bestsellers) return null;
    return bestsellers.filter(bestseller => bestseller.id === bestsellerId)[0] || false;
  },
);
