import {
  USER_PASSWORD_SET_REQUEST,
  USER_PASSWORD_SET_SUCCESS,
  USER_PASSWORD_SET_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isSetting: false,
    isSet: false
  },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_SET_REQUEST:
      return { ...state, isSetting: true, isSet: false };

    case USER_PASSWORD_SET_SUCCESS:
      return { ...state, isSetting: false, isSet: true };

    case USER_PASSWORD_SET_FAILURE:
      return { ...state, isSetting: false };

    default:
      return state;
  }
};
