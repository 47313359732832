import {
  SHOP_CART_SERVICE_EDIT_REQUEST,
  SHOP_CART_SERVICE_EDIT_SUCCESS,
  SHOP_CART_SERVICE_EDIT_FAILURE
} from 'constants/actionTypes';
import { getJSON } from 'utils';
import { authCall } from 'api';
import i18next from '../../../i18next';
import { showCart } from '../../location';
import { selectOnePartner } from '../partners';
import { confirm } from '../../notifications';

const t = i18next.getFixedT(null, 'Notifications');

const edit = serviceEventDefinitionId =>
  authCall({
    shouldFetch: state => !state.shop.cart.isEditing,
    types: [SHOP_CART_SERVICE_EDIT_REQUEST, SHOP_CART_SERVICE_EDIT_SUCCESS, SHOP_CART_SERVICE_EDIT_FAILURE],
    endpoint: '/user/shop/cart/sed',
    method: 'POST',
    body: { sed_id: serviceEventDefinitionId }
  });

export default serviceEventDefinitionId => (dispatch, getState) => {
  const {
    shop: {
      cart: { cart }
    }
  } = getState();

  return (cart.length ? dispatch(confirm(t('confirm-override-cart'))) : Promise.resolve())
    .then(() => dispatch(edit(serviceEventDefinitionId)))
    .then(response => getJSON(response))
    .then(json => {
      if (json.sub_carts.length > 0) dispatch(selectOnePartner(json.sub_carts[0].partner.id));
      dispatch(showCart('/shop/all')); // should be last
    })
    .catch(() => {});
};
