import { SHOP_VIEW_LIST_SET, SHOP_VIEW_GRID_SET } from 'constants/actionTypes';

export default (
  state = {
    isList: false,
    isGrid: true
  },
  action
) => {
  switch (action.type) {
    case SHOP_VIEW_LIST_SET:
      return { ...state, isList: true, isGrid: false };

    case SHOP_VIEW_GRID_SET:
      return { ...state, isList: false, isGrid: true };

    default:
      return state;
  }
};
