import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { getCategories } from 'selectors/shop';
import { Category, MoreButton } from '.';

import './CategoryMenu.less';

export class CategoryMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxWidth: 0,
      separateItemsAfterRender: true,
      mainItems: props.items,
      subItems: []
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.setMaxWidth(this.DOMNode.offsetWidth);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        separateItemsAfterRender: true,
        mainItems: nextProps.items,
        subItems: []
      });
    }
  }

  componentDidUpdate() {
    if (this.state.separateItemsAfterRender) {
      this.separatedItems();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimer);
    window.removeEventListener('resize', this.handleResize);
  }

  setMaxWidth(maxWidth) {
    if (maxWidth !== this.state.maxWidth) {
      this.setState({
        maxWidth,
        separateItemsAfterRender: true,
        mainItems: this.props.items,
        subItems: []
      });
    }
  }

  separatedItems() {
    let width = 0;
    let maxIndex = 0;
    const { items } = this.props;
    const { maxWidth } = this.state;

    if (!items) return;

    Array.from(this.refMap.values()).forEach((ref, index) => {
      // eslint-disable-next-line react/no-find-dom-node
      const DOMNode = ReactDOM.findDOMNode(ref);
      if (!DOMNode) return;
      width += DOMNode.offsetWidth;
      if (width <= maxWidth) {
        maxIndex = index;
      }
    });

    this.setState({
      separateItemsAfterRender: false,
      mainItems: items.slice(0, maxIndex + 1),
      subItems: items.slice(maxIndex + 1)
    });
  }

  handleResize() {
    const maxWidth = this.DOMNode.offsetWidth;

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => this.setMaxWidth(maxWidth), 200);
  }

  render() {
    this.refMap = new Map();

    const { isFetching } = this.props;
    const { separateItemsAfterRender, mainItems, subItems } = this.state;

    return (
      <div className="shopnav-flex">
        <div
          className="shopnav-flex-inner"
          ref={ref => {
            this.DOMNode = ref;
          }}
        >
          <ul className={`shop-nav ${isFetching ? 'loading' : ''}`}>
            {mainItems &&
              mainItems.map(item => (
                <Category
                  key={item.id}
                  item={item}
                  ref={ref => {
                    this.refMap.set(item.id, ref);
                  }}
                />
              ))}
          </ul>
        </div>

        <MoreButton forceVisible={separateItemsAfterRender} items={subItems} />
      </div>
    );
  }
}

CategoryMenu.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array
};

const mapStateToProps = state => ({
  isFetching: state.shop.categories.list.isFetching,
  items: getCategories(state)
});

export default connect(mapStateToProps)(CategoryMenu);
