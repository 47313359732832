import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { currency } from 'components/HOC';

const TaskList = ({ t, offer, offerToken, c, handleClickTask }) => (
  <div className="offer-box">
    <p className="offer-headline partner__tasklist__head">
      {offer[offerToken].item.subtype === 'Offer' ? t('tasks') : t('task-list')}
    </p>
    <p>{t('choose-task')}</p>
    <div className="partner__tasklist">
      {offer[offerToken].item.performances.map((item, i) => (
        <div key={`${offerToken}-${item.public_token}`}>
          <div className="partner__task">
            <button
              type="button"
              className={`partner__task__headline
                                ${!item.contract_status || item.contract_status === 'confirmed' ? 'accepted' : ''}`}
              id={`task-${i}`}
              onClick={() => handleClickTask(item.public_token)}
            >
              {t('task')} {i + 1}
            </button>
          </div>
          <div className="partner__task__details">
            <p className="partner__task__row">
              <strong>{item.task_misc}</strong>
            </p>
            <p className="partner__task__row">{item.task_name}</p>
            <p className="partner__task__row">{item.area}</p>
            {(offer[offerToken].item.show_price || offer[offerToken].item.subtype === 'Offer') &&
              item.unit_type === 'h' && (
              <p className="partner__task__row unit-price">
                {item.man_hours} {t('hours-cost')} {c(item.unit_price)}
              </p>
            )}
            {(offer[offerToken].item.show_price || offer[offerToken].item.subtype === 'Offer') &&
              item.unit_type === 'm²' && (
              <p className="partner__task__row unit-price">
                {item.man_hours} {t('squaremeters-cost')} {c(item.unit_price)}
              </p>
            )}
            {(offer[offerToken].item.show_price || offer[offerToken].item.subtype === 'Offer') &&
              item.unit_type === 'Stk.' && (
              <p className="partner__task__row unit-price">
                {item.man_hours} {t('pieces-cost')} {c(item.unit_price)}
              </p>
            )}
            {(offer[offerToken].item.show_price || offer[offerToken].item.subtype === 'Offer') && (
              <p className="partner__task__row">
                <strong>
                  {t('total-price')}: {c(item.total_price)}
                </strong>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

TaskList.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired,
  c: PropTypes.func.isRequired,
  handleClickTask: PropTypes.func.isRequired
};

export { TaskList as PureComponent };
export default currency(withTranslation('PartnerConfirmation')(TaskList));
