import { createSelector } from 'reselect';

const getProducts = state => state.shop.products.entities;

export default getItems => createSelector(
  [getItems, getProducts], (items, products) => {
    if (!items) return null;

    return items.filter(item => !item.is_delivery_cost && !item.is_discount).map(item => {
      const { product } = item;
      const customerProduct = products[item.customer_product] || null;

      let newUnitPrice = null;
      if (customerProduct && customerProduct.unit_price !== product.unit_price) {
        newUnitPrice = customerProduct.unit_price;
      }

      return { ...item.product,
        is_out_of_stock: !customerProduct,
        new_unit_price: newUnitPrice,
        image_url: (customerProduct && customerProduct.image_url) || null,
        thumbnail_200: (customerProduct && customerProduct.thumbnail_200) || null,
        thumbnail_400: (customerProduct && customerProduct.thumbnail_400) || null
      };
    });
  },
);
