import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { CreateCase, Faq } from '.';

import './Support.less';

export const Support = ({ t }) => (
  <DocumentTitle title={t('headline')}>
    <div className="col-group content-area pad support">
      <div className="col-6 col-m-12">
        <CreateCase />
      </div>

      <div className="col-6 col-m-12">
        <Faq />
      </div>
    </div>
  </DocumentTitle>
);

Support.propTypes = {
  t: PropTypes.func.isRequired
};

export default pure(withTranslation('Support')(Support));
