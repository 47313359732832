/* eslint-disable no-unused-vars */

import numeral from 'numeral';
import * as locales from 'numeral/locales';
import { I18N_CURRENCY_SET } from 'constants/actionTypes';
import { CURRENCY_EUR } from '../../constants/currency';

export default (currency = CURRENCY_EUR) => dispatch => {
  numeral.locale('de');

  dispatch({
    type: I18N_CURRENCY_SET,
    currency
  });
};
