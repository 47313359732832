import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import responsive from 'components/HOC/responsive';
import { Loader } from 'common';
import Product from '../Product';

const GridView = ({ isFetching, items, hasMore, fetchNext }) => {
  return (
    <div className="shop-module col-12 pad gridview">
      {items && items.length > 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchNext}
          hasMore={hasMore}
          threshold={200}
          className="products-list-wrap"
        >
          {items.map(item => (
            <Product key={item.id} item={item} />
          ))}
        </InfiniteScroll>
      )}

      <Loader isLoading={isFetching} />
    </div>
  );
};

GridView.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  hasMore: PropTypes.bool,
  fetchNext: PropTypes.func.isRequired
};

export default responsive()(GridView);
