import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Loader, ServiceEvent, Transition } from 'common';
import { propCreator } from 'components/HOC';
import { WithCustomer, WithSEFilters } from 'components/DI';
import { fetchServiceEvents } from 'actions/serviceEvents';
import { createGetServiceEvents } from 'selectors/serviceEvents';

import './ServiceEventOverview.less';

class ServiceEventOverview extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.fetchData();
    }
  }

  fetchData(nextPage) {
    const { dispatch, startDate, endDate, dataKey, currentPage, limit, isUpcoming } = this.props;

    dispatch(
      fetchServiceEvents(dataKey, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        page: nextPage || currentPage,
        order_by: isUpcoming ? 'asc' : 'desc',
        only_within_date_span: isUpcoming,
        limit
      })
    );
  }

  fetchNext() {
    const { currentPage } = this.props;
    this.fetchData(currentPage + 1);
  }

  render() {
    const { isUpcoming, isFetching, items, hasMore, t } = this.props;

    return (
      <div className={`next-events-module ${isUpcoming ? 'upcoming' : 'past'}`}>
        <h2 className="headline">{isUpcoming ? t('headline-upcoming') : t('headline-past')}</h2>

        <Transition>
          {items && items.length > 0 && items.map(item => <ServiceEvent key={item.id} item={item} withButtons />)}
        </Transition>

        {items && !items.length && <p className="next-events-placeholder">{t('no-items')}</p>}

        <Loader isLoading={isFetching} />

        {hasMore && (
          <button type="button" className={`btn button-more ${isFetching ? 'disabled' : ''}`} onClick={this.fetchNext}>
            {t('more')}
          </button>
        )}

        <WithCustomer onChange={() => this.fetchData(1)} />
        <WithSEFilters onChange={() => this.fetchData(1)} />
      </div>
    );
  }
}

ServiceEventOverview.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  limit: PropTypes.number,
  isUpcoming: PropTypes.bool,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  t: PropTypes.func.isRequired
};

ServiceEventOverview.defaultProps = {
  limit: 5
};

const createProps = ({ startDate, endDate }) => ({
  dataKey: `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`
});

const mapStateToProps = () => {
  const getServiceEvents = createGetServiceEvents();

  return (state, props) => {
    const {
      serviceEvents: {
        list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore } = {} }
      }
    } = state;

    return {
      isFetching,
      items: getServiceEvents(state, props),
      currentPage,
      hasMore
    };
  };
};

export default withTranslation('Dashboard/ServiceEventOverview')(
  propCreator(createProps)(connect(mapStateToProps)(ServiceEventOverview))
);
