/* eslint-disable import/prefer-default-export */
import i18next from 'src/i18next';

export const SALUTATIONS = [
  {
    label: i18next.t('Members:mr'),
    value: 'Mr'
  },
  {
    label: i18next.t('Members:mrs'),
    value: 'Mrs'
  }
];
