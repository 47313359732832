// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';

import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import App from 'components/App';
import configureStore from './configureStore';
import configureTracking from './tracking';
import * as serviceWorker from './registerServiceWorker';

import './i18next';

// Setup error catcher
if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV === 'production') {
  Raven.config(process.env.REACT_APP_SENTRY_DSN).install();
}

const history = createBrowserHistory();
const store = configureStore(history);
const root = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  configureTracking(history);
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    root
  );
};

render();
serviceWorker.unregister();
