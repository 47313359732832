import {
  SHOP_CART_CHECKOUT_REQUEST,
  SHOP_CART_CHECKOUT_SUCCESS,
  SHOP_CART_CHECKOUT_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_PREVIEW } from 'constants/shoppingCart';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_PREVIEW && !state.shop.cart.isCheckingOut,
    types: [SHOP_CART_CHECKOUT_REQUEST, SHOP_CART_CHECKOUT_SUCCESS, SHOP_CART_CHECKOUT_FAILURE],
    endpoint: '/user/shop/cart/checkout',
    method: 'GET'
  });
