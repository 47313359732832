import RRule from './RRule';

export default w => {
  if (w === 1 || w === '1' || w === 'MO') return RRule.MO;
  if (w === 2 || w === '2' || w === 'TU') return RRule.TU;
  if (w === 3 || w === '3' || w === 'WE') return RRule.WE;
  if (w === 4 || w === '4' || w === 'TH') return RRule.TH;
  if (w === 5 || w === '5' || w === 'FR') return RRule.FR;
  if (w === 6 || w === '6' || w === 'SA') return RRule.SA;
  return RRule.SU;
};
