import {
  SHOP_CART_COUPON_REMOVE_REQUEST,
  SHOP_CART_COUPON_REMOVE_SUCCESS,
  SHOP_CART_COUPON_REMOVE_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT && !state.shop.cart.isRemovingCoupon,
    types: [SHOP_CART_COUPON_REMOVE_REQUEST, SHOP_CART_COUPON_REMOVE_SUCCESS, SHOP_CART_COUPON_REMOVE_FAILURE],
    endpoint: '/user/shop/cart/discount',
    method: 'DELETE'
  });
