import { USER_REQUEST, USER_SUCCESS, USER_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      users: {
        single: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
    endpoint: '/user/users/current',
    method: 'GET'
  });
