import React from "react";
import { pure } from "recompose";
import { ShopPlaceholder } from ".";

const EmptyList = () => (
  <div>
    <ShopPlaceholder icon="shop" location="/shop" />
    {/* <ServicePlaceholder icon="services" location="/services" /> */}
  </div>
);

export { EmptyList as PureComponent };
export default pure(EmptyList);
