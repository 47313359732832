import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { ReorderButton, CancelButton, EditButton } from '.';

import './ServiceEventDefinition.less';

const ServiceEventDefinition = ({ item, withButtons, t }) => (
  <div className={`sed ${item.type} ${item.status}`}>
    <div className={`sed-icon ${item.type}`}>{item.isModeRecurrent && <div className="sed-recurrent" />}</div>

    <div className="sed-texts">
      <div className="headline">{item.name}</div>

      <div className="subline ellipsis">
        <span className={`sed-mode ${item.mode}`}>{t(`mode-${item.mode}`)}</span>

        {item.order_id && item.partner && <span className="sed-partner">{t('by-partner', item.partner)}</span>}

        {!item.is_all_day && item.start_time && (
          <span className="sed-times">
            {item.start_time} – {item.end_time}
          </span>
        )}

        {item.next_event && (
          <span className="sed-next-event">
            {t('next-event')} {item.next_event.start_date.format('dd DD.MM.YYYY')}
          </span>
        )}
      </div>

      {item.isPendingChanges && (
        <div className="subline ellipsis">
          <span className="sed-pending">{t('pending-request')}</span>
        </div>
      )}

      {item.isDraft && item.old_id && (
        <div className="subline ellipsis">
          <span className="sed-pending">{t('edited')}</span>
        </div>
      )}
    </div>

    {withButtons && (
      <div className="sed-buttons">
        <ReorderButton item={item} />
        <CancelButton item={item} />
        <EditButton item={item} />
      </div>
    )}
  </div>
);

ServiceEventDefinition.propTypes = {
  item: PropTypes.object.isRequired,
  withButtons: PropTypes.bool,
  t: PropTypes.func.isRequired
};

export { ServiceEventDefinition as PureComponent };
export default pure(withTranslation('Common/ServiceEventDefinition')(ServiceEventDefinition));
