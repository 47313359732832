import {
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE,
  SHOP_CART_ORDER_SUCCESS
} from 'constants/actionTypes';

const initialState = {
  isCreated: false,
  isCreating: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    case SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_REQUEST:
      return { ...state, isCreating: true, isCreated: false };

    case SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS:
      return { ...state, isCreating: false, isCreated: true };

    case SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_FAILURE:
      return { ...state, isCreating: false };

    default:
      return state;
  }
};
