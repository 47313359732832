import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CategoryDropdown } from '.';

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  show() {
    if (!this.state.isActive) {
      this.setState({ isActive: true });
    }
  }

  hide() {
    if (this.state.isActive) {
      this.setState({ isActive: false });
    }
  }

  handleClick() {
    this.hide();
    if (this.props.onClick) this.props.onClick();
  }

  render() {
    const { item, level, isMore } = this.props;
    const { isActive } = this.state;
    let classLevel = null;

    if (level === 0) {
      classLevel = 'bottom arrow-left';
    } else if (level === 1 && isMore) {
      classLevel = 'left arrow-top';
    } else if (level === 2 && isMore) {
      classLevel = 'left arrow-top';
    } else {
      classLevel = 'right arrow-top';
    }

    return (
      <li
        className={`shop-nav-category dropdown-container ${classLevel}`}
        onMouseEnter={this.show}
        onMouseLeave={this.hide}
      >
        <Link
          className={`shop-category-link ${item.children && item.children.length > 0 ? 'with-children' : ''}`}
          to={`/shop/category/${item.id}/${item.slug}`}
          onClick={this.handleClick}
        >
          {item.name}
        </Link>

        {item.children && item.children.length > 0 && (
          <CategoryDropdown initialItems={item} level={level + 1} isActive={isActive} onClick={this.handleClick} />
        )}
      </li>
    );
  }
}

Category.propTypes = {
  item: PropTypes.object.isRequired,
  level: PropTypes.number,
  onClick: PropTypes.func
};

Category.defaultProps = {
  level: 0
};

export default Category;
