import {
  SHOP_CART_PRODUCT_ADD_REQUEST,
  SHOP_CART_PRODUCT_ADD_SUCCESS,
  SHOP_CART_PRODUCT_ADD_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_PREVIEW } from 'constants/shoppingCart';
import { authCall } from 'api';
import { RRule } from 'utils';

// @deprecated: see changeProduct
export default (product, q = 1, isSubscribed = false) => {
  const quantity = parseInt(q, 10) || 1;
  const rrule = isSubscribed ? new RRule({ freq: RRule.WEEKLY }) : undefined;

  return authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_PREVIEW,
    meta: { product, quantity },
    types: [SHOP_CART_PRODUCT_ADD_REQUEST, SHOP_CART_PRODUCT_ADD_SUCCESS, SHOP_CART_PRODUCT_ADD_FAILURE],
    endpoint: '/user/shop/cart/product',
    method: 'POST',
    body: { product_id: product.id, quantity, rrule }
  });
};
