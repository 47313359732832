import storage from 'store';
import memoize from '../memoize';

const getCustomerId = state =>
  state.current.customer && state.current.customer.id;
const getPartnerIds = state => state.shop.partners.partnerIds;
const getIsList = state => state.shop.view.isList;

export default memoize(
  [getCustomerId, getPartnerIds, getIsList],
  (customerId, partners, listView) =>
    customerId &&
        storage.set(customerId, {
          partners,
          listView
        }),
);
