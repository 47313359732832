export default () => {
  const languages = [];

  if (typeof navigator !== 'undefined') {
    if (navigator.languages) navigator.languages.forEach(language => languages.push(language));
    if (navigator.userLanguage) languages.push(navigator.userLanguage);
    if (navigator.language) languages.push(navigator.language);
  }

  return languages;
};
