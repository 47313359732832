import {
  CHANGE_BUTTONS_COLOR,
  CHANGE_MAIN_COLOR,
  CHANGE_LOGO,
  RESET_COLORS,
  USER_SETTINGS_SAVE_THEME_REQUEST,
  USER_SETTINGS_SAVE_THEME_SUCCESS,
  USER_SETTINGS_SAVE_THEME_FAILURE,
  USER_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  CUSTOMER_CHANGE_SUCCESS
} from 'constants/actionTypes';

const formatFetchedThemeColor = (data, field) => {
  if (data && data.user && data.user.company && data.user.company.theme) {
    return data.user.company.theme[field];
  }
  if (data && data.company && data.company.theme) {
    return data.company.theme[field];
  }

  return '#2c94e2';
};

const formatFetchedThemeLogo = data => (data && data.user ? data.user.company.image_url : data.company.image_url);

export default (
  state = {
    buttonsColor: null,
    mainColor: null,
    logo: null
  },
  action
) => {
  switch (action.type) {
    case CHANGE_BUTTONS_COLOR:
      return {
        ...state,
        buttonsColor: action.color.hex
      };
    case CHANGE_MAIN_COLOR:
      return {
        ...state,
        mainColor: action.color.hex
      };
    case CHANGE_LOGO:
      return {
        ...state,
        logo: action.logo
      };
    case RESET_COLORS:
      return {
        ...state,
        buttonsColor: action.colors.buttonsColor,
        mainColor: action.colors.mainColor,
        logo: action.colors.logo
      };
    case USER_SETTINGS_SAVE_THEME_REQUEST:
      return { ...state };
    case USER_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
    case CUSTOMER_CHANGE_SUCCESS:
    case USER_SETTINGS_SAVE_THEME_SUCCESS:
      return {
        ...state,
        buttonsColor: formatFetchedThemeColor(action.payload, 'button_color'),
        mainColor: formatFetchedThemeColor(action.payload, 'main_color'),
        logo: formatFetchedThemeLogo(action.payload, 'main_color')
      };
    case USER_SETTINGS_SAVE_THEME_FAILURE:
      return { ...state };

    default:
      return state;
  }
};
