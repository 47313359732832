import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from 'common';
import Product from '../Product';

import './ListView.less';

const ListView = ({ isFetching, items, hasMore, fetchNext }) => (
  <div className="shop-module col-12 pad listview">
    {items && items.length > 0 && (
      <InfiniteScroll
        className="list-products-container"
        pageStart={0}
        loadMore={fetchNext}
        hasMore={hasMore}
        threshold={200}
      >
        {items.map(item => (
          <Product key={item.id} item={item} />
        ))}
      </InfiniteScroll>
    )}

    <Loader isLoading={isFetching} />
  </div>
);

ListView.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  hasMore: PropTypes.bool,
  fetchNext: PropTypes.func.isRequired
};

export default pure(ListView);
