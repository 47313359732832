import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { currency } from 'components/HOC';
import { bump } from '../HOC';

const DefaultHeader = ({ isBumping, isLoading, loadingClassName, numberOfItems, sum, t, c }) => (
  <div className={`cart-module ${isBumping ? 'bump' : ''}`}>
    <div className="cart-preview">
      <span>
        {t('shopping-cart')}{' '}
        <span className="cart-length bumping">{numberOfItems > 0 ? `(${numberOfItems})` : ''}</span>
      </span>

      <span className={`cart-sum ${isLoading ? loadingClassName : ''}`}>{c(sum)}</span>
    </div>
  </div>
);

DefaultHeader.propTypes = {
  isBumping: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingClassName: PropTypes.string,
  numberOfItems: PropTypes.number,
  sum: PropTypes.number,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

DefaultHeader.defaultProps = {
  numberOfItems: 0,
  sum: 0,
  loadingClassName: 'loading'
};

const mapStateToProps = ({
  shop: {
    cart: { isFetching: isLoading, isVisible, cart }
  }
}) => ({
  isLoading,
  loadingClassName: isVisible ? 'loading' : 'loading-light',
  numberOfItems: cart.length,
  sum: cart.sum_net
});

export { DefaultHeader as PureComponent };
export default currency(withTranslation('Shop/Cart/Default')(connect(mapStateToProps)(bump(DefaultHeader))));
