import { authCall } from 'api';
import { TERMS_UPDATE_REQUEST, TERMS_UPDATE_SUCCESS, TERMS_UPDATE_FAILURE } from 'constants/actionTypes';

export default () =>
  authCall({
    shouldFetch: s => !s.customer.update.isChanging,
    endpoint: '/user/customer/termsandconditions',
    types: [TERMS_UPDATE_REQUEST, TERMS_UPDATE_SUCCESS, TERMS_UPDATE_FAILURE],
    method: 'PUT'
  });
