import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';

import './Switch.less';

const Switch = props => {
  const { id, className, label, cornerType, alignCheckboxLeft, children } = props;
  const passedProps = omit(props, 'className', 'label', 'cornerType', 'alignCheckboxLeft', 'children');

  const switchLabel = <p className="switch__text">{label}</p>;

  return (
    <label htmlFor={id} className={`switch ${className}`}>
      {!alignCheckboxLeft && children}
      {label && !alignCheckboxLeft && switchLabel}

      <div className="switch__button">
        <input {...passedProps} className="switch__checkbox" type="checkbox" />
        <div className={`switch__slider ${cornerType}`} />
      </div>

      {alignCheckboxLeft && children}
      {label && alignCheckboxLeft && switchLabel}
    </label>
  );
};

Switch.propTypes = {
  alignCheckboxLeft: PropTypes.bool,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  cornerType: PropTypes.string
};

Switch.defaultProps = {
  className: '',
  cornerType: 'round',
  alignCheckboxLeft: true
};

export default htmlId(Switch);
