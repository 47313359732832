import { connect } from 'react-redux';
import numeral from 'numeral';
import { CURRENCY_GBP } from 'constants/currency';

const formatEUR = value => numeral(value).format('0,0.00 $');
const formatGBP = value => numeral(value).format('$0,0.00');

const mapStateToProps = ({ i18n: { currency } }) => ({
  c: currency === CURRENCY_GBP ? formatGBP : formatEUR
});

export default WrappedComponent =>
  connect(
    mapStateToProps,
    {}
  )(WrappedComponent);
