import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';

export const NoResult = ({ t }) => (
  <div className="orders-no-result">
    <p className="hint">{t('no-result-msg')}</p>
    <p className="hint">{t('no-result-hint')}</p>
    <Link className="button button-blue go-to-shop" to="/shop">
      {t('go-to-shop')}
    </Link>
  </div>
);

NoResult.propTypes = {
  t: PropTypes.func.isRequired
};

export default pure(NoResult);
