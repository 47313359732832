import { OFFER_DOWNLOAD_PDF, OFFER_DOWNLOAD_PDF_SUCCESS, OFFER_DOWNLOAD_PDF_FAIL } from 'constants/actionTypes';
import { apiCall } from 'api';

const downloadOfferPdf = pdfUrl =>
  apiCall({
    types: [OFFER_DOWNLOAD_PDF, OFFER_DOWNLOAD_PDF_SUCCESS, OFFER_DOWNLOAD_PDF_FAIL],
    endpoint: `/offer/download-pdf?url=${encodeURIComponent(pdfUrl)}`,
    method: 'GET'
  });

export default downloadOfferPdf;
