import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import omit from 'lodash/omit';

import './Transition.less';

const Transition = props => {
  const { name, appearTimeout, enterTimeout, leaveTimeout, timeout } = props;
  const passedProps = omit(props, 'name', 'appearTimeout', 'enterTimeout', 'leaveTimeout', 'timeout');

  if (process.env.REACT_APP_DISABLE_TRANSITIONS) return <div {...passedProps}>{props.children}</div>;

  return (
    <CSSTransition
      {...passedProps}
      classNames={name || 'fade'}
      appear={appearTimeout !== false}
      enter={enterTimeout !== false}
      exit={leaveTimeout !== false}
      timeout={{
        appear: timeout || 500,
        enter: timeout || 500,
        exit: timeout || 500
      }}
    >
      <div>{props.children}</div>
    </CSSTransition>
  );
};

Transition.propTypes = {
  name: PropTypes.string,
  appearTimeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  enterTimeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  leaveTimeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  timeout: PropTypes.number
};

export default pure(Transition);
