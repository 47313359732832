import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class RatingButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxVisible: false
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isRated && nextProps.isRated) {
      this.closeBox();
    }
  }

  toggleBox() {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  }

  closeBox() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { item } = this.props;

    return (
      <div className="star se-button">
        <Link className="button-star" to={`/feedback/${item.id}/${item.start_date}`}>
                    &nbsp;
        </Link>
      </div>
    );
  }
}

RatingButton.propTypes = {
  item: PropTypes.object.isRequired,
  isRated: PropTypes.bool
};

const mapStateToProps = ({ serviceEvents: { rate } }, { item: { id } }) => ({
  isRating: !!(rate[id] && rate[id].isRating),
  isRated: !!(rate[id] && rate[id].isRated)
});

export default withTranslation('Common/ServiceEvent/RatingButton')(connect(mapStateToProps)(RatingButton));
