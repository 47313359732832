import { combineReducers } from 'redux';
import change from './change';
import update from './update';
import billingAddress from './billingAddress';
import shippingAddress from './shippingAddress';
import shippingAddressTicket from './shippingAddressTicket';
import officeProfileProgress from './officeProfileProgress';
import shoppingShippingAddress from './shoppingShippingAddress';
import shoppingBillingAddress from './shoppingBillingAddress';

export default combineReducers({
  change,
  billingAddress,
  shippingAddress,
  shippingAddressTicket,
  update,
  officeProfileProgress,
  shoppingShippingAddress,
  shoppingBillingAddress
});
