import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '..';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { isBoxVisible: false };
  }

  toggleBox = () => {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  };

  renderMoreLinks = items => (
    <div className="tab-navigation-content">
      <ul>{items}</ul>
    </div>
  );

  render() {
    const { isBoxVisible } = this.state;
    const { items } = this.props;
    return (
      // eslint-disable-next-line
      <a
        className={`tab-navigation-item dropdown ${isBoxVisible ? 'open' : ''}`}
        onClick={this.toggleBox}
        role="button"
        tabIndex="0"
      >
        <button className="list-toggle-button" type="button" />

        <Transition>{isBoxVisible && this.renderMoreLinks(items)}</Transition>
      </a>
    );
  }
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired
};

export default Dropdown;
