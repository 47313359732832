import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { viewProductList } from 'actions/tracking';
import { ListTeaser, SliderTeaser } from '.';

class Teaser extends Component {
  constructor(props) {
    super(props);

    this.viewedIds = [];
  }

  componentDidMount() {
    this.handleViewItems();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.trackingListId !== nextProps.trackingListId) {
      this.viewedIds = [];
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.handleViewItems();
    }
  }

  handleViewItems() {
    const { items, trackingListId, onViewItems } = this.props;

    if (!items || !items.length) return;

    const products = items.map(item => item.id).filter(id => !this.viewedIds.includes(id));

    if (!products.length) return;

    this.viewedIds = [...this.viewedIds, ...products];

    onViewItems(trackingListId, products);
  }

  render() {
    const { headline, location, locationText, items, className, isList, isGrid } = this.props;

    return (
      <div className={className}>
        <p className="headline">
          <Link to={location}>{headline}</Link>
        </p>

        {isList && <ListTeaser location={location} locationText={locationText} items={items} />}

        {isGrid && <SliderTeaser location={location} locationText={locationText} items={items} />}
      </div>
    );
  }
}

Teaser.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  locationText: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  trackingListId: PropTypes.string.isRequired,
  onViewItems: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    view: { isList, isGrid }
  }
}) => ({ isList, isGrid });

const mapDispatchToProps = {
  onViewItems: viewProductList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teaser);
