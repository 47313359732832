import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

class WithCustomer extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.customerHasChanged(nextProps)) {
      this.props.onChange();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !this.customerHasChanged(nextProps);
  }

  customerHasChanged(nextProps) {
    return this.props.customer && this.props.customer !== nextProps.customer;
  }

  render() {
    if (!this.props.children) return null;

    const passedProps = omit(this.props, 'customer', 'onChange', 'dispatch');
    return <div {...passedProps}>{this.props.children}</div>;
  }
}

WithCustomer.propTypes = {
  customer: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ current: { customer } }) => ({ customer });

export { WithCustomer as PureComponent };
export default connect(mapStateToProps)(WithCustomer);
