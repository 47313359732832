import { OFFER_CONFIRM, OFFER_CONFIRM_SUCCESS, OFFER_CONFIRM_FAIL } from 'constants/actionTypes';
import { apiCall } from 'api';

const confirmOffer = (token, offer) =>
  apiCall({
    types: [OFFER_CONFIRM, OFFER_CONFIRM_SUCCESS, OFFER_CONFIRM_FAIL],
    endpoint: `/offer/accept/${token}`,
    method: 'POST',
    body: offer
  });

export default confirmOffer;
