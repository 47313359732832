import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';

import './RadioOption.less';

const RadioOption = props => {
  const { alignButtonLeft, children, id, label, value } = props;
  const passedProps = omit(props, 'alignButtonLeft', 'children', 'id', 'label');
  const optionId = `${id}-${value}`;
  return (
    <label className={`radiobutton ${alignButtonLeft ? 'left' : 'right'}`} htmlFor={optionId}>
      <input {...passedProps} id={optionId} type="radio" className="radiobutton-input" />
      <div className="radiobutton-wrapper">
        <div className="radiobutton-wrapper--top">
          <p className="radiobutton-label">{label}</p>
          <span className="radiobutton-icon" />
        </div>
        {children && <div className="radiobutton-content">{children}</div>}
      </div>
    </label>
  );
};

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  alignButtonLeft: PropTypes.bool
};

RadioOption.defaultProps = {
  alignButtonLeft: true
};

export default htmlId(RadioOption);
