import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { stopEditingSED } from 'actions/shop/cart';

import './Notifications.less';

const createOnPrompt = dispatch => location => {
  if (location.pathname.startsWith('/shop')) return true;
  dispatch(stopEditingSED(location));
  return false;
};

const Editing = ({ isVisible, isLoading, isDisabled, item, partner, onClick, onPrompt, t }) =>
  isVisible ? (
    <div className="notifications bottom-note">
      <div className="notification-warning notification-modal notification-bottom">
        <div className="text-wrapper">
          <div className="notification-headline">
            <p dangerouslySetInnerHTML={{ __html: t('message', item) }} />
          </div>

          <div className="notification-subtext">
            <p>{t('help', partner)}</p>
          </div>
        </div>

        <div className="button-wrapper">
          <button
            type="button"
            className={`${isLoading ? 'loading' : ''} ${isDisabled ? 'disabled' : ''}`}
            onClick={onClick}
          >
            {t('cancel')}
          </button>
        </div>
      </div>

      <Prompt when message={onPrompt} />
    </div>
  ) : null;

Editing.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  item: PropTypes.object,
  partner: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onPrompt: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isDeleting: isLoading, isOrdering: isDisabled, cart }
  }
}) => ({
  isVisible: !!cart.sed,
  isLoading,
  isDisabled,
  item: cart.sed,
  partner: cart.sed && cart.sed.partner
});

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(stopEditingSED()),
  onPrompt: createOnPrompt(dispatch)
});

export { Editing as PureComponent };
export default withTranslation('Shop/Notifications/Editing')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Editing)
);
