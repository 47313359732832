import { VERIFY_REQUEST, VERIFY_SUCCESS, VERIFY_FAILURE } from 'constants/actionTypes';
import { apiCall } from 'api';

export default token =>
  apiCall({
    types: [VERIFY_REQUEST, VERIFY_SUCCESS, VERIFY_FAILURE],
    endpoint: `/user/verify/${token}`,
    method: 'PUT',
    meta: { token }
  });
