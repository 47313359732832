import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changeProduct } from 'actions/shop/cart';

export class AddForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
      isSubscribed: false
    };

    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.decreaseQuantity = this.decreaseQuantity.bind(this);
    this.toggleSubscription = this.toggleSubscription.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  increaseQuantity() {
    this.quantityInput.focus();
    this.setState(prevState => ({ quantity: prevState.quantity + 1 }));
  }

  decreaseQuantity() {
    this.quantityInput.focus();
    if (this.state.quantity === 1) return;
    this.setState(prevState => ({ quantity: prevState.quantity - 1 }));
  }

  toggleSubscription() {
    this.setState(prevState => ({
      isSubscribed: !prevState.isSubscribed
    }));
  }

  handleChange({ target: { value } }) {
    const quantity = parseInt(value.trim(), 10) || 0;

    if (this.state.quantity !== quantity && quantity > 0) {
      this.setState({ quantity });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { item, onSubmit, closeModal } = this.props;
    const { quantity, isSubscribed } = this.state;

    onSubmit(item, quantity, isSubscribed);
    closeModal();
  }

  render() {
    const { t, item } = this.props;
    const { quantity, isSubscribed } = this.state;

    return (
      <form className="product-wrapper" onSubmit={this.handleSubmit}>
        {item.discount_price > 0 && (
          <div className={`product-subscription-wrapper ${isSubscribed ? 'active' : ''}`}>
            <button className="discount-subscribe" type="button" onClick={this.toggleSubscription}>
              <div className="recurring-icon" />
              {t('discount-question')}
              <div className="recurring-check-icon" />
            </button>
          </div>
        )}

        <div className="product-add-wrapper">
          <button className="product-add" type="submit">
            {t('add-to-cart')}
          </button>

          <div className="cart-product-changer">
            <input
              ref={ref => {
                this.quantityInput = ref;
              }}
              type="number"
              name="quantity"
              className="cart-product-amount"
              value={quantity}
              onChange={this.handleChange}
              min="1"
            />

            <button className="cart-product-plus" onClick={this.increaseQuantity} type="button" />

            <button className="cart-product-minus" onClick={this.decreaseQuantity} type="button" />
          </div>
        </div>
      </form>
    );
  }
}

AddForm.propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onSubmit: changeProduct
};

export { AddForm as PureComponent };
export default withTranslation('Modal/ProductDetail')(
  connect(
    null,
    mapDispatchToProps
  )(AddForm)
);
