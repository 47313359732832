import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAllTokens } from 'actions/auth';
import { LOCATION_PATHNAME_DEFAULT } from 'constants/location';

const LeadLoginFromUrl = ({ jwtToken, masterToken, onMatch }) => {
  onMatch(jwtToken, masterToken);
  return <Redirect to={`${LOCATION_PATHNAME_DEFAULT}/#lead-data`} />;
};

LeadLoginFromUrl.propTypes = {
  jwtToken: PropTypes.string.isRequired,
  masterToken: PropTypes.string.isRequired,
  onMatch: PropTypes.func.isRequired
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { jwtToken, masterToken }
    }
  }
) => ({ jwtToken, masterToken });

const mapDispatchToProps = {
  onMatch: setAllTokens
};

export { LeadLoginFromUrl as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadLoginFromUrl);
