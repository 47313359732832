import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { editEmailNotifications, fetchEmailNotifications } from 'actions/users';
import { Checkbox } from 'common/Form';

import './EmailNotifications.less';

export class EmailNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      industryInsight: this.props.emailPreferences.industry_insight || false,
      specialOffers: this.props.emailPreferences.special_offers || false,
      updatesAnnouncements: this.props.emailPreferences.updates_announcements || false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.propsHaveChanged(nextProps)) {
      this.setState(() => ({
        industryInsight: nextProps.emailPreferences.industry_insight || false,
        specialOffers: nextProps.emailPreferences.special_offers || false,
        updatesAnnouncements: nextProps.emailPreferences.updates_announcements || false
      }));
    }
  }

  optOut = e => {
    e.preventDefault();
    const emailData = {
      industryInsight: false,
      specialOffers: false,
      updatesAnnouncements: false,
      emailOptOut: true
    };
    this.props.onSubmit(emailData);
  };

  propsHaveChanged(nextProps) {
    if (this.props.emailPreferences && nextProps.emailPreferences) {
      return (
        this.props.emailPreferences.industry_insight !== nextProps.emailPreferences.industry_insight ||
        this.props.emailPreferences.special_offers !== nextProps.emailPreferences.special_offers ||
        this.props.emailPreferences.updates_announcements !== nextProps.emailPreferences.updates_announcements
      );
    }
    return false;
  }

  handleSubmit(e) {
    e.preventDefault();
    const emailData = {
      industryInsight: this.state.industryInsight,
      specialOffers: this.state.specialOffers,
      updatesAnnouncements: this.state.updatesAnnouncements,
      emailOptOut: false
    };
    this.props.onSubmit(emailData);
  }

  render() {
    const { isSubmitting, t } = this.props;

    return (
      <div className="email-notifications">
        <p className="headline">{t('headline')}</p>
        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div className="email-notifications__group">
              <Checkbox
                alignCheckboxLeft
                className="email-notifications__checkbox"
                label={t('updates')}
                id="updates"
                name="updates"
                onChange={() =>
                  this.setState(prevState => ({
                    updatesAnnouncements: !prevState.updatesAnnouncements
                  }))
                }
                checked={this.state.updatesAnnouncements}
              />
              <p className="email-notifications__subline">{t('updates-subline')}</p>
            </div>
            <div className="email-notifications__group">
              <Checkbox
                alignCheckboxLeft
                className="email-notifications__checkbox"
                label={t('offers')}
                id="offers"
                name="offers"
                onChange={() =>
                  this.setState(prevState => ({
                    specialOffers: !prevState.specialOffers
                  }))
                }
                checked={this.state.specialOffers}
              />
              <p className="email-notifications__subline">{t('offers-subline')}</p>
            </div>
            <div className="email-notifications__group">
              <Checkbox
                alignCheckboxLeft
                className="email-notifications__checkbox"
                label={t('insights')}
                id="insights"
                name="insights"
                onChange={() =>
                  this.setState(prevState => ({
                    industryInsight: !prevState.industryInsight
                  }))
                }
                checked={this.state.industryInsight}
              />
              <p className="email-notifications__subline">{t('insights-subline')}</p>
            </div>
            <p className="email-notifications__disclaimer">{t('disclaimer')}</p>
            <div role="button" className="link email-notifications__optout" onClick={this.optOut} tabIndex="-1">
              {t('opt-out')}
            </div>
            <button type="submit" className={isSubmitting ? 'loading-light' : ''}>
              {t('save')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

EmailNotifications.propTypes = {
  industry_insight: PropTypes.bool,
  special_offers: PropTypes.bool,
  updates_announcements: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ users: { emailNotifications } }) => ({
  isSubmitting: emailNotifications.isSubmitting,
  isChanged: emailNotifications.isChanged,
  emailPreferences: emailNotifications.emailPreferences || {}
});

const mapDispatchToProps = {
  fetchData: fetchEmailNotifications,
  onSubmit: editEmailNotifications
};

export default withTranslation('Settings/Customer/EmailNotifications')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmailNotifications)
);
