import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, RadioButton, Select } from 'common/Form';
import { editShippingAddress, editBillingAddress } from 'actions/customer';
import { translateEditing } from '../HOC';

import './ShippingAddress.less';

class ShippingAddress extends Component {
  constructor(props) {
    super(props);

    const { name, floorLevel, item, shoppingShippingAddress } = this.props;
    const shippingAddress = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.shipping_address : item;
    const companyName = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.corporate_name : name;
    const floor = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.floor : floorLevel;

    this.state = {
      showEditShippingAddressForm: !this.props.isConverted,
      companyName,
      street: shippingAddress.street ? shippingAddress.street : '',
      postalCode: shippingAddress.postal_code ? shippingAddress.postal_code : '',
      city: shippingAddress.city ? shippingAddress.city : '',
      elevator: shippingAddress.elevator ? shippingAddress.elevator : true,
      floor,
      countryCode: shippingAddress.country_code ? shippingAddress.country_code : 'DE'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.shoppingShippingAddress.isChanging && nextProps.shoppingShippingAddress.isChanged) {
      this.handleEditAddressButtonClick(true);
      this.props.handleShippingAddressSubmit();

      if (nextProps.isAddressesTheSame) {
        const { companyName, street, postalCode, city, countryCode } = this.state;

        this.props.editBillingAddressDetails(companyName, street, postalCode, city, countryCode);
      }
    }
  }

  handleEditAddressButtonClick = (forceCloseDialog = false) => {
    const { name, floorLevel, item, shoppingShippingAddress } = this.props;
    const shippingAddress = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.shipping_address : item;
    const companyName = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.corporate_name : name;
    const floor = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.floor : floorLevel;

    this.setState(prevState => ({
      ...prevState,
      companyName,
      street: shippingAddress.street ? shippingAddress.street : '',
      postalCode: shippingAddress.postal_code ? shippingAddress.postal_code : '',
      city: shippingAddress.city ? shippingAddress.city : '',
      elevator: shippingAddress.elevator ? shippingAddress.elevator : false,
      floor,
      countryCode: shippingAddress.country_code ? shippingAddress.country_code : 'DE'
    }));

    this.setState(prevState => ({
      ...prevState,
      showEditShippingAddressForm: forceCloseDialog ? false : !prevState.showEditShippingAddressForm
    }));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));

    this.props.handleShippingAddressChange();
  };

  handleSubmit = () => {
    const { companyName, street, postalCode, city, elevator, floor, countryCode } = this.state;

    this.props.editShippingAddressDetails(companyName, street, postalCode, city, elevator, floor, countryCode);
  };

  shouldSubmit = () => {
    const { companyName, street, postalCode, city, floor } = this.state;

    return companyName && street && postalCode && city && floor;
  };

  renderEditAddressForm = (t, companyName, street, postalCode, city, elevator, floor, countryCode) => {
    const items = [{ name: t('DE'), value: 'DE' }, { name: t('AU'), value: 'AU' }];

    return (
      <div className="company-address shipping">
        <form>
          <fieldset>
            <Input
              autoFocus
              label={t('companyName')}
              name="companyName"
              parentClass="form-group"
              onChange={this.handleChange}
              value={companyName}
              disabled
            />
            <div className="form-group flex-row">
              <Input
                label={t('postCode')}
                name="postalCode"
                parentClass="company-zip row-group"
                onChange={this.handleChange}
                value={postalCode}
                required
                error={postalCode === null || postalCode === ''}
                errormsg={t('error-required')}
              />

              <Input
                label={t('city')}
                name="city"
                parentClass="company-city row-group"
                onChange={this.handleChange}
                value={city}
                required
                error={city === null || city === ''}
                errormsg={t('error-required')}
              />
            </div>

            <div className="country-dropdown dropdown-list">
              <Select
                label={t('country')}
                name="country_code"
                onChange={({ target: { value } }) => this.setState(() => ({ countryCode: value }))}
                options={items}
                value={countryCode}
                parentClass="form-group input__select"
              />
            </div>

            <div className="form-group flex-row floor-elevator-wrapper">
              <div className="floor">
                <Input
                  label={t('floor')}
                  name="floor"
                  parentClass="form-group"
                  onChange={this.handleChange}
                  value={floor}
                  required
                  error={floor === null || city === ''}
                  errormsg={t('error-required')}
                />
              </div>

              <div className="elevator input form-group">
                <label className="elevator__label input__label required" htmlFor="elevator-radio">
                  {t('elevator')}
                </label>
                <div className="radio-group" id="elevator-radio">
                  <RadioButton
                    name="elevator"
                    label={t('yes')}
                    value="true"
                    onClick={this.handleChange}
                    defaultChecked
                    required
                  />
                  <RadioButton
                    name="elevator"
                    label={t('no')}
                    value="false"
                    onClick={this.handleChange}
                    defaultChecked={false}
                    required
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              className="button-blue button-save-address"
              onClick={this.handleSubmit}
              disabled={!this.shouldSubmit()}
            >
              {t('save')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  };

  renderAddressHeader = () => {
    const { t, isConverted } = this.props;
    const { showEditShippingAddressForm } = this.state;

    return (
      <div className="cart-title flex">
        <span className="title-address">{t('shipping-address-1')}</span>
        <div
          className="edit-button shipping"
          onClick={() => this.handleEditAddressButtonClick(false)}
          role="presentation"
        >
          {!isConverted && !showEditShippingAddressForm ? <span className="edit-button-bg" /> : ''}
          {!isConverted && (
            <span className="edit-address">{showEditShippingAddressForm ? t('cancel-edit') : t('edit')}</span>
          )}
        </div>
      </div>
    );
  };

  renderAddress = (t, name, item) => (
    <div>
      <p className="cart-company">{name}</p>
      <p className="cart-address">
        {item.street}, {item.postal_code} {item.city}, {t(item.country_code)}
      </p>
    </div>
  );

  render() {
    const { shoppingShippingAddress, item, name, t } = this.props;

    const {
      showEditShippingAddressForm,
      companyName,
      street,
      postalCode,
      city,
      elevator,
      floor,
      countryCode
    } = this.state;

    const shippingAddress = shoppingShippingAddress.item ? shoppingShippingAddress.item.company.shipping_address : item;

    if (!shippingAddress) {
      return (
        <div className="cart-item shipping-address">
          {this.renderAddressHeader('shipping')}
          {this.renderEditAddressForm(t, companyName, street, postalCode, city, elevator, floor, countryCode)}
        </div>
      );
    }

    return (
      <div className="cart-item shipping-address">
        {this.renderAddressHeader('shipping')}
        {showEditShippingAddressForm
          ? this.renderEditAddressForm(t, companyName, street, postalCode, city, elevator, floor, countryCode)
          : this.renderAddress(
            t,
            shoppingShippingAddress.item ? shoppingShippingAddress.item.company.corporate_name : name,
            shippingAddress
          )}
      </div>
    );
  }
}

ShippingAddress.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
  floorLevel: PropTypes.string,
  t: PropTypes.func.isRequired,
  editShippingAddressDetails: PropTypes.func,
  editBillingAddressDetails: PropTypes.func,
  shoppingShippingAddress: PropTypes.object,
  isConverted: PropTypes.bool,
  handleShippingAddressSubmit: PropTypes.func,
  handleShippingAddressChange: PropTypes.func,
  isAddressesTheSame: PropTypes.bool
};

const mapStateToProps = ({ current: { customer }, customer: { shoppingShippingAddress } }) => ({
  item: customer && customer.shipping_address,
  name: customer && (customer.corporate_name || customer.name),
  floorLevel: customer && customer.floor,
  shoppingShippingAddress,
  isConverted: customer ? customer.is_converted : 0
});

const mapDispatchToProps = dispatch => ({
  editShippingAddressDetails: (companyName, street, postalCode, city, elevator, floor, countryCode) =>
    dispatch(editShippingAddress(companyName, street, postalCode, city, elevator, floor, countryCode)),
  editBillingAddressDetails: (companyName, street, postalCode, city, countryCode) =>
    dispatch(editBillingAddress(companyName, street, postalCode, city, countryCode))
});

export { ShippingAddress as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShippingAddress)
);
