import IntensiveCleaning from './IntensiveCleaning';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Banner from './Banner';
import FormWrapper from './FormWrapper';
import Stepbar from './Stepbar';

export default IntensiveCleaning;
export { Step1, Step2, Step3, Banner, FormWrapper, Stepbar };
