import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DocumentTitle } from 'common';
import { okCustomer } from 'actions/customer';
import { Modal } from 'components/HOC';

import './CustomerInformation.less';

export class CustomerInformation extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit();
    this.props.confirmModal();
    this.props.closeModal();
  }

  handleClose() {
    this.props.denyModal();
    this.props.closeModal();
  }

  render() {
    const { customer, billingAddress, shippingAddress, t } = this.props;

    return (
      <DocumentTitle title={t('title')}>
        <div className="cmp-info">
          <h2>{t('title')}</h2>

          <div className="cmp-info__company">
            <div>
              {t('company')}: <span>{customer.corporate_name}</span>
            </div>
            <div>
              {t('number-of-employees')}: <span>{customer.number_of_employees}</span>
            </div>
            <div>
              {t('floor-space')}: <span>{customer.floor_space}</span>
            </div>
          </div>

          <h3>{t('title-billing-address')}</h3>

          <div className="cmp-info__billing">
            {billingAddress.street},&nbsp;
            {billingAddress.postal_code} {billingAddress.city},&nbsp;
            {billingAddress.state}
          </div>

          <h3>{t('title-shipping-address')}</h3>

          <div className="cmp-info__shipping">
            {shippingAddress.street},&nbsp;
            {shippingAddress.postal_code} {shippingAddress.city},&nbsp;
            {shippingAddress.state}
          </div>

          <form onSubmit={this.handleSubmit} className="cmp-info__form">
            <button type="submit" className="cmp-info__btn-yes">
              {t('yes')}
            </button>

            <Link to="/settings#open" className="button cmp-info__btn-no" onClick={this.handleClose}>
              {t('no')}
            </Link>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

CustomerInformation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  denyModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  billingAddress: PropTypes.object.isRequired,
  shippingAddress: PropTypes.object.isRequired
};

const noop = {};
const mapStateToProps = ({ current: { customer } }) => ({
  customer: customer || noop,
  billingAddress: (customer && customer.billing_address) || noop,
  shippingAddress: (customer && customer.shipping_address) || noop
});

const mapDispatchToProps = {
  onSubmit: okCustomer
};

export { CustomerInformation as PureComponent };
export default withTranslation('Modal/CustomerInformation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Customer Information Modal', { className: 'rating-modal modal-flex' })(CustomerInformation))
);
