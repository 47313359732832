import {
  SERVICE_EVENT_DEPOSIT_REQUEST,
  SERVICE_EVENT_DEPOSIT_SUCCESS,
  SERVICE_EVENT_DEPOSIT_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_DEPOSIT_REQUEST: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isNoting: true,
          isNoted: false
        }
      };
    }

    case SERVICE_EVENT_DEPOSIT_SUCCESS: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isNoting: false,
          isNoted: true
        }
      };
    }

    case SERVICE_EVENT_DEPOSIT_FAILURE: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isNoting: false
        }
      };
    }

    default:
      return state;
  }
};
