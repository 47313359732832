import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { currency } from 'components/HOC';
import AmountDetails from '../AmountDetails';

export class Invoice extends Component {
  renderAmountDetails = (t, c, amount, total, paid) => (
    <div className="amount-details">
      <div className="amount-details-grand-total">
        <span className="title">{t('grand-total')}</span>
        <span className="value">{c(total)}</span>
      </div>
      <div className="amount-details-paid">
        <span className="title">{t('prepayments')}</span>
        <span className="value">{c(paid)}</span>
      </div>
      <div className="separator" />
      <div className="amount-details-outstanding">
        <span className="title">{t('outstanding-amount')}</span>
        <span className="value">{c(amount)}</span>
      </div>
    </div>
  );

  render() {
    const { url, number, date, due_date, status } = this.props.invoice; // eslint-disable-line camelcase
    const { isPaid, c, t } = this.props;

    const colClass = isPaid ? 'col-4' : 'col-2';
    const dueDate = !isPaid ? <span className={`due-date ${colClass}`}>{due_date.format('L')}</span> : null;
    const statusField = !isPaid ? <span className={`status ${status.toLowerCase()} ${colClass}`}>{status}</span> : null;
    return (
      <div className="invoice-entry">
        <span className={`date ${colClass}`}>{date.format('L')}</span>
        {dueDate}
        <span className={`invoice-number ${colClass}`}>
          <a rel="noopener noreferrer" target="_blank" href={url}>
            <span>{number}</span>
            <span className="download-icon" />
          </a>
        </span>
        {statusField}
        <AmountDetails invoice={this.props.invoice} t={t} c={c} isPaid={isPaid} />
      </div>
    );
  }
}

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  c: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isPaid: PropTypes.bool
};

export default pure(currency(Invoice));
