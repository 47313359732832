import { LOCATION_HASH_CUSTOMER_INFORMATION } from '../../constants/location';
import replaceLocation from './replaceLocation';

export default () => (dispatch, getState) => {
  const {
    location: { pathname, hash }
  } = getState();

  if (hash !== LOCATION_HASH_CUSTOMER_INFORMATION) {
    dispatch(replaceLocation({ pathname, hash: LOCATION_HASH_CUSTOMER_INFORMATION }));
  }
};
