import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { verifyEmail } from 'actions/auth';

import './Verify.less';

class Verify extends Component {
  componentDidMount() {
    this.props.verifyToken();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccess) {
      this.props.history.push(`/leadlogin/${nextProps.userData.token}/${nextProps.userData.masterToken}`);
    }
  }

  redirectToDashboard = e => {
    e.preventDefault();
    const { verifyToken } = this.props;

    return verifyToken();
  };

  render() {
    const { isSuccess, isFailing, t } = this.props;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="verify col-group">
          <div className="verify__logo" />
          {!isFailing && !isSuccess && <p className="verify__text">{t('verify-text-fetching')}</p>}
          {isFailing && (
            <div>
              <p className="headline">{t('verify-text-failing')}</p>
              <p className="verify__text declined">{t('verify-text-already-verified')}</p>
              <div>
                <Link className="button button-blue center" to="/dashboard">
                  {t('dashboard')}
                </Link>
              </div>
            </div>
          )}
          {isSuccess && (
            <div>
              <p className="verify__text">{t('verify-text-success')}</p>
              <div>
                <Link className="button button-blue center" to="/dashboard">
                  {t('dashboard')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </DocumentTitle>
    );
  }
}

Verify.propTypes = {
  isFailing: PropTypes.bool,
  isSuccess: PropTypes.bool,
  userData: PropTypes.object,
  t: PropTypes.func.isRequired,
  verifyToken: PropTypes.func.isRequired
};

const mapStateToProps = ({
  auth: {
    emailVerify: { isSuccess, isFailing, data }
  }
}) => ({ isSuccess, isFailing, userData: data });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { token }
    }
  }
) => ({
  verifyToken: () => dispatch(verifyEmail(token))
});

export { Verify as PureComponent };
export default withTranslation('Public/Verify')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Verify)
);
