import {
  SET_ORDER_PO_NUMBER_REQUEST,
  SET_ORDER_PO_NUMBER_SUCCESS,
  SET_ORDER_PO_NUMBER_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (poNumber, id) =>
  authCall({
    types: [SET_ORDER_PO_NUMBER_REQUEST, SET_ORDER_PO_NUMBER_SUCCESS, SET_ORDER_PO_NUMBER_FAILURE],
    endpoint: '/user/order',
    method: 'PUT',
    meta: { id },
    body: {
      sedSfid: id,
      poNumber
    }
  });
