import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import _ from 'lodash';
import TimePicker from 'rc-time-picker';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { updateCleaningObject, submitCleaningService } from 'actions/serviceEvents/index';
import RecurringCleaningLayout from './RecurringCleaningLayout';

const dateObj = [
  { time: 'time1', date: 'date1', focused: 'focused1' },
  { time: 'time2', date: 'date2', focused: 'focused2' },
  { time: 'time3', date: 'date3', focused: 'focused3' }
];

class RecurringCleaning2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date1: this.props.cleaningRequest.date1,
      date2: this.props.cleaningRequest.date2,
      date3: this.props.cleaningRequest.date3,
      time1: this.props.cleaningRequest.time1,
      time2: this.props.cleaningRequest.time2,
      time3: this.props.cleaningRequest.time3
    };
  }

  componentDidUpdate() {
    const { history, submitCleaning } = this.props;

    if (submitCleaning.isSuccessful) {
      history.push('/cleaning/cleaning-calc/step-3');
    }
  }

  onTimeChange(e, value) {
    if (e) {
      this.setState({
        [value]: e.format('HH:mm')
      });
    } else {
      this.setState({
        [value]: null
      });
    }
  }

  getDates = () => {
    const { date1, date2, date3, time1, time2, time3 } = this.state;
    const dates = [];

    if (date1 && time1) {
      const newFormat = moment(`${date1.format('YYYY-MM-DD')} ${time1}`).toISOString();
      dates.push(newFormat);
    }
    if (date2 && time2) {
      const newFormat = moment(`${date2.format('YYYY-MM-DD')} ${time2}`).toISOString();
      dates.push(newFormat);
    }
    if (date3 && time3) {
      const newFormat = moment(`${date3.format('YYYY-MM-DD')} ${time3}`).toISOString();
      dates.push(newFormat);
    }

    return dates;
  };

  handleChangeEvent = () => {
    const { cleaningRequest, updateCleaningObject } = this.props; //eslint-disable-line

    const cleaningObject = Object.assign({}, cleaningRequest, { dates: this.getDates() }, this.state);
    updateCleaningObject(cleaningObject);
    this.props.history.goBack();
  };

  handleSubmit() {
    const { cleaningRequest, submitCleaningService } = this.props; //eslint-disable-line
    const startDate = moment(cleaningRequest.calcObj.start_date).toISOString();
    const dates = this.getDates();
    if (startDate) {
      submitCleaningService({
        dates,
        ...cleaningRequest.calcObj,
        start_date: startDate
      });
    } else {
      const newRequestObj = _.omit(cleaningRequest.calcObj, 'start_date');
      submitCleaningService({
        dates,
        ...newRequestObj
      });
    }
  }

  renderForm(t) {
    return (
      <div className="cleaning-services__scheduling cleaning-services__content">
        <h1 className="cleaning-services__headline">{t('priceEvaluationVisit')}</h1>
        <form className="cleaning-services__form cleaning-services__form2">
          <div className="cleaning-services__date-group cleaning-services__boxed">
            <div className="cleaning-services__text">{t('time-description')}</div>
            <h1 className="cleaning-services__title">{t('time-headline')}</h1>
            {dateObj.map(date => {
              //eslint-disable-line
              const defaultTime =
                this.state[date.date] && this.state[date.time]
                  ? `${this.state[date.date].format('YYYY-MM-DD')} ${this.state[date.time]}`
                  : null;
              return (
                <div className="cleaning-services__single-date" key={date.date}>
                  <label className="input__label" htmlFor={date.date}>
                    {t(`option-${date.date}`)}
                  </label>
                  <div className="cleaning-services__times">
                    <SingleDatePicker
                      placeholder={t('date-placeholder')}
                      id={date.date}
                      date={this.state[date.date]}
                      onDateChange={newDate => this.setState({ [date.date]: newDate })}
                      showClearDate
                      numberOfMonths={1}
                      focused={this.state[date.focused]}
                      onFocusChange={({ focused }) =>
                        this.setState({
                          [date.focused]: focused
                        })
                      }
                      hideKeyboardShortcutsPanel
                      openDirection="up"
                    />
                    <TimePicker
                      id={date.time}
                      showSecond={false}
                      placeholder={t('time-placeholder')}
                      className={`cleaning-services__timepick ${date.time}`}
                      onChange={e => this.onTimeChange(e, date.time)}
                      defaultValue={defaultTime ? moment(defaultTime) : null}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </form>
        <div className="cleaning-services__buttongroup">
          <button
            type="button"
            className="cleaning-services__button"
            onClick={e => this.handleChangeEvent(e)}
            to="/cleaning/cleaning-calc/step-1"
          >
            {t('back')}
          </button>
          <button type="submit" className="cleaning-services__submit" onClick={e => this.handleSubmit(e)}>
            {t('button')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { t, cleaningCalc } = this.props;
    const estimatedPrice = cleaningCalc.min_price
      ? `€${cleaningCalc.min_price} - €${cleaningCalc.max_price}`
      : `${t('output-placeholder')}`;
    return (
      <RecurringCleaningLayout activeStep="step2" t={t} estimatedPrice={estimatedPrice} form={this.renderForm(t)} />
    );
  }
}

RecurringCleaning2.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { cleaningRequest, submitCleaning, cleaningCalc } }) => ({
  cleaningRequest,
  submitCleaning,
  cleaningCalc
});

const mapDispatchToProps = { updateCleaningObject, submitCleaningService };

export default withTranslation('Services/Cleaning')(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RecurringCleaning2)
  )
);
