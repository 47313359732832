import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';
import { setPassword } from 'actions/users';
import { notifyError } from 'actions/notifications';

import './SetPassword.less';

const initialState = {
  password: '',
  verifyPassword: ''
};

export class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isSet && nextProps.isSet) {
      this.setState(initialState);
      this.props.closeModal();
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit, onError, t } = this.props;
    const { password, verifyPassword } = this.state;

    if (password !== verifyPassword) {
      onError(t('passwords-not-matching'));
      return;
    }

    onSubmit(password);
  }

  render() {
    const { isSetting, t } = this.props;
    const { password, verifyPassword } = this.state;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="modal-password">
          <div className="modal-password-inside">
            <div className="col-12">
              <div className="headline">{t('headline')}</div>
            </div>

            <p className="modal-password-text">{t('help')}</p>

            <form onSubmit={this.handleSubmit}>
              <fieldset disabled={isSetting}>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    value={password}
                    className="form-control"
                    placeholder={t('password')}
                    onChange={this.handleChange}
                    required
                    minLength="8"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    name="verifyPassword"
                    value={verifyPassword}
                    className="form-control"
                    placeholder={t('retype-password')}
                    onChange={this.handleChange}
                    required
                    minLength="8"
                  />
                </div>

                <button type="submit" className="button">
                  {t('save')}
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

SetPassword.propTypes = {
  closeModal: PropTypes.func,
  t: PropTypes.func,
  isSetting: PropTypes.bool,
  isSet: PropTypes.bool,
  onSubmit: PropTypes.func,
  onError: PropTypes.func
};

const mapStateToProps = ({
  users: {
    password: {
      set: { isSetting, isSet }
    }
  }
}) => ({ isSetting, isSet });

const mapDispatchToProps = {
  onSubmit: setPassword,
  onError: notifyError
};

export { SetPassword as PureComponent };
export default withTranslation('Settings/SetPassword')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Set Password', { className: 'password-modal' })(SetPassword))
);
