import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chat, Progress, ProgressLead, Today } from '.';

import './Header.less';

export const Header = ({ startDate, endDate, isConverted }) => (
  <div className="dashboard-modul">
    <div className="modul-header">
      <Today startDate={startDate} endDate={endDate} />
      <Chat />
      {isConverted ? <Progress /> : <ProgressLead />}
    </div>
  </div>
);

Header.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired
};

const mapStateToProps = ({ current: { customer } }) => ({
  isConverted: customer ? customer.is_converted : 0
});

export default connect(mapStateToProps)(Header);
