import {
  EMAIL_NOTIFICATIONS_EDIT_REQUEST,
  EMAIL_NOTIFICATIONS_EDIT_SUCCESS,
  EMAIL_NOTIFICATIONS_EDIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default ({ emailOptOut, industryInsight, specialOffers, updatesAnnouncements }) =>
  authCall({
    types: [EMAIL_NOTIFICATIONS_EDIT_REQUEST, EMAIL_NOTIFICATIONS_EDIT_SUCCESS, EMAIL_NOTIFICATIONS_EDIT_FAILURE],
    endpoint: '/user/users/current/preferences',
    method: 'PUT',
    body: {
      email_optout: emailOptOut,
      industry_insight: industryInsight,
      special_offers: specialOffers,
      updates_announcements: updatesAnnouncements
    }
  });
