import moment from 'moment';
import 'moment/locale/de';
import numeral from 'numeral';
import { I18N_LANGUAGE_SET } from 'constants/actionTypes';
import { I18N_DEFAULT_LANGUAGE, I18N_ALLOWED_LANGUAGES } from 'constants/i18n';
import i18next from '../../i18next';

export default (language = I18N_DEFAULT_LANGUAGE) => (dispatch, getState) => {
  const { i18n } = getState();

  if (!I18N_ALLOWED_LANGUAGES.includes(language)) {
    language = I18N_DEFAULT_LANGUAGE; // eslint-disable-line no-param-reassign
  }

  if (i18n.language === language) return;

  i18next.changeLanguage(language);
  moment.locale(language);
  numeral.locale('de');

  dispatch({
    type: I18N_LANGUAGE_SET,
    language
  });
};
