import { fetchCart } from 'actions/shop/cart';
import { fetchPartners } from 'actions/shop/partners';
import { fetchCategories } from 'actions/shop/categories';
import memoize from '../memoize';

const getCustomerId = state => state.current.customer && state.current.customer.id;

export default dispatch =>
  memoize([getCustomerId], customerId => {
    if (!customerId) return;

    dispatch(fetchCart());
    dispatch(fetchPartners());
    dispatch(fetchCategories());
  });
