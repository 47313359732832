import { INVOICES_DUE_REQUEST, INVOICES_DUE_SUCCESS, INVOICES_DUE_FAILURE } from 'constants/actionTypes';
import { invoice } from '../../models';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    items: null
  },
  action
) => {
  switch (action.type) {
    case INVOICES_DUE_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case INVOICES_DUE_SUCCESS:
      return { ...state, isFetching: false, fetchedAt: new Date(), items: action.payload.map(invoice) };

    case INVOICES_DUE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
