import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchOffer } from 'actions/offerConfirmation';

import './SuccessPage.less';

class SuccessPage extends Component {
  constructor(props) {
    super(props);

    this.props.fetchData();
  }

  render() {
    const { t, offer, token } = this.props;

    if (!offer[token] || !offer[token].item) {
      return (
        <div className="offer-container col-group content-area">
          <div className="offer-details loading">
            <p className="offer-success__description">{t('fetch-offer')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="offer-container col-group content-area">
        <div className="offer-details">
          <div className="offer-success">
            {offer[token].item.status === 'Closed Lost' ? (
              <p className="offer-success__description">{t('unsuccessful')}</p>
            ) : (
              <div>
                <h1 className="offer-success__headline">{t('success-headline')}</h1>
                <p className="offer-success__description">{t('success-description')}</p>
                <p className="offer-success__description">{t('success-description2')}</p>
              </div>
            )}

            <Link to="/dashboard" className="button button-blue">
              {t('success-button')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

SuccessPage.propTypes = {
  fetchData: PropTypes.func.isRequired,
  offer: PropTypes.object,
  t: PropTypes.func.isRequired
};
const mapStateToProps = ({ offerConfirmation: { offer } }) => ({ offer });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { token }
    }
  }
) => ({
  fetchData: () => dispatch(fetchOffer(token)),
  token
});

export default withTranslation('OfferConfirmation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SuccessPage)
);
