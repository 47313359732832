import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { propCreator, Modal } from 'components/HOC';
import { createGetEntries } from 'selectors/contentful';
import { CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT } from 'constants/contentful';
import { markFeaturesAsSeen } from 'actions/features';
import { Feature } from '.';

import './FeatureHighlights.less';

export class FeatureHighlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0
    };

    this.advanceCurrentIndex = this.advanceCurrentIndex.bind(this);
    this.setCurrentIndex = this.setCurrentIndex.bind(this);
  }

  componentWillUnmount() {
    const { items, onSubmit } = this.props;
    const ids = items.map(item => item.sys.id);

    onSubmit(ids);
  }

  setCurrentIndex(currentIndex) {
    if (currentIndex !== this.state.currentIndex) {
      this.setState({ currentIndex });
    }
  }

  advanceCurrentIndex() {
    const { currentIndex } = this.state;

    if (currentIndex + 1 < this.props.items.length) {
      this.setState({
        currentIndex: currentIndex + 1
      });
    }
  }

  render() {
    const { items, closeModal, t } = this.props;
    const { currentIndex } = this.state;
    const currentItem = items && items[currentIndex];
    const isLastItem = items && currentIndex === items.length - 1;

    return (
      <DocumentTitle title={t('title')}>
        <div className="modal-flex">
          {currentItem && <Feature item={currentItem} />}

          <div className="item-selectors">
            {items &&
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => this.setCurrentIndex(i)}
                  key={item.sys.id}
                  className={`item-selector ${i === currentIndex ? 'active' : ''}`}
                />
              ))}
          </div>

          <button type="button" className="next-button" onClick={isLastItem ? closeModal : this.advanceCurrentIndex}>
            {isLastItem ? t('done') : t('next')}
          </button>
        </div>
      </DocumentTitle>
    );
  }
}

FeatureHighlights.propTypes = {
  isLoading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  items: PropTypes.array,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func
};

const createProps = {
  dataKey: CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT
};

const mapStateToProps = () => {
  const getEntries = createGetEntries();

  return (state, props) => {
    const {
      contentful: { [props.dataKey]: { isFetching } = {} }
    } = state;

    return {
      isLoading: isFetching,
      items: getEntries(state, props)
    };
  };
};

const mapDispatchToProps = {
  onSubmit: markFeaturesAsSeen
};

export { FeatureHighlights as PureComponent };
export default withTranslation('Modal/FeatureHighlights')(
  propCreator(createProps)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Modal('Feature Highlights', { className: 'feature-highlights' })(FeatureHighlights))
  )
);
