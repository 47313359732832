import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { injectStripe, CardElement } from 'react-stripe-elements';

// import { Switch } from 'common/Form';
import { createCreditCard } from 'actions/shop/payment';

import './CreditCard.less';

const initialState = {
  save: true
};

export class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isCreated && nextProps.isCreated) this.setState(initialState);
  }

  // handleChangeSave = ({ target: { checked } }) => {
  //   if (checked === this.state.save) return;

  //   this.setState({ save: checked });
  // };

  handleSubmit = ev => {
    ev.preventDefault();

    if (this.props.stripe) {
      this.props.stripe.createToken().then(payload => {
        this.props.onSubmit(payload);
      });
    } else {
      // console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const { t } = this.props;

    return (
      <form className="creditcard">
        <div className="creditcard__row">
          <CardElement onChange={this.handleChange} />
        </div>

        <div className="creditcard__row">
          {/* <Switch name="save" checked={save} onChange={this.handleChangeSave} label={t('cc-save')} /> */}

          <button type="submit" className="cc-send button relative" onClick={this.handleSubmit}>
            {t('cc-add')}
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  onSubmit: createCreditCard
};

const mapStateToProps = ({
  shop: {
    payment: {
      creditCards: {
        create: { isCreating, isCreated }
      }
    }
  }
}) => ({
  isCreating,
  isCreated
});

export default injectStripe(
  withTranslation('Shop/Cart/Payment')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CreditCard)
  )
);
