import { toast } from 'react-toastify';
import i18next from 'src/i18next';
import {
  MEMBERS_FETCH,
  MEMBERS_FETCH_SUCCESS,
  MEMBERS_FETCH_FAILURE,
  MEMBERS_CREATE,
  MEMBERS_CREATE_SUCCESS,
  MEMBERS_CREATE_FAILURE,
  MEMBERS_DELETE,
  MEMBERS_DELETE_SUCCESS,
  MEMBERS_DELETE_FAILURE,
  MEMBERS_EDIT,
  MEMBERS_EDIT_SUCCESS,
  MEMBERS_EDIT_FAILURE,
  MEMBERS_DEACTIVATE,
  MEMBERS_DEACTIVATE_SUCCESS,
  MEMBERS_DEACTIVATE_FAILURE,
  MEMBERS_ACTIVATE,
  MEMBERS_ACTIVATE_SUCCESS,
  MEMBERS_ACTIVATE_FAILURE
} from 'constants/actionTypes';
import { updateObjectInArray } from '../helper';

const t = i18next.getFixedT(null, 'Members');

export default (state = {}, action) => {
  switch (action.type) {
    case MEMBERS_FETCH:
      return { ...state, isFetching: true, fetchedAt: null };

    case MEMBERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload
      };

    case MEMBERS_FETCH_FAILURE:
      return { ...state, isFetching: false };

    case MEMBERS_CREATE:
      return { ...state, isFetching: true };

    case MEMBERS_CREATE_SUCCESS:
      toast.success(t('create-success'));
      return {
        ...state,
        isFetching: false,
        items: [...state.items, action.payload]
      };

    case MEMBERS_CREATE_FAILURE:
      toast.error(t('create-error'));
      return { ...state, isFetching: false };

    case MEMBERS_EDIT:
      return { ...state, isFetching: true };

    case MEMBERS_EDIT_SUCCESS:
      toast.success(t('update-success'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case MEMBERS_EDIT_FAILURE:
      toast.error(t('update-error'));
      return { ...state, isFetching: false };

    case MEMBERS_DELETE:
      return { ...state, isFetching: true };

    case MEMBERS_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: state.items.filter(item => item.id !== action.payload.id)
      };

    case MEMBERS_DELETE_FAILURE:
      return { ...state, isFetching: false };

    case MEMBERS_DEACTIVATE:
    case MEMBERS_ACTIVATE:
      return { ...state, isFetching: true };

    case MEMBERS_DEACTIVATE_SUCCESS:
      toast.success(t('deactivate-success'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case MEMBERS_ACTIVATE_SUCCESS:
      toast.success(t('activate-success'));
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      };

    case MEMBERS_DEACTIVATE_FAILURE:
    case MEMBERS_ACTIVATE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
