import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, Select } from 'common/Form';
import { submitAdditionalCleaning } from 'actions/serviceEvents';
import { checkboxObj, hoursObj } from '../../Config';

import './AdditionalCleaning.less';

class AdditionalCleaning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      reqDate: null,
      time: null,
      reqTime: null,
      hours: 1,
      tasks: [],
      taskMisc: '',
      area: 'total',
      areaPartial: '',
      isMisc: false,
      isPartial: false,
      errorTime: false,
      errorFields: false
    };

    this.areaPartialObj = [{ name: props.t('total'), value: 'total' }, { name: props.t('partial'), value: 'partial' }];
  }

  componentDidUpdate() {
    const { history, additionalCleaning } = this.props;

    if (additionalCleaning.isSuccessful) {
      history.push('/cleaning/cleaning-calc/step-3');
    }
  }

  createArray(val) {
    const { tasks } = this.state;
    let newArray = [];

    if (tasks.includes(val)) {
      newArray = tasks.filter(task => task !== val);
    } else {
      newArray = [...tasks, val];
    }

    if (val === 'misc') {
      this.setState(prevState => ({
        isMisc: !prevState.isMisc
      }));
    }

    this.setState({ tasks: [...newArray] });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { tasks, hours, areaPartial, taskMisc, area, reqDate, reqTime } = this.state;
    const dateTime = moment(`${reqDate} ${reqTime}`).toISOString();
    const request = {
      date_time: dateTime,
      man_hours: hours,
      tasks,
      task_misc: taskMisc,
      area,
      area_partial: areaPartial
    };

    if (this.validate()) {
      this.props.submitAdditionalCleaning(request);
    }
  }

  validate() {
    const { tasks, date, time } = this.state;
    let isValid = false;

    if (tasks.length < 1) {
      this.setState({ errorFields: true });
      isValid = false;
    } else {
      this.setState({ errorFields: false });
      isValid = true;
    }

    if (date && time) {
      this.setState({ errorTime: false });
      isValid = true;
    } else {
      this.setState({ errorTime: true });
      isValid = false;
    }

    return isValid;
  }

  render() {
    const { t } = this.props;
    const { isMisc, isPartial, errorTime, errorFields } = this.state;

    return (
      <div className="pad cleaning-services additional">
        <div className="cleaning-services__banner">
          <div className="cleaning-services__icon" />
          <div className="cleaning-services__description">
            <h2>{t('title')}</h2>
            <p>{t('description')}</p>
          </div>
        </div>
        <div className="cleaning-services__content">
          <form className="cleaning-services__form">
            <label className="cleaning-services__label" htmlFor="datetime">
              {t('date-label')}
            </label>
            <div>
              {errorTime && <span className="error">{t('error-date')}</span>}
              <fieldset id="datetime">
                <div className="cleaning-services__times">
                  <SingleDatePicker
                    placeholder={t('date-placeholder')}
                    id="date"
                    date={this.state.date}
                    onDateChange={newDate =>
                      this.setState({
                        date: newDate,
                        reqDate: moment(newDate).format('YYYY-MM-DD')
                      })
                    }
                    showClearDate
                    numberOfMonths={1}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                  />
                  <TimePicker
                    id="time"
                    showSecond={false}
                    placeholder={t('time-placeholder')}
                    className="cleaning-services__timepick"
                    onChange={e => this.setState({ time: e, reqTime: e.format('HH:mm') })}
                  />
                </div>
              </fieldset>
            </div>
            <Select
              id="test"
              className="test"
              parentClass="test"
              label={t('cleaningHours')}
              onChange={input => this.setState({ hours: input.target.value })}
              options={hoursObj}
            />
            <label className="cleaning-services__label" htmlFor="add-checkbox">
              {t('cleaningOption')}
            </label>
            {errorFields && <span className="error">{t('error-choose')}</span>}
            <fieldset id="add-checkbox">
              <div className="cleaning-services__checkboxes">
                {checkboxObj.map(val => (
                  <Checkbox
                    key={val}
                    alignCheckboxLeft
                    className="cleaning-services__checkbox"
                    label={t(val)}
                    id={`partner-${val}`}
                    onChange={() => this.createArray(val)}
                  />
                ))}
              </div>
            </fieldset>
            {isMisc && (
              <div>
                <label className="cleaning-services__label" htmlFor="task-misc">
                  {t('please-describe')}
                </label>
                <div className="cleaning-services__input-con">
                  <input
                    id="task-misc"
                    value={this.state.taskMisc}
                    onChange={e => this.setState({ taskMisc: e.target.value })}
                    type="text"
                  />
                </div>
              </div>
            )}
            <Select
              id="test2"
              className="test"
              parentClass="test"
              label={t('is-partial')}
              onChange={e => {
                e.persist();
                this.setState(prevState => {
                  return {
                    area: e.target.value,
                    isPartial: !prevState.isPartial
                  };
                });
              }}
              options={this.areaPartialObj}
            />
            {isPartial && (
              <div>
                <label className="cleaning-services__label" htmlFor="work-desc">
                  {t('which-part')}
                </label>
                <div className="cleaning-services__input-con">
                  <input
                    id="work-disc"
                    value={this.state.areaPartial}
                    onChange={e => this.setState({ areaPartial: e.target.value })}
                    type="text"
                  />
                </div>
              </div>
            )}
            <div className="cleaning-services__buttongroup">
              <Link className="cleaning-services__button" to="/cleaning/cleaning-menu">
                {t('cancel')}
              </Link>
              <button type="submit" className="cleaning-services__submit" onClick={e => this.handleSubmit(e)}>
                {t('submit')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AdditionalCleaning.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { additionalCleaning } }) => ({
  additionalCleaning
});

export default withTranslation('Services/AdditionalCleaning')(
  withRouter(
    connect(
      mapStateToProps,
      { submitAdditionalCleaning }
    )(AdditionalCleaning)
  )
);
