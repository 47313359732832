import React, { PureComponent } from 'react';

const defaultMediaQueries = {
  xx: 'only screen and (min-width: 1800px)',
  xl: 'only screen and (min-width: 1500px)',
  lg: 'only screen and (min-width: 1200px)',
  md: 'only screen and (min-width: 992px)',
  sm: 'only screen and (min-width: 768px)',
  xs: 'only screen and (min-width: 479px)',
  ss: 'only screen and (max-width: 478px)'
};

export default (queries = defaultMediaQueries) => WrappedComponent => {
  class Responsive extends PureComponent {

    constructor(props) {
      super(props);

      this.updateMatches = this.updateMatches.bind(this);
      this.mqMap = new Map();
      this.state = {};

      Object.keys(queries).forEach(name => {
        const mq = matchMedia(queries[name]);
        mq.addListener(this.updateMatches);

        this.mqMap.set(name, mq);
        this.state[name] = mq.matches;
      });
    }

    componentWillUnmount() {
      this.mqMap.forEach(mq => mq.removeListener(this.updateMatches));
    }

    updateMatches() {
      const state = {};

      this.mqMap.forEach((mq, name) => {
        if (this.state[name] !== mq.matches) {
          state[name] = mq.matches;
        }
      });

      this.setState(state);
    }

    render() {
      const mergedProps = { ...this.props, mediaQuery: this.state };
      return <WrappedComponent {...mergedProps} />;
    }
  }

  return Responsive;
};
