/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { Select, RadioButton, InputGroup, InputGroupAddon } from 'common/Form';
import { fetchCleaningServiceConfig, calcCleaningService, updateCleaningObject } from 'actions/serviceEvents/index';
import { notifyError } from 'actions/notifications';
import { PremiumTasklistLink } from 'components/Modal';
import FormObjectArray, { mapedObject } from '../../Config';
import RecurringCleaningLayout from './RecurringCleaningLayout';

import './RecurringCleaning.less';

class RecurringCleaning extends Component {
  state = (() => {
    const { cleaningRequest } = this.props;
    const { calcObj, hardfloor_choice } = cleaningRequest;
    return {
      toilet: calcObj.toilet_size,
      week: calcObj.number_of_cleanings,
      hours: calcObj.weekend_choice,
      woodenFloor: hardfloor_choice,
      kitchen: calcObj.kitchen_size,
      nrEmployees: calcObj.number_of_employees,
      premium: calcObj.premium_choice,
      floor: calcObj.office_size,
      startDate: calcObj.start_date ? moment(calcObj.start_date) : null,
      weeksFocused: false
    };
  })();

  timer = null;

  componentDidMount() {
    this.props.fetchCleaningServiceConfig();
  }

  componentDidUpdate() {
    const { office_size: officeSize, number_of_employees: numberOfEmployees } = this.props.cleaningRequest.calcObj;

    if (this.props.cleaningRequest.checked) {
      this.props.updateCleaningObject({ checked: false });

      if (officeSize !== '' && numberOfEmployees !== '') {
        if (this.timer) {
          // eslint-disable-line
          clearTimeout(this.timer); // eslint-disable-line
        }
        this.timer = setTimeout(() => {
          this.props.calcCleaningService({ ...this.props.cleaningRequest.calcObj });
        }, 500);
      }
    }
  }

  setFocusedState(input) {
    if (input.target.dataset.label === 'week') {
      this.setState({
        weeksFocused: true
      });
    }
  }

  getOptions = name => {
    const { t } = this.props;
    const options = {
      woodenFloor: [
        { name: t('floor-type-carpet-bright'), value: 'carpet_bright' },
        { name: t('floor-type-carpet-dark'), value: 'carpet_dark' },
        { name: t('floor-type-laminate'), value: 'laminate' },
        { name: t('floor-type-parquet'), value: 'parquet' },
        { name: t('floor-type-linoleum'), value: 'linoleum' },
        { name: t('floor-type-screed'), value: 'screed' },
        { name: t('floor-type-stone'), value: 'stone' }
      ],
      kitchen: [
        { value: 0, name: '0 m²' },
        { value: 5, name: '0-5 m²' },
        { value: 10, name: '5-10 m²' },
        { value: 15, name: '10-15 m²' },
        { value: 20, name: '15-20 m²' },
        { value: 25, name: '20-25 m²' },
        { value: 30, name: '25-30 m²' },
        { value: 35, name: '30-35 m²' },
        { value: 40, name: '35-40 m²' },
        { value: 45, name: '40-45 m²' },
        { value: 50, name: '45-50 m²' },
        { value: 1000, name: '> 50 m²' }
      ],
      toilet: [
        { value: 0, name: '0 m²' },
        { value: 5, name: '0-5 m²' },
        { value: 10, name: '5-10 m²' },
        { value: 15, name: '10-15 m²' },
        { value: 20, name: '15-20 m²' },
        { value: 25, name: '20-25 m²' },
        { value: 30, name: '25-30 m²' },
        { value: 35, name: '30-35 m²' },
        { value: 40, name: '35-40 m²' },
        { value: 45, name: '40-45 m²' },
        { value: 50, name: '45-50 m²' },
        { value: 1000, name: '> 50 m²' }
      ],
      week: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 6, value: 6 },
        { name: 7, value: 7 }
      ]
    };

    return options[name] ? options[name] : [];
  };

  validateStep1 = (floor, employees) => {
    if (/^\d+$/.test(floor) === false || /^\d+$/.test(employees) === false) {
      // show notification
      this.props.notifyError(this.props.t('error-not-integer'));
      return false;
    }

    if (parseInt(floor, 10) >= 100000 || parseInt(employees, 10) >= 100000) {
      // show notification
      this.props.notifyError(this.props.t('error-size-exceeded'));
      return false;
    }

    return true;
  };

  handleNextButtonClick = e => {
    e.preventDefault();
    const { calcObj } = this.props.cleaningRequest;
    if (!calcObj.start_date) {
      return false;
    }

    return this.props.history.push('/cleaning/cleaning-calc/step-2');
  };

  handleChangeEvent = e => {
    const obj = _.cloneDeep(this.props.cleaningRequest.calcObj);
    const { label } = e.target.dataset;
    const { value } = e.target;
    const floorValue = label === 'floor' ? value : obj.office_size;
    const employeesValue = label === 'nrEmployees' ? value : obj.number_of_employees;
    const validationResult = this.validateStep1(floorValue, employeesValue);

    if (label === 'startDate') {
      obj[mapedObject[label]] = moment(value).toISOString();

      this.setState({
        startDate: moment(value)
      });
    } else {
      obj[mapedObject[label]] = value;
    }

    if (!this.state.weeksFocused) {
      if (employeesValue < 51) {
        obj.number_of_cleanings = 2;
      } else if (employeesValue < 101) {
        obj.number_of_cleanings = 3;
      } else {
        obj.number_of_cleanings = 4;
      }

      this.setState({
        week: obj.number_of_cleanings
      });
    }

    if (!this.state.weeksFocused && floorValue > 500 && floorValue < 1001) {
      obj.number_of_cleanings++; // eslint-disable-line

      this.setState({
        week: obj.number_of_cleanings
      });
    }

    this.props.updateCleaningObject({ checked: validationResult, ...{ calcObj: obj } });

    this.setState({
      [e.target.dataset.label]: e.target.value
    });
  };

  renderForm(t) {
    return (
      <div className="cleaning-services__content">
        <div className="cleaning-services__left">
          <form className="cleaning-services__form">
            {FormObjectArray.map(obj => {
              if (obj.type === 'input') {
                return (
                  <div key={obj.id}>
                    <label htmlFor={obj.id} className="cleaning-services__label">
                      {t(obj.label)}
                    </label>
                    <InputGroup>
                      <input
                        type="number"
                        min="1"
                        defaultValue={this.state[obj.label]}
                        data-label={obj.label}
                        key={obj.label}
                        id={obj.id}
                        className={obj.className}
                        required={obj.required}
                        onChange={this.handleChangeEvent}
                      />
                      <InputGroupAddon>{t(obj.addon)}</InputGroupAddon>
                    </InputGroup>
                  </div>
                );
              }
              if (obj.type === 'radio') {
                return (
                  <div key={obj.id}>
                    <p className="input__label">
                      {t(obj.label)}
                      {obj.label === 'hours' ? null : <PremiumTasklistLink />}
                    </p>
                    <div className="cleaning-radio-group">
                      <RadioButton
                        name={obj.name}
                        label={t('yes')}
                        data-label={obj.label}
                        onClick={this.handleChangeEvent}
                        defaultChecked={!!this.state[obj.label] === true}
                        value="true"
                      />
                      <RadioButton
                        name={obj.name}
                        label={t('no')}
                        data-label={obj.label}
                        onClick={this.handleChangeEvent}
                        defaultChecked={!!this.state[obj.label] === false}
                        value="false"
                      />
                    </div>
                  </div>
                );
              }

              return (
                <Select
                  value={this.state[obj.label]}
                  onChange={this.handleChangeEvent}
                  key={obj.label}
                  data-label={obj.label}
                  id={obj.id}
                  className={obj.className}
                  parentClass={obj.parentClass}
                  required={obj.required}
                  label={t(obj.label)}
                  onFocus={input => this.setFocusedState(input)}
                  options={this.getOptions(obj.label)}
                />
              );
            })}

            <label className="select__label" htmlFor="date">
              {t('startDate')}
            </label>
            <div className="cleaning-services__times">
              <SingleDatePicker
                placeholder={t('date-placeholder')}
                id="date"
                date={this.state.startDate}
                onDateChange={newDate => {
                  this.handleChangeEvent({
                    target: {
                      value: newDate,
                      dataset: { label: 'startDate' }
                    }
                  });
                }}
                openDirection="up"
                showClearDate
                numberOfMonths={1}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                hideKeyboardShortcutsPanel
              />
            </div>
            <div className="cleaning-services__buttongroup">
              <button
                type="button"
                className={`cleaning-services__next-step
                                    ${!this.props.cleaningRequest.calcObj.start_date ? 'deactivated' : ''}`}
                onClick={e => this.handleNextButtonClick(e)}
              >
                {t('next')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { t, cleaningCalc } = this.props;

    const sum = () => {
      if (
        this.state.kitchen > 999 ||
        this.state.floor > 1000 ||
        this.state.toilet > 999 ||
        this.state.nrEmployees === '' ||
        this.state.nrEmployees === null ||
        this.state.floor === '' ||
        this.state.floor === null
      ) {
        return t('output-wrong');
      }

      return cleaningCalc.min_price
        ? `€${cleaningCalc.min_price} - €${cleaningCalc.max_price}`
        : `${t('output-placeholder')}`;
    };

    return <RecurringCleaningLayout form={this.renderForm(t)} t={t} activeStep="step1" estimatedPrice={sum()} />;
  }
}

RecurringCleaning.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { cleaningConfig, cleaningCalc, cleaningRequest } }) => ({
  cleaningConfig,
  cleaningCalc,
  cleaningRequest
});

export default withTranslation('Services/Cleaning')(
  withRouter(
    connect(
      mapStateToProps,
      {
        fetchCleaningServiceConfig,
        calcCleaningService,
        updateCleaningObject,
        notifyError
      }
    )(RecurringCleaning)
  )
);
