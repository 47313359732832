import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';

import './Service.less';

export const Service = ({ t, type, jwtToken, userId, customerId }) => {
  const baseUrl =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage'
      ? t(`service-${type}-url`)
      : process.env.REACT_APP_TYPEFORM_TEST_URL;
  const formUrl = `${baseUrl}?token=${jwtToken}&userid=${userId}&customerid=${customerId}`;

  return (
    <DocumentTitle title={t(`service-${type}-title`)}>
      <div className="typeform-modal-inner-wrapper">
        <div className={`typeform-modal-header ${type}`}>
          <div className="description">
            <h2>{t(`service-${type}-title`)}</h2>
            <p>{t(`service-${type}-description`)}</p>
          </div>
        </div>

        <iframe id="typeform-iframe" title={t(`service-${type}-title`)} width="100%" height="100%" src={formUrl} />

        <script type="text/javascript" src="https://s3-eu-west-1.amazonaws.com/share.typeform.com/embed.js" />
      </div>
    </DocumentTitle>
  );
};

Service.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
  userId: PropTypes.string,
  customerId: PropTypes.string
};

const mapStateToProps = ({
  auth: {
    data: { jwtToken }
  },
  current: { user, customer }
}) => ({
  jwtToken,
  userId: user && user.id,
  customerId: customer && customer.id
});

export default withTranslation('Services')(
  connect(mapStateToProps)(Modal('Service', { className: 'modal-flex typeform-modal' })(Service))
);
