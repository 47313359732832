import {
  EMAIL_NOTIFICATIONS_EDIT_REQUEST,
  EMAIL_NOTIFICATIONS_EDIT_SUCCESS,
  EMAIL_NOTIFICATIONS_EDIT_FAILURE,
  EMAIL_NOTIFICATIONS_FETCH_SUCCESS
} from 'constants/actionTypes';

export default (
  state = {
    isChanging: false,
    isChanged: false,
    emailPreferences: null
  },
  action
) => {
  switch (action.type) {
    case EMAIL_NOTIFICATIONS_EDIT_REQUEST:
      return { ...state, isSubmitting: true, isChanged: false };

    case EMAIL_NOTIFICATIONS_EDIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isChanged: true,
        emailPreferences: action.payload.preferences,
        success: action.payload.success
      };

    case EMAIL_NOTIFICATIONS_EDIT_FAILURE:
      return { ...state, isSubmitting: false, isChanged: false };

    case EMAIL_NOTIFICATIONS_FETCH_SUCCESS:
      return { ...state, emailPreferences: action.payload };
    default:
      return state;
  }
};
