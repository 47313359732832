import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { checkElementExist } from 'utils/checkElementExist';

const Chat = ({ t }) => {
  const [chatActive, setChatActive] = useState(false);

  useEffect(() => {
    checkElementExist('.helpButtonEnabled').then(() => {
      setChatActive(true);
    });
  }, []);

  const openChat = () => {
    const helpButton = document.querySelector('.helpButtonEnabled');

    if (helpButton) {
      helpButton.click();
    }
  };

  const currentMonth = moment().month();

  return (
    <div className={`header-module__column header-module__help ${currentMonth === 11 && 'december'}`}>
      <p className="header-module__headline">{t('need-help')}</p>
      {chatActive ? (
        <button type="button" className="header-module__chat" id="header-chat" onClick={openChat}>
          {t('chat-now')}
        </button>
      ) : (
        <div className="header-module__chat not" id="header-chat">
          {t('chat-not')}
        </div>
      )}
      {chatActive ? (
        <p className="header-module__text flex-bottom">{t('call-us')}</p>
      ) : (
        <p className="header-module__text flex-bottom">{t('call-instead')}</p>
      )}
    </div>
  );
};

Chat.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('Dashboard/Header')(Chat);
