import {
  AUTH_LOGIN_SUCCESS,
  CUSTOMER_CHANGE_SUCCESS,
  USER_SUCCESS,
  USER_PASSWORD_SET_SUCCESS,
  USER_PASSWORD_CHANGE_SUCCESS
} from 'constants/actionTypes';

export default (state = null, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case CUSTOMER_CHANGE_SUCCESS:
      return {
        ...action.payload.user,
        company: undefined, // Backward compatibility
        customer: undefined
      };

    case USER_SUCCESS:
      return {
        ...action.payload,
        company: undefined, // Backward compatibility
        customer: undefined
      };

    case USER_PASSWORD_SET_SUCCESS:
    case USER_PASSWORD_CHANGE_SUCCESS:
      return { ...state, should_set_password: false };

    default:
      return state;
  }
};
