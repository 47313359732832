import Raven from 'raven-js';
import {
  shouldFetchUser,
  shouldFetchCustomerDeps,
  shouldRestoreSettings,
  shouldToggleCartFromLocation,
  shouldSetCurrency,
  shouldSearchFromLocation,
  shouldClearSearch,
  shouldSetBrand,
  shouldShowModals,
  shouldFetchFeatures,
  createSetupVendors,
  shouldFetchMembers
} from './observers';

export default store => {
  const { dispatch } = store;

  const fetchUser = shouldFetchUser(dispatch);
  const fetchCustomerDeps = shouldFetchCustomerDeps(dispatch);
  const setCurrency = shouldSetCurrency(dispatch);
  const restoreSettings = shouldRestoreSettings(dispatch);
  const toggleCartFromLocation = shouldToggleCartFromLocation(dispatch);
  const searchFromLocation = shouldSearchFromLocation(dispatch);
  const clearSearch = shouldClearSearch(dispatch);
  const setBrand = shouldSetBrand(dispatch);
  const showModals = shouldShowModals(dispatch);
  const setupVendors = createSetupVendors(Raven);
  const fetchFeatures = shouldFetchFeatures(dispatch);
  const fetchMembers = shouldFetchMembers(dispatch);

  return store.subscribe(() => {
    const state = store.getState();

    setupVendors(state);
    fetchUser(state);
    fetchCustomerDeps(state);
    setCurrency(state);
    restoreSettings(state);
    toggleCartFromLocation(state);
    searchFromLocation(state);
    clearSearch(state);
    setBrand(state);
    showModals(state);
    fetchFeatures(state);
    fetchMembers(state);
  });
};
