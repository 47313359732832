import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tooltip } from 'common';
import { WithCustomer } from 'components/DI';
import { propCreator } from 'components/HOC';
import { getFilters, getIsFilterSelected } from 'selectors/serviceEvents/filters';
import { fetchFilters, resetFilters } from 'actions/serviceEvents/filters';
import { ToggleButton, ResetButton } from '.';

import './ServiceEventFilter.less';

export class ServiceEventFilter extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, dataKey, startDate, endDate } = this.props;

    dispatch(resetFilters());
    dispatch(
      fetchFilters(dataKey, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      })
    );
  }

  render() {
    const { isFetching, items, t, isSelected } = this.props;
    const tooltipId = 'service-event-filter';

    return (
      <WithCustomer onChange={this.fetchData} className="dashboard-filters dropdown-container bottom arrow-right">
        <button
          type="button"
          className={`modul__header-button filter dashboard-filters-button ${isSelected ? 'filters-selected' : ''} ${
            isFetching ? 'loading' : ''
          }`}
          data-tip={t('tooltip')}
          data-for={tooltipId}
          data-effect="solid"
          data-place="left"
        >
          {t('filters')}
        </button>

        <div className="dashboard-filters-list dropdown-list">
          <div>{items && items.types.map(item => <ToggleButton key={`filter-type-${item.value}`} item={item} />)}</div>

          <div className="dashboard-filters-toggle-con">
            {items && items.mode && <ToggleButton item={items.mode} />}

            {items && items.state && <ToggleButton item={items.state} />}
          </div>

          <ResetButton />
        </div>

        <Tooltip id={tooltipId} />
      </WithCustomer>
    );
  }
}

ServiceEventFilter.propTypes = {
  dataKey: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.object,
  t: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

const createProps = ({ startDate, endDate }) => ({
  dataKey: `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`
});

const mapStateToProps = (state, props) => {
  const {
    serviceEvents: {
      filters: {
        list: { [props.dataKey]: { isFetching } = {} }
      }
    }
  } = state;

  return {
    isFetching,
    items: getFilters(state, props),
    isSelected: getIsFilterSelected(state)
  };
};

export default withTranslation('Dashboard/ServiceEventFilter')(
  propCreator(createProps)(connect(mapStateToProps)(ServiceEventFilter))
);
