import { createSelector } from 'reselect';
import { getItemsFromPages } from '../shared';

const getPages = (state, { dataKey }) => state.shop.products.list[dataKey] && state.shop.products.list[dataKey].pages;
const getProducts = state => state.shop.products.entities;

export default () =>
  createSelector([getItemsFromPages(getPages), getProducts], (items, products) => {
    if (!items) return null;

    return items.map(productId => products[productId]).filter(product => !!product);
  });
