import {
  SHOP_CART_COMMENT_SET_REQUEST,
  SHOP_CART_COMMENT_SET_SUCCESS,
  SHOP_CART_COMMENT_SET_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default (id, comment) =>
  authCall({
    shouldFetch: state => state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT,
    types: [SHOP_CART_COMMENT_SET_REQUEST, SHOP_CART_COMMENT_SET_SUCCESS, SHOP_CART_COMMENT_SET_FAILURE],
    endpoint: `/user/shop/cart/sub_cart/${id}/comment`,
    method: 'PUT',
    body: { comment }
  });
