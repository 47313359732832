import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';

export const Placeholder = ({ icon, location, t }) => (
  <div className="sed sed-placeholder">
    <div className={`sed-icon ${icon}`}>&nbsp;</div>

    <div className="sed-texts">
      <div className="headline">{t('headline')}</div>
      <div className="subline">{t('subline')}</div>
    </div>

    <Link className="button-soft placeholder-button" to={location}>
      {t('button')}
    </Link>
  </div>
);

Placeholder.propTypes = {
  t: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default pure(Placeholder);
