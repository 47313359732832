import {
  SET_ORDER_PO_NUMBER_REQUEST,
  SET_ORDER_PO_NUMBER_SUCCESS,
  SET_ORDER_PO_NUMBER_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ORDER_PO_NUMBER_REQUEST: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isSubmitting: true, isSubmitted: false } };
    }

    case SET_ORDER_PO_NUMBER_SUCCESS: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isSubmitting: false, isSubmitted: true, data: action.payload } };
    }

    case SET_ORDER_PO_NUMBER_FAILURE: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isSubmitting: false } };
    }

    default:
      return state;
  }
};
