/* eslint-disable react/no-unused-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { fetchFeedback, sendFeedback } from 'actions/serviceEvents/feedback';
import { RadioButton } from 'common/Form';

import './Feedback.less';

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isServiceProvided: true,
      isCompleted: true,
      feedbackComment: '',
      isSubmitDisabled: false,
      isFeedbackSent: false
    };

    this.props.fetchData();
  }

  handleRadioButtonChange = (name, value) => {
    this.setState(() => ({ [name]: value }));
    if (name === 'isCompleted') {
      this.setState(() => ({ isSubmitDisabled: false }));
      if (!value) {
        this.setState(() => ({ isSubmitDisabled: true }));
      }
    }
  };

  handleCommentChange = e => {
    e.persist();
    this.setState(() => ({ feedbackComment: e.target.value }));
    this.setState(() => ({ isSubmitDisabled: false }));
  };

  handleCancelButtonClick = () => this.props.history.push('/dashboard');

  handleSubmitButtonClick = event => {
    event.preventDefault();

    const { isServiceProvided, isCompleted, feedbackComment } = this.state;

    const { id, handleSubmit } = this.props;

    handleSubmit({
      id,
      isServiceProvided,
      isCompleted,
      feedbackComment: isCompleted ? '' : feedbackComment
    });

    if (!isCompleted && feedbackComment.trim() === '') {
      this.setState(() => ({ isSubmitDisabled: true }));
      return false;
    }

    this.setState(() => ({ isFeedbackSent: true }));
    return true;
  };

  renderHasFeedbackView = () => {
    const { t, feedback, id, submitFeedback } = this.props;

    if (!feedback || !feedback[id]) return null;

    const { item } = submitFeedback && submitFeedback[id] ? submitFeedback[id] : feedback[id];

    return (
      <div>
        <p className="input__label">{t('was-service-provided')}</p>
        <strong>{item.service_provided ? t('yes') : t('no')}</strong>
        <p className="input__label">{t('did-provider-do-all-tasks')}</p>
        <strong>{item.is_completed ? t('yes') : t('no')}</strong>
        {item.partially_done_comment && (
          <div className="comment-area">
            <p className="input__label">{t('not-fulfilled-tasks')}</p>
            <strong>{item.partially_done_comment}</strong>
          </div>
        )}
        <div className="buttongroup">
          <button className="spo-input button feedback-submit" type="submit" onClick={this.handleCancelButtonClick}>
            {t('return-todashboard')}
          </button>
        </div>
      </div>
    );
  };

  renderSubmitFeedbackView = () => {
    const { t, feedback, id } = this.props;

    if (!feedback || !feedback[id]) return null;

    const { item } = feedback[id];

    return (
      <div>
        <p className="input__label">{t('was-service-provided')}</p>
        <RadioButton
          name="isServiceProvided"
          label={t('yes')}
          defaultChecked
          value={true}
          onChange={() => this.handleRadioButtonChange('isServiceProvided', true)}
        />
        <RadioButton
          name="isServiceProvided"
          label={t('no')}
          onChange={() => this.handleRadioButtonChange('isServiceProvided', false)}
        />
        <p className="input__label">{t('did-provider-do-all-tasks')}</p>
        <RadioButton
          name="isCompleted"
          label={t('yes')}
          defaultChecked
          value={true}
          onChange={() => this.handleRadioButtonChange('isCompleted', true)}
        />
        <RadioButton
          name="isCompleted"
          label={t('no')}
          onChange={() => this.handleRadioButtonChange('isCompleted', false)}
        />
        {!this.state.isCompleted && (
          <div className="comment-area">
            <p className="input__label">
              {t('not-fulfilled-tasks')}
              {this.state.isSubmitDisabled && <span className="mandatory-label">{` ${t('mandatory-field')}`}</span>}
              {this.state.feedbackComment.length === 255 && (
                <span className="mandatory-label">{` ${t('max-255')}`}</span>
              )}
            </p>
            <textarea
              name="feedbackComment"
              className="feedback-text"
              onChange={e => this.handleCommentChange(e)}
              defaultValue={item.partially_done_comment}
              required
              maxLength="255"
            />
          </div>
        )}
        <div className="buttongroup">
          <button
            className="spo-input button feedback-submit"
            type="submit"
            onClick={this.handleSubmitButtonClick}
            disabled={this.state.isSubmitDisabled}
          >
            {t('submit-feedback')}
          </button>
          <button
            type="button"
            className="spo-input button-soft feedback-cancel"
            onClick={this.handleCancelButtonClick}
          >
            {t('return-todashboard')}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { t, feedback, id, eventDate, submitFeedback } = this.props;

    if (!feedback || !feedback[id]) return null;

    const { item } = feedback[id];

    if (!item) return null;

    const isSubmitted = submitFeedback && submitFeedback[id] !== undefined && submitFeedback[id].isSubmitted;

    const showFeedbackForm = !item.has_feedback && !isSubmitted;
    const showFeedbackView = item.has_feedback || isSubmitted;

    return (
      <form className="col-group content-area feedback-form">
        <h1 className="feedback-headline">{t('headline')}</h1>
        <h3 className="feedback-title">{item.name}</h3>
        <div className="service-date">{moment(new Date(eventDate)).format('ddd, DD.MM.YYYY')}</div>
        {showFeedbackForm && this.renderSubmitFeedbackView()}
        {showFeedbackView && this.renderHasFeedbackView()}
      </form>
    );
  }
}

Feedback.propTypes = {
  fetchData: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  feedback: PropTypes.object,
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired
};

const mapStateToProps = ({
  serviceEvents: {
    feedback: { feedback, submitFeedback }
  }
}) => ({ feedback, submitFeedback });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id, eventDate }
    }
  }
) => ({
  fetchData: () => dispatch(fetchFeedback(id, true)),
  handleSubmit: (...args) => dispatch(sendFeedback(...args)),
  id,
  eventDate
});

export { Feedback as PureComponent };
export default withTranslation('Dashboard/Feedback')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Feedback)
);
