import { shouldFetch } from 'utils';
import { FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE } from 'constants/actionTypes';
import { authCall } from '../api';

export default (startDate, endDate, limit, page, shouldDoForceFetch = false) =>
  authCall({
    shouldFetch: ({ orders: { isFetching, fetchedAt } }) => shouldFetch(isFetching, fetchedAt, shouldDoForceFetch),
    types: [FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE],
    endpoint: '/user/order-history',
    method: 'GET',
    query: {
      start_date: endDate,
      end_date: startDate,
      limit,
      page
    }
  });
