import {
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
  PARTNER_SUBMIT_OFFER_REQUEST,
  PARTNER_SUBMIT_OFFER_SUCCESS,
  PARTNER_SUBMIT_OFFER_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';
import { CONTENT_TYPE, CONTENT_TYPE_JSON } from '../../constants/headers';

export const fetchOffer = token =>
  apiCall({
    types: [FETCH_PARTNER_REQUEST, FETCH_PARTNER_SUCCESS, FETCH_PARTNER_FAILURE],
    endpoint: `/offer/partner/${token}`,
    meta: { token },
    method: 'GET'
  });

export const submitOffer = offerDetails =>
  apiCall({
    types: [PARTNER_SUBMIT_OFFER_REQUEST, PARTNER_SUBMIT_OFFER_SUCCESS, PARTNER_SUBMIT_OFFER_FAILURE],
    headers: {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON
    },
    endpoint: '/offer/partner',
    method: 'POST',
    body: offerDetails
  });
