import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { LOCATION_HASH_PASSWORD_RESET } from 'constants/location';

const ResetPasswordLink = props => {
  const { pathname, title } = props;
  return (
    <Link className="password-forget" to={`${pathname}${LOCATION_HASH_PASSWORD_RESET}`}>
      {title}
    </Link>
  );
};
ResetPasswordLink.propTypes = {
  pathname: PropTypes.string,
  title: PropTypes.string
};

export { ResetPasswordLink as PureComponent };
export default withRouter(ResetPasswordLink);
