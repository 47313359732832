import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import moment from 'moment';
import gholiday from 'german-holiday';
import { depositServiceEvent } from 'actions/serviceEvents';

import './DepositBox.less';

class DepositBox extends Component {
  static businessDay(date, periode) {
    let adate = moment(date);
    let aperiode = periode;

    while (aperiode > 0) {
      adate = adate.subtract(1, 'days');
      if (adate.isoWeekday() !== 6 && adate.isoWeekday() !== 7 && !gholiday.holidayCheck(adate.toDate(), 'BE')) {
        aperiode -= 1;
      }
    }
    return adate;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { dispatch, item } = this.props;
    const deposit = this.depositMessageInput.value.trim();

    dispatch(depositServiceEvent(item.id, deposit));

    this.props.closeBox();
  }

  isInTime() {
    const { end_date: endDate } = this.props.item;
    const date = moment();
    // eslint-disable-next-line
    const eventMinusPeriode = DepositBox.businessDay(moment(endDate._i), 2);

    return eventMinusPeriode.isSameOrBefore(date);
  }

  handleClickOutside(e) {
    e.preventDefault();
    this.props.closeBox();
  }

  render() {
    const { item, isNoting, t } = this.props;
    const { deposit } = item.customer_note || {};

    return (
      <form className="deposit-box">
        <h3 className="deposit-box__headline">{t('headline')}</h3>
        <p className="deposit-box__paragraph">{t('description')}</p>
        <fieldset disabled={isNoting}>
          <textarea
            className="deposit-message deposit-deposit"
            ref={ref => {
              this.depositMessageInput = ref;
            }}
            rows="3"
            defaultValue={deposit}
            placeholder={t('body')}
          />
          <button disabled={this.isInTime()} className="deposit-submit" type="submit" onClick={this.handleSubmit}>
            {t('send')}
          </button>
          <p className="deposit-box__info">{t('info')}</p>
          <button type="button" onClick={e => this.handleClickOutside(e)} className="deposit-box__close">
            {t('close-link')}
          </button>
        </fieldset>
      </form>
    );
  }
}

DepositBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isNoting: PropTypes.bool,
  item: PropTypes.object.isRequired,
  closeBox: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { deposit } }, { item: { id } }) => ({
  isNoting: !!(deposit[id] && deposit[id].isNoting)
});

export default withTranslation('Common/ServiceEvent/DepositBox')(connect(mapStateToProps)(onClickOutside(DepositBox)));
