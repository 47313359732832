export default data => {
  const formData = new FormData();

  /* eslint-disable-next-line */
  for (const name in data) {
    formData.append(name, data[name]);
  }

  return formData;
};
