import {
  SERVICE_EVENT_DEFINITIONS_REQUEST,
  SERVICE_EVENT_DEFINITIONS_SUCCESS,
  SERVICE_EVENT_DEFINITIONS_FAILURE,
  SERVICE_EVENT_FILTERS_SELECT,
  SERVICE_EVENT_FILTERS_DESELECT,
  SERVICE_EVENT_FILTERS_RESET
} from 'constants/actionTypes';

const initialState = {};

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FILTERS_SELECT:
    case SERVICE_EVENT_FILTERS_DESELECT:
    case SERVICE_EVENT_FILTERS_RESET:
      return initialState;

    case SERVICE_EVENT_DEFINITIONS_REQUEST: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: true, fetchedAt: null } };
    }

    case SERVICE_EVENT_DEFINITIONS_SUCCESS: {
      const {
        meta: { dataKey }
      } = action;

      return {
        ...state,
        [dataKey]: {
          ...state[dataKey],
          isFetching: false,
          fetchedAt: new Date(),
          items: action.payload.map(item => item.id)
        }
      };
    }

    case SERVICE_EVENT_DEFINITIONS_FAILURE: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: false } };
    }

    default:
      return state;
  }
};
