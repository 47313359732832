import {
  SHOP_CART_CREATE_REQUEST,
  SHOP_CART_CREATE_SUCCESS,
  SHOP_CART_CREATE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default body =>
  authCall({
    shouldFetch: state => !state.shop.cart.isCreating,
    types: [SHOP_CART_CREATE_REQUEST, SHOP_CART_CREATE_SUCCESS, SHOP_CART_CREATE_FAILURE],
    endpoint: '/user/shop/cart',
    method: 'POST',
    body
  });
