import { combineReducers } from 'redux';
import cart from './cart';
import categories from './categories';
import partners from './partners';
import products from './products';
import favorites from './favorites';
import bestsellers from './bestsellers';
import payment from './payment';
import view from './view';

export default combineReducers({
  cart,
  categories,
  favorites,
  partners,
  products,
  bestsellers,
  payment,
  view
});
