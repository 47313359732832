import { shouldFetch } from 'utils';
import {
  SERVICE_EVENT_DEFINITION_REQUEST,
  SERVICE_EVENT_DEFINITION_SUCCESS,
  SERVICE_EVENT_DEFINITION_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

export default serviceEventDefinitionId =>
  authCall({
    shouldFetch: ({
      serviceEventDefinitions: {
        single: { [serviceEventDefinitionId]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { serviceEventDefinitionId },
    types: [SERVICE_EVENT_DEFINITION_REQUEST, SERVICE_EVENT_DEFINITION_SUCCESS, SERVICE_EVENT_DEFINITION_FAILURE],
    endpoint: `/user/service-event-definitions/${serviceEventDefinitionId}`,
    method: 'GET'
  });
