import { LOCATION_PUSH, LOCATION_REPLACE, LOCATION_GO_BACK } from 'constants/actionTypes';

export default history => () => next => action => {
  next(action);

  switch (action.type) {
    case LOCATION_PUSH:
      if (action.location) {
        history.push(action.location);
      }
      break;

    case LOCATION_REPLACE:
      if (action.location) {
        history.replace(action.location);
      }
      break;

    case LOCATION_GO_BACK:
      history.goBack();
      break;

    default:
      break;
  }
};
