import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LOCATION_QUERY_SED } from 'constants/location';
import { CommentButton, DepositButton } from '../..';

const Event = ({ item, pathname, t }) => {
  const location = `${pathname}?${LOCATION_QUERY_SED}=${item.definition_id}#${item.id}`;
  const isCommentable = item.isNoteable();
  const isRecurring = item.isModeRecurrent;
  const isDrinks = item.type === 'drinks';
  const trackableUrl = item && item.tracking_url;

  return (
    <div className="modul__row" key={item.id}>
      <Link className="se-link" to={location}>
        <div className="schedule-event relative flex">
          <div className="event-info flex">
            <span className={`se-line ${item.type}`} />
            <div className="flex">
              <div className="event-title ellipsis">
                <span className={`headline ${isRecurring && ' recurring'}`}>{item.name}</span>
              </div>
              {trackableUrl && (
                <button
                  type="button"
                  className="event-track-button"
                  onClick={e => {
                    e.preventDefault();
                    window.open(trackableUrl, '_blank');
                  }}
                >
                  {t('track-order')}
                </button>
              )}
            </div>

            <div className="event-details">
              <div className="event-date">{item.start_date.format('ddd, DD.MM.YYYY')}</div>
              <div className="event-time">{item.start_date.format('HH:mm')}</div>
              <div className="event-actions">
                {isDrinks && <DepositButton item={item} />}
                {isCommentable && <CommentButton item={item} />}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

Event.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export { Event as PureComponent };
export default withTranslation('Dashboard/Schedule')(connect(mapStateToProps)(Event));
