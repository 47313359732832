import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import IntensiveCleaningContainer from './IntensiveCleaningContainer';
import CleaningBox from '../Common/CleaningBox';
import DateTimeGroup from '../Common/DateTimeGroup';
import TrueOrFalse from '../Common/TrueOrFalse';

class Step2 extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  state = {
    focused: null
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  nextStep = e => {
    e.preventDefault();

    const { validateStep2 } = this.props;
    if (validateStep2() === true) {
      this.props.onSubmit();
    }
    return null;
  };

  render() {
    const { t, onChange, setValue, values, invalidFields } = this.props;

    const visitMinDate = moment()
      .startOf('day')
      .add(3, 'days');
    const startMinDate = moment()
      .startOf('day')
      .add(2, 'weeks');

    return (
      <form>
        <div className="cleaning-services__headline">{t('cleaning-date-headline')}</div>

        <CleaningBox>
          <label className="cleaning-services__label" htmlFor="date-of-service" id="block--date_time">
            {t('date-of-service')}
          </label>
          {invalidFields.date_time ? <p className="form-error">{t('error')}</p> : ''}
          <div className={`cleaning-services__times date-of-service  ${invalidFields.date_time ? 'input-error' : ''}`}>
            <SingleDatePicker
              placeholder={t('date-placeholder')}
              id="date-of-service"
              date={values.date_time ? moment.utc(values.date_time) : null}
              onDateChange={date => {
                setValue('date_time', date && typeof date.toISOString === 'function' ? date.toISOString() : null);
              }}
              showClearDate
              numberOfMonths={1}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              hideKeyboardShortcutsPanel
              isOutsideRange={day => day.isBefore(startMinDate)}
            />
          </div>

          <p className="cleaning-services__label">{t('time-of-service')}</p>
          <TrueOrFalse name="irregular_hours" initialValue={values.irregular_hours} onChange={onChange} />
        </CleaningBox>

        <div className="cleaning-services__headline">{t('visit-date-headline')}</div>

        <CleaningBox>
          <p>{t('visit-date-description')}</p>
          <br />

          <p className="cleaning-services__label" id="block--date1">
            {t('choose-visit-dates')}
          </p>
          {invalidFields.date1 ? <p className="form-error">{t('visit-date-error')}</p> : ''}
          <p className="input__label">{t('option-date1')}</p>
          <DateTimeGroup
            className={invalidFields.date1 ? 'input-error' : ''}
            datetime={values.date1}
            onChange={date => {
              setValue('date1', date.isoDate);
            }}
            idDate="date1"
            idTime="time1"
            isOutsideRange={day => day.isBefore(visitMinDate)}
          />
          <p className="input__label">{t('option-date2')}</p>
          <DateTimeGroup
            className={invalidFields.date1 ? 'input-error' : ''}
            datetime={values.date2}
            onChange={date => {
              setValue('date2', date.isoDate);
            }}
            idDate="date2"
            idTime="time2"
            isOutsideRange={day => day.isBefore(visitMinDate)}
          />
          <p className="input__label">{t('option-date3')}</p>
          <DateTimeGroup
            className={invalidFields.date1 ? 'input-error' : ''}
            datetime={values.date3}
            onChange={date => {
              setValue('date3', date.isoDate);
            }}
            idDate="date3"
            idTime="time3"
            isOutsideRange={day => day.isBefore(visitMinDate)}
          />
        </CleaningBox>

        <div className="cleaning-services__buttongroup">
          <Link className="cleaning-services__button" to="/cleaning/intensive-cleaning/step-1">
            {t('cancel')}
          </Link>
          <button type="submit" onClick={this.nextStep} className="cleaning-services__submit">
            {t('submit')}
          </button>
        </div>
      </form>
    );
  }
}

export default IntensiveCleaningContainer(Step2);
