import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DATA_KEY_SHOP_PRODUCTS_FAVORITES } from 'constants/dataKeys';
import { fetchFavoriteProducts } from 'actions/shop/products';
import { WithCustomer, WithPartnerIds } from 'components/DI';
import { getFavoriteProductsForTeaser } from 'selectors/shop';
import Teaser from './Teaser';

class FavoriteTeaser extends Component {
  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    const { isLoading, items, fetchData, t } = this.props;

    return (
      <div className={`${isLoading ? 'loading' : ''}`}>
        {items && items.length > 0 && (
          <Teaser
            className="favourites-row"
            headline={t('headline')}
            location="/shop/favorites"
            locationText={t('show-all')}
            items={items}
            trackingListId="favorite_teaser"
          />
        )}

        <WithCustomer onChange={fetchData} />
        <WithPartnerIds onChange={fetchData} />
      </div>
    );
  }
}

FavoriteTeaser.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  fetchData: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    shop: {
      products: {
        list: { [DATA_KEY_SHOP_PRODUCTS_FAVORITES]: { isFetching } = {} }
      }
    }
  } = state;
  const items = getFavoriteProductsForTeaser(state, props);

  return {
    isLoading: !items && isFetching,
    items
  };
};

const mapDispatchToProps = {
  fetchData: () => fetchFavoriteProducts({ limit: 25 })
};

export default withTranslation('Shop/FavoriteOverview')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FavoriteTeaser)
);
