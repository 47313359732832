import {
  SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_RESET,
  SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_RESET,
  SERVICE_EVENT_CLEANING_SUBMIT_RESET,
  SERVICE_EVENT_CLEANING_OBJECT_RESET,
  SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_RESET,
  SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET,
  SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_RESET,
  SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET
} from 'constants/actionTypes';

export function resetAdditional() {
  return {
    type: SERVICE_EVENT_ADDITIONAL_CLEANING_SUBMIT_RESET
  };
}

export function resetWeekly() {
  return {
    type: SERVICE_EVENT_CLEANING_SUBMIT_RESET
  };
}

export function resetWindow() {
  return {
    type: SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_RESET
  };
}
export function resetWindowOversized() {
  return {
    type: SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET
  };
}
export function resetIntensive() {
  return {
    type: SERVICE_EVENT_INTENSIVE_CLEANING_SUBMIT_RESET
  };
}
export function resetIntensiveObject() {
  return {
    type: SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET
  };
}
export function resetGegenbauer() {
  return {
    type: SERVICE_EVENT_GEGENBAUER_CLEANING_SUBMIT_RESET
  };
}
export function resetRecurrentCleaningObject() {
  return {
    type: SERVICE_EVENT_CLEANING_OBJECT_RESET
  };
}
