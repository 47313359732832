import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DocumentTitle } from 'common';
import { currency, Modal } from 'components/HOC';
import { fetchProduct } from 'actions/shop/products';
import { viewProduct } from 'actions/tracking';
import { ProductImage, PartnerImage } from 'components/Shop/components/Product/Common';
import { FavoriteButton } from 'components/Shop/components/Product';
import { AddForm } from '.';

import './ProductDetail.less';

export class ProductDetail extends Component {
  componentDidMount() {
    if (this.props.item) {
      this.props.onView(this.props.item);
    } else {
      this.props.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item && !this.props.item) {
      this.props.fetchData();
    }

    if (this.props.item !== prevProps.item && this.props.item) {
      this.props.onView(this.props.item);
    }
  }

  discount() {
    const discountPercentage = Math.floor(
      ((this.props.item.unit_price - this.props.item.discount_price) / this.props.item.unit_price) * 100
    );
    return discountPercentage;
  }

  renderContent() {
    const { item, partner, t, closeModal, c } = this.props;

    if (!item) return null;

    return (
      <div className="modal-flex">
        <div className="left">
          <div className="top">
            <ProductImage item={item} />
            <FavoriteButton id={item.id} />
          </div>

          <AddForm item={item} closeModal={closeModal} />
          {item.discount_price > 0 && <p className="discount-rules">{t('discount-rules')}</p>}
        </div>

        <div className="right">
          <div className="product-name">{item.name}</div>
          {item.unit && (
            <div className="product-unit-size">
              {item.unit}
              {item.unit_type}
            </div>
          )}

          {partner && (
            <div className="product-partner-wrapper">
              <PartnerImage item={partner} />
              <div className="product-partner-name">{partner.name}</div>
              {partner.minimum_order_value > 0 && (
                <div className="product-partner-min-order">
                  {t('minimum-order-value')} {c(partner.minimum_order_value)}
                </div>
              )}
            </div>
          )}

          {item.unit_price && (
            <div className="product-price-wrapper">
              <div className="product-price-label">{t('price')}</div>
              <div className="product-price">{c(item.unit_price)}</div>
              {item.deposit > 0 && (
                <div className="product-price-deposit">
                  + {c(item.deposit)} {t('deposit')}
                </div>
              )}
            </div>
          )}

          {item.discount_price > 0 && (
            <div className="product-discount-price-wrapper">
              <div className="product-discount-price-label">{t('discount-price')}</div>
              <div className="product-discount-price">
                {c(item.discount_price)}
                <div className="product-discount-save">
                  &nbsp;/&nbsp;{t('discount-save')} {this.discount()}%
                </div>
              </div>
              {item.deposit > 0 && (
                <div className="product-discount-price-deposit">
                  + {c(item.deposit)} {t('deposit')}
                </div>
              )}
            </div>
          )}

          {item.shipped_in && (
            <div className="product-shipment-in-wrapper">
              <div className="product-shipment-label">{t('shipment')}</div>
              <div className="product-shipment">{item.shipped_in}</div>
            </div>
          )}

          {item.description && (
            <div className="product-description-wrapper">
              <div className="product-description">{item.description}</div>
              {item.info && <div className="product-info">{item.info}</div>}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return <DocumentTitle title={(item && item.name) || '...'}>{this.renderContent()}</DocumentTitle>;
  }
}

ProductDetail.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  t: PropTypes.func,
  isLoading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  item: PropTypes.object,
  partner: PropTypes.object,
  fetchData: PropTypes.func,
  closeModal: PropTypes.func,
  onView: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const {
    shop: {
      products: {
        single: { [props.id]: { isFetching } = {} }
      }
    }
  } = state;
  const {
    shop: { products, partners }
  } = state;

  const item = products.entities[props.id];
  const partner = item && partners.entities[item.partner_id];

  return {
    isLoading: !item && isFetching,
    item,
    partner
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchData: () => dispatch(fetchProduct(id)),
  onView: item => dispatch(viewProduct(item))
});

export { ProductDetail as PureComponent };
export default currency(
  withTranslation('Modal/ProductDetail')(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Modal('Product Detail', { className: 'product-modal' })(ProductDetail))
  )
);
