import { AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAILURE } from 'constants/actionTypes';
import { X_MASTER_TOKEN } from 'constants/headers';
import { apiCall } from 'api';

export default () => (dispatch, getState) => {
  const {
    auth: {
      data: { masterToken }
    }
  } = getState();

  if (!masterToken) {
    dispatch({
      type: AUTH_TOKEN_FAILURE
    });

    return Promise.reject();
  }

  return dispatch(
    apiCall({
      types: [AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAILURE],
      endpoint: '/user/auth',
      method: 'PUT',
      headers: {
        [X_MASTER_TOKEN]: masterToken
      }
    })
  );
};
