import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

export class RegisterStepTwo extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(e);
  };

  render() {
    const { t, email } = this.props;

    return (
      <form className="register-view__form" onSubmit={this.handleSubmit}>
        <div className="register-view__wrapper">
          <div className="register-view__container">
            <div className="success-logo" />
            <h1 className="success-page__headline">{t('check-inbox')}</h1>
            <div className="register-view__content">
              <div>
                <div dangerouslySetInnerHTML={{ __html: t('click-link', { email }) }} className="success-page__text" />
                <div className="success-page__text">
                  {t('no-email')}
                  <br />
                  <button type="submit" className="link">
                    {t('send-email-again-2')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

RegisterStepTwo.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string
};

const mapStateToProps = ({ register: { isRegistering: isDisabled } }) => ({ isDisabled });

export default withTranslation('Public/Register')(connect(mapStateToProps)(RegisterStepTwo));
