import {
  AUTH_RESTORE,
  AUTH_JWT_TOKEN_SET,
  AUTH_ALL_TOKENS_SET,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_TOKEN_SUCCESS,
  AUTH_TOKEN_FAILURE,
  CUSTOMER_CHANGE_SUCCESS
} from 'constants/actionTypes';

export default (
  state = {
    isAuthenticated: false,
    jwtToken: null,
    masterToken: null
  },
  action
) => {
  switch (action.type) {
    case AUTH_RESTORE:
      return { ...state, isAuthenticated: true, jwtToken: action.jwtToken, masterToken: action.masterToken };

    case AUTH_JWT_TOKEN_SET:
      return { ...state, isAuthenticated: true, jwtToken: action.jwtToken, masterToken: null };
    case AUTH_ALL_TOKENS_SET:
      return { ...state, isAuthenticated: true, jwtToken: action.jwtToken, masterToken: action.masterToken };
    case AUTH_LOGIN_REQUEST:
      return { ...state, isAuthenticated: false, jwtToken: null, masterToken: null };

    case AUTH_LOGIN_SUCCESS:
    case CUSTOMER_CHANGE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        jwtToken: action.payload.token,
        masterToken: action.payload.masterToken
      };

    case AUTH_LOGOUT_SUCCESS:
    case AUTH_LOGOUT_FAILURE:
      return { ...state, isAuthenticated: false, jwtToken: null, masterToken: null };

    case AUTH_TOKEN_SUCCESS:
      return { ...state, jwtToken: action.payload.token };

    case AUTH_TOKEN_FAILURE:
      return { ...state, isAuthenticated: false, jwtToken: null, masterToken: null };

    default:
      return state;
  }
};
