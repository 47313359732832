import React from 'react';
import PropTypes from 'prop-types';
import { currency } from 'components/HOC';
import { translateEditing } from '../../HOC';

const Sum = ({ item, t, c }) => {
  const { discount } = item;
  const deliveryFee = item.delivery_fee;
  const sumDeposit = item.sum_deposit;
  const sumNet = item.sum_net;
  const sumTax = item.sum_tax;
  const sumGross = item.sum_gross;
  const netDeposit = item.sum_net + item.sum_deposit;

  return (
    <div className="subcart-sum">
      <div className="cart-costline">
        {t('sub-total')}: <span className="cart-costs sum-net">{c(sumNet)}</span>
      </div>

      {sumDeposit > 0 && (
        <div className="cart-costline">
          {t('deposit')}:<span className="cart-costs sum-deposit">{c(sumDeposit)}</span>
        </div>
      )}

      {sumNet > 0 && sumDeposit > 0 && (
        <div className="cart-costline cart-costline-net-deposit">
          {t('sub-total')}&nbsp;
          <small>
            ({t('net')} + {t('deposit')})
          </small>
          :<span className="cart-costs sum-net-deposit">{c(netDeposit)}</span>
        </div>
      )}

      <div className="cart-costline">
        {t('tax-amount')}:<span className="cart-costs sum-tax">{c(sumTax)}</span>
      </div>

      <div className="cart-costline">
        {t('delivery-costs')}:<span className="cart-costs sum-delivery-fee">{c(deliveryFee)}</span>
      </div>

      {discount && (
        <div className="cart-costline cart-discount">
          <span className="discount-name">{discount.name}:</span>
          <span className="cart-costs discount-amount">- {c(discount.amount)}</span>
        </div>
      )}

      <div className="cart-costline sum">
        {t('sum')}&nbsp;<small>({t('gross')})</small>:<span className="cart-costs sum-gross">{c(sumGross)}</span>
      </div>
    </div>
  );
};

Sum.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

export { Sum as PureComponent };
export default currency(translateEditing(Sum));
