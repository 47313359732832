import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const SurveyMonkey = ({ language }) => {
  const url =
    language === 'de' ? process.env.REACT_APP_SURVEY_MONKEY_URL_DE : process.env.REACT_APP_SURVEY_MONKEY_URL_EN;

  if (!url) return null;

  return (
    <div className="survey-monkey-wrapper">
      <iframe src={url} title="survey-monkey" width="100%" height="100%" frameBorder="0" />
    </div>
  );
};

SurveyMonkey.propTypes = {
  language: PropTypes.string
};

const mapStateToProps = ({ i18n: { language } }) => ({ language });

export default connect(mapStateToProps)(SurveyMonkey);
