import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { propCreator } from 'components/HOC';
import { Loader, Transition, ServiceEventDefinitionLink, Tooltip } from 'common';
import { fetchServiceEventDefinitions } from 'actions/serviceEventDefinitions';
import { createGetServiceEventDefinitions } from 'selectors/serviceEventDefinitions';
import { WithCustomer, WithSEFilters } from 'components/DI';
import { EmptyList } from '.';

import './ServiceEventDefinitionOverview.less';
import './CostOverview.less';

class ServiceEventDefinitionOverview extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, startDate, endDate, dataKey } = this.props;

    dispatch(
      fetchServiceEventDefinitions(dataKey, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      })
    );
  }

  render() {
    const { isFetching, items, t } = this.props;
    const tooltipId = 'service-event-definition-overview';

    return (
      <Tooltip className="cost-module" id={tooltipId}>
        <h2 className="headline" data-for={tooltipId} data-tip={t('tooltip')} data-effect="solid">
          {t('headline')}
        </h2>

        <div className="col-group current-products-module">
          <Transition>
            {items &&
              items.length > 0 &&
              items.map(item => <ServiceEventDefinitionLink key={item.id} item={item} withButtons />)}
          </Transition>

          {items && !items.length && <EmptyList />}
        </div>

        <Loader isLoading={isFetching} />

        <WithCustomer onChange={this.fetchData} />
        <WithSEFilters onChange={this.fetchData} />
      </Tooltip>
    );
  }
}

ServiceEventDefinitionOverview.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func.isRequired
};

const createProps = ({ startDate, endDate }) => ({
  dataKey: `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`
});

const mapStateToProps = () => {
  const getServiceEventDefinitions = createGetServiceEventDefinitions();

  return (state, props) => {
    const {
      serviceEventDefinitions: {
        list: { [props.dataKey]: { isFetching } = {} }
      }
    } = state;

    return {
      isFetching,
      items: getServiceEventDefinitions(state, props)
    };
  };
};

export default withTranslation('Dashboard/ServiceEventDefinitionOverview')(
  propCreator(createProps)(connect(mapStateToProps)(ServiceEventDefinitionOverview))
);
