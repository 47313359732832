import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';
import { Tooltip } from '../..';

import './Select.less';

const Select = props => {
  const { id, className, parentClass, label, options, required, tooltip, tooltipMessage, dataType } = props;
  const passedProps = omit(props, 'className', 'parentClass', 'label', 'options', 'dataType');

  if (typeof passedProps.value === 'undefined') {
    passedProps.defaultValue = dataType;
  }

  return (
    <div className={`select ${parentClass}`}>
      {label && (
        <label htmlFor={id} className={`select__label ${required ? 'required' : ''}`}>
          {label}
          {tooltip && (
            <div className="tooltip" data-for={tooltip} data-tip={tooltipMessage}>
              <Tooltip id={tooltip} />
            </div>
          )}
        </label>
      )}
      <span className={`select__input ${className}`}>
        <select {...passedProps}>
          {dataType && <option disabled>{dataType}</option>}
          {options &&
            options.map((item, index) => (
              <option
                key={`${id}-i${index + 1}`} // eslint-disable-line react/no-array-index-key
                value={item.value}
              >
                {item.name}
              </option>
            ))}
          ;
        </select>
      </span>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  tooltipMessage: PropTypes.string,
  className: PropTypes.string,
  parentClass: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  dataType: PropTypes.string
};

Select.defaultProps = {
  className: '',
  parentClass: '',
  required: false,
  dataType: ''
};

export default htmlId(Select);
