import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Transition } from 'common';

class Feature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTextVisible: false
    };

    this.showText = this.showText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.item !== nextProps.item) {
      this.hideText();
    }
  }

  showText() {
    if (!this.state.isTextVisible) {
      this.setState({
        isTextVisible: true
      });
    }
  }

  hideText() {
    if (this.state.isTextVisible) {
      this.setState({
        isTextVisible: false
      });
    }
  }

  render() {
    const { item } = this.props;
    const { isTextVisible } = this.state;
    const imageUrl =
            item.fields.images &&
            item.fields.images[0] &&
            item.fields.images[0].fields &&
            item.fields.images[0].fields.file &&
            item.fields.images[0].fields.file.de &&
            item.fields.images[0].fields.file.de.url;

    return (
      <div className="feature">
        <h1>{item.fields.title}</h1>

        <div className="subTitle" dangerouslySetInnerHTML={{ __html: item.fields.subTitle }} />

        {!isTextVisible && item.fields.text && (
          <div className="cta-wrapper">
            <button type="button" className="cta" onClick={this.showText}>
              {item.fields.ctaText}
            </button>
          </div>
        )}

        <Transition
          name="fade"
          className="feature-text"
          appearTimeout={500}
          enterTimeout={500}
          leaveTimeout={false}
        >
          {isTextVisible && (
            <div
              className="feature-text-payload long-text"
              dangerouslySetInnerHTML={{ __html: item.fields.text }}
            />
          )}
        </Transition>

        {imageUrl && (
          <div className="feature-image-wrapper">
            <img className="feature-image" alt="" src={imageUrl} />
          </div>
        )}
      </div>
    );
  }
}

Feature.propTypes = {
  item: PropTypes.object.isRequired
};

export { Feature as PureComponent };
export default pure(Feature);
