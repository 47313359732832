import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { DocumentTitle } from 'common';
import { login } from 'actions/auth';
import { ResetPasswordLink } from 'components/Modal';

import './Login.less';

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputEmail: '',
      inputPassword: '',
      errorEmail: false,
      errorPassword: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.validate()) {
      this.props.doLogin(
        this.emailInput.value.trim(),
        this.passwordInput.value.trim(),
        this.recaptcha && this.recaptcha.getValue()
      );
    }
  }

  validate() {
    const { inputEmail, inputPassword } = this.state;
    let isValid = true;

    if (inputEmail) {
      this.setState({ errorEmail: false });
    } else {
      this.setState({ errorEmail: true });
      isValid = false;
    }
    if (inputPassword) {
      this.setState({ errorPassword: false });
    } else {
      this.setState({ errorPassword: true });
      isValid = false;
    }

    return isValid;
  }

  render() {
    const { isFetching, isCaptchaRequired, t, pathname } = this.props;
    const { errorEmail, errorPassword } = this.state;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="public__background">
          <header className="fake-website-header">
            <div className="content">
              <a href="https://one-ifm.com/" title="Service Partner ONE" rel="nofollow" className="logo">
                <img src="/images/logo/logo_SPONE.svg" alt="Service Partner ONE" />
              </a>
              <div className="info">
                <span>{t('header')}</span>
              </div>
              <div className="login_register">
                <Link className="header-link" to="/register">
                  <span>{t('register')}</span>
                </Link>
              </div>
            </div>
          </header>
          <div className="public__box">
            <div className="col-group login-view">
              <p className="headline">{t('headline')}</p>
              <p className="login-text">
                {t('no-client')}
                <Link className="link" to="/register">
                  {' '}
                  {t('register-long')}
                </Link>
              </p>
              <form onSubmit={this.handleSubmit}>
                <fieldset disabled={isFetching}>
                  <div className="form-group">
                    {errorEmail ? (
                      <label className="error" htmlFor="email">
                        {t('enter-email')}
                      </label>
                    ) : (
                      <label htmlFor="email">{t('email')}</label>
                    )}
                    <input
                      name="email"
                      type="email"
                      className={`form-control ${errorEmail ? 'active' : ''}`}
                      ref={ref => {
                        this.emailInput = ref;
                      }}
                      placeholder={t('email')}
                      onChange={e => this.setState({ inputEmail: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    {errorPassword ? (
                      <label className="error" htmlFor="password">
                        {t('enter-password')}
                      </label>
                    ) : (
                      <label htmlFor="password">{t('password')}</label>
                    )}
                    <input
                      name="password"
                      type="password"
                      className={`form-control ${errorPassword ? 'active' : ''}`}
                      ref={ref => {
                        this.passwordInput = ref;
                      }}
                      placeholder={t('password')}
                      onChange={e => this.setState({ inputPassword: e.target.value })}
                    />
                  </div>
                  {isCaptchaRequired && (
                    <ReCAPTCHA
                      ref={ref => {
                        this.recaptcha = ref;
                      }}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={() => {}}
                    />
                  )}
                  <button type="submit" className={`button relative ${isFetching ? 'loading' : ''}`}>
                    {t('login')}
                  </button>
                </fieldset>
              </form>
              <div className="center">
                <ResetPasswordLink title={t('forgot-password')} pathname={pathname} />
              </div>
              <div className="partnerapp-signup">
                <p>{t('partner-register-headline')}</p>
                <a
                  className="partnerapp-signup-link"
                  target="_blank"
                  href="https://partner.servicepartner.one/login"
                  rel="noopener noreferrer"
                >
                  {t('partner-register')}
                </a>
              </div>
            </div>
          </div>
          <div className="public_footer">
            <Link className="public_footer__link" to="https://www.servicepartner.one/de/impressum">
              {' '}
              {t('imprint')}
            </Link>
            <Link className="public_footer__link" to="https://one-ifm.com/en/datenschutzerklaerung/">
              {' '}
              {t('privacy-police')}
            </Link>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isCaptchaRequired: PropTypes.bool,
  doLogin: PropTypes.func.isRequired
};

const mapStateToProps = ({
  auth: {
    login: { isFetching, isCaptchaRequired }
  },
  location: { pathname }
}) => ({ isFetching, isCaptchaRequired, pathname });

const mapDispatchToProps = {
  doLogin: login
};

export default withTranslation('Public/Login')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
