import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';

const PremiumTasklist = ({ t }) => (
  <DocumentTitle title={t('title')}>
    <div className="modal-flex">
      <div className="cleaning-tasklist">
        <div className="cleaning-tasklist__header">
          <p className="cleaning-tasklist__header-title">{t('premium-header')}</p>
        </div>

        <div className="cleaning-tasklist__body offices">
          <p className="cleaning-tasklist__body-title">{t('cleaning-offices')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-free-desk')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-hardware')}</p>
            <p className="row-body">{t('cleaning-fog-wet')}</p>
          </div>
          <div className="row">
            <p className="row-title">{t('cleaning-upholstery')}</p>
            <p className="row-body">{t('cleaning-vacuum')}</p>
          </div>
        </div>

        <div className="cleaning-tasklist__body kitchen">
          <p className="cleaning-tasklist__body-title">{t('cleaning-kitchen')}</p>
          <div className="row">
            <p className="row-title">{t('cleaning-dishwasher')}</p>
            <p className="row-body">{t('cleaning-move')}</p>
          </div>
        </div>
      </div>
    </div>
  </DocumentTitle>
);

PremiumTasklist.propTypes = {
  t: PropTypes.func.isRequired
};

export { PremiumTasklist as PureComponent };
export default withTranslation('Modal/CleaningTasklist')(
  Modal('Calendar Sync', { className: 'cleaning-tasklist-modal' })(PremiumTasklist)
);
