/* eslint-disable import/no-named-as-default */

import Invoice from './components/Invoice';
import Invoices from './Invoices';
import Orders from './components/Orders';
import Order from './components/Order';
import NoResult from './components/NoResult';
import Navigation from './components/Navigation';

export default Navigation;
export { Invoices, Invoice, Orders, Order, NoResult };
