import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

class WithPartnerIds extends Component {

  componentWillReceiveProps(nextProps) {
    if (this.partnerIdsHaveChanged(nextProps)) {
      this.props.onChange();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !this.partnerIdsHaveChanged(nextProps);
  }

  partnerIdsHaveChanged(nextProps) {
    return this.props.partnerIds !== nextProps.partnerIds;
  }

  render() {
    if (!this.props.children) return null;

    const passedProps = omit(this.props, 'partnerIds', 'onChange', 'dispatch');
    return <div {...passedProps}>{this.props.children}</div>;
  }
}

WithPartnerIds.propTypes = {
  partnerIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = ({ shop: { partners: { partnerIds } } }) => ({ partnerIds });

export { WithPartnerIds as PureComponent };
export default connect(mapStateToProps)(WithPartnerIds);
