import fetch from 'isomorphic-fetch';
import pick from 'lodash/pick';
import { stringify } from 'query-string';
import { CONTENT_TYPE, CONTENT_TYPE_JSON, CONTENT_TYPE_FORM_URLENCODED, X_LANGUAGE } from 'constants/headers';
import { getJSON } from 'utils';

const resolveWithState = (value, state) => (typeof value === 'function' ? value(state) : value);
const getQuery = (q, state) => {
  let query = resolveWithState(q, state);

  if (typeof query === 'object') {
    query = stringify(query);
  }

  return query ? `?${query}` : '';
};

const getEndpoint = (options, state) =>
  process.env.REACT_APP_SPONE_API_URL + resolveWithState(options.endpoint, state) + getQuery(options.query, state);

const getContentType = data => data && data[CONTENT_TYPE];

const getHttpOptions = (options, state) => {
  const httpOptions = pick(options, 'method', 'headers', 'body', 'multipart');
  const {
    i18n: { language }
  } = state;

  if (language) {
    httpOptions.headers = { ...httpOptions.headers, [X_LANGUAGE]: language };
  }

  if (['POST', 'PUT'].includes(httpOptions.method) && !getContentType(httpOptions.headers) && !httpOptions.multipart) {
    httpOptions.headers = { ...httpOptions.headers, [CONTENT_TYPE]: CONTENT_TYPE_FORM_URLENCODED };
  } else if (['POST', 'PUT'].includes(httpOptions.method) && httpOptions.multipart) {
    httpOptions.headers = { ...httpOptions.headers };
  }

  if (httpOptions.body) {
    httpOptions.body = resolveWithState(httpOptions.body, state);

    if (typeof httpOptions.body !== 'string') {
      if (getContentType(httpOptions.headers) === CONTENT_TYPE_JSON) {
        httpOptions.body = JSON.stringify(httpOptions.body);
      } else if (httpOptions.multipart) {
        httpOptions.body = httpOptions.body;
      } else {
        httpOptions.body = stringify(httpOptions.body);
      }
    }
  }

  return httpOptions;
};

const getTypeDescriptor = type => (typeof type === 'string' ? { type } : type);

export default (options = {}) => (dispatch, getState) => {
  const state = getState();
  const { shouldFetch, types = [], meta } = options;

  if (shouldFetch && !shouldFetch(state)) return Promise.resolve();

  let endpoint;
  let httpOptions;

  try {
    endpoint = getEndpoint(options, state);
    httpOptions = getHttpOptions(options, state);
  } catch (requestError) {
    dispatch({
      meta,
      ...getTypeDescriptor(types[2]),
      error: true,
      requestError
    });

    return Promise.resolve();
  }

  let response;
  let payload;

  dispatch({
    meta,
    ...getTypeDescriptor(types[0])
  });

  return fetch(endpoint, httpOptions)
    .then(res => {
      response = res;
      return getJSON(response);
    })
    .then(json => {
      payload = json;

      if (response.ok) {
        dispatch({
          meta,
          ...getTypeDescriptor(types[1]),
          response,
          payload
        });
      } else {
        dispatch({
          meta,
          ...getTypeDescriptor(types[2]),
          error: true,
          response,
          payload
        });
      }

      return response;
    })
    .catch(requestError =>
      dispatch({
        meta,
        ...getTypeDescriptor(types[2]),
        error: true,
        requestError,
        response,
        payload
      })
    );
};
