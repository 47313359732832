import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import marked from 'marked';
import { propCreator } from 'components/HOC';
import { Loader, ToggleBox } from 'common';
import { fetchFaqEntries } from 'actions/contentful';
import { CONTENTFUL_CONTENT_TYPE_FAQ } from 'constants/contentful';
import { createGetEntries } from 'selectors/contentful';

import './Faq.less';

const filterItems = (items, regExps) => {
  if (items && regExps.length > 0) {
    return items.filter(
      item =>
        regExps.every(regExp => regExp.test(item.fields.question)) ||
        regExps.every(regExp => regExp.test(item.fields.answer))
    );
  }

  return items;
};

export class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      regExps: [],
      items: props.items
    };

    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {
    this.props.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState(prevState => ({
        items: filterItems(nextProps.items, prevState.regExps)
      }));
    }
  }

  handleInput({ target: { value } }) {
    const { items } = this.props;
    const search = value.trim();

    if (this.state.search !== search) {
      const regExps = search
        .split(' ')
        .map(s => s.trim())
        .filter(s => !!s)
        .map(s => new RegExp(s, 'i'));

      this.setState({
        search,
        regExps,
        items: filterItems(items, regExps)
      });
    }
  }

  render() {
    const { isFetching, t } = this.props;
    const { regExps, items } = this.state;

    return (
      <div className="col-12 pad">
        <div className="support-headline">{t('headline')}</div>

        <input type="text" className="faq-search" placeholder={t('search')} onInput={this.handleInput} />

        <Loader isLoading={isFetching} />

        {items &&
          items.map(item => {
            const isOpened = regExps.length > 0 ? regExps.every(regExp => regExp.test(item.fields.answer)) : false;

            return (
              <ToggleBox className="faq-entry" key={item.sys.id} title={item.fields.question} isOpened={isOpened}>
                <div className="faq-answer" dangerouslySetInnerHTML={{ __html: marked(item.fields.answer) }} />
              </ToggleBox>
            );
          })}
      </div>
    );
  }
}

Faq.propTypes = {
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  t: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired
};

const createProps = {
  dataKey: CONTENTFUL_CONTENT_TYPE_FAQ
};

const mapStateToProps = () => {
  const getEntries = createGetEntries();

  return (state, props) => {
    const {
      contentful: { [props.dataKey]: { isFetching } = {} }
    } = state;

    return {
      isFetching,
      items: getEntries(state, props)
    };
  };
};

const mapDispatchToProps = {
  fetchData: fetchFaqEntries
};

export default withTranslation('Support/Faq')(
  propCreator(createProps)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Faq)
  )
);
