import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import moment from 'moment';

import { Loader } from 'common';
import { getItemsFromPages } from 'selectors/shared';
import { WithCustomer, WithSEFilters } from 'components/DI';
import { fetchServiceEvents } from 'actions/serviceEvents';
import ServiceEventFilter from '../ServiceEventFilter';
import { Event, Placeholder } from '.';

import './PastEvents.less';

export class PastEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: moment()
    };

    this.dataKey = '';

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate) {
      this.fetchData();
    }
  }

  getPages = () => {
    const { list } = this.props;

    return list[this.dataKey] && list[this.dataKey].pages;
  };

  getEntities = () => this.props.entities;

  getServiceEvents = () =>
    createSelector(
      [getItemsFromPages(this.getPages), this.getEntities],
      (items, entities) => {
        if (!items) return null;

        return items.map(itemId => entities[itemId]).filter(item => !!item);
      }
    )();

  getDateSpan = month => {
    const selectedMonth = month || this.state.selectedMonth || moment();

    return {
      startDate: selectedMonth.clone().startOf('month'),
      endDate: selectedMonth.clone().endOf('month')
    };
  };

  handleMonthClick = selectedMonth => {
    this.setState(() => ({
      selectedMonth
    }));

    this.fetchData(1, selectedMonth);
  };

  fetchData(nextPage, selectedMonth) {
    const { dispatch, limit, list } = this.props;
    const { startDate, endDate } = selectedMonth ? this.getDateSpan(selectedMonth) : this.getDateSpan();
    const now = moment(new Date().getTime());

    let currentPage = 1;
    this.dataKey = `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`;
    if (list[this.dataKey]) {
      currentPage = list[this.dataKey].currentPage || 1;
    }

    let latestAvailableDate = endDate;
    if (endDate >= now) {
      latestAvailableDate = now;
    }

    dispatch(
      fetchServiceEvents(this.dataKey, {
        start_date: startDate.toISOString(),
        end_date: latestAvailableDate.toISOString(),
        page: nextPage || currentPage,
        order_by: 'desc',
        only_within_date_span: false,
        limit
      })
    );
  }

  fetchNext(currentPage) {
    this.fetchData(currentPage + 1);
  }

  renderHeader = () => {
    const { t } = this.props;
    const { selectedMonth } = this.state;
    const prevMonth = selectedMonth.clone().subtract(1, 'month');
    const nextMonth = selectedMonth.clone().add(1, 'month');
    const { startDate, endDate } = this.getDateSpan();
    const now = moment(new Date().getTime());

    return (
      <div className="modul__header">
        <span>{t('title')}</span>
        <div className="modul__header-buttongroup">
          <span className="modul__header-date">{selectedMonth.format('MMM YYYY')}</span>
          <button
            type="button"
            onClick={() => this.handleMonthClick(prevMonth)}
            className="modul__header-button left"
            title={prevMonth.format('ddd, DD.MM.YYYY')}
          />
          <button
            type="button"
            onClick={() => this.handleMonthClick(nextMonth)}
            className={`modul__header-button right ${endDate >= now ? 'disabled' : ''}`}
            title={nextMonth.format('ddd, DD.MM.YYYY')}
          />
          <ServiceEventFilter startDate={startDate} endDate={endDate} />
        </div>
      </div>
    );
  };

  render() {
    const { t, list } = this.props;
    let items = [];
    let isFetching = false;
    let currentPage = 1;
    let hasMore = false;

    if (list[this.dataKey]) {
      items = this.getServiceEvents() || [];

      isFetching = list[this.dataKey].isFetching || false;
      currentPage = list[this.dataKey].currentPage || 1;
      hasMore = list[this.dataKey].hasMore || false;
    }

    return (
      <div className="dashboard-modul modul-past past">
        {this.renderHeader()}
        {items.length > 0 &&
          items.map(item => {
            if (item.isActive) {
              return <Event item={item} key={item.id} />;
            }
            return null;
          })}
        {!items.length && <Placeholder />}
        {hasMore && (
          <div className="modul__more">
            <button
              type="button"
              className={`button-more ${isFetching ? 'disabled loading' : ''}`}
              onClick={() => this.fetchNext(currentPage)}
            >
              {t('more')}
            </button>
          </div>
        )}
        <Loader isLoading={isFetching} />
        <WithCustomer onChange={() => this.fetchData(1)} />
        <WithSEFilters onChange={() => this.fetchData(1)} />
      </div>
    );
  }
}

PastEvents.propTypes = {
  dispatch: PropTypes.func,
  limit: PropTypes.number,
  entities: PropTypes.object,
  list: PropTypes.object,
  t: PropTypes.func
};

PastEvents.defaultProps = {
  limit: 5
};

const mapStateToProps = ({ serviceEvents: { entities, list } }) => ({ entities, list });

export default withTranslation('Dashboard/PastEvents')(connect(mapStateToProps)(PastEvents));
