import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DATA_KEY_SHOP_PRODUCTS_ALL } from 'constants/dataKeys';
import { fetchProducts } from 'actions/shop/products';
import { propCreator } from 'components/HOC';
import { createGetProducts } from 'selectors/shop';
import Overview from '../Overview';

const createTitle = ({ t }) => `${t('headline')} - ${t('Shop:headline')}`;

const createBreadcrumbItems = ({ numberOfItems, t }) => [
  { url: '/shop', label: t('Shop:headline') },
  { label: t('headline'), numberOfItems }
];

class ProductOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: createTitle(props),
      breadcrumbItems: createBreadcrumbItems(props)
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.t !== nextProps.t || this.props.numberOfItems !== nextProps.numberOfItems) {
      this.setState({
        title: createTitle(nextProps),
        breadcrumbItems: createBreadcrumbItems(nextProps)
      });
    }
  }

  fetchData(nextPage) {
    const { dispatch, dataKey, currentPage, limit } = this.props;

    dispatch(
      fetchProducts(dataKey, {
        page: nextPage || currentPage,
        limit
      })
    );
  }

  fetchNext() {
    this.fetchData(this.props.currentPage + 1);
  }

  render() {
    const { fetchData, fetchNext } = this;
    const { title, breadcrumbItems } = this.state;
    const { isFetching, items, hasMore } = this.props;
    const passedProps = { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext };

    return <Overview {...passedProps} trackingListId="all_products_list" />;
  }
}

ProductOverview.propTypes = {
  dataKey: PropTypes.string.isRequired,
  limit: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  numberOfItems: PropTypes.number,
  t: PropTypes.func.isRequired
};

ProductOverview.defaultProps = {
  limit: 25
};

const createProps = {
  dataKey: DATA_KEY_SHOP_PRODUCTS_ALL
};

const mapStateToProps = () => {
  const getProducts = createGetProducts();

  return (state, props) => {
    const {
      shop: {
        products: {
          list: { [props.dataKey]: { isFetching, currentPage = 1, hasMore, numberOfItems } = {} }
        }
      }
    } = state;

    return {
      isFetching,
      items: getProducts(state, props),
      currentPage,
      hasMore,
      numberOfItems
    };
  };
};

export default withTranslation('Shop/ProductOverview')(
  propCreator(createProps)(connect(mapStateToProps)(ProductOverview))
);
