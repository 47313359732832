import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "./Services.less";

const Services = ({ t }) => (
  <div className="dashboard-modul modul-servicelinks">
    <div className="modul__bigrow">
      <div className="flex servicelinks-container">
        <div className="servicelinks-title">{t("title")}</div>
        <div className="servicelinks-list flex">
          <Link to="/cleaning">
            <div className="servicelinks-cleaning servicelink">
              <span>{t("cleaning")}</span>
            </div>
          </Link>
          {/* <Link to="/services">
            <div className="servicelinks-otherservices servicelink">
              <span>{t('other-services')}</span>
            </div>
          </Link> */}
          <Link to="/shop">
            <div className="servicelinks-shop servicelink">
              <span>{t("shop")}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

Services.propTypes = {
  t: PropTypes.func.isRequired
};

export { Services as PureComponent };
export default withTranslation("Dashboard/ServiceLinks")(Services);
