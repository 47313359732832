import { FETCH_PARTNER_REQUEST, FETCH_PARTNER_SUCCESS, FETCH_PARTNER_FAILURE } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PARTNER_REQUEST: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isFetching: true, fetchedAt: null } };
    }

    case FETCH_PARTNER_SUCCESS: {
      const {
        meta: { token }
      } = action;

      return {
        ...state,
        [token]: { ...state[token], isFetching: false, fetchedAt: new Date(), item: action.payload }
      };
    }

    case FETCH_PARTNER_FAILURE: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isFetching: false } };
    }

    default:
      return state;
  }
};
