import { hasError } from 'utils';
import {
  REGISTER_STEP_ONE_REQUEST,
  REGISTER_STEP_ONE_SUCCESS,
  REGISTER_STEP_ONE_FAILURE,
  REGISTER_STEP_TWO_REQUEST,
  REGISTER_STEP_TWO_SUCCESS,
  REGISTER_STEP_TWO_FAILURE
} from 'constants/actionTypes';
import { CAPTCHA_REQUIRED, CAPTCHA_INVALID } from '../constants/errors';

export default (
  state = {
    isRegistering: false,
    isRegistered: false,
    isCaptchaRequired: false,
    step: 1,
    token: null
  },
  action
) => {
  switch (action.type) {
    case REGISTER_STEP_ONE_REQUEST:
      return { ...state, isRegistering: true, isRegistered: false };

    case REGISTER_STEP_ONE_SUCCESS:
      return { ...state, isRegistering: false, isCaptchaRequired: false, step: 2, token: action.payload.token };

    case REGISTER_STEP_ONE_FAILURE:
      return {
        ...state,
        isRegistering: false,
        isCaptchaRequired: hasError(action.payload, CAPTCHA_REQUIRED, CAPTCHA_INVALID)
      };

    case REGISTER_STEP_TWO_REQUEST:
      return { ...state, isRegistering: true, isRegistered: false };

    case REGISTER_STEP_TWO_SUCCESS:
      return { ...state, isRegistering: false, isRegistered: true, step: 2, token: action.payload.token };

    case REGISTER_STEP_TWO_FAILURE:
      return { ...state, isRegistering: false };

    default:
      return state;
  }
};
