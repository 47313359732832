/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { pure } from 'recompose';
import { FilterSelect } from '.';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      status: [],
      priority: [],
      startDate: null,
      endDate: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPriorities !== prevProps.selectedPriorities) {
      this.setState({
        priority: this.props.selectedPriorities
      });
    }
  }

    toggleFilterDropdown = () => {
      this.setState(prevState => ({
        showFilter: !prevState.showFilter
      }));
    };

    hideFilterDropdown = () => {
      this.setState({
        showFilter: false
      });
    };

    onFilterDatesChange = ({ startDate, endDate }) => {
      this.setState({
        startDate,
        endDate
      });

      if (startDate && endDate) {
        this.props.fetchIssues({
          start_date: moment(endDate).toISOString(),
          end_date: moment(startDate).toISOString()
        });
      } else if (!startDate && !endDate) {
        this.props.fetchIssues({ start_date: [], end_date: [] });
      }
    };

    changeFilter = (e, type) => {
      const name = e.target ? e.target.name : e;

      this.setState(
        prevState => {
          let selectedFilters = prevState[type].slice();

          if (selectedFilters.indexOf(name) === -1) {
            selectedFilters.push(name);
          } else {
            selectedFilters = selectedFilters.filter(item => item !== name);
          }

          return { [type]: selectedFilters };
        },
        () => {
          this.props.fetchIssues({ status: this.state.status, priority: this.state.priority });
        }
      );
    };

    render() {
      const { t, toggleStats } = this.props;
      const { status, priority, showFilter, startDate, endDate } = this.state;
      return (
        <div className="page-subheader">
          <button type="button" className="button button-blue button-show-stats" onClick={toggleStats}>
            <span className="icon-stats" />
            {t('statsBtn')}
          </button>

          <div className="filters-wrapper">
            <div className="filters-list">
              {status &&
                            status.map(filter => (
                              <div
                                key={filter}
                                role="presentation"
                                className="filters-list-item"
                                onClick={() => this.changeFilter(filter, 'status')}
                              >
                                {t(filter)} <span />
                              </div>
                            ))}
              {priority &&
                            priority.map(filter => (
                              <div
                                key={filter}
                                role="presentation"
                                className="filters-list-item"
                                onClick={() => this.changeFilter(filter, 'priority')}
                              >
                                {t(filter)} <span />
                              </div>
                            ))}
            </div>

            <FilterSelect
              hide={this.hideFilterDropdown}
              isOpen={showFilter}
              status={status}
              priority={priority}
              onChangeFilter={this.changeFilter}
              startDate={startDate}
              endDate={endDate}
              filterDatesChange={this.onFilterDatesChange}
            >
              <button
                type="button"
                className="button button-inherit btn-filter"
                onClick={this.toggleFilterDropdown}
              >
                <span className="icon-filter" />
                {t('filterBtn')}
              </button>
            </FilterSelect>
          </div>
        </div>
      );
    }
}

Filter.propTypes = {
  t: PropTypes.func.isRequired
};

export { Filter as PureComponent };
export default pure(withTranslation('Issues')(Filter));
