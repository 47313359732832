import {
  SHOP_BESTSELLERS_REQUEST,
  SHOP_BESTSELLERS_SUCCESS,
  SHOP_BESTSELLERS_FAILURE,
  SHOP_PARTNERS_RESTORE,
  SHOP_PARTNERS_SELECT,
  SHOP_PARTNERS_SELECT_ONE,
  SHOP_PARTNERS_DESELECT,
  SHOP_PARTNERS_RESET
} from 'constants/actionTypes';

const initialState = {
  isFetching: false,
  fetchedAt: null,
  items: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_PARTNERS_RESTORE:
    case SHOP_PARTNERS_SELECT:
    case SHOP_PARTNERS_SELECT_ONE:
    case SHOP_PARTNERS_DESELECT:
    case SHOP_PARTNERS_RESET:
      return initialState;

    case SHOP_BESTSELLERS_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case SHOP_BESTSELLERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: action.payload.map(item => ({ ...item, products: item.products.map(product => product.id) }))
      };

    case SHOP_BESTSELLERS_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
