import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

const QuantityInput = ({ quantity, onChange }) => (
  <input
    className="cart-product-amount"
    type="number"
    value={Number.isNaN(quantity) ? '' : quantity}
    onChange={({ target: { value } }) => onChange(parseInt(value, 10))}
  />
);

QuantityInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export { QuantityInput as PureComponent };
export default pure(QuantityInput);
