import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { groupServiceEventsBy } from 'selectors/serviceEvents';
import { fetchServiceEventDefinitionEvents } from 'actions/serviceEvents';
import { Loader, ServiceEvent } from 'common';

export class Events extends Component {
  constructor(props) {
    super(props);

    this.fetchNext = this.fetchNext.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.fetchData();
    }
  }

  fetchData(nextPage) {
    const { dispatch, id, currentPage, limit } = this.props;

    dispatch(
      fetchServiceEventDefinitionEvents(id, {
        order_by: 'desc',
        page: nextPage || currentPage,
        limit
      })
    );
  }

  fetchNext() {
    const { currentPage } = this.props;

    this.fetchData(currentPage + 1);
  }

  render() {
    const { isFetching, items, hasMore, t, taskGroups } = this.props;
    const weeks = items && Object.keys(items).reverse();

    return (
      <div className="modal-event-list">
        {weeks &&
          weeks.length > 0 &&
          weeks.map(week => (
            <div key={`week-${week}`}>
              <div className="modal-headline">{t('week', { week })}</div>

              {items[week].map(item => (
                <ServiceEvent key={item.id} item={item} taskGroups={taskGroups} />
              ))}
            </div>
          ))}

        {weeks && !weeks.length && <p>{t('no-events')}</p>}

        <Loader isLoading={isFetching} />

        {hasMore && (
          <button type="button" className={`button-more ${isFetching ? 'disabled' : ''}`} onClick={this.fetchNext}>
            {t('more')}
          </button>
        )}
      </div>
    );
  }
}

Events.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  limit: PropTypes.number,
  isFetching: PropTypes.bool,
  items: PropTypes.object,
  currentPage: PropTypes.number,
  hasMore: PropTypes.bool,
  taskGroups: PropTypes.array,
  t: PropTypes.func.isRequired
};

Events.defaultProps = {
  limit: 10
};

const mapStateToProps = () => {
  const getServiceEvents = groupServiceEventsBy('ww');

  return (state, props) => {
    const {
      serviceEvents: {
        list: { [props.id]: { isFetching, currentPage = 1, hasMore } = {} }
      }
    } = state;

    return {
      isFetching,
      items: getServiceEvents(state, props),
      currentPage,
      hasMore
    };
  };
};

export default withTranslation('Modal/ServiceEventDefinition')(connect(mapStateToProps)(Events));
