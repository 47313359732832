import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOCATION_QUERY_SED } from 'constants/location';
import ServiceEventDefinition from '.';

const ServiceEventDefinitionLink = props => {
  const { item, pathname } = props;

  return (
    <Link className="sed-link" to={`${pathname}?${LOCATION_QUERY_SED}=${item.id}`}>
      <ServiceEventDefinition {...props} />
    </Link>
  );
};

ServiceEventDefinitionLink.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default connect(mapStateToProps)(ServiceEventDefinitionLink);
