import {
  CUSTOMER_BILLING_ADDRESS_EDIT_REQUEST,
  CUSTOMER_BILLING_ADDRESS_EDIT_SUCCESS,
  CUSTOMER_BILLING_ADDRESS_EDIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (companyName, street, postalCode, city, countryCode) =>
  authCall({
    shouldFetch: s => !s.customer.shoppingBillingAddress.isChanging,
    types: [
      CUSTOMER_BILLING_ADDRESS_EDIT_REQUEST,
      CUSTOMER_BILLING_ADDRESS_EDIT_SUCCESS,
      CUSTOMER_BILLING_ADDRESS_EDIT_FAILURE
    ],
    endpoint: '/user/customer/billing-address',
    method: 'PUT',
    body: {
      company_name: companyName,
      street,
      postal_code: postalCode,
      city,
      country_code: countryCode
    }
  });
