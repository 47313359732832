import { GET_OFFER_REQUEST, GET_OFFER_SUCCESS, GET_OFFER_FAILURE } from 'constants/actionTypes';
import { apiCall } from 'api';

const getOfferByToken = token =>
  apiCall({
    types: [GET_OFFER_REQUEST, GET_OFFER_SUCCESS, GET_OFFER_FAILURE],
    endpoint: `/offer/view/${token}`,
    meta: { token },
    method: 'GET'
  });

export default getOfferByToken;
