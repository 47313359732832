import React from 'react';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import { Search, CartHeader, MissingProductLink } from '.';

import './Header.less';

const Header = ({ brand }) => (
  <div className="header">
    <div className="search col-12">
      <Search />
    </div>
    {brand !== 'wework' && (
      <div className="missing-product-con">
        <MissingProductLink />
      </div>
    )}
    <div className="header-cart absolute">
      <CartHeader />
    </div>
  </div>
);

const mapStateToProps = ({ brand }) => ({
  brand
});

export default pure(
  connect(
    mapStateToProps,
    null
  )(Header)
);
