import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changeBillingAddress } from 'actions/customer';
import { Input } from 'common/Form';

import './BillingAddressForm.less';

export const createState = state => ({
  street: state.street || '',
  postalCode: state.postalCode || '',
  city: state.city || '',
  customer: state.customer || { billing_address: { contact: {} } }
});

export class BillingAddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.propsHaveChanged(nextProps)) {
      this.setState(createState(nextProps));
    }
  }

  get hasChanged() {
    return this.propsHaveChanged(this.state);
  }

  propsHaveChanged(nextProps) {
    const isAddressChanged =
      this.props.street !== nextProps.street ||
      this.props.postalCode !== nextProps.postalCode ||
      this.props.city !== nextProps.city;
    let isContactChanged = false;
    if (this.props.customer && this.props.customer.billing_address && this.props.customer.billing_address.contact) {
      isContactChanged =
        this.props.customer.billing_address.contact.firstname !==
          nextProps.customer.billing_address.contact.firstname ||
        this.props.customer.billing_address.contact.lastname !== nextProps.customer.billing_address.contact.lastname ||
        this.props.customer.billing_address.contact.mobile !== nextProps.customer.billing_address.contact.mobile ||
        this.props.customer.billing_address.contact.email !== nextProps.customer.billing_address.contact.email;
    } else if (nextProps.customer && nextProps.billing_address && nextProps.billing_address.contact) {
      isContactChanged = true;
    }

    return isAddressChanged || isContactChanged;
  }

  handleSubmit(e) {
    e.preventDefault();

    const billingAddressData = {
      street: this.state.street,
      postal_code: this.state.postalCode,
      city: this.state.city,
      firstname: this.state.customer.billing_address.contact && this.state.customer.billing_address.contact.firstname,
      lastname: this.state.customer.billing_address.contact && this.state.customer.billing_address.contact.lastname,
      country_code: this.props.country,
      mobile: this.state.customer.billing_address.contact && this.state.customer.billing_address.contact.mobile,
      email: this.state.customer.billing_address.contact && this.state.customer.billing_address.contact.email
    };

    if (this.hasChanged) this.props.onSubmit(billingAddressData);
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name]) {
      this.setState({ [name]: value });
    }
    if (['firstname', 'lastname', 'mobile', 'email'].indexOf(name) !== -1) {
      const customer = {
        billing_address: {
          contact: {
            ...this.state.customer.billing_address.contact,
            [name]: value
          }
        }
      };
      this.setState(prevState => ({
        ...prevState,
        customer
      }));
    }
  }

  render() {
    const { isSubmitting, t, country } = this.props;
    const { street, city, postalCode, customer } = this.state;

    return (
      <div className="company-address">
        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <Input
              autoFocus
              value={street}
              label={t('street')}
              name="street"
              onChange={this.handleChange}
              parentClass="form-group"
              required
            />

            <div className="form-group flex-row">
              <Input
                value={postalCode}
                label={t('postal-code')}
                name="postalCode"
                onChange={this.handleChange}
                parentClass="company-zip row-group"
                required
              />

              <Input
                value={city}
                label={t('city')}
                name="city"
                onChange={this.handleChange}
                parentClass="company-city row-group"
                required
              />
            </div>
            <div className="input form-group">
              <p className="company-country">{t(country)}</p>
            </div>
            <div className="form-group flex-row">
              <Input
                className="company-person_firstname"
                value={customer.billing_address.contact && customer.billing_address.contact.firstname}
                label={t('person')}
                name="firstname"
                onChange={this.handleChange}
                parentClass="row-group"
                placeholder="Vorname"
                required
              />
              <Input
                className="company-person_lastname"
                value={customer.billing_address.contact && customer.billing_address.contact.lastname}
                name="lastname"
                onChange={this.handleChange}
                parentClass="row-group"
                placeholder="Nachname"
                required
              />
            </div>
            <Input
              value={customer.billing_address.contact && customer.billing_address.contact.mobile}
              label={t('cellphone')}
              name="mobile"
              onChange={this.handleChange}
              parentClass="form-group"
              required
            />
            <Input
              value={customer.billing_address.contact && customer.billing_address.contact.email}
              label={t('email')}
              name="email"
              onChange={this.handleChange}
              parentClass="form-group"
              required
            />
            <button type="submit" disabled={!this.hasChanged} className={isSubmitting ? 'loading-light' : ''}>
              {t('save')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

BillingAddressForm.propTypes = {
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  customer: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  current: { customer },
  customer: {
    billingAddress: { isChanging: isSubmitting }
  }
}) => {
  const { street, postal_code: postalCode, city, country_code: country } = (customer && customer.billing_address) || {};
  return { customer, street, postalCode, city, country, isSubmitting };
};

const mapDispatchToProps = {
  onSubmit: changeBillingAddress
};

export default withTranslation('Settings/Customer/BillingAddressForm')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BillingAddressForm)
);
