import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { DocumentTitle } from 'common';
import { recoverPassword } from 'actions/password';

import './RecoverPassword.less';

export class RecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.emailInput.value, this.recaptcha && this.recaptcha.getValue());
  }

  render() {
    const { isRecovering, isCaptchaRequired, t } = this.props;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="password-view">
          <p className="headline">{t('headline')}</p>

          <form onSubmit={this.handleSubmit}>
            <fieldset disabled={isRecovering}>
              <div className="form-group">
                <input
                  type="email"
                  ref={ref => {
                    this.emailInput = ref;
                  }}
                  className="form-control"
                  placeholder={t('email')}
                  required
                />
              </div>

              {isCaptchaRequired && (
                <ReCAPTCHA
                  ref={ref => {
                    this.recaptcha = ref;
                  }}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={() => {}}
                />
              )}

              <button type="submit" className={`button relative ${isRecovering ? 'loading' : ''}`}>
                {t('send')}
              </button>
            </fieldset>
          </form>

          <Link className="password-back" to="/">
            {t('go-back')}
          </Link>
        </div>
      </DocumentTitle>
    );
  }
}

RecoverPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isRecovering: PropTypes.bool,
  isCaptchaRequired: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  password: {
    recover: { isRecovering, isCaptchaRequired }
  }
}) => ({ isRecovering, isCaptchaRequired });

const mapDispatchToProps = {
  onSubmit: recoverPassword
};

export default withTranslation('Public/RecoverPassword')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecoverPassword)
);
