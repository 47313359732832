import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { Modal } from 'components/HOC';
import { fetchServiceEvent, rateServiceEvent } from 'actions/serviceEvents';

import './ServiceEventRating.less';

const createState = ({ item: { rating_value: ratingValue = 0, rating_message: ratingMessage = '' } = {} }) => ({
  ratingValue,
  ratingMessage,
  hoverValue: 0
});

export class ServiceEventRating extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.item) this.props.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isRated && nextProps.isRated) {
      this.props.closeModal();
      return;
    }

    if (this.props.item !== nextProps.item) {
      this.setState(createState(nextProps));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item && !this.props.item) {
      this.props.fetchData();
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { item, onSubmit } = this.props;
    const { ratingValue, ratingMessage } = this.state;

    onSubmit(item.id, ratingValue, ratingMessage);
  }

  renderContent() {
    const { item, t, closeModal, isRating } = this.props;

    if (!item) return null;

    const { ratingValue, ratingMessage, hoverValue } = this.state;
    const starValue = hoverValue || ratingValue;
    const ratingStars = [1, 2, 3, 4, 5];
    const isDisabled = !ratingValue && !ratingMessage;

    return (
      <div className="rating-modal-inner-wrapper">
        <div className="se-modal-header">
          <div className="title">{t('title')}</div>
          <div className="subtitle">{t('subtitle')}</div>
        </div>

        <div className={`se ${item.status}`}>
          <div className="se-date">
            <div className="se-day">{item.start_date.format('D')}</div>
            <div className="se-month">{item.start_date.format('MMM')}</div>
            <span className={`se-circle ${item.type}`} />
          </div>
          <div className="se-body ellipsis">
            <span className="headline">{item.name}</span>
          </div>
        </div>

        <div className="rate-se">{t(`rate-type-${item.type}`) || t('rate-type-default')}</div>

        <form onSubmit={this.handleSubmit} className="rating-box-modal">
          <fieldset disabled={isRating}>
            <div className="rating-stars">
              {ratingStars.map(value => (
                <button
                  name="ratingValue"
                  value={value}
                  type="button"
                  key={`star-${value}`}
                  className={`rating-star ${starValue >= value ? 'active' : ''}`}
                  onClick={this.handleChange}
                  onMouseEnter={() => this.handleChange({ target: { name: 'hoverValue', value } })}
                  onMouseLeave={() => this.handleChange({ target: { name: 'hoverValue', value: 0 } })}
                >
                  &nbsp;
                </button>
              ))}
            </div>

            <div className="rate-explanation">
              (1) {t('rating-min')} - (5) {t('rating-max')}
            </div>

            <textarea
              name="ratingMessage"
              value={ratingMessage || ''}
              onChange={this.handleChange}
              className="rating-message"
              rows="3"
              placeholder={t('rating-message')}
            />

            <button
              className={`rating-submit ${isDisabled ? 'disabled' : ''} ${isRating ? 'loading' : ''}`}
              type="submit"
            >
              {t('rate')}
            </button>

            <button className="rating-cancel" onClick={closeModal} type="button">
              {t('cancel')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return <DocumentTitle title={(item && item.name) || '...'}>{this.renderContent()}</DocumentTitle>;
  }
}

ServiceEventRating.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  isLoading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  isRating: PropTypes.bool,
  isRated: PropTypes.bool,
  fetchData: PropTypes.func,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  item: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = ({ serviceEvents: { entities, single, rate } }, { id }) => ({
  isLoading: !!(single[id] && single[id].isFetching),
  isRating: !!(rate[id] && rate[id].isRating),
  isRated: !!(rate[id] && rate[id].isRated),
  item: entities[id]
});

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchData: () => dispatch(fetchServiceEvent(id)),
  onSubmit: (...args) => dispatch(rateServiceEvent(...args))
});

export { ServiceEventRating as PureComponent };
export default withTranslation('Modal/ServiceEventRating')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal('Service Event Rating', { className: 'rating-modal modal-flex' })(ServiceEventRating))
);
