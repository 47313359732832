import { createSelector } from 'reselect';
import { I18N_DEFAULT_LANGUAGE } from '../../constants/i18n';

const createCategory = (category, categories) => {
  if (!category || !category.parent_id) return category;

  return { ...category, parent: createCategory(categories[category.parent_id], categories) };
};

const getCategoryId = (state, props) => props.categoryId;
const getCategories = state =>
  state.shop.categories.entities[state.i18n.language] || state.shop.categories.entities[I18N_DEFAULT_LANGUAGE];

export default createSelector([getCategoryId, getCategories], (categoryId, categories) => {
  if (!categories) return null;

  const category = categories[categoryId];
  if (!category) return false;

  return createCategory(category, categories);
});
