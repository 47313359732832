import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { DocumentTitle } from 'common';
import { fetchMembers, createMember, deleteMember, updateMember } from 'actions/members';
import { MemberForm, MemberSidebar } from '.';

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      showForm: false,
      searchValue: '',
      member: null,
      members: [],
      filteredMembers: [],
      searchEmpty: true
    };
  }

  componentDidMount() {
    this.props.fetchMembers();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.members !== state.members) {
      return {
        members: props.members,
        filteredMembers: props.members,
        searchValue: '',
        member: null,
        showForm: false,
        isEdit: false
      };
    }

    return null;
  }

  editMember = member => {
    this.setState(prevState => ({
      ...prevState,
      isEdit: true,
      showForm: true,
      member
    }));
  };

  onCancelEdit = () => {
    this.setState({
      isEdit: false,
      showForm: false,
      member: null
    });
  };

  submitForm = member => {
    if (member && member.id) {
      this.props.updateMember(member).then(() => {
        this.onCancelEdit();
      });
    } else {
      this.props.createMember(member).then(() => {
        this.props.fetchMembers();
        this.onCancelEdit();
      });
    }
  };

  searchHandler = event => {
    const searchValue = event.target.value.toLowerCase();
    const { members } = this.state;

    if (!members.length) return;

    this.setState({
      searchValue,
      filteredMembers: members.filter(mem => mem.fullname.toLowerCase().match(searchValue)),
      searchEmpty: !event.target.value || event.target.value.length <= 0
    });
  };

  onDeleteMember = memberId => {
    this.props.deleteMember(memberId);
    this.onCancelEdit();
  };

  showMemberForm = () => {
    this.setState({
      showForm: true,
      member: null
    });
  };

  render() {
    const { filteredMembers, member, isEdit, showForm, searchValue, searchEmpty } = this.state;

    return (
      <DocumentTitle title="Members">
        <div className={classNames('members-page content-area', { showForm })}>
          <div className="page-content">
            <MemberForm
              member={member}
              submitForm={this.submitForm}
              isEdit={isEdit}
              deleteMember={this.onDeleteMember}
              cancelEdit={this.onCancelEdit}
              showForm={showForm}
            />

            <MemberSidebar
              members={filteredMembers}
              showForm={showForm}
              showMemberForm={this.showMemberForm}
              searchHandler={this.searchHandler}
              searchValue={searchValue}
              editMember={this.editMember}
              searchEmpty={searchEmpty}
            />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.members.isFetching,
  members: state.members.items
});

const mapDispatchToProps = dispatch => ({
  fetchMembers: () => dispatch(fetchMembers()),
  createMember: member => dispatch(createMember(member)),
  updateMember: member => dispatch(updateMember(member)),
  deleteMember: id => dispatch(deleteMember(id))
});

export { Members as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Members);
