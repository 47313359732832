import { createSelector } from 'reselect';

const getLanguage = state => state.i18n.language;
const getItems = (state, { dataKey }) => state.contentful[dataKey] && state.contentful[dataKey].items;
const isImage = data => data.sys && data.sys.type === 'Link';

export default () =>
  createSelector(
    [getLanguage, getItems],
    (language, items) => {
      if (!items) return null;

      return items.map(item => ({
        ...item,
        fields: Object.keys(item.fields).reduce(
          (fields, fieldName) => ({
            ...fields,
            [fieldName]: isImage(item.fields[fieldName]) ? item.fields[fieldName].en : item.fields[fieldName][language]
          }),
          {}
        )
      }));
    }
  );
