import {
  SHOP_CART_PAYMENT_SUCCESS,
  SHOP_CART_ORDER_SUCCESS,
  SHOP_CART_ORDER_FAILURE
} from 'constants/actionTypes';
import { PAYMENT_METHOD_BILLING } from 'constants/paymentMethods';
import { ORDER_PAYMENT_BILLING_NOT_ALLOWED } from 'constants/errors';
import { hasError } from 'utils';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    case SHOP_CART_ORDER_FAILURE:
      if (state.includes(PAYMENT_METHOD_BILLING) && hasError(action.payload, ORDER_PAYMENT_BILLING_NOT_ALLOWED)) {
        return state.filter(method => method !== PAYMENT_METHOD_BILLING);
      }

      return state;

    case SHOP_CART_PAYMENT_SUCCESS:
      return action.payload.methods;

    default:
      return state;
  }
};
