import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Category } from '.';

export class MoreButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show() {
    if (!this.state.isActive) {
      this.setState({
        isActive: true
      });
    }
  }

  hide() {
    if (this.state.isActive) {
      this.setState({
        isActive: false
      });
    }
  }

  render() {
    const { items, forceVisible, t } = this.props;
    const { isActive } = this.state;

    if ((!items || !items.length) && !forceVisible) return null;

    return (
      <div
        className="shop-nav-category-more dropdown-container bottom arrow-left"
        onMouseEnter={this.show}
        onMouseLeave={this.hide}
      >
        <div className="shop-category-link-more">{t('more')}</div>

        <ul className={`shop-nav-subcategory dropdown-list ${isActive ? 'active' : ''}`}>
          {items && items.map(item => <Category key={item.id} item={item} onClick={this.hide} level={1} isMore />)}
        </ul>
      </div>
    );
  }
}

MoreButton.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.array,
  forceVisible: PropTypes.bool
};

export default pure(withTranslation('Shop/CategoryMenu')(MoreButton));
