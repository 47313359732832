import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { resetPartners } from 'actions/shop/partners';

const ResetButton = ({ isDisabled, onClick, t }) => (
  <button type="button" className={`button-soft ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
    {t('reset')}
  </button>
);

ResetButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    partners: { partnerIds }
  }
}) => ({
  isDisabled: partnerIds.length === 0
});

const mapDispatchToProps = {
  onClick: resetPartners
};

export default withTranslation('Shop/PartnerFilter')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetButton)
);
