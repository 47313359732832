import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SHOPPING_CART_STEP_PREVIEW,
  SHOPPING_CART_STEP_CHECKOUT,
  SHOPPING_CART_STEP_PAYMENT,
  SHOPPING_CART_STEP_SUCCESS
} from 'constants/shoppingCart';
import { Transition } from 'common';
import { hideCart } from 'actions/location';
import { translateEditing } from './HOC';
import { Preview, Checkout, Payment, Success } from '.';

import './Cart.less';

const Cart = ({ step, isEditing, onClick, t }) => (
  <div className="cart-overlay">
    <div className="cart-bg">
      <button type="button" className="cart-hide" onClick={onClick}>
        {t('close-cart')}
      </button>
    </div>

    <div className={`cart ${isEditing ? 'edit' : ''}`}>
      <Transition name="cart-animation" appearTimeout={500} enterTimeout={500} leaveTimeout={300}>
        {step === SHOPPING_CART_STEP_PREVIEW && <Preview />}
        {step === SHOPPING_CART_STEP_CHECKOUT && <Checkout />}
        {step === SHOPPING_CART_STEP_PAYMENT && <Payment />}
        {step === SHOPPING_CART_STEP_SUCCESS && <Success />}
      </Transition>
    </div>
  </div>
);

Cart.propTypes = {
  step: PropTypes.string,
  isEditing: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

Cart.defaultProps = {
  step: SHOPPING_CART_STEP_PREVIEW,
  isEditing: false
};

const mapStateToProps = ({
  shop: {
    cart: { step, cart }
  }
}) => ({
  step,
  isEditing: !!cart.sed
});

const mapDispatchToProps = {
  onClick: hideCart
};

export { Cart as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Cart)
);
