import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

const Member = ({ fullName, firstname, lastname, userName, photo, editMember }) => (
  <div className="member" role="presentation" onClick={editMember}>
    <div
      className={classNames('member-photo', {
        hasPhoto: photo && photo.length > 0,
        noPhoto: !photo || photo.length === 0
      })}
      style={{ backgroundImage: `url(${photo})` }}
    >
      {`${firstname && firstname.substring(0, 1)}${lastname && lastname.substring(0, 1)}`}
    </div>

    <div className="member-detail">
      <div className="name">{fullName}</div>
      <div className="link">{userName}</div>
    </div>
  </div>
);

export default withTranslation('Members')(Member);
