import {
  SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST,
  SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS,
  SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default serviceEventDefinitionId =>
  authCall({
    shouldFetch: ({
      serviceEventDefinitions: {
        products: { [serviceEventDefinitionId]: { isFetching, fetchedAt } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { serviceEventDefinitionId },
    types: [
      SERVICE_EVENT_DEFINITION_PRODUCTS_REQUEST,
      SERVICE_EVENT_DEFINITION_PRODUCTS_SUCCESS,
      SERVICE_EVENT_DEFINITION_PRODUCTS_FAILURE
    ],
    endpoint: `/user/service-event-definitions/${serviceEventDefinitionId}/products`,
    method: 'GET'
  });
