import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Stepbar from './Stepbar';
import Banner from './Banner';

class FormWrapper extends PureComponent {

    static propTypes = {
      children: PropTypes.node.isRequired,
      step: PropTypes.number.isRequired,
      t: PropTypes.func.isRequired
    };

    render() {
      const { children, step, t } = this.props;

      return (
        <div className="pad cleaning-services">
          <Stepbar step={step} />
          <Banner
            title={t('service-cleaning-title')}
            description={t('service-cleaning-description')}
          />
          <div className="cleaning-service__box">
            <div className="cleaning-service__content">
              <div className="cleaning-service__left">
                {children}
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default withTranslation('Services/Cleaning')(FormWrapper);
