import jwt from 'jwt-simple';
import { getJSON } from 'utils';
import { X_AUTH_TOKEN } from 'constants/headers';
import { getToken } from '../auth';
import { apiCall } from '.';

let promise;
const getPromise = dispatch => {
  if (promise) return promise;

  promise = dispatch(getToken()).then(response => {
    promise = null;
    return getJSON(response);
  });

  return promise;
};

const addToken = (options, jwtToken) => ({ ...options, headers: { ...options.headers, [X_AUTH_TOKEN]: jwtToken } });

export default options => (dispatch, getState) => {
  const {
    auth: {
      data: { jwtToken }
    }
  } = getState();

  if (jwtToken) {
    try {
      const decoded = jwt.decode(jwtToken, null, true);

      if (decoded && decoded.exp && decoded.exp > new Date().getTime() / 1000) {
        return dispatch(apiCall(addToken(options, jwtToken)));
      }
    } catch (e) {}
  }

  return getPromise(dispatch).then(json => dispatch(apiCall(addToken(options, json.token))));
};
