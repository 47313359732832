import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LOCATION_QUERY_SED } from 'constants/location';

const ServiceEvent = ({ item, pathname }) => (
  <Link
    className={`timeline-item ${item.type}`}
    to={`${pathname}?${LOCATION_QUERY_SED}=${item.definition_id}#${item.id}`}
  >
    {item.name}
    <span className="timeline-item-time">{item.start_date.format('HH:mm')}</span>
  </Link>
);

ServiceEvent.propTypes = {
  item: PropTypes.object.isRequired
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default connect(mapStateToProps)(ServiceEvent);
