import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toggleFilter } from 'actions/serviceEvents/filters';
import { Switch } from 'common';

export const ToggleButton = ({ t, item, onToggle }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleChange = ({ target: { checked } }) => {
    setIsSelected(checked);
    onToggle(item, checked);
  };

  return (
    <div className="dashboard-filter-single filter-toggle">
      <Switch
        alignCheckboxLeft={false}
        checked={isSelected}
        className="dashboard-filter-choose"
        id={`filter-${item.value}`}
        onChange={handleChange}
      >
        <div className={`filter-color filter-color-${item.value}`} />
        <div className="dashboard-filter-container">
          <p className="dashboard-filter-name">{t(`filter-${item.value}`)}</p>
        </div>
        <div className={`filter-logo ${item.value}`} />
      </Switch>
    </div>
  );
};

ToggleButton.propTypes = {
  item: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onToggle: toggleFilter
};

export default withTranslation('Dashboard/ServiceEventFilter')(
  connect(
    null,
    mapDispatchToProps
  )(ToggleButton)
);
