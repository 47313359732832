import { CONTENTFUL_REQUEST, CONTENTFUL_SUCCESS, CONTENTFUL_FAILURE } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case CONTENTFUL_REQUEST: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: true, fetchedAt: null } };
    }

    case CONTENTFUL_SUCCESS: {
      const {
        meta: { dataKey }
      } = action;

      return {
        ...state,
        [dataKey]: { ...state[dataKey], isFetching: false, fetchedAt: new Date(), items: action.payload.items }
      };
    }

    case CONTENTFUL_FAILURE: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: false } };
    }

    default:
      return state;
  }
};
