import React from 'react';
import { pure } from 'recompose';

const TabTitle = props => (
  <div className="tab-title">
    {props.children}
  </div>
);

export default pure(TabTitle);
