import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pure } from 'recompose';
import { RRule, getRRuleWeekday } from 'utils';

class Monthly extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { value } }) {
    const { dueDate, onChange } = this.props;
    const bysetpos = parseInt(value, 10) || 0;
    let rruleOptions = { freq: RRule.MONTHLY };

    if (bysetpos !== 0) {
      rruleOptions = { ...rruleOptions, byweekday: [getRRuleWeekday(moment(dueDate).format('E'))], bysetpos };
    }

    onChange(rruleOptions);
  }

  render() {
    const {
      dueDate,
      rruleOptions: { bysetpos = 0 },
      t
    } = this.props;
    const dayOfMonth = moment(dueDate).format('D');
    const dayName = moment(dueDate).format('dddd');

    let weekdayPosition;
    if (dayOfMonth < 8) weekdayPosition = 1;
    else if (dayOfMonth < 15) weekdayPosition = 2;
    else if (dayOfMonth < 22) weekdayPosition = 3;
    else if (dayOfMonth < 29) weekdayPosition = 4;
    else weekdayPosition = -1;

    return (
      <div className="col-group shipping-date-details month">
        <select className="shipping-date-month cart-drop-dark" value={bysetpos} onChange={this.handleChange}>
          <option dangerouslySetInnerHTML={{ __html: t('every-day-month', { dayOfMonth }) }} value="0" />
          <option
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: t(`every-n-weekday-month${weekdayPosition < 0 ? '-last' : ''}`, {
                dayName,
                weekdayPosition
              })
            }}
            value={weekdayPosition}
          />
        </select>
      </div>
    );
  }
}

Monthly.propTypes = {
  dueDate: PropTypes.object.isRequired,
  rruleOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export { Monthly as PureComponent };
export default pure(Monthly);
