import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setComment } from 'actions/shop/cart';
import { translateEditing } from '../../../HOC';

class Comment extends Component {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur() {
    this.props.onChange(this.input.value.trim());
  }

  render() {
    const { comment, isDisabled, t } = this.props;

    return (
      <div className={`shipping ${isDisabled ? 'disabled' : ''}`}>
        <div className="cart-title">{t('comment')}</div>

        <textarea
          ref={ref => {
            this.input = ref;
          }}
          className="cart-drop cart-textarea"
          defaultValue={comment}
          placeholder={t('comment-placeholder')}
          onBlur={this.handleBlur}
          rows="1"
        />
      </div>
    );
  }
}

Comment.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  comment: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isOrdering, isCancelling, isDeleting }
  }
}) => ({
  isDisabled: isOrdering || isCancelling || isDeleting
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onChange: comment => dispatch(setComment(id, comment))
});

export { Comment as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Comment)
);
