import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';

class MobileMenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };

    this.toggleClick = this.toggleClick.bind(this);
  }

  toggleClick() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened
    }));
  }

  render() {
    const { item, onClick } = this.props;
    const { isOpened } = this.state;
    const hasChildren = item.children && item.children.length > 0;

    return (
      <li className="shop-nav-mobile-category dropdown-container">
        <Link
          className={`shop-nav-mobile-subitem
                        ${hasChildren ? 'with-children' : ''}
                        ${isOpened ? 'is-open' : ''}
                    `}
          to={`/shop/category/${item.id}/${item.slug}`}
          onClick={onClick}
        >
          <TextTruncate containerClassName="category-name" line={1} truncateText="…" text={item.name} />
        </Link>

        {hasChildren && <button className="submenu-toggle" type="button" onClick={this.toggleClick} />}

        {hasChildren && (
          <ul className={`shop-nav-mobile-sublist ${isOpened ? 'is-open' : ''}`}>
            {item.children.map(subItem => (
              <MobileMenuItem key={subItem.id} item={subItem} onClick={onClick} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

MobileMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default pure(MobileMenuItem);
