import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { noteServiceEvent } from 'actions/serviceEvents';

import './NoteBox.less';

class NoteBox extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { dispatch, item } = this.props;
    const note = this.notemessageInput.value.trim();

    dispatch(noteServiceEvent(item.id, note));
  }

  handleClickOutside() {
    this.props.closeBox();
  }

  render() {
    const { item, isNoting, t } = this.props;
    const { body } = item.customer_note || {};

    return (
      <form className="note-box" onSubmit={this.handleSubmit}>
        <fieldset disabled={isNoting}>
          <textarea
            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
            className="note-message"
            ref={ref => {
              this.notemessageInput = ref;
            }}
            defaultValue={body}
            rows="3"
            placeholder={t('body')}
          />
          <button className="note-submit" type="submit">
            {t('send')}
          </button>
        </fieldset>
      </form>
    );
  }
}

NoteBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isNoting: PropTypes.bool,
  item: PropTypes.object.isRequired,
  closeBox: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { note } }, { item: { id } }) => ({
  isNoting: !!(note[id] && note[id].isNoting)
});

export default withTranslation('Common/ServiceEvent/NoteBox')(connect(mapStateToProps)(onClickOutside(NoteBox)));
