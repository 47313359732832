import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { ServiceEventFilter } from 'components/Dashboard';

const Header = ({
  t,
  selectedWeek,
  selectedDay,
  startDate,
  endDate,
  setSelectedWeek,
  setDaySelectedThisWeek,
  fetchData
}) => {
  const prevWeek = selectedWeek.clone().subtract(1, 'week');
  const nextWeek = selectedWeek.clone().add(1, 'week');
  const now = moment(new Date().getTime());

  const handleWeekClick = selectedWeekArgs => {
    const daySelected = moment(selectedDay, 'dddd, MMMM Do YYYY').week();
    const weekSelected = selectedWeekArgs.week();

    const isThisWeek = daySelected === weekSelected;

    setSelectedWeek(selectedWeekArgs);
    setDaySelectedThisWeek(isThisWeek);
    fetchData(1, selectedWeekArgs);
  };

  return (
    <div className="modul__header">
      <span>{t('title')}</span>
      <div className="modul__header-buttongroup">
        <span className="modul__header-date">
          {startDate.format('DD MMM')} — {endDate.format('DD MMM YYYY')}
        </span>
        <button
          type="button"
          onClick={() => handleWeekClick(prevWeek)}
          className={`modul__header-button left ${startDate < now ? 'disabled' : ''}`}
          title={prevWeek.format('ddd, DD.MM.YYYY')}
        />
        <button
          type="button"
          onClick={() => handleWeekClick(nextWeek)}
          className="modul__header-button right"
          title={nextWeek.format('ddd, DD.MM.YYYY')}
        />
        <ServiceEventFilter startDate={startDate} endDate={endDate} />
      </div>
    </div>
  );
};

export default withTranslation('Dashboard/Schedule')(Header);
