import React from 'react';
import { pure } from 'recompose';
import { Pagination } from 'common';
import { Issue } from '../..';

import './IssueList.less';

const IssuesList = ({
  issues,
  numberOfItems,
  showPerPage,
  handlePageChange,
  members,
  updateIssue,
  statusesOptions,
  prioritiesOptions
}) => (
  <div className="issues-list-wrapper">
    <div className="issues-list">
      {issues &&
        issues.length > 0 &&
        issues.map(issue => (
          <Issue
            key={issue.id}
            issue={issue}
            members={members}
            updateIssue={updateIssue}
            statusesOptions={statusesOptions}
            prioritiesOptions={prioritiesOptions}
          />
        ))}
    </div>

    {numberOfItems > showPerPage && (
      <div className="orders-list-pagination">
        <Pagination handlePageChange={handlePageChange} numberOfItems={numberOfItems} showItemsPerPage={showPerPage} />
      </div>
    )}
  </div>
);

export { IssuesList as PureComponent };
export default pure(IssuesList);
