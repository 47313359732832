import React from 'react';
import PropTypes from 'prop-types';

export default class ConditionalContent extends React.Component {

    static propTypes = {
      isVisible: PropTypes.bool
    };

    state = {};

    render() {
      const { isVisible, children, hideOnly } = this.props;
      const style = {};

      if (!isVisible) {
        if (!hideOnly) {
          return null;
        }
        style.display = 'none';
      }

      return (
        <div style={style}>
          { children }
        </div>
      );
    }

}
