import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetIntensive, resetIntensiveObject } from 'actions/serviceEvents';
import IntensiveCleaningContainer from './IntensiveCleaningContainer';

class Step3 extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    resetIntensive: PropTypes.func.isRequired,
    resetIntensiveObject: PropTypes.func.isRequired,
    intensiveCleaningSubmit: PropTypes.object.isRequired
  };

  state = {};

  componentDidMount() {
    const { history, intensiveCleaningSubmit } = this.props;

    if (!intensiveCleaningSubmit.isSuccessful) {
      history.push('/cleaning/intensive-cleaning/step-1');
    }
  }

  componentWillUnmount() {
    this.props.resetIntensive();
    this.props.resetIntensiveObject();
  }

  render() {
    const { t } = this.props;

    return (
      <div className="pad cleaning-services">
        <h1 className="cleaning-services__success-headline">{t('success-headline')}</h1>
        <p className="cleaning-services__success-description">{t('success-description')}</p>
        <p className="cleaning-services__success-brand">{t('success-brand')}</p>
      </div>
    );
  }
}

const mapStateToProps = ({ serviceEvents: { intensiveCleaningSubmit } }) => ({
  intensiveCleaningSubmit
});

export default connect(
  mapStateToProps,
  { resetIntensive, resetIntensiveObject }
)(IntensiveCleaningContainer(Step3));
