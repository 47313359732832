import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { currency } from 'components/HOC';
import { LOCATION_QUERY_PRODUCT } from 'constants/location';
import { ProductImage } from '../../../Product/Common';
import { translateEditing } from '../../HOC';

const Product = ({ children, item, t, c, pathname }) => {
  const { product, quantity, sum_net: sumNet } = item;
  const to = `${pathname}?${LOCATION_QUERY_PRODUCT}=${item.product.id}`;

  return (
    <div className="cart-product">
      <Link className="linkable-wrapper" to={to}>
        <ProductImage item={product} />
      </Link>

      <Link className="linkable-wrapper" to={to}>
        <p className="cart-product-title">{product.name}: </p>
      </Link>
      <p className="cart-product-details">
        {quantity} x {c(product.unit_price)}
      </p>

      {children}

      <div className="cart-product-costs">
        <div className="cart-costs">{c(sumNet)}</div>
        <div className="cart-tax">{t('no-tax')}</div>
      </div>
    </div>
  );
};

Product.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

const mapStateToProps = (
  {
    location: { pathname },
    shop: {
      partners: { entities }
    }
  },
  { item: { partner_id: partnerId } }
) => ({
  pathname,
  partner: entities[partnerId]
});

export { Product as PureComponent };
export default currency(translateEditing(connect(mapStateToProps)(Product)));
