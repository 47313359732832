import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import {
  createLocationMiddleware,
  notificationMiddleware,
  unauthorizedMiddleware,
  trackerMiddleware
} from './middlewares';

import appReducer, { root as rootReducer } from './reducers';
import { restoreLanguage, setCurrency } from './actions/i18n';
import { restoreAuth } from './actions/auth';
import { setLocation } from './actions/location';
import persistStore from './store/persistStore';
import observeStore from './store/observeStore';

export default function configureStore(history) {
  const middlewares = [
    thunk,
    createLocationMiddleware(history),
    unauthorizedMiddleware,
    notificationMiddleware,
    trackerMiddleware
  ];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }

  // eslint-disable-next-line no-underscore-dangle
  const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = process.env.NODE_ENV === 'development' && reduxCompose ? reduxCompose : compose;

  const store = createStore(rootReducer(appReducer), composeEnhancers(applyMiddleware(...middlewares)));

  store.dispatch(setLocation(history.location));
  history.listen(location => store.dispatch(setLocation(location)));

  observeStore(store);

  store.dispatch(setCurrency());
  store.dispatch(restoreLanguage());
  store.dispatch(restoreAuth());

  persistStore(store);

  return store;
}
