import {
  PASSWORD_RECOVER_REQUEST,
  PASSWORD_RECOVER_SUCCESS,
  PASSWORD_RECOVER_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';

export default (email, captcha = '') =>
  apiCall({
    shouldFetch: state => !state.password.recover.isRecovering,
    types: [PASSWORD_RECOVER_REQUEST, PASSWORD_RECOVER_SUCCESS, PASSWORD_RECOVER_FAILURE],
    endpoint: '/user/auth/password',
    method: 'POST',
    body: { email, captcha }
  });
