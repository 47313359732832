import {
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default (currentPassword, newPassword) =>
  authCall({
    shouldFetch: state => !state.users.password.change.isChanging,
    types: [USER_PASSWORD_CHANGE_REQUEST, USER_PASSWORD_CHANGE_SUCCESS, USER_PASSWORD_CHANGE_FAILURE],
    endpoint: '/user/users/current/password',
    method: 'PUT',
    body: {
      password: currentPassword,
      new_password: newPassword
    }
  });
