import { parse } from 'query-string';
import { LOCATION_SET } from 'constants/actionTypes';

const withQuery = location => {
  let query = {};

  if (location.search) {
    try {
      query = parse(location.search);
    } catch (e) {}
  }

  return { ...location, query };
};

/**
 * Never use this directly
 * This is use by history
 */
export default location => ({
  type: LOCATION_SET,
  location: withQuery(location)
});
