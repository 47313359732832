import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';
import { ServiceEvent } from '../..';

class Day extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverflowing: false,
      windowHeight: window.innerHeight
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.setOverflowing(this.isOverflowing());
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      this.props.item !== nextProps.item ||
      this.props.serviceEvents !== nextProps.serviceEvents ||
      this.state.windowHeight !== nextState.windowHeight
    ) {
      this.setOverflowingAfterRender = true;
    }
  }

  componentDidUpdate() {
    if (this.setOverflowingAfterRender) {
      this.setOverflowingAfterRender = false;
      this.setOverflowing(this.isOverflowing());
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimer);
    window.removeEventListener('resize', this.handleResize);
  }

  setOverflowing(isOverflowing) {
    if (isOverflowing !== this.state.isOverflowing) {
      this.setState({ isOverflowing });
    }
  }

  setWindowHeight(windowHeight) {
    if (windowHeight !== this.state.windowHeight) {
      this.setState({ windowHeight });
    }
  }

  isOverflowing() {
    const { offsetHeight } = this.DOMNode.children[0];
    const { scrollHeight } = this.DOMNode.children[0];
    const minHeight = 10;

    return scrollHeight - offsetHeight > minHeight;
  }

  handleResize() {
    const windowHeight = window.innerHeight;

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => this.setWindowHeight(windowHeight), 200);
  }

  render() {
    const { item, serviceEvents, numberOfDays } = this.props;
    const { isOverflowing } = this.state;
    const className = classNames(`timeline-wrapper days-${numberOfDays}`, {
      expired: !item.isInMonth,
      today: item.isToday,
      overflow: isOverflowing
    });

    return (
      <div
        className={className}
        ref={ref => {
          this.DOMNode = ref;
        }}
      >
        <div className="timeline-day">
          <span className="timeline-day-date">{item.date.format('D')}</span>

          {serviceEvents &&
            serviceEvents.map(serviceEvent => <ServiceEvent key={serviceEvent.id} item={serviceEvent} />)}
        </div>

        {isOverflowing && <div className="fade-back" />}
      </div>
    );
  }
}

Day.propTypes = {
  item: PropTypes.object.isRequired,
  serviceEvents: PropTypes.array,
  numberOfDays: PropTypes.number
};

export default pure(Day);
