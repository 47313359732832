import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Dropdown from 'react-dropdown';

import './ServiceRequest.less';

class ServiceRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'open'
    };
  }

  componentDidMount() {
    this.setState({
      status: this.props.serviceRequest.status
    });
  }

  onChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;

    this.setState(
      () => ({
        [name]: value
      }),
      () => {
        this.props.updateServiceRequest({
          id: this.props.serviceRequest.id,
          ...this.state
        });
      }
    );
  };

  openServiceRequestDetails = () => {
    const { history, serviceRequest } = this.props;
    history.push(`/service-requests/${serviceRequest.request_id}`);
  };

  render() {
    const { t, serviceRequest, statusesOptions } = this.props;
    const { status } = this.state;
    const image = serviceRequest.image_url ? serviceRequest.image_url : '/images/issues/dummy2.svg';
    const creatorImage = serviceRequest.contact_picture
      ? serviceRequest.contact_picture
      : '/images/icons/spo-viewallusers-icon.svg';

    return (
      <div className={classNames('service-request', { inactive: serviceRequest.status === 'archived' })}>
        <div
          className="image"
          role="presentation"
          onClick={this.openServiceRequestDetails}
          style={{ backgroundImage: `url('${image}')` }}
        />

        <div className="details" role="presentation" onClick={this.openServiceRequestDetails}>
          <div className="title">
            {serviceRequest.subtype} {serviceRequest.type}
            <span className="number">#{serviceRequest.db_id}</span>
          </div>

          <div className="address">
            <span className="loc-icon" />{' '}
            {serviceRequest.location_building && <span>{serviceRequest.location_building}</span>}
            {serviceRequest.location_floor && <span>{`, ${t('floor')} ${serviceRequest.location_floor}`}</span>}
            {serviceRequest.location_rooms && <span>{`, ${t('room')} ${serviceRequest.location_rooms}`}</span>}
          </div>

          <div className="creator">
            <div className="user">
              <span className="user-image" style={{ backgroundImage: `url('${creatorImage}')` }} />
              {serviceRequest.owner_name}
            </div>
            <span className="type">{t(serviceRequest.origin)}</span>
            <span className="time">{moment(serviceRequest.created_date).fromNow()}</span>
          </div>
        </div>

        <div className="actions">
          <Dropdown
            disabled={serviceRequest.status === 'archived'}
            options={statusesOptions}
            onChange={e => this.onChange(e, 'status')}
            value={status}
            className={classNames('Dropdown-round status-dropdown', status.value, {
              inactive: serviceRequest.status === 'archived'
            })}
          />

          <div className="total-price">{serviceRequest.customer_price_net} €</div>
        </div>
      </div>
    );
  }
}

ServiceRequest.propTypes = {
  t: PropTypes.func.isRequired,
  serviceRequest: PropTypes.object.isRequired
};

export { ServiceRequest as PureComponent };
export default pure(withTranslation('ServiceRequests')(withRouter(ServiceRequest)));
