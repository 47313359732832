import { createSelector } from 'reselect';
import { getItemsFromPages } from '../shared';

// props.id = serviceEventDefinitionId
const getPages = (state, { dataKey, id }) =>
  state.serviceEvents.list[dataKey || id] && state.serviceEvents.list[dataKey || id].pages;
const getEntities = state => state.serviceEvents.entities;

export default keyFormat =>
  createSelector([getItemsFromPages(getPages), getEntities], (items, entities) => {
    if (!items) return null;

    return items
      .map(itemId => entities[itemId])
      .filter(item => !!item)
      .reduce((groupedItems, item) => {
        const key = item.start_date.format(keyFormat);
        return { ...groupedItems, [key]: [...(groupedItems[key] || []), item] };
      }, {});
  });
