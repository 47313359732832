import {
  SHOP_CART_REQUEST,
  SHOP_CART_SUCCESS,
  SHOP_CART_FAILURE,
  SHOP_CART_CREATE_REQUEST,
  SHOP_CART_CREATE_SUCCESS,
  SHOP_CART_CREATE_FAILURE,
  SHOP_CART_BUDGET_LIMIT_IGNORE,
  SHOP_CART_DELETE_REQUEST,
  SHOP_CART_DELETE_SUCCESS,
  SHOP_CART_DELETE_FAILURE,
  SHOP_CART_SHOW,
  SHOP_CART_HIDE,
  SHOP_CART_RESET,
  SHOP_CART_PO_NUMBER_CHANGE_REQUEST,
  SHOP_CART_PO_NUMBER_CHANGE_SUCCESS,
  SHOP_CART_PO_NUMBER_CHANGE_FAILURE,
  SHOP_CART_PRODUCT_ADD_REQUEST,
  SHOP_CART_PRODUCT_ADD_SUCCESS,
  SHOP_CART_PRODUCT_ADD_FAILURE,
  SHOP_CART_PRODUCT_REMOVE_REQUEST,
  SHOP_CART_PRODUCT_REMOVE_SUCCESS,
  SHOP_CART_PRODUCT_REMOVE_FAILURE,
  SHOP_CART_COUPON_ADD_REQUEST,
  SHOP_CART_COUPON_ADD_SUCCESS,
  SHOP_CART_COUPON_ADD_FAILURE,
  SHOP_CART_COUPON_REMOVE_REQUEST,
  SHOP_CART_COUPON_REMOVE_SUCCESS,
  SHOP_CART_COUPON_REMOVE_FAILURE,
  SHOP_CART_COMMENT_SET_REQUEST,
  SHOP_CART_COMMENT_SET_SUCCESS,
  SHOP_CART_COMMENT_SET_FAILURE,
  SHOP_CART_SCHEDULE_SET_REQUEST,
  SHOP_CART_SCHEDULE_SET_SUCCESS,
  SHOP_CART_SCHEDULE_SET_FAILURE,
  SHOP_CART_SERVICE_EDIT_REQUEST,
  SHOP_CART_SERVICE_EDIT_SUCCESS,
  SHOP_CART_SERVICE_EDIT_FAILURE,
  SHOP_CART_CHECKOUT_REQUEST,
  SHOP_CART_CHECKOUT_SUCCESS,
  SHOP_CART_CHECKOUT_FAILURE,
  SHOP_CART_CHECKOUT_CANCEL_REQUEST,
  SHOP_CART_CHECKOUT_CANCEL_SUCCESS,
  SHOP_CART_CHECKOUT_CANCEL_FAILURE,
  SHOP_CART_PAYMENT_REQUEST,
  SHOP_CART_PAYMENT_SUCCESS,
  SHOP_CART_PAYMENT_FAILURE,
  SHOP_CART_PAYMENT_CANCEL_SUCCESS,
  SHOP_CART_ORDER_REQUEST,
  SHOP_CART_ORDER_SUCCESS,
  SHOP_CART_ORDER_FAILURE
} from 'constants/actionTypes';
import {
  SHOPPING_CART_STEP_PREVIEW,
  SHOPPING_CART_STEP_CHECKOUT,
  SHOPPING_CART_STEP_PAYMENT,
  SHOPPING_CART_STEP_SUCCESS
} from 'constants/shoppingCart';

const initialState = {
  isFetching: false,
  step: SHOPPING_CART_STEP_PREVIEW,
  cart: {},
  isChanged: false,
  isVisible: false,
  isCreating: false,
  isDeleting: false,
  isEditing: false,
  isCheckingOut: false,
  isCancelling: false,
  isAddingCoupon: false,
  isCouponAdded: false,
  isRemovingCoupon: false,
  isRequestingPayment: false,
  isOrdering: false,
  isBudgetLimitIgnored: false,
  isPoNumberChanging: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_REQUEST:
    case SHOP_CART_PRODUCT_ADD_REQUEST:
    case SHOP_CART_PRODUCT_REMOVE_REQUEST:
    case SHOP_CART_COMMENT_SET_REQUEST:
    case SHOP_CART_SCHEDULE_SET_REQUEST:
      return { ...state, isFetching: true };

    case SHOP_CART_SUCCESS:
      return {
        ...state,
        isFetching: false,
        cart: action.payload,
        isChanged: false,
        step: SHOPPING_CART_STEP_PREVIEW
      };

    case SHOP_CART_PRODUCT_ADD_SUCCESS:
    case SHOP_CART_PRODUCT_REMOVE_SUCCESS:
    case SHOP_CART_COMMENT_SET_SUCCESS:
    case SHOP_CART_SCHEDULE_SET_SUCCESS:
      return { ...state, isFetching: false, cart: action.payload, isChanged: true };

    case SHOP_CART_FAILURE:
    case SHOP_CART_PRODUCT_ADD_FAILURE:
    case SHOP_CART_PRODUCT_REMOVE_FAILURE:
    case SHOP_CART_COMMENT_SET_FAILURE:
    case SHOP_CART_SCHEDULE_SET_FAILURE:
      return { ...state, isFetching: false };

    case SHOP_CART_CREATE_REQUEST:
      return { ...state, isCreating: true };

    case SHOP_CART_CREATE_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isChanged: false,
        isCreating: false,
        step: SHOPPING_CART_STEP_PREVIEW
      };

    case SHOP_CART_CREATE_FAILURE:
      return { ...state, isCreating: false };

    case SHOP_CART_DELETE_REQUEST:
      return { ...state, isDeleting: true };

    case SHOP_CART_DELETE_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isChanged: false,
        isDeleting: false,
        step: SHOPPING_CART_STEP_PREVIEW
      };

    case SHOP_CART_DELETE_FAILURE:
      return { ...state, isDeleting: false };

    case SHOP_CART_SERVICE_EDIT_REQUEST:
      return { ...state, isEditing: true };

    case SHOP_CART_SERVICE_EDIT_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isChanged: false,
        isEditing: false,
        step: SHOPPING_CART_STEP_PREVIEW
      };

    case SHOP_CART_SERVICE_EDIT_FAILURE:
      return { ...state, isEditing: false };

    case SHOP_CART_CHECKOUT_REQUEST:
      return { ...state, isCheckingOut: true };

    case SHOP_CART_CHECKOUT_SUCCESS:
      return { ...state, cart: action.payload, isCheckingOut: false, step: SHOPPING_CART_STEP_CHECKOUT };

    case SHOP_CART_CHECKOUT_FAILURE:
      return { ...state, isCheckingOut: false };

    case SHOP_CART_CHECKOUT_CANCEL_REQUEST:
      return { ...state, isCancelling: true };

    case SHOP_CART_CHECKOUT_CANCEL_SUCCESS:
      return { ...state, cart: action.payload, isCancelling: false, step: SHOPPING_CART_STEP_PREVIEW };

    case SHOP_CART_CHECKOUT_CANCEL_FAILURE:
      return { ...state, isCancelling: false };

    case SHOP_CART_PAYMENT_REQUEST:
      return { ...state, isRequestingPayment: true };

    case SHOP_CART_PAYMENT_SUCCESS:
      return { ...state, isRequestingPayment: false, step: SHOPPING_CART_STEP_PAYMENT };

    case SHOP_CART_PAYMENT_FAILURE:
      return { ...state, isRequestingPayment: false };

    case SHOP_CART_PO_NUMBER_CHANGE_REQUEST:
      return { ...state, isPoNumberChanging: true };

    case SHOP_CART_PO_NUMBER_CHANGE_SUCCESS:
      return { ...state, cart: action.payload, isPoNumberChanging: false };

    case SHOP_CART_PO_NUMBER_CHANGE_FAILURE:
      return { ...state, isPoNumberChanging: false };

    case SHOP_CART_PAYMENT_CANCEL_SUCCESS:
      return { ...state, step: SHOPPING_CART_STEP_CHECKOUT };

    case SHOP_CART_COUPON_ADD_REQUEST:
      return { ...state, isAddingCoupon: true, isCouponAdded: false };

    case SHOP_CART_COUPON_ADD_SUCCESS:
      return { ...state, cart: action.payload, isChanged: true, isAddingCoupon: false, isCouponAdded: true };

    case SHOP_CART_COUPON_ADD_FAILURE:
      return { ...state, isAddingCoupon: false };

    case SHOP_CART_COUPON_REMOVE_REQUEST:
      return { ...state, isRemovingCoupon: true };

    case SHOP_CART_COUPON_REMOVE_SUCCESS:
      return { ...state, cart: action.payload, isChanged: true, isRemovingCoupon: false };

    case SHOP_CART_COUPON_REMOVE_FAILURE:
      return { ...state, isRemovingCoupon: false };

    case SHOP_CART_SHOW:
      return { ...state, isVisible: true };

    case SHOP_CART_HIDE:
      return { ...state, isVisible: false };

    case SHOP_CART_RESET:
      return initialState;

    case SHOP_CART_ORDER_REQUEST:
      return { ...state, isOrdering: true };

    case SHOP_CART_ORDER_SUCCESS:
      return { ...state, isOrdering: false, step: SHOPPING_CART_STEP_SUCCESS };

    case SHOP_CART_ORDER_FAILURE:
      return { ...state, isOrdering: false };
    case SHOP_CART_BUDGET_LIMIT_IGNORE:
      return {
        ...state,
        isBudgetLimitIgnored: action.status
      };
    default:
      return state;
  }
};
