import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DefaultHeader, EditingHeader } from '.';

import './Header.less';

const Header = ({ isEditing }) => (isEditing ? <EditingHeader /> : <DefaultHeader />);

Header.propTypes = {
  isEditing: PropTypes.bool
};

Header.defaultProps = {
  isEditing: false
};

const mapStateToProps = ({
  shop: {
    cart: { cart }
  }
}) => ({
  isEditing: !!cart.sed
});

export { Header as PureComponent };
export default connect(mapStateToProps)(Header);
