import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { currency } from 'components/HOC'

const Sum = ({ item, deliveryCost, discount, t, c }) => (
  <div>
    {discount && (
      <div className="modal-costline">
        {t('discount')}:<span className="modal-costs">{c(discount.total_price)}</span>
      </div>
    )}

    {deliveryCost && (
      <div className="modal-costline">
        {t('delivery-costs')}:<span className="modal-costs">{c(deliveryCost.total_price)}</span>
      </div>
    )}

    <div className="modal-costline">
      {t('sum-no-tax')}:<span className="modal-costs">{c(item.net_price)}</span>
    </div>

    <div className="modal-costline light">
      {t('sum-tax')}:<span className="modal-costs">{c(item.gross_price)}</span>
    </div>
  </div>
);

Sum.propTypes = {
  item: PropTypes.object.isRequired,
  deliveryCost: PropTypes.object,
  discount: PropTypes.object,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

export default currency(withTranslation('Modal/ServiceEvent')(Sum));
