import { createSelector } from 'reselect';

const getItems = state => state.shop.partners.list.items;
const getPartners = state => state.shop.partners.entities;

export default createSelector(
  [getItems, getPartners], (items, partners) => {
    if (!items) return null;

    return items.map(partnerId => partners[partnerId])
      .filter(partner => !!partner);
  },
);
