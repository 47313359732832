import {
  SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT,
  SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET } from 'constants/actionTypes';

const initialState = {
  floor_space: 100,
  number_of_employees: 5,
  office: false,
  kitchen: false,
  bathroom: false,
  entrance: false,
  other: false,
  office_cleaning_area_size: '',
  office_floor_type: null,
  office_collect_dishes: false,
  office_deep_cleaning: false,
  office_move_furniture: false,
  office_clean_equipment: false,
  bathroom_cleaning_area_size: '',
  bathroom_deep_cleaning: false,
  kitchen_cleaning_area_size: '',
  kitchen_floor_type: null,
  kitchen_deep_cleaning: false,
  kitchen_move_furniture: false,
  entrance_cleaning_area_size: '',
  entrance_floor_type: null,
  entrance_deep_cleaning: false,
  entrance_move_furniture: false,
  other_details: '',
  date_time: null,
  irregular_hours: false,
  date1: null,
  date2: null,
  date3: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT:
      return { ...state, ...action.payload };
    case SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT_RESET:
      return initialState;
    default:
      return state;
  }
};
