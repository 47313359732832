import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { LogoutButton } from '../..';

import './Account.less';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = { isBoxVisible: false };
  }

  getAccountName = customerName => customerName || 'Service Partner One';

  toggleBox = () => {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  };

  getCustomerImage = customer => (customer.image_url ? { backgroundImage: `url(${customer.image_url})` } : null);

  handleClickOutside(e) {
    this.hide(e);
  }

  hide() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  renderAccountLinks = (t, customerName, doChangeCustomer, items, customerId, image) => {
    const currentAccImage = image ? { backgroundImage: `url(${image})` } : null;

    return (
      <div className="account-settings-content ignore-react-onclickoutside">
        <div className="link-to-account">
          <div className="account-item account-current">
            <div className={classNames('image', { empty: !image })} style={currentAccImage} />
            <div className="details">
              <div className="name">{customerName}</div>
              <Link className="button button-blue btn-settings" to="/settings" onClick={this.toggleBox}>
                {t('my-account')}
              </Link>
            </div>
          </div>
          {items.length > 1 && (
            <div className="account-list">
              {items
                .filter(it => it.id !== customerId)
                .map(item => (
                  <div
                    className="account-item"
                    key={item.id}
                    role="presentation"
                    onClick={() => doChangeCustomer(item.id)}
                  >
                    <div
                      className={classNames('image', { empty: !item.image_url })}
                      style={this.getCustomerImage(item)}
                    />
                    <div className="details">
                      <div className="name">{item.name}</div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="logout-wrap">
          <NavLink to="/members" className="link qa-team-link" title="Members" onClick={this.toggleBox}>
            {t('user-management')}
          </NavLink>

          <LogoutButton t={t} />
        </div>
      </div>
    );
  };

  render() {
    const { isBoxVisible } = this.state;
    const { t, customerName, doChangeCustomer, items, customerId, image } = this.props;

    return (
      <React.Fragment>
        <div
          className={`account-title ${isBoxVisible ? 'open' : ''}`}
          data-for="tooltip-account"
          data-effect="solid"
          onClick={this.toggleBox}
          role="presentation"
        >
          <div className="account-title-text">{this.getAccountName(customerName)}</div>
        </div>
        {this.renderAccountLinks(t, customerName, doChangeCustomer, items, customerId, image)}
      </React.Fragment>
    );
  }
}

Account.propTypes = {
  t: PropTypes.func.isRequired,
  customerName: PropTypes.string,
  customerId: PropTypes.string,
  items: PropTypes.array,
  doChangeCustomer: PropTypes.func.isRequired
};

export default onClickOutside(Account);
