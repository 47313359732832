import {
  SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_REQUEST,
  SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_SUCCESS,
  SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_FAILURE,
  SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET
} from 'constants/actionTypes';

const initialState = {
  isSuccessful: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_REQUEST:
      return { ...state,
        isSuccessful: false
      };

    case SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_SUCCESS:
      return { ...state,
        ...action.payload,
        isSuccessful: true
      };

    case SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_FAILURE:
      return { ...state,
        isSuccessful: false
      };
    case SERVICE_EVENT_WINDOW_CLEANING_OVERSIZED_SUBMIT_RESET:
      return { ...state,
        isSuccessful: false
      };
    default:
      return state;
  }
};
