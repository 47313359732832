import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import { RRule, getRRuleWeekday } from 'utils';

class Weekly extends Component {
  constructor(props) {
    super(props);

    this.handleChangeWeekday = this.handleChangeWeekday.bind(this);
    this.handleChangeInterval = this.handleChangeInterval.bind(this);
  }

  componentWillMount() {
    this.rootId = uniqueId();
  }

  handleChangeWeekday({ target: { value, checked } }) {
    const {
      dueDate,
      rruleOptions: { interval = 1 },
      onChange
    } = this.props;
    let {
      rruleOptions: { byweekday = [] }
    } = this.props;
    const weekday = getRRuleWeekday(value);

    byweekday = checked ? byweekday.concat([weekday]) : byweekday.filter(w => w !== weekday);

    if (!byweekday.length) {
      byweekday = [getRRuleWeekday(moment(dueDate).format('E'))];
    }

    onChange({
      freq: RRule.WEEKLY,
      interval,
      byweekday
    });
  }

  handleChangeInterval({ target: { value } }) {
    const {
      rruleOptions: { byweekday = [] },
      onChange
    } = this.props;
    const interval = parseInt(value, 10) || 1;

    onChange({
      freq: RRule.WEEKLY,
      interval,
      byweekday
    });
  }

  render() {
    const {
      rruleOptions: { interval = 1 },
      t,
      dueDate
    } = this.props;
    let {
      rruleOptions: { byweekday = [getRRuleWeekday(moment(dueDate).format('E'))] }
    } = this.props;
    byweekday = byweekday.map(w => `${w}`);

    const humanWeekdays = {
      [RRule.MO]: t('weekday-mo'),
      [RRule.TU]: t('weekday-tu'),
      [RRule.WE]: t('weekday-we'),
      [RRule.TH]: t('weekday-th'),
      [RRule.FR]: t('weekday-fr'),
      [RRule.SA]: t('weekday-sa'),
      [RRule.SU]: t('weekday-su')
    };

    return (
      <div>
        <div className="col-group shipping-date-details">
          {Object.keys(humanWeekdays).map(w => (
            <div className="shipping-date-weekday" key={`weekday-${w}`}>
              <input
                id={`weekday-${this.rootId}-${w}`}
                type="checkbox"
                value={w}
                onChange={this.handleChangeWeekday}
                checked={byweekday.includes(w)}
              />

              <label htmlFor={`weekday-${this.rootId}-${w}`}>{humanWeekdays[w]}</label>
            </div>
          ))}

          <select className="cart-drop-dark" value={interval} onChange={this.handleChangeInterval}>
            <option value="1">{t('weekly')}</option>
            <option value="2">{t('every-2-weeks')}</option>
            <option value="3">{t('every-3-weeks')}</option>
            <option value="4">{t('every-4-weeks')}</option>
          </select>
        </div>

        <p className="shipping-date-week-text">{t('select-weekday')}</p>
      </div>
    );
  }
}

Weekly.propTypes = {
  dueDate: PropTypes.object.isRequired,
  rruleOptions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export { Weekly as PureComponent };
export default pure(Weekly);
