import {
  SHOP_CART_PAYMENT_REQUEST,
  SHOP_CART_PAYMENT_SUCCESS,
  SHOP_CART_PAYMENT_FAILURE
} from 'constants/actionTypes';
import { SHOPPING_CART_STEP_CHECKOUT } from 'constants/shoppingCart';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: state =>
      state.shop.cart.step === SHOPPING_CART_STEP_CHECKOUT && !state.shop.cart.isRequestingPayment,
    types: [SHOP_CART_PAYMENT_REQUEST, SHOP_CART_PAYMENT_SUCCESS, SHOP_CART_PAYMENT_FAILURE],
    endpoint: '/user/shop/cart/payment',
    method: 'GET'
  });
