import { SHOP_PRODUCT_REQUEST, SHOP_PRODUCT_SUCCESS, SHOP_PRODUCT_FAILURE } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_PRODUCT_REQUEST: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isFetching: true, fetchedAt: null } };
    }

    case SHOP_PRODUCT_SUCCESS: {
      const {
        meta: { productId }
      } = action;

      return {
        ...state,
        [productId]: { ...state[productId], isFetching: false, fetchedAt: new Date(), item: action.payload.id }
      };
    }

    case SHOP_PRODUCT_FAILURE: {
      const {
        meta: { productId }
      } = action;

      return { ...state, [productId]: { ...state[productId], isFetching: false } };
    }

    default:
      return state;
  }
};
