import { CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT } from '../../constants/contentful';
import fetchEntries from './fetchEntries';

export default (excludedIds = []) =>
  fetchEntries({
    content_type: CONTENTFUL_CONTENT_TYPE_FEATURE_HIGHLIGHT,
    locale: '*',
    order: 'fields.featureOrder',
    'sys.id[nin]': excludedIds.join(',')
  });
