import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input } from "common";

const PONumber = ({ t, poNumber, insertPONumber }) => {
  if (!poNumber) {
    return (
      <div>
        <Input
          name="po-number"
          parentClass="po-number form-group"
          placeholder={t('po-number-optional')}
          onChange={insertPONumber}
        />
      </div>
    );
  }

  if (poNumber) {
    return (
      <div className="po-number-submitted">
        {t('po-number')}: {poNumber}
      </div>
    );
  }

  return <div />;
};

PONumber.propTypes = {
  t: PropTypes.func.isRequired
};

export { PONumber as PureComponent };
export default withTranslation('PartnerConfirmation')(PONumber);
