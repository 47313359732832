import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Transition, Tooltip } from '../../..';
import { NoteBox } from '../..';

class NoteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxVisible: false
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isNoted && nextProps.isNoted) {
      this.closeBox();
    }
  }

  toggleBox() {
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  }

  closeBox() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { item, isNoting, t } = this.props;
    const { isBoxVisible } = this.state;
    const tooltipId = `tooltip-se-${item.id}-note-button`;
    const className = classNames('se-button note', {
      'ignore-react-onclickoutside': isBoxVisible,
      active: isBoxVisible,
      loading: isNoting,
      noted: !!item.customer_note
    });

    return (
      <Tooltip className={className} id={tooltipId}>
        <button
          type="button"
          className="button-note"
          onClick={this.toggleBox}
          data-for={tooltipId}
          data-tip={t('tooltip')}
          data-effect="solid"
        >
          &nbsp;
        </button>

        <Transition>{isBoxVisible && <NoteBox item={item} closeBox={this.closeBox} />}</Transition>
      </Tooltip>
    );
  }
}

NoteButton.propTypes = {
  item: PropTypes.object.isRequired,
  isNoting: PropTypes.bool,
  isNoted: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { note } }, { item: { id } }) => ({
  isNoting: !!(note[id] && note[id].isNoting),
  isNoted: !!(note[id] && note[id].isNoted)
});

export default withTranslation('Common/ServiceEvent/NoteButton')(connect(mapStateToProps)(NoteButton));
