import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const DateBox = ({ t, offer, offerToken }) => {
  const offerSignedDate = offer[offerToken].item.contract_signed_date;
  const offerRejectedDate = offer[offerToken].item.contract_rejected_date;
  const offerCloseDate = offer[offerToken].item.offer_close_date;
  const isOffer = offer[offerToken].item.subtype === 'Offer';

  if (offerSignedDate && isOffer) {
    return (
      <div className="offer-box-margin grey-bg">
        <p
          dangerouslySetInnerHTML={{ __html: t('offer-date-accept',
            { date: moment(offerSignedDate).format('DD.MM.YYYY') }) }}
        />
      </div>
    );
  }
  if (!offerSignedDate && offerRejectedDate && isOffer) {
    return (
      <div className="offer-box-margin grey-bg">
        <p
          dangerouslySetInnerHTML={{ __html: t('offer-date-reject',
            { date: moment(offerRejectedDate).format('DD.MM.YYYY') }) }}
        />
      </div>
    );
  }
  if (offerSignedDate && !isOffer) {
    return (
      <div className="offer-box-margin grey-bg">
        <p
          dangerouslySetInnerHTML={{ __html: t('performance-date-accept',
            { date: moment(offerSignedDate).format('DD.MM.YYYY') }) }}
        />
      </div>
    );
  }
  if (!offerSignedDate && offerRejectedDate && !isOffer) {
    return (
      <div className="offer-box-margin grey-bg">
        <p
          dangerouslySetInnerHTML={{ __html: t('performance-date-reject',
            { date: moment(offerRejectedDate).format('DD.MM.YYYY') }) }}
        />
      </div>
    );
  }
  if (offerCloseDate && !offerSignedDate && !offerRejectedDate && isOffer) {
    return (
      <div className="offer-box-margin grey-bg">
        <p
          dangerouslySetInnerHTML={{ __html: t('performance-date-validity',
            { date: moment(offerCloseDate).format('DD.MM.YYYY') }) }}
        />
      </div>
    );
  }
  return (<div />);
};

DateBox.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export { DateBox as PureComponent };
export default withTranslation('PartnerConfirmation')(DateBox);
