import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { NavLink } from 'react-router-dom';

const TabItem = ({ item }) => (
  <li>
    <NavLink
      to={`${item.parentRoute ? `/${item.parentRoute}` : ''}/${item.id}`}
      activeClassName="active"
      className="tab-navigation-item"
    >
      {item.name}
    </NavLink>
  </li>
);

TabItem.propTypes = {
  item: PropTypes.object.isRequired
};

export { TabItem as PureComponent };
export default pure(TabItem);
