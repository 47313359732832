import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideCart } from 'actions/location';
import { translateEditing } from '../HOC';

const FinishButton = ({ onClick, t }) => (
  <button type="button" className="button cart-finish" onClick={onClick}>
    {t('finish')}
  </button>
);

FinishButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onClick: hideCart
};

export { FinishButton as PureComponent };
export default translateEditing(
  connect(
    null,
    mapDispatchToProps
  )(FinishButton)
);
