import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';

import './Loader.less';

const Loader = ({ isLoading, white }) =>
  isLoading ? <div className={`loader loading${white ? '-light' : ''} `} /> : null;

Loader.propTypes = {
  isLoading: PropTypes.bool,
  white: PropTypes.bool
};

export default pure(Loader);
