import React, { Component } from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { Input } from '..';

import './Autocomplete.less';

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      currentIndex: -1
    };

    this.blurTimer = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        currentIndex: -1
      });
    }
  }

  handleClickOutside() {
    this.hide();
  }

  hide() {
    if (this.state.isVisible) {
      this.setState({
        isVisible: false,
        currentIndex: -1
      });
    }
  }

  handleBlur() {
    clearTimeout(this.blurTimer);
    this.blurTimer = setTimeout(this.hide, 200);
  }

  show() {
    clearTimeout(this.blurTimer);

    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
        currentIndex: -1
      });
    }
  }

  handleChange(e) {
    const { onChange } = this.props;
    const { value } = e.target;

    if (onChange) onChange(value, e);
    this.show();
  }

  handleKeyDown(e) {
    const { items, onSelect } = this.props;
    let { currentIndex } = this.state;

    switch (e.which) {
      case 9: // Tab
      case 13: // Enter
      case 39: // Arrow Right
        if (currentIndex > -1) {
          e.preventDefault();
          if (onSelect) onSelect(items[currentIndex]);
          this.hide();
        }
        break;

      case 27: // Esc
        this.hide();
        break;

      case 38: // Arrow Up
        e.preventDefault();

        currentIndex -= 1;
        if (currentIndex < -1) currentIndex = items.length - 1;

        this.setState({
          isVisible: true,
          currentIndex
        });
        break;

      case 40: // Arrow Down
        e.preventDefault();

        currentIndex += 1;
        if (currentIndex >= items.length) currentIndex = -1;

        this.setState({
          isVisible: true,
          currentIndex
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { inputClassName, value, placeholder, items, onSelect } = this.props;
    const { isVisible, currentIndex } = this.state;

    return (
      <div className="autocomplete-wrapper">
        <Input
          type="text"
          className={inputClassName}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onFocus={this.show}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />

        {isVisible && items.length > 0 && (
          <ul className="autocomplete active" role="menu">
            {items.map((item, index) => (
              <li
                key={item.key}
                role="menuitem"
                className={`autocomplete-item ${index === currentIndex ? 'active' : ''}`}
                onClick={() => onSelect && onSelect(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

Autocomplete.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default pure(onClickOutside(Autocomplete));
