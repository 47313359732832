import {
  SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_REQUEST,
  SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_SUCCESS,
  SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { CONTENT_TYPE, CONTENT_TYPE_JSON } from '../../constants/headers';

export default ({ officeSize, windowExceed, windowFrames, startThis, panels }) =>
  authCall({
        shouldFetch: () => true, // eslint-disable-line
    types: [
      SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_REQUEST,
      SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_SUCCESS,
      SERVICE_EVENT_WINDOW_CLEANING_SUBMIT_FAILURE
    ],
    endpoint: '/user/form/cleaning/window',
    meta: 'submitCleaning',
    method: 'POST',
    headers: {
      [CONTENT_TYPE]: CONTENT_TYPE_JSON
    },
    body: {
      cleaning_area_size: officeSize,
      is_ladder_needed: windowExceed,
      needs_frame_cleaning: windowFrames,
      start_date: startThis,

      panels
    }
  });
