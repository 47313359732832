import {
  SHOP_CART_PAYMENT_SUCCESS,
  SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS,
  SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS,
  SHOP_CART_ORDER_SUCCESS
} from 'constants/actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_CART_ORDER_SUCCESS:
      return initialState;

    case SHOP_CART_PAYMENT_SUCCESS:
      return action.payload.credit_cards;

    case SHOP_CART_PAYMENT_CREDIT_CARD_CREATE_SUCCESS: {
      const {
        meta: { save }
      } = action;

      return [...state, { ...action.payload.card, token: action.payload.id, isNew: true, save }];
    }

    case SHOP_CART_PAYMENT_CREDIT_CARD_DELETE_SUCCESS:
      return state.filter(cc => cc.id !== action.meta.cardId);

    default:
      return state;
  }
};
