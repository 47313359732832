import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DocumentTitle } from 'common';
import { Navbar, Calendar } from '.';

const createState = ({
  match: {
    params: { start }
  }
}) => {
  let startOfMonth = start || moment();

  if (typeof startOfMonth === 'string') {
    startOfMonth = moment(startOfMonth, 'YYYYMMDD');
  }

  if (!startOfMonth.isValid()) {
    startOfMonth = moment();
  }

  startOfMonth.startOf('month');

  const keyOfToday = moment().format('YYYYMMDD');
  const endOfMonth = startOfMonth.clone().endOf('month');
  const currentMonth = startOfMonth.month();
  const startDate = startOfMonth.clone().startOf('week');
  const endDate = endOfMonth.clone().endOf('week');
  const dates = [];

  while (startDate.format() < endDate.format()) {
    const date = startDate.clone();
    const key = date.format('YYYYMMDD');

    dates.push({
      date,
      key,
      isToday: key === keyOfToday,
      isInMonth: date.month() === currentMonth
    });

    startDate.add(1, 'day');
  }

  return {
    startOfMonth,
    endOfMonth,
    prevMonth: startOfMonth.clone().subtract(1, 'month'),
    nextMonth: startOfMonth.clone().add(1, 'month'),
    dates
  };
};

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = createState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.start !== nextProps.match.params.start) {
      this.setState(createState(nextProps));
    }
  }

  render() {
    const { t } = this.props;
    const { startOfMonth, prevMonth, nextMonth, dates } = this.state;

    return (
      <DocumentTitle title={t('headline')}>
        <div className="col-group content-area">
          <Navbar currentDate={startOfMonth} prevMonth={prevMonth} nextMonth={nextMonth} />

          <Calendar dates={dates} />
        </div>
      </DocumentTitle>
    );
  }
}

Timeline.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default pure(withTranslation('Timeline')(Timeline));
