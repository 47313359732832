import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

const DecreaseButton = ({ onClick }) => (
  <button type="button" className="cart-product-minus" onClick={onClick}>
    -
  </button>
);

DecreaseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default pure(DecreaseButton);
