import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

const EmptyProduct = ({ image, name }) => {
  const imageStyle = image
    ? {
      background: `url(${image}) no-repeat center center`,
      backgroundSize: '50%, auto'
    }
    : null;

  return (
    <div className="product product-placeholder">
      <div className="product-img" style={imageStyle}>
                &nbsp;
      </div>

      <div className="product-data">
        <p className="product-name">{name}</p>
      </div>
    </div>
  );
};

EmptyProduct.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string
};

export default pure(EmptyProduct);
