import {
  SERVICE_EVENT_RATE_REQUEST,
  SERVICE_EVENT_RATE_SUCCESS,
  SERVICE_EVENT_RATE_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_RATE_REQUEST: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isRating: true,
          isRated: false
        }
      };
    }

    case SERVICE_EVENT_RATE_SUCCESS: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isRating: false,
          isRated: true
        }
      };
    }

    case SERVICE_EVENT_RATE_FAILURE: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isRating: false
        }
      };
    }

    default:
      return state;
  }
};
