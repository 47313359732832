import { SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT } from 'constants/actionTypes';

function updateIntensiveCleaningObject(valueEvent) {
  return {
    type: SERVICE_EVENT_INTENSIVE_CLEANING_OBJECT,
    payload: valueEvent
  };
}

export default updateIntensiveCleaningObject;
