import {
  SERVICE_EVENT_CLEANING_REQUEST,
  SERVICE_EVENT_CLEANING_SUCCESS,
  SERVICE_EVENT_CLEANING_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      serviceEvents: {
        cleaningConfig: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [SERVICE_EVENT_CLEANING_REQUEST, SERVICE_EVENT_CLEANING_SUCCESS, SERVICE_EVENT_CLEANING_FAILURE],
    endpoint: '/user/form/cleaning',
    meta: 'cleaningConfig',
    method: 'GET'
  });
