export function hideShopButton(brand) {
  return ['gegenbauer'].includes(brand);
}

export function hideServicesButton(brand) {
  return ['gegenbauer'].includes(brand);
}

export function showCustomCleaningForms(brand) {
  return ['gegenbauer'].includes(brand);
}

export default {
  hideShopButton,
  hideServicesButton,
  showCustomCleaningForms
};
