import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false
    };
  }

  handleCloseColorPicker() {
    this.setState({ displayColorPicker: false });
  }

  handleToggleColorPicker() {
    this.setState(prevState => ({
      displayColorPicker: !prevState.displayColorPicker
    }));
  }

  render() {
    const { title, color, changeColor } = this.props;

    return (
      <div className="menu-pannel">
        <div className="menu-pannel-title">{title}</div>
        <div className="menu-pannel-color">
          <div
            style={{ backgroundColor: color }}
            className="selected-color"
            role="presentation"
            onClick={() => this.handleToggleColorPicker()}
          />
          {this.state.displayColorPicker ? (
            <div className="colorpicker-popover">
              <div
                className="colorpicker-close"
                role="presentation"
                onClick={() => this.handleCloseColorPicker()}
              />
              <ChromePicker disableAlpha color={color} onChange={c => changeColor(c)} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ColorSection.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  changeColor: PropTypes.func
};

export { ColorSection as PureComponent };
export default ColorSection;
