import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Modal } from 'components/HOC';
import { deactivateMember, activateMember } from 'actions/members';

import './ConfirmDeactivateMember.less';

export class ConfirmDeactivateMember extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const { deactivateSubmit, activateSubmit, id, deactivate } = this.props;

    if (deactivate) {
      deactivateSubmit(id);
    } else {
      activateSubmit(id);
    }
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  render() {
    const { t, deactivate } = this.props;
    const headerText = deactivate ? t('deactivate-member') : t('activate-member');
    const submitBtnText = deactivate ? t('deactivate-member') : t('activate-member');

    return (
      <div>
        <h2 className="headline">{headerText}?</h2>
        <form onSubmit={this.handleSubmit}>
          <button type="button" className="link default" onClick={this.handleCloseModal}>
            {t('cancel')}
          </button>
          <button type="submit" className="button button-blue">
            {submitBtnText}
          </button>
        </form>
      </div>
    );
  }
}

ConfirmDeactivateMember.propTypes = {
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  deactivateSubmit: PropTypes.func.isRequired,
  activateSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
  deactivateSubmit: id => dispatch(deactivateMember(id)),
  activateSubmit: id => dispatch(activateMember(id))
});

export { ConfirmDeactivateMember as PureComponent };
export default withTranslation('Members')(
  connect(
    null,
    mapDispatchToProps
  )(Modal('Confirm Deactivate Member', { className: 'confirm-deactivate-member' })(ConfirmDeactivateMember))
);
