import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_PRODUCT_MISSING } from 'constants/location';

import './MissingProductLink.less';

const MissingProductLink = ({ pathname, children }) => {
  const { t } = useTranslation('Header');

  return (
    <Link to={`${pathname}${LOCATION_HASH_PRODUCT_MISSING}`} className="button-soft missing-product">
      {children || t('product-is-missing')}
    </Link>
  );
};

MissingProductLink.propTypes = {
  pathname: PropTypes.string
};

const mapStateToProps = ({ location: { pathname } }) => ({ pathname });

export default connect(mapStateToProps)(MissingProductLink);
