import {
  CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST,
  CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS,
  CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default () =>
  authCall({
    shouldFetch: s => !s.customer.shippingAddress.isChanging,
    types: [
      CUSTOMER_SHIPPING_ADDRESS_TICKET_REQUEST,
      CUSTOMER_SHIPPING_ADDRESS_TICKET_SUCCESS,
      CUSTOMER_SHIPPING_ADDRESS_TICKET_FAILURE
    ],
    endpoint: '/user/customer/shipping-address',
    method: 'GET'
  });
