import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { currency } from 'components/HOC';
import { RemoveCouponButton } from '.';

const Discount = ({ isVisible, name, amount, t, c }) =>
  isVisible ? (
    <div className="cart-costline cart-discount">
      <span>
        <strong>
          {t('discount')} ({name})
        </strong>{' '}
        {t('vat')}
        <RemoveCouponButton />
      </span>

      <span className="cart-costs">- {c(amount)}</span>
    </div>
  ) : null;

Discount.propTypes = {
  isVisible: PropTypes.bool,
  name: PropTypes.string,
  amount: PropTypes.number,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

Discount.defaultProps = {
  isVisible: false,
  name: '',
  amount: 0
};

const mapStateToProps = ({
  shop: {
    cart: {
      cart: { discount }
    }
  }
}) => ({
  isVisible: !!discount,
  name: discount && discount.name,
  amount: discount && discount.amount
});

export { Discount as PureComponent };
export default currency(withTranslation('Shop/Cart/Default')(connect(mapStateToProps)(Discount)));
