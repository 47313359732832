import {
  SHOP_FAVORITE_CREATE_SUCCESS,
  SHOP_PRODUCTS_REQUEST,
  SHOP_PRODUCTS_SUCCESS,
  SHOP_PRODUCTS_FAILURE,
  SHOP_PARTNERS_RESTORE,
  SHOP_PARTNERS_SELECT,
  SHOP_PARTNERS_SELECT_ONE,
  SHOP_PARTNERS_DESELECT,
  SHOP_PARTNERS_RESET
} from 'constants/actionTypes';
import { DATA_KEY_SHOP_PRODUCTS_FAVORITES } from 'constants/dataKeys';
import { X_TOTAL_COUNT } from 'constants/headers';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_FAVORITE_CREATE_SUCCESS:
      if (state[DATA_KEY_SHOP_PRODUCTS_FAVORITES]) {
        return { ...state, [DATA_KEY_SHOP_PRODUCTS_FAVORITES]: undefined };
      }

      return state;

    case SHOP_PARTNERS_RESTORE:
    case SHOP_PARTNERS_SELECT:
    case SHOP_PARTNERS_SELECT_ONE:
    case SHOP_PARTNERS_DESELECT:
    case SHOP_PARTNERS_RESET:
      return initialState;

    case SHOP_PRODUCTS_REQUEST: {
      const {
        meta: { dataKey, currentPage }
      } = action;

      return {
        ...state,
        [dataKey]: {
          ...state[dataKey],
          isFetching: true,
          pages: {
            ...(state[dataKey] && state[dataKey].pages),
            [currentPage]: {
              fetchedAt: null
            }
          },
          hasMore: null
        }
      };
    }

    case SHOP_PRODUCTS_SUCCESS: {
      const {
        meta: { dataKey, currentPage },
        response
      } = action;

      return {
        ...state,
        [dataKey]: {
          ...state[dataKey],
          isFetching: false,
          pages: {
            ...(state[dataKey] && state[dataKey].pages),
            [currentPage]: {
              fetchedAt: new Date(),
              items: action.payload.map(item => item.id)
            }
          },
          numberOfItems: parseInt(response && response.headers.get(X_TOTAL_COUNT), 10) || 0,
          currentPage,
          hasMore: !!(response && response.status === 206)
        }
      };
    }

    case SHOP_PRODUCTS_FAILURE: {
      const {
        meta: { dataKey }
      } = action;

      return { ...state, [dataKey]: { ...state[dataKey], isFetching: false } };
    }

    default:
      return state;
  }
};
