import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { propCreator, currency } from 'components/HOC';
import { WithCustomer } from 'components/DI';
import { fetchCosts } from 'actions/serviceEventDefinitions';
import { getTotalCost } from 'selectors/serviceEventDefinitions';
import ServiceEventFilter from '../ServiceEventFilter';

import './Navbar.less';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentDate !== prevProps.currentDate) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dataKey, currentDate } = this.props;

    this.props.fetchData(dataKey, {
      date: currentDate
        .clone()
        .add(15, 'days')
        .toISOString()
    });
  }

  render() {
    const {
      total,
      isCurrentMonth,
      isFetching,
      currentDate,
      prevDate,
      nextDate,
      t,
      c,
      startOfMonth,
      endOfMonth
    } = this.props;

    return (
      <WithCustomer className="cost-headline" onChange={this.fetchData}>
        <div className="col-6 col-m-12">
          <span className="light">{t('headline')}</span>
          {currentDate.format('MMMM YYYY')}
        </div>

        <div className="col-6 col-m-12">
          <Link
            to={`/dashboard/${nextDate.format('YYYYMMDD')}`}
            className="cost-next-month"
            title={nextDate.format('MMMM YYYY')}
          >
            &nbsp;
          </Link>

          <Link
            to={`/dashboard/${prevDate.format('YYYYMMDD')}`}
            className="cost-prev-month"
            title={prevDate.format('MMMM YYYY')}
          >
            &nbsp;
          </Link>

          {!isCurrentMonth && (
            <Link to="/dashboard" className="button cost-back" title={nextDate.format('MMMM YYYY')}>
              {t('to-current-month')}
            </Link>
          )}

          <ServiceEventFilter startDate={startOfMonth} endDate={endOfMonth} />

          <span className={`${isFetching ? 'loading' : ''} cost-this-month`}>
            {c(total)} <span className="light">{t('per-month')}</span>
          </span>
        </div>
      </WithCustomer>
    );
  }
}

Navbar.propTypes = {
  dataKey: PropTypes.string.isRequired,
  isCurrentMonth: PropTypes.bool,
  isFetching: PropTypes.bool,
  currentDate: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  prevDate: PropTypes.object.isRequired,
  nextDate: PropTypes.object.isRequired,
  startOfMonth: PropTypes.object.isRequired,
  endOfMonth: PropTypes.object.isRequired,
  total: PropTypes.number,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

const createProps = ({ currentDate }) => ({
  dataKey: `dshbrd_${currentDate.format('YYYYMMDD')}`
});

const mapStateToProps = (state, props) => {
  const {
    serviceEventDefinitions: {
      costs: { [props.dataKey]: { isFetching } = {} }
    }
  } = state;
  const total = getTotalCost(state, props);
  return { isFetching, total };
};

const mapDispatchToProps = {
  fetchData: fetchCosts
};

export default currency(
  propCreator(createProps)(
    withTranslation('Dashboard/Navbar')(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(Navbar)
    )
  )
);
