export const SERVICE_EVENT_STATUS_DRAFT = 'draft';
export const SERVICE_EVENT_STATUS_ACTIVE = 'active';
export const SERVICE_EVENT_STATUS_PAUSED = 'paused';
export const SERVICE_EVENT_STATUS_INACTIVE = 'inactive';

export const SERVICE_EVENT_TYPE_CLEANING = 'cleaning';

export const SERVICE_EVENT_MODE_RECURRENT = 'recurrent';

export const ORDER_SCHEDULE_ONE_TIME = 'one_time';
export const ORDER_SCHEDULE_WEEKLY = 'recurrent:weekly';
export const ORDER_SCHEDULE_MONTHLY = 'recurrent:monthly';
