// @flow

import React, { Component } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-dates/initialize';
import { connect } from 'react-redux';
import { isMobile } from 'utils';
import { withRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

// Don't change the order!!!
import 'src/styles/global.less';

import Routes from 'src/routes/routes';

type Props = {
  children?: React$Node,
  isAuthenticated: boolean,
  buttonsColor?: string,
  mainColor?: string
};
type State = {};

export class App extends Component<Props, State> {
  componentDidMount() {
    if (isMobile()) {
      const root = document.getElementById('root');

      if (root) {
        root.setAttribute('class', 'touch');
      }
    }
  }

  renderContent = () => {
    const { children } = this.props;
    const toastPosition = window.innerWidth <= 1024 ? 'bottom-center' : 'top-right';

    return (
      <>
        <ToastContainer autoClose={5000} hideProgressBar position={toastPosition} transition={Slide} />
        {children}
      </>
    );
  };

  render() {
    const { isAuthenticated, buttonsColor, mainColor } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY || null}>
        <div
          className="route-wrapper"
          style={{
            '--theme-buttons-color': buttonsColor,
            '--theme-main-color': mainColor
          }}
        >
          <Routes isAuthenticated={isAuthenticated} />
          {this.renderContent()}
        </div>
      </StripeProvider>
    );
  }
}

const mapStateToProps = ({
  auth: {
    data: { isAuthenticated }
  },
  userSettings: { buttonsColor, mainColor }
}) => ({
  isAuthenticated,
  buttonsColor,
  mainColor
});

export default withRouter(connect(mapStateToProps)(App));
