import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { viewProductList } from 'actions/tracking';
import { WithCustomer, WithPartnerIds } from 'components/DI';
import { DocumentTitle, Breadcrumb } from 'common';
import { GridView, ListView, EmptyView } from '../..';

import './Overview.less';

class Overview extends Component {
  constructor(props) {
    super(props);

    this.viewedIds = [];
  }

  componentDidMount() {
    this.props.fetchData();
    this.handleViewItems();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.trackingListId !== nextProps.trackingListId) {
      this.viewedIds = [];
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.handleViewItems();
    }
  }

  handleViewItems() {
    const { items, trackingListId, onViewItems } = this.props;

    if (!items || !items.length) return;

    const products = items.map(item => item.id).filter(id => !this.viewedIds.includes(id));

    if (!products.length) return;

    this.viewedIds = [...this.viewedIds, ...products];

    onViewItems(trackingListId, products);
  }

  render() {
    const { title, breadcrumbItems, isFetching, items, hasMore, fetchData, fetchNext, isList, isGrid } = this.props;
    const passedProps = { isFetching, items, hasMore, fetchNext };

    return (
      <DocumentTitle title={title}>
        <div className="shop-module col-12 pad">
          <div className="page-header">
            <Breadcrumb items={breadcrumbItems} />
          </div>

          {isList && <ListView {...passedProps} />}

          {isGrid && <GridView {...passedProps} />}

          {items && !items.length && <EmptyView />}

          <WithCustomer onChange={() => fetchData(1)} />
          <WithPartnerIds onChange={() => fetchData(1)} />
        </div>
      </DocumentTitle>
    );
  }
}

Overview.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  items: PropTypes.array,
  hasMore: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  fetchNext: PropTypes.func.isRequired,
  trackingListId: PropTypes.string.isRequired,
  isList: PropTypes.bool,
  isGrid: PropTypes.bool,
  onViewItems: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    view: { isList, isGrid }
  }
}) => ({ isList, isGrid });

const mapDispatchToProps = {
  onViewItems: viewProductList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
