import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOCATION_QUERY_SE_RATING } from 'constants/location';
import { Tooltip } from '../../..';

const RatingLink = ({ item, pathname, isRating, t }) => {
  const tooltipId = `tooltip-se-${item.id}-rating-link`;
  const className = classNames('se-button star', {
    loading: isRating,
    rated: !!item.rated_date
  });

  return (
    <Tooltip className="se-rating-link" id={tooltipId}>
      <Link className="linkable-wrapper" to={`${pathname}?${LOCATION_QUERY_SE_RATING}=${item.id}`}>
        <div className={className}>
          <button
            type="button"
            className="button-star"
            data-for={tooltipId}
            data-tip={t('tooltip')}
            data-effect="solid"
          >
            &nbsp;
          </button>
        </div>
      </Link>
    </Tooltip>
  );
};

RatingLink.propTypes = {
  item: PropTypes.object.isRequired,
  pathname: PropTypes.string,
  isRating: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ location: { pathname }, serviceEvents: { rate } }, { id }) => ({
  pathname,
  isRating: !!(rate[id] && rate[id].isRating)
});

export default withTranslation('Common/ServiceEvent/RatingButton')(connect(mapStateToProps)(RatingLink));
