import React, { Component } from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import TabContent from './TabContent';
import TabTitle from './TabTitle';

class TabPane extends Component {

  renderCurrentTab() {
    const { activeTabIndex, children } = this.props;

    return children.filter(child => child.type === TabContent)
      .filter((child, index) => index === activeTabIndex);
  }

  renderTabTitles() {
    const { activeClass, children, activeTabIndex } = this.props;

    return children.filter(child => child.type === TabTitle)
      .map((child, index) => (
        <li
          className={activeTabIndex === index ? activeClass : ''}
          key={`tab-number-${index}`} // eslint-disable-line react/no-array-index-key
        >
          {child}
        </li>
      ));
  }

  render() {
    return (
      <div className="tab-pane">
        <ul className="tab-titles">
          {this.renderTabTitles()}
        </ul>

        {this.renderCurrentTab()}
      </div>
    );
  }
}

TabPane.propTypes = {
  activeClass: PropTypes.string,
  activeTabIndex: PropTypes.number
};

TabPane.defaultProps = {
  activeClass: 'active disabled'
};

export default pure(TabPane);
