import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DocumentTitle, TabNavigation } from '../Common';
import { Customer, BillingAddressForm, ChangeLanguage, EmailNotifications, ShippingAddress, LoginAndSecurity } from '.';

import './Settings.less';

class Settings extends Component {
  getTabNavigationItems = t => [
    { id: 'office-profile', name: t('tab-office-profile'), parentRoute: 'settings' },
    { id: 'billing-address', name: t('tab-billing-address'), parentRoute: 'settings' },
    { id: 'shipping-address', name: t('tab-shipping-address'), parentRoute: 'settings' },
    { id: 'login-security', name: t('tab-login-security'), parentRoute: 'settings' },
    { id: 'language', name: t('tab-languages'), parentRoute: 'settings' },
    { id: 'email-notifications', name: t('tab-email-notifications'), parentRoute: 'settings' }
  ];

  getAccountName = customerName => customerName || 'Service Partner One';

  getChildRoutes = () => (
    <Switch>
      <Redirect from="/settings" exact to="/settings/office-profile" />
      <Route path="/settings/office-profile" component={Customer} />
      <Route path="/settings/billing-address" component={BillingAddressForm} />
      <Route path="/settings/shipping-address" component={ShippingAddress} />
      <Route path="/settings/login-security" component={LoginAndSecurity} />
      <Route path="/settings/language" component={ChangeLanguage} />
      <Route path="/settings/email-notifications" component={EmailNotifications} />
    </Switch>
  );

  render() {
    const { t, customerName } = this.props;
    return (
      <DocumentTitle title={t('headline')}>
        <div className="settings content-area">
          <div className="col-group">
            <h2 className="customer-name">{customerName}</h2>
            <TabNavigation items={this.getTabNavigationItems(t)} />
            <div className="settings-container">{this.getChildRoutes()}</div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  customerName: PropTypes.string
};

const mapStateToProps = ({ current: { customer } }) => ({
  customerName: customer && (customer.corporate_name || customer.name)
});

export default withRouter(withTranslation('Settings')(connect(mapStateToProps)(Settings)));
