import {
  SERVICE_EVENT_FILTERS_REQUEST,
  SERVICE_EVENT_FILTERS_SUCCESS,
  SERVICE_EVENT_FILTERS_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

/**
 * @param query.start_date
 * @param query.end_date
 */
export default (dataKey, query) =>
  authCall({
    shouldFetch: ({
      serviceEvents: {
        filters: {
          list: { [dataKey]: { isFetching, fetchedAt } = {} }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    meta: { dataKey },
    types: [SERVICE_EVENT_FILTERS_REQUEST, SERVICE_EVENT_FILTERS_SUCCESS, SERVICE_EVENT_FILTERS_FAILURE],
    endpoint: '/user/service-events/filters',
    method: 'GET',
    query
  });
