import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'common';
import { togglePartner } from 'actions/shop/partners';
import { PartnerImage } from '../../Product/Common';

const TogglePartner = ({ item, isSelected, onChange }) => (
  <div className="shop-partner-single">
    <Switch
      alignCheckboxLeft={false}
      checked={isSelected}
      className="shop-partner-choose"
      id={`partner-${item.id}`}
      onChange={onChange}
    >
      <div className="shop-partner-container">
        <p className="shop-partner-name">{item.name}</p>
        <p className="shop-partner-subline" />
      </div>

      <PartnerImage item={item} />
    </Switch>
  </div>
);

TogglePartner.propTypes = {
  item: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

const mapStateToProps = (
  {
    shop: {
      partners: { partnerIds }
    }
  },
  { item: { id } }
) => ({
  isSelected: partnerIds.includes(id)
});

const mapDispatchToProps = (dispatch, { item: { id } }) => ({
  onChange: () => dispatch(togglePartner(id))
});

export { TogglePartner as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TogglePartner);
