import {
  TRACK_OFFER_SEEN_REQUEST,
  TRACK_OFFER_SEEN_SUCCESS,
  TRACK_OFFER_SEEN_FAILURE
} from 'constants/actionTypes';

import { apiCall } from 'api';

export default token =>
  apiCall({
    types: [TRACK_OFFER_SEEN_REQUEST, TRACK_OFFER_SEEN_SUCCESS, TRACK_OFFER_SEEN_FAILURE],
    endpoint: `/offer/view/${token}`,
    method: 'GET'
  });
