import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { htmlId } from 'components/HOC';
import { Tooltip } from '../..';

export class MultipleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOptions !== this.state.selectedOptions) {
      this.props.getValues(this.state.selectedOptions);
    }
  }

  multiSelectChange = e => {
    const option = e.target.value;
    if (this.state.selectedOptions.includes(option)) {
      this.setState(prevState => ({
        selectedOptions: [...prevState.selectedOptions.filter(selectedOption => selectedOption !== option)]
      }));
    } else {
      this.setState(prevState => ({ selectedOptions: [...prevState.selectedOptions, option] }));
    }
  };

  render() {
    const { id, className, parentClass, label, options, required, tooltip, tooltipMessage, dataType } = this.props;
    const passedProps = omit(this.props, 'className', 'parentClass', 'label', 'options', 'dataType', 'getValues');

    return (
      <div className={`select ${parentClass}`}>
        {label && (
          <label htmlFor={id} className={`select__label ${required ? 'required' : ''}`}>
            {label}
            {tooltip && (
              <div className="tooltip" data-for={tooltip} data-tip={tooltipMessage}>
                <Tooltip id={tooltip} />
              </div>
            )}
          </label>
        )}
        <span className={`select__input select__multiple ${className}`}>
          <select
            multiple
            {...passedProps}
            value={this.state.selectedOptions}
            onChange={e => this.multiSelectChange(e)}
            type="select-multiple"
          >
            {dataType && <option disabled>{dataType}</option>}
            {options &&
              options.map((item, index) => (
                <option
                  key={`${id}-i${index + 1}`} // eslint-disable-line react/no-array-index-key
                  value={item.value}
                >
                  {item.name}
                </option>
              ))}
            ;
          </select>
        </span>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  tooltipMessage: PropTypes.string,
  className: PropTypes.string,
  parentClass: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  dataType: PropTypes.string
};

MultipleSelect.defaultProps = {
  className: '',
  parentClass: '',
  required: false,
  dataType: ''
};

export default htmlId(MultipleSelect);
