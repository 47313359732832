import {
  SHOP_CATEGORIES_REQUEST,
  SHOP_CATEGORIES_SUCCESS,
  SHOP_CATEGORIES_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    items: null
  },
  action
) => {
  switch (action.type) {
    case SHOP_CATEGORIES_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case SHOP_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        items: Object.keys(action.payload).reduce(
          (items, language) => ({
            ...items,
            [language]: action.payload[language]
              .sort((a, b) => b.priority - a.priority || a.name.localeCompare(b.name))
              .map(item => item.id)
          }),
          {}
        )
      };

    case SHOP_CATEGORIES_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
