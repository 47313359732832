import {
  SHOP_FAVORITES_REQUEST,
  SHOP_FAVORITES_SUCCESS,
  SHOP_FAVORITES_FAILURE,
  SHOP_FAVORITE_CREATE_SUCCESS,
  SHOP_FAVORITE_DELETE_SUCCESS
} from 'constants/actionTypes';

export default (
  state = {
    isFetching: false,
    fetchedAt: null,
    items: []
  },
  action
) => {
  switch (action.type) {
    case SHOP_FAVORITES_REQUEST:
      return { ...state, isFetching: true, fetchedAt: null };

    case SHOP_FAVORITES_SUCCESS:
      return { ...state, isFetching: false, fetchedAt: new Date(), items: action.payload };

    case SHOP_FAVORITES_FAILURE:
      return { ...state, isFetching: false };

    case SHOP_FAVORITE_CREATE_SUCCESS:
      return { ...state, items: [...state.items, action.meta.productId] };

    case SHOP_FAVORITE_DELETE_SUCCESS:
      return { ...state, items: state.items.filter(id => id !== action.meta.productId) };

    default:
      return state;
  }
};
