import { createSelector } from 'reselect';
import { DATA_KEY_SHOP_PRODUCTS_FAVORITES } from '../../constants/dataKeys';
import { getItemsFromPages } from '../shared';

const getPages = state =>
  state.shop.products.list[DATA_KEY_SHOP_PRODUCTS_FAVORITES] &&
    state.shop.products.list[DATA_KEY_SHOP_PRODUCTS_FAVORITES].pages;
const getProducts = state => state.shop.products.entities;
const getFavorites = state => state.shop.favorites.list.items;

export default createSelector(
  [getItemsFromPages(getPages), getProducts, getFavorites],
  (items, products, favorites) => {
    if (!items) return null;

    return items
      .map(productId => products[productId])
      .filter(product => !!product && favorites.includes(product.id));
  }
);
