import { createSelector } from 'reselect';
import { I18N_DEFAULT_LANGUAGE } from '../../constants/i18n';

const createCategory = (category, categories) => {
  if (!category || !category.children || !category.children.length) return category;

  return {
    ...category,
    children: category.children
      .map(categoryId => createCategory(categories[categoryId], categories))
      .filter(child => !!child)
  };
};

const getItems = state =>
  state.shop.categories.list.items &&
    (state.shop.categories.list.items[state.i18n.language] || state.shop.categories.list.items[I18N_DEFAULT_LANGUAGE]);
const getCategories = state =>
  state.shop.categories.entities[state.i18n.language] || state.shop.categories.entities[I18N_DEFAULT_LANGUAGE];

export default createSelector([getItems, getCategories], (items, categories) => {
  if (!items || !categories) return null;

  return items.map(categoryId => createCategory(categories[categoryId], categories)).filter(category => !!category);
});
