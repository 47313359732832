import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Input } from 'common';
import { RadioButton, Checkbox, Select } from 'common/Form';
import { RegisterControls } from '../..';

export class RegisterStepOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFemale: true,
      agbAccepted: false
    };
  }

  setTrue = e => {
    this.setState(prevState => ({
      isFemale: !prevState.isFemale
    }));

    this.props.onChange(e);
  };

  setAgb = e => {
    this.setState(prevState => ({
      agbAccepted: !prevState.agbAccepted
    }));

    this.props.onChange(e);
  };

  render() {
    const {
      t,
      onChange,
      changeCountry,
      errorPassword,
      errorPasswordRepeat,
      errorEmail,
      errorFirstname,
      errorLastname,
      errorCompany,
      errorPhone,
      errorAgb
    } = this.props;
    const { isFemale } = this.state;
    const items = [{ name: t('DE'), value: 'DE' }, { name: t('AU'), value: 'AU' }];
    return (
      <div className="register-view__wrapper">
        <div className="register-view__container">
          <h1 className="lead">
            <span className="lead__headline">{t('headline')}</span>
            <span className="lead__subheadline">
              {t('subheadline')}{' '}
              <Link to="/dashboard" className="link">
                {t('login')}
              </Link>
            </span>
          </h1>

          <div className="col-group">
            <form className="register-view__form" onSubmit={this.props.onSubmit} noValidate>
              <fieldset disabled={this.props.isDisabled}>
                <div className="register-flex">
                  <div className="register-column">
                    <div className="radio-group">
                      <RadioButton
                        name="salutation"
                        label={t('ms')}
                        value="true"
                        onClick={this.setTrue}
                        defaultChecked={isFemale}
                      />
                      <RadioButton
                        name="salutation"
                        label={t('mr')}
                        value="false"
                        onClick={this.setTrue}
                        defaultChecked={!isFemale}
                      />
                    </div>
                    <div className="personal-info">
                      {(errorFirstname || errorLastname) && (
                        <div>
                          <label className="error block" htmlFor="agb">
                            {t('enter-name')}
                          </label>
                        </div>
                      )}
                      <Input
                        label={t('firstname')}
                        name="firstname"
                        onChange={onChange}
                        parentClass="form-group-half"
                        placeholder={t('firstname')}
                        required
                      />
                      <Input
                        label={t('lastname')}
                        name="lastname"
                        onChange={onChange}
                        parentClass="form-group-half"
                        placeholder={t('lastname')}
                        required
                      />
                    </div>
                    <div className="company-info">
                      <Input
                        label={t('company')}
                        name="company"
                        onChange={onChange}
                        parentClass="form-group"
                        placeholder={t('company')}
                        required
                        error={errorCompany}
                        errormsg={t('enter-company')}
                      />
                      <Input
                        label={t('postal-code')}
                        name="postal_code"
                        onChange={onChange}
                        parentClass="form-group"
                        placeholder={t('postal-code')}
                      />
                    </div>
                    <div className="country-dropdown dropdown-list">
                      <Select
                        label={t('country')}
                        name="country_code"
                        onChange={changeCountry}
                        options={items}
                        parentClass="form-group input__select"
                      />
                    </div>
                  </div>
                  <div className="register-column">
                    <div className="personal-contacts">
                      <Input
                        label={t('phone')}
                        name="phone"
                        onChange={onChange}
                        parentClass="form-group"
                        required
                        placeholder={t('phone-placeholder')}
                        type="tel"
                        error={errorPhone}
                        errormsg={t('enter-phone')}
                      />
                      <Input
                        label={t('email')}
                        name="email"
                        onChange={onChange}
                        parentClass="form-group"
                        required
                        placeholder={t('email-placeholder')}
                        type="email"
                        error={errorEmail}
                        errormsg={t('enter-email')}
                      />
                      <div className="register-password">
                        <div>
                          {errorPassword && (
                            <label className="error" htmlFor="agb">
                              {t('enter-password')}
                            </label>
                          )}
                        </div>
                        <div>
                          <Input
                            label={t('password')}
                            name="password"
                            type="password"
                            className="form-control active"
                            parentClass="form-group-half"
                            ref={ref => {
                              this.passwordInput = ref;
                            }}
                            placeholder={t('password-placeholder')}
                            onChange={onChange}
                            required
                          />
                          <Input
                            label={t('confirm-password')}
                            name="password_repeat"
                            type="password"
                            className="form-control active"
                            parentClass="form-group-half"
                            ref={ref => {
                              this.passwordInput = ref;
                            }}
                            placeholder={t('password-placeholder-2')}
                            onChange={onChange}
                            required
                          />
                        </div>
                        {errorPasswordRepeat && (
                          <label className="error" htmlFor="agb">
                            {t('enter-password-repeat')}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                {this.props.isCaptchaRequired && (
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={this.props.onChangeCaptcha} />
                )}
                <div className="clearfix" />
                <div className="register-flex">
                  <div className="agb">
                    <Checkbox
                      alignCheckboxLeft
                      className="agb__checkbox"
                      id="agb"
                      name="agb"
                      onChange={this.setAgb}
                      checked={this.state.agbAccepted}
                    />
                    {errorAgb && (
                      <label className="agb__error" htmlFor="agb">
                        {t('accept-agb')}
                      </label>
                    )}
                    <p className="agb__text">
                      {t('agb')}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.servicepartner.one/de/agb"
                      >
                        {t('agb2')}
                      </a>
                      {t('agb3')}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://one-ifm.com/en/datenschutzerklaerung/"
                      >
                        {t('agb4')}
                      </a>
                      {t('agb5')}
                    </p>
                  </div>
                  <RegisterControls />
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RegisterStepOne.propTypes = {
  isDisabled: PropTypes.bool,
  isCaptchaRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeCaptcha: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errorPassword: PropTypes.bool,
  errorPasswordRepeat: PropTypes.bool,
  errorEmail: PropTypes.bool,
  errorFirstname: PropTypes.bool,
  errorLastname: PropTypes.bool,
  errorCompany: PropTypes.bool,
  errorPhone: PropTypes.bool,
  errorAgb: PropTypes.bool
};

const mapStateToProps = ({ register: { isRegistering: isDisabled, isCaptchaRequired } }) => ({
  isDisabled,
  isCaptchaRequired
});

export default withTranslation('Public/Register')(connect(mapStateToProps)(RegisterStepOne));
