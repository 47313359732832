import { createSelector } from 'reselect';

const getMembersFilter = state => state;

export default createSelector(
  [getMembersFilter],
  members => {
    if (!members) return null;
    return members.filter(member => !member.inactive);
  }
);
