import { SHOP_CATEGORIES_REQUEST, SHOP_CATEGORIES_SUCCESS, SHOP_CATEGORIES_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

export default () =>
  authCall({
    shouldFetch: ({
      shop: {
        categories: {
          list: { isFetching, fetchedAt }
        }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [SHOP_CATEGORIES_REQUEST, SHOP_CATEGORIES_SUCCESS, SHOP_CATEGORIES_FAILURE],
    endpoint: '/user/shop/categories',
    method: 'GET'
  });
