import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import storage from 'store';

const isClosed = key => !!(storage.enabled && storage.get(key));

export default storageKey => WrappedComponent => {
  class Ad extends PureComponent {

    constructor(props) {
      super(props);

      this.state = {
        isVisible: props.isVisible && !isClosed(storageKey)
      };

      this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
      if (this.state.isVisible) this.props.onView();
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.isVisible !== nextProps.isVisible) {
        this.setState({
          isVisible: nextProps.isVisible && !isClosed(storageKey)
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.isVisible && !prevState.isVisible) {
        this.props.onView();
      }
    }

    handleClose() {
      if (storage.enabled) storage.set(storageKey, true);

      this.props.onClose();

      this.setState({
        isVisible: false
      });
    }

    render() {
      const { isVisible } = this.state;

      return (
        <div>
          {isVisible && <WrappedComponent
            {...this.props}
            onClose={this.handleClose}
          />}
        </div>
      );
    }
  }

  Ad.propTypes = {
    onView: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isVisible: PropTypes.bool
  };

  return Ad;
};
