import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cancelCheckout } from 'actions/shop/cart';
import { translateEditing } from '../HOC';

const CancelButton = ({ isLoading, isDisabled, onClick, t }) => (
  <button
    type="button"
    className={`button checkout-back ${isLoading ? 'loading' : ''} ${isDisabled ? 'disabled' : ''}`}
    onClick={onClick}
  >
    {t('cancel')}
  </button>
);

CancelButton.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isCancelling, isRequestingPayment, isDeleting }
  }
}) => ({
  isLoading: isCancelling,
  isDisabled: isRequestingPayment || isDeleting
});

const mapDispatchToProps = {
  onClick: cancelCheckout
};

export { CancelButton as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CancelButton)
);
