import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import { SingleDatePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class DateTimeGroup extends React.Component {
    static propTypes = {
      onChange: PropTypes.func,
      datetime: PropTypes.string,
      idDate: PropTypes.string,
      idTime: PropTypes.string,
      isOutsideRange: PropTypes.func,
      className: PropTypes.string
    };

    state = {
      time: this.props.datetime && moment.utc(this.props.datetime),
      date: this.props.datetime && moment.utc(this.props.datetime)
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevState.time !== this.state.time || prevState.date !== this.state.date) {
        this.onChange();
      }
    }

    onChange = () => {
      if (typeof this.props.onChange === 'function') {
        const { date, time } = this.state;
        const dateTime = this.getDateTime();
        return this.props.onChange({
          datetime: dateTime,
          isoDate: dateTime && typeof dateTime.toISOString ? dateTime.toISOString() : null,
          date: date && typeof date.format === 'function' ? date.format('YYYY-MM-DD') : null,
          time: time && typeof time.format === 'function' ? time.format('HH:mm') : null
        });
      }
      return null;
    };

    getDate = () => this.state.date;

    getTime = () => this.state.time;

    getDateTime = () => {
      const time = this.getTime();
      const date = this.getDate();

      if (date && time) {
        return date.set('hours', time.get('hours')).set('minutes', time.get('minutes'));
      }
      return null;
    };

    setDate = date => {
      this.setState(() => ({
        date
      }));
    };

    setTime = time => {
      if (time) {
        return this.setState(() => ({
          time
        }));
      }
      return this.setState(() => ({
        time: null
      }));
    };

    render() {
      const { t, isOutsideRange, className } = this.props;
      return (
        <div className={`cleaning-services__times ${className || ''}`}>
          <SingleDatePicker
            placeholder={t('date-placeholder')}
            date={this.state.date}
            id={this.props.idDate}
            onDateChange={this.setDate}
            showClearDate
            numberOfMonths={1}
            focused={this.state.focused}
            onFocusChange={({ focused }) =>
              this.setState({
                focused
              })
            }
            hideKeyboardShortcutsPanel
            isOutsideRange={isOutsideRange}
            openDirection="up"
          />
          <TimePicker
            showSecond={false}
            id={this.props.idTime}
            placeholder={t('time-placeholder')}
            className="cleaning-services__timepick"
            defaultValue={this.state.time}
            onChange={this.setTime}
          />
        </div>
      );
    }
}

export default withTranslation('Services/Cleaning')(DateTimeGroup);
