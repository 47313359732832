import storage from 'store';
import { restorePartners } from '../../actions/shop/partners';
import { setList } from '../../actions/shop/view';
import memoize from '../memoize';

const getCustomerId = state => state.current.customer && state.current.customer.id;

export default dispatch => {
  if (!storage.enabled) return () => {};

  return memoize([getCustomerId], customerId => {
    if (!customerId) return;

    const data = storage.get(customerId);

    if (data && data.partners) {
      dispatch(restorePartners(data.partners));
    }

    if (data && data.listView) {
      dispatch(setList());
    }
  });
};
