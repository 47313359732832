import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '..';

const ReorderButton = ({ item, t }) => {
  const tooltipId = `tooltip-sed-${item.id}-reorder-button`;

  if (!item.is_reorderable) return null;

  return (
    <Tooltip id={tooltipId}>
      <button
        type="button"
        className="sed-reorder"
        data-for={tooltipId}
        data-tip={t('order-again')}
        data-effect="solid"
      >
                &nbsp;
      </button>
    </Tooltip>
  );
};

ReorderButton.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export { ReorderButton as PureComponent };
export default pure(withTranslation('Modal/ServiceEvent/ReorderButton')(ReorderButton));
