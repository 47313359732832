import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tooltip } from 'common';
import { toggleFavorite } from 'actions/shop/favorites';

const FavoriteButton = ({ id, isActive, isLoading, onClick, t }) => {
  const className = classNames('product-fave', {
    favorited: isActive,
    'disabled loading': isLoading
  });
  const tooltipId = `tooltip-product-${id}-favorite-button`;

  return (
    <Tooltip className="fav-tooltip-container" id={tooltipId}>
      <button
        type="button"
        className={className}
        onClick={onClick}
        data-for={tooltipId}
        data-tip={t(isActive ? 'remove-from-favourites' : 'add-to-favourites')}
        data-effect="solid"
        data-place="bottom"
      />
    </Tooltip>
  );
};

FavoriteButton.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (
  {
    shop: {
      favorites: {
        create,
        delete: remove,
        list: { items }
      }
    }
  },
  { id }
) => ({
  isLoading: !!(create[id] && create[id].isCreating) || !!(remove[id] && remove[id].isDeleting),
  isActive: items.includes(id)
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onClick: () => dispatch(toggleFavorite(id))
});

export { FavoriteButton as PureComponent };
export default withTranslation('Shop/Product')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FavoriteButton)
);
