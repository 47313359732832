import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Input, Select, InputGroup, InputGroupAddon } from 'common/Form';
import { IntensiveCleaningTasklistLink } from 'components/Modal';
import IntensiveCleaningContainer from './IntensiveCleaningContainer';
import ConditionalContent from '../Common/ConditionalContent';
import CleaningBox from '../Common/CleaningBox';
import TrueOrFalse from '../Common/TrueOrFalse';

class Step1 extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  nextStep = () => {
    const { validateStep1 } = this.props;
    if (validateStep1() === true) {
      return this.props.history.push('/cleaning/intensive-cleaning/step-2');
    }
    return null;
  };

  render() {
    const { t, onChange, values, invalidFields } = this.props;
    const coloredWidth = (values.floor_space - 50) / 4.5;

    const floorTypeOptions = [
      { name: t('floor-type-tiling'), value: 'tiling' },
      { name: t('floor-type-carpet'), value: 'carpet' },
      { name: t('floor-type-laminate'), value: 'laminate' },
      { name: t('floor-type-parquet'), value: 'parquet' },
      { name: t('floor-type-linoleum'), value: 'linoleum' },
      { name: t('floor-type-pvc'), value: 'pvc' }
    ];

    return (
      <form>
        <div className="cleaning-services__headline">{t('facility-info-headline')}</div>

        <div className="cleaning-services__boxed" id="block--floor_space">
          {invalidFields.floor_space ? <p className="form-error">{t('error')}</p> : ''}

          <p id="block--floor_space" className="cleaning-services__label">
            {t('object-size')}
          </p>

          <p className="facility-size-output">
            {values.floor_space > 499 && t('more')} {values.floor_space} m<sup>2</sup>
          </p>

          <div className="relative">
            <Input
              id="slider"
              onChange={onChange}
              value={values.floor_space || '50'}
              min="50"
              max="500"
              name="floor_space"
              step="50"
              type="range"
            />
            <div className="range-colored" style={{ width: `${coloredWidth}%` }} />
          </div>

          <label
            htmlFor={`cleaning-service-${'nrEmployees'}`}
            id="block--number_of_employees"
            className="cleaning-services__label"
          >
            {t('no-of-employees')}
          </label>

          {invalidFields.number_of_employees ? <p className="form-error">{t('error-employees')}</p> : ''}

          <InputGroup>
            <input
              type="number"
              name="number_of_employees"
              min="5"
              value={values.number_of_employees}
              onChange={onChange}
              id={`cleaning-service-${'nrEmployees'}`}
              className={`cleaning-service__${'nrEmployees'} ${invalidFields.number_of_employees ? 'input-error' : ''}`}
              required={false}
            />
            <InputGroupAddon>{t('employees')}</InputGroupAddon>
          </InputGroup>
        </div>

        <div className="cleaning-services__headline" id="block--cleaning-areas-headline">
          {t('define-cleaning-area-headline')}
        </div>
        {invalidFields.all_cleaning_areas ? <p className="form-error">{t('all-areas-error')}</p> : ''}

        <CleaningBox headline={t('work-space-headline')}>
          <p>{t('work-space-switch')}</p>

          <TrueOrFalse name="office" initialValue={values.office} onChange={onChange} />

          <ConditionalContent isVisible={values.office} hideOnly>
            <p className="cleaning-services__label" id="block--office_cleaning_area_size">
              {t('work-space-size')}
            </p>

            {invalidFields.office_cleaning_area_size ? <p className="form-error">{t('error-numbers')}</p> : ''}

            <InputGroup>
              <input
                type="number"
                name="office_cleaning_area_size"
                min="1"
                value={values.office_cleaning_area_size}
                onChange={onChange}
                id={`cleaning-service-${'workSpaceSize'}`}
                className={`cleaning-service__${'workSpaceSize'}
                                    ${invalidFields.office_cleaning_area_size ? 'input-error' : ''}`}
                required={false}
              />

              <InputGroupAddon>
                m<sup>2</sup>
              </InputGroupAddon>
            </InputGroup>

            <p className="cleaning-services__label">{t('dishes')}</p>

            <TrueOrFalse name="office_collect_dishes" initialValue={values.office_collect_dishes} onChange={onChange} />

            <p className="cleaning-services__label" id="block--office_floor_type">
              {t('floor-type')}
            </p>
            {invalidFields.office_floor_type ? <p className="form-error">{t('error')}</p> : ''}
            <Select
              name="office_floor_type"
              onChange={onChange}
              options={floorTypeOptions}
              value={values.office_floor_type || undefined}
              className={`${invalidFields.office_floor_type ? 'input-error' : ''}`}
              dataType={t('choose-floor-type')}
            />

            <p className="cleaning-services__label">{t('work-space-deep-clean')}</p>

            <TrueOrFalse name="office_deep_cleaning" initialValue={values.office_deep_cleaning} onChange={onChange} />

            <p className="cleaning-services__label">{t('furniture-move')}</p>

            <TrueOrFalse name="office_move_furniture" initialValue={values.office_move_furniture} onChange={onChange} />

            <p className="cleaning-services__label">{t('office-equipment')}</p>

            <TrueOrFalse
              name="office_clean_equipment"
              initialValue={values.office_clean_equipment}
              onChange={onChange}
            />
          </ConditionalContent>
        </CleaningBox>

        <CleaningBox headline={t('bathrooms')}>
          <p>{t('bathroom-switch')}</p>

          <TrueOrFalse name="bathroom" initialValue={values.bathroom} onChange={onChange} />

          <ConditionalContent isVisible={values.bathroom} hideOnly>
            <p className="cleaning-services__label" id="block--bathroom_cleaning_area_size">
              {t('bathroom-size')}
            </p>
            {invalidFields.bathroom_cleaning_area_size ? <p className="form-error">{t('error-numbers')}</p> : ''}
            <InputGroup>
              <input
                type="number"
                name="bathroom_cleaning_area_size"
                min="1"
                value={values.bathroom_cleaning_area_size}
                onChange={onChange}
                id={`cleaning-service-${'bathroomSize'}`}
                className={`cleaning-service__${'bathroomSize'}
                                    ${invalidFields.bathroom_cleaning_area_size ? 'input-error' : ''}`}
              />
              <InputGroupAddon>
                m<sup>2</sup>
              </InputGroupAddon>
            </InputGroup>

            <p className="cleaning-services__label">{t('bathroom-deep-clean')}</p>

            <TrueOrFalse
              name="bathroom_deep_cleaning"
              initialValue={values.bathroom_deep_cleaning}
              onChange={onChange}
            />
          </ConditionalContent>
        </CleaningBox>

        <CleaningBox headline={t('kitchen')}>
          <p>{t('kitchen-switch')}</p>

          <TrueOrFalse name="kitchen" initialValue={values.kitchen} onChange={onChange} />

          <ConditionalContent isVisible={values.kitchen} hideOnly>
            <p className="cleaning-services__label" id="block--kitchen_cleaning_area_size">
              {t('kitchen-size')}
            </p>
            {invalidFields.kitchen_cleaning_area_size ? <p className="form-error">{t('error-numbers')}</p> : ''}
            <InputGroup>
              <input
                type="number"
                name="kitchen_cleaning_area_size"
                min="1"
                value={values.kitchen_cleaning_area_size}
                onChange={onChange}
                id={`cleaning-service-${'kitchenSize'}`}
                className={`cleaning-service__${'kitchenSize'}
                                    ${invalidFields.kitchen_cleaning_area_size ? 'input-error' : ''}`}
              />
              <InputGroupAddon>
                m<sup>2</sup>
              </InputGroupAddon>
            </InputGroup>

            <p className="cleaning-services__label" id="block--kitchen_floor_type">
              {t('kitchen-floor-type')}
            </p>
            {invalidFields.kitchen_floor_type ? <p className="form-error">{t('error')}</p> : ''}

            <Select
              name="kitchen_floor_type"
              onChange={onChange}
              value={values.kitchen_floor_type || undefined}
              options={floorTypeOptions}
              className={`${invalidFields.kitchen_floor_type ? 'input-error' : ''}`}
              dataType={t('choose-floor-type')}
            />

            <p className="cleaning-services__label">{t('kitchen-deep-clean')}</p>

            <TrueOrFalse name="kitchen_deep_cleaning" initialValue={values.kitchen_deep_cleaning} onChange={onChange} />

            <p className="cleaning-services__label">{t('furniture-move')}</p>

            <TrueOrFalse
              name="kitchen_move_furniture"
              initialValue={values.kitchen_move_furniture}
              onChange={onChange}
            />
          </ConditionalContent>
        </CleaningBox>

        <CleaningBox headline={t('traffic-area-headline')}>
          <p>{t('traffic-area-switch')}</p>

          <TrueOrFalse name="entrance" initialValue={values.entrance} onChange={onChange} />

          <ConditionalContent isVisible={values.entrance} hideOnly>
            <p className="cleaning-services__label" id="block--entrance_cleaning_area_size">
              {t('traffic-area-size')}
            </p>
            {invalidFields.entrance_cleaning_area_size ? <p className="form-error">{t('error-numbers')}</p> : ''}
            <InputGroup>
              <input
                type="number"
                name="entrance_cleaning_area_size"
                min="1"
                value={values.entrance_cleaning_area_size}
                onChange={onChange}
                id={`cleaning-service-${'trafficAreaSize'}`}
                className={`cleaning-service__${'trafficAreaSize'}
                                    ${invalidFields.entrance_cleaning_area_size ? 'input-error' : ''}`}
              />
              <InputGroupAddon>
                m<sup>2</sup>
              </InputGroupAddon>
            </InputGroup>

            <p className="cleaning-services__label" id="block--entrance_floor_type">
              {t('traffic-area-floor-type')}
            </p>
            {invalidFields.entrance_floor_type ? <p className="form-error">{t('error')}</p> : ''}
            <Select
              name="entrance_floor_type"
              onChange={onChange}
              value={values.entrance_floor_type || undefined}
              options={floorTypeOptions}
              className={`${invalidFields.entrance_floor_type ? 'input-error' : ''}`}
              dataType={t('choose-floor-type')}
            />

            <p className="cleaning-services__label">{t('traffic-area-deep-clean')}</p>

            <TrueOrFalse
              name="entrance_deep_cleaning"
              initialValue={values.entrance_deep_cleaning}
              onChange={onChange}
            />

            <p className="cleaning-services__label">{t('furniture-move')}</p>

            <TrueOrFalse
              name="entrance_move_furniture"
              initialValue={values.entrance_move_furniture}
              onChange={onChange}
            />
          </ConditionalContent>
        </CleaningBox>

        <CleaningBox headline={t('other')}>
          <p>{t('other-switch')}</p>

          <TrueOrFalse name="other" initialValue={values.other} onChange={onChange} />

          <ConditionalContent isVisible={values.other} hideOnly>
            <p className="cleaning-services__label" id="block--other_details">
              {t('other-details')}
            </p>
            {invalidFields.other_details ? <p className="form-error">{t('error')}</p> : ''}
            <input
              name="other_details"
              value={values.other_details}
              onChange={onChange}
              className={`${invalidFields.other_details ? 'input-error' : ''}`}
            />
          </ConditionalContent>
        </CleaningBox>

        <IntensiveCleaningTasklistLink className="cleaning-service__link" />

        <div className="cleaning-services__buttongroup">
          <Link className="cleaning-services__button" to="/cleaning/cleaning-menu">
            {t('cancel')}
          </Link>
          <button className="cleaning-services__next-step" type="button" onClick={this.nextStep}>
            {t('next')}
          </button>
        </div>
      </form>
    );
  }
}

export default IntensiveCleaningContainer(Step1);
