import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { pure } from 'recompose';
import { currency } from 'components/HOC';
import { RRule } from 'rrule';
import { connect } from 'react-redux';
import { Button, Modal } from '@spone/ui';
import cx from 'classnames';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

import { getRepeatDays } from 'utils/schedules';
import priceToNumber from 'utils/priceToNumber';
import { DocumentTitle } from 'common';
import {
  getOfferByToken,
  rejectOffer as rejectOfferAction,
  confirmOffer as confirmOfferAction,
  downloadOfferPdf as downloadOfferPdfAction
} from 'actions/offers';
import { OfferRejectModal, OfferAcceptModal } from '.';

import './OfferReview.less';

const OfferReview = ({
  t,
  c,
  offer,
  getOffer,
  rejectOffer,
  match: {
    params: { token }
  },
  isLoading,
  confirmOffer,
  offerConfirmSuccess,
  offerRejectSuccess,
  downloadOfferPdf
}) => {
  const [isRejectOfferOpen, setIsRejectOfferOpen] = useState(false);
  const [isAcceptOfferOpen, setIsAcceptOfferOpen] = useState(false);
  const [isOfferRejected, setIsOfferRejected] = useState(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  let totalVat = 0;
  const repeatDays = getRepeatDays();

  useEffect(() => {
    document.body.classList.add('offer-review-page');
    getOffer(token);

    return () => {
      document.body.classList.remove('offer-review-page');
    };
  }, [getOffer, token]);

  useEffect(() => {
    if (offerConfirmSuccess) {
      setIsOfferAccepted(true);
    }
  }, [offerConfirmSuccess]);

  useEffect(() => {
    if (offerRejectSuccess) {
      setIsOfferRejected(true);
    }
  }, [offerRejectSuccess]);

  const formatServicePeriod = (startDate, endDate) => {
    let startText = '';
    let endText = '';

    if (startDate) {
      startText = format(new Date(startDate), 'dd.MM.yyyy');
    }

    if (endDate) {
      endText = format(new Date(endDate), 'dd.MM.yyyy');
    }

    return `${startText} - ${endText}`;
  };

  const hideRejectOfferModal = () => {
    setIsRejectOfferOpen(false);
  };

  const hideAcceptOfferModal = () => {
    setIsAcceptOfferOpen(false);
  };

  const handleRejectOffer = ({ comment }) => {
    rejectOffer(token, comment).then(() => hideRejectOfferModal());
  };

  const handleAcceptOffer = offerData => {
    confirmOffer(token, { ...offerData, startDate: format(new Date(offerData.startDate), 'yyyy-MM-dd') }).then(() =>
      hideAcceptOfferModal()
    );
  };

  const handleDownloadPdf = () => {
    downloadOfferPdf(offer.offer_pdf).then(resp => {
      return resp.blob().then(blob => {
        const fileNameHeader = resp.headers.get('content-disposition');
        const fileName = fileNameHeader.match(/filename="(.+)"/)[1];
        return saveAs(blob, fileName);
      });
    });
  };

  const formatFrequency = () => {
    if (offer.type === 'recurrent') {
      let rruleText = '';
      if (offer.rrule) {
        const rule = RRule.fromString(offer.rrule);
        delete rule.options.until;
        delete rule.origOptions.until;
        delete rule.options.count;
        rruleText = rule.toText();
      } else if (offer.frequency) {
        rruleText = `${get(offer, 'interval', '')}x ${t(`OfferReview:${offer.frequency}`)}`;
      }

      return rruleText;
    }

    return t('OfferReview:one-time');
  };

  // Get total products VAT amount
  if (offer && offer.products) {
    offer.products.forEach(product => {
      const { quantity, price, taxRate } = product;
      const unitItemPrice = priceToNumber(price);
      const totalNetPrice = quantity * unitItemPrice;
      const totalItemVat = taxRate > 0 ? (totalNetPrice / 100) * taxRate : 0;

      totalVat += totalItemVat;
    });
  }

  return (
    <DocumentTitle title={t('OfferReview:offer')}>
      <div
        className={cx('offer-review col-group content-area', {
          rejected: isOfferRejected || get(offer, 'status') === 'Closed Lost' || get(offer, 'status') === 'Rejected',
          accepted: isOfferAccepted || get(offer, 'status') === 'Accepted',
          closed: get(offer, 'status') === 'Closed Won'
        })}
      >
        {!isLoading && !offer && <p className="offer-error">{t('OfferReview:offer-not-found')}</p>}

        {offer && !isLoading && (
          <>
            <div className="offer-header">
              <div className="offer-header-name">
                {offer.name}
                <div className="offer-header-number">
                  {t('OfferReview:angebot-number')}: {offer.number || '-'}
                </div>
                <div className="offer-header-status">
                  {(offer.status === 'Closed Lost' || offer.status === 'Rejected') && t('OfferReview:rejected')}
                  {offer.status === 'Accepted' && t('OfferReview:accepted')}
                  {offer.status === 'Closed Won' && t('OfferReview:closed')}
                </div>
              </div>

              <div className="offer-header-right">
                <div className="offer-header-buttons">
                  <Button className="btn-download button-blue" onClick={handleDownloadPdf}>
                    <span>{t('OfferReview:download-pdf')}</span>
                  </Button>
                </div>
              </div>
            </div>

            <div className="offer-content">
              <div className="offer-left-section">
                <div className="offer-general-info offer-section">
                  <div className="offer-section-title">{t('OfferReview:general-informations')}</div>
                  <div className="offer-general-row">
                    <span className="icon-period">{t('OfferReview:service-period')}</span>{' '}
                    {formatServicePeriod(offer.start_date, offer.end_date)}
                  </div>
                  <div className="offer-general-row">
                    <span className="icon-freq">{t('OfferReview:frequency')}</span> {!!offer && formatFrequency()}
                  </div>
                  <div className="offer-general-row">
                    <span className="icon-valid">{t('OfferReview:valid-until')}</span>{' '}
                    {offer.due_date ? format(new Date(offer.due_date), 'dd.MM.yyyy') : '-'}
                  </div>
                </div>

                <div className="offer-address offer-section">
                  <div className="offer-section-title">{t('OfferReview:Address')}</div>

                  <div className="offer-subsections">
                    <div className="offer-subsection">
                      <div className="offer-section-subtitle">{t('OfferReview:service-address')}</div>
                      <div>
                        {offer.customer.name} {offer.customer.legalForm}
                      </div>
                      <div>{get(offer, 'customer.shipping_address.street')}</div>
                      <div>
                        {get(offer, 'customer.shipping_address.postal_code')}{' '}
                        {get(offer, 'customer.shipping_address.city')}
                      </div>
                      <div>
                        {t(
                          `Settings/Customer/BillingAddressForm:${get(offer, 'customer.shipping_address.country_code')}`
                        )}
                      </div>
                    </div>

                    <div className="offer-subsection">
                      <div className="offer-section-subtitle">{t('OfferReview:billing-provider')}</div>
                      <div>{get(offer, 'customer.billing_address.street')}</div>
                      <div>
                        {get(offer, 'customer.billing_address.postal_code')}{' '}
                        {get(offer, 'customer.billing_address.city')}
                      </div>
                      <div>
                        {t(
                          `Settings/Customer/BillingAddressForm:${get(offer, 'customer.billing_address.country_code')}`
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offer-address offer-section">
                  <div className="offer-section-title">{t('OfferReview:service-provider')}</div>

                  <div className="offer-subsections">
                    <div className="offer-subsection">
                      <div>
                        {offer.partner.name} {offer.partner.legalForm}
                      </div>
                      <div>
                        {t('OfferReview:manager-in-charge')}:{' '}
                        {`${get(offer, 'contact.firstname', '')} ${get(offer, 'contact.lastname', '')}`}
                      </div>
                      <div>
                        {t('OfferReview:phone')}: {get(offer, 'contact.phone', '')}
                      </div>
                      <div>
                        {t('OfferReview:email')}: {get(offer, 'contact.email', '')}
                      </div>
                    </div>

                    <div className="offer-subsection">
                      <div>{get(offer, 'partner.billing_address.street')}</div>
                      <div>
                        {get(offer, 'partner.billing_address.postal_code')} {get(offer, 'partner.billing_address.city')}
                      </div>
                      <div>
                        {t(
                          `Settings/Customer/BillingAddressForm:${get(offer, 'partner.billing_address.country_code')}`
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offer-additional-info offer-section">
                  <div className="offer-section-title">{t('OfferReview:additional-info')}</div>

                  <div className="offer-subsections">
                    <div className="offer-subsection">
                      <div className="offer-section-subtitle">{t('OfferReview:description')}</div>
                      <div>{offer.description}</div>
                    </div>
                  </div>
                </div>

                <div className="offer-products offer-section">
                  <div className="offer-section-title">{t('OfferReview:products')}</div>

                  <div className="section-table width-80-20">
                    <div className="section-table-head">
                      <div className="head-col">{t('OfferReview:item')}</div>
                      <div className="head-col">{t('OfferReview:total-price')}</div>
                      <div className="head-col">{t('OfferReview:tax-rate')}</div>
                    </div>

                    <div className="section-table-body">
                      {offer &&
                        offer.products.map(product => {
                          let totalPrice = product.price;

                          if (typeof product.price === 'string') {
                            totalPrice = parseFloat(product.price.replace(',', '.'));
                          }

                          return (
                            <div className="body-row" key={`product_${product.id || product.name}`}>
                              <div className="body-col">
                                {product.quantity} {product.name} à {c(product.price)}
                              </div>
                              <div className="body-col">{c(totalPrice * product.quantity)}</div>
                              <div className="body-col">{product.taxRate}%</div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="offer-tasks offer-section">
                  <div className="offer-section-title">{t('OfferReview:task-list')}</div>

                  <div className="offer-tasks-group">
                    {offer &&
                      offer.task_groups.map(taskGroup => (
                        <div className="task-group" key={taskGroup.sortOrder}>
                          <div className="task-group-header">
                            <div className="task-group-name">{taskGroup.name}</div>

                            <div className="task-group-days">
                              {repeatDays.map(day => (
                                <div
                                  className={cx('day', {
                                    active: taskGroup.days && taskGroup.days.includes(+day.value)
                                  })}
                                  key={day.value}
                                >
                                  {day.label.substring(0, 2)}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="offer-tasks-list">
                            {taskGroup.tasks.length > 0 &&
                              taskGroup.tasks.map(task => (
                                <div className="offer-tasks-item" key={task.sortOrder}>
                                  {task.name}
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="offer-sidebar">
                <div className="offer-sidebar-title">{t('OfferReview:your-offer-price')}</div>
                <div className="offer-sidebar-pricerow">
                  <span>{t('OfferReview:net-price')}</span> <span>{c(offer.total_price_before_discount)}</span>
                </div>
                <div className="offer-sidebar-pricerow">
                  <span>{t('OfferReview:total-vat')}</span> <span>{c(totalVat)}</span>
                </div>
                <div className="divider" />
                <div className="offer-sidebar-pricerow brutto">
                  <span>{t('OfferReview:gross-price')}</span> <span>{c(offer.total_value)}</span>
                </div>

                <div className="offer-sidebar-buttons">
                  <Button className="btn button-blue" onClick={() => setIsAcceptOfferOpen(true)}>
                    {t('OfferReview:confirm')}
                  </Button>
                  <Button
                    color="red"
                    variant="link"
                    className="btn btn-reject"
                    onClick={() => setIsRejectOfferOpen(true)}
                  >
                    {t('OfferReview:reject')}
                  </Button>
                </div>

                <p className="offer-sidebar-text">{t('OfferReview:help-text')}</p>

                <div className="offer-sidebar-bank">
                  <div className="offer-sidebar-bank-title">{t('OfferReview:bank-acc')}</div>
                  <div className="offer-sidebar-bank-row">
                    {t('OfferReview:owner')}: {get(offer, 'partner.name') || '-'}
                  </div>
                  <div className="offer-sidebar-bank-row">
                    {t('OfferReview:iban')}: {get(offer, 'partner.ibanNumber') || '-'}
                  </div>
                  <div className="offer-sidebar-bank-row">
                    {t('OfferReview:tax-number')}: {get(offer, 'partner.taxNumber') || '-'}
                  </div>
                  <div className="offer-sidebar-bank-row">
                    {t('OfferReview:ust')}: {get(offer, 'partner.ustIdNo') || '-'}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Modal isOpen={isRejectOfferOpen} onClose={hideRejectOfferModal} title={t('OfferReview:reject-offer')}>
          <OfferRejectModal handleRejectOffer={handleRejectOffer} />
        </Modal>

        <Modal isOpen={isAcceptOfferOpen} onClose={hideAcceptOfferModal} title={t('OfferReview:accept-offer')}>
          <OfferAcceptModal handleAcceptOffer={handleAcceptOffer} offer={offer} />
        </Modal>
      </div>
    </DocumentTitle>
  );
};

const mapStateToProps = state => ({
  offer: state.offers.offer,
  isLoading: state.offers.loading,
  offerConfirmSuccess: state.offers.offerConfirmSuccess,
  offerRejectSuccess: state.offers.offerRejectSuccess
});

const mapDispatchToProps = dispatch => ({
  getOffer: token => dispatch(getOfferByToken(token)),
  rejectOffer: (token, comment) => dispatch(rejectOfferAction(token, comment)),
  confirmOffer: (token, offer) => dispatch(confirmOfferAction(token, offer)),
  downloadOfferPdf: pdfUrl => dispatch(downloadOfferPdfAction(pdfUrl))
});

export default pure(
  withTranslation(['OfferReview', 'Settings/Customer/BillingAddressForm'])(
    connect(mapStateToProps, mapDispatchToProps)(currency(OfferReview))
  )
);
