import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Select } from 'common/Form';
import { editBillingAddress } from 'actions/customer';
import { translateEditing } from '../HOC';

class BillingAddress extends Component {
  constructor(props) {
    super(props);

    const { name, shippingAddress } = this.props;

    this.state = {
      showEditBillingAddressForm: false,
      sameAsShipping: true,
      companyName: shippingAddress.name ? shippingAddress.name : name,
      street: shippingAddress.street ? shippingAddress.street : '',
      postalCode: shippingAddress.postal_code ? shippingAddress.postal_code : '',
      city: shippingAddress.city ? shippingAddress.city : '',
      countryCode: shippingAddress.country_code ? shippingAddress.country_code : 'DE'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.shoppingBillingAddress.isChanging && nextProps.shoppingBillingAddress.isChanged) {
      this.handleEditAddressButtonClick(true);
      this.props.handleBillingAddressSubmit();
    }

    if (
      !nextProps.shoppingShippingAddress.isChanging &&
      nextProps.shoppingShippingAddress.isChanged &&
      this.state.sameAsShipping
    ) {
      this.setState({
        street: nextProps.shoppingShippingAddress.item.company.shipping_address.street,
        postalCode: nextProps.shoppingShippingAddress.item.company.shipping_address.postal_code,
        city: nextProps.shoppingShippingAddress.item.company.shipping_address.city,
        countryCode: nextProps.shoppingShippingAddress.item.company.shipping_address.country_code
      });
    }
  }

  handleEditAddressButtonClick = (forceCloseDialog = false) => {
    const { name, item, shoppingBillingAddress } = this.props;
    const billingAddress = shoppingBillingAddress.item ? shoppingBillingAddress.item.company.billing_address : item;
    const companyName = shoppingBillingAddress.item ? shoppingBillingAddress.item.company.corporate_name : name;

    this.setState({
      companyName,
      street: billingAddress.street ? billingAddress.street : '',
      postalCode: billingAddress.postal_code ? billingAddress.postal_code : '',
      city: billingAddress.city ? billingAddress.city : '',
      countryCode: billingAddress.country_code ? billingAddress.country_code : ''
    });

    this.setState(prevState => ({
      showEditBillingAddressForm: forceCloseDialog ? false : !prevState.showEditBillingAddressForm
    }));
  };

  handleSameAddressCheckbox = () => {
    const { shippingAddress, name, handleSameAddressCheckboxClick } = this.props;

    handleSameAddressCheckboxClick(!this.state.sameAsShipping);

    if (!this.state.sameAsShipping) {
      this.setState(prevState => ({
        companyName: shippingAddress.name ? shippingAddress.name : name,
        street: shippingAddress.street ? shippingAddress.street : '',
        postalCode: shippingAddress.postal_code ? shippingAddress.postal_code : '',
        city: shippingAddress.city ? shippingAddress.city : '',
        countryCode: shippingAddress.country_code ? shippingAddress.country_code : '',
        sameAsShipping: !prevState.sameAsShipping
      }));

      return;
    }

    this.setState(prevState => ({
      sameAsShipping: !prevState.sameAsShipping
    }));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });

    this.props.handleBillingAddressChange();
  };

  handleSubmit = () => {
    const { companyName, street, postalCode, city, countryCode } = this.state;

    this.props.editAddressDetails(companyName, street, postalCode, city, countryCode);
  };

  shouldSubmit = () => {
    const { companyName, street, postalCode, city, countryCode } = this.state;

    return companyName && street && postalCode && city && countryCode;
  };

  isEquivalent = (shippingAddress, billingAddress) => {
    const props = ['street', 'postal_code', 'city'];

    for (let i = 0; i < props.length; i += 1) {
      const propName = props[i];

      if (shippingAddress[propName] !== billingAddress[propName]) {
        return false;
      }
    }
    return true;
  };

  renderEditAddressForm = (t, companyName, street, postalCode, city, countryCode) => {
    const items = [{ name: t('DE'), value: 'DE' }, { name: t('AU'), value: 'AU' }];

    return (
      <div className="company-address billing">
        <form>
          <fieldset>
            <button
              className={`billing-address-checkbox ${this.state.sameAsShipping ? 'accepted' : ''}`}
              onClick={() => this.handleSameAddressCheckbox()}
              type="button"
            >
              {t('addresses-identical')}
            </button>
            <fieldset disabled={this.state.sameAsShipping}>
              <Input
                autoFocus
                label={t('companyName')}
                name="companyName"
                parentClass="form-group"
                onChange={this.handleChange}
                value={companyName}
                disabled
              />

              <Input
                label={t('street')}
                name="street"
                parentClass="form-group"
                onChange={this.handleChange}
                value={street}
                required
                error={street === null || street === ''}
                errormsg={t('error-required')}
              />

              <div className="form-group flex-row">
                <Input
                  label={t('postCode')}
                  name="postalCode"
                  parentClass="company-zip row-group"
                  onChange={this.handleChange}
                  value={postalCode}
                  required
                  error={postalCode === null || postalCode === ''}
                  errormsg={t('error-required')}
                />

                <Input
                  label={t('city')}
                  name="city"
                  parentClass="company-city row-group"
                  onChange={this.handleChange}
                  value={city}
                  required
                  error={city === null || city === ''}
                  errormsg={t('error-required')}
                />
              </div>
              <div className="country-dropdown dropdown-list">
                <Select
                  label={t('country')}
                  name="country_code"
                  onChange={({ target: { value } }) => this.setState(() => ({ countryCode: value }))}
                  options={items}
                  parentClass="form-group input__select"
                  value={countryCode}
                />
              </div>
            </fieldset>
            <button
              type="button"
              className="button-blue button-save-address"
              onClick={this.handleSubmit}
              disabled={!this.shouldSubmit()}
            >
              {t('save')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  };

  renderAddressHeader = () => {
    const { t } = this.props;
    const { showEditBillingAddressForm } = this.state;

    return (
      <div className="cart-title flex">
        <span className="title-address">{t('billing-address')}</span>
        <div
          className="edit-button billing"
          onClick={() => this.handleEditAddressButtonClick(false)}
          role="presentation"
        >
          {!showEditBillingAddressForm ? <span className="edit-button-bg" /> : ''}
          <span className="edit-address">{showEditBillingAddressForm ? t('cancel-edit') : t('edit')}</span>
        </div>
      </div>
    );
  };

  renderAddress = (t, name, item) => (
    <div>
      {this.isEquivalent(this.props.shippingAddress, item) || this.state.sameAsShipping ? (
        <p className="cart-address">{t('addresses-identical')}</p>
      ) : (
        <div>
          <p className="cart-company">{name}</p>
          <p className="cart-address">
            {item.street}, {item.postal_code} {item.city}, {t(item.country_code)}
          </p>
        </div>
      )}
    </div>
  );

  render() {
    const { shoppingBillingAddress, item, t, isConverted } = this.props;
    const { showEditBillingAddressForm, companyName, street, postalCode, city, countryCode } = this.state;

    const billingAddress = shoppingBillingAddress.item ? shoppingBillingAddress.item.company.billing_address : item;
    if (!billingAddress && !isConverted) {
      return this.renderEditAddressForm(t, companyName, street, postalCode, city, countryCode);
    }

    return (
      <div>
        {!isConverted && (
          <div className="cart-item billing-address">
            {this.renderAddressHeader()}
            {showEditBillingAddressForm
              ? this.renderEditAddressForm(t, companyName, street, postalCode, city, countryCode)
              : this.renderAddress(t, companyName, billingAddress)}
          </div>
        )}
      </div>
    );
  }
}

BillingAddress.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
  t: PropTypes.func.isRequired,
  editAddressDetails: PropTypes.func.isRequired,
  shoppingBillingAddress: PropTypes.object,
  shippingAddress: PropTypes.object,
  isConverted: PropTypes.bool,
  handleBillingAddressSubmit: PropTypes.func.isRequired,
  handleBillingAddressChange: PropTypes.func.isRequired,
  handleSameAddressCheckboxClick: PropTypes.func.isRequired
};

const mapStateToProps = ({ current: { customer }, customer: { shoppingBillingAddress, shoppingShippingAddress } }) => ({
  item: customer && customer.billing_address,
  name: customer && (customer.corporate_name || customer.name),
  shoppingBillingAddress,
  shoppingShippingAddress,
  shippingAddress: customer && customer.shipping_address,
  isConverted: customer ? customer.is_converted : 0
});

const mapDispatchToProps = dispatch => ({
  editAddressDetails: (companyName, street, postalCode, city, countryCode) =>
    dispatch(editBillingAddress(companyName, street, postalCode, city, countryCode))
});

export { BillingAddress as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BillingAddress)
);
