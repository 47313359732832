import React from 'react';
import moment from 'moment';
import cx from 'classnames';

const CalendarDay = ({
  day,
  index,
  selectedDay,
  getServiceEventsForSelectedDay,
  setSelectedDay,
  setDaySelectedThisWeek
}) => {
  const newDay = moment(day).format('dddd, MMMM Do YYYY');
  const active = selectedDay === newDay ? 'active' : '';
  const eventsAvailableIndicator =
    getServiceEventsForSelectedDay(newDay).length && selectedDay !== newDay ? <div className="se-circle" /> : null;

  const handleDayClick = () => {
    setSelectedDay(newDay);
    setDaySelectedThisWeek(true);
  };

  return (
    <div className={cx('modul__day', active)}>
      <div className="day">
        {moment()
          .weekday(index)
          .format('ddd')}
      </div>
      <div className="date" role="presentation" onClick={handleDayClick}>
        {moment(newDay, 'dddd, MMMM Do YYYY').format('DD')}
      </div>
      {eventsAvailableIndicator}
    </div>
  );
};

export default CalendarDay;
