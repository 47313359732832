import { OFFER_SUBMIT_REQUEST, OFFER_SUBMIT_SUCCESS, OFFER_SUBMIT_FAILURE } from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case OFFER_SUBMIT_REQUEST: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isSubmitting: true, isSubmitted: false } };
    }

    case OFFER_SUBMIT_SUCCESS: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isSubmitting: false, isSubmitted: true } };
    }

    case OFFER_SUBMIT_FAILURE: {
      const {
        meta: { token }
      } = action;

      return { ...state, [token]: { ...state[token], isSubmitting: false } };
    }

    default:
      return state;
  }
};
