import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LOCATION_HASH_REJECT_PARTNER } from 'constants/location';
import { currency } from 'components/HOC';

const PriceBox = ({ t, c, offer, offerToken, handleSendOfferClick, offerIsSent, disabled }) => {
  let removedItems = 0;
  let totalPrice = 0;
  let totalTax = 0;
  const shouldShowPrice = offer[offerToken].item.show_price || offer[offerToken].item.subtype === 'Offer';

  return (
    <div className="offer-box-margin">
      <p className="offer-headline">{shouldShowPrice ? t('price-box') : t('summary')}</p>
      {offer[offerToken].item.performances.map((item, i) => {
        if (item.contract_status !== 'rejected') {
          totalPrice += item.total_price;
          totalTax += item.total_tax;
          return (
            <div key={`price-${item.public_token}`}>
              <div className="offer-pricerow single">
                <p className="ellipsis">
                  {t('task')} {i + 1}:
                </p>
                {shouldShowPrice && <span className="offer-price single__price">{c(item.total_price)}</span>}
              </div>
            </div>
          );
        }
        removedItems += 1;
        return null;
      })}
      {shouldShowPrice && (
        <div className="offer-pricerow tax">
          {t('tax')}
          <span className="offer-price"> {c(totalTax)}</span>
        </div>
      )}
      {shouldShowPrice && (
        <div className="offer-pricerow total">
          <strong>
            {t('total-price')}
            <span className="offer-price"> {c(totalPrice + totalTax)}</span>
          </strong>
        </div>
      )}
      {!shouldShowPrice && offer[offerToken].item.subtype === 'Performance Note' && (
        <span className="offer-placeholder">&nbsp;</span>
      )}
      {offerIsSent ? null : (
        <div className="col-group offer-buttons">
          {removedItems !== 0 && removedItems === offer[offerToken].item.performances.length ? (
            <p className="rejected-warning">{t('all-rejected')}</p>
          ) : null}
          {removedItems !== 0 && removedItems !== offer[offerToken].item.performances.length ? (
            <p className="rejected-warning">
              {removedItems} {t('some-rejected')}
            </p>
          ) : null}
          {removedItems > 0 && removedItems !== offer[offerToken].item.performances.length ? (
            <div
              onClick={() => handleSendOfferClick()}
              className={`button button-submit col-12 ${disabled ? 'loading-light disabled' : ''}`}
              role="presentation"
            >
              {offer[offerToken].item.performances.length - removedItems} {t('confirming')}
            </div>
          ) : null}
          {removedItems === 0 ? (
            <div
              onClick={() => handleSendOfferClick()}
              className={`button button-submit col-12 ${disabled ? 'loading-light disabled' : ''}`}
              role="presentation"
            >
              {t('confirming')}
            </div>
          ) : null}
          {removedItems === offer[offerToken].item.performances.length ? (
            <Link className="button button-blue col-12" to={LOCATION_HASH_REJECT_PARTNER}>
              {t('rejecting')}
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

PriceBox.propTypes = {
  offer: PropTypes.object.isRequired,
  offerToken: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired,
  handleSendOfferClick: PropTypes.func.isRequired,
  offerIsSent: PropTypes.string,
  disabled: PropTypes.bool
};

export { PriceBox as PureComponent };
export default currency(withTranslation('PartnerConfirmation')(PriceBox));
