import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Transition } from 'common';
import { DepositBox } from 'common/ServiceEvent';

class DepositButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxVisible: false
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isDeposited && nextProps.isDeposited) {
      this.closeBox();
    }
  }

  toggleBox(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isBoxVisible: !prevState.isBoxVisible
    }));
  }

  closeBox() {
    if (this.state.isBoxVisible) {
      this.setState({
        isBoxVisible: false
      });
    }
  }

  render() {
    const { item, t } = this.props;
    const { isBoxVisible } = this.state;

    return (
      <div className="event-deposit" onClick={e => e.preventDefault()} role="presentation">
        <button type="button" className="event-deposit__button" onClick={e => this.toggleBox(e)}>
          {t('bottles')}
        </button>

        <Transition>{isBoxVisible && <DepositBox item={item} closeBox={this.closeBox} />}</Transition>
      </div>
    );
  }
}

DepositButton.propTypes = {
  item: PropTypes.object.isRequired,
  isDeposited: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { deposit } }, { item: { id } }) => ({
  isNoting: !!(deposit[id] && deposit[id].isNoting),
  isDeposited: !!(deposit[id] && deposit[id].isDeposited)
});

export default withTranslation('Dashboard/Schedule')(connect(mapStateToProps)(DepositButton));
