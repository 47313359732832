import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

export const RegisterControls = ({ isLoading, step, t }) => (
  <div className="register-view__controls">
    <button type="submit" className={`register-view__controls-signup button relative ${isLoading ? 'loading' : ''}`}>
      <div className="register__lock" />
      {t(step === 2 ? 'complete' : 'register')}
    </button>
  </div>
);

RegisterControls.propTypes = {
  step: PropTypes.number,
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired
};

RegisterControls.defaultProps = {
  step: 1
};

const mapStateToProps = ({ register: { isRegistering: isLoading, step } }) => ({ isLoading, step });

export default withTranslation('Public/Register')(connect(mapStateToProps)(RegisterControls));
