import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isMobile as md } from 'utils';
import { fetchFavorites } from 'actions/shop/favorites';
import { DocumentTitle, Transition } from 'common';
import { WithCustomer } from 'components/DI';
import Header from 'components/Header';
import {
  CategoryMenu,
  MobileMenu,
  PartnerMenu,
  Cart,
  PersonalOverview,
  ProductOverview,
  CategoryOverview,
  SearchOverview,
  FavoriteOverview,
  BestsellerOverview,
  Notifications,
  ToggleViewButton
} from '.';

import './Shop.less';

class Shop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false
    };
  }

  componentDidMount() {
    this.props.fetchData();

    if (md()) {
      this.setState({
        isMobile: true
      });
    }
  }

  render() {
    const { isCartVisible, isCartCheckout, isCartEditing, fetchData, t } = this.props;
    const { isMobile } = this.state;
    const className = classNames('main-content', {
      'cart-is-visible': isCartVisible,
      'cart-is-checkout': isCartCheckout,
      'is-editing': isCartEditing
    });

    return (
      <DocumentTitle title={t('headline')}>
        <WithCustomer onChange={fetchData} className="col-group content-area pad">
          <Header />
          <div className={className}>
            <div className="col-12 pad shop-container">
              <div className="shop-navbar">
                {isMobile ? <MobileMenu /> : <CategoryMenu />}
                <ToggleViewButton />
                <PartnerMenu />
              </div>
            </div>

            <Switch>
              <Route path="/shop/all" component={ProductOverview} />
              <Route path="/shop/category/:categoryId/:slug" component={CategoryOverview} />
              <Route path="/shop/search" component={SearchOverview} />
              <Route path="/shop/favorites" component={FavoriteOverview} />
              <Route path="/shop/bestseller/:bestsellerId/:slug" component={BestsellerOverview} />
              <Route component={PersonalOverview} />
            </Switch>

            <Notifications />
          </div>

          <Transition>{isCartVisible && <Cart />}</Transition>
        </WithCustomer>
      </DocumentTitle>
    );
  }
}

Shop.propTypes = {
  t: PropTypes.func.isRequired,
  isCartVisible: PropTypes.bool,
  isCartCheckout: PropTypes.bool,
  isCartEditing: PropTypes.bool,
  fetchData: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isVisible: isCartVisible, isCheckout: isCartCheckout, cart }
  }
}) => ({
  isCartVisible,
  isCartCheckout,
  isCartEditing: !!cart.sed
});

const mapDispatchToProps = {
  fetchData: fetchFavorites
};

export default withTranslation('Shop')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Shop)
);
