import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const TaskGroups = ({ items, t }) => {
  if (!items || items.length <= 0) {
    return (
      <div className="se-tab-content">
        <div className="modal-content-list">
          <p className="task-group-empty">{t('no-tasks')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="se-tab-content">
      {items.map(taskGroup => (
        <div key={taskGroup.id} className="modal-content-list task-group-container">
          <div className="modal-headline task-group-name">{taskGroup.name}</div>

          {taskGroup.tasks &&
            taskGroup.tasks.map(task => (
              <div key={task.id} className="modal-list task-container">
                <div className="modal-icon task-icon" />
                <div className="task-name">{task.name}</div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

TaskGroups.propTypes = {
  items: PropTypes.array,
  t: PropTypes.func.isRequired
};

export default withTranslation('Modal/ServiceEvent')(TaskGroups);
