export default priorities => {
  const newPriorities = [];

  if (priorities && priorities.length > 0) {
    priorities.forEach(pr => {
      if (pr === 'low') {
        newPriorities.push(1, 2, 3);
      } else if (pr === 'medium') {
        newPriorities.push(4, 5, 6);
      } else if (pr === 'high') {
        newPriorities.push(7, 8, 9);
      } else if (pr === 'urgent') {
        newPriorities.push(10);
      }
    });
  }

  return newPriorities;
};
