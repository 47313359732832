import { shouldFetch } from 'utils';
import {
  INVOICES_PAID_REQUEST,
  INVOICES_PAID_SUCCESS,
  INVOICES_PAID_FAILURE,
  INVOICES_DUE_REQUEST,
  INVOICES_DUE_SUCCESS,
  INVOICES_DUE_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export const fetchPaidInvoices = () =>
  authCall({
    shouldFetch: ({
      invoices: {
        paid: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [INVOICES_PAID_REQUEST, INVOICES_PAID_SUCCESS, INVOICES_PAID_FAILURE],
    endpoint: '/user/invoices/paid',
    method: 'GET'
  });

export const fetchDueInvoices = () =>
  authCall({
    shouldFetch: ({
      invoices: {
        due: { isFetching, fetchedAt }
      }
    }) => shouldFetch(isFetching, fetchedAt),
    types: [INVOICES_DUE_REQUEST, INVOICES_DUE_SUCCESS, INVOICES_DUE_FAILURE],
    endpoint: '/user/invoices/due',
    method: 'GET'
  });
