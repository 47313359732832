import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DocumentTitle } from 'common';
import { pushLocation } from 'actions/location';
import { restorePassword } from 'actions/password';
import { notifyError } from 'actions/notifications';

import './RestorePassword.less';

const initialState = {
  newPassword: '',
  verifyPassword: ''
};

export class RestorePassword extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isRestored && nextProps.isRestored) {
      this.setState(initialState);
      nextProps.redirectToLogin();
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  validate() {
    return {
      passwordTooShort: this.state.newPassword.length <= 5 && this.state.newPassword.length > 1,
      passwordsMismatch: this.state.newPassword !== this.state.verifyPassword,
      passwordGood: this.state.newPassword.length >= 6,
      passwordsMatch: this.state.newPassword === this.state.verifyPassword && this.state.newPassword.length >= 6
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    const { token, t, doNotifyError } = this.props;
    const { newPassword, verifyPassword } = this.state;

    if (newPassword !== verifyPassword) {
      doNotifyError(t('passwords-not-matching'));
      return;
    }

    this.props.onSubmit(token, newPassword);
  }

  render() {
    const { isRestoring, t } = this.props;
    const { newPassword, verifyPassword } = this.state;
    const errors = this.validate(newPassword, verifyPassword);

    return (
      <DocumentTitle title={t('headline')}>
        <div className="password-view">
          <div className="logo" />
          <p className="headline">{t('headline')}</p>

          <form onSubmit={this.handleSubmit}>
            <fieldset disabled={isRestoring}>
              <div className={`form-group ${errors.passwordGood ? 'valid' : ''}`}>
                {errors.passwordTooShort && <label htmlFor="newPassword">{t('password-too-short')}</label>}

                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  className="form-control"
                  placeholder={t('new-password')}
                  onChange={this.handleChange}
                  required
                  minLength="8"
                />
              </div>

              <div className={`form-group ${errors.passwordsMatch ? 'valid' : ''}`}>
                {errors.passwordsMismatch && <label htmlFor="verifyPassword">{t('passwords-not-matching')}</label>}
                <input
                  type="password"
                  name="verifyPassword"
                  value={verifyPassword}
                  className="form-control"
                  placeholder={t('retype-password')}
                  onChange={this.handleChange}
                  required
                  minLength="8"
                />
              </div>

              <button type="submit" className={`button relative ${isRestoring ? 'loading' : ''}`}>
                {t('change')}
              </button>
            </fieldset>
          </form>
        </div>
      </DocumentTitle>
    );
  }
}

RestorePassword.propTypes = {
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  doNotifyError: PropTypes.func.isRequired,
  isRestoring: PropTypes.bool,
  isRestored: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = (
  {
    password: {
      restore: { isRestoring, isRestored }
    }
  },
  {
    match: {
      params: { token }
    }
  }
) => ({ token, isRestoring, isRestored });

const mapDispatchToProps = {
  onSubmit: restorePassword,
  doNotifyError: notifyError,
  redirectToLogin: () => pushLocation('/')
};

export { RestorePassword as PureComponent };

export default withTranslation('Public/RestorePassword')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RestorePassword)
);
