import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { FinishButton } from '.';

import './Success.less';

const Success = ({ t }) => (
  <div className="cart-inside">
    <div className="cart-success">{t('success-message')}</div>
    <FinishButton />
  </div>
);

Success.propTypes = {
  t: PropTypes.func.isRequired
};

export { Success as PureComponent };
export default pure(withTranslation('Shop/Cart/Default')(Success));
