import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Loader } from 'common';
import Product from './Product';

const List = ({ isFetching, showNewPrice, showOutOfStock, items, t }) => (
  <div className="modal-product-list">
    <Loader isLoading={isFetching} />

    {items &&
      items.map(item => (
        <Product key={item.id} item={item} showNewPrice={showNewPrice} showOutOfStock={showOutOfStock} />
      ))}

    {items && !items.length && <p className="product-list-empty">{t('no-products')}</p>}
  </div>
);

List.propTypes = {
  t: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  showNewPrice: PropTypes.bool,
  showOutOfStock: PropTypes.bool,
  items: PropTypes.array
};

export { List as PureComponent };
export default pure(withTranslation('Modal/ServiceEvent')(List));
