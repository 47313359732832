import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { RRule } from 'utils';
import { DATE_FORMAT } from 'constants/dateTime';
import { setSchedule } from 'actions/shop/cart';
import { translateEditing } from '../../../../HOC';
import { DueDate, Duration, Schedule } from '.';

const parseRRule = rrule => (rrule ? RRule.parseString(rrule) : {});

class Scheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minDate: moment(props.minDate, DATE_FORMAT),
      dueDate: moment(props.dueDate, DATE_FORMAT),
      duration: props.duration || undefined,
      rruleOptions: parseRRule(props.rrule)
    };

    this.handleChangeDueDate = this.handleChangeDueDate.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.handleChangeRRule = this.handleChangeRRule.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let nextState;

    if (this.props.minDate !== nextProps.minDate) {
      nextState = { ...nextState, minDate: moment(nextProps.minDate, DATE_FORMAT) };
    }

    if (this.props.dueDate !== nextProps.dueDate) {
      nextState = { ...nextState, dueDate: moment(nextProps.dueDate, DATE_FORMAT) };
    }

    if (this.props.duration !== nextProps.duration) {
      nextState = { ...nextState, duration: nextProps.duration || undefined };
    }

    if (this.props.rrule !== nextProps.rrule) {
      nextState = { ...nextState, rruleOptions: parseRRule(nextProps.rrule) };
    }

    if (nextState) this.setState(nextState);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  deferChange(dueDate, rruleOptions, duration) {
    const { onChange, timeout } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => onChange(dueDate, rruleOptions, duration), timeout);
  }

  handleChangeDueDate(dueDate) {
    const rruleOptions = {};
    const duration = undefined;

    if (dueDate !== this.state.dueDate) {
      this.setState({ dueDate, rruleOptions, duration });
      this.deferChange(dueDate, rruleOptions, duration);
    }
  }

  handleChangeDuration(duration) {
    const { dueDate, rruleOptions } = this.state;

    if (duration !== this.state.duration) {
      this.setState({ duration });
      this.deferChange(dueDate, rruleOptions, duration);
    }
  }

  handleChangeRRule(rruleOptions = {}) {
    const { dueDate, duration } = this.state;

    if (rruleOptions !== this.state.rruleOptions) {
      this.setState({ rruleOptions });
      this.deferChange(dueDate, rruleOptions, duration);
    }
  }

  render() {
    const { isDisabled, t } = this.props;
    const { minDate, dueDate, duration, rruleOptions } = this.state;
    const { freq } = rruleOptions;
    const hasDuration = freq === RRule.WEEKLY || freq === RRule.MONTHLY;

    return (
      <div className={`shipping ${isDisabled ? 'disabled' : ''}${hasDuration ? 'with-duration' : ''}`}>
        <div className="cart-title">{t('due-date')}</div>

        <div className="cart-details-flex">
          <DueDate minDate={minDate} dueDate={dueDate} onChange={this.handleChangeDueDate} />

          {hasDuration && <Duration duration={duration} onChange={this.handleChangeDuration} />}
        </div>

        <Schedule dueDate={dueDate} rruleOptions={rruleOptions} onChange={this.handleChangeRRule} />
      </div>
    );
  }
}

Scheduler.propTypes = {
  id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  minDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  duration: PropTypes.string,
  rrule: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  timeout: PropTypes.number
};

Scheduler.defaultProps = {
  isDisabled: false,
  timeout: 400
};

const mapStateToProps = ({
  shop: {
    cart: { isOrdering, isCancelling, isDeleting }
  }
}) => ({
  isDisabled: isOrdering || isCancelling || isDeleting
});

const mapDispatchToProps = (dispatch, { id }) => ({
  onChange: (...args) => dispatch(setSchedule(id, ...args))
});

export { Scheduler as PureComponent };
export default translateEditing(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Scheduler)
);
