import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './YearPicker.less';

class YearPicker extends Component {
  constructor(props) {
    super(props);

    const date = new Date();
    const year = date.getFullYear();
    this.startYear = year - 2;
    this.endYear = year;
    this.state = { currentYear: year };
  }

  handlePrevYearClick = () => {
    const { currentYear } = this.state;

    const prevYear = currentYear - 1;
    if (prevYear >= this.startYear) {
      this.setState(() => ({ currentYear: prevYear }));
      this.props.handleYearChange(prevYear);
    }
  };

  handleNextYearClick = () => {
    const { currentYear } = this.state;

    const nextYear = currentYear + 1;
    if (nextYear <= this.endYear) {
      this.setState(() => ({ currentYear: nextYear }));
      this.props.handleYearChange(nextYear);
    }
  };

  render() {
    const { currentYear } = this.state;

    return (
      <div className="yearpicker flex">
        <div onClick={this.handlePrevYearClick} className="year-prev" role="button" tabIndex="-1" />
        <div className="year-current">{currentYear}</div>
        <div onClick={this.handleNextYearClick} className="year-next" role="button" tabIndex="-2" />
      </div>
    );
  }
}

YearPicker.propTypes = {
  handleYearChange: PropTypes.func
};

export default YearPicker;
