import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Pagination.less';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = { currentSelectedPage: 1 };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange = page => {
    if (this.state.currentSelectedPage !== page) {
      this.setState(() => ({
        currentSelectedPage: page
      }));

      if (this.props.handlePageChange) {
        this.props.handlePageChange(page);
      }
    }
  };

  renderPaging = countOfPages => {
    const items = [];
    const { currentSelectedPage } = this.state;
    const pageArray = Array.from(new Array(countOfPages), (val, index) => index + 1);

    const filteredPages = page => {
      // total page count <= 10
      if (countOfPages <= 9 && page > 0) {
        return countOfPages;
        // current selected page is one of the last 10 pages
      }
      if (currentSelectedPage >= countOfPages - 10) {
        return page >= countOfPages - 9;
      }
      return page >= currentSelectedPage && page <= currentSelectedPage + 9;
    };

    const addPaginationItem = (page, buttonClass, value, key) => (
      <li
        className={`pagination-item ${page === currentSelectedPage ? 'active' : buttonClass}`}
        onClick={() => this.handlePageChange(page)}
        key={`${key}page-${page}`}
        role="presentation"
      >
        {value}
      </li>
    );

    // adds prev button
    if (currentSelectedPage !== 1) {
      items.push(addPaginationItem(currentSelectedPage - 1, 'prev-button', '', 'prev-'));
    }

    // adds button to go to first page
    if (currentSelectedPage >= 10) {
      items.push(addPaginationItem(1, 'first-button', '1 ... '));
    }

    // adds up to ten page buttons
    items.push(pageArray.filter(filteredPages).map(page => addPaginationItem(page, '', page)));

    // adds button to go to last page
    if (currentSelectedPage <= countOfPages - 10) {
      items.push(addPaginationItem(countOfPages, 'last-button', ` ... ${countOfPages}`));
    }

    // adds next button
    if (currentSelectedPage !== countOfPages) {
      items.push(addPaginationItem(currentSelectedPage + 1, 'next-button', '', 'next-'));
    }

    return <ul>{items}</ul>;
  };

  render() {
    const { numberOfItems, showItemsPerPage } = this.props;
    const countOfPages = Math.ceil(numberOfItems / showItemsPerPage);
    return <div className="pagination">{this.renderPaging(countOfPages)}</div>;
  }
}

Pagination.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  showItemsPerPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func
};

export default Pagination;
