import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createCase } from 'actions/cases';

import './CreateCase.less';

const initialState = {
  subject: '',
  body: ''
};

export class CreateCase extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isCreated && nextProps.isCreated) {
      this.setState(initialState);
    }
  }

  handleChange({ target: { name, value } }) {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmit } = this.props;
    const { subject, body } = this.state;

    onSubmit(subject.trim(), body.trim());
  }

  render() {
    const { isCreating, t } = this.props;
    const { subject, body } = this.state;

    return (
      <div className="col-12 pad">
        <div className="support-headline">{t('headline')}</div>

        <p className="support-text">{t('subline')}</p>

        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={isCreating}>
            <input
              name="subject"
              value={subject}
              className="contact-subject"
              type="text"
              placeholder={t('subject')}
              onChange={this.handleChange}
              required
            />

            <textarea
              name="body"
              value={body}
              className="contact-textarea"
              rows="3"
              placeholder={t('body')}
              onChange={this.handleChange}
              required
            />

            <button type="submit" className={`button support-submit ${isCreating ? 'loading-light' : ''}`}>
              {t('submit')}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  cases: {
    create: { isCreating, isCreated }
  }
}) => ({ isCreating, isCreated });

const mapDispatchToProps = {
  onSubmit: createCase
};

export { CreateCase as PureComponent };
export default withTranslation('Support/CreateCase')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateCase)
);
