import {
  USER_SETTINGS_SAVE_THEME_REQUEST,
  USER_SETTINGS_SAVE_THEME_SUCCESS,
  USER_SETTINGS_SAVE_THEME_FAILURE
} from 'constants/actionTypes';
import { authCall } from '../api';

const formatFormData = data => {
  const formData = new FormData();
  /* eslint-disable-next-line */
    for (const name in data) {
    formData.append(name, data[name]);
  }
  return formData;
};

export default theme => {
  const data = formatFormData(theme);

  return authCall({
    types: [USER_SETTINGS_SAVE_THEME_REQUEST, USER_SETTINGS_SAVE_THEME_SUCCESS, USER_SETTINGS_SAVE_THEME_FAILURE],
    endpoint: '/user/customer/preferences',
    method: 'POST',
    body: data,
    multipart: true
  });
};
