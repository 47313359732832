import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeProduct } from 'actions/shop/cart';

const RemoveButton = ({ isDisabled, onClick }) => (
  <button type="button" className={`cart-product-remove ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
    &nbsp;
  </button>
);

RemoveButton.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = ({
  shop: {
    cart: { isCheckingOut, isDeleting }
  }
}) => ({
  isDisabled: isCheckingOut || isDeleting
});

const mapDispatchToProps = (dispatch, { item }) => ({
  onClick: () => dispatch(removeProduct(item))
});

export { RemoveButton as PureComponent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveButton);
