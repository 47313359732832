import {
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAILURE
} from 'constants/actionTypes';

export default (
  state = {
    isChanging: false,
    isChanged: false
  },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { ...state, isChanging: true, isChanged: false };

    case USER_PASSWORD_CHANGE_SUCCESS:
      return { ...state, isChanging: false, isChanged: true };

    case USER_PASSWORD_CHANGE_FAILURE:
      return { ...state, isChanging: false };

    default:
      return state;
  }
};
