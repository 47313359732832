import {
  SERVICE_EVENT_REQUEST,
  SERVICE_EVENT_SUCCESS,
  SERVICE_EVENT_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_REQUEST: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: true,
          fetchedAt: null
        }
      };
    }

    case SERVICE_EVENT_SUCCESS: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: false,
          fetchedAt: new Date(),
          item: action.payload.id
        }
      };
    }

    case SERVICE_EVENT_FAILURE: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: false
        }
      };
    }

    default:
      return state;
  }
};
