import { SHOP_CATEGORIES_SUCCESS } from 'constants/actionTypes';

const createEntities = (entities, items) =>
  items.reduce((newEntities, item) => {
    if (item.children && item.children.length > 0) {
      return {
        ...newEntities,
        ...createEntities(
          {
            [item.id]: {
              ...item,
              children: item.children
                .sort((a, b) => b.priority - a.priority || a.name.localeCompare(b.name))
                .map(child => child.id)
            }
          },
          item.children
        )
      };
    }

    return { ...newEntities, [item.id]: item };
  }, entities);

export default (state = {}, action) => {
  switch (action.type) {
    case SHOP_CATEGORIES_SUCCESS:
      return Object.keys(action.payload).reduce(
        (entities, language) => ({ ...entities, [language]: createEntities({}, action.payload[language]) }),
        {}
      );

    default:
      return state;
  }
};
