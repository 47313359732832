import {
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILURE,
  OFFER_SUBMIT_REQUEST,
  OFFER_SUBMIT_SUCCESS,
  OFFER_SUBMIT_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';

export const fetchOffer = token =>
  apiCall({
    types: [FETCH_OFFER_REQUEST, FETCH_OFFER_SUCCESS, FETCH_OFFER_FAILURE],
    endpoint: `/offer/${token}`,
    meta: { token },
    method: 'GET'
  });

export const sendOffer = (token, submitOfferData) =>
  apiCall({
    types: [OFFER_SUBMIT_REQUEST, OFFER_SUBMIT_SUCCESS, OFFER_SUBMIT_FAILURE],
    endpoint: `/offer/sign/${token}`,
    meta: { token },
    method: 'PUT',
    body: submitOfferData
  });
