import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pure } from 'recompose';
import { Datepicker } from '.';

class DueDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  handleShow() {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true
      });
    }
  }

  handleHide() {
    if (this.state.isVisible) {
      this.setState({
        isVisible: false
      });
    }
  }

  render() {
    if (this.state.isVisible) {
      return <Datepicker {...this.props} onClickOutside={this.handleHide} outsideClickIgnoreClass="react-datepicker" />;
    }

    return (
      <button type="button" className="button-inherit cart-drop shipping-dated" onClick={this.handleShow}>
        {moment(this.props.dueDate).format('L')}
      </button>
    );
  }
}

DueDate.propTypes = {
  minDate: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  dueDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired // eslint-disable-line react/no-unused-prop-types
};

export { DueDate as PureComponent };
export default pure(DueDate);
