import {
  SERVICE_EVENT_PRODUCTS_REQUEST,
  SERVICE_EVENT_PRODUCTS_SUCCESS,
  SERVICE_EVENT_PRODUCTS_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_PRODUCTS_REQUEST: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: true,
          fetchedAt: null
        }
      };
    }

    case SERVICE_EVENT_PRODUCTS_SUCCESS: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: false,
          fetchedAt: new Date(),
          items: action.payload.map(item => ({ ...item,
            customer_product: item.customer_product ? item.customer_product.id : null
          }))
        }
      };
    }

    case SERVICE_EVENT_PRODUCTS_FAILURE: {
      const { meta: { serviceEventId } } = action;

      return { ...state,
        [serviceEventId]: { ...state[serviceEventId],
          isFetching: false
        }
      };
    }

    default:
      return state;
  }
};
