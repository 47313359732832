import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currency } from 'components/HOC';
import { translateEditing } from '../HOC';
import { Discount } from '.';

const Sum = ({ sumGross, sumNet, sumTax, sumDeliveryFee, sumDeposit, sumPartnerDiscount, t, c }) => {
  const netDeposit = sumNet + sumDeposit;

  return (
    <div className="cart-costline hover-area">
      <span>
        <span>{t('sum')}</span> {t('vat')}
      </span>
      <span className="cart-hovertext">{t('show-sum-details')}</span>
      <span className="cart-costs cart-costs-preview">{c(sumGross)}</span>

      <div className="cart-costs-hover">
        <div className="cart-costline">
          <span>
            {t('sum')}&nbsp;<span className="sum-net-prefix">({t('no-vat-no-deposit')})</span>
          </span>
          <span className="cart-costs sum-net">{c(sumNet)}</span>
        </div>

        {sumDeposit > 0 && (
          <div className="cart-costline">
            <span>{t('deposit')}</span>
            <span className="cart-costs sum-deposit">{c(sumDeposit)}</span>
          </div>
        )}

        <div className="cart-costline">
          <span>
            {t('sum')} <span className="sum-net-prefix"> {t('no-vat')}</span>
          </span>
          <span className="cart-costs sum-net-deposit">{c(netDeposit)}</span>
        </div>

        <div className="cart-costline">
          <span>{t('tax-amount')}</span>
          <span className="cart-costs sum-tax">{c(sumTax)}</span>
        </div>

        <Discount />

        <div className="cart-costline">
          <span>{t('delivery-costs')}</span>
          <span className="cart-costs sum-delivery-fee">{c(sumDeliveryFee)}</span>
        </div>

        {sumPartnerDiscount > 0 && (
          <div className="cart-costline cart-discount">
            <span>
              <strong>{t('sum-subcart-discount')}</strong> {t('vat')}
            </span>
            <span className="cart-costs sum-partner-discount">- {c(sumPartnerDiscount)}</span>
          </div>
        )}

        <div className="cart-costline sum">
          <span>
            <strong className="sum">{t('sum')}</strong> {t('vat')}
          </span>
          <span className="cart-costs sum-gross">{c(sumGross)}</span>
        </div>
      </div>
    </div>
  );
};

Sum.propTypes = {
  sumGross: PropTypes.number,
  sumNet: PropTypes.number,
  sumTax: PropTypes.number,
  sumDeliveryFee: PropTypes.number,
  sumDeposit: PropTypes.number,
  sumPartnerDiscount: PropTypes.number,
  t: PropTypes.func.isRequired,
  c: PropTypes.func.isRequired
};

Sum.defaultProps = {
  sumGross: 0,
  sumNet: 0,
  sumTax: 0,
  sumDeliveryFee: 0,
  sumDeposit: 0,
  sumPartnerDiscount: 0
};

const mapStateToProps = ({
  shop: {
    cart: {
      cart: {
        sum_gross: sumGross,
        sum_net: sumNet,
        sum_tax: sumTax,
        sum_delivery_fee: sumDeliveryFee,
        sum_deposit: sumDeposit,
        sum_partner_discount: sumPartnerDiscount
      }
    }
  }
}) => ({ sumGross, sumNet, sumTax, sumDeliveryFee, sumDeposit, sumPartnerDiscount });

export { Sum as PureComponent };
export default currency(translateEditing(connect(mapStateToProps)(Sum)));
