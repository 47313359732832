import { combineReducers } from 'redux';

import location from './location';
import auth from './auth';
import cases from './cases';
import current from './current';
import customer from './customer';
import password from './password';
import users from './users';
import offerConfirmation from './offerConfirmation';
import offers from './offers';
import partnerConfirmation from './partnerConfirmation';
import serviceEvents from './serviceEvents';
import serviceEventDefinitions from './serviceEventDefinitions';
import search from './search';
import shop from './shop';
import i18n from './i18n';
import notifications from './notifications';
import contentful from './contentful';
import invoices from './invoices';
import orders from './orders';
import orderPoNumbers from './orders/orderPoNumbers';
import register from './register';
import brand from './brand';
import root from './root';
import features from './features';
import members from './members';
import userSettings from './userSettings';
import issues from './issues';
import serviceRequests from './serviceRequests';

export { root };

export default combineReducers({
  auth,
  cases,
  current,
  customer,
  contentful,
  i18n,
  location,
  notifications,
  offerConfirmation,
  offers,
  partnerConfirmation,
  password,
  serviceEvents,
  serviceEventDefinitions,
  search,
  shop,
  users,
  invoices,
  orders,
  register,
  brand,
  features,
  orderPoNumbers,
  members,
  userSettings,
  issues,
  serviceRequests
});
