import { SERVICE_EVENT_CLEANING_OBJECT } from 'constants/actionTypes';

function updateCleaningObject(valueEvent) {
  return {
    type: SERVICE_EVENT_CLEANING_OBJECT,
    payload: valueEvent
  };
}

export default updateCleaningObject;
