import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './Stepbar.less';

const Stepbar = ({ step, t }) => (
  <div className="cleaning-stepbar">
    <div className={`cleaning-stepbar__step ${step === 1 ? 'active' : ''}`}>
      <p className="circle step-1" />
      <div className="cleaning-stepbar__headline">{t('step-1')}</div>
    </div>
    <div className="cleaning-stepbar__ministep">
      <div className="circle" />
    </div>
    <div className="cleaning-stepbar__ministep">
      <div className="circle" />
    </div>
    <div className={`cleaning-stepbar__step ${step === 2 ? 'active' : ''}`}>
      <p className="circle step-2" />
      <div className="cleaning-stepbar__headline">{t('step-2')}</div>
    </div>
    <div className="cleaning-stepbar__ministep">
      <div className="circle" />
    </div>
    <div className="cleaning-stepbar__ministep">
      <div className="circle" />
    </div>
    <div className={`cleaning-stepbar__step ${step === 3 ? 'active' : ''}`}>
      <p className="circle step-3" />
      <div className="cleaning-stepbar__headline">{t('step-3')}</div>
    </div>
  </div>
);

Stepbar.propTypes = {
  step: PropTypes.oneOf([1, 2, 3]),
  t: PropTypes.func.isRequired
};

Stepbar.defaultProps = {
  step: 1
};

export default withTranslation('Services/IntensiveCleaning')(Stepbar);
