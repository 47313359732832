import pick from 'lodash/pick';
import { AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE, CUSTOMER_CHANGE_SUCCESS } from 'constants/actionTypes';

// This is a scpecial reducer, used to
// reset the store after logout
export default reducer => (state, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS:
    case AUTH_LOGOUT_FAILURE:
    case CUSTOMER_CHANGE_SUCCESS:
      return reducer(pick(state, 'location', 'i18n', 'brand', 'contentful'), action);

    default:
      return reducer(state, action);
  }
};
