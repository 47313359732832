import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { createSelector } from 'reselect';

import { Loader } from 'common';
import { WithCustomer, WithSEFilters } from 'components/DI';
import { fetchServiceEvents as fetchServiceEventsAction } from 'actions/serviceEvents';
import { getItemsFromPages } from 'selectors/shared';
import { Event, Placeholder, Header, Calendar } from '.';

import './Schedule.less';

const Schedule = ({ t, list, entities, fetchServiceEvents }) => {
  const [selectedWeek, setSelectedWeek] = useState(moment());
  const [selectedDay, setSelectedDay] = useState(moment().format('dddd, MMMM Do YYYY'));
  const [dataKey, setDataKey] = useState('');
  const [daySelectedThisWeek, setDaySelectedThisWeek] = useState(true);

  const getDateSpan = useCallback(
    week => {
      const newSelectedWeek = week || selectedWeek || moment();

      return {
        startDate: newSelectedWeek.clone().startOf('week'),
        endDate: newSelectedWeek.clone().endOf('week')
      };
    },
    [selectedWeek]
  );

  const fetchData = useCallback(
    (nextPage, passedSelectedWeek) => {
      const { startDate, endDate } = passedSelectedWeek ? getDateSpan(passedSelectedWeek) : getDateSpan();
      let currentPage = 1;
      const dataKeyVar = `dshbrd_${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}`;
      setDataKey(dataKeyVar);

      if (list[dataKeyVar]) {
        const currP = list[dataKeyVar].currentPage;
        currentPage = currP;
      }

      fetchServiceEvents(dataKeyVar, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        page: nextPage || currentPage,
        order_by: 'asc',
        only_within_date_span: false
      });
    },
    [fetchServiceEvents, getDateSpan, list]
  );

  const fetchNext = currentPage => {
    fetchData(currentPage + 1);
  };

  const getPages = () => {
    return list[dataKey] && list[dataKey].pages;
  };
  const getEntities = () => entities;

  const getServiceEvents = () =>
    createSelector([getItemsFromPages(getPages), getEntities], (items, entitiesList) => {
      if (!items) return null;

      return items.map(itemId => entitiesList[itemId]).filter(item => !!item);
    })();

  const getServiceEventsForSelectedDay = passedSelectedDay => {
    const items = getServiceEvents() || [];

    return (
      items &&
      items.length > 0 &&
      items.filter(item => moment(item.start_date).format('dddd, MMMM Do YYYY') === passedSelectedDay)
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let items = [];
  let isFetching = false;
  let currentPage = 1;
  let hasMore = false;

  if (list[dataKey]) {
    items = daySelectedThisWeek === true ? getServiceEventsForSelectedDay(selectedDay) : getServiceEvents() || [];

    isFetching = list[dataKey].isFetching || false;
    currentPage = list[dataKey].currentPage || 1;
    hasMore = list[dataKey].hasMore || false;
  }

  const { startDate, endDate } = getDateSpan();

  return (
    <div className="dashboard-modul modul-schedule">
      <Header
        selectedWeek={selectedWeek}
        selectedDay={selectedDay}
        startDate={startDate}
        endDate={endDate}
        setSelectedWeek={setSelectedWeek}
        setDaySelectedThisWeek={setDaySelectedThisWeek}
        fetchData={fetchData}
      />
      <Calendar
        startDate={startDate}
        endDate={endDate}
        selectedDay={selectedDay}
        getServiceEventsForSelectedDay={getServiceEventsForSelectedDay}
        setSelectedDay={setSelectedDay}
        setDaySelectedThisWeek={setDaySelectedThisWeek}
      />
      {items.length > 0 &&
        items.map(item => {
          if (item.isActive) {
            return <Event key={`event-${item.id}`} item={item} />;
          }
          return null;
        })}
      {!items.length && <Placeholder />}
      {hasMore && (
        <div className="modul__more">
          <button
            type="button"
            className={`button-more ${isFetching ? 'disabled loading' : ''}`}
            onClick={() => fetchNext(currentPage)}
          >
            {t('more')}
          </button>
        </div>
      )}
      <Loader isLoading={isFetching} />
      <WithCustomer onChange={() => fetchData(1)} />
      <WithSEFilters onChange={() => fetchData(1)} />
    </div>
  );
};

Schedule.propTypes = {
  fetchServiceEvents: PropTypes.func.isRequired,
  entities: PropTypes.object,
  list: PropTypes.object,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ serviceEvents: { entities, list } }) => ({ entities, list });

const mapDispatchToProps = {
  fetchServiceEvents: (dataKey, query) => fetchServiceEventsAction(dataKey, query)
};

export default withTranslation('Dashboard/Schedule')(connect(mapStateToProps, mapDispatchToProps)(Schedule));
