import inspector from 'schema-inspector';
import { SHOP_PARTNERS_RESTORE } from 'constants/actionTypes';

const partnerIdsSchema = {
  type: 'array',
  minLength: 1,
  items: { type: 'string' }
};

export default partnerIds => dispatch => {
  if (inspector.validate(partnerIdsSchema, partnerIds).valid) {
    dispatch({
      type: SHOP_PARTNERS_RESTORE,
      partnerIds
    });
  }
};
