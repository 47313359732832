import {
  SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST,
  SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS,
  SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FEEDBACK_SUBMIT_REQUEST: {
      const {
        meta: { id }
      } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          isSubmitting: true,
          isSubmitted: false
        }
      };
    }

    case SERVICE_EVENT_FEEDBACK_SUBMIT_SUCCESS: {
      const {
        meta: { id }
      } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          isSubmitting: false,
          isSubmitted: true,
          item: action.payload.service_event
        }
      };
    }

    case SERVICE_EVENT_FEEDBACK_SUBMIT_FAILURE: {
      const {
        meta: { id }
      } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          isSubmitting: false
        }
      };
    }

    default:
      return state;
  }
};
