import {
  REGISTER_STEP_ONE_REQUEST,
  REGISTER_STEP_ONE_SUCCESS,
  REGISTER_STEP_ONE_FAILURE
} from 'constants/actionTypes';
import { apiCall } from 'api';

export default body =>
  apiCall({
    shouldFetch: state => !state.register.isRegistering,
    types: [REGISTER_STEP_ONE_REQUEST, REGISTER_STEP_ONE_SUCCESS, REGISTER_STEP_ONE_FAILURE],
    endpoint: '/user/register',
    method: 'POST',
    body
  });
