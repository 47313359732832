import {
  SERVICE_EVENT_FEEDBACK_REQUEST,
  SERVICE_EVENT_FEEDBACK_SUCCESS,
  SERVICE_EVENT_FEEDBACK_FAILURE
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EVENT_FEEDBACK_REQUEST: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isFetching: true, fetchedAt: null } };
    }

    case SERVICE_EVENT_FEEDBACK_SUCCESS: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isFetching: false, fetchedAt: new Date(), item: action.payload } };
    }

    case SERVICE_EVENT_FEEDBACK_FAILURE: {
      const {
        meta: { id }
      } = action;

      return { ...state, [id]: { ...state[id], isFetching: false } };
    }

    default:
      return state;
  }
};
