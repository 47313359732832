import React from 'react';
import PropTypes from 'prop-types';

export class InputGroupAddon extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    return <div className="input-group__addon">{this.props.children}</div>;
  }
}

export default InputGroupAddon;
