import { BRAND_SET } from 'constants/actionTypes';

export default (brand = null) => (dispatch, getState) => {
  const state = getState();

  if (brand !== state.brand) {
    dispatch({
      type: BRAND_SET,
      brand
    });
  }
};
