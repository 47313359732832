import moment from 'moment';
import {
  SERVICE_EVENT_STATUS_DRAFT,
  SERVICE_EVENT_TYPE_CLEANING,
  SERVICE_EVENT_MODE_RECURRENT
} from '../constants/models';
import serviceEvent from './serviceEvent';

export default props => ({
  ...props,
  start_date: moment(props.start_date, moment.ISO_8601),
  end_date: props.end_date ? moment(props.end_date, moment.ISO_8601) : props.end_date,
  next_event: props.next_event ? serviceEvent(props.next_event) : props.next_event,
  isDraft: props.status === SERVICE_EVENT_STATUS_DRAFT,
  isTypeCleaning: props.type === SERVICE_EVENT_TYPE_CLEANING,
  isModeRecurrent: props.mode === SERVICE_EVENT_MODE_RECURRENT,
  isPendingChanges: props.status === SERVICE_EVENT_STATUS_DRAFT && !!props.old_id,
  toJson: () => props
});
