import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';

const createTitle = title => (title ? `${title} - ${process.env.REACT_APP_SITENAME}` : process.env.REACT_APP_SITENAME);

const DocTitle = props => {
  const [title, setTitle] = useState(() => createTitle(props.title));

  useEffect(() => {
    setTitle(createTitle(props.title));
  }, [props.title]);

  return <DocumentTitle title={title}>{props.children}</DocumentTitle>;
};

DocTitle.propTypes = {
  title: PropTypes.string
};

export default DocTitle;
