import isArray from 'lodash/isArray';
import i18next from '../i18next';
import { CAPTCHA_REQUIRED, DISCOUNT_SYSTEM_UNAVAILABLE } from '../constants/errors';
import { notifyError, notifySuccess } from '../actions/notifications';

const t = i18next.getFixedT(null, 'Error');
const getErrors = action => {
  const error = action.payload && action.payload.error;
  if (isArray(error)) return error;
  if (error && error.message) return [error];
  return null;
};
const getSuccess = action => action.payload && action.payload.success && action.payload.success.message;

export default store => next => action => {
  next(action);

  const { dispatch } = store;

  if (action.error) {
    const errors = getErrors(action);

    if (errors) {
      errors
        .filter(err => ![CAPTCHA_REQUIRED, DISCOUNT_SYSTEM_UNAVAILABLE].includes(err.code))
        .forEach(err => dispatch(notifyError(err.message)));
    } else {
      let message;

      if (action.requestError) {
        message = t('request-error', { message: action.requestError.message });
      } else if (action.response) {
        message = t(`http-${action.response.status}`) || t('http-500');
      }

      dispatch(notifyError(message || t('error-unknown')));
    }

    return;
  }

  const success = getSuccess(action);
  if (success) dispatch(notifySuccess(success));
};
