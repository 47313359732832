import { SERVICE_EVENTS_REQUEST, SERVICE_EVENTS_SUCCESS, SERVICE_EVENTS_FAILURE } from 'constants/actionTypes';
import { authCall } from 'api';
import { shouldFetch } from 'utils';

/**
 * @param query.start_date
 * @param query.end_date
 * @param query.page
 * @param query.limit
 */
export default (dataKey, query) => {
  const currentPage = query.page || 1;

  return authCall({
    shouldFetch: ({
      serviceEvents: {
        list: { [dataKey]: { isFetching, pages: { [currentPage]: { fetchedAt } = {} } = {} } = {} }
      }
    }) => shouldFetch(isFetching, fetchedAt, true),
    meta: { dataKey, currentPage },
    types: [SERVICE_EVENTS_REQUEST, SERVICE_EVENTS_SUCCESS, SERVICE_EVENTS_FAILURE],
    endpoint: '/user/service-events',
    method: 'GET',
    query: ({
      serviceEvents: {
        filters: { selected }
      }
    }) => ({
      ...query,
      ...selected,
      page: currentPage // force page for now
    })
  });
};
