import { createSelector } from 'reselect';
import { FILTER_TYPE_TYPES, FILTER_TYPE_MODES, FILTER_TYPE_STATES } from 'constants/filterTypes';

const getFilters = (state, { dataKey }) => state.serviceEvents.filters.list[dataKey];
const getSelectedFilters = state => state.serviceEvents.filters.selected;

export default createSelector([getFilters, getSelectedFilters], (filters, selectedFilters) => {
  if (!filters || !filters.types) return null;

  return {
    types: filters.types.map(type => ({
      value: type,
      type: FILTER_TYPE_TYPES,
      isSelected: selectedFilters.types.includes(type)
    })),
    mode:
            filters.modes.length > 1 &&
            filters.modes
              .filter(mode => mode === 'recurrent')
              .map(mode => ({
                value: mode,
                type: FILTER_TYPE_MODES,
                isSelected: selectedFilters.modes.includes(mode)
              }))[0],
    state:
            filters.states.length > 1 &&
            filters.states
              .filter(state => state === 'active')
              .map(state => ({
                value: state,
                type: FILTER_TYPE_STATES,
                isSelected: selectedFilters.states.includes(state)
              }))[0]
  };
});
