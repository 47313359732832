// Inspired from reselect
export default (funcs, callback) => {
  let lastArgs = null;
  let lastReturn = null;

  return state => {
    const args = funcs.map(func => func(state));

    if (lastArgs !== null
            && lastArgs.length === args.length
            && args.every((value, index) => value === lastArgs[index])) return lastReturn;

    lastArgs = args;
    lastReturn = callback(...args);
    return lastReturn;
  };
};
