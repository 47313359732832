import i18next from '../../../i18next';
import { confirm } from "../../notifications";
import { resetPartners } from "../partners";
import { pushLocation } from "../../location";
import { deleteCart } from ".";

const t = i18next.getFixedT(null, 'Notifications');

export default nextLocation => (dispatch, getState) => {
  const {
    shop: {
      cart: { isChanged }
    }
  } = getState();

  return (isChanged ? dispatch(confirm(t('confirm-leave-editing'))) : Promise.resolve())
    .then(() => dispatch(deleteCart()))
    .then(() => {
      dispatch(resetPartners());
      if (nextLocation) dispatch(pushLocation(nextLocation));
    })
    .catch(() => {});
};
