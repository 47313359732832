import {
  SERVICE_EVENT_CLEANING_CALC_REQUEST,
  SERVICE_EVENT_CLEANING_CALC_SUCCESS,
  SERVICE_EVENT_CLEANING_CALC_FAILURE
} from 'constants/actionTypes';
import { authCall } from 'api';

export default cleaningObj =>
  authCall({
        shouldFetch: () => true, // eslint-disable-line
    types: [
      SERVICE_EVENT_CLEANING_CALC_REQUEST,
      SERVICE_EVENT_CLEANING_CALC_SUCCESS,
      SERVICE_EVENT_CLEANING_CALC_FAILURE
    ],
    endpoint: '/user/form/cleaning/calculate',
    method: 'POST',
    body: cleaningObj
  });
