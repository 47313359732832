import { SHOPPING_CART_STEP_PREVIEW, SHOPPING_CART_STEP_SUCCESS } from '../../constants/shoppingCart';
import { pushLocation } from ".";
import { cancelCheckout, resetCart } from "../shop/cart";

export default () => (dispatch, getState) => {
  const {
    location,
    shop: {
      cart: { step }
    }
  } = getState();

  if (step === SHOPPING_CART_STEP_SUCCESS) dispatch(resetCart());
  else if (step !== SHOPPING_CART_STEP_PREVIEW) dispatch(cancelCheckout());

  dispatch(pushLocation({ ...location, state: { isCartVisible: false } }));
};
