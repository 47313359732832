import { combineReducers } from 'redux';
import data from './data';
import emailVerify from './emailVerify';
import login from './login';
import logout from './logout';
import token from './token';

export default combineReducers({
  data,
  emailVerify,
  login,
  logout,
  token
});
